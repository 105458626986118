<div class="flex flex-col bg-card h-full overflow-hidden">
  <!-- Header -->
  <div class="flex-col sm:flex-row md:flex-col justify-between">
    <div class="flex flex-row justify-between mb-4 items-center">
      <div class="xs:text-xl sm:text-2xl md:text-4xl font-extrabold tracking-tight leading-none">
        Create Supplier Order
      </div>
      <div class="flex flex-row items-center">
        <button
          (click)="cancel()"
          class="pl-2 text-xs rounded-xl min-h-8 h-8 px-4 ml-4"
          mat-stroked-button
          [color]="'primary'"
        >
          <span class="ml-2 mr-1">Cancel</span>
        </button>
        <button
          (click)="submit()"
          class="pl-2 text-xs rounded-xl min-h-8 h-8 px-4 ml-4"
          mat-flat-button
          [color]="'primary'"
        >
          <span class="ml-2 mr-1">Add to order</span>
        </button>
      </div>
    </div>

    <hr class="my-2.5" />

    <div class="table-search-wrap mb-3">
      <div [formGroup]="filterForm" class="w-60">
        <mat-form-field class="fuse-mat-no-subscript w-full small-select">
          <mat-select formControlName="supplier">
            <mat-option [value]="0"> All Suppliers </mat-option>
            <mat-option *ngFor="let s of supplierOptions" [value]="s.id">
              {{ s.name }}
            </mat-option>
          </mat-select>
          <mat-icon
            matPrefix
            aria-hidden="true"
            class="icon-size-5 dark:text-white"
            [svgIcon]="'heroicons_outline:adjustments-horizontal'"
          ></mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="h-full flex flex-col overflow-hidden border">
    <div class="table-search-wrap rounded-b-none border-b">
      <div class="flex flex-row justify-between items-center">
        <div [formGroup]="filterForm" class="w-full max-w-80">
          <mat-form-field
            class="fuse-mat-dense fuse-mat-no-subscript small-search w-full min-w-50"
          >
            <mat-icon
              matPrefix
              [svgIcon]="'heroicons_outline:magnifying-glass'"
            ></mat-icon>
            <input
              formControlName="search"
              matInput
              [autocomplete]="'off'"
              [placeholder]="'Search by description'"
            />
          </mat-form-field>
        </div>
        <div [formGroup]="filterForm" class="w-60">
          <mat-form-field class="fuse-mat-no-subscript w-full small-select">
            <mat-select formControlName="category">
              <mat-option [value]="0">All Categories</mat-option>
              <mat-option *ngFor="let c of categoryOptions" [value]="c">
                {{ c }}
              </mat-option>
            </mat-select>
            <mat-icon
              matPrefix
              aria-hidden="true"
              class="icon-size-5 dark:text-white"
              [svgIcon]="'heroicons_outline:tag'"
            ></mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="h-full overflow-hidden"
      fuseScrollbar
      [fuseScrollbarOptions]="{
        wheelPropagation: false,
        suppressScrollX: true
      }"
    >
      <table mat-table [dataSource]="dataSource" matSort class="w-full">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th class="w-20" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              [color]="'primary'"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td class="w-20" mat-cell *matCellDef="let row">
            <mat-checkbox
              [color]="'primary'"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="priceListCategoryName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.priceListCategoryName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="itemCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.itemCode }}
          </td>
        </ng-container>
        <ng-container matColumnDef="supplierItemCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Supplier Code
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.supplierItemCode }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Item Description
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.qty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unitOfMeasure">
          <th mat-header-cell *matHeaderCellDef>UOM</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.unitOfMeasure }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unitPrice">
          <th mat-header-cell *matHeaderCellDef>Unit Price</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.unitPrice }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unitTotal">
          <th mat-header-cell *matHeaderCellDef>Total Price</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ element.unitTotal }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="selection.toggle(row)"
        ></tr>
      </table>
      <div *ngIf="!dataSource?.data?.length">
        <p class="text-center mt-3">No items found to order</p>
      </div>
    </div>
  </div>
</div>
