/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-parens */
import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { PriceListState, selectPriceListState } from '../reducers';

export const selectItemPriceCheckState = createSelector(
  selectPriceListState,
  (s: PriceListState) => s.itemPriceCheck,
);

export const selectItemPriceCheckIsLoading = createSelector(
  selectItemPriceCheckState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectItemPriceCheckIsLoaded = createSelector(
  selectItemPriceCheckState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectItemPriceCheckError = createSelector(
  selectItemPriceCheckState,
  (s) => getError(s.callState),
);

export const selectFullPriceList = createSelector(
  selectItemPriceCheckState,
  (s) => s.items,
);
