import { createAction, props } from '@ngrx/store';
import {
  AddCallForwardItem,
  AddCallForwardItemNote,
  CallForwardItem,
  CallForwardItemNote,
  CallForwardItemNoteParameters,
  CallForwardItemParameters,
  PaginatedListOfCallForwardItem,
  PaginatedListOfCallForwardItemNote,
} from 'app/shared/models';

export enum JobCallForwardActionTypes {
  GetJobCallForward = '[Job CallForward List] Get Job CallForward',
  GetJobCallForwardSuccess = '[Job CallForward List] Get Job CallForward Success',
  GetJobCallForwardFailure = '[Job CallForward List] Get Job CallForward Failure',

  GetJobCallForwardItems = '[Job CallForwardItems List] Get Job CallForwardItems',
  GetJobCallForwardItemsSuccess = '[Job CallForwardItems List] Get Job CallForwardItems Success',
  GetJobCallForwardItemsFailure = '[Job CallForwardItems List] Get Job CallForwardItems Failure',

  GetJobCallForwardItemTypes = '[Job CallForward List] Get Job CallForwardItemTypes',
  GetJobCallForwardItemTypesSuccess = '[Job CallForward List] Get Job CallForwardItemTypes Success',
  GetJobCallForwardItemTypesFailure = '[Job CallForward List] Get Job CallForwardItemTypes Failure',

  GetJobCallForwardItemStatuses = '[Job CallForward List] Get Job CallForwardItemStatuses',
  GetJobCallForwardItemStatusesSuccess = '[Job CallForward List] Get Job CallForwardItemStatuses Success',
  GetJobCallForwardItemStatusesFailure = '[Job CallForward List] Get Job CallForwardItemStatuses Failure',

  GetJobCallForwardFilterSuppliers = '[Job CallForward List] Get Job CallForwardFilterSuppliers',
  GetJobCallForwardFilterSuppliersSuccess = '[Job CallForward List] Get Job CallForwardFilterSuppliers Success',
  GetJobCallForwardFilterSuppliersFailure = '[Job CallForward List] Get Job CallForwardFilterSuppliers Failure',

  DeleteJobCallForwardItem = '[Job CallForwardItem] Delete Job CallForwardItem',
  DeleteJobCallForwardItemSuccess = '[Job CallForwardItem] Delete Job CallForwardItem Success',
  DeleteJobCallForwardItemFailure = '[Job CallForwardItem] Delete Job CallForwardItem Failure',

  AddJobCallForwardItem = '[Job CallForwardItem] Add Job CallForwardItem',
  AddJobCallForwardItemSuccess = '[Job CallForwardItem] Add Job CallForwardItem Success',
  AddJobCallForwardItemFailure = '[Job CallForwardItem] Add Job CallForwardItem Failure',

  UpdateJobCallForwardItem = '[Job CallForwardItem] Update Job CallForwardItem',
  UpdateJobCallForwardItemSuccess = '[Job CallForwardItem] Update Job CallForwardItem Success',
  UpdateJobCallForwardItemFailure = '[Job CallForwardItem] Update Job CallForwardItem Failure',

  EditIndex = '[Job CallForwardItem] EditIndex',
  IsNew = '[Job CallForwardItem] IsNew',
  CreateNew = '[Job CallForwardItem] CreateNew',

  // CallForwardItemNote CRUD
  GetCallForwardItemNotes = '[CallForwardItemNotes] Get CallForwardItemNotes',
  GetCallForwardItemNotesSuccess = '[CallForwardItemNotes] Get CallForwardItemNotes Success',
  GetCallForwardItemNotesFailure = '[CallForwardItemNotes] Get CallForwardItemNotes Failure',

  DeleteCallForwardItemNote = '[CallForwardItemNote] Delete CallForwardItemNote',
  DeleteCallForwardItemNoteSuccess = '[CallForwardItemNote] Delete CallForwardItemNote Success',
  DeleteCallForwardItemNoteFailure = '[CallForwardItemNote] Delete CallForwardItemNote Failure',

  AddCallForwardItemNote = '[CallForwardItemNote] Add CallForwardItemNote',
  AddCallForwardItemNoteSuccess = '[CallForwardItemNote] Add CallForwardItemNote Success',
  AddCallForwardItemNoteFailure = '[CallForwardItemNote] Add CallForwardItemNote Failure',

  UpdateCallForwardItemNote = '[CallForwardItemNote] Update CallForwardItemNote',
  UpdateCallForwardItemNoteSuccess = '[CallForwardItemNote] Update CallForwardItemNote Success',
  UpdateCallForwardItemNoteFailure = '[CallForwardItemNote] Update CallForwardItemNote Failure',

  UpdateCallForwardItemStatus = '[CallForwardItem] Update CallForwardItemStatus',
  UpdateCallForwardItemStatusSuccess = '[CallForwardItem] Update CallForwardItemStatus Success',
  UpdateCallForwardItemStatusFailure = '[CallForwardItem] Update CallForwardItemStatus Failure',

  ClearCallForwardsStore = '[CallForwardItemNote] Clear CallForwardsStore',
}

export const getJobCallForwardFailure = createAction(
  JobCallForwardActionTypes.GetJobCallForwardFailure,
  props<{ error: string }>(),
);

// CallForwardItems
export const getJobCallForwardItems = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItems,
  props<{ query: CallForwardItemParameters }>(),
);

export const getJobCallForwardItemsSuccess = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemsSuccess,
  props<{ callforwardItems: PaginatedListOfCallForwardItem }>(),
);

export const getJobCallForwardItemsFailure = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemsFailure,
  props<{ error: string }>(),
);

// item  types
export const getJobCallForwardItemTypes = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemTypes,
);

export const getJobCallForwardItemTypesSuccess = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemTypesSuccess,
  props<{ itemTypes: { id: string; name: string }[] }>(),
);

export const getJobCallForwardItemTypesFailure = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemTypesFailure,
  props<{ error: string }>(),
);

// item statuses
export const getJobCallForwardItemStatuses = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemStatuses,
);

export const getJobCallForwardItemStatusesSuccess = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemStatusesSuccess,
  props<{ itemStatuses: { id: string; name: string }[] }>(),
);

export const getJobCallForwardItemStatusesFailure = createAction(
  JobCallForwardActionTypes.GetJobCallForwardItemStatusesFailure,
  props<{ error: string }>(),
);

// delete item
export const deleteCallForwardItem = createAction(
  JobCallForwardActionTypes.DeleteJobCallForwardItem,
  props<{ id: string }>(),
);

export const deleteCallForwardItemSuccess = createAction(
  JobCallForwardActionTypes.DeleteJobCallForwardItemSuccess,
  props<{ response: any }>(),
);

export const deleteCallForwardItemFailure = createAction(
  JobCallForwardActionTypes.DeleteJobCallForwardItemFailure,
  props<{ error: string }>(),
);

// add new item
export const addCallForwardItem = createAction(
  JobCallForwardActionTypes.AddJobCallForwardItem,
  props<{ payload: AddCallForwardItem }>(),
);

export const addCallForwardItemSuccess = createAction(
  JobCallForwardActionTypes.AddJobCallForwardItemSuccess,
  props<{ response: CallForwardItem }>(),
);

export const addCallForwardItemFailure = createAction(
  JobCallForwardActionTypes.AddJobCallForwardItemFailure,
  props<{ error: string }>(),
);

// update item
export const updateCallForwardItem = createAction(
  JobCallForwardActionTypes.UpdateJobCallForwardItem,
  props<{ payload: CallForwardItem }>(),
);

export const updateCallForwardItemSuccess = createAction(
  JobCallForwardActionTypes.UpdateJobCallForwardItemSuccess,
  props<{ response: CallForwardItem }>(),
);

export const updateCallForwardItemFailure = createAction(
  JobCallForwardActionTypes.UpdateJobCallForwardItemFailure,
  props<{ error: string }>(),
);

// editing an item
export const editIndex = createAction(
  JobCallForwardActionTypes.EditIndex,
  props<{ index: number | null }>(),
);

export const isNew = createAction(
  JobCallForwardActionTypes.IsNew,
  props<{ value: boolean | null }>(),
);
export const createNew = createAction(
  JobCallForwardActionTypes.CreateNew,
  props<{ value: boolean | null }>(),
);

// get CallForwardItemNotes
export const getCallForwardItemNotes = createAction(
  JobCallForwardActionTypes.GetCallForwardItemNotes,
  props<{ query: CallForwardItemNoteParameters }>(),
);

export const getCallForwardItemNotesSuccess = createAction(
  JobCallForwardActionTypes.GetCallForwardItemNotesSuccess,
  props<{ callforwardItemNotes: PaginatedListOfCallForwardItemNote }>(),
);

export const getCallForwardItemNotesFailure = createAction(
  JobCallForwardActionTypes.GetCallForwardItemNotesFailure,
  props<{ error: string }>(),
);

// update CallForwardItemNote
export const updateCallForwardItemNote = createAction(
  JobCallForwardActionTypes.UpdateCallForwardItemNote,
  props<{ payload: CallForwardItemNote }>(),
);

export const updateCallForwardItemNoteSuccess = createAction(
  JobCallForwardActionTypes.UpdateCallForwardItemNoteSuccess,
  props<{ response: CallForwardItemNote }>(),
);

export const updateCallForwardItemNoteFailure = createAction(
  JobCallForwardActionTypes.UpdateCallForwardItemNoteFailure,
  props<{ error: string }>(),
);

// add CallForwardItemNote
export const addCallForwardItemNote = createAction(
  JobCallForwardActionTypes.AddCallForwardItemNote,
  props<{ payload: AddCallForwardItemNote }>(),
);

export const addCallForwardItemNoteSuccess = createAction(
  JobCallForwardActionTypes.AddCallForwardItemNoteSuccess,
  props<{ response: CallForwardItemNote }>(),
);

export const addCallForwardItemNoteFailure = createAction(
  JobCallForwardActionTypes.AddCallForwardItemNoteFailure,
  props<{ error: string }>(),
);

// delete CallForwardItemNote
export const deleteCallForwardItemNote = createAction(
  JobCallForwardActionTypes.DeleteCallForwardItemNote,
  props<{ id: string }>(),
);

export const deleteCallForwardItemNoteSuccess = createAction(
  JobCallForwardActionTypes.DeleteCallForwardItemNoteSuccess,
  props<{ response: CallForwardItemNote }>(),
);

export const deleteCallForwardItemNoteFailure = createAction(
  JobCallForwardActionTypes.DeleteCallForwardItemNoteFailure,
  props<{ error: string }>(),
);

// update CallForwardItemStatus
export const updateCallForwardItemStatus = createAction(
  JobCallForwardActionTypes.UpdateCallForwardItemStatus,
  props<{ cfId: string; statusId: number }>(),
);

export const updateCallForwardItemStatusSuccess = createAction(
  JobCallForwardActionTypes.UpdateCallForwardItemStatusSuccess,
  props<{ response: CallForwardItem }>(),
);

export const updateCallForwardItemStatusFailure = createAction(
  JobCallForwardActionTypes.UpdateCallForwardItemStatusFailure,
  props<{ error: string }>(),
);

export const clearCallForwardsStore = createAction(
  JobCallForwardActionTypes.ClearCallForwardsStore,
);
