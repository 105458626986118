import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { JobVariationsListFacade } from 'app/views/jobs/store';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgIf, AsyncPipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-job-costing-summary',
  templateUrl: './job-costing-summary.component.html',
  styleUrls: ['./job-costing-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, AsyncPipe, DecimalPipe],
})
export class JobCostingSummaryComponent implements OnInit, OnDestroy {
  isLoaded$ = this.facade.isLoaded$;
  summary$ = this.facade.costingSummary$;
  private unsubscriber$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private facade: JobVariationsListFacade,
  ) {}

  ngOnInit(): void {
    const jobId = this.route.parent.parent.snapshot.params.id;
    this.facade.getJobCostingSummary(jobId);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
