<div
  class="xs:absolute xs:inset-0 flex flex-col flex-auto min-w-0 xs:overflow-hidden"
>
  <div class="flex justify-start p-2">
    <button
      *ngIf="!isReadOnlyMode"
      class="order-5 xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
      mat-flat-button
      color="primary"
      [disabled]="isEditing === false"
      (click)="save()"
    >
      <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
      <span class="xs:hidden sm:block ml-2 mr-1">Save</span>
    </button>
    <button
      *ngIf="!isReadOnlyMode"
      class="order-5 xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
      mat-flat-button
      color="accent"
      [disabled]="isEditing === false"
      (click)="cancel()"
    >
      <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
      <span class="xs:hidden sm:block ml-2 mr-1">Cancel</span>
    </button>
  </div>
  <div class="px-2.5 w-full overflow-auto h-full flex flex-col">
    <!-- Estimate Header -->
    <div id="estimate-summary" class="w-full flex flex-row items-center">
      <app-estimate-header
        [isReadOnlyMode]="isReadOnlyMode"
        [defaultOnCost]="defaultOnCost$ | async"
      ></app-estimate-header>
    </div>
    <div class="flex flex-col py-3 h-full">
      <app-estimate-price-list-items
        class="h-full relative"
        [items]="(estimate$ | async)?.items"
        [readOnly]="isReadOnlyMode"
      >
      </app-estimate-price-list-items>
    </div>
  </div>
</div>
