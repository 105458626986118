<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [ngClass]="{ 'intro-mode': introJsService.introMenuMode$ | async }"
    [mode]="
        isScreenSmall && (introJsService.introMenuMode$ | async) === false
            ? 'over'
            : 'side'
    "
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall || (introJsService.introMenuMode$ | async)"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex w-full items-center p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img class="w-32" src="images/logo/logo-reversed-web.svg" />
            </div>
            <!-- Components -->
            <div class="ml-auto flex items-center">
                <accounts [showAvatar]="false"></accounts>
            </div>
        </div>
        <!-- User -->
        <div class="flex w-full flex-col items-center p-4">
            <div class="flex w-full max-w-80 flex-col p-4">
                <div
                    class="align-middle mt-2 flex flex-row"
                    *ngIf="af.selectActiveAccountPlan$ | async"
                >
                    <mat-icon
                        class="mr-3 text-yellow-500 icon-size-5"
                        fontIcon="perm_contact_calendar"
                        matTooltip="Current plan"
                    ></mat-icon>
                    <span class="line-clamp-2 text-sm">{{ planName }}</span>
                </div>
                <div class="align-middle mt-2 flex flex-row">
                    <mat-icon
                        class="mr-3 icon-size-5"
                        [svgIcon]="'heroicons_outline:briefcase'"
                        color="primary"
                        matTooltip="Current account"
                    ></mat-icon>
                    <span class="mr-2 line-clamp-2 text-sm">{{
                        (af.activeAccount$ | async).accountName ??
                            'Account name not set'
                    }}</span>
                    <div
                        class="mr-2 flex flex-col items-center justify-center rounded-full bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 dark:bg-primary-900 dark:text-blue-300"
                        *ngIf="
                            (af.activeAccount$ | async).teamMemberTypeId === 1
                        "
                    >
                        <div>Owner</div>
                    </div>
                    <div
                        class="mr-2 flex flex-col items-center justify-center rounded-full bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 dark:bg-primary-900 dark:text-blue-300"
                        *ngIf="
                            (af.activeAccount$ | async).teamMemberTypeId === 2
                        "
                    >
                        <div>Contributor</div>
                    </div>
                    <div
                        class="mr-2 flex flex-col items-center justify-center rounded-full bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 dark:bg-primary-900 dark:text-blue-300"
                        *ngIf="
                            (af.activeAccount$ | async).teamMemberTypeId === 3
                        "
                    >
                        <div>Call Forward</div>
                    </div>
                </div>
                <div class="align-middle mt-2 flex flex-row">
                    <mat-icon
                        class="mr-3 icon-size-5"
                        [svgIcon]="'heroicons_outline:user'"
                        color="primary"
                        matTooltip="User name"
                    ></mat-icon>
                    <span class="line-clamp-2 text-sm">{{
                        (af.loggedInUser$ | async).user.person.fullName ??
                            'User name not set'
                    }}</span>
                </div>
                <div class="align-middle mt-2 flex flex-row">
                    <mat-icon
                        class="mr-3 icon-size-5"
                        [svgIcon]="'heroicons_outline:envelope'"
                        color="primary"
                        matTooltip="User email"
                    ></mat-icon>
                    <span class="line-clamp-2 text-sm">{{
                        (af.loggedInUser$ | async).user.person.email ??
                            'Email not set'
                    }}</span>
                </div>
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div
        class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <app-custom-switcher></app-custom-switcher>
        <a
            class="ml-5 mt-1 font-medium"
            href="https://eazimate-pty-ltd.helpscoutdocs.com/article/18-quick-start-guide#"
            target="_blank"
            >Quick Start Guide</a
        >
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <button
                *ngIf="
                    showIntrojs && !(this.introJsService.isReadOnly$ | async)
                "
                [matTooltip]="'Show tour again'"
                [disabled]="showTour"
                (click)="resetIntro()"
                mat-icon-button
            >
                <mat-icon [svgIcon]="'heroicons_outline:map'"></mat-icon>
            </button>
            <button
                *ngIf="
                    (af.unfinishedAccountSections$ | async).company === false ||
                    (af.unfinishedAccountSections$ | async).payment === false ||
                    (af.unfinishedAccountSections$ | async).address === false ||
                    (af.unfinishedAccountSections$ | async).abn === false
                "
                [matTooltip]="'Complete account setup'"
                routerLink="/settings/account"
                mat-icon-button
            >
                <mat-icon
                    class="text-red-600"
                    [svgIcon]="'feather:alert-triangle'"
                ></mat-icon>
            </button>
            <messages></messages>
            <account-invites
                *ngIf="
                    (af.invitedAccounts$ | async).length > 0 ||
                    (af.recentTeamUserRemovedFrom$ | async).length > 0
                "
            ></account-invites>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>
</div>
