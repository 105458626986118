import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { JobVariationDetailFacade } from 'app/views/jobs/store';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-job-variation-header',
  templateUrl: './job-variation-header.component.html',
  styleUrls: ['./job-variation-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputMaskModule],
})
export class JobVariationHeaderComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private facade: JobVariationDetailFacade,
  ) {
    this.variationTotalsForm = this.fb.group({
      name: ['', Validators.required],
      unitTotal: [''],
      quotableUnitTotal: [''],
      netQuoteAmount: [''],
      onCost: ['', { validators: [Validators.required, Validators.min(0)] }],
      profitAmount: ['', [Validators.required]],
      quoteAmount: ['', [Validators.required]],
    });
  }

  variationTotalsForm: FormGroup;
  private unsubscriber$ = new Subject<void>();

  onCostInputMask = createMask({
    alias: 'numeric',
    digits: 2,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0',
  });

  currencyInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    prefix: '$ ',
    placeholder: '0',
  });

  @Input() readonly: boolean = false;

  updateValue(formControlName: string) {
    const value = +this.variationTotalsForm
      .get(formControlName)
      .value.replace(/[^(\d|\\.)]/g, '');
    switch (formControlName) {
      case 'onCost':
        this.facade.updateOnCost(value);
        break;
      case 'profitAmount':
        this.facade.updateProfit(value);
        break;
      case 'quoteAmount':
        this.facade.updateCustomerQuote(value);
        break;
    }
  }

  ngOnInit(): void {
    this.facade.variation$
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((variation) => !!variation),
      )
      .subscribe((variation) => this.variationTotalsForm.patchValue(variation));
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
