import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

import { createAction, props } from '@ngrx/store';
import { RounterNavigationPayload } from '../models/router.models';

export enum RouterActionTypes {
  actionGO = '[Router] Go',
  actionBACK = '[Router] Back',
  actionFORWARD = '[Router] Forward',
}

export const go = createAction(
  RouterActionTypes.actionGO,
  props<{ payload: RounterNavigationPayload }>(),
);

export const back = createAction(RouterActionTypes.actionBACK);

export const forward = createAction(RouterActionTypes.actionFORWARD);
