import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseConfig, Scheme } from '@fuse/services/config';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '../../../../@fuse/services/config';

@Component({
  selector: 'app-custom-switcher',
  templateUrl: './custom-switcher.component.html',
  styleUrls: ['./custom-switcher.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule, AsyncPipe],
  providers: [AccountUserFacade, AsyncPipe],
})
export class CustomSwitcherComponent implements OnInit, OnDestroy {
  mode: Scheme;
  config: FuseConfig;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fuseConfigService: FuseConfigService,
    private async: AsyncPipe,
    private accountUserFacade: AccountUserFacade,
  ) {}

  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: FuseConfig) => {
        // console.log('config:::', config);
        // Store the config
        this.mode = config.scheme === 'auto' ? 'dark' : config.scheme;
      });
  }

  toggle(): void {
    this.mode = this.mode === 'light' ? 'dark' : 'light';
    console.log('toggle:::', this.mode);
    // this._fuseConfigService.config = { scheme: this.mode };
    this.accountUserFacade.setThemeMode(this.mode);
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
