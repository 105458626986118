import {
    AddTeamMember,
    AddUnitOfMeasure,
    IntIdName,
    SettingsTeamMemberAccessLevelUpdate,
    SettingsTeamMemberDelete,
    SettingsTeamMemberMergedRoles,
    SettingsTeamMemberModifyRoles,
    TeamMember,
    TeamMemberType,
    UnitOfMeasure,
    UnitOfMeasureParameters,
    UserRole,
} from 'app/shared/models/eazimate.models';
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BillingSettings } from 'app/shared/models';
import {
    AccountTeamMember,
    TeamMemberData,
} from 'app/shared/models/form-ui.models';

import moment from 'moment';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(private http: HttpClient) {}

    getBillingSettings(): Observable<BillingSettings> {
        const settings = {
            expiryDate: moment().add(12, 'month').toDate(),
        } as BillingSettings;
        return of(settings);
    }

    getTeam(): Observable<AccountTeamMember[]> {
        const url = `${environment.urls.api}/settings/team`;
        return this.http.get<AccountTeamMember[]>(url);
    }

    inviteTeamMember(payload: AddTeamMember): Observable<AccountTeamMember[]> {
        const url = `${environment.urls.api}/settings/team/member`;
        return this.http.post<AccountTeamMember[]>(url, payload);
    }

    getAvailableSeats(): Observable<number> {
        const url = `${environment.urls.api}/settings/team/availableseats`;
        return this.http.get<number>(url);
    }

    getExtraSeatsCount(): Observable<number> {
        const url = `${environment.urls.api}/settings/team/extraseats`;
        return this.http.get<number>(url);
    }

    removeTeamMember(
        payload: SettingsTeamMemberDelete
    ): Observable<AccountTeamMember[]> {
        const url = `${environment.urls.api}/settings/team/member/remove`;

        return this.http.post<AccountTeamMember[]>(url, payload);
    }

    updateTeamMemberRole(
        payload: TeamMemberData
    ): Observable<AccountTeamMember[]> {
        const url = `${environment.urls.api}/settings/team/member`;
        return this.http.put<AccountTeamMember[]>(url, payload);
    }

    listUnitsOfMeasure(payload: UnitOfMeasureParameters): Observable<any> {
        const url = `${environment.urls.api}/UnitOfMeasure/list`;
        return this.http.post<any>(url, payload);
    }

    addUnitOfMeasure(payload: AddUnitOfMeasure): Observable<UnitOfMeasure> {
        const url = `${environment.urls.api}/UnitOfMeasure/add`;
        return this.http.post<UnitOfMeasure>(url, payload);
    }

    updateUnitOfMeasure(payload: UnitOfMeasure): Observable<UnitOfMeasure> {
        const url = `${environment.urls.api}/UnitOfMeasure/save`;
        return this.http.put<UnitOfMeasure>(url, payload);
    }

    deleteUnitOfMeasure(id: string): Observable<UnitOfMeasure> {
        const url = `${environment.urls.api}/UnitOfMeasure/${id}`;
        return this.http.delete<UnitOfMeasure>(url);
    }

    getTeamMemberTypes(isPublic = true): Observable<any[]> {
        const url = isPublic
            ? `${environment.urls.api}/teammember/teammembertypes/public`
            : `${environment.urls.api}/teammember/teammembertypes`;

        return this.http.get<TeamMemberType[]>(url);
    }

    getRoles(isPublic = true): Observable<UserRole[]> {
        const url = isPublic
            ? `${environment.urls.api}/teammember/roles/public`
            : `${environment.urls.api}/teammember/roles`;
        return this.http.get<UserRole[]>(url);
    }

    getRolesAccessLevels(): Observable<IntIdName[]> {
        const url = `${environment.urls.api}/teammember/roles/accesslevels`;
        return this.http.get<IntIdName[]>(url);
    }

    getRolesByTeamMemberType(
        isPublic = true,
        teamMemberTypeId: number
    ): Observable<UserRole[]> {
        const url = isPublic
            ? `${environment.urls.api}/teammember/roles/${teamMemberTypeId}/public`
            : `${environment.urls.api}/teammember/roles/${teamMemberTypeId}`;
        return this.http.get<UserRole[]>(url);
    }

    getTeamMemberMergedRoles(
        payload: SettingsTeamMemberMergedRoles
    ): Observable<UserRole[]> {
        const url = `${environment.urls.api}/settings/team/member/roles/merged`;
        return this.http.post<UserRole[]>(url, payload);
    }

    updateTeamMemberAccessLevel(
        payload: SettingsTeamMemberAccessLevelUpdate
    ): Observable<TeamMember[]> {
        const url = `${environment.urls.api}/settings/team/member/type`;
        return this.http.put<TeamMember[]>(url, payload);
    }

    updateTeamMemberRoles(
        payload: SettingsTeamMemberModifyRoles
    ): Observable<TeamMember[]> {
        const url = `${environment.urls.api}/settings/team/member`;
        return this.http.put<TeamMember[]>(url, payload);
    }
}
