import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getJobHistoryList,
  getJobHistorySingle,
} from '../actions';
import { JobHistoryState } from '../reducers/job-history.reducer';
import {
  selectHistory,
  selectJobHistorySingle,
  selectJobHistoryError,
  selectJobHistoryIsLoaded,
  selectJobHistoryIsLoading
} from '../selectors';
import {
  JobHistoryParameters,
} from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class JobHistoryFacade {
  isLoading$ = this.store.select(selectJobHistoryIsLoading);
  isLoaded$ = this.store.select(selectJobHistoryIsLoaded);
  error$ = this.store.select(selectJobHistoryError);
  jobHistory$ = this.store.select(selectHistory);
  jobHistorySingle$ = this.store.select(selectJobHistorySingle);

  constructor(private store: Store<JobHistoryState>) {}

  getJobHistorySingle(id: string): void {
    this.store.dispatch(getJobHistorySingle({ id }));
  }
 
  getJobHistory(query: JobHistoryParameters): void {
    this.store.dispatch(getJobHistoryList({ query }));
  }
}
