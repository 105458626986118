<h2 mat-dialog-title class="text-sm sm:text-base">{{ title }}</h2>
<mat-dialog-content class="overflow-y-auto">
  <div class="form-wrapper" [formGroup]="itemForm">
    <!--  Description -->
    <div class="grid sm:grid-cols-6 gap-6 w-full mt-4">
      <div class="sm:col-span-6">
        <div class="flex items-center">
          <div class="mr-2">Description</div>
          <app-speach-to-text
            [isClearText]="true"
            formControlName="description"
          ></app-speach-to-text>
        </div>
        <mat-form-field class="w-full">
          <textarea
            (focusin)="inputOnFocus = true"
            (blur)="inputOnFocus = false"
            formControlName="description"
            matInput
            placeholder="Type description"
            [autocomplete]="'off'"
            maxLength="1024"
            required
          ></textarea>
          <mat-error *ngIf="checkRequired('description')"
            >Description required
          </mat-error>
          <mat-error *ngIf="checkEmptyStrings('description')"
            >No empty strings allowed
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Supplier List, supplier item code -->
    <div class="grid sm:grid-cols-6 gap-6 w-full mt-4">
      <mat-form-field
        class="sm:col-span-6"
        [ngClass]="{ 'pointer-events-none': data.currentSupplier }"
      >
        <mat-label>Preferred Supplier</mat-label>
        <mat-select
          formControlName="supplier"
          [compareWith]="compareSupplier"
          required
        >
          <mat-option
            [value]="supplier"
            *ngFor="let supplier of (facade.supplierList$ | async).items"
          >
            {{ supplier.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="checkRequired('supplier')"
          >Supplier required
        </mat-error>
      </mat-form-field>
      <!--      <div class="sm:col-span-3">-->
      <!--        <mat-form-field class="fuse-mat-no-subscript w-full">-->
      <!--          <mat-label>Supplier item code</mat-label>-->
      <!--          <input-->
      <!--            (focusin)="inputOnFocus=true" (blur)="inputOnFocus=false"-->
      <!--            type="text"-->
      <!--            matInput-->
      <!--            [autocomplete]="'off'"-->
      <!--            formControlName="supplierItemCode"-->
      <!--            maxLength="15"-->
      <!--          />-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
    </div>

    <!-- gst, price, gst -->
    <div class="grid sm:grid-cols-8 gap-6 w-full mt-4">
      <!--  Quantity -->
      <div class="sm:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>Qty</mat-label>
          <input
            (focusin)="inputOnFocus = true"
            (blur)="inputOnFocus = false"
            trim="blur"
            numbersOnly
            min="1"
            decimal="true"
            formControlName="qty"
            maxlength="50"
            matInput
            [autocomplete]="'off'"
            required
          />
          <mat-error *ngIf="checkRequired('qty')">Value required</mat-error>
          <mat-error *ngIf="checkMax('qty')"
            >Maximum allowed value is 999 000 000
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>UOM</mat-label>
          <mat-select
            panelClass="w-36"
            formControlName="unitOfMeasure"
            required
          >
            <mat-option
              [value]="uom.uom"
              *ngFor="let uom of generalFacade.unitsOfMeasures$ | async"
            >
              {{ uom.uom }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkRequired('unitOfMeasure')">
            Unit of measure required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>Unit price</mat-label>
          <input
            (focusin)="inputOnFocus = true"
            (blur)="inputOnFocus = false"
            [inputMask]="currencyInputMask"
            trim="blur"
            formControlName="unitPrice"
            matInput
            [autocomplete]="'off'"
            required
          />
          <mat-error *ngIf="checkRequired('unitPrice')"
            >Unit price required
          </mat-error>
          <mat-error *ngIf="checkMax('unitPrice')"
            >Maximum allowed price is 999 000 000
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-2">
        <mat-form-field class="fuse-mat-no-subscript w-full">
          <mat-label>GST</mat-label>
          <mat-slide-toggle color="primary" formControlName="gstRate">{{
            itemForm.get("gstRate").value ? "10% GST" : "0% GST"
          }}</mat-slide-toggle>
          <input matInput hidden />
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div class="flex flex-row justify-end">
  <button
    class="mr-2"
    type="submit"
    mat-flat-button
    [color]="'primary'"
    (click)="save()"
    [disabled]="!itemForm.valid || !itemForm.dirty"
  >
    Save
  </button>
  <button mat-stroked-button (click)="close()">Cancel</button>
</div>
<div
  class="tablet-buffer w-full"
  [class.h-52]="
    virtualKeyBoardOpened && inputOnFocus && isTabletLandscapeScreen
  "
></div>
