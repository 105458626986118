<div class="flex flex-row items-center py-4">
  <h2 class="p-0 mr-4 text-3xl">Manage - Original Quote Items</h2>
  <div class="table-search-wrap mr-auto ml-auto w-80 p-0 rounded-md">
    <div class="table-search">
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full">
        <mat-icon
          [svgIcon]="'heroicons_outline:magnifying-glass'"
          matPrefix
        ></mat-icon>
        <input
          [autocomplete]="'off'"
          [placeholder]="'Search anything...'"
          [formControl]="searchControl"
          matInput
        />
        <mat-icon
          *ngIf="searchControl.value"
          (click)="searchControl.patchValue('')"
          svgIcon="heroicons_outline:x-circle"
        ></mat-icon>
      </mat-form-field>
    </div>
  </div>

  <button
    class="text-xs rounded-xl ml-4 mr-4 min-h-8 h-8 px-4"
    [disabled]="!(hasChanges$ | async)"
    [color]="'primary'"
    (click)="save()"
    mat-flat-button
  >
    <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
    <span class="ml-2 mr-1">Save</span>
  </button>
  <button
    class="text-xs rounded-xl border border-solid border-gray-600 min-h-8 h-8 px-4"
    [color]="'accent'"
    (click)="close()"
    mat-flat-button
  >
    <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
    <span class="ml-2 mr-1">Cancel</span>
  </button>
</div>
<mat-dialog-content>
  <div
    class="content-wrapper flex sm:grid-cols-10 gap-3 w-full h-full max-h-full overflow-hidden"
  >
    <div class="flex w-1/4 overflow-hidden categories-wrap p-1.5 rounded">
      <div class="flex flex-col w-full">
        <div class="mb-2.5">Categories</div>
        <div class="flex flex-auto flex-col overflow-auto">
          <button
            class="w-full flex flex-row justify-start hover:bg-black rounded text-left px-1.5 border border-transparent border-solid h-7 min-h-7"
            *ngFor="let category of categories$ | async"
            [ngClass]="{
              active: (selectedCategoryId$ | async) === category.id
            }"
            (click)="selectCategory(category)"
            mat-button
            matTooltip="{{ category.code ?? '00' }} - {{ category.name }}"
            matTooltipPosition="right"
          >
            <div class="w-full truncate text-secondary">
              {{ category.code ?? "00" }} - {{ category.name }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex w-3/4 h-full overflow-hidden rounded border border-slate-200 pt-2.5"
    >
      <div class="flex flex-col w-full h-full max-h-full">
        <div
          class="sm:col-span-5 h-full overflow-hidden"
          *ngrxLet="priceListItems$; let items"
        >
          <div class="flex flex-col h-full">
            <div class="mb-2.5 pl-1">
              Actual Costing Items ({{ items.length }})
            </div>
            <div class="h-full overflow-auto">
              <table
                class="w-full"
                [dataSource]="items"
                [matSortActive]="(priceListSorting$ | async).sortField"
                [matSortDirection]="(priceListSorting$ | async).sortOrder"
                (matSortChange)="sortPriceList($event)"
                matSortDisableClear
                mat-table
                matSort
              >
                <ng-container matColumnDef="selected">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    <mat-checkbox
                      [disabled]="(priceListItems$ | async)?.length === 0"
                      [checked]="allPriceListItemsSelected$ | async"
                      (change)="selectAllPriceListItems($event)"
                    >
                    </mat-checkbox>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    <mat-checkbox
                      [checked]="itemSelected$(element.id) | async"
                      [disabled]="'disabled'"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="itemcode">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="itemCode"
                  >
                    Item Code
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.itemCode }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="description"
                  >
                    Description
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="supplier">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="supplier.name"
                  >
                    Supplier
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.supplier?.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="qty">
                  <th
                    class="w-10"
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="qty"
                  >
                    Qty
                  </th>
                  <td *matCellDef="let element" mat-cell>{{ element.qty }}</td>
                </ng-container>
                <ng-container matColumnDef="uom">
                  <th
                    class="w-10"
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="unitOfMeasure"
                  >
                    UOM
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitOfMeasure }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="unitPrice">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="unitPrice"
                  >
                    Unit price
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitPrice }}
                  </td>
                </ng-container>
                <tr
                  *matHeaderRowDef="displayedColumnsPriceList; sticky: true"
                  mat-header-row
                ></tr>
                <tr
                  class="cursor-pointer"
                  *matRowDef="
                    let row;
                    columns: displayedColumnsPriceList;
                    let i = index
                  "
                  (click)="toggleItemSelected(row)"
                  mat-row
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1 pt-4 flex flex-row justify-center">
          <button
            class="text-xs rounded-xl mr-12 min-h-8 h-8 px-4"
            [color]="'primary'"
            [disabled]="!(canAdd$ | async)"
            (click)="add()"
            mat-flat-button
          >
            <span class="ml-2 mr-1">Remove from Original Quote</span>
            <mat-icon
              class="text-primary"
              [svgIcon]="'heroicons_outline:arrow-down'"
            ></mat-icon>
          </button>
          <button
            class="text-xs rounded-xl min-h-8 h-8 px-4"
            [color]="'primary'"
            [disabled]="!(canRemove$ | async)"
            (click)="remove()"
            mat-flat-button
          >
            <mat-icon
              class="text-primary"
              [svgIcon]="'heroicons_outline:arrow-up'"
            ></mat-icon>
            <span class="ml-2 mr-1">Undo remove from Original Quote</span>
          </button>
        </div>
        <div
          class="sm:col-span-5 h-full overflow-hidden"
          *ngrxLet="estimateListItems$; let items"
        >
          <div class="flex flex-col h-full">
            <div class="mb-2.5 pl-1">Variation Items ({{ items.length }})</div>
            <div class="h-full overflow-auto">
              <table
                class="w-full"
                [dataSource]="items"
                [matSortActive]="(estimateListSorting$ | async).sortField"
                [matSortDirection]="(estimateListSorting$ | async).sortOrder"
                (matSortChange)="sortEstimateList($event)"
                matSortDisableClear
                mat-table
                matSort
              >
                <ng-container matColumnDef="selected">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    <mat-checkbox
                      [disabled]="(estimateListItems$ | async)?.length === 0"
                      [checked]="allEstimateListItemsSelected$ | async"
                      (change)="selectAllEstimateListItems($event)"
                    >
                    </mat-checkbox>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    <mat-checkbox
                      [checked]="itemSelected$(element.id) | async"
                      [disabled]="'disabled'"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="itemcode">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="itemCode"
                  >
                    Item Code
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.itemCode }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="description"
                  >
                    Description
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="supplier">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="supplier.name"
                  >
                    Supplier
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.supplier?.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="qty">
                  <th
                    class="w-10"
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="qty"
                  >
                    Qty
                  </th>
                  <td *matCellDef="let element" mat-cell>{{ element.qty }}</td>
                </ng-container>
                <ng-container matColumnDef="uom">
                  <th
                    class="w-10"
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="unitOfMeasure"
                  >
                    UOM
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitOfMeasure }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="unitPrice">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                    mat-sort-header="unitPrice"
                  >
                    Unit price
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitPrice }}
                  </td>
                </ng-container>
                <tr
                  *matHeaderRowDef="displayedColumnsEstimateList; sticky: true"
                  mat-header-row
                ></tr>
                <tr
                  class="cursor-pointer"
                  *matRowDef="
                    let row;
                    columns: displayedColumnsEstimateList;
                    let i = index
                  "
                  (click)="toggleItemSelected(row)"
                  mat-row
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
