import { EstimateDetailFacade } from './estimate-detail.facade';
import { EstimateListFacade } from './estimate-list.facade';
import { EstimateQuoteFacade } from './estimate-quote.facade';

export * from './estimate-detail.facade';
export * from './estimate-list.facade';
export * from './estimate-quote.facade';

export const facades = [
  EstimateListFacade,
  EstimateDetailFacade,
  EstimateQuoteFacade,
];
