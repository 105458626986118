import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private angularPlugin = new AngularPlugin();
  public appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.appInsights.connectionString,
      extensions: [this.angularPlugin],
      enableAutoRouteTracking: true,
      enableCorsCorrelation: false,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: false,
      correlationHeaderExcludedDomains: [
        '*.queue.core.windows.net',
        '*.googleapis.com',
      ],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          useInjector: true,
        },
      },
    },
  });

  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
  }
}
