/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BulkSoftDeleteResponse,
  JobFile,
  Photo,
  PaginatedListOfJob,
  JobPhoto,
} from 'app/shared/models';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(private http: HttpClient) {}

  createJobPhoto(photo: JobPhoto) {
    let url;
    if (photo.jobId) {
      url = `${environment.urls.api}/jobphoto/add`;
    } else {
      url = `${environment.urls.api}/add`;
    }
    return this.http.post<JobFile>(url, photo);
  }

  deleteJobPhotos(jobId, ids: string[]): Observable<BulkSoftDeleteResponse> {
    let url;
    if (jobId) {
      url = `${environment.urls.api}/jobphoto/bulk/delete`;
    } else {
      url = `${environment.urls.api}/bulk/delete`;
    }
    return this.http.delete<BulkSoftDeleteResponse>(url, { body: ids });
  }

  downloadMultiplePhotos(
    jobId: string,
    photos: Photo[],
  ): Observable<HttpResponse<Blob>> {
    let url;
    if (jobId) {
      url = `${environment.urls.api}/jobphoto/bulk/download`;
    } else {
      url = `${environment.urls.api}/bulk/download`;
    }
    return this.http
      .post(url, photos, {
        observe: 'response',
        responseType: 'blob' as 'blob',
      })
      .pipe(map((response: any) => response.body));
  }

  renameJobPhotosFolder(jobId: string, oldPath: string, newPath: string) {
    let url;
    let params: any = {};
    if (jobId) {
      url = `${environment.urls.api}/jobphoto/renamefolder`;
      params = { jobId, oldPath, newPath };
    } else {
      url = `${environment.urls.api}/renamefolder`;
      params = { oldPath, newPath };
    }
    return this.http.post<JobFile[]>(url, params);
  }

  getJobPhotos(jobId: string): Observable<Photo[]> {
    let url;
    let params: any = {};
    if (jobId) {
      url = `${environment.urls.api}/jobphoto/list`;
      params = { jobId, pageSize: 65536 };
    } else {
      url = `${environment.urls.api}/list`;
      params = { pageSize: 65536 };
    }
    const photos = this.http
      .post<PaginatedListOfJob>(url, params)
      .pipe(map((data: PaginatedListOfJob) => data.items));
    return photos;
  }

  photoFolderExists(jobId: string, path: string): Observable<boolean> {
    let url;
    let params: any = {};
    if (jobId) {
      url = `${environment.urls.api}/jobphoto/folderexists`;
      params = { jobId, path };
    } else {
      url = `${environment.urls.api}/folderexists`;
      params = { path };
    }
    return this.http
      .post(url, params)
      .pipe(map((response: any) => response.exists));
  }
}
