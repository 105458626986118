import { createAction, props } from '@ngrx/store';
import {
  BaseIdNameDesc,
  EstimatePriceListItemParameters,
} from 'app/shared/models';

export enum SuppliersListByJobActionTypes {
  GetSuppliersListByJob = '[SuppliersListByJob] Get SuppliersListByJob',
  GetSuppliersListByJobSuccess = '[SuppliersListByJob] Get SuppliersListByJob Success',
  GetSuppliersListByJobFailure = '[SuppliersListByJob] Get SuppliersListByJob Failure',
  GetUseCategories = '[UseCategories] Get UseCategories',
  GetUseCategoriesSuccess = '[UseCategories] Get UseCategories Success',
  GetUseCategoriesFailure = '[UseCategories] Get UseCategories Failure',
  UpdateUseCategories = '[UseCategories] Update UseCategories',
  UpdateUseCategoriesSuccess = '[UseCategories] Update UseCategories Success',
  UpdateUseCategoriesFailure = '[UseCategories] Update UseCategories Failure',
}

export const getSuppliersListByJob = createAction(
  SuppliersListByJobActionTypes.GetSuppliersListByJob,
  props<{ id: string }>(),
);

export const getSuppliersListByJobSuccess = createAction(
  SuppliersListByJobActionTypes.GetSuppliersListByJobSuccess,
  props<{ suppliersDesc: BaseIdNameDesc[] }>(),
);

export const getSuppliersListByJobFailure = createAction(
  SuppliersListByJobActionTypes.GetSuppliersListByJobFailure,
  props<{ error: any }>(),
);

export const getUseCategories = createAction(
  SuppliersListByJobActionTypes.GetUseCategories,
  props<{ id: string }>(),
);

export const getUseCategoriesSuccess = createAction(
  SuppliersListByJobActionTypes.GetUseCategoriesSuccess,
  props<{ useCategoriesState: any }>(),
);

export const getUseCategoriesFailure = createAction(
  SuppliersListByJobActionTypes.GetUseCategoriesFailure,
  props<{ error: any }>(),
);

export const updateUseCategories = createAction(
  SuppliersListByJobActionTypes.UpdateUseCategories,
  props<{ id: string; value: boolean }>(),
);

export const updateUseCategoriesSuccess = createAction(
  SuppliersListByJobActionTypes.UpdateUseCategoriesSuccess,
  props<{ useCategoriesState: any }>(),
);

export const updateUseCategoriesFailure = createAction(
  SuppliersListByJobActionTypes.UpdateUseCategoriesFailure,
  props<{ error: any }>(),
);
