import { AsyncPipe, NgIf, NgFor } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  RouterLinkActive,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { SendModalComponent } from 'app/shared/components';
import {
  LoggedInUser,
  SendQuote,
  SingleSaveEstimate,
  SnippetArea,
  SnippetType,
} from 'app/shared/models';
import { SendModalData } from 'app/shared/models/form-ui.models';
import { LeavePageService } from 'app/shared/services/leave-page.service';
import { ToastService } from 'app/shared/services/toast.service';
import { AccountUserActionTypes } from 'app/shared/store/actions/account-user.actions';
import { AccountUserFacade } from 'app/shared/store/facades';
import { EstimateService } from 'app/views/estimates/services/estimate.service';
import { QuoteFormService } from 'app/views/estimates/services/quote-form.service';
import {
  EstimateDetailFacade,
  EstimateQuoteActionTypes,
  EstimateQuoteFacade,
} from 'app/views/estimates/store';
import { JobService } from 'app/views/jobs/services/job.service';
import { JobDetailFacade } from 'app/views/jobs/store';
import { Subject } from 'rxjs/internal/Subject';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SnippetsService } from '@app/views/snippets/services/snippets.service';

enum QuoteAction {
  Print,
  Refresh,
  Send,
}

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    NgFor,
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
    NgxExtendedPdfViewerModule,
    AsyncPipe,
  ],
})
export class QuoteComponent implements OnInit, OnDestroy {
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];
  unsubscribeAll$ = new Subject<void>();
  unsubscribeFormChanges$ = new Subject<void>();
  menuItems = [
    {
      route: 'settings',
      text: 'Settings',
    },
    {
      route: 'details',
      text: 'Details',
    },
    {
      route: 'quote-introduction',
      text: 'Quote introduction',
    },
    {
      route: 'terms',
      text: 'Terms and conditions',
    },
    {
      route: 'footer',
      text: 'Quote instructions',
    },
  ];
  estimate: SingleSaveEstimate;
  account: LoggedInUser;
  pdfData: {
    generatedFile?: any;
    fileName?: string;
  } = {};

  constructor(
    public estimateQuoteFacade: EstimateQuoteFacade,
    private quoteFormService: QuoteFormService,
    private accountUserFacade: AccountUserFacade,
    private jobDetailFacade: JobDetailFacade,
    private asyncPipe: AsyncPipe,
    private actionsSubj: ActionsSubject,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public leavePageService: LeavePageService,
    public jobService: JobService,
    private estimateService: EstimateService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private confirm: FuseConfirmationService,
    public estimateDetailFacade: EstimateDetailFacade,
    private snippetService: SnippetsService
  ) {}

  ngOnInit(): void {
    this.quoteFormService.isReadOnlyMode = this.isReadOnlyMode;
    this.estimateDetailFacade.estimate$
      .pipe(filter(Boolean), takeUntil(this.unsubscribeAll$))
      .subscribe((estimate) => {
        this.quoteFormService.reset();
        this.estimate = estimate;
        this.updateForm(this.estimate);
        this.preview(this.estimate.id);
        this.leavePageService.needSave = false;
      });

    this.actionsSubj
      .pipe(
        ofType(AccountUserActionTypes._uploadQuoteLogoSuccess),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe((res) => {
        this.quoteFormService.quoteForm
          .get('logo')
          .setValue(res['fileLocation'], {emitEvent: false});
        this.cdRef.detectChanges();
      });

    this.accountUserFacade.loggedInUser$
      .pipe(take(1))
      .subscribe((res) => (this.account = res));

    this.leavePageService.dialog = {
      title: 'Leave Page?',
      message: 'You have unsaved changes.',
      icon: {
        name: 'heroicons_outline:exclamation-triangle',
        color: 'warn',
      },
      actions: {
        cancel: { label: 'Cancel' },
        confirm: { label: 'Leave Page', color: 'warn' },
      },
    };

    this.quoteFormService.quoteForm.valueChanges
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(() => {
        this.leavePageService.needSave = true;
        this.cdRef.detectChanges();
      });
  }

  updateForm(estimateFull) {
    if (this.quoteFormService.formUpdated$.value) {
      return;
    }
    this.quoteFormService.buildForm(estimateFull);
    const accountSettings = this.asyncPipe.transform(
      this.accountUserFacade.accountFormModel$,
    );

    if (!this.quoteFormService.quoteForm.get('logo').value) {
      this.quoteFormService.quoteForm
        .get('logo')
        .setValue(accountSettings.logo);
    }

    if (!this.quoteFormService.quoteForm.get('validDays').value) {
      this.quoteFormService.quoteForm
        .get('validDays')
        .setValue(accountSettings.quoteTerms);
    }
    if (!this.quoteFormService.quoteForm.get('sent').value) {
      this.quoteFormService.quoteForm
        .get('sent')
        .setValue(this.estimate.created);
    }
  }

  ngOnDestroy(): void {
    const isFormDestroy = true;
    this.quoteFormService.isReadOnlyMode = false;
    this.quoteFormService.reset(isFormDestroy);
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
    this.unsubscribeFormChanges$.next();
    this.unsubscribeFormChanges$.complete();
  }

  sendModal(): void {
    if (this.leavePageService.needSave) {
      this.saveBeforeAction(QuoteAction.Send);
      return;
    }
    const snippetsParams = {
      snippetArea: SnippetArea.Email_Body,
      snippetType: SnippetType.Quote,
    }
    const snippetContent$ = this.snippetService.getSnippetDefaultByTypeArea(snippetsParams);
    const data: SendModalData = {
      title: 'Send Quote',
      from: this.account.account.email,
      message: `<p>Hi ${
        this.asyncPipe.transform(this.jobDetailFacade.job$).customer.person
          .fullName
      },</p> <br> <p>Please find attached your quote.</p> <br> <p>Any questions please let us know.</p> <br> <p>Thanks</p> <p>${
        this.account.user.person.firstName
      } ${this.account.user.person.lastName}</p> <p>${
        this.account.account.name
      }</p>`,
      subject: `Quote ${this.asyncPipe.transform(this.jobDetailFacade.job$)
        ?.estimateCode} from ${
        this.account.account.name
      } for ${this.asyncPipe.transform(this.jobDetailFacade.job$)?.jobTitle}`,
      to: this.asyncPipe.transform(this.jobDetailFacade.job$)?.customer.person
        .email
        ? [
            this.asyncPipe.transform(this.jobDetailFacade.job$)?.customer.person
              .email,
          ]
        : null,
      quillOptions: {
        snippetsParams,
        snippetContent$
      },
      jobId: this.route.snapshot.parent.parent.params.id,
    };

    const dialogRef = this.dialog.open(SendModalComponent, {
      width: '720px',
      maxHeight: '70vh',
      data: data,
      panelClass: 'app-send-modal',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const request: SendQuote = {
          entityId: this.estimate.id,
          emailTo: result.to.join(';'),
          message: result.message,
          subject: result.subject,
          sendCopy: !!result.sendMe,
          attachments: result.attachments ? result.attachments : null,
        };
        this.estimateService.sendQuote(request).subscribe(
          () => {
            this.toast.success('Sent successfully');
            this.estimateDetailFacade.updateEstimateStatus({
              id: this.estimate.id,
              type: 'quote',
            });
          },
          () => {
            this.toast.error('Sending the quote failed');
          },
        );
      }
    });
  }

  update() {
    if (
      this.quoteFormService.quoteForm.invalid ||
      this.quoteFormService.quoteSectionsForm.invalid
    ) {
      this.quoteFormService.quoteForm.markAllAsTouched();
      this.quoteFormService.quoteForm.markAsDirty();

      this.quoteFormService.quoteSectionsForm.markAllAsTouched();
      this.quoteFormService.quoteSectionsForm.markAsDirty();
      return;
    }

    const estimate = {
      ...this.estimate,
      quote: this.quoteFormService.quoteForm.value,
    };

    this.estimateDetailFacade.updateEstimate(estimate);
    this.estimateDetailFacade.save();
  }

  preview(estimateId): void {
    this.jobService.previewEstimateQuote(estimateId).subscribe(
      (r: any) => {
        this.pdfData.generatedFile = r;
        this.pdfData.fileName = this.pdfFileName;
        this.cdRef.detectChanges();
      },
      () => {},
    );
  }

  print() {
    if (this.leavePageService.needSave) {
      this.saveBeforeAction(QuoteAction.Print);
      return;
    }
    const source = `data:application/pdf;base64,${this.pdfData.generatedFile}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = this.pdfData.fileName;
    link.click();
    link.remove();
  }

  refreshPdf() {
    if (this.leavePageService.needSave) {
      this.saveBeforeAction(QuoteAction.Refresh);
      return;
    }
    this.preview(this.estimate.id);
  }

  saveBeforeAction(action?: QuoteAction) {
    this.update();
    this.actionsSubj
      .pipe(
        ofType(EstimateQuoteActionTypes.UpdateEstimateQuoteSuccess),
        take(1),
      )
      .subscribe(() => {
        switch (action) {
          case QuoteAction.Print:
            this.jobService.previewEstimateQuote(this.estimate.id).subscribe(
              (r: any) => {
                this.pdfData.generatedFile = r;
                this.pdfData.fileName = this.pdfFileName;
                this.cdRef.detectChanges();
                this.print();
              },
              () => {},
            );
            break;
          case QuoteAction.Refresh:
            this.preview(this.estimate.id);
            break;
          case QuoteAction.Send:
            this.sendModal();
            break;
        }
      });
  }

  cancel() {
    this.confirm
      .open({
        title: 'Discard Changes',
        message:
          'Are you sure you want to discard the changes? All changes will be lost.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Discard', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result) => result === 'confirmed'))
      .subscribe(() => {
        this.estimateDetailFacade.reset();
      });
  }

  get pdfFileName() {
    return `Estimate ${
      this.asyncPipe.transform(this.jobDetailFacade.job$).estimateCode
    } - ${this.asyncPipe.transform(this.jobDetailFacade.job$).jobTitle} - ${
      new Date().toISOString().split('T')[0]
    }.pdf`;
  }
}
