import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum ToastLevel {
  Success,
  Info,
  Warn,
  Error,
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private snackbar: MatSnackBar) {}

  success(message: string): void {
    this.toast(message, ToastLevel.Success);
  }

  info(message: string): void {
    this.toast(message, ToastLevel.Info);
  }

  warn(message: string): void {
    this.toast(message, ToastLevel.Warn);
  }

  error(message: string): void {
    this.toast(message, ToastLevel.Error);
  }

  private toast(message: string, level: ToastLevel): void {
    const panelClass = `toast-${ToastLevel[level].toLowerCase()}`;
    console.log(panelClass);
    this.snackbar.open(message, 'OK', {
      panelClass: [panelClass],
      duration: 5000,
    });
  }
}
