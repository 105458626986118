/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FileDownloadFacade } from 'app/shared/store/facades/file-download.facade';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Photo } from 'app/shared/models/eazimate.models';
import { AsyncPipe, NgIf, NgClass, NgFor } from '@angular/common';
import { PhotosFacade } from '../../store';
import { ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-photos-photo-list',
  templateUrl: './photos-photo-list.component.html',
  styleUrls: ['./photos-photo-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    NgClass,
    MatTooltipModule,
    NgFor,
    MatButtonModule,
    AsyncPipe,
  ],
  providers: [AsyncPipe],
})
export class PhotosPhotoListComponent implements OnInit, OnDestroy {
  @Input() photos: any;
  @Input() isReadOnlyMode: boolean = false;

  @ViewChild('file') file;

  jobId: string;
  currentPath: string;
  showAllSelectors: boolean;
  anySelected: boolean;
  allSelected: boolean;

  private unsubscriber$ = new Subject<void>();

  constructor(
    private facade: PhotosFacade,
    private fileDownloadFacade: FileDownloadFacade,
    private route: ActivatedRoute,
    private async: AsyncPipe,
  ) {}

  toggleSelectAll(): void {
    const fileIds = this.photos.map((p) => p.id);

    if (this.allSelected) {
      this.facade.deselectFileIds(fileIds);
    } else {
      this.facade.selectFileIds(fileIds);
    }
  }

  toggleSelected(id): void {
    this.facade.toggleFileSelected(id);
  }

  setDetail(index: number): void {
    this.facade.setDetailPhotoIndex(index);
  }

  uploadFiles(): void {
    this.file.nativeElement.click();
  }

  downloadSelected(): void {
    const selectedPhotos = this.photos.filter((f) => f.selected);
    if (selectedPhotos.length === 1) {
      const sas = this.async.transform(this.facade.sasToken$);
      const photoUrl = selectedPhotos[0].url + '?' + sas;
      this.fileDownloadFacade.downloadFile(photoUrl, selectedPhotos[0].name);
    } else {
      this.facade.downloadMultiplePhotos(this.jobId, selectedPhotos);
    }
  }

  deleteSelected(): void {
    const ids = this.photos.filter((f) => f.selected).map((f) => f.id);
    this.facade.deletePhotos(this.jobId, ids);
  }

  onFilesAdded(): void {
    this.facade.uploadPhotos(
      this.jobId,
      this.currentPath,
      this.file.nativeElement.files,
    );
    this.file.nativeElement.value = '';
  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.parent.parent.params['id'];

    // this.facade.jobId$
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe((jobId) => (this.jobId = jobId));
    this.facade.currentFolder$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((folder) => (this.currentPath = folder?.path));

    this.facade.selectedJobPhotoIds$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((ids) => {
        this.showAllSelectors = ids.length > 0;
        this.allSelected = ids.length && ids.length === this.photos.length;
        this.anySelected = ids.length > 0;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
