import { takeUntil } from 'rxjs';
/* eslint-disable no-bitwise */
/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Observable, Subject } from 'rxjs';
import { TeamMember } from 'app/shared/models';
import { DateTime } from 'luxon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-new-invite-dialog',
  templateUrl: './new-invite-dialog.component.html',
  styleUrls: ['./new-invite-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatDialogTitle,
    MatDialogContent,
    NgFor,
    MatIconModule,
    MatTooltipModule,
    AsyncPipe,
  ],
})
export class NewInviteDialogComponent implements OnInit, OnDestroy {
  public roles = [
    {
      label: 'Owner',
      value: 'Owner',
      description: 'Owner desription',
    },
    {
      label: 'Contributor',
      value: 'Contributor',
      description: 'Contributor description',
    },
  ];

  unsubscriber$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<NewInviteDialogComponent>,
    public af: AccountUserFacade,
  ) {}

  getCapitalLetters(name: string): string {
    if (name == null) {
      return '';
    }
    if (name.indexOf(' ') > 0) {
      const splitName = name.split(' ');
      return splitName[0].charAt(0) + splitName[1].charAt(0);
    }
    return name.charAt(0);
  }

  getStyle(email: string, member: any): string {
    const color = this.generateColor(email);

    const style = `display: inline-block;
        font-size: 1em;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        text-align: center;
        border-radius: 50%;
        background: ${color};
        vertical-align: middle;
        margin-right: 1em;
        color: white;`;

    return style;
  }

  generateColor(str: string): string {
    if (str == null) {
      return '#fff';
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  trackByFn(index: number, item: TeamMember): any {
    return item.id || index;
  }

  rejectInvite(tm: TeamMember): void {
    const teamMember = JSON.parse(JSON.stringify(tm));
    teamMember.rejectedDate = DateTime.now().setLocale('en-AU');
    teamMember.showNotification = false;
    this.af.modifyTeamMemberInvite(teamMember);
  }

  acceptInvite(tm: TeamMember): void {
    const teamMember = JSON.parse(JSON.stringify(tm));
    this.af.acceptTeamMemberInvite({
      id: teamMember.id,
    });
  }

  ngOnInit(): void {
    this.af.accountsToDisplayDialogFor$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((x) => {
        if (x.length === 0) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
