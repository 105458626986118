import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
})
export class CheckBoxComponent implements OnInit {
  form = this.fb.group({
    checked: [false],
  });

  get checked() {
    return this.form.get('checked');
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CheckBoxComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { label: string; checked: boolean; title?: string },
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.checked.setValue(this.data.checked);
    }
  }

  save(): void {
    this.dialogRef.close(this.form.value.checked);
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }
}
