import { MappedRoutes, getKeyByValue } from './../models/role-url.model';
/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AccountUserFacade } from '../store/facades';
import { Role } from '../models/eazimate.models';

@Injectable({
  providedIn: 'root',
})
export class DefaultPageGuard implements CanActivate {
  private url = '/dashboard';

  constructor(
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private facade: AccountUserFacade,
  ) {}

  navigateDefaultLocation$ = (url: string): Observable<boolean> => {
    return this.facade.roles$.pipe(
      take(1),
      filter((roles) => !!roles),
      map((roles: Role[]) => {
        if (url === '/dashboard') {
          return true;
        }
        if (
          roles.length === 1 &&
          roles[0].name.toLocaleLowerCase() === 'owner'
        ) {
          return true;
        }

        // find name of role
        const roleName = MappedRoutes[this.url];
        const filteredRoles = roles.filter((r) => r.name === roleName);

        if (filteredRoles.length > 0) {
          return true;
        }

        if (filteredRoles.length === 0) {
          // redirect to first page
          const firstRoleName = roles[1].name;
          const path = getKeyByValue(MappedRoutes, firstRoleName);
          this.router.navigate(['/', path]);
          return true;
        }

        return true;
      }),
    );
  };

  checkData = (url: string): Observable<boolean> =>
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap((_) => this.navigateDefaultLocation$(url)),
    );

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.url = route['url'].toString();
    return this.checkData(state.url);
  }
}
