import {
  AsyncPipe,
  NgIf,
  NgFor,
  CurrencyPipe,
  DatePipe,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable as MatTable,
  MatTableDataSource as MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { JobOrderParameters, OrderStatus } from 'app/shared/models';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  JobOrderListActionTypes,
  JobOrdersListFacade,
  JobOrderSummaryFacade,
  SuppliersListByJobFacade,
} from '../../store';
import { AccountUserFacade } from 'app/shared/store/facades';
import { uniqBy } from 'lodash-es';
import {
  GuidedTourNameEnum,
  IntroJsService,
} from '../../../../shared/services/introjs.service';
import { GuidedTourService } from '../../../../shared/services/guided-tour.service';
import { FuseCardComponent } from '../../../../../@fuse/components/card/card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { JobOrderSummaryComponent } from '../job-order-summary/job-order-summary.component';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'app-job-orders',
  templateUrl: './job-orders.component.html',
  styleUrls: ['./job-orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    JobOrderSummaryComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
    MatTableModule,
    MatSortModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    FuseCardComponent,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
  ],
})
export class JobOrdersComponent implements OnInit, OnDestroy {
  @Output() addRemoveItems = new EventEmitter();
  @Output() addTemplateItem = new EventEmitter();
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];

  private unsubscribeAll: Subject<any> = new Subject<any>();

  displayedColumns: string[] = [
    'orderCode',
    'supplier',
    'priceListCategoryName',
    'calledDate',
    'callForDate',
    'acknowledgedDate',
    'amount',
    'status',
    'sent',
    'action',
  ];
  dataSource;

  statusOptions = [
    {
      name: 'Awaiting Delivery',
      value: OrderStatus.Awaiting_Delivery,
    },
    {
      name: 'Draft',
      value: OrderStatus.Draft,
    },
    {
      name: 'Received',
      value: OrderStatus.Received,
    },
    // {
    //   name: "Deleted",
    //   value: OrderStatus.Void
    // }
  ];
  syncEnabled = false;

  public query: JobOrderParameters = {
    pageNumber: 1,
    pageSize: 9999,
    orderBy: [{ orderBy: 'orderCode', descending: false }],
  };

  searchForm: FormGroup = this.fb.group({
    status: [0],
    supplier: [0],
  });
  expandedSummary = false;
  suppliersList;
  fullSuppliersList;
  intro;

  get status(): FormGroup {
    return this.searchForm.get('status') as FormGroup;
  }
  get supplier(): FormGroup {
    return this.searchForm.get('supplier') as FormGroup;
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;

  constructor(
    public jobOrdersListFacade: JobOrdersListFacade,
    public route: ActivatedRoute,
    public router: Router,
    public fb: FormBuilder,
    public jobOrderSummaryFacade: JobOrderSummaryFacade,
    private confirm: FuseConfirmationService,
    private actionsSubj: ActionsSubject,
    public suppliersListByJobFacade: SuppliersListByJobFacade,
    private asyncPipe: AsyncPipe,
    private jobService: JobService,
    private accountUserFacade: AccountUserFacade,
    private introJsService: IntroJsService,
    private guidedTourService: GuidedTourService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.route.parent.params.subscribe((params) => {
      this.query.jobId = params.id;

      this.jobOrderSummaryFacade.getJobsOrderSummary(this.query.jobId);
      this.jobOrdersListFacade.getJobsOrderList(this.query);
      this.jobOrdersListFacade.jobOrders$
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((res) => {
          this.suppliersListByJobFacade.getSuppliersListByJob(this.query.jobId);
          if (
            res.length &&
            !this.fullSuppliersList &&
            !this.status.value &&
            !this.supplier.value
          ) {
            this.fullSuppliersList = uniqBy(
              res
                .map((order) => order.supplier)
                .map((s) => ({ id: s.id, name: s.name })),
              (s) => s.id,
            );
          }
          if (this.status.value  && res.length) {
            this.suppliersList = uniqBy(
              res
                .map((order) => order.supplier)
                .map((s) => ({ id: s.id, name: s.name })),
              (s) => s.id,
            );
          } else {
            this.suppliersList = this.fullSuppliersList;
          }

          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'supplier':
                return item.supplier.name;
              default:
                return item[property];
            }
          };
          this.dataSource.sort = this.sort;

          this.introJsService.resetedTour$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
              this.introInit();
            });
          this.guidedTourService
            .getGuidedTourShow(btoa(GuidedTourNameEnum.JobOrderList))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((show) => {
              if (show) {
                this.introInit();
              }
            });
        });
    });

    this.actionsSubj
      .pipe(
        ofType(JobOrderListActionTypes.JobOrderDeleteFacadeSuccess),
        takeUntil(this.unsubscribeAll),
      )
      .subscribe(() => {
        this.jobOrderSummaryFacade.getJobsOrderSummary(this.query.jobId);
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  ngOnInit(): void {
    this.introJsService.currentIntroPage$.next(GuidedTourNameEnum.JobOrderList);

    this.status.valueChanges
      .pipe(takeUntil(this.unsubscribeAll), debounceTime(1000))
      .subscribe((value) => {
        this.query = {
          ...this.query,
        };
        this.query['pageNumber'] = 1;
        if (value) {
          this.query['status'] = value;
        } else {
          delete this.query['status'];
        }

        this.jobOrdersListFacade.getJobsOrderList(this.query);
      });
    this.supplier.valueChanges
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((value) => {

        this.query = {
          ...this.query,
        };
        this.query['pageNumber'] = 1;
        if (value) {
          this.query['supplier'] = {
            id: value,
            name: this.suppliersList.find((s) => s.id === value).name,
          };
        } else {
          delete this.query['supplier'];
        }
        this.jobOrdersListFacade.getJobsOrderList(this.query);
      });

    //get the status of the accounting integration
    this.accountUserFacade.loggedInUser$.subscribe((loggedInUser) => {
      this.syncEnabled = loggedInUser.accountingIntegrationActive;
      //if we're enabled then add the column to the displayedColumns list
      if (this.syncEnabled) {
        this.displayedColumns.push('integrationStatus');
      }
    });
  }

  introInit(): void {
    this.intro = this.introJsService.getIntro();
    this.intro.onexit(() => {
      this.guidedTourService
        .completeGuidedTour(btoa(GuidedTourNameEnum.JobOrderList))
        .subscribe();
      this.intro.setOptions({});
      this.intro = null;
    });
    this.cdRef.detectChanges();
    this.introJsService.jobOrderList(this.intro, this.isReadOnlyMode);
  }

  deleteOrder(order) {
    this.confirm
      .open({
        title: 'Delete order',
        message:
          'Are you sure you want to delete this order? This action cannot be undone.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Delete', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result) => result === 'confirmed'))
      .subscribe(() => this.jobOrdersListFacade.deleteJobsOrder(order.id));
  }

  details(url, params) {
    this.router.navigate([url, params]);
  }

  createOrder(supplier) {
    if (this.isReadOnlyMode) return;
    this.jobService
      .jobOrderBuildJobSupplier(this.query.jobId, supplier.supplierId)
      .subscribe(() => {
        this.jobOrdersListFacade.getJobsOrderList(this.query);
        this.jobOrderSummaryFacade.getJobsOrderSummary(this.query.jobId);
        this.expandedSummary = true;
      });
  }
}
