/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import {
  Estimate,
  EstimateParameters,
  PaginatedListOfEstimate,
} from 'app/shared/models';

export enum EstimateListActionTypes {
  GetEstimates = '[Estimate List] Get Estimates',
  GetEstimatesSuccess = '[Estimate List] Get Estimates Success',
  GetEstimatesFailure = '[Estimate List] Get Estimates Failure',

  CloneEstimate = '[Estimate List] Clone Estimates',
  CloneEstimateSuccess = '[Estimate List] Clone Estimates Success',
  CloneEstimateFailure = '[Estimate List] Clone Estimates Failure',

  AcceptEstimate = '[Estimate List] Accept Estimates',
  AcceptEstimateSuccess = '[Estimate List] Accept Estimates Success',
  AcceptEstimateFailure = '[Estimate List] Accept Estimates Failure',

  _getEstimatesHistory = '[Estimate List] Get Estimate History',
  _getEstimatesHistorySuccess = '[Estimate List API] Get Estimate History Success',
  _getEstimatesHistoryFailure = '[Estimate List API] Get Estimate History History',
}

export const getEstimatesHistory = createAction(
  EstimateListActionTypes._getEstimatesHistory,
  props<{ request: EstimateParameters }>(),
);

export const getEstimatesHistorySuccess = createAction(
  EstimateListActionTypes._getEstimatesHistorySuccess,
  props<{ estimates: PaginatedListOfEstimate }>(),
);

export const getEstimatesHistoryFailure = createAction(
  EstimateListActionTypes._getEstimatesHistoryFailure,
  props<{ error: any }>(),
);

export const getEstimates = createAction(
  EstimateListActionTypes.GetEstimates,
  props<{ request: EstimateParameters }>(),
);

export const getEstimatesSuccess = createAction(
  EstimateListActionTypes.GetEstimatesSuccess,
  props<{ estimates: PaginatedListOfEstimate }>(),
);

export const getEstimatesFailure = createAction(
  EstimateListActionTypes.GetEstimatesFailure,
  props<{ error: any }>(),
);

// clone
export const cloneEstimate = createAction(
  EstimateListActionTypes.CloneEstimate,
  props<{ id: string }>(),
);

export const cloneEstimateSuccess = createAction(
  EstimateListActionTypes.CloneEstimateSuccess,
  props<{ estimate: Estimate }>(),
);

export const cloneEstimateFailure = createAction(
  EstimateListActionTypes.CloneEstimateFailure,
  props<{ error: any }>(),
);

// accept
export const acceptEstimate = createAction(
  EstimateListActionTypes.AcceptEstimate,
  props<{ id: string }>(),
);

export const acceptEstimateSuccess = createAction(
  EstimateListActionTypes.AcceptEstimateSuccess,
  props<{ estimate: Estimate }>(),
);

export const acceptEstimateFailure = createAction(
  EstimateListActionTypes.AcceptEstimateFailure,
  props<{ error: any }>(),
);
