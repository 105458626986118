import { createSelector } from '@ngrx/store';
import { selectJobState } from '../reducers';
import { filterAndSortItems } from '../reducers/job-variation-add-remove-costing.reducer';

export const selectJobVariationAddRemoveCostingState = createSelector(
  selectJobState,
  (s) => s.variationAddRemoveCosting,
);

export const selectJobVariationAddRemoveCostingVariation = createSelector(
  selectJobVariationAddRemoveCostingState,
  (s) => s.variation,
);

export const selectJobVariationAddRemoveCostingSection = createSelector(
  selectJobVariationAddRemoveCostingState,
  (s) => s.section,
);

export const selectedJobVariationAddRemoveCostingSelectedItemIds =
  createSelector(
    selectJobVariationAddRemoveCostingState,
    (s) => s.selectedItemIds,
  );

export const selectJobVariationAddRemoveCostingPriceListItems = createSelector(
  selectJobVariationAddRemoveCostingState,
  (s) =>
    filterAndSortItems(
      s.priceListItems,
      s.selectedCategoryId,
      s.filter,
      s.priceListSorting.sortField,
      s.priceListSorting.sortOrder,
    ),
);

export const selectSelectedJobVariationAddRemoveCostingPriceListItems =
  createSelector(
    selectJobVariationAddRemoveCostingPriceListItems,
    selectedJobVariationAddRemoveCostingSelectedItemIds,
    (items, ids) => items.filter((item) => ids.includes(item.id)),
  );

export const selectJobVariationAddRemoveCostingAllPriceListItemsSelected =
  createSelector(
    selectJobVariationAddRemoveCostingPriceListItems,
    selectSelectedJobVariationAddRemoveCostingPriceListItems,
    (all, selected) => selected.length > 0 && all.length === selected.length,
  );

export const selectJobVariationAddRemoveCostingCanAdd = createSelector(
  selectSelectedJobVariationAddRemoveCostingPriceListItems,
  (items) => items.length > 0,
);

export const selectJobVariationAddRemoveCostingEstimateListItems =
  createSelector(selectJobVariationAddRemoveCostingState, (s) =>
    filterAndSortItems(
      s.estimateListItems,
      s.selectedCategoryId,
      s.filter,
      s.estimateListSorting.sortField,
      s.estimateListSorting.sortOrder,
    ),
  );

export const selectSelectedJobVariationAddRemoveCostingEstimateListItems =
  createSelector(
    selectJobVariationAddRemoveCostingEstimateListItems,
    selectedJobVariationAddRemoveCostingSelectedItemIds,
    (items, ids) => items.filter((item) => ids.includes(item.id)),
  );

export const selectJobVariationAddRemoveCostingCanRemove = createSelector(
  selectSelectedJobVariationAddRemoveCostingEstimateListItems,
  (items) => items.length > 0,
);

export const selectJobVariationAddRemoveCostingAllEstimateListItemsSelected =
  createSelector(
    selectJobVariationAddRemoveCostingEstimateListItems,
    selectSelectedJobVariationAddRemoveCostingEstimateListItems,
    (all, selected) => selected.length > 0 && all.length === selected.length,
  );
export const selectJobVariationAddRemoveCostingSelectedCategoryId =
  createSelector(
    selectJobVariationAddRemoveCostingState,
    (s) => s.selectedCategoryId,
  );

export const selectJobVariationAddRemoveCostingCategories = createSelector(
  selectJobVariationAddRemoveCostingState,
  (s) => s.categories,
);

export const selectJobVariationAddRemoveCostingAddedItems = createSelector(
  selectJobVariationAddRemoveCostingState,
  (s) => {
    const originalEstimateListItemIds = s.originalEstimateListItems.map(
      (i) => i.id,
    );
    const items = s.estimateListItems.filter(
      (i) => !originalEstimateListItemIds.includes(i.id),
    );
    return items;
  },
);

export const selectJobVariationAddRemoveCostingRemovedItems = createSelector(
  selectJobVariationAddRemoveCostingState,
  (s) => {
    const originalPriceListItemIds = s.originalPriceListItems.map((i) => i.id);
    const items = s.priceListItems.filter(
      (i) => !originalPriceListItemIds.includes(i.id),
    );
    return items;
  },
);

export const selectJobVariationAddRemoveCostingPriceListSorting =
  createSelector(
    selectJobVariationAddRemoveCostingState,
    (s) => s.priceListSorting,
  );

export const selectJobVariationAddRemoveCostingEstimateListSorting =
  createSelector(
    selectJobVariationAddRemoveCostingState,
    (s) => s.estimateListSorting,
  );

export const selectJobVariationIsItemSelected = (id: string) =>
  createSelector(selectJobVariationAddRemoveCostingState, (s) => {
    return s.selectedItemIds.includes(id);
  });
