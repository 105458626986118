import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgIf, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-totals-header',
  templateUrl: './totals-header.component.html',
  styleUrls: ['./totals-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, CurrencyPipe],
})
export class TotalsHeaderComponent implements OnInit, OnDestroy {
  @Input()
  totals = undefined;

  @Input()
  isLoaded = false;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
