/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountUserRole } from './accountuser.types';
import { LoggedInUser } from 'app/shared/models/eazimate.models';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountuserService {
  private _accountUser: ReplaySubject<LoggedInUser> =
    new ReplaySubject<LoggedInUser>(1);
  private _accountUserRoles: ReplaySubject<AccountUserRole[]> =
    new ReplaySubject<AccountUserRole[]>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: LoggedInUser) {
    // Store the value
    this._accountUser.next(value);
  }

  get user$(): Observable<LoggedInUser> {
    return this._accountUser.asObservable();
  }

  get accountUserRoles$(): Observable<AccountUserRole[]> {
    return this._accountUserRoles.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<LoggedInUser> {
    const url = `${environment.urls.api}/user/login`;
    return this._httpClient.get<LoggedInUser>(url).pipe(
      tap((accountUser) => {
        this._accountUser.next(accountUser);
      }),
    );
  }

  getRoles(): Observable<AccountUserRole[]> {
    const url = `${environment.urls.api}/accountuser/roles`;
    return this._httpClient.get<AccountUserRole[]>(url).pipe(
      tap((accountUserRoles) => {
        this._accountUserRoles.next(accountUserRoles);
      }),
    );
  }
}
