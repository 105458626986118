<div
  class="flex flex-col h-full w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden"
>
  <mat-drawer-container class="flex-auto sm:h-full">
    <!-- Drawer -->
    <mat-drawer
      class="sm:w-96 dark:bg-gray-900"
      #drawer
      [autoFocus]="false"
      [mode]="drawerMode"
      [opened]="drawerOpened"
    >
      <!-- Header -->
      <div class="flex items-center justify-between m-8 mr-6 sm:my-10">
        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight leading-none">
          Settings
        </div>
        <!-- Close button -->
        <div class="lg:hidden">
          <button (click)="drawer.close()" mat-icon-button>
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
          </button>
        </div>
      </div>
      <!-- Panel links -->
      <div class="flex flex-col divide-y border-t border-b">
        <ng-container *ngFor="let panel of panels; trackBy: trackByFn">
          <div
            class="flex px-8 py-5 cursor-pointer hover:bg-gray-100 dark:hover:bg-hover"
            [routerLink]="panel.id"
            (click)="goToPanel(panel.id)"
            routerLinkActive="bg-primary-50 dark:bg-hover"
          >
            <mat-icon
              class="text-hint"
              [svgIcon]="panel.icon"
              routerLinkActive="text-primary dark:text-primary-500"
            ></mat-icon>
            <div class="ml-3">
              <div
                class="font-medium leading-6"
                routerLinkActive="text-primary dark:text-primary-500"
              >
                {{ panel.title }}
              </div>
              <div class="mt-0.5 text-secondary" *ngIf="panel.description">
                {{ panel.description }}
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-drawer>

    <!-- Drawer content -->
    <mat-drawer-content class="flex flex-col h-full">
      <!-- Main -->
      <div class="flex-auto px-6 pt-9 pb-12 md:p-8 md:pb-12 lg:p-12 h-full">
        <!-- Panel header -->
        <div class="flex items-center">
          <!-- Drawer toggle -->
          <button
            class="lg:hidden -ml-2"
            (click)="drawer.toggle()"
            mat-icon-button
          >
            <mat-icon
              [svgIcon]="'heroicons_outline:ellipsis-vertical'"
            ></mat-icon>
          </button>

          <div>
            <div class="prose prose-sm max-w-3xl">
              <h2>{{ getPanelInfo(selectedPanel).title }}</h2>
              <p>
                {{ getPanelInfo(selectedPanel).header }}
              </p>
            </div>
          </div>
        </div>

        <!-- Load settings panel -->
        <div class="h-full">
          <router-outlet class="h-full"></router-outlet>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
