import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Job, JobType } from 'app/shared/models';
import { map } from 'rxjs/operators';
import { JobStatusParams } from '../../models/job-statuses';
import {
  getAcceptedEstimate,
  getJob,
  getOriginalitems,
  updateJob,
  updateJobStatus,
} from '../actions';
import { JobListState } from '../reducers/job-list.reducer';
import {
  selectAcceptedEstimate,
  selectAcceptedEstimateDetailSections,
  selectCurrentUrl,
  selectJob,
  selectJobActiveRoute,
  selectJobDetailError,
  selectJobDetailIsLoaded,
  selectJobDetailIsLoading,
  selectOriginalItems,
} from '../selectors';
@Injectable({
  providedIn: 'root',
})
export class JobDetailFacade {
  isLoading$ = this.store.select(selectJobDetailIsLoading);
  isLoaded$ = this.store.select(selectJobDetailIsLoaded);
  error$ = this.store.select(selectJobDetailError);
  activeRoute$ = this.store.select(selectJobActiveRoute);
  currentUrl$ = this.store.select(selectCurrentUrl);
  job$ = this.store.select(selectJob);
  acceptedEstimate$ = this.store.select(selectAcceptedEstimate);
  originalItems$ = this.store.select(selectOriginalItems);
  acceptedEstimateSections$ = this.store.select(
    selectAcceptedEstimateDetailSections,
  );
  title$ = this.job$.pipe(
    map(
      (job) =>
        `${JobType[job?.jobType]} #${
          job?.jobType === JobType.Job ? job?.jobCode : job?.estimateCode
        } - ${job?.jobTitle}`,
    ),
  );

  constructor(private store: Store<JobListState>) {}

  getJob(id: string): void {
    this.store.dispatch(getJob({ id }));
  }

  getAcceptedEstimate(id: string): void {
    this.store.dispatch(getAcceptedEstimate({ id }));
  }

  getOriginalitems(id: string): void {
    this.store.dispatch(getOriginalitems({ id }));
  }

  updateJob(job: Job): void {
    return this.store.dispatch(updateJob({ job }));
  }

  updateJobStatus(params: JobStatusParams): void {
    return this.store.dispatch(updateJobStatus({ params }));
  }
}
