/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-parens */
import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { flatten } from 'lodash';
import { EstimateState, selectEstimateState } from '../reducers';

export const selectEstimateDetailState = createSelector(
  selectEstimateState,
  (s: EstimateState) => s.estimate,
);

export const selectEstimateDetailIsLoading = createSelector(
  selectEstimateDetailState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectEstimateDetailIsLoaded = createSelector(
  selectEstimateDetailState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectEstimateDetailError = createSelector(
  selectEstimateDetailState,
  (s) => getError(s.callState),
);

export const selectEstimateIsEditing = createSelector(
  selectEstimateDetailState,
  (s) => s.editing,
);

export const selectEstimateDetailItemCount = createSelector(
  selectEstimateDetailState,
  (s) => s.estimate?.items.length,
);

export const selectEstimateDetailEstimate = createSelector(
  selectEstimateDetailState,
  (s) => s.estimate,
);
