import { createSelector } from '@ngrx/store';
import { getError, LoadingState, selectRouterState } from 'app/store';
import { JobRoute } from 'app/views/jobs/models';
import { sortBy } from 'lodash';
import { JobState, selectJobState } from '../reducers';

export const selectJobDetailState = createSelector(
  selectJobState,
  (s: JobState) => s?.job,
);

export const selectJobDetailIsLoading = createSelector(
  selectJobDetailState,
  (s) => s?.callState === LoadingState.LOADING,
);

export const selectJobDetailIsLoaded = createSelector(
  selectJobDetailState,
  (s) => s?.callState === LoadingState.LOADED,
);

export const selectJobDetailError = createSelector(selectJobDetailState, (s) =>
  getError(s?.callState),
);

export const selectJobActiveRoute = createSelector(
  selectRouterState,
  (s) => <JobRoute>s.state.url.split('/').reverse()[0],
);

export const selectCurrentUrl = createSelector(
  selectRouterState,
  (s) => s?.state?.url,
);

export const selectJob = createSelector(selectJobDetailState, (s) => s?.job);

export const selectAcceptedEstimate = createSelector(
  selectJobDetailState,
  (s) => s?.acceptedEstimate,
);

export const selectAcceptedEstimateDetailSections = createSelector(
  selectAcceptedEstimate,
  (s) => sortBy(s?.['sections'], (section) => section.section.order),
);

export const selectOriginalItems = createSelector(
  selectJobDetailState,
  (s) => s?.originalItems,
);
