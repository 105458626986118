/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AddJobOrder,
  BulkSoftDeleteResponse,
  Estimate,
  EstimateParameters,
  Job,
  JobFile,
  JobInvoice,
  JobInvoiceByCustomerParameters,
  JobInvoiceParameters,
  JobInvoicePayment,
  JobInvoiceTotals,
  JobNote,
  JobNoteParameters,
  JobOrder,
  JobOrderParameters,
  JobParameters,
  JobPhoto,
  JobType,
  PaginatedListOfEstimate,
  PaginatedListOfJob,
  PaginatedListOfJobInvoice,
  PaginatedListOfJobInvoicePayment,
  PaginatedListOfJobOrderListItem,
  PaginatedListOfTemplate,
  SendJobInvoice,
  SendJobOrder,
  SendQuote,
  Template,
  TemplateParameters,
} from 'app/shared/models';
import { EstimateStatusParams } from 'app/views/estimates/models/estimate-statuses';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PhotoService {
  constructor(private http: HttpClient) {}

  deleteJobFiles(
    ids: string[],
    jobId: string,
  ): Observable<BulkSoftDeleteResponse> {
    let url;
    if (jobId) {
      url = `${environment.urls.api}/jobfile/bulk/delete`;
    } else {
      url = `${environment.urls.api}/libraryfile/bulk/delete`;
    }
    return this.http.delete<BulkSoftDeleteResponse>(url, { body: ids });
  }

  downloadMultipleJobFiles(
    jobId: string,
    files: JobFile[],
  ): Observable<HttpResponse<Blob>> {
    let url;
    if (jobId) {
      url = `${environment.urls.api}/jobfile/bulk/download`;
    } else {
      url = `${environment.urls.api}/libraryfile/bulk/download`;
    }
    return this.http
      .post(url, files, {
        observe: 'response',
        responseType: 'blob' as 'blob',
      })
      .pipe(map((response: any) => response.body));
  }

  renameJobFile(file: JobFile): Observable<JobFile> {
    let url;
    if (file.jobId) {
      url = `${environment.urls.api}/jobfile/renamefile`;
    } else {
      url = `${environment.urls.api}/libraryfile/list`;
    }
    return this.http.post<JobFile>(url, file);
  }

  createJobFile(file: JobFile): Observable<JobFile> {
    let url;
    if (file.jobId) {
      url = `${environment.urls.api}/jobfile/add`;
    } else {
      url = `${environment.urls.api}/libraryfile/add`;
    }
    return this.http.post<JobFile>(url, file);
  }

  getJobFiles(jobId: string): Observable<JobFile[]> {
    let url;
    let params: any = {};

    if (jobId) {
      url = `${environment.urls.api}/jobfile/list`;
      params = { jobId, pageSize: 65536 };
    } else {
      url = `${environment.urls.api}/libraryfile/list`;
      params = { pageSize: 65536 };
    }
    const files = this.http
      .post<PaginatedListOfJob>(url, params)
      .pipe(map((data: PaginatedListOfJob) => data.items));
    return files;
  }

  renameJobFilesFolder(
    jobId: string,
    oldPath: string,
    newPath: string,
  ): Observable<JobFile[]> {
    let url;
    let params: any = {};
    if (jobId) {
      url = `${environment.urls.api}/jobfile/renamefolder`;
      params = { jobId, oldPath, newPath };
    } else {
      url = `${environment.urls.api}/libraryfile/renamefolder`;
      params = { oldPath, newPath };
    }

    return this.http.post<JobFile[]>(url, params);
  }

  fileFolderExists(jobId: string, path: string): Observable<boolean> {
    let url;
    let params: any = {};
    if (jobId) {
      url = `${environment.urls.api}/jobfile/folderexists`;
      params = { jobId, path };
    } else {
      url = `${environment.urls.api}/libraryfile/folderexists`;
      params = { path };
    }

    return this.http
      .post(url, params)
      .pipe(map((response: any) => response.exists));
  }
}
