import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  RouterLinkActive,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { CurrentPriceListFacade } from 'app/views/price-list/store/facades';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EstimateActionTypes, EstimateDetailFacade } from '../../store';
import { BackNavigationService } from '../../../../shared/services/back-navigation.service';
import { NgFor } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-estimate-container',
  templateUrl: './estimate-container.component.html',
  styleUrls: ['./estimate-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTabsModule, NgFor, RouterLinkActive, RouterLink, RouterOutlet],
})
export class EstimateContainerComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private estimateFacade: EstimateDetailFacade,
    private priceListFacade: CurrentPriceListFacade,
    private actionsSubj: ActionsSubject,
    public backNavigationService: BackNavigationService,
  ) {}

  estimate$ = this.estimateFacade.estimate$;
  priceList$ = this.priceListFacade.priceList$;
  isLoaded$ = combineLatest([
    this.estimateFacade.isLoaded$,
    this.priceListFacade.isLoaded$,
  ]).pipe(
    map(
      ([estimateLoaded, priceListLoaded]) => estimateLoaded && priceListLoaded,
    ),
  );

  private unsubscriber$ = new Subject<void>();

  ngOnInit(): void {
    this.init();
    this.actionsSubj
      .pipe(
        ofType(EstimateActionTypes.UpdateEstimateStatusSuccess),
        takeUntil(this.unsubscriber$),
      )
      .subscribe(() => {
        this.init();
      });
  }

  init(): void {
    const id = this.route.snapshot.params['id'];
    this.estimateFacade.getEstimate(id);
    this.priceListFacade.getCurrentPriceList();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.backNavigationService.url = null;
    this.backNavigationService.tooltip = null;
  }

  menuItems = [
    {
      text: 'Costing',
      route: 'estimate-info',
    },
    {
      text: 'Quote Letter',
      route: 'quote',
    },
  ];
}
