import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addRemovePriceListItemsShowDialog,
  deletePriceListItemConfirm,
  getJobCosting,
  getJobCostingSingle,
  getJobHistory,
  getVariations,
  resetJobCosting,
  saveJobCosting, updateJobCostingEstimate,
  updateJobCostingCustomerQuote,
  updateJobCostingOnCost,
  updateJobCostingProfit,
  updatePriceListItem,
  updatePriceListItemShowDialog, updateJobCostingStatus, uploadJobCostingQuoteLogo, updateStatusForSelectedVariationItem
} from '../actions';
import { JobCostingState } from '../reducers/job-costing.reducer';
import {
  selectHistory,
  selectJobCosting,
  selectJobCostingError, selectJobCostingIsEditing,
  selectJobCostingIsLoaded,
  selectJobCostingIsLoading, selectJobCostingOriginal, selectJobCostingSingle, selectJobCostingStatus,
  selectVariation,
} from '../selectors';
import {
  JobCostingPriceListItem,
  JobHistoryParameters, SingleSaveJobCosting,
  VariationParameters
} from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class JobCostingFacade {
  isLoading$ = this.store.select(selectJobCostingIsLoading);
  isLoaded$ = this.store.select(selectJobCostingIsLoaded);
  error$ = this.store.select(selectJobCostingError);
  jobCosting$ = this.store.select(selectJobCosting);
  jobCostingSingle$ = this.store.select(selectJobCostingSingle);
  jobCostingOriginal$ = this.store.select(selectJobCostingOriginal);
  jobVariation$ = this.store.select(selectVariation);
  jobHistory$ = this.store.select(selectHistory);
  editing$ = this.store.select(selectJobCostingIsEditing);
  jobCostingStatus = this.store.select(selectJobCostingStatus);

  constructor(private store: Store<JobCostingState>) {}

  getJobCosting(id: string): void {
    this.store.dispatch(getJobCosting({ id }));
  }
  getJobCostingSingle(id: string): void {
    this.store.dispatch(getJobCostingSingle({ id }));
  }
  getJobVariation(query: VariationParameters): void {
    this.store.dispatch(getVariations({ query }));
  }
  getJobHistory(query: JobHistoryParameters): void {
    this.store.dispatch(getJobHistory({ query }));
  }
  save() {
    this.store.dispatch(saveJobCosting());
  }

  updateJobCosting(jobCosting: SingleSaveJobCosting) {
    this.store.dispatch(updateJobCostingEstimate({jobCosting}));
  }

  updateStatus(params: {id: string, type: string}) {
    this.store.dispatch(updateJobCostingStatus({params}));
  }

  updateStatusForSelectedVariationItem(params: {variationItemId: string, status: number}) {
    this.store.dispatch(updateStatusForSelectedVariationItem({params}));
  }

  reset() {
    this.store.dispatch(resetJobCosting());
  }
  updateName(name) {
    //  TODO
  }

  uploadJobCostingLogo(id: string, file: File): void {
    this.store.dispatch(uploadJobCostingQuoteLogo({ id, file }));
  }

  updateCustomerQuote(customerQuote: number): void {
    this.store.dispatch(updateJobCostingCustomerQuote({ customerQuote }));
  }

  updateProfit(profit: number): void {
    this.store.dispatch(updateJobCostingProfit({ profit }));
  }

  updateOnCost(onCost: number): void {
    this.store.dispatch(updateJobCostingOnCost({ onCost }));
  }

  updatePriceListItem(item: JobCostingPriceListItem) {
    this.store.dispatch(updatePriceListItem({ item }));
  }


  updatePriceListItemShowDialog(item: JobCostingPriceListItem) {
    this.store.dispatch(updatePriceListItemShowDialog({ item }));
  }

  addRemovePriceListItemsShowDialog() {
    this.store.dispatch(addRemovePriceListItemsShowDialog());
  }

  deletePriceListItem(id: string) {
    this.store.dispatch(deletePriceListItemConfirm({ id }));
  }
}
