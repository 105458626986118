import { Component, OnInit } from '@angular/core';
import { IntegrationsService } from '../../../services/integration.service';
import {
  IntegrationType,
  TransactionHistoryDisplayDto,
} from '../xero/xero.component';
import { AccountUserFacade } from 'app/shared/store/facades';
import { AsyncPipe, NgIf } from '@angular/common';
import { QuickBooksIntegrationMethodCustomizationComponent } from '../quickbooks-integration-method-customization/quickbooks-integration-method-customization.component';
import { MatButtonModule } from '@angular/material/button';
import { ConnectionStatusComponent } from '../connection-status/connection-status.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
export interface QuickBooksAccountSettingsDto {
  id: string;
  deleted: boolean;
  accountId: string;
  active: boolean;
  gstAccountName: string;
  defaultSaleItemIncomeAccountName: string;
  paymentSychronizationType: number;
}

@Component({
  selector: 'app-quickbooks',
  templateUrl: './quickbooks.component.html',
  styleUrls: ['./quickbooks.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressBarModule,
    ConnectionStatusComponent,
    MatButtonModule,
    QuickBooksIntegrationMethodCustomizationComponent,
  ],
})
export class QuickbooksComponent implements OnInit {
  isConnected: boolean = undefined;
  isLoading = true;
  settings: QuickBooksAccountSettingsDto;
  syncStatus = 'Pending';
  transactionHistory: TransactionHistoryDisplayDto[];

  isReadOnly =
    this.async
      .transform(this.accountUserFacade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingsbilling' &&
          r.accessLevel === 0,
      ).length === 1;

  constructor(
    private integrationsService: IntegrationsService,
    public accountUserFacade: AccountUserFacade,
    private async: AsyncPipe,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.integrationsService.getQuickBooksStatus().subscribe((r) => {
      console.log(r);
      this.isLoading = false;
      this.isConnected = r.connected;
      if (this.isConnected) {
        this.getSettings();
        this.getTransactionHistory();
      }
    });
  }

  connect() {
    console.log('connect');
    this.isLoading = true;
    this.integrationsService.connectToQuickBooks().subscribe((r) => {
      console.log(r);
      this.isLoading = false;
      window.location.href = r.url;
    });
  }

  disconnect() {
    console.log('disconnect');
    this.isLoading = true;
    this.integrationsService.disconnectFromQuickBooks().subscribe((r) => {
      console.log(r);
      this.isLoading = false;
      this.isConnected = !this.isConnected;
    });
  }

  getSettings() {
    this.integrationsService.getQuickBookSettings().subscribe((r) => {
      console.log('settings', r);
      this.settings = r;
    });
  }

  getTransactionHistory() {
    const topResultCount = 5;
    this.integrationsService
      .getTransactionHistory(topResultCount, IntegrationType.QuickBooks)
      .subscribe((r) => {
        this.transactionHistory = r;

        if (this.transactionHistory.length > 0) {
          this.syncStatus = this.transactionHistory[0].hasError
            ? 'Error during last sync'
            : 'OK';
        } else {
          this.syncStatus = 'N/A';
        }
      });
  }

  onSettingChange(formValues) {
    console.log(formValues);
    this.isLoading = true;
    this.integrationsService
      .updateQuickBookSettings({
        ...this.settings,
        ...formValues,
      })
      .subscribe((r) => {
        console.log(r);
        this.isLoading = false;
      });
  }
}
