import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortCfitems',
  standalone: true,
})

export class SortCfitemsPipe implements PipeTransform {
  transform(arr: any[], sort: {name: string; order: string}): any[] {
    if (sort && arr) {
      arr.sort((a,b) => {
        let a1, b1;
          switch (sort.name) {
            case 'job':
              a1 = a.job?.jobTitle;
              b1 = b.job?.jobTitle;
              break;
            case 'supplier':
              a1 = a.supplier?.name;
              b1 = b.supplier?.name;
              break;
            default:
              a1 = a[sort.name];
              b1 = b[sort.name];
          }
        switch (sort.order) {

          case 'asc':
            return (a1 || '') > (b1 || '') ? 1 : -1;
            break;
          case 'desc':
            return (a1 || '') < (b1 || '') ? 1 : -1;
            break;
        }
      }
      )
    }
    return arr;
  }
}
