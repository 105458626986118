import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  JobInvoice,
  JobInvoiceParameters,
  JobInvoicePayment,
} from 'app/shared/models';
import {
  addJobInvoice,
  changeInvoiceStatus,
  clearInvoice,
  deleteJobInvoice,
  getCustomerJobInvoices,
  getJobInvoice,
  getJobInvoices,
  selectCustomerJobInvoices,
  selectJobInvoice,
  selectJobInvoices,
  selectJobInvoicesIsLoaded,
  selectJobInvoicesTotalCount,
  sendInvoice,
  updateInvoicePayment,
  updateJobInvoice,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class JobInvoicesFacade {
  invoices$ = this.store.select(selectJobInvoices);
  customerInvoices$ = this.store.select(selectCustomerJobInvoices);
  invoice$ = this.store.select(selectJobInvoice);
  isLoaded$ = this.store.select(selectJobInvoicesIsLoaded);
  totalCount$ = this.store.select(selectJobInvoicesTotalCount);

  constructor(private store: Store) {}

  getInvoices(request: JobInvoiceParameters): void {
    this.store.dispatch(getJobInvoices({ request }));
  }

  getCustomerInvoices(id: string): void {
    this.store.dispatch(getCustomerJobInvoices({ id }));
  }

  getInvoice(id: string): void {
    this.store.dispatch(getJobInvoice({ id }));
  }

  deleteInvoice(id: string): void {
    this.store.dispatch(deleteJobInvoice({ id }));
  }

  changeInvoiceStatus(action: string, id: string): void {
    this.store.dispatch(changeInvoiceStatus({ action, id }));
  }

  sendInvoice(action: string, id: string): void {
    this.store.dispatch(sendInvoice({ action, id }));
  }

  addInvoice(invoice: JobInvoice): void {
    this.store.dispatch(addJobInvoice({ invoice }));
  }

  updateInvoice(invoice: JobInvoice): void {
    return this.store.dispatch(updateJobInvoice({ invoice }));
  }

  updateInvoicePayment(payload: JobInvoicePayment): void {
    return this.store.dispatch(updateInvoicePayment({ payload }));
  }
  clearInvoice(): void {
    return this.store.dispatch(clearInvoice());
  }
}
