import { createReducer, on } from '@ngrx/store';
import {
  FullVariationQuote,
  VariationSection,
} from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';

import {
  getVariationLetterQuote,
  getVariationLetterQuoteFailure,
  getVariationLetterQuoteSuccess,
  updateVariationLetterQuote,
  updateVariationLetterQuoteFailure,
  updateVariationLetterQuoteSuccess,
  uploadVariationLetterLogo,
  uploadVariationLetterLogoFailure,
  uploadVariationLetterLogoSuccess,
  getQuoteDocumentSection,
  getQuoteDocumentSectionFailure,
  getQuoteDocumentSectionSuccess,
} from '../actions';
import {
  updateQuoteDocumentSection,
  updateQuoteDocumentSectionFailure,
  updateQuoteDocumentSectionSuccess,
} from '../../../estimates/store';

export interface VariationLetterQuoteState {
  variationLetterQuote: FullVariationQuote;
  callState: CallState;
  error: any;
  quoteDocumentSections: VariationSection[];
}

export const initialState: VariationLetterQuoteState = {
  variationLetterQuote: null,
  callState: LoadingState.INIT,
  error: null,
  quoteDocumentSections: [],
};

export const variationLetterQuoteReducer = createReducer(
  initialState,

  on(getVariationLetterQuote, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getVariationLetterQuoteFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getVariationLetterQuoteSuccess, (state, { variationLetterQuote }) => ({
    ...state,
    variationLetterQuote: variationLetterQuote,
    callState: LoadingState.LOADED,
  })),
  // update VariationLetter
  on(updateVariationLetterQuote, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateVariationLetterQuoteFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateVariationLetterQuoteSuccess, (state, { variationLetterQuote }) => ({
    ...state,
    variationLetterQuote: {
      ...state.variationLetterQuote,
      ...{ variation: variationLetterQuote },
    },
    callState: LoadingState.LOADED,
  })),
  //  upload logo
  on(uploadVariationLetterLogo, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(uploadVariationLetterLogoFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),

  // get quote sections
  on(getQuoteDocumentSection, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getQuoteDocumentSectionFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getQuoteDocumentSectionSuccess, (state, { quoteDocumentSections }) => ({
    ...state,
    quoteDocumentSections,
    callState: LoadingState.LOADED,
  })),

  // update quote sections
  on(updateQuoteDocumentSection, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateQuoteDocumentSectionFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateQuoteDocumentSectionSuccess, (state, { quoteDocumentSections }) => ({
    ...state,
    quoteDocumentSections,
    callState: LoadingState.LOADED,
  })),
);
