/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AsyncPipe, NgIf, NgFor, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogContent,
} from '@angular/material/dialog';
import {
  EstimatePriceListItem,
  PriceListCategory,
  PriceListItem, SingleSaveEstimate,
  SingleSaveTemplate,
  TemplatePriceListItem,
} from 'app/shared/models';
import { CurrentPriceListFacade } from 'app/views/price-list/store/facades';
import { orderBy, uniqBy, xorBy } from 'lodash';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { PriceListCategoryDialogComponent } from '../../../views/price-list/components';
import { PriceListItemDialogComponent } from '../../../views/price-list/components/price-list-item-dialog/price-list-item-dialog.component';
import { Actions, ofType } from '@ngrx/effects';
import { addCurrentPriceListCategorySuccess } from '../../../views/price-list/store/actions';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash-es';

interface ExtraOrder {
  id?: string;
  items: PriceListItem[];
  selectedSupplier: { id: string; name: string };
  selectedCategory: { id: string; name: string };
}

@Component({
  selector: 'app-add-remove-price-list-items-dialog',
  templateUrl: './add-remove-price-list-items-dialog.component.html',
  styleUrls: ['./add-remove-price-list-items-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatDialogContent,
    NgFor,
    NgClass,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
    AsyncPipe,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  providers: [AsyncPipe],
})
export class AddRemovePriceListItemsDialogComponent
  implements OnInit, OnDestroy
{
  constructor(
    public priceListFacade: CurrentPriceListFacade,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data:
      | SingleSaveEstimate
      | SingleSaveTemplate
      | ExtraOrder,
    private fb: FormBuilder,
    private async: AsyncPipe,
    private dialog: MatDialog,
    private actions$: Actions,
    private cdr: ChangeDetectorRef
  ) {}

  get searchControl(): FormControl {
    return this.searchForm.get('searchField') as FormControl;
  }

  searchForm: FormGroup = this.fb.group({
    searchField: [''],
  });

  allCategories = {
    id: '',
    name: 'All Categories',
    displayOrder: -1,
    priceListId: '',
    code: '000'
  };
  categories: PriceListCategory[] = [];

  newCategories: PriceListCategory[] = [];

  unsubscriber$ = new Subject<void>();

  suppliers$ = this.priceListFacade.suppliers$;
  supplier = this.fb.control(['']);
  selectedCategorySubject$ = new BehaviorSubject<PriceListCategory>(
    this.data['selectedCategory'] || this.allCategories,
  );
  selectedCategory$ = this.selectedCategorySubject$.asObservable();

  selectedEstimateListItemsSubject$ = new BehaviorSubject<
    EstimatePriceListItem[]
  >([]);
  selectedEstimateListItems$ = combineLatest([
    this.selectedEstimateListItemsSubject$.asObservable(),
    this.selectedCategory$,
  ]).pipe(
    map(([items, category]) =>
      items.filter(
        (i) => category.id === '' || i.priceListCategoryId === category.id,
      ),
    ),
  );

  selectedPriceListItemsSubject$ = new BehaviorSubject<PriceListItem[]>([]);
  selectedPriceListItems$ = combineLatest([
    this.selectedPriceListItemsSubject$.asObservable(),
    this.selectedCategory$,
  ]).pipe(
    map(([items, category]) =>
      items.filter(
        (i) => category.id === '' || i.priceListCategoryId === category.id,
      ),
    ),
  );

  selectedJobListItem: EstimatePriceListItem;
  displayedColumnsPriceList = [
    'selected',
    'itemcode',
    'description',
    'supplier',
    'qty',
    'uom',
    'unitPrice',
  ];
  displayedColumnsEstimateList = [
    'selected',
    'itemcode',
    'description',
    'supplier',
    'qty',
    'uom',
    'unitPrice',
  ];

  close() {
    this.dialogRef.close();
  }

  save() {
    const originalItemIds = this.data.items.map((i) => (<EstimatePriceListItem | TemplatePriceListItem>i).priceListItemId);
    const currentItemIds = this.estimateListItemsSubject$.value.map(
      (i) => i.priceListItemId,
    );
    const added = this.estimateListItemsSubject$.value.filter(
      (i) => !originalItemIds.includes(i.priceListItemId),
    );

    const removed = (<any[]>this.data.items).filter(
      (i: EstimatePriceListItem | TemplatePriceListItem) =>
        !currentItemIds.includes(i.priceListItemId),
    );

    let area = 'jobCostingSectionId';
    // if (this.data.hasOwnProperty('estimateId')) {
    //   area = 'estimateSectionId';
    // }
    // if (this.data.hasOwnProperty('templateId')) {
    //   area = 'templateSectionId';
    // }
    const response = {
      [area]: this.data.id,
      added,
      removed,
    };

    this.dialogRef.close(response);
  }

  estimateListItemsSubject$ = new BehaviorSubject<EstimatePriceListItem[]>(
    this.data.items,
  );

  estimateListItems$ = combineLatest([
    this.selectedCategory$,
    this.estimateListItemsSubject$.asObservable(),
    this.searchControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([category, items, search]) =>
      items
        .filter(
          (i) => category.id === '' || i.priceListCategoryId === category.id,
        )
        .filter(
          (i) =>
            i.description.toLowerCase().includes(search.toLowerCase()) ||
            i.supplier.name.toLowerCase().includes(search.toLowerCase()),
        ),
    ),
  );

  canSave$ = this.estimateListItemsSubject$
    .asObservable()
    .pipe(
      map(
        (items) =>
          xorBy(items, this.data.items, (i) => i.priceListItemId).length > 0,
      ),
    );

  priceListItems$ = combineLatest([
    this.selectedCategory$,
    this.estimateListItems$,
    this.priceListFacade.items$,
    this.searchControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([category, estimateItems, allItems, search]) => {
      const estimateItemIds = estimateItems.map((i) => i.priceListItemId);
      const priceListItems = allItems
        .filter((i) => {
          if (this.data['selectedCategory']) {
            return i.supplier.id === this.data['selectedSupplier'].id;
          } else {
            return true;
          }
        })
        .filter((i) => !estimateItemIds.includes(i.id))
        .filter(
          (i) =>
            i.description.toLowerCase().includes(search.toLowerCase()) ||
            i.supplier.name.toLowerCase().includes(search.toLowerCase()),
        );
      return priceListItems.filter(
        (i) => category.id === '' || i.priceListCategory.id === category.id,
      );
    }),
  );

  estimateListItemIsSelected$ = (item: EstimatePriceListItem) =>
    this.selectedEstimateListItems$.pipe(
      map((items) =>
        items.map((i) => i.priceListItemId).includes(item.priceListItemId),
      ),
    );
  allEstimateListItemsSelected$ = combineLatest([
    this.estimateListItems$,
    this.selectedEstimateListItems$,
  ]).pipe(map(([a, b]) => a.length === b.length && b.length > 0));

  priceListItemIsSelected$ = (item: PriceListItem) =>
    this.selectedPriceListItems$.pipe(
      map((items) => items.map((i) => i.id).includes(item.id)),
    );

  allPriceListItemsSelected$ = combineLatest([
    this.priceListItems$,
    this.selectedPriceListItems$,
  ]).pipe(map(([a, b]) => a.length === b.length && b.length > 0));

  selectAllPriceListItems(ob: MatCheckboxChange) {
    let items = [];
    if (ob.checked) {
      items = this.async.transform(this.priceListItems$);
    }
    this.selectedPriceListItemsSubject$.next(items);
  }

  selectAllEstimateListItems(ob: MatCheckboxChange) {
    let items = [];
    if (ob.checked) {
      items = this.async.transform(this.estimateListItems$);
    }
    this.selectedEstimateListItemsSubject$.next(items);
  }

  selectPriceListItem(item: PriceListItem) {
    let items = this.selectedPriceListItemsSubject$.value;
    const selected = items.map((i) => i.id).includes(item.id);

    if (selected) {
      items = items.filter((i) => i.id !== item.id);
    } else {
      items = [...items, item];
    }

    this.selectedPriceListItemsSubject$.next(items);
  }

  selectEstimateListItem(item: EstimatePriceListItem) {
    let items = this.selectedEstimateListItemsSubject$.value;
    const selected = items
      .map((i) => i.priceListItemId)
      .includes(item.priceListItemId);

    if (selected) {
      items = items.filter((i) => i.priceListItemId !== item.priceListItemId);
    } else {
      items = [...items, item];
    }

    this.selectedEstimateListItemsSubject$.next(items);
  }

  selectCategory(category: PriceListCategory) {
    if (this.data['selectedCategory']) {
      return;
    }
    if (this.searchControl.value) {
      this.searchControl.patchValue('');
    }
    this.selectedCategorySubject$.next(category);
  }

  addToEstimate() {
    const newItems = this.selectedPriceListItemsSubject$.value.map((item) => {
      const obj: EstimatePriceListItem | TemplatePriceListItem = {
        priceListItemId: item.id,
        priceListCategoryCode: item.priceListCategory.code,
        priceListCategoryId: item.priceListCategory.id,
        priceListCategoryName: item.priceListCategory.name,
        priceListCategoryOrder: item.priceListCategory.displayOrder,
        supplier: item.supplier,
        supplierId: item.supplierId,
        supplierItemCode: item.supplierItemCode,
        itemCode: item.itemCode,
        description: item.description,
        unitOfMeasure: item.unitOfMeasure,
        unitPrice: item.unitPrice,
        qty: 1,
        unitTotal: item.unitPrice,
        gstRate: 10,
        quotable: true,
      };
      return obj;
    });

    const items = [...newItems, ...this.estimateListItemsSubject$.value];
    this.estimateListItemsSubject$.next(items);
    const ids = newItems.map((i) => i.priceListItemId);
    this.selectedPriceListItemsSubject$.next(
      this.selectedPriceListItemsSubject$.value.filter(
        (i) => !ids.includes(i.id),
      ),
    );
  }

  removeFromEstimate() {
    const ids = this.selectedEstimateListItemsSubject$.value.map(
      (i) => i.priceListItemId,
    );
    const items = this.estimateListItemsSubject$.value.filter(
      (i) => !ids.includes(i.priceListItemId),
    );

    this.estimateListItemsSubject$.next(items);
    this.selectedEstimateListItemsSubject$.next(
      this.selectedEstimateListItemsSubject$.value.filter(
        (i) => !ids.includes(i.priceListItemId),
      ),
    );
  }

  addNewCategory() {
    this.dialog.open(PriceListCategoryDialogComponent, {
      width: '626px',
      data: {
        isEdit: false,
        category: null,
        priceListItem: null,
        currentPriceList: null,
      },
    });
  }

  addItemToCategory(category) {
    console.log(category);
    this.dialog.open(PriceListItemDialogComponent, {
      width: '626px',
      data: {
        isEdit: false,
        category: category,
        priceListItem: null,
        currentSupplier: this.data['selectedSupplier'],
      },
    });
  }
  trackByFn(index: number, item: any): any {
    return item.id;
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    const data = cloneDeep(this.categories).map((c: any, index) => {
      c.displayOrder = index;
      return c;
    });
    this.priceListFacade.updateCurrentPriceListCategory(data);
  }

  ngOnInit(): void {
    this.priceListFacade.categories$.pipe(
      takeUntil(this.unsubscriber$),
      debounceTime(100),
      map((categories) =>
        uniqBy(
          [...categories, ...this.newCategories],
          (s) => s.id,
        ),
      ),
      map((categories) => orderBy(categories, (c) => c.displayOrder)),
      map((categories) =>
        categories.filter((c) => {
          if (this.data['selectedCategory']) {
            return c.id === this.data['selectedCategory'].id;
          } else {
            return true;
          }
        }),
      ),
    ).subscribe(r => {
      this.categories = r;
      this.cdr.detectChanges();
    });

    this.actions$
      .pipe(ofType(addCurrentPriceListCategorySuccess))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((action) => {
        this.newCategories.push(action.category);
      });

    this.supplier.setValue([]);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
