import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[abn]',
  standalone: true,
})
export class ABNDirective {
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste',
  ];

  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('beforeinput', ['$event'])
  onBeforeInput(e: InputEvent): any {
    if (isNaN(Number(e.data))) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): any {
    if (e.key === 'Delete') {
      if (
        this.inputElement.selectionStart === this.inputElement.selectionEnd &&
        this.inputElement.value.substr(this.inputElement.selectionStart, 1) ===
          ' '
      ) {
        this.inputElement.selectionStart += 1;
      }
      return;
    }
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
      ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
      ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
      ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
      ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
      ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
      ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
      ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
      return;
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    event.target.value = this.sanitizeInput(event.target.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any): void {
    let pastedInput: string = '';
    if ((window as { [key: string]: any })['clipboardData']) {
      // Browser is IE
      pastedInput = (window as { [key: string]: any })['clipboardData'].getData(
        'text',
      );
    } else if (event.clipboardData && event.clipboardData.getData) {
      // Other browsers
      pastedInput = event.clipboardData.getData('text/plain');
    }

    this.pasteData(pastedInput);
    event.preventDefault();
  }

  private format(value: string): string {
    return `${value.substr(0, 2)} ${value.substr(2, 3)} ${value.substr(
      5,
      3,
    )} ${value.substr(8, 3)}`.trim();
  }

  private pasteData(pastedContent: string): void {
    const sanitizedContent = this.sanitizeInput(pastedContent);

    const pasted = document.execCommand('insertText', false, sanitizedContent);
    if (!pasted) {
      if (this.inputElement.setRangeText) {
        const { selectionStart: start, selectionEnd: end } = this.inputElement;
        this.inputElement.setRangeText(
          sanitizedContent,
          start ?? 0,
          end ?? 0,
          'end',
        );
        // Angular's Reactive Form relies on "input" event, but on Firefox, the setRangeText method doesn't trigger it
        // so we have to trigger it ourself.
        if (
          typeof (window as { [key: string]: any })['InstallTrigger'] !==
          'undefined'
        ) {
          this.inputElement.dispatchEvent(
            new Event('input', { cancelable: true }),
          );
        }
      } else {
        // Browser does not support setRangeText, e.g. IE
        this.insertAtCursor(this.inputElement, sanitizedContent);
      }
    }
  }

  // The following 2 methods were added from the below article for browsers that do not support setRangeText
  // https://stackoverflow.com/questions/11076975/how-to-insert-text-into-the-textarea-at-the-current-cursor-position
  private insertAtCursor(myField: HTMLInputElement, myValue: string): void {
    const startPos = myField.selectionStart ?? 0;
    const endPos = myField.selectionEnd ?? 0;

    myField.value =
      myField.value.substring(0, startPos) +
      myValue +
      myField.value.substring(endPos, myField.value.length);

    const pos = startPos + myValue.length;
    myField.focus();
    myField.setSelectionRange(pos, pos);

    this.triggerEvent(myField, 'input');
  }

  private triggerEvent(el: HTMLInputElement, type: string): void {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      const e = document.createEvent('HTMLEvents');
      e.initEvent(type, false, true);
      el.dispatchEvent(e);
    }
  }
  // end stack overflow code

  private sanitizeInput(input: string): string {
    return this.format(input.replace(/\D/g, '').substr(0, 11));
  }

  private getSelection(): string {
    return this.inputElement.value.substring(
      this.inputElement.selectionStart ?? 0,
      this.inputElement.selectionEnd ?? 0,
    );
  }
}
