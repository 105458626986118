<div>
    <h2 mat-dialog-title class="text-sm sm:text-base text-center">Remove extra seat</h2>
    <mat-dialog-content *ngIf="!loadingExtraSeats && !loadingAvailableSeats">
        <div *ngIf="numExtraSeats > 0">
            <p>Number of extra seats: {{numExtraSeats}}</p>
            <p>Number of available seats: {{numAvailableSeats}}</p>
            <br />
            <p>An extra seat can only be removed when there are available seats</p>
            <p>You will need to remove a team member to gain an available seat</p>
        </div>
        <div *ngIf="numExtraSeats == 0">
            <p>You have not purchased any extra seats</p>
        </div>
        <br />
        <div *ngIf="changes && (numExtraSeats > 0 && numAvailableSeats > 0)">
            <p>If you remove the extra seat you will no longer be charged {{changes.extraSeatPrice}}</p>
            <p>New subscription cost: {{ changes.subscriptionTotal }}</p>
            <p>Amount due next payment: {{changes.total}} (may include prorated charges)</p>
            <p>Next billing date: {{changes.dueDate}}</p>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button
                type="button"
                mat-stroked-button
                mat-flat-button
                mat-dialog-close>Cancel</button>
        <button
          [color]="'primary'"
          mat-flat-button
          mat-dialog-close="Confirm"
            [disabled]="!(numExtraSeats > 0 && numAvailableSeats > 0)">
            Remove extra seat
        </button>
    </mat-dialog-actions>
</div>
