import { createReducer, on } from '@ngrx/store';
import {
  PaginatedListOfJobHistory,
  SingleGetJobHistory
  } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  getJobHistoryList,
  getJobHistorySingle, getJobHistorySingleFailure, getJobHistorySingleSuccess, getJobHistoryFailure, getJobHistorySuccess, getJobHistoryListFailure, getJobHistoryListSuccess,
} from '../actions';

export interface JobHistoryState {
  jobHistorySingle: SingleGetJobHistory;
  jobHistory: PaginatedListOfJobHistory;
  callState: CallState;
  error: any;
  editing: boolean;
}

export const initialState: JobHistoryState = {
  jobHistory: null,
  jobHistorySingle: null,
  callState: LoadingState.INIT,
  error: null,
  editing: false,
};

export const JobHistoryReducer = createReducer(
  initialState,
  
  on(getJobHistorySingle, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobHistorySingleFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobHistorySingleSuccess, (state, { jobHistorySingle }) => ({
    ...state,
    jobHistorySingle,
    callState: LoadingState.LOADED,
  })),

  on(getJobHistoryList, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobHistoryListFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobHistoryListSuccess, (state, { jobHistory }) => ({
    ...state,
    jobHistory,
    callState: LoadingState.LOADED,
  })),
);
