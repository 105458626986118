import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { OrderStatus } from 'app/shared/models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-order-status-pill',
  templateUrl: './order-status-pill.component.html',
  styleUrls: ['./order-status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass],
})
export class OrderStatusPillComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  jobOrderStatus = OrderStatus;
  @Input('status') set status(value: OrderStatus) {
    if (OrderStatus[value]) {
      this._status = value;
      switch (value) {
        case OrderStatus.Void:
          this.text = 'Deleted';
          break;
        case OrderStatus.Awaiting_Delivery:
          this.text = 'Awaiting Delivery';
          break;
        default:
          this.text = OrderStatus[value]
            .replace(/([A-Z])/g, ' $1')
            .toLowerCase();
          break;
      }
    }
  }

  get status(): OrderStatus {
    return this._status;
  }

  _status: OrderStatus;
  text: string;
}
