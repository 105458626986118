<div class="mb-4 flex flex-row items-center">
  <div class="w-1/2">
    <p class="mb-1">Last Synchronisation Status</p>
    <p class="p-4 w-full border border-slate-800 rounded">{{ syncStatus }}</p>
  </div>
</div>

<div>
  <!-- 
  <ng-container>
    <table class="w-full" [dataSource]="dataSource" [matSortActive]="defaultSort.active"
      [matSortDirection]="defaultSort.direction" mat-table matSort matSortDisableClear="true">

      <ng-container matColumnDef="transactionTime">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Transaction Time</th>
        <td *matCellDef="let element" mat-cell>
          {{ element?.transactionTime }}
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Message</th>
        <td *matCellDef="let element" mat-cell>
          {{ element?.message }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
        *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </ng-container>
  <ng-template #noData>
    <div class="relative w-full">
      <div class="absolute w-full w-full mt-4 text-base font-semibold text-center">
        N/A
      </div>
    </div>
  </ng-template> 
--></div>
