<div class="flex flex-row space-x-2">
  <div class="font-medium">Photos</div>
  <input
    type="file"
    #file
    (change)="onFilesAdded()"
    multiple
    accept="image/*"
  />
  <div *ngIf="!isReadOnlyMode" class="cursor-pointer" (click)="uploadFiles()">
    <mat-icon
      [svgIcon]="'heroicons_solid:document-plus'"
      class="hover:text-primary-600 dark:hover:text-white"
    ></mat-icon>
  </div>
  <div class="relative" *ngIf="photos?.length > 0">
    <mat-icon
      (click)="toggleSelectAll()"
      class="cursor-pointer"
      [ngClass]="{
        'hover:text-primary-600 dark:hover:text-white': !allSelected,
        'text-blue-600': allSelected
      }"
    >
      check_circle
    </mat-icon>
    <!--    <svg-->
    <!--      *ngIf="allSelected"-->
    <!--      class="absolute top-0 left-0"-->
    <!--      style="z-index: -1 !important; height: 22px; width: 22px"-->
    <!--    >-->
    <!--      <circle fill="white" cx="12" cy="12" r="9"></circle>-->
    <!--    </svg>-->
  </div>
  <div
    *ngIf="anySelected && !isReadOnlyMode"
    class="cursor-pointer"
    (click)="deleteSelected()"
    [matTooltip]="Delete"
  >
    <mat-icon
      [svgIcon]="'heroicons_solid:trash'"
      class="hover:text-primary-600 dark:hover:text-white"
    ></mat-icon>
  </div>
  <div
    *ngIf="anySelected"
    class="cursor-pointer"
    (click)="downloadSelected()"
    [matTooltip]="Download"
  >
    <mat-icon
      [svgIcon]="'heroicons_solid:arrow-down-circle'"
      class="hover:text-primary-600 dark:hover:text-white"
    ></mat-icon>
  </div>
</div>
<div *ngIf="photos?.length > 0; else noPhotos" class="flex flex-wrap -m-2 mt-2">
  <ng-container *ngFor="let photo of photos; let i = index">
    <div
      class="flex flex-col group relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card hover:text-primary-600 dark:hover:text-white"
    >
      <a
        class="absolute top-0 left-0"
        [ngClass]="{ hidden: photo.selected || !showAllSelectors }"
        mat-icon-button
      >
        <mat-icon class="icon-size-5 opacity-70">
          radio_button_unchecked
        </mat-icon>
      </a>
      <a
        class="absolute top-0 left-0"
        (click)="toggleSelected(photo.id)"
        mat-icon-button
      >
        <mat-icon
          class="hidden group-hover:block hover:text-primary-600 dark:hover:text-white"
        >
          check_circle</mat-icon
        >
      </a>
      <!--      <a class="absolute top-0 left-0" *ngIf="photo.selected" mat-icon-button>-->
      <!--        <svg *ngIf="photo.selected" style="height: 22px; width: 22px">-->
      <!--          <circle fill="white" cx="12" cy="12" r="8"></circle>-->
      <!--        </svg>-->
      <!--      </a>-->
      <a
        *ngIf="photo.selected"
        class="absolute top-0 left-0"
        (click)="toggleSelected(photo.id)"
        mat-icon-button
      >
        <mat-icon class="text-blue-600"> check_circle </mat-icon>
      </a>

      <a (click)="setDetail(i)">
        <div class="aspect-w-9 aspect-h-6 cursor-pointer">
          <div class="flex items-center justify-center">
            <img
              class="rounded-xl thumbnail"
              [src]="photo.thumbnailUrl + '?' + (facade.sasToken$ | async)"
            />
          </div>
        </div>
        <div
          class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
        >
          <div class="truncate" [matTooltip]="photo.name">{{ photo.name }}</div>
          <!-- <div class="text-secondary truncate">{{file.size | filesize}}</div>
                <div class="text-secondary truncate">{{file.date | date}}</div> -->
        </div>
      </a>
    </div>
  </ng-container>
</div>
<ng-template #noPhotos>
  <div class="mt-4 font-semibold tracking-tight text-secondary">No photos</div>
</ng-template>
