import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaginatedListOfJobCosting } from 'app/shared/models';
import { catchError, finalize, map, of, switchMap, tap } from 'rxjs';
import { VariationsService } from '../../services/variations.service';
import {
  getJobCostingSummary,
  getJobCostingSummaryFailure,
  getJobCostingSummarySuccess,
  getJobVariations,
  getJobVariationsFailure,
  getJobVariationsSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobVariationsListEffects {
  getVariationsPayload;
  constructor(
    private actions$: Actions,
    private variationsService: VariationsService,
  ) {}

  //   getJobVariations$ = createEffect(
  //     () =>
  //       this.actions$.pipe(
  //         ofType(getJobVariations),
  //         tap(({ request }) => console.log(request))
  //       ),
  //     { dispatch: false }
  //   );

  getJobVariations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobVariations),
      tap(({ request }) => {
        if (request) {
          this.getVariationsPayload = request;
        }
      }),
      switchMap(({ request }) =>
        this.variationsService
          .getVariations(request || this.getVariationsPayload)
          .pipe(
            map((variations: PaginatedListOfJobCosting) =>
              getJobVariationsSuccess({ variations }),
            ),
            catchError((error) => {
              return of(getJobVariationsFailure({ error }));
            }),
          ),
      ),
    ),
  );

  getJobCostingSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobCostingSummary),
      switchMap(({ id }) =>
        this.variationsService.getJobCostingSummary(id).pipe(
          map((summary) => getJobCostingSummarySuccess({ summary })),
          catchError((error) => {
            return of(getJobCostingSummaryFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
