import { AccountUserEffects } from './account-user.effects';
import { CommonDataEffects } from './common-data.effects';
import { FileDownloadEffects } from './file-download.effects';
import { FileUploadEffects } from './file-upload.effects';

export const effects = [
  FileUploadEffects,
  FileDownloadEffects,
  AccountUserEffects,
  CommonDataEffects,
];

export * from './account-user.effects';
export * from './common-data.effects';
export * from './file-download.effects';
export * from './file-upload.effects';
