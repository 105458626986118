import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
    EstimatePriceListItem,
    SingleSaveVariation,
    VariationEx,
} from 'app/shared/models';
import { flatten, sumBy } from 'lodash';
import { catchError, filter, forkJoin, map, of, switchMap } from 'rxjs';
import { AddRemovePriceListItemsDialogComponent } from '../../../../shared/components';
import {
    EstimatePriceListItemDialogComponent,
    EstimateSectionDialogComponent,
} from '../../../estimates/components';
import { RemovePriceListItemsFromJobVariationCostingDialogComponent } from '../../components';
import { VariationsService } from '../../services/variations.service';
import {
  addRemoveVariationPriceListItems,
  addRemoveVariationPriceListItemsShowDialog,
  addSection,
  addVariation,
  changeVariationStatus,
  changeVariationStatusFailure,
  changeVariationStatusSuccess,
  deleteJobVariationPriceListItem,
  deleteJobVariationPriceListItemConfirm,
  deleteSection,
  deleteVariationSectionConfirm,
  getJobVariation,
  getJobVariationFailure,
  getJobVariationSuccess,
  removeJobVariationSectionPriceItemsFromCostingShowDialog,
  renameVariationSection,
  saveJobVariation,
  saveJobVariationFailure,
  saveJobVariationSuccess,
  showAddSectionDialog,
  showAddVariationDialog,
  showRenameSectionDialog,
  updateJobVariationCustomerQuote,
  updateJobVariationOnCost,
  updateJobVariationPriceListItem,
  updateJobVariationProfit,
  updateJobVariationSectionPriceListItems,
  updateJobVariationSectionPriceListItemsShowDialog,
  updateJobVariationTotals, updateStatusForSelectedVariationItem,
  updateVariationPriceListItemShowDialog,
  uploadVariationLetterLogo,
  uploadVariationLetterLogoFailure,
  uploadVariationLetterLogoSuccess,
} from '../actions';
import { JobCostingFacade } from '../facades/job-costing.facade';
import { selectJobVariation } from '../selectors';

@Injectable({
    providedIn: 'root',
})
export class JobVariationDetailEffects {
    constructor(
        private actions$: Actions,
        private variationsService: VariationsService,
        private jobCostingFacade: JobCostingFacade,
        private confirm: FuseConfirmationService,
        private store: Store,
        private dialog: MatDialog
    ) {}

    removeJobVariationSectionPriceItemsFromCostingShowDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    removeJobVariationSectionPriceItemsFromCostingShowDialog
                ),
                switchMap(({ variation, section }) =>
                    this.dialog
                        .open(
                            RemovePriceListItemsFromJobVariationCostingDialogComponent,
                            {
                                data: { variation, section },
                            }
                        )
                        .afterClosed()
                        .pipe(
                            filter((result) => !!result),
                            map((data) =>
                                updateJobVariationSectionPriceListItems(data)
                            )
                        )
                )
            )
    );

    updateJobVariationSectionPriceListItemsShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobVariationSectionPriceListItemsShowDialog),
            switchMap(({ section }) =>
                this.dialog
                    .open(AddRemovePriceListItemsDialogComponent, {
                        data: section,
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => !!result),
                        map((data) =>
                            updateJobVariationSectionPriceListItems(data)
                        )
                    )
            )
        )
    );

    addRemoveVariationPriceListItemsShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addRemoveVariationPriceListItemsShowDialog),
            concatLatestFrom(() => this.store.select(selectJobVariation)),
            switchMap(([{}, variation]) =>
                this.dialog
                    .open(AddRemovePriceListItemsDialogComponent, {
                        data: variation,
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map((data) => addRemoveVariationPriceListItems(data))
        )
    );

    updateJobVariationSectionPriceListItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobVariationSectionPriceListItems),
            map(updateJobVariationTotals)
        )
    );

    updateJobVariationCustomerQuote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobVariationCustomerQuote),
            concatLatestFrom(() => this.store.select(selectJobVariation)),
            map(([{ customerQuote }, variation]) => {
                const netQuote = customerQuote / 1.1;
                const profit = netQuote - variation.quotableUnitTotal;
                const onCost =
                    variation.quotableUnitTotal !== 0
                        ? 100 * (profit / variation.quotableUnitTotal)
                        : 0;
                return updateJobVariationOnCost({ onCost });
            })
        )
    );

    updateJobVariationProfit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobVariationProfit),
            concatLatestFrom(() => this.store.select(selectJobVariation)),
            map(([{ profit }, variation]) => {
                const nonQuotableUnitTotal = sumBy(
                    flatten(variation.sections.map((s) => s.items)).filter(
                        (i) => !i.quotable
                    ),
                    (i) => i.unitTotal
                );
                const onCost =
                    variation.quotableUnitTotal > 0
                        ? 100 *
                          ((profit + nonQuotableUnitTotal) /
                              variation.quotableUnitTotal)
                        : 0;
                return updateJobVariationOnCost({ onCost });
            })
        )
    );

    updateJobVariationOnCost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobVariationOnCost),
            map(updateJobVariationTotals)
        )
    );

    saveJobVariation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveJobVariation),
            concatLatestFrom(() => this.store.select(selectJobVariation)),
            switchMap(([_, variation]) =>
                this.variationsService.save(variation).pipe(
                    map((variation: SingleSaveVariation) =>
                        saveJobVariationSuccess({ variation })
                    ),
                    catchError((error) => {
                        return of(saveJobVariationFailure({ error }));
                    })
                )
            )
        )
    );

    deleteJobVariationPriceListItemConfirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobVariationPriceListItemConfirm),
            switchMap(({ id }) =>
                this.confirm
                    .open({
                        title: 'Delete item',
                        message:
                            'Are you sure you want to delete this item? This action cannot be undone.',
                        icon: {
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        actions: {
                            cancel: { label: 'Cancel' },
                            confirm: { label: 'Delete', color: 'warn' },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => result === 'confirmed'),
                        map(() => deleteJobVariationPriceListItem({ id }))
                    )
            )
        )
    );

    deleteJobVariationPriceListItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobVariationPriceListItem),
            map(updateJobVariationTotals)
        )
    );

    getJobVariation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getJobVariation),
            switchMap(({ variationId }) =>
                this.variationsService.getVariation(variationId).pipe(
                    map((variationEx: VariationEx) =>
                        getJobVariationSuccess({ variationEx })
                    ),
                    catchError((error) => {
                        return of(getJobVariationFailure({ error }));
                    })
                )
            )
        )
    );

    updateJobVariationPriceListItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobVariationPriceListItem),
            map(updateJobVariationTotals)
        )
    );

    changeVariationStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeVariationStatus),
            switchMap(({ jobId, variationId, status }) =>
                this.variationsService
                    .changeStatus(jobId, variationId, status)
                    .pipe(
                        switchMap((variation: SingleSaveVariation) => of(
                            changeVariationStatusSuccess({ variation }),
                            updateStatusForSelectedVariationItem({params: {variationItemId: variation.id, status: variation.status}})
                          )
                        ),
                        catchError((error) => {
                            return of(changeVariationStatusFailure({ error }));
                        })
                    )
            )
        )
    );

    showAddSectionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showAddSectionDialog),
            switchMap(() =>
                this.dialog
                    .open(EstimateSectionDialogComponent, {
                        width: '325px',
                        panelClass: 'app-estimate-section-dialog',
                        data: {
                            area: 'Variation Item',
                        },
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ name }) => addSection({ name }))
        )
    );

    showAddVariationDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showAddVariationDialog),
            switchMap(({ jobId }) =>
                forkJoin([
                    of(jobId),
                    this.dialog
                        .open(EstimateSectionDialogComponent, {
                            width: '325px',
                            data: { area: 'Variation' },
                            panelClass: 'app-estimate-section-dialog',
                        })
                        .afterClosed(),
                ])
            ),
            map(([jobId, { name }]) => {
                if (name.trim().length) {
                    return addVariation({ jobId, name });
                }
            })
        )
    );

    addVariation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addVariation),
                switchMap(({ jobId, name }) =>
                    this.variationsService.addVariation(jobId, name).pipe(
                        map((variation: SingleSaveVariation) => {
                            const query = {
                                pageNumber: 1,
                                pageSize: 999,
                                orderBy: [
                                    { orderBy: 'code', descending: true },
                                ],
                                filter: '',
                                includeDeleted: false,
                                jobId: jobId,
                            };
                            this.jobCostingFacade.getJobVariation(query);
                        })
                    )
                )
            ),
        { dispatch: false }
    );

    updateVariationPriceListItemShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateVariationPriceListItemShowDialog),
            switchMap(({ item }) =>
                this.dialog
                    .open(EstimatePriceListItemDialogComponent, { data: item })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map((item: EstimatePriceListItem) =>
                updateJobVariationPriceListItem({ item })
            )
        )
    );

    deleteVariationSectionConfirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteVariationSectionConfirm),
            switchMap(({ id }) =>
                this.confirm
                    .open({
                        title: 'Delete section',
                        message:
                            'Are you sure you want to delete this section? This action cannot be undone.',
                        icon: {
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        actions: {
                            cancel: { label: 'No' },
                            confirm: { label: 'Yes', color: 'warn' },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => result === 'confirmed'),
                        map(() => deleteSection({ id }))
                    )
            )
        )
    );

    deleteVariationSection$ = createEffect(() =>
        this.actions$.pipe(ofType(deleteSection), map(updateJobVariationTotals))
    );

    showRenameSectionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showRenameSectionDialog),
            switchMap(({ sectionId, name }) =>
                this.dialog
                    .open(EstimateSectionDialogComponent, {
                        width: '325px',
                        data: { id: sectionId, name },
                        panelClass: 'app-estimate-section-dialog',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ id, name }) => renameVariationSection({ id, name }))
        )
    );

    uploadVariationLetterLogo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(uploadVariationLetterLogo),
            switchMap(({ variationId, file }) =>
                this.variationsService
                    .uploadVariationLetterLogo(variationId, file)
                    .pipe(
                        map((fileLocation: any) =>
                            uploadVariationLetterLogoSuccess({
                                fileLocation: fileLocation.fileLocation,
                            })
                        ),
                        catchError((error) => {
                            return of(
                                uploadVariationLetterLogoFailure({ error })
                            );
                        })
                    )
            )
        )
    );
}
