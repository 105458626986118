export interface AddressLookup {
  country?: string;
  state?: string;
  postCode?: string;
  suburb?: string;
  streetAddress?: string;
  latitude?: number;
  longitude?: number;
  locationType?: string;
  placeId?: string;
  id?: string;
  formattedAddress?: string;
}
