import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '@shared/services';
import { CallForwardItemType } from '@shared/models';

@Pipe({
  name: 'filterStatusesByCfType',
  standalone: true,
})

export class FilterStatusesByCfTypePipe implements PipeTransform {
  transform(arr: any[], value: string): any[] {
    if (value && arr) {
      const types = UtilService.convertEnumToObject(CallForwardItemType);
      const type = types.find(t => t.value === value).text.toLowerCase();
      return arr.filter(s => s[type]);
    }
    return arr;
  }
}
