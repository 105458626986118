import { createAction, props } from '@ngrx/store';
import { JobOrderStatusParams } from '../../models/job-orders';

export enum JobOrderPriceListItemActionTypes {
  GetJobOrderPriceListItemFacade = '[JobOrderPriceListItem] Get JobOrder',
  GetJobOrderPriceListItemFacadeSuccess = '[JobOrderPriceListItem] Get JobOrder Success',
  GetJobOrderPriceListItemFacadeFailure = '[JobOrderPriceListItem] Get JobOrder Failure',

  UpdateJobOrderPriceListItemFacade = '[JobOrderPriceListItem] Update JobOrder',
  UpdateJobOrderPriceListItemFacadeSuccess = '[JobOrderPriceListItem] Update JobOrder Success',
  UpdateJobOrderPriceListItemFacadeFailure = '[JobOrderPriceListItem] Update JobOrder Failure',

  AddJobOrderPriceListItemFacade = '[JobOrderPriceListItem] Add JobOrder',
  AddJobOrderPriceListItemFacadeSuccess = '[JobOrderPriceListItem] Add JobOrder Success',
  AddJobOrderPriceListItemFacadeFailure = '[JobOrderPriceListItem] Add JobOrder Failure',

  DeleteJobOrderPriceListItem = '[JobOrderPriceListItem] Delete JobOrder',
  DeleteJobOrderPriceListItemSuccess = '[JobOrderPriceListItem] Delete JobOrder Success',
  DeleteJobOrderPriceListItemFailure = '[JobOrderPriceListItem] Delete JobOrder Failure',

  ClearJobOrderPriceListItemState = '[JobOrderPriceListItem] clearState JobOrder Failure',
}

export const getJobOrderPriceListItem = createAction(
  JobOrderPriceListItemActionTypes.GetJobOrderPriceListItemFacade,
  props<{ request: any }>(),
);
export const getJobOrderPriceListItemSuccess = createAction(
  JobOrderPriceListItemActionTypes.GetJobOrderPriceListItemFacadeSuccess,
  props<{ jobOrder: any }>(),
);
export const getJobOrderPriceListItemFailure = createAction(
  JobOrderPriceListItemActionTypes.GetJobOrderPriceListItemFacadeFailure,
  props<{ error: any }>(),
);

export const updateJobOrderPriceListItem = createAction(
  JobOrderPriceListItemActionTypes.UpdateJobOrderPriceListItemFacade,
  props<{ request: any }>(),
);
export const updateJobOrderPriceListItemSuccess = createAction(
  JobOrderPriceListItemActionTypes.UpdateJobOrderPriceListItemFacadeSuccess,
  props<{ jobOrder: any }>(),
);
export const updateJobOrderPriceListItemFailure = createAction(
  JobOrderPriceListItemActionTypes.UpdateJobOrderPriceListItemFacadeFailure,
  props<{ error: any }>(),
);

export const addJobOrderPriceListItem = createAction(
  JobOrderPriceListItemActionTypes.AddJobOrderPriceListItemFacade,
  props<{ request: any }>(),
);
export const addJobOrderPriceListItemSuccess = createAction(
  JobOrderPriceListItemActionTypes.AddJobOrderPriceListItemFacadeSuccess,
  props<{ jobOrder: any }>(),
);
export const addJobOrderPriceListItemFailure = createAction(
  JobOrderPriceListItemActionTypes.AddJobOrderPriceListItemFacadeFailure,
  props<{ error: any }>(),
);

export const deleteJobOrderPriceListItem = createAction(
  JobOrderPriceListItemActionTypes.DeleteJobOrderPriceListItem,
  props<{ request: any }>(),
);
export const deleteJobOrderPriceListItemSuccess = createAction(
  JobOrderPriceListItemActionTypes.DeleteJobOrderPriceListItemSuccess,
  props<{ jobOrder: any }>(),
);
export const deleteJobOrderPriceListItemFailure = createAction(
  JobOrderPriceListItemActionTypes.DeleteJobOrderPriceListItemFailure,
  props<{ error: any }>(),
);

export const clearJobOrderPriceListItemState = createAction(
  JobOrderPriceListItemActionTypes.ClearJobOrderPriceListItemState,
);
