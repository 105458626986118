<div *ngIf="!connectionActive">
    <fuse-alert [type]="'info'">
        Connect to Xero to see this page
    </fuse-alert>
</div>
<div *ngIf="connectionActive" class="flex flex-col">
    <div class="m-1">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Orders
                    <span
                        class="flex items-center justify-center shrink-0 min-w-4 h-4 ml-1 px-1 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                        {{ordersNumber}}
                    </span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="flex flex-row justify-end">
                <button mat-raised-button color="primary" [disabled]="ordersNumber == 0 || syncingOrders"
                    (click)="syncOrdersOnClick()">Sync Orders</button>
            </div>

            <div *ngIf="!(jobOrdersListFacade.isLoading$ | async)">
                <div *ngIf="ordersNumber > 0; else ordersNoData">
                    <table mat-table [dataSource]="ordersDataSource" matSort
                        class="w-full border-t border-slate-200 overflow-y-auto">

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRowsOrders() : null"
                                    [checked]="selectionOrders.hasValue() && isAllSelectedOrders()"
                                    [indeterminate]="selectionOrders.hasValue() && !isAllSelectedOrders()"
                                    [aria-label]="checkboxLabelOrders()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selectionOrders.toggle(row) : null"
                                    [checked]="selectionOrders.isSelected(row)" [aria-label]="checkboxLabelOrders(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="orderCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Number
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ element.orderCode }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="orderDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Date
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ element.orderDate | date: "dd MMM yyyy" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="supplier">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Supplier
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ element.supplier?.name }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>Total Price</th>
                            <td mat-cell *matCellDef="let element; let i = index">
                                {{ element.totalAmount | currency }}
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="ordersDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: ordersDisplayedColumns; let i = index"
                            class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"></tr>
                    </table>
                </div>
                <ng-template #ordersNoData>
                    <div class="w-full top-1/2 xs:text-lg md:text-4xl font-semibold text-center">
                        <span class="text-lg">Nothing to sync</span>
                    </div>
                </ng-template>
            </div>
        </mat-expansion-panel>
    </div>

    <div class="m-1">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Invoices
                    <span
                        class="flex items-center justify-center shrink-0 min-w-4 h-4 ml-1 px-1 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                        {{invoicesNumber}}
                    </span>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="flex flex-row justify-end">
                <button mat-raised-button color="primary" [disabled]="(invoicesNumber === 0) || syncingInvoices"
                    (click)="syncInvoicesOnClick()">Sync Invoices</button>
            </div>
            <div *ngIf="(invoicesFacade.isLoaded$ | async)">
                <div *ngIf="invoicesNumber > 0; else invoiceNoData">

                    <table *ngIf="invoicesNumber > 0; else noData" mat-table [dataSource]="invoiceDataSource" matSort
                        class="w-full">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRowsInvoice() : null"
                                    [checked]="selectionInvoice.hasValue() && isAllSelectedInvoice()"
                                    [indeterminate]="selectionInvoice.hasValue() && !isAllSelectedInvoice()"
                                    [aria-label]="checkboxLabelInvoice()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selectionInvoice.toggle(row) : null"
                                    [checked]="selectionInvoice.isSelected(row)"
                                    [aria-label]="checkboxLabelInvoice(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="invoiceCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.invoiceCode }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Description
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.description }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="invoiceDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.invoiceDate | date: "dd MMM yyyy" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="paymentDueDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.paymentDueDate | date: "dd MMM yyyy" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="invoicedAmount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Amount Due
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.invoicedAmount | currency }}
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="invoiceDisplayedColumns"></tr>
                        <tr class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer" (click)="details(row)"
                            mat-row *matRowDef="let row; columns: invoiceDisplayedColumns"></tr>
                    </table>

                </div>
                <ng-template #invoiceNoData>
                    <div class="w-full top-1/2 xs:text-lg md:text-4xl font-semibold text-center">
                        <span class="text-lg">Nothing to sync</span>
                    </div>
                </ng-template>
            </div>
        </mat-expansion-panel>


    </div>

    <div class="m-1">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Issues
                    <span
                        class="flex items-center justify-center shrink-0 min-w-4 h-4 ml-1 px-1 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                        {{issuesNumber}}
                    </span>

                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>


            </mat-expansion-panel-header>

            <div class="flex flex-row justify-end">
                <button mat-raised-button color="primary" [disabled]="(issuesNumber === 0) || syncingIssues"
                    (click)="removeIssuesOnClick()">Remove</button>
                <button mat-raised-button color="primary" [disabled]="(issuesNumber === 0) || syncingIssues"
                    (click)="syncIssuesOnClick()">Sync</button>
            </div>

            <table *ngIf="issuesNumber > 0; else issuesNoData" mat-table [dataSource]="issuesDataSource" matSort
                class="w-full">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? toggleAllRowsIssues() : null"
                            [checked]="selectionIssues.hasValue() && isAllSelectedIssues()"
                            [indeterminate]="selectionIssues.hasValue() && !isAllSelectedIssues()"
                            [aria-label]="checkboxLabelIssues()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selectionIssues.toggle(row) : null"
                            [checked]="selectionIssues.isSelected(row)" [aria-label]="checkboxLabelInvoice(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="referenceId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference Id</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.referenceId }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="message">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Message
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.message }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{getExportType(element.exportType)}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="issueDisplayedColumns"></tr>
                <tr class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer" (click)="details(row)" mat-row
                    *matRowDef="let row; columns: issueDisplayedColumns"></tr>
            </table>

            <ng-template #issuesNoData>
                <div class="w-full top-1/2 xs:text-lg md:text-4xl font-semibold text-center">
                    <span class="text-lg">Nothing to sync</span>
                </div>
            </ng-template>
        </mat-expansion-panel>
    </div>
</div>