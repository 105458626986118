/* eslint-disable arrow-parens */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import { AccountUserFacade } from '../store/facades/account-user.facade';

@Injectable({
  providedIn: 'root',
})
export class SasGuard implements CanActivate {
  constructor(private facade: AccountUserFacade) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.getSasToken().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false)),
    );
  }

  private getSasToken(): Observable<string> {
    return this.facade.sasToken$.pipe(
      tap((token) => this.prefetchToken()),
      filter((token) => !!token),
      take(1),
    );
  }

  private prefetchToken(): void {
    this.facade.generateSasToken();
  }
}
