/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import { FileUploadType, Photo } from 'app/shared/models';
import { fileUploadCompleted } from 'app/shared/store/actions';
import { CallState, LoadingState } from 'app/store';
import {
  createJobPhotosFolderFailure,
  createJobPhotosFolderSuccess,
  deleteJobPhotosFailure,
  deleteJobPhotosSuccess,
  getJobPhotosFailure,
  getJobPhotosSuccess,
  renameJobPhotosFolderSuccess,
  setDetailPhotoIndex,
  setJobPhotosCurrentFolderPath,
  setJobPhotosSortField,
  setJobPhotosSortOrder,
  toggleJobPhotoFileSelected,
  toggleJobPhotosFolderSelected,
  deselectPhotoIds,
  selectPhotoIds,
  selectJobPhotosFolderPaths,
  deselectJobPhotosFolderPaths,
  deleteJobPhotosFoldersSuccess,
} from '..';

export enum PhotosListSortField {
  Date = 'date',
  Name = 'name',
  Size = 'size',
}
export interface PhotosState {
  callState: CallState;
  photos: Photo[];
  currentFolderPath: string;
  selectedFileIds: string[];
  selectedFolderPaths: string[];
  sortField: string;
  sortOrder: 'asc' | 'desc';
  detailPhotoIndex: number;
}

export const initialState: PhotosState = {
  callState: LoadingState.INIT,
  photos: [],
  currentFolderPath: 'Home\\',
  selectedFileIds: [],
  selectedFolderPaths: [],
  sortField: 'name',
  sortOrder: 'asc',
  detailPhotoIndex: null,
};

export const jobPhotosReducer = createReducer(
  initialState,
  on(selectPhotoIds, (state, { ids }) => ({
    ...state,
    selectedFileIds: ids,
  })),
  on(deselectPhotoIds, (state, { ids }) => ({
    ...state,
    selectedFileIds: state.selectedFileIds.filter((f) => !ids.includes(f)),
  })),
  on(toggleJobPhotoFileSelected, (state, { id }) => ({
    ...state,
    selectedFileIds: state.selectedFileIds.includes(id)
      ? state.selectedFileIds.filter((f) => f !== id)
      : [...state.selectedFileIds, id],
  })),
  on(toggleJobPhotosFolderSelected, (state, { path }) => ({
    ...state,
    selectedFolderPaths: state.selectedFolderPaths.includes(path)
      ? state.selectedFolderPaths.filter((f) => f !== path)
      : [...state.selectedFolderPaths, path],
  })),
  on(renameJobPhotosFolderSuccess, (state, { photos }) => ({
    ...state,
    photos: state.photos.map((f) => {
      const renamedPhotos = photos.find((x) => x.id === f.id);
      return renamedPhotos ?? f;
    }),
  })),
  on(createJobPhotosFolderSuccess, (state, { photo }) => ({
    ...state,
    photos: [...state.photos, photo],
  })),
  on(createJobPhotosFolderFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(deleteJobPhotosSuccess, (state, { ids }) => ({
    ...state,
    photos: state.photos.filter((p) => !ids.includes(p.id)),
    selectedFileIds: [],
  })),
  on(deleteJobPhotosFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(setDetailPhotoIndex, (state, { index }) => ({
    ...state,
    detailPhotoIndex: index,
  })),
  on(fileUploadCompleted, (state, { entity, file }) => ({
    ...state,
    photos:
      file.uploadType === FileUploadType.JobPhoto
        ? [...state.photos, entity as Photo]
        : [...state.photos],
  })),
  on(getJobPhotosSuccess, (state, { photos }) => ({
    ...state,
    callState: LoadingState.LOADED,
    photos,
    detailPhotoIndex: null,
  })),
  on(getJobPhotosFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),
  on(setJobPhotosCurrentFolderPath, (state, { path }) => ({
    ...state,
    selectedFileIds: [],
    currentFolderPath: path,
    detailPhotoIndex: null,
  })),
  on(setJobPhotosSortField, (state, { sortField }) => ({
    ...state,
    sortField,
  })),
  on(setJobPhotosSortOrder, (state, { sortOrder }) => ({
    ...state,
    sortOrder,
  })),
  on(deselectJobPhotosFolderPaths, (state, { paths }) => ({
    ...state,
    selectedFolderPaths: state.selectedFolderPaths.filter(
      (p) => !paths.includes(p),
    ),
  })),
  on(selectJobPhotosFolderPaths, (state, { paths }) => ({
    ...state,
    selectedFolderPaths: paths,
  })),
  on(deleteJobPhotosFoldersSuccess, (state, { ids }) => ({
    ...state,
    photos: state.photos.filter((f) => !ids.includes(f.id)),
    selectedFolderPaths: [],
  })),
);
