/* eslint-disable ngrx/on-function-explicit-return-type */
/* eslint-disable arrow-parens */
import { createReducer, on } from '@ngrx/store';
import { FullPriceList } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import { sortBy, uniq } from 'lodash';
import {
  addCurrentPriceListCategorySuccess,
  addCurrentPriceListItemSuccess,
  deleteCurrentPriceListCategorySuccess,
  deleteCurrentPriceListItemsSuccess,
  deleteSinglePriceListItemSuccess,
  deselectCurrentPriceListItems,
  editCurrentPriceListCategorySuccess,
  editCurrentPriceListItemSuccess,
  getCurrentPriceList,
  getCurrentPriceListFailure,
  getCurrentPriceListSuccess,
  selectCurrentPriceListItems,
  updateCurrentPriceListCategory,
  updateCurrentPriceListCategoryFailure,
  updateCurrentPriceListCategorySuccess,
} from '../actions';

export interface CurrentPriceListState {
  priceList: FullPriceList;
  selectedPriceListItemIds: string[];
  callState: CallState;
  error: any;
}

export const initialState: CurrentPriceListState = {
  priceList: null,
  selectedPriceListItemIds: [],
  callState: LoadingState.INIT,
  error: null,
};

export const currentPriceListReducer = createReducer(
  initialState,
  on(addCurrentPriceListItemSuccess, (state, { item }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListItems: [...state.priceList.priceListItems, item],
    },
  })),
  on(editCurrentPriceListItemSuccess, (state, { item }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListItems: state.priceList.priceListItems.map((pit) => {
        if (pit.id === item.id) {
          pit = item;
        }
        return pit;
      }),
    },
  })),
  on(deleteSinglePriceListItemSuccess, (state, { id }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListItems: state.priceList.priceListItems.filter((pit) => {
        if (pit.id !== id) {
          return pit;
        }
      }),
    },
  })),
  on(addCurrentPriceListCategorySuccess, (state, { category }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListCategories: sortBy(
        [...state.priceList.priceListCategories, category],
        (c) => c.displayOrder,
      ).reverse(),
    },
  })),
  on(editCurrentPriceListCategorySuccess, (state, { category }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListCategories: state.priceList.priceListCategories.map((item) => {
        if (item.id === category.id) {
          item = category;
        }
        return item;
      }),
    },
  })),
  on(deleteCurrentPriceListCategorySuccess, (state, { category }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListCategories: state.priceList.priceListCategories.filter(
        (item) => {
          if (item.id !== category.id) {
            return item;
          }
        },
      ),
    },
  })),
  on(selectCurrentPriceListItems, (state, { ids }) => ({
    ...state,
    selectedPriceListItemIds: uniq([...state.selectedPriceListItemIds, ...ids]),
  })),
  on(deselectCurrentPriceListItems, (state, { ids }) => ({
    ...state,
    selectedPriceListItemIds: state.selectedPriceListItemIds.filter(
      (id) => !ids.includes(id),
    ),
  })),
  on(deleteCurrentPriceListItemsSuccess, (state, { items }) => {
    const ids = items.map((i) => i.id);

    return {
      ...state,
      priceList: {
        ...state.priceList,
        priceListItems: state.priceList.priceListItems.filter(
          (i) => !ids.includes(i.id),
        ),
      },
    };
  }),
  on(updateCurrentPriceListCategory, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateCurrentPriceListCategoryFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateCurrentPriceListCategorySuccess, (state, { category }) => ({
    ...state,
    priceList: {
      ...state.priceList,
      priceListCategories: sortBy(
        [...category],
        (c) => c.displayOrder,
      ).reverse(),
    },
    callState: LoadingState.LOADED,
  })),
  on(getCurrentPriceList, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getCurrentPriceListFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getCurrentPriceListSuccess, (state, { priceList }) => ({
    ...state,
    priceList,
    callState: LoadingState.LOADED,
  })),
);

function reorderCategory(priceList, category): FullPriceList {
  priceList.priceListCategories = category;
  return priceList;
}
