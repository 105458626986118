<div class="flex flex-col bg-card shadow rounded-xl p-4">
  <div class="flex flex-row justify-between">
    <div class="flex items-center text-sm">
      {{ note.updated | date: "dd MMM yyyy" }}
    </div>
    <div>
      <ng-container *ngIf="!isEditing && !isReadOnlyMode">
        <button
          (click)="onEdit()"
          class="text-xs rounded-xl min-h-8 h-8 px-4"
          mat-flat-button
          [color]="'primary'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
          <span class="ml-2 mr-1">Edit</span>
        </button>
        <button
          (click)="onDelete()"
          class="text-xs rounded-xl min-h-8 h-8 px-4 ml-2"
          mat-flat-button
          [color]="'accent'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
          <span class="ml-2">Delete</span>
        </button>
      </ng-container>

      <ng-container *ngIf="isEditing">
        <button
          [disabled]="!(notesForm.valid && notesForm.dirty)"
          (click)="save()"
          class="text-xs rounded-xl min-h-8 h-8 px-4"
          mat-flat-button
          [color]="'primary'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
          <span class="ml-2 mr-1">Save</span>
        </button>
        <button
          (click)="cancel()"
          class="text-xs rounded-xl min-h-8 h-8 px-4 ml-2"
          mat-flat-button
          [color]="'accent'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
          <span class="ml-2">Cancel</span>
        </button>
      </ng-container>
    </div>
  </div>
  <form class="flex flex-col mt-2 notes" [formGroup]="notesForm">
    <quill-editor
      *ngIf="!isEditing"
      class="ql-collapsed"
      [modules]="{ toolbar: false }"
      formControlName="content"
      [readOnly]="true"
    >
    </quill-editor>
<!--    <quill-editor-->
<!--      *ngIf="isEditing"-->
<!--      placeholder="Type here at least 3 characters"-->
<!--      (onContentChanged)="onContentChanged()"-->
<!--      formControlName="content"-->
<!--      [minLength]="3"-->
<!--      [required]="true"-->
<!--    >-->
<!--    </quill-editor>-->
    <app-quill-editor-element
      *ngIf="isEditing"
      [formControl]="content"
    ></app-quill-editor-element>
  </form>
</div>
