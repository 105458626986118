/* eslint-disable arrow-body-style */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  concatAll,
  concatMap,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';

import * as fromActions from '../actions/team-settings.actions';
import * as fromAccountUserActions from 'app/shared/store/actions/account-user.actions';
import { concat, zip } from 'lodash';
import { AccountTeamMember } from '@app/shared/models/form-ui.models';
import { fork } from 'child_process';
import { BillingService } from '@app/core/billing/billing.service';
import { ToastService } from '@app/shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class TeamSettingsEffects {
  addExtraSeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addExtraSeat),
      switchMap(() => {
        return this.billingService.addExtraSeat().pipe(
          map((data: any) => {
            return fromActions.addExtraSeatSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            return of(fromActions.addExtraSeatFailure({ payload: error }));
          }),
        );
      }),
    ),
  );

  getAvailableSeats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAvailableSeats),
      switchMap(() => {
        return this.settingsService.getAvailableSeats().pipe(
          map((data: any) => {
            return fromActions.getAvailableSeatsSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            return of(fromActions.getAvailableSeatsFailure({ payload: error }));
          }),
        );
      }),
    ),
  );
  getProfileSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTeam),
      switchMap(() => {
        return this.settingsService.getTeam().pipe(
          map((data: any) => {
            return fromActions.getTeamSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            return of(fromActions.getTeamFailure({ payload: error }));
          }),
        );
      }),
    ),
  );

  inviteTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.inviteTeamMember),
      concatMap(({ payload }) => {
        console.log('inviteTeamMember$:::', payload);
        return this.settingsService.inviteTeamMember(payload).pipe(
          switchMap((r) => {
            return this.settingsService.getAvailableSeats().pipe(
              map((x) => {
                return fromActions.inviteTeamMemberSuccess({
                  payload: r,
                  availableSeats: x,
                });
              }),
            );
          }),
        );
      }),
    ),
  );

  removeTeamMemberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeTeamMember),
      switchMap(({ payload }) => {
        const t1 = this.settingsService.removeTeamMember(payload);
        const t2 = this.settingsService.getAvailableSeats();

        return this.settingsService.removeTeamMember(payload).pipe(
          switchMap((r) => {
            return this.settingsService.getAvailableSeats().pipe(
              map((x) => {
                return fromActions.removeTeamMemberSuccess({
                  payload: r,
                  availableSeats: x,
                });
              }),
            );
          }),
        );
      }),
    ),
  );

  updateTeamMemberEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTeamMemberRole),
      concatMap(({ payload }) => {
        return this.settingsService.updateTeamMemberRole(payload).pipe(
          map((data) => {
            this.toast.success('Team member updated');
            console.log('Roles updated post toast');
            return fromActions.updateTeamMemberRoleSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            this.toast.error('Team member update failed');
            return of(
              fromActions.updateTeamMemberRoleFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  regetAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTeamMemberRoleSuccess),
      switchMap(() => of(fromAccountUserActions.loginAccountUser())),
    ),
  );

  getCommonTeamMemberData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCommonTeamMemberData),
      switchMap(() => {
        const mt = this.settingsService.getTeamMemberTypes();
        const rl = this.settingsService.getRoles();
        const rla = this.settingsService.getRolesAccessLevels();
        const aS = this.settingsService.getAvailableSeats();

        return forkJoin([mt, rl, rla, aS]).pipe(
          map(([teamMemberTypes, features, accessLevels, availableSeats]) => {
            const combined = {
              teamMemberTypes,
              features,
              accessLevels,
              availableSeats,
            };
            console.log('test', availableSeats);

            return fromActions.getCommonTeamMemberDataSuccess({
              payload: combined,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.getCommonTeamMemberDataFailure({ payload: error }),
            );
          }),
        );
      }),
    ),
  );

  getSelectedTeamMemberMergedRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSelectedTeamMemberMergedRoles),
      switchMap(({ payload }) => {
        return this.settingsService.getTeamMemberMergedRoles(payload).pipe(
          map((roles) => {
            return fromActions.getSelectedTeamMemberMergedRolesSuccess({
              payload: roles,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.getSelectedTeamMemberMergedRolesFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  updateTeamMemberAccessLevel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTeamMemberAccessLevel),
      switchMap(({ payload }) => {
        return this.settingsService.updateTeamMemberAccessLevel(payload).pipe(
          map((teamMember) => {
            return fromActions.updateTeamMemberAccessLevelSuccess({
              payload: teamMember,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.updateTeamMemberAccessLevelFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  updateTeamMemberRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTeamMemberRoles),
      switchMap(({ payload }) => {
        return this.settingsService.updateTeamMemberRoles(payload).pipe(
          map((teamMember) => {
            this.toast.success('Team member roles updated');
            return fromActions.updateTeamMemberRolesSuccess({
              payload: teamMember,
            });
          }),

          catchError((error: any) => {
            this.toast.error('Team member role update failed');
            return of(
              fromActions.updateTeamMemberRolesFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private settingsService: SettingsService,
    private billingService: BillingService,
    private toast: ToastService,
  ) {}
}
