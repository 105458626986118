<div
  class="xs:text-xl sm:text-2xl md:text-4xl font-extrabold tracking-tight leading-none border-b pb-3 mb-5"
>
  Create new orders
</div>

<mat-dialog-content
  fuseScrollbar
  [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
  class="pb-1 relative"
>
  <div
    *ngIf="jobSummaryFacade.isLoading$ | async"
    class="absolute w-full h-full flex items-center justify-center"
  ></div>

  <p class="mb-2" *ngIf="jobSummaryFacade.isLoaded$ | async">
    {{
      dataSource?.data?.length
        ? "Create order for unordered items for selected suppliers. Draft orders will be updated."
        : "All orders have already been created."
    }}
  </p>

  <ng-container *ngIf="jobSummaryFacade.isLoaded$" [formGroup]="form">
    <!--      <div class="mb-2" >-->
    <!--        <mat-checkbox [checked]="(suppliersListByJobFacade.useCategories$ | async).purchaseOrderAtCategoryLevel"-->
    <!--                      [disabled]="(suppliersListByJobFacade.useCategories$ | async).canEdit === false"-->
    <!--                      [color]="'primary'"-->
    <!--                      (change)="changeUseCategories($event)">-->
    <!--          Create a separate Purchase Order for each Supplier and Category-->
    <!--        </mat-checkbox>-->
    <!--      </div>-->
    <div class="mb-2 pb-2 border-b">
      <mat-checkbox formControlName="emailToSuppliers" [color]="'primary'">
        Email to suppliers
      </mat-checkbox>
    </div>
    <!--        <div class="mb-2">-->
    <!--          <mat-checkbox formControlName="all" [color]="'primary'" (change)="changeAll($event)">-->
    <!--               Select all suppliers-->
    <!--          </mat-checkbox>-->
    <!--        </div>-->
    <!--        <ng-container *ngIf="(suppliersListByJobFacade.isLoaded$ | async)" formArrayName="suppliers">-->
    <!--            <div *ngFor="let supplier of (suppliersListByJobFacade.items$ | async); let i = index;" class="mb-2">-->
    <!--                <ng-container [formGroupName]="i">-->
    <!--                    <mat-checkbox formControlName="checked" [color]="'primary'" (change)="change()">-->
    <!--                        {{supplier.name}}-->
    <!--                    </mat-checkbox>-->
    <!--                </ng-container>-->
    <!--            </div>-->
    <!--        </ng-container>-->
    <!--      <div class="flex flex-col flex-auto overflow-hidden">-->
    <!--        <div-->
    <!--          class="w-full max-h-1/3"-->
    <!--          [fuseScrollbarOptions]="{-->
    <!--          wheelPropagation: false,-->
    <!--          suppressScrollX: true-->
    <!--        }"-->
    <!--          fuseScrollbar-->
    <!--        >-->
    <table
      class="w-full hidden sm:table"
      [dataSource]="dataSource"
      mat-table
      matSort
    >
      <ng-container matColumnDef="selected">
        <th *matHeaderCellDef mat-header-cell>
          <mat-checkbox
            [checked]="allSelected"
            (change)="selectAll($event)"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td *matCellDef="let element; let i = index" mat-cell>
          <mat-checkbox
            [checked]="element.checked"
            (change)="onSelectItemChange(element, $event)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="supplier">
        <th class="pr-4" *matHeaderCellDef mat-header-cell mat-sort-header>
          Supplier
        </th>
        <td class="pr-4" *matCellDef="let element" mat-cell>
          {{ element.supplier }}
        </td>
      </ng-container>

      <ng-container matColumnDef="priceListCategoryName">
        <th class="pr-4" *matHeaderCellDef mat-header-cell mat-sort-header>
          Category
        </th>
        <td class="pr-4" *matCellDef="let element" mat-cell>
          {{ element.priceListCategoryName }}
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No items to order.
        </td>
      </tr>
    </table>
    <!--        </div>-->
    <!--      </div>-->
  </ng-container>
  <div
    *ngIf="jobSummaryFacade.isLoading$ | async"
    class="flex items-center justify-center"
  >
    <mat-progress-spinner
      [diameter]="24"
      [mode]="'indeterminate'"
    ></mat-progress-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex flex-row justify-end border-t pt-2 w-full">
    <button (click)="close()" class="fuse-mat-button-large" mat-stroked-button>
      <span class="ml-2 mr-1">{{
        dataSource?.data?.length ? "Cancel" : "Close"
      }}</span>
    </button>
    <button
      [disabled]="!hasSelected()"
      *ngIf="dataSource?.data?.length"
      (click)="createNew()"
      class="ml-4 fuse-mat-button-large"
      mat-flat-button
      [color]="'primary'"
    >
      <span class="ml-2 mr-1">Create orders</span>
    </button>
  </div>
</mat-dialog-actions>
