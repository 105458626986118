import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  RouterLinkActive,
  RouterLink,
  RouterOutlet, Router, NavigationEnd,
} from '@angular/router';
import { BackNavigationService } from 'app/shared/services/back-navigation.service';
import {
  JobDetailFacade,
  JobHistoryFacade,
  JobVariationActionTypes,
  JobVariationDetailFacade,
} from 'app/views/jobs/store';
import { CurrentPriceListFacade } from 'app/views/price-list/store/facades';
import { ofType } from '@ngrx/effects';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActionsSubject } from '@ngrx/store';
import { catchError, combineLatestWith, of, Subject } from 'rxjs';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { JobCostingFacade } from '@app/views/jobs/store/facades/job-costing.facade';
import { MatButtonModule } from '@angular/material/button';
import { EstimateStatus } from '@shared/models';
import { ToastService } from '@shared/services/toast.service';
import { SharedModule } from '@shared/shared.module';
import { VariationsService } from '@app/views/jobs/services/variations.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LeavePageService } from '@app/shared/services/leave-page.service';

@Component({
  selector: 'app-job-variation-detail-container',
  templateUrl: './job-variation-detail-container.component.html',
  styleUrls: ['./job-variation-detail-container.component.scss'],
  standalone: true,
  imports: [SharedModule, MatTabsModule, MatButtonModule, NgIf, NgFor, RouterLinkActive, RouterLink, RouterOutlet, AsyncPipe],
})
export class JobVariationDetailContainerComponent implements OnInit, OnDestroy {
  menuItems;
  unsubscribeAll$ = new Subject<void>();
  canResetVariationToQuoted$;
  canResetVariationToDraft$;
  variationsCountForStatus$;
  jobId: string;
  variationId: string;
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'] ? true : (this.route.queryParams['value']?.isReadOnly  ? true : false) ;
  isCostingPage = this.router.url.endsWith('variation');
  public variation$ = this.variationFacade.variation$;

  variationStatusesList = [
    {
      name: 'Draft',
      value: EstimateStatus.Draft,
    },
    {
      name: 'Quoted',
      value: EstimateStatus.Quoted,
    },
    {
      name: 'Rejected',
      value: EstimateStatus.Rejected,
    },
    {
      name: 'Withdrawn',
      value: EstimateStatus.Withdrawn,
    },
    {
      name: 'Accepted',
      value: EstimateStatus.Accepted,
    },
  ];

  constructor(
    private backNavigationService: BackNavigationService,
    private route: ActivatedRoute,
    private router: Router,
    private priceListFacade: CurrentPriceListFacade,
    public leavePageService: LeavePageService,
    private actionsSubj: ActionsSubject,
    public variationFacade: JobVariationDetailFacade,
    public jobDetailFacade: JobDetailFacade,
    public jobCostingFacade: JobCostingFacade,
    public jobHistoryFacade: JobHistoryFacade,
    private toast: ToastService,
    private asyncPipe: AsyncPipe,
    private variationService: VariationsService,
    private confirm: FuseConfirmationService,
  ) {}

  ngOnDestroy(): void {
    this.variationFacade.clearVariationChangesState();
    this.backNavigationService.reset();
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe(r =>  {
        this.isCostingPage = this.router.url.endsWith('variation');
    });

    this.route.queryParams.subscribe(params=>{
      if(params?.isReadOnly){
       this.isReadOnlyMode = true;
      }
     })
    this.variationFacade.isOriginal$
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((original) => {
        if (original) {
          this.menuItems = [
            {
              text: 'Estimate Costing',
              route: 'variation',
            },
            {
              text: 'Quote',
              route: 'quote',
            },
          ];
        } else {
          this.menuItems = [
            {
              text: 'Variation Costing',
              route: 'variation',
            },
            {
              text: 'Variation Letter',
              route: 'letter',
            },
          ];
        }
      });
    this.jobId = this.route.parent.parent.snapshot.params.id;
    this.jobDetailFacade.getOriginalitems(this.jobId);

    this.priceListFacade.getCurrentPriceList();

    this.variationId = this.route.snapshot.params.id;

    if (this.router.url.includes('complete-estimate')) {
      const jobCostingSingle = this.asyncPipe.transform(this.jobCostingFacade.jobCostingSingle$);
      if (!(jobCostingSingle && jobCostingSingle.id === this.variationId)) {
        this.jobCostingFacade.getJobCostingSingle(this.variationId);
      }
      this.menuItems = [
        {
          text: 'Costing',
          route: 'complete-estimate',
        },
        {
          text: 'Quote Letter',
          route: 'complete-estimate-letter',
        },
      ];
    }
    else if (this.router.url.endsWith('job-history')) {
      this.jobHistoryFacade.getJobHistorySingle(this.variationId);
      this.menuItems = [];
    }
    else {
      this.variationFacade.getJobVariation(this.variationId);
      this.canResetVariationToQuoted$ = this.variationFacade.variation$.pipe(
        takeUntil(this.unsubscribeAll$),
        filter((variation) => !!variation),
        switchMap((variation) => {
          if (variation.status === EstimateStatus.Accepted) {
            return this.variationService.canResetVariation(this.jobId, this.variationId).pipe(
              map((r) => of(true)),
              catchError(() => of(false)),
            );
          } else {
            return of(false);
          }
        }),
      );

      this.canResetVariationToDraft$ = this.variationFacade.status$.pipe(
        filter(Boolean),
        combineLatestWith(this.jobCostingFacade.jobVariation$.pipe(filter(Boolean))),
        map(([status, {items}]) => {
          return (status === EstimateStatus.Rejected || status === EstimateStatus.Withdrawn) &&
            items.every((item) =>
              item.status === EstimateStatus.Rejected ||
              item.status === EstimateStatus.Withdrawn ||
              item.status === EstimateStatus.Accepted
            )
        })
      )
    }

    this.backNavigationService.tooltip = 'Back to Variation list';
    this.backNavigationService.url = `/jobs/${this.jobId}/variations/list`;

    this.actionsSubj
      .pipe(
        ofType(JobVariationActionTypes.SaveJobVariationSuccess),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe((r) => {
        this.jobDetailFacade.getOriginalitems(this.jobId);
      });
  }

  addVariationSection() {
    this.variationFacade.addSection(null);
  }
  updateVariationStatus(value) {
    if (this.isReadOnlyMode) return;
    let status;
    switch (value) {
      case EstimateStatus.Withdrawn:
        status = 'withdrawn';
        break;
      case EstimateStatus.Accepted:
        status = 'accepted';
        break;
      case EstimateStatus.Draft:
        status = 'draft';
        break;
      case EstimateStatus.Quoted:
        status = 'quote';
        break;
      case EstimateStatus.Rejected:
        status = 'rejected';
        break;
    }
    const variation = this.asyncPipe.transform(this.variationFacade.variation$);
    const originatedVariation = this.asyncPipe.transform(this.variationFacade.originatedVariation$);
    if (
      value === EstimateStatus.Accepted &&
      !variation.sections?.every(s => s.items?.length)
    ) {
      this.toast.warn('It is impossible to accept empty variation.');
      return;
    }
    if (
      value === EstimateStatus.Accepted &&
      !originatedVariation.sections?.every(s => s.items?.length)
    ) {
      this.toast.warn(
        'You have unsaved changes. Save your changes before updating variation status.',
      );
      return;
    }

    if ((value === EstimateStatus.Draft || value === EstimateStatus.Quoted) && this.variationsCountForStatus(value) > 0) {
      this.toast.warn('You already have a Variation in Draft / Quoted status.');
      return;
    }

    this.variationFacade.changeStatus(
      this.asyncPipe.transform(this.jobDetailFacade.job$).id,
      this.asyncPipe.transform(this.variationFacade.variation$).id,
      status,
    );
  }

  variationsCountForStatus(status: number) {
    const  res=  this.asyncPipe.transform(this.jobCostingFacade.jobVariation$.pipe(
      filter(Boolean),
      map(({items}) => items.filter(i => i.status === status).length)
    ))
    return  res
  }

  resetVariationToQuotedStatus(): void {
    if (this.leavePageService.needSave) {
      this.save();
    }
    else {
      this.confirm
        .open({
          title: 'Reset Variation to Quoted',
          message: 'Are you sure you want to reset variation to quoted?',
          icon: {
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
          },
          actions: {
            cancel: { label: 'Cancel' },
            confirm: { label: 'Confirm', color: 'warn' },
          },
        })
        .afterClosed()
        .pipe(filter((result) => result === 'confirmed'))
        .subscribe(() => {
          this.variationService.resetVariation(this.jobId, this.variationId).subscribe(
            (res) => {
              this.confirm
                .open({
                  title: 'Reset Variation to Quoted',
                  message:
                    'The Variation has been successfully reset to quoted',
                  icon: {
                    name: 'heroicons_outline:check-circle',
                    color: 'success',
                  },
                  actions: {
                    cancel: {
                      show: false,
                    },
                    confirm: { label: 'OK', color: 'primary' },
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  this.variationFacade.getJobVariation(this.variationId);
                });
            },
            (errorMessage) => {
              this.confirm.open({
                title: 'Error in Reset Variation to Quoted',
                message: errorMessage.error.message,
                icon: {
                  name: 'heroicons_outline:exclamation-triangle',
                  color: 'warn',
                },
                actions: {
                  cancel: {
                    show: false,
                  },
                  confirm: { label: 'OK', color: 'warn' },
                },
              });
            },
          );
        });
    }
  }

  save(): void {
    this.confirm
      .open({
        title: 'Save Changes?',
        message:
          'You have unsaved changes',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Save', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'confirmed'),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe(() => {
        this.variationFacade.save();
        this.variationFacade.isLoaded$.pipe(take(1)).subscribe(loaded => {
          if (loaded) {
            this.leavePageService.needSave = false;
            this.resetVariationToQuotedStatus()
          }
        })
      });
  }

}
