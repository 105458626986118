import { createAction, props } from '@ngrx/store';

interface Query {
  jobId: string;
  supplierId: string;
}

export enum JobOrderItemsToOrderActionTypes {
  GetJobOrderItemsToOrder = '[JobOrder JobOrderItemsToOrder List] Get JobOrderItemsToOrder',
  GetJobOrderItemsToOrderSuccess = '[JobOrder JobOrderItemsToOrder List] Get JobOrderItemsToOrder Success',
  GetJobOrderItemsToOrderFailure = '[JobOrder JobOrderItemsToOrder List] Get JobOrderItemsToOrder Failure',

  ResetJobOrderItemsToOrder = '[JobOrder JobOrderItemsToOrder List] Reset JobOrderItemsToOrder',
}

export const getJobOrderItemsToOrder = createAction(
  JobOrderItemsToOrderActionTypes.GetJobOrderItemsToOrder,
  props<{ query: Query }>(),
);
export const getJobOrderItemsToOrderSuccess = createAction(
  JobOrderItemsToOrderActionTypes.GetJobOrderItemsToOrderSuccess,
  props<{ jobOrderItemsToOrder: any }>(),
);
export const getJobOrderItemsToOrderFailure = createAction(
  JobOrderItemsToOrderActionTypes.GetJobOrderItemsToOrderFailure,
  props<{ error: any }>(),
);

export const resetJobOrderItemsToOrder = createAction(
  JobOrderItemsToOrderActionTypes.ResetJobOrderItemsToOrder,
);
