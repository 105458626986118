/* eslint-disable arrow-parens */
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  addJobInvoiceSuccess,
  deleteJobInvoiceSuccess,
  JobDetailFacade,
  JobInvoicesFacade,
  sendInvoiceSuccess,
  updateInvoicePaymentSuccess,
  updateJobInvoiceSuccess,
} from '../../../store';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  Customer,
  InvoiceStatus,
  Job,
  JobInvoice,
  JobInvoiceItem,
  JobInvoicePayment,
  JobInvoiceTotals,
  LoggedInUser,
  ProcessStatus,
  SendJobInvoice,
  SnippetArea,
  SnippetType,
  TransactionHistory,
} from 'app/shared/models/eazimate.models';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { UserService } from 'app/core/user/user.service';
import { AccountuserService } from 'app/core/accountuser/accountuser.service';
import { CustomerService } from '../../../../customers/services/customer.service';
import moment from 'moment';
import { JobService } from 'app/views/jobs/services/job.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Actions, ofType } from '@ngrx/effects';
import { cloneDeep } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfigQuill,
  InlineEditingGridComponent,
  QuillEditorModalComponent,
  SendModalComponent,
} from 'app/shared/components';
import { JobStateService } from '../../../services/job-state.service';
import { PreviewComponent } from 'app/shared/components/preview/preview.component';
import { SendModalData } from 'app/shared/models/form-ui.models';
import { ToastService } from 'app/shared/services/toast.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  AddPercentageAmountInvoiceLineDialogComponent,
  InvoiceStatusText,
} from '../..';
import { BackNavigationService } from 'app/shared/services/back-navigation.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { IntegrationsService } from 'app/views/settings/services/integration.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { InvoiceStatusBadgeComponent } from '../invoice-status-badge/invoice-status-badge.component';
import { NgIf, NgFor, CurrencyPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'app/shared/shared.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnippetsService } from '@app/views/snippets/services/snippets.service';

enum PageMode {
  VIEW = 'view',
  NEW = 'new',
  EDIT = 'edit',
}

enum InvoiceAction {
  Print,
  Preview,
  StatusChange,
  Send,
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'dd',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

function dateIssuedValidator(control: AbstractControl) {
  const dateIssuedControl = control.get('invoiceDate');
  const dueDate = control.get('paymentDueDate').value;
  if (typeof dueDate === 'string') {
    if (dateIssuedControl.value > moment(dueDate)) {
      dateIssuedControl.setErrors({ incorrectDate: true });
      return { dateIssuedIncorrect: true };
    }
  }
  if (typeof dueDate === 'object') {
    if (dateIssuedControl.value > dueDate) {
      dateIssuedControl.setErrors({ incorrectDate: true });
      return { dateIssuedIncorrect: true };
    }
  }
  dateIssuedControl.setErrors(null);
  return null;
}

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  standalone: true,
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    NgIf,
    InvoiceStatusBadgeComponent,
    MatMenuModule,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxTrimDirectiveModule,
    MatDatepickerModule,
    MatCheckboxModule,
    SharedModule,
    MatProgressSpinnerModule,
    CurrencyPipe,
  ],
})
export class InvoiceViewComponent implements OnInit, OnDestroy {
  @ViewChild('invoiceTableRef') invoiceTableRef: InlineEditingGridComponent;
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];

  jobId;
  invoice: JobInvoice;
  invoicePrice = 0;
  gst = 0;
  invoiceId: string;
  isSaving = false;

  jobInvoiceTotals: JobInvoiceTotals = undefined;

  previousAmount: number = 0;
  accountUser: LoggedInUser;
  customer: Customer;
  mode: PageMode;
  pageMode = PageMode;
  paidOptions = [
    { text: 'Draft', value: InvoiceStatus.Draft, link: 'draft' },
    {
      text: 'Awaiting Payment',
      value: InvoiceStatus.Awaiting_Payment,
      link: 'awaitingpayment',
    },
    { text: 'Paid', value: InvoiceStatus.Paid, link: 'paid' },
    { text: 'Void', value: InvoiceStatus.Void, link: 'void' },
  ];

  form = this.fb.group(
    {
      // to: ['', Validators.required], //customerSearch
      from: ['', Validators.required],
      description: ['', Validators.required],
      invoiceCode: [''],
      invoiceDate: [moment(), Validators.required],
      paymentDueDate: ['', Validators.required],
      externalInvoiceCode: [''],
      sent: [false],
      // customerId: ['', Validators.required],
    },
    { validator: dateIssuedValidator },
  );

  calledForOptions = [
    {
      name: 'Today',
      value: 0,
    },
    {
      name: 'In 7 days',
      value: 7,
    },
    {
      name: 'In 14 days',
      value: 14,
    },
    {
      name: 'In 30 days',
      value: 30,
    },
  ];

  paymentTable;
  invoiceTable;
  isEditing;

  ableToEdit: {
    terms: boolean;
    payments: boolean;
    invoiceLines: boolean;
    invoiceHeader: boolean;
    saveInvoice: boolean;
  } = {
      terms: true,
      payments: false,
      invoiceLines: true,
      invoiceHeader: true,
      saveInvoice: true,
    };

  pageChanges: {
    paymentTable: boolean;
    invoiceTable: boolean;
    invoiceHeader: boolean;
    footer: boolean;
  } = {
      paymentTable: false,
      invoiceTable: false,
      invoiceHeader: false,
      footer: false,
    };

  // for preview
  generatedFile;
  fileName;
  syncButtonDisabled = true;
  syncEnabled = false;
  transactionHistory?: TransactionHistory;
  xeroDeepLink;

  private unsubscribe$ = new Subject<void>();

  get hasChanges(): boolean {
    return (
      this.form.valid &&
      (Object.values(this.pageChanges).some((value) => value) ||
        //exclude sent controls from changes tracking
        Object.keys(this.form.controls)
          .filter((k) => k !== 'sent')
          .some((key) => this.form.controls[key].dirty)) || this.isSaving
    );
  }

  get invoiceStatus() {
    return InvoiceStatusText[this.invoice?.status];
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public invoicesFacade: JobInvoicesFacade,
    private jobFacade: JobDetailFacade,
    private fb: FormBuilder,
    private _userService: UserService,
    private _accountUserService: AccountuserService,
    private customerService: CustomerService,
    private jobService: JobService,
    private confirm: FuseConfirmationService,
    private actions$: Actions,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private toast: ToastService,
    public backNavigationService: BackNavigationService,
    private accountUserFacade: AccountUserFacade,
    private integrationService: IntegrationsService,
    private snackbarService: MatSnackBar,
    private snippetService: SnippetsService,
  ) { }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params.jobId;
    this.invoiceId = this.route.snapshot.params['id'];
    this.mode = this.invoiceId === 'new' ? PageMode.NEW : PageMode.VIEW;

    this.fetchJobInvoiceTotals(this.jobId);

    // fetch current  user
    this._accountUserService.get().subscribe((accountUser: LoggedInUser) => {
      this.accountUser = accountUser;
      this.form.get('from').setValue(this.accountUser.account.name);
      this.form.get('from').disable();
      if (this.mode === PageMode.NEW) {
        this.form
          .get('paymentDueDate')
          .setValue(moment().add(this.accountUser.account.invoiceTerms, 'd'));
      }
    });

    this.form.get('invoiceCode').disable();

    this.dataInit();

    this.actions$
      .pipe(
        ofType(
          deleteJobInvoiceSuccess,
          updateInvoicePaymentSuccess,
          sendInvoiceSuccess,
          updateJobInvoiceSuccess,
          addJobInvoiceSuccess,
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.isSaving = false;
        if (response.type === '[Job Invoice] SendInvoice Success') {
          this.invoice.sent = response.invoice.sent;
          this.form.get('sent').patchValue(this.invoice.sent);
        } else if (
          response.type === '[Job Invoices] Update Job Invoice Success'
        ) {
          this.dataInit();
          this.fetchJobInvoiceTotals(this.invoice.jobId);

        } else if (response.type === '[Job Invoices] Add Job Invoice Success') {
          this.invoiceId = response.invoice.id;
          this.router.navigate(['../', this.invoiceId], {
            relativeTo: this.route,
          });
          this.mode = PageMode.VIEW;
          this.dataInit();
          this.fetchJobInvoiceTotals(response.invoice.jobId);

        } else if (
          response.type === '[Job Invoices] Delete Job Invoice Success'
        ) {
          this.back();
        } else if (response) {
          this.invoicesFacade.getInvoice(this.invoiceId);
        } else if (!response) {
          this.back();
        }
      });

    //get the status of the accounting integration
    this.accountUserFacade.loggedInUser$.subscribe((loggedInUser) => {
      this.syncEnabled = loggedInUser.accountingIntegrationActive;
    });
  }

  dataInit() {
    if (this.mode !== PageMode.NEW) {
      this.invoicesFacade.getInvoice(this.invoiceId);
      this.invoicesFacade.invoice$
        .pipe(
          filter((r) => Boolean(r)),
          takeUntil(this.unsubscribe$),
          tap((invoice: JobInvoice) => {
            this.resetPageChanges();
            this.paymentTable = null;
            this.invoiceTable = null;
            this.cdr.detectChanges();
            this.invoice = cloneDeep(invoice);

            this.configPageRestrictions();

            this.integrationService
              .getTransactionHistoryByReferenceId(this.invoice.invoiceCode)
              .subscribe((res) => {
                if (!res) {
                  return;
                }
                this.transactionHistory = res;
                this.xeroDeepLink = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${res.organisationShortCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${res.externalReferenceId}`;
                this.syncButtonDisabled =
                  this.transactionHistory.processStatus ===
                  ProcessStatus.Success;
              },
                (err) => {
                  this.snackbarService.open('Getting transaction history failed.', 'Close');
                },);

            this.form
              .get('paymentDueDate')
              .setValue(moment(this.invoice.paymentDueDate));
            this.form
              .get('invoiceDate')
              .setValue(moment(this.invoice.invoiceDate));
            this.form.get('description').setValue(this.invoice.description);
            this.form.get('invoiceCode').setValue(this.invoice.invoiceCode);
            this.form
              .get('externalInvoiceCode')
              .setValue(this.invoice['externalInvoiceCode']);
            this.form.get('sent').setValue(this.invoice.sent);

            if (!this.ableToEdit.invoiceHeader || this.isReadOnlyMode) {
              this.form.get('paymentDueDate').disable();
              this.form.get('invoiceDate').disable();
              this.form.get('invoiceCode').disable();
              this.form.get('externalInvoiceCode').disable();
              this.form.get('description').disable();
            }

            this.initInvoiceGrid();

            // need a small timeout to initiate a table form inside the app-inline-editing-grid component
            setTimeout(() => this.updateSummary(), 300);

            this.fetchPayments();

            if (this.form.invalid) {
              Object.keys(this.form.controls)
                .filter((key) => this.form.controls[key])
                .forEach((key) => {
                  this.form.controls[key].markAsTouched();
                  this.form.controls[key].updateValueAndValidity();
                });
            }
          }),
        )
        .subscribe();
    } else {
      this.invoice = {
        amount: 0,
        jobInvoiceItems: [],
        job: {},
      } as any;
      this.initInvoiceGrid();

      // need a small timeout to initiate a table form inside the app-inline-editing-grid component
      setTimeout(() => this.updateSummary(), 300);

      this.jobFacade.getJob(this.jobId);
      this.jobFacade.job$
        .pipe(
          filter((r) => Boolean(r)),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((job: Job) => {
          this.invoice.job.jobCode = job.jobCode;
          this.invoice.job.jobTitle = job.jobTitle;
        });
    }
  }

  updateSummary(): void {
    if (this.invoiceTable) {
      this.invoice.invoicedAmount = +this.invoiceTable?.form
        ?.getRawValue()
        ?.listForm.reduce(
          (prev: number, curr: JobInvoiceItem) => prev + +curr.amount,
          0,
        );
    }
    this.gst = +this.invoice.invoicedAmount / 11;
    this.invoicePrice = +this.invoice.invoicedAmount / 1.1;
  }

  initInvoiceGrid(): void {
    const dataSource = this.invoice.jobInvoiceItems;
    const invoiceTable = {
      mappedColumns: [
        { sourceName: 'id', columnName: 'id', type: 'text' },
        { sourceName: 'description', columnName: 'Item', type: 'text' },
        { sourceName: 'qty', columnName: 'Qty', type: 'digits' },
        {
          sourceName: 'unitPrice',
          columnName: 'Price',
          type: 'digits',
        },
        { sourceName: 'gstAmount', columnName: 'GST', type: 'digits' },
        { sourceName: 'amount', columnName: 'Amount', type: 'digits' },
      ],
      // to edit the grid, add 'action' column
      displayedColumns: [
        'description',
        'qty',
        'unitPrice',
        'gstAmount',
        'amount',
      ],
      dataSource: dataSource,
      form: this.fb.group({
        listForm: new FormArray([]),
      }),
      itemForm: this.getInvoiceLineForm.bind(this),
      canAddNewRow: false, // adding a new row handles through the invoiceTableRef
    };
    if (
      this.invoice.status === InvoiceStatus.Draft ||
      this.mode === PageMode.NEW
    ) {
      invoiceTable.displayedColumns.push('action');
    }
    this.invoiceTable = invoiceTable;
  }

  onAddInvoiceLine(option: string): void {
    if (this.invoiceTableRef.editRowIndex !== null) {
      this.confirm.open({
        title: 'Warning',
        message: 'Please finish editing an existing row first.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          confirm: { label: 'OK', color: 'warn' },
        },
      });
      return;
    }
    const amount =
      this.jobInvoiceTotals.customerQuote -
      this.invoice.invoicedAmount -
      this.jobInvoiceTotals.paymentReceived;
    if (amount <= 0) {
      this.confirm.open({
        title: 'Warning',
        message:
          'Invoiced amount is already greater than Сustomer Quote amount.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          confirm: { label: 'OK', color: 'warn' },
          cancel: { show: false },
        },
      });
      return;
    }
    switch (option) {
      case 'fixed':
      case 'percentage': {
        this.dialog
          .open(AddPercentageAmountInvoiceLineDialogComponent, {
            width: '400px',
            data: {
              amount,
              type: option === 'fixed' ? 'Fixed amount' : 'Percentage amount',
            },
          })
          .afterClosed()
          .subscribe((r: any) => {
            if (!r) {
              return;
            }
            this.invoiceTableRef.addItem({
              description: r.description,
              qty: 1,
              unitPrice: +(r.amount / 1.1).toFixed(2),
              gstAmount: +(r.amount - r.amount / 1.1).toFixed(2),
              amount: r.amount.toFixed(2),
            });
          });
        break;
      }
      case 'remaining': {
        const unitPrice = +(amount / 1.1).toFixed(2);
        const gstAmount = +(amount - unitPrice).toFixed(2);
        this.invoiceTableRef.addItem({
          description: 'Remaining amount',
          qty: 1,
          unitPrice,
          gstAmount,
          amount,
        });
        break;
      }
      case 'credit': {
        this.dialog
          .open(AddPercentageAmountInvoiceLineDialogComponent, {
            width: '400px',
            data: {
              amount,
              type: 'Credit amount',
            },
          })
          .afterClosed()
          .subscribe((r: any) => {
            if (!r) {
              return;
            }
            const unitPrice = +(r.amount / 1.1).toFixed(2);
            const gstAmount = +(r.amount - unitPrice).toFixed(2);
            if (r.taxInclusive) {
              this.invoiceTableRef.addItem({
                description: r.description,
                qty: 1,
                unitPrice,
                gstAmount,
                amount: r.amount.toFixed(2),
              });
            } else {
              this.invoiceTableRef.addItem({
                description: r.description,
                qty: 1,
                unitPrice: r.amount.toFixed(2),
                gstAmount: +(r.amount * 0.1).toFixed(2),
                amount: r.amount.toFixed(2),
              });
            }
          });
        break;
      }
    }
  }

  validatemaxPriceValue(): ValidatorFn {
    return (group: FormGroup) => {
      let message = '';
      const amount = group.controls['amount'];
      let isValid = true;
      if (this.isEditing) {
        if (
          this.invoice?.invoicedAmount + -this.previousAmount + amount.value >
          this.jobInvoiceTotals?.customerQuote
        ) {
          isValid = false;
          message =
            'Invoiced amount is already greater than Customer Quote amount';
        }
        if (
          this.invoice?.invoicedAmount + -this.previousAmount + +amount.value <
          0
        ) {
          isValid = false;
          message = 'Invoiced amount is negative';
        }
      } else {
        if (
          this.invoice?.invoicedAmount + +amount.value >
          this.jobInvoiceTotals?.customerQuote
        ) {
          isValid = false;
          message =
            'Invoiced amount is already greater than Customer Quote amount';
        }
        if (this.invoice?.invoicedAmount + +amount.value < 0) {
          isValid = false;
          message = 'Invoiced amount is negative';
        }
      }
      const validatorInstance = {
        maxValue: { message },
      };
      return !isValid ? validatorInstance : null;
    };
  }

  getInvoiceLineForm(): FormGroup {
    return this.fb.group(
      {
        id: new FormControl(''),
        description: new FormControl('', [
          Validators.required,
          Validators.maxLength(100)
        ]),
        qty: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[.\d]+$/),
        ]),
        unitPrice: this.fb.control('', [
          Validators.required,
          // removed bc added new type "Credit". Value can be negative
          Validators.pattern(/^-?[0-9]{1,12}(.[0-9]{1,2})?$/),
        ]),
        gstAmount: new FormControl({ value: '', disabled: true }),
        amount: new FormControl({ value: '', disabled: true }),
      },
      {
        validators: [
          JobStateService.invoiceGridAutoFillValidator(),
          this.validatemaxPriceValue(),
        ],
      },
    );
  }

  onEditRow(event: boolean & { amount: number }): void {
    this.isEditing = event?.amount ? true : false;
    if (event?.amount) {
      this.previousAmount = +event?.amount;
    }
  }

  onUpdateInvoiceGrid(): void {
    this.pageChanges.invoiceTable = true;
    this.updateSummary();
  }

  fetchPayments(): void {
    const paymentTable = {
      mappedColumns: [
        { sourceName: 'id', columnName: 'id', type: 'text' },
        {
          sourceName: 'amount',
          columnName: 'Amount paid',
          type: 'digits',
        },
        {
          sourceName: 'paymentDate',
          columnName: 'Date paid',
          type: 'date',
        },
        // {sourceName: 'paymentDate', columnName: 'Paid to', type: 'date'},
        {
          sourceName: 'reference',
          columnName: 'Reference',
          type: 'text',
        },
      ],
      // to edit the grid, add 'action' column
      displayedColumns: ['amount', 'paymentDate', 'reference'],
      dataSource: this.invoice.jobInvoicePayments,
      form: this.fb.group({
        listForm: new FormArray([]),
      }),
      itemForm: this.getPaymentsForm,
      canAddNewRowToPaymentGrid: false,
    };

    if (
      this.invoice.status === InvoiceStatus.Awaiting_Payment ||
      this.invoice.status === InvoiceStatus.Draft
    ) {
      paymentTable.displayedColumns.push('action');
      paymentTable.canAddNewRowToPaymentGrid = true;
    }
    this.paymentTable = paymentTable;
  }

  getPaymentsForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(''),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/),
      ]),
      // created: new FormControl(moment(), Validators.required),
      paymentDate: new FormControl(moment(), Validators.required),
      reference: new FormControl(''),
    });
  }

  onEditFooter(): void {
    const snippetParams = {
      snippetArea: SnippetArea.Terms_And_Conditions,
      snippetType: SnippetType.Invoice,
    };

    const data: ConfigQuill = {
      content: this.invoice?.footer,
      noRequired: true,
      minlength: 0,
      snippetsParams: snippetParams,
    };

    this.dialog
      .open(QuillEditorModalComponent, {
        width: '800px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result !== this.invoice.footer && result !== undefined) {
          this.pageChanges.footer = true;
          this.invoice.footer = result;
        }
      });
  }

  onEditIntro(): void {
    const snippetParams = {
      snippetArea: SnippetArea.Introduction,
      snippetType: SnippetType.Invoice,
    };

    const data: ConfigQuill = {
      content: this.invoice?.intro,
      noRequired: true,
      minlength: 0,
      snippetsParams: snippetParams,
    };

    this.dialog
      .open(QuillEditorModalComponent, {
        width: '800px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result !== this.invoice.intro) {
          this.pageChanges.invoiceHeader = true;
          this.invoice.intro = result;
        }
      });
  }

  initForm(): void { }

  // call for date functionality
  callForDateUpdate(value): void {
    this.form
      .get('paymentDueDate')
      .setValue(
        moment(Date.now()).add(value, 'days').format(),
      );
    this.pageChanges.invoiceHeader = true;
  }

  formatDate(controlName): void {
    this.form
      .get(controlName)
      .setValue(moment(this.form.get(controlName).value).format());
    this.pageChanges.invoiceHeader = true;
  }

  onSendOptionSelected(e): void {
    this.invoicesFacade.sendInvoice(e ? 'sent' : 'notsent', this.invoice.id);
  }

  onPaidOptionSelected(e: {
    text: string;
    value: InvoiceStatus;
    link: string;
  }): void {
    if (this.hasChanges) {
      this.saveBeforeAction(InvoiceAction.StatusChange, e);
      return;
    }

    this.syncButtonDisabled = true;
    if (e.value === InvoiceStatus.Awaiting_Payment) {
      //only enable when the invoiced amount is greater than 0
      if (this.invoice?.invoicedAmount && this.invoice?.invoicedAmount > 0.0) {
        if (
          this.transactionHistory &&
          this.transactionHistory?.processStatus === ProcessStatus.Success
        ) {
          return;
        }
        this.syncButtonDisabled = false;
      }
    }

    if (
      e.value === InvoiceStatus.Paid &&
      (this.invoice.status === InvoiceStatus.Awaiting_Payment ||
        this.invoice.status === InvoiceStatus.Draft)
    ) {
      let paymentsAmount;
      if (this.paymentTable) {
        paymentsAmount = this.paymentTable?.form?.value?.listForm.reduce(
          (prev: number, curr: JobInvoicePayment) => prev + curr.amount,
          0,
        );
      } else {
        paymentsAmount = this.invoice.jobInvoicePayments.reduce(
          (prev: number, curr: JobInvoicePayment) => prev + curr.amount,
          0,
        );
      }
      if (paymentsAmount >= this.invoice.invoicedAmount) {
        // change Invoice status
        this.invoicesFacade.changeInvoiceStatus(e.link, this.invoice.id);

        setTimeout(() => {
          // mark as Sent if the  Invoice isn't sent
          if (!this.invoice.sent) {
            this.onSendOptionSelected('sent');
          }
        }, 500);
      } else {
        this.confirm
          .open({
            title: 'Do you want to move the Invoice to paid status?',
            message:
              'A payment will be added to finalize this Invoice. All unsaved payments data will be lost.',
            icon: {
              name: 'heroicons_outline:exclamation-triangle',
              color: 'warn',
            },
            actions: {
              cancel: { label: 'Cancel' },
              confirm: { label: 'OK', color: 'warn' },
            },
          })
          .afterClosed()
          .pipe(
            filter((result) => result === 'confirmed'),
            takeUntil(this.unsubscribe$),
          )
          .subscribe(() => {
            // add missed payment so that owing amount is zero
            const finalisePayment = {
              id: '00000000-0000-0000-0000-000000000000',
              amount: this.invoice.invoicedAmount - paymentsAmount,
              paymentDate: moment(),
              reference: '',
            };

            if (this.paymentTable) {
              // update payment table
              const form = this.getPaymentsForm();
              form.patchValue(finalisePayment);
              this.paymentTable.form.get('listForm').push(form);
            } else {
              // add new payment to payments array
              this.invoice.jobInvoicePayments.push(finalisePayment);
            }
            this.pageChanges.paymentTable = true;
            // set status to paid
            this.invoice.status = InvoiceStatus.Paid;
            this.invoice.sent = true;
            this.onSave();
          });
      }
    } else {
      this.invoicesFacade.changeInvoiceStatus(e.link, this.invoice.id);

      setTimeout(() => {
        if (
          (e.value === InvoiceStatus.Paid ||
            e.value === InvoiceStatus.Awaiting_Payment) &&
          !this.invoice.sent
        ) {
          // mark as Sent if the  Invoice isn't sent
          this.onSendOptionSelected('sent');
        } else if (e.value === InvoiceStatus.Draft && this.invoice.sent) {
          // clear Sent flag if the  Invoice is sent
          this.onSendOptionSelected(null);
        }
      }, 500);
    }
  }

  back(): void {
    if (this.hasChanges) {
      this.confirm
        .open({
          title: 'Discard Changes',
          message:
            'Are you sure you want to discard the changes? All changes will be lost.',
          icon: {
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
          },
          actions: {
            cancel: { label: 'Cancel' },
            confirm: { label: 'Leave', color: 'warn' },
          },
        })
        .afterClosed()
        .pipe(
          filter((result) => result === 'confirmed'),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(() => this.handleBackNavigation());
    } else {
      this.handleBackNavigation();
    }
  }

  handleBackNavigation(): void {
    if (this.backNavigationService.url) {
      this.router.navigate([this.backNavigationService.url], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate(['../..'], { relativeTo: this.route });
    }
  }

  sync() {
    if (this.form.valid && !this.syncButtonDisabled) {
      const dto = this.collectInvoiceData();

      this.syncButtonDisabled = true;
      this.jobService.syncJobInvoice(dto.id).subscribe(
        (r) => {
          if (r === 10) {

            this.snackbarService.open(
              'Invoice has been synced successfully',
              'Close',
            );

            this.integrationService
              .getTransactionHistoryByReferenceId(this.invoice.invoiceCode)
              .subscribe((res) => {
                this.transactionHistory = res;

                this.xeroDeepLink = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${res.organisationShortCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${res.externalReferenceId}`;
                this.syncButtonDisabled = true;
              });
          }
          else if (r === 20) {
            this.snackbarService.open('Invoice has failed to sync - check the integration summary under issues for more details', 'Close');
            this.syncButtonDisabled = false;
          }
        },
        (err) => {
          this.snackbarService.open('Invoice has failed to sync - check the integration summary under issues for more details', 'Close');
          this.syncButtonDisabled = false;
        },
      );
    }
  }

  onSave(): void {
    if (
      !this.hasChanges ||
      this.form.invalid ||
      this.isEditing ||
      !this.invoice?.invoicedAmount || this.isSaving
    ) {
      return;
    }
    this.isSaving = true;
    const dto = this.collectInvoiceData();
    if (this.mode === PageMode.NEW) {
      this.invoicesFacade.addInvoice(dto);
    } else {
      this.invoicesFacade.updateInvoice(dto);
    }
  }

  fetchJobInvoiceTotals(id: string) {
    this.jobService.totalJobInvoice(id).subscribe((r) => {
      this.jobInvoiceTotals = r;
    });
  }

  onCancel(): void { }

  onPreview(): void {
    if (this.hasChanges) {
      this.saveBeforeAction(InvoiceAction.Preview);
      return;
    }

    this.jobService.previewJobInvoice(this.invoiceId).subscribe(
      (r: any) => {
        const dialogRef = this.dialog.open(PreviewComponent, {
          width: '900px',
          data: {
            generatedFile: r,
            fileName: this.pdfFileName,
          },
        });
      },
      (error) => {
        // this.loadingRequest = false;
        // this.fileFetchingError = true;
      },
    );
  }

  onPaid(): void { }

  onSend(): void {
    if (this.hasChanges) {
      this.saveBeforeAction(InvoiceAction.Send);
      return;
    }
    // fetch customer
    this.jobFacade.getJob(this.invoice.jobId);
    this.jobFacade.job$
      .pipe(
        filter((r) => Boolean(r)),
        switchMap((job: Job) =>
          this.customerService.getCustomer(job.customerId),
        ),
        take(1),
      )
      .subscribe((customer: Customer) => {
        this.customer = customer;
        const snippetContent$ = this.snippetService.getSnippetDefaultByTypeArea({
          snippetArea: SnippetArea.Email_Body,
          snippetType: SnippetType.Invoice,
        });
        const data: SendModalData = {
          title: 'Send Invoice',
          from: this.accountUser.account.email,
          message: `<p>Hi ${this.customer.person.firstName},</p> <p>Please find attached your invoice.</p> <br> <p>Any questions please let us know.</p> <br> <p>Thanks</p> <p>${this.accountUser.user.person.firstName} ${this.accountUser.user.person.lastName}</p>`,
          subject: `Invoice ${this.invoice.invoiceCode} from ${this.accountUser.account.name} for ${this.customer.person.fullName}`,
          to: this.customer.person.email ? [this.customer.person.email] : null,
          quillOptions: {
            snippetsParams: {
              snippetArea: SnippetArea.Email_Body,
              snippetType: SnippetType.Invoice,
            },
            snippetContent$
          },
          tags: {
            '{Name}': this.customer.person.firstName,
            '{AccountFullName}': `${this.accountUser.user.person.firstName} ${this.accountUser.user.person.lastName}`,
            '{AccountName}': `${this.accountUser.account.name}`,
          },
          jobId: this.jobId,
        };

        const dialogRef = this.dialog.open(SendModalComponent, {
          width: '720px',
          maxHeight: '70vh',
          data: data,
          panelClass: 'app-send-modal',
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const payload: SendJobInvoice = {
              jobInvoiceId: this.invoiceId,
              emailTo: result.to.join(';'),
              message: result.message,
              subject: result.subject,
              sendCopy: !!result.sendMe,
              attachments: result.attachments ? result.attachments : null,
            };
            this.jobService.sendEmailJobInvoice(payload).subscribe(
              (r) => {
                this.toast.success('Sent successfully');
                this.invoice.sent = true;
                this.form.get('sent').patchValue(this.invoice.sent);
              },
              () => {
                this.toast.error('Sending an invoice failed');
              },
            );
          }
        });
      });
  }

  onDelete(): void {
    this.confirm
      .open({
        title: 'Delete Invoice',
        message:
          'Are you sure you want to delete this Invoice? This action cannot be undone.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Delete', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'confirmed'),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.invoicesFacade.deleteInvoice(this.invoice.id));
  }

  resetPageChanges(): void {
    this.pageChanges = {
      paymentTable: false,
      invoiceTable: false,
      invoiceHeader: false,
      footer: false,
    };
    this.form.get('paymentDueDate').enable();
    this.form.get('invoiceDate').enable();
    this.form.get('invoiceCode').disable();
    this.form.get('externalInvoiceCode').enable();
    this.form.get('description').enable();
    this.form.markAsPristine();
  }

  collectInvoiceData(): JobInvoice {
    const dto = {
      ...this.invoice,
      ...this.form.value,
    };

    if (this.mode === PageMode.NEW) {
      dto.jobId = this.jobId;
      dto.status = 10;
    }
    if (this.paymentTable) {
      dto.jobInvoicePayments = this.paymentTable?.form?.value?.listForm.map(
        (e) => ({
          ...e,
          id: e.id || '00000000-0000-0000-0000-000000000000',
          accountId: this.accountUser.account.id,
        }),
      );
    }
    if (this.invoiceTable) {
      dto.jobInvoiceItems = this.invoiceTable?.form
        ?.getRawValue()
        ?.listForm.map((e) => ({
          ...e,
          id: e.id || '00000000-0000-0000-0000-000000000000',
        }));
    }

    return dto;
  }

  configPageRestrictions(): void {
    this.ableToEdit = {
      terms:
        this.invoice.status === InvoiceStatus.Draft ||
        this.mode === PageMode.NEW,
      invoiceHeader:
        this.invoice.status === InvoiceStatus.Draft ||
        this.mode === PageMode.NEW,
      invoiceLines:
        this.invoice.status === InvoiceStatus.Draft ||
        this.mode === PageMode.NEW,
      payments:
        this.invoice.status === InvoiceStatus.Awaiting_Payment ||
        this.invoice.status === InvoiceStatus.Draft,
      saveInvoice:
        (this.invoice.status !== InvoiceStatus.Void &&
          this.invoice.status !== InvoiceStatus.Paid) ||
        this.mode === PageMode.NEW,
    };
  }

  downloadPdf() {
    if (this.hasChanges) {
      this.saveBeforeAction(InvoiceAction.Print);
      return;
    }

    this.jobService.previewJobInvoice(this.invoiceId).subscribe(
      (r: any) => {
        const source = `data:application/pdf;base64,${r}`;
        const link = document.createElement('a');
        link.href = source;
        link.download = this.pdfFileName;
        link.click();
        link.remove();
      },
      (error) => { },
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.invoicesFacade.clearInvoice();
    this.backNavigationService.url = null;
    this.backNavigationService.tooltip = null;
  }

  cancel() {
    this.confirm
      .open({
        title: 'Discard Changes',
        message:
          'Are you sure you want to discard the changes? All changes will be lost.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Discard', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result) => result === 'confirmed'))
      .subscribe(() => {
        this.dataInit();
      });
  }

  saveBeforeAction(
    action: InvoiceAction,
    e?: {
      text: string;
      value: InvoiceStatus;
      link: string;
    },
  ) {
    this.confirm
      .open({
        title: 'Save changes?',
        message: 'You have unsaved changes.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Save', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result) => result === 'confirmed'))
      .subscribe(() => {
        if (this.form.valid) {
          this.actions$
            .pipe(ofType(updateJobInvoiceSuccess), take(1))
            .subscribe(() => {
              switch (action) {
                case InvoiceAction.StatusChange:
                  this.onPaidOptionSelected(e);
                  break;
                case InvoiceAction.Preview:
                  this.onPreview();
                  break;
                case InvoiceAction.Print:
                  this.downloadPdf();
                  break;
                case InvoiceAction.Send:
                  this.onSend();
                  break;
              }
            });
          this.onSave();
        } else {
          this.form.markAllAsTouched();
        }
      });
  }

  get pdfFileName() {
    return `Invoice ${this.invoice.invoiceCode} for ${this.invoice.job.jobCode
      } - ${this.invoice.job.jobTitle} - ${new Date().toISOString().split('T')[0]
      }.pdf`;
  }
}
