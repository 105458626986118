/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { TeamMember } from 'app/shared/models';
import { TeamMemberData } from 'app/shared/models/form-ui.models';
import { TeamMembershipValidators } from './team-memberships.validators';
import { TeamSettingsFacade } from '../../store/facades/team-settings.facade';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-invite-team-member-dialog',
  templateUrl: './invite-team-member.dialog.html',
  styles: [
    /* language=SCSS */
    `
      .mat-form-field {
        .mat-form-field-wrapper {
          padding-bottom: 0;

          .mat-form-field-underline {
            position: initial !important;
            display: block;
            margin-top: -1px;
          }

          .mat-form-field-subscript-wrapper,
          .mat-form-field-ripple {
            position: initial !important;
            display: table;
          }

          .mat-form-field-subscript-wrapper {
            min-height: calc(1em + 1px);
          }
        }
      }
      .app-invite-team-member-dialog {
        @screen md {
          @apply w-128;
        }

        .mat-mdc-dialog-container {
          .mat-mdc-dialog-surface {
            padding: 0 !important;
          }
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    AsyncPipe,
  ],
})
export class InviteTeamMemberDialog {
  public inviteForm: FormGroup;

  get checkInvitePending(): boolean {
    const retVal = this.inviteForm.controls['email'].hasError('invitePending');
    return retVal ?? false;
  }

  get checkInviteRejected(): boolean {
    const retVal = this.inviteForm.controls['email'].hasError('inviteRejected');
    return retVal ?? false;
  }

  get checkInviteAccepted(): boolean {
    const retVal = this.inviteForm.controls['email'].hasError('acceptedDate');
    return retVal ?? false;
  }

  get validEmail(): boolean {
    const retVal =
      this.inviteForm.controls['email'].hasError('required') ||
      this.inviteForm.controls['email'].hasError('email');
    return retVal ?? false;
  }

  constructor(
    public facade: TeamSettingsFacade,
    public dialogRef: MatDialogRef<InviteTeamMemberDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TeamMember[],
    private fb: FormBuilder,
  ) {
    this.inviteForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          this.checkInviteStatus.bind(this),
        ],
      ],
      teamMemberTypeId: [undefined, [Validators.required]],
    });
  }

  checkInviteStatus(ctrl: FormControl): any {
    const teamMembers = this.data as TeamMember[];
    const val = ctrl.value as TeamMemberData;
    const index = teamMembers.findIndex((x) => x.inviteEmail === val);
    if (index === -1) {
      return null;
    } else {
      if (teamMembers[index].rejectedDate != null) {
        return { inviteRejected: true };
      } else if (teamMembers[index].acceptedDate != null) {
        return { inviteAccepted: true };
      } else if (teamMembers[index].invitedDate != null) {
        return { invitePending: true };
      }
      return null;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
