import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  PaginatedListOfSnippet,
  Snippet,
  SnippetArea,
  SnippetType,
} from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { SnippetsService } from '../../services/snippets.service';
import {
  addSnippet,
  addSnippetFailure,
  addSnippetSuccess,
  deleteSnippet,
  deleteSnippetFailure,
  deleteSnippetSuccess,
  getSnippetsList,
  getSnippetsListByTypeArea,
  getSnippetsListByTypeAreaFailure,
  getSnippetsListByTypeAreaSuccess,
  getSnippetsListFailure,
  getSnippetsListSuccess,
  updateSnippet,
  updateSnippetFailure,
  updateSnippetSuccess,
} from '../actions/snippets.actions';
import { ToastService } from '../../../../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class SnippetsEffects {
  constructor(
    private actions$: Actions,
    private snippetsService: SnippetsService,
    private toast: ToastService,
  ) {}

  getSnippets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSnippetsList),
      switchMap(({ request }) =>
        this.snippetsService.getSnippets(request).pipe(
          map((snippets: PaginatedListOfSnippet) =>
            getSnippetsListSuccess({
              snippets: {
                ...snippets,
                items: snippets.items.map((t) => ({
                  ...t,
                  typeText: SnippetType[t.snippetType],
                  areaText: SnippetArea[t.snippetArea],
                })),
              },
            }),
          ),
          catchError((error) => of(getSnippetsListFailure({ error }))),
        ),
      ),
    ),
  );

  deleteSnippet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSnippet),
      switchMap(({ id }) =>
        this.snippetsService.deleteSnippet(id).pipe(
          map((snippet: Snippet) => deleteSnippetSuccess({ snippet })),
          catchError((error) => {
            if (error.error.Message.includes('Cannot delete default item')) {
              this.toast.error('Cannot delete default item');
            }
            return of(deleteSnippetFailure({ error }));
          }),
        ),
      ),
    ),
  );

  addSnippet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSnippet),
      switchMap(({ snippet }) =>
        this.snippetsService.addSnippet(snippet).pipe(
          map((snippet: Snippet) => addSnippetSuccess({ snippet })),
          catchError((error) => of(addSnippetFailure({ error }))),
        ),
      ),
    ),
  );

  updateSnippet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSnippet),
      switchMap(({ snippet }) =>
        this.snippetsService.updateSnippet(snippet).pipe(
          map((snippet: Snippet) => updateSnippetSuccess({ snippet })),
          catchError((error) => {
            const err = 'Type & Area must have at least one default item';
            if (error.error.Message.includes(err)) {
              this.toast.error(err);
            }
            return of(updateSnippetFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getSnippetsListByTypeArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSnippetsListByTypeArea),
      switchMap(({ params }) =>
        this.snippetsService.getSnippetsListByTypeArea(params).pipe(
          map((snippets: Snippet[]) => {
              const sortedSnippets = [...snippets].sort((a, b) => a?.isDefault < b?.isDefault ? 1 : -1);
            console.log(  sortedSnippets)
              return getSnippetsListByTypeAreaSuccess({snippets: sortedSnippets});
            }
          ),
          catchError((error) => of(getSnippetsListFailure({ error }))),
        ),
      ),
    ),
  );
}
