<div class="relative flex flex-col w-full h-full max-h-screen overflow-hidden">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-5 pr-4 overflow-hidden">
    <button [matDialogClose]="undefined" mat-icon-button>
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div
    class="shadow-lg flex flex-col sm:flex-row items-center overflow-hidden p-6"
  >
    <!-- Icon -->
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600"
    >
      <mat-icon
        class="text-current"
        svgIcon="heroicons_outline:photograph"
      ></mat-icon>
    </div>

    <div class="text-center sm:text-left">
      <!-- Title -->
      <div
        class="text-xl leading-6 font-medium"
        [innerHTML]="'Company Logo Selector'"
      ></div>
    </div>
  </div>

  <!-- Form -->
  <div class="flex flex-col overflow-y-auto h-full gap-x-5 p-6 py-4 mb-0">
    <input
      id="imgCropper"
      #inportBtn
      (change)="fileChangeEvent($event)"
      (click)="inportBtn.value = null"
      mat-raised-button
      color="accent"
      type="file"
      accept="image/*"
      style="display: none"
    />

    <div class="image-cropper-wrapper relative mt-5 h-auto">
      <div *ngIf="!isLoaded" class="image-cropper-hint absolute text-black z-10 w-2/3 left-0 right-0 ml-auto mr-auto text-center">
        Click the Select button below to browse and upload your logo.
      </div>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="false"
        [aspectRatio]="3 / 3"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        format="png"
        output="base64"
      ></image-cropper>
    </div>

    <div class="mt-5">
      <ol>
        <li>Click on Select</li>
        <li>Select part of image you would like to save</li>
        <li>Click on Save Section</li>
      </ol>
    </div>
  </div>

  <!-- Action buttons -->
  <div
    class="shadow-lg flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <button
      (click)="inportBtn.click()"
      mat-raised-button
      color="accent"
      style="margin-left: 1rem"
    >
      Select
    </button>
    <button
      [disabled]="!!!imageChangedEvent.srcElement"
      (click)="onOkClick()"
      class=""
      mat-raised-button
      color="primary"
      cdkFocusInitial
    >
      Save
    </button>
  </div>
</div>
