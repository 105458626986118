import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Host,
  Injector,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import {
  FormControl,
  NgControl,
  NG_VALIDATORS,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FormControlDirective,
  getValueAccessor,
} from 'app/shared/directives/form-control-component.model';
import { SnippetsFacade } from 'app/views/snippets/store';
import { ConfigQuill } from '../quill-editor-modal/quill-editor-modal.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { QuillEditorComponent } from 'ngx-quill';
import { SpeachToTextComponent } from '../speach-to-text/speach-to-text.component';

@Component({
  selector: 'app-quill-editor-element',
  templateUrl: './quill-editor-element.component.html',
  styleUrls: ['./quill-editor-element.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: QuillEditorElementComponent,
      multi: true,
    },
    getValueAccessor(QuillEditorElementComponent),
  ],
  standalone: true,
  imports: [
    QuillEditorComponent,
    SpeachToTextComponent,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    AsyncPipe,
  ],
})
export class QuillEditorElementComponent
  extends FormControlDirective
  implements OnInit
{
  @Input() data: ConfigQuill;
  @Input() useFirstValue? = false;
  @Output() snippetChanged = new EventEmitter();
  formControl: FormControl;
  isRequired = false;
  snippetsOptions$ = this.snippetsFacade.snippetsByTypeArea$;
  defaultValue = '';

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
    public snippetsFacade: SnippetsFacade,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data?.snippetsParams) {
      this.snippetsFacade.getSnippetsByTypeArea(this.data.snippetsParams);
    }
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector?.get(NgControl, null);
    if (ngControl) {
      this.formControl = ngControl.control as FormControl;
      this.changeDetectorRef.detectChanges();
    }
  }

  linkClick() {
    let editLink: HTMLElement = document.querySelector('.ql-editing');
    if (editLink) {
      console.log(editLink?.style.left);
      let left: number = parseInt(editLink?.style.left, 10);
      if (left < 0) {
        editLink.style.left = '0px';
      }
    }
  }

  modelChanged() {
    this.emitChanges();
  }

  validate(control: FormControl) {
    if (control.disabled || control.validator) {
      if (!this.defaultValue && control.value) {
        this.defaultValue = control.value;
      }
      this.formControl = control as FormControl;
      if (control.validator) {
        const validation = control.validator(new FormControl());
        this.isRequired = validation !== null && validation.required === true;
      }
      return false;
    }
  }

  setSpippet(content: string) {
    this.formControl.setValue(content);
    this.formControl.markAsDirty();
    this.snippetChanged.emit(true);
  }
}
