import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { JobCostingParameters } from 'app/shared/models';
import { getJobCostingSummary, getJobVariations } from '../actions';
import { JobVariationsListState } from '../reducers/job-variations-list.reducer';

import {
  selectJobCostingSummary,
  selectJobVariationsList,
  selectJobVariationsListError,
  selectJobVariationsListIsLoaded,
  selectJobVariationsListIsLoading,
  selectJobVariationsListPageNumber,
  selectJobVariationsListTotalCount,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobVariationsListFacade {
  isLoading$ = this.store.select(selectJobVariationsListIsLoading);
  isLoaded$ = this.store.select(selectJobVariationsListIsLoaded);
  error$ = this.store.select(selectJobVariationsListError);
  variations$ = this.store.select(selectJobVariationsList);
  totalCount$ = this.store.select(selectJobVariationsListTotalCount);
  pageIndex$ = this.store.select(selectJobVariationsListPageNumber);
  costingSummary$ = this.store.select(selectJobCostingSummary);

  constructor(private store: Store<JobVariationsListState>) {}

  getJobVariations(request: JobCostingParameters): void {
    this.store.dispatch(getJobVariations({ request }));
  }

  getJobCostingSummary(id: string): void {
    this.store.dispatch(getJobCostingSummary({ id }));
  }
}
