<div class="flex flex-row space-x-2">
  <div class="font-medium">Folders</div>
  <div
    *ngIf="!isReadOnlyMode"
    class="cursor-pointer"
    (click)="createNewFolder()"
  >
    <mat-icon
      [svgIcon]="'heroicons_solid:folder-plus'"
      class="hover:text-primary-600 dark:hover:text-primary-200"
    ></mat-icon>
  </div>
  <div class="relative" *ngIf="folders?.length > 0 && !isReadOnlyMode">
    <mat-icon
      (click)="toggleSelectAll()"
      class="cursor-pointer"
      [ngClass]="{
        'hover:text-primary-600 dark:hover:text-white': !allSelected,
        'text-blue-600': allSelected
      }"
    >
      check_circle
    </mat-icon>
    <svg
      *ngIf="allSelected"
      class="absolute top-0 left-0"
      style="z-index: -1 !important"
    >
      <circle fill="white" cx="12" cy="12" r="9"></circle>
    </svg>
  </div>
  <div
    *ngIf="anySelected && !isReadOnlyMode"
    class="cursor-pointer"
    (click)="deleteSelected()"
  >
    <mat-icon
      [svgIcon]="'heroicons_solid:trash'"
      class="hover:text-primary-600 dark:hover:text-white"
    ></mat-icon>
  </div>
</div>
<div
  *ngIf="folders?.length > 0; else noFolders"
  class="flex flex-wrap -m-2 mt-2"
>
  <div *ngFor="let folder of folders">
    <div
      class="flex flex-col group relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card hover:text-primary-600 dark:hover:text-white"
      [ngClass]="{ 'cursor-pointer': showAllSelectors }"
    >
      <div
        class="absolute inset-0 flex flex-col p-4 cursor-pointer"
        (click)="setCurrentFolderPath.emit(folder.path)"
      >
        <div class="aspect-w-9 aspect-h-6">
          <div class="flex items-center justify-center">
            <!-- Icon -->
            <mat-icon
              class="icon-size-14 text-hint"
              [svgIcon]="'heroicons_outline:folder'"
            ></mat-icon>
          </div>
        </div>
        <div
          class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
        >
          <div class="truncate" [matTooltip]="folder.name">
            {{ folder.name }}
          </div>
          <ng-container *ngIf="folder.folderCount || folder.fileCount">
            <div class="text-secondary truncate">
              <span *ngIf="folder.folderCount"
              >{{ folder.folderCount }} folder<span
                *ngIf="folder.folderCount > 1"
              >s</span
              ></span
              >
              <span *ngIf="folder.folderCount && folder.fileCount">, </span>
              <span *ngIf="folder.fileCount"
              >{{ folder.fileCount }} file<span *ngIf="folder.fileCount > 1"
              >s</span
              ></span
              >
            </div>
            <div *ngIf="folder.size">
              {{ folder.size | filesize }}
            </div>
          </ng-container>
        </div>
      </div>
      <a
        *ngIf="!isReadOnlyMode"
        class="absolute top-0 right-0"
        mat-icon-button
        (click)="edit(folder.path, $event)"
      >
        <mat-icon
          class="icon-size-5 hover:text-primary-600 dark:hover:text-white"
        >
          edit
        </mat-icon>
      </a>
      <a
        class="absolute top-0 left-0"
        [ngClass]="{ hidden: folder.selected || !showAllSelectors }"
        mat-icon-button
      >
        <mat-icon class="icon-size-5 opacity-70">
          radio_button_unchecked
        </mat-icon>
      </a>
      <a
        class="absolute top-0 left-0"
        (click)="toggleSelected(folder)"
        mat-icon-button
      >
        <mat-icon
          class="hidden group-hover:block hover:text-primary-600 dark:hover:text-white"
        >
          check_circle</mat-icon
        >
      </a>
<!--      <a class="absolute top-0 left-0" *ngIf="folder.selected" mat-icon-button>-->
<!--        <svg *ngIf="folder.selected" style="height: 22px; width: 22px">-->
<!--          <circle fill="white" cx="12" cy="12" r="8"></circle>-->
<!--        </svg>-->
<!--      </a>-->
      <a
        *ngIf="folder.selected"
        class="absolute top-0 left-0"
        (click)="toggleSelected(folder)"
        mat-icon-button
      >
        <mat-icon class="text-blue-600"> check_circle </mat-icon>
      </a>

    </div>
  </div>
</div>
<ng-template #noFolders>
  <div class="mt-4 font-semibold tracking-tight text-secondary">No folders</div>
</ng-template>
