import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromActions from '../actions/supplier-list.actions';
import * as fromSelectors from '../selectors/supplier-list.selectors';

@Injectable({
  providedIn: 'root',
})
export class SupplierListFacade {
  supplierList$ = this.store.select(fromSelectors.getSupplierList);
  supplierListItems$ = this.store.select(fromSelectors.getSupplierListItems);
  totalCount$ = this.store.select(fromSelectors.getSupplierTotalCount);
  supplierListPageNumber$ = this.store.select(
    fromSelectors.getSupplierListPageNumber,
  );

  isLoading$ = this.store.select(fromSelectors.getSupplierListIsLoading);
  isLoaded$ = this.store.select(fromSelectors.getSupplierListIsLoaded);
  error$ = this.store.select(fromSelectors.getSupplierListError);

  constructor(private store: Store) { }

  loadSupplierList(query): void {
    this.store.dispatch(fromActions.getSupplierList({ query }));
  }

  addSupplier(query): void {
    this.store.dispatch(fromActions.addSupplier({ query }));
  }

  addXeroSupplier(query): void {
    query.xeroImport = true;
    this.store.dispatch(fromActions.addXeroSupplier({ query }));
  }
}
