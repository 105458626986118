import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    let phoneNumber = value.replace(/\D/g, '');

    if (/^0[2|3|7|8]/.test(phoneNumber)) {
      phoneNumber = `${phoneNumber.substr(0, 2)} ${phoneNumber.substr(
        2,
        4,
      )} ${phoneNumber.substr(6, 4)}`;
    } else if (/^04/.test(phoneNumber)) {
      phoneNumber = `${phoneNumber.substr(0, 4)} ${phoneNumber.substr(
        4,
        3,
      )} ${phoneNumber.substr(7, 3)}`;
    }

    return phoneNumber;
  }
}
