import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { AvailablePlan } from 'app/shared/models';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf, NgClass } from '@angular/common';

export interface ChangePlanDialogData {
  availablePlans: AvailablePlan[];
  accountCancelled: boolean;
}

@Component({
  selector: 'app-change-plan-dialog',
  templateUrl: './change-plan-dialog.component.html',
  styleUrls: ['./change-plan-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, NgFor, NgIf, NgClass, MatButtonModule],
})
export class ChangePlanDialogComponent implements OnInit {
  availablePlans: AvailablePlan[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangePlanDialogData,
    private dialogRef: MatDialogRef<ChangePlanDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.availablePlans = this.data.availablePlans;
  }

  handleOnSubscribeClicked($event: any, item: AvailablePlan) {
    this.dialogRef.close(item);
  }

  showLabel(plan: AvailablePlan) {
    if (plan.current) {
      return 'Current plan';
    } else {
      return 'Current plan (Expired)';
    }
  }

  isDisabled(plan: AvailablePlan) {
    if (this.data.accountCancelled) {
      return false;
    } else {
      //only disable the current one
      if (plan.current) {
        return true;
      }

      return false;
    }
  }
}
