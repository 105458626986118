import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromActions from '../actions';
import * as fromSelectors from '../selectors/item-price-check.selectors';
import { PriceListItemPriceChangeCheck } from 'app/shared/models';
@Injectable({
  providedIn: 'root',
})
export class ItemPriceCheckFacade {
  priceListItemPriceChangeJob$ = this.store.select(
    fromSelectors.selectFullPriceList,
  );
  isLoading$ = this.store.select(fromSelectors.selectItemPriceCheckIsLoading);
  isLoaded$ = this.store.select(fromSelectors.selectItemPriceCheckIsLoaded);
  error$ = this.store.select(fromSelectors.selectItemPriceCheckError);

  constructor(private store: Store) {}

  getItemPriceCheck(params: PriceListItemPriceChangeCheck): void {
    this.store.dispatch(fromActions.getItemPriceCheck({ params }));
  }

  clear(): void {
    this.store.dispatch(fromActions.clearState());
  }
}
