import { createReducer, on } from '@ngrx/store';
import {
  EstimatePriceListItem,
  PriceListCategory,
  SingleSaveVariation,
  SingleSaveVariationSection
} from 'app/shared/models';
import { orderBy, sortBy, uniq, uniqBy } from 'lodash';
import {
  addSelectedItems,
  deselectAllEstimateListItems,
  deselectAllPriceListItems,
  filterItems,
  removeSelectedItems,
  selectAllEstimateListItems,
  selectAllPriceListItems,
  selectCategory,
  showDialog,
  sortEstimateListItems,
  sortPriceListItems,
  toggleItemSelected,
} from '../actions';

export interface JobVariationAddRemoveCostingState {
  variation: SingleSaveVariation;
  section: SingleSaveVariationSection;
  originalPriceListItems: EstimatePriceListItem[];
  originalEstimateListItems: EstimatePriceListItem[];
  priceListItems: EstimatePriceListItem[];
  estimateListItems: EstimatePriceListItem[];
  selectedItemIds: string[];
  selectedCategoryId: string;
  priceListSorting: { sortField: string; sortOrder: 'asc' | 'desc' };
  estimateListSorting: { sortField: string; sortOrder: 'asc' | 'desc' };
  filter: string;
  categories: PriceListCategory[];
  error: any;
}

export const initialState: JobVariationAddRemoveCostingState = {
  variation: null,
  section: null,
  originalPriceListItems: [],
  originalEstimateListItems: [],
  priceListItems: [],
  estimateListItems: [],
  selectedItemIds: [],
  categories: [],
  selectedCategoryId: '',
  priceListSorting: { sortField: 'itemCode', sortOrder: 'asc' },
  estimateListSorting: { sortField: 'itemCode', sortOrder: 'asc' },
  filter: '',
  error: null,
};

export const filterAndSortItems = (
  items: EstimatePriceListItem[],
  categoryId: string,
  filter: string,
  sortField: string,
  sortOrder: 'asc' | 'desc',
) => {
  return orderBy(
    items.filter(
      (i) =>
        (filter.trim() === '' ||
          i.description.toLowerCase().includes(filter.toLowerCase())) &&
        (categoryId === '' || i.priceListCategoryId === categoryId),
    ),
    [sortField, 'itemCode'],
    [sortOrder, 'asc'],
  );
};

function mapItem(
  i: EstimatePriceListItem,
  state: JobVariationAddRemoveCostingState,
) {
  return {
    ...i,
    variationId: state.variation.id,
    variationCode: state.variation.code,
    variationSectionId: state.section.id,
    originalItemId: i.id,
    unitTotal: -i.unitTotal,
    qty: -i.qty,
    netQuote: -i.netQuote,
    jobCostingPriceListItemId: i['jobCostingPriceListItemId'] ? null : i.id,
  };
}

function onRemoveSelectedItems(
  state: JobVariationAddRemoveCostingState,
): JobVariationAddRemoveCostingState {
  const selectedEstimateListItems = filterAndSortItems(
    state.estimateListItems,
    state.selectedCategoryId,
    state.filter,
    state.estimateListSorting.sortField,
    state.estimateListSorting.sortOrder,
  ).filter((i) => state.selectedItemIds.includes(i.id));

  const selectedEstimateListItemsIds = selectedEstimateListItems.map(
    (i) => i.id,
  );

  const estimateListItems = state.estimateListItems.filter(
    (i) => !selectedEstimateListItemsIds.includes(i.id),
  );

  const priceListItems = [
    ...state.priceListItems,
    ...selectedEstimateListItems.map((i) => mapItem(i, state)),
  ];
  const selectedItemIds = state.selectedItemIds.filter(
    (id) => !selectedEstimateListItemsIds.includes(id),
  );

  return {
    ...state,
    estimateListItems,
    priceListItems,
    selectedItemIds,
  };
}

function onAddSelectedItems(
  state: JobVariationAddRemoveCostingState,
): JobVariationAddRemoveCostingState {
  const selectedPriceListItems = filterAndSortItems(
    state.priceListItems,
    state.selectedCategoryId,
    state.filter,
    state.priceListSorting.sortField,
    state.priceListSorting.sortOrder,
  ).filter((i) => state.selectedItemIds.includes(i.id));

  const selectedPriceListItemsIds = selectedPriceListItems.map((i) => i.id);

  const selectedItemIds = state.selectedItemIds.filter(
    (id) => !selectedPriceListItemsIds.includes(id),
  );

  const priceListItems = state.priceListItems.filter(
    (i) => !selectedPriceListItemsIds.includes(i.id),
  );

  const estimateListItems = [
    ...state.estimateListItems,
    ...selectedPriceListItems.map((i) => mapItem(i, state)),
  ];

  return {
    ...state,
    priceListItems,
    estimateListItems,
    selectedItemIds,
  };
}

export const jobVariationAddRemoveCostingReducer = createReducer(
  initialState,
  on(sortPriceListItems, (state, { sortField, sortOrder }) => ({
    ...state,
    priceListSorting: { sortField, sortOrder },
  })),
  on(sortEstimateListItems, (state, { sortField, sortOrder }) => ({
    ...state,
    estimateListSorting: { sortField, sortOrder },
  })),
  on(removeSelectedItems, (state) => onRemoveSelectedItems(state)),
  on(addSelectedItems, (state) => onAddSelectedItems(state)),
  on(filterItems, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(selectCategory, (state, { id }) => ({
    ...state,
    selectedCategoryId: id,
  })),
  on(selectAllEstimateListItems, (state) => ({
    ...state,
    selectedItemIds: uniq([
      ...state.selectedItemIds,
      ...filterAndSortItems(
        state.estimateListItems,
        state.selectedCategoryId,
        state.filter,
        state.estimateListSorting.sortField,
        state.estimateListSorting.sortOrder,
      ).map((i) => i.id),
    ]),
  })),
  on(deselectAllEstimateListItems, (state) => ({
    ...state,
    selectedItemIds: state.selectedItemIds.filter(
      (id) =>
        !filterAndSortItems(
          state.estimateListItems,
          state.selectedCategoryId,
          state.filter,
          state.estimateListSorting.sortField,
          state.estimateListSorting.sortOrder,
        ).find((i) => i.id === id),
    ),
  })),
  on(selectAllPriceListItems, (state) => ({
    ...state,
    selectedItemIds: uniq([
      ...state.selectedItemIds,
      ...filterAndSortItems(
        state.priceListItems,
        state.selectedCategoryId,
        state.filter,
        state.priceListSorting.sortField,
        state.priceListSorting.sortOrder,
      ).map((i) => i.id),
    ]),
  })),
  on(deselectAllPriceListItems, (state) => ({
    ...state,
    selectedItemIds: state.selectedItemIds.filter(
      (id) =>
        !filterAndSortItems(
          state.priceListItems,
          state.selectedCategoryId,
          state.filter,
          state.priceListSorting.sortField,
          state.priceListSorting.sortOrder,
        ).find((i) => i.id === id),
    ),
  })),
  on(toggleItemSelected, (state, { id }) => ({
    ...state,
    selectedItemIds: state.selectedItemIds.includes(id)
      ? state.selectedItemIds.filter((i) => id !== i)
      : [...state.selectedItemIds, id],
  })),
  on(
    showDialog,
    (state, { variation, section, estimateListItems, priceListItems }) => ({
      ...state,
      variation,
      section,
      originalPriceListItems: priceListItems,
      originalEstimateListItems: estimateListItems,
      priceListItems: priceListItems,
      estimateListItems,
      selectedItemIds: [],
      selectedCategoryId: '',
      filter: '',
      sortField: 'itemCode',
      sortOrder: 'asc',
      categories: sortBy(
        [
          ...uniqBy(
            [...estimateListItems, ...priceListItems].map(
              (item) =>
                <PriceListCategory>{
                  id: item.priceListCategoryId,
                  code: item.priceListCategoryCode,
                  name: item.priceListCategoryName,
                  displayOrder: item.order,
                },
            ),
            (s) => s.id,
          ),
          <PriceListCategory>{
            id: '',
            name: 'All Categories',
            displayOrder: -1,
          },
        ],
        (s) => s.displayOrder,
      ),
    }),
  ),
);
