import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonDataFacade } from 'app/shared/store/facades';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-job-status-pill',
  templateUrl: './job-status-pill.component.html',
  styleUrls: ['./job-status-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, NgClass, AsyncPipe],
})
export class JobStatusPillComponent {
  constructor(private commonDataFacade: CommonDataFacade) {}

  private statusSubject = new BehaviorSubject<number>(0);
  status$ = this.statusSubject.asObservable();

  @Input() class?: string;
  @Input('status') set status(status: number) {
    this.statusSubject.next(status);
  }

  statuses$ = combineLatest([
    this.commonDataFacade.jobStatuses$,
    this.commonDataFacade.estimateStatuses$,
  ]).pipe(
    map(([jobStatuses, estimateStatuses]) => {
      return [...jobStatuses, ...estimateStatuses];
    }),
  );
  text$ = combineLatest([
    this.status$.pipe(filter((s) => !!s)),
    this.statuses$,
  ]).pipe(
    map(([status, statuses]) => statuses?.find((s) => s.id === status)?.name),
  );
}
