import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { EstimatePriceListItem, SupplierParameters } from 'app/shared/models';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { GeneralSettingsFacade } from '../../../settings/store/facades';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NumbersOnlyDirective } from '../../../../shared/directives/numbers-only.directive';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CurrentPriceListFacade } from 'app/views/price-list/store/facades';
import { SupplierListFacade } from 'app/views/suppliers/store/facades';

@Component({
  selector: 'app-estimate-price-list-item-dialog',
  templateUrl: './estimate-price-list-item-dialog.component.html',
  styleUrls: ['./estimate-price-list-item-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxTrimDirectiveModule,
    MatInputModule,
    NgIf,
    NumbersOnlyDirective,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    InputMaskModule,
    MatButtonModule,
    AsyncPipe,
  ],
  providers: [AsyncPipe],
})
export class EstimatePriceListItemDialogComponent implements OnInit, OnDestroy {
  currencyInputMask = createMask({
    parser: (v) => +v.split(',').join('').substring(1),
    prefix: '$',
    inputmode: 'numeric',
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
  });

  percentInputMask = createMask({
    parser: (v) => +v.split(',').join('').slice(0, -1),
    suffix: '%',
    inputmode: 'numeric',
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
  });

  compareSupplier(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }

  // validators
  checkRequired(controlName: string): boolean {
    return (
      this.form.get(controlName).hasError('required') &&
      this.form.get(controlName).touched
    );
  }

  greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === undefined || value ===  null || value === '') {
        return null;
      }

      const isValid = +value > 0;

      return isValid ? null : { greaterThanZero: true };
    };
  }

  greaterOrEqualThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value === undefined || value ===  null || value === '') {
        return null;
      }

      const isValid = +value >= 0;

      return isValid ? null : { greaterOrEqualThanZero: true };
    };
  }

  constructor(
    public dialogRef: MatDialogRef<EstimatePriceListItem>,
    public generalFacade: GeneralSettingsFacade,
    public facade: CurrentPriceListFacade,
    private async: AsyncPipe,
    public supplierListFacade: SupplierListFacade,
    @Inject(MAT_DIALOG_DATA) public data: EstimatePriceListItem,
    private fb: FormBuilder,
  ) {}

  unsubscriber$ = new Subject<void>();

  form: FormGroup = this.fb.group({
    description: ['', Validators.required],
    qty: ['', [Validators.required, this.greaterThanZeroValidator()]],
    unitPrice: ['', [Validators.required, this.greaterThanZeroValidator()]],
    unitOfMeasure: ['', Validators.required],
    unitTotal: ['', [this.greaterThanZeroValidator()]],
    onCost: ['10', [Validators.required,  this.greaterOrEqualThanZeroValidator()]],
    gstRate: ['10', Validators.required],
    supplier: ['', Validators.required],
  });

  qtyChange = this.form.get('qty').valueChanges.pipe(map((val) => +val));
  unitPriceChange = this.form
    .get('unitPrice')
    .valueChanges.pipe(map((val) => +val));
  onCost = this.form.get('onCost').valueChanges.pipe(map((val) => +val));
  isVariation;

  close() {
    this.dialogRef.close();
  }

  save() {
    const item = <EstimatePriceListItem>{ ...this.data, ...this.form.value };
    item.netQuote = item.unitTotal * (1 + item.onCost / 100);
    item.supplierId = (this.form.value.supplier as any).id;
    this.dialogRef.close(item);
  }

  ngOnInit(): void {
    combineLatest([this.qtyChange, this.unitPriceChange])
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(([qty, unitPrice]) => {
        const unitTotal = qty * unitPrice;
        this.form.patchValue({ unitTotal });
      });

    if (this.data?.id) {
      if (this.data.hasOwnProperty('variationItemCode')) {
        this.isVariation = true;
        this.form.addControl(
          'variationItemCode',
          new FormControl('', Validators.required),
        );
        this.form.addControl('variationComment', new FormControl(''));
      }
      this.form.patchValue(this.data);
    }

    const supplierList = this.async.transform(this.facade.supplierList$);
    if (!supplierList.items.length) {
      const query: SupplierParameters = {
        pageNumber: 1,
        pageSize: 99999,
        orderBy: [{ orderBy: 'name', descending: false }],
      };

      this.supplierListFacade.loadSupplierList(query);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
