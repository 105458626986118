<div class="flex flex-auto flex-col h-full " *ngIf="photo">
  <div class="flex flex-row justify-end">
    <div class="flex flex-1 justify-start">
      <button mat-icon-button [matTooltip]="'Back to photos'" (click)="close()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-left'"></mat-icon>
      </button>
    </div>
    <button mat-icon-button [matTooltip]="'Download'" (click)="download()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
    </button>
    <button
      *ngIf="!isReadOnlyMode"
      mat-icon-button
      [matTooltip]="'Delete'"
      (click)="delete()"
    >
      <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
    </button>
  </div>
  <div class="flex flex-auto flex-row h-full">
    <button
      mat-icon-button
      class="self-center"
      [disabled]="!canGoPrevious()"
      (click)="goPrevious()"
      [matTooltip]="'Previous photo'"
    >
      <mat-icon [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
    </button>
    <mat-tab-group animationDuration="2000ms" mat-align-tabs="start" class="flex-auto h-full w-full">
      <mat-tab label="Photo" class="h-full">
        <div class="inset-0">

        </div>
        <div
          class="flex-auto h-full bg-top bg-no-repeat bg-contain"
          [style.background-image]="
            'url(' + photo.url + '?' + (jobPhotosFacade.sasToken$ | async) + ')'
          "
        ></div>
      </mat-tab>
      <mat-tab *ngIf="!isReadOnlyMode" label="Edit/Annotate"  class="flex-auto h-full w-full">
        <div class="flex-auto h-full w-full">
          <pintura-editor
            #editor
            [src]="photoUrl"
            [options]="options"
            (process)="handleEditorProcess($event)"
          >
          </pintura-editor>
        </div>
      </mat-tab>
      <mat-tab label="Info">
        <app-photos-photo-info [photo]="photo"></app-photos-photo-info>
      </mat-tab>
    </mat-tab-group>
    <button
      mat-icon-button
      class="self-center"
      [disabled]="!canGoNext()"
      (click)="goNext()"
      [matTooltip]="'Next photo'"
    >
      <mat-icon [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
    </button>
  </div>
</div>
