<div class="w-full h-full absolute">
  <div *ngIf="!showMobile" class="hidden lg:flex flex-auto overflow-hidden">
    <div
      class="flex flex-col flex-auto sm:mb-4 overflow-hidden sm:overflow-y-auto px-4"
    >
      <div class="w-full mb-3">
        <div class="text-xl">Current</div>
      </div>
      <ng-container>
        <table
          *ngIf="dataSource; else noData"
          mat-table
          [dataSource]="dataSource"
          class="w-full"
        >
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let element">
              {{ element.version }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.created | date: "dd MMM yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="unitTotal">
            <th mat-header-cell *matHeaderCellDef>Unit Total</th>
            <td mat-cell *matCellDef="let element">
              {{ element.unitTotal | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="onCost">
            <th mat-header-cell *matHeaderCellDef>On cost %</th>
            <td mat-cell *matCellDef="let element">
              {{ element.onCost }}
            </td>
          </ng-container>

          <ng-container matColumnDef="profit">
            <th mat-header-cell *matHeaderCellDef>Profit</th>
            <td mat-cell *matCellDef="let element">
              {{ element.profitAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="netCost">
            <th mat-header-cell *matHeaderCellDef>Net quote</th>
            <td mat-cell *matCellDef="let element">
              {{ element.netQuoteAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gstAmount">
            <th mat-header-cell *matHeaderCellDef>GST</th>
            <td mat-cell *matCellDef="let element">
              {{ element.gstAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalCost">
            <th mat-header-cell *matHeaderCellDef>Quote</th>
            <td mat-cell *matCellDef="let element">
              {{ element.quoteAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <app-job-status-pill
                class="mx-1"
                [status]="element?.status"
              ></app-job-status-pill>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex flex-row flex-nowrap">
                <button
                  id="estimate-costings-manage-button"
                  class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                  mat-stroked-button
                  [color]="'primary'"
                  (click)="details(element)"
                >
                  <span class="ml-2 mr-1">Manage</span>
                </button>
                <button
                  *ngIf="!isReadOnlyMode"
                  id="estimate-costings-clone-button"
                  class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                  mat-stroked-button
                  [color]="'primary'"
                  [disabled]="!element.unitTotal"
                  (click)="$event.stopPropagation(); clone(element)"
                >
                  <span class="ml-2 mr-1">Copy</span>
                </button>
                <button
                  *ngIf="!isReadOnlyMode"
                  id="estimate-costings-accept-button"
                  class="text-xs rounded-xl min-h-8 h-8 px-4"
                  mat-stroked-button
                  [color]="'primary'"
                  [disabled]="!element.unitTotal || element.status !== 20"
                  (click)="$event.stopPropagation(); accept(element)"
                >
                  <span class="ml-2 mr-1">Accept</span>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </ng-container>
    </div>
  </div>

  <!-- HISTORY -->
  <div class="hidden lg:flex flex-auto overflow-hidden">
    <div
      class="flex flex-col flex-auto sm:mb-4 overflow-hidden sm:overflow-y-auto px-4"
    >
      <div class="w-full mb-3">
        <div class="text-xl">History</div>
      </div>
      <ng-container>
        <table
          mat-table
          [dataSource]="estimatesHistoryDataSource"
          class="w-full"
        >
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let element">
              {{ element.version }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.created | date: "dd MMM yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="unitTotal">
            <th mat-header-cell *matHeaderCellDef>Unit Total</th>
            <td mat-cell *matCellDef="let element">
              {{ element.unitTotal | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="onCost">
            <th mat-header-cell *matHeaderCellDef>On cost %</th>
            <td mat-cell *matCellDef="let element">
              {{ element.onCost }}
            </td>
          </ng-container>

          <ng-container matColumnDef="profit">
            <th mat-header-cell *matHeaderCellDef>Profit</th>
            <td mat-cell *matCellDef="let element">
              {{ element.profitAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="netCost">
            <th mat-header-cell *matHeaderCellDef>Net quote</th>
            <td mat-cell *matCellDef="let element">
              {{ element.netQuoteAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gstAmount">
            <th mat-header-cell *matHeaderCellDef>GST</th>
            <td mat-cell *matCellDef="let element">
              {{ element.gstAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalCost">
            <th mat-header-cell *matHeaderCellDef>Quote</th>
            <td mat-cell *matCellDef="let element">
              {{ element.quoteAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <app-job-status-pill
                class="mx-1"
                [status]="element?.status"
              ></app-job-status-pill>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <div class="flex flex-row flex-nowrap">
                <button
                  class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                  mat-stroked-button
                  [color]="'primary'"
                  (click)="details(element)"
                >
                  <span class="ml-2 mr-1">Manage</span>
                </button>
                <button
                  *ngIf="!isReadOnlyMode"
                  class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                  mat-stroked-button
                  [color]="'primary'"
                  (click)="$event.stopPropagation(); clone(element)"
                >
                  <span class="ml-2 mr-1">Copy</span>
                </button>
                <button
                  *ngIf="!isReadOnlyMode"
                  class="text-xs rounded-xl min-h-8 h-8 px-4"
                  mat-stroked-button
                  [color]="'primary'"
                  [disabled]="!element.unitTotal || element.status !== 20"
                  (click)="$event.stopPropagation(); accept(element)"
                >
                  <span class="ml-2 mr-1">Accept</span>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
        <dev class="my-3" *ngIf="!estimatesHistoryDataSource">
          <i>
            To create a revision of an estimate select Copy on the estimate
            costing.
          </i>
        </dev>
      </ng-container>
    </div>
  </div>

  <!--  mobile and tablet -->
  <div
    class="lg:hidden flex flex-col flex-auto xs:mb-4 overflow-hidden xs:overflow-y-auto px-4"
  >
    <div class="w-full mb-3">
      <div class="text-xl">Current</div>
    </div>
    <ng-container *ngIf="(estimates$ | async).length; else noData">
      <fuse-card
        *ngFor="let estimate of estimates$ | async; index as i"
        class="flex xs:flex-col md:flex-row flex-shrink-0 w-full p-4 mb-2"
      >
        <div class="flex flex-col xs:w-full sm:w-1/4">
          <div class="text-md font-semibold leading-tight">
            Version: {{ estimate?.version }}
          </div>
          <div class="text-secondary font-medium text-md">
            Date: {{ estimate?.created | date: "dd MMM yyyy" }}
          </div>
          <div class="text-secondary font-medium text-md">
            Status: {{ estimate?.statusText }}
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/4 md:mt-0 md:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tight">
            Unit Total: {{ estimate.unitTotal | currency }}
          </div>
          <div class="text-secondary font-medium text-md">
            On cost %: {{ estimate.onCost }}
          </div>
          <div class="text-secondary font-medium text-md">
            Net quote: {{ estimate.netQuoteAmount | currency }}
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/4 md:mt-0 md:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tigh">
            Profit: {{ estimate?.profitAmount | currency }}
          </div>
          <div class="text-secondary font-medium text-md break-all">
            Quote: {{ estimate?.quoteAmount | currency }}
          </div>
          <div class="text-secondary font-medium text-md break-all">
            GST: {{ estimate?.gstAmount | currency }}
          </div>
        </div>

        <div
          class="flex xs:w-full sm:w-1/4 justify-end items-center xs:ml-auto"
        >
          <button
            id="estimate-costings-manage-button"
            class="type-action mr-2"
            mat-stroked-button
            [color]="'primary'"
            (click)="details(estimate)"
          >
            <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
          </button>
          <button
            *ngIf="!isReadOnlyMode"
            id="estimate-costings-clone-button"
            class="type-action mr-2"
            mat-stroked-button
            [color]="'primary'"
            (click)="clone(estimate)"
          >
            <mat-icon
              matPrefix
              [svgIcon]="'heroicons_outline:document-duplicate'"
            ></mat-icon>
          </button>
          <button
            *ngIf="!isReadOnlyMode"
            id="estimate-costings-accept-button"
            class="type-action"
            mat-stroked-button
            [color]="'primary'"
            [disabled]="!estimate.unitTotal || estimate.status !== 20"
            (click)="accept(estimate)"
          >
            <mat-icon
              matPrefix
              [svgIcon]="'heroicons_outline:check-circle'"
            ></mat-icon>
          </button>
        </div>
      </fuse-card>
    </ng-container>
  </div>
  <div
    class="lg:hidden flex flex-col flex-auto mb-4 overflow-hidden xs:overflow-y-auto px-4"
  >
    <div class="w-full mb-3">
      <div class="text-xl">History</div>
    </div>
    <ng-container *ngIf="(estimatesHistory$ | async).length; else noData">
      <fuse-card
        *ngFor="let estimate of estimatesHistory$ | async; index as i"
        class="flex xs:flex-col md:flex-row flex-shrink-0 w-full p-4 mb-2"
      >
        <div class="flex flex-col xs:w-full sm:w-1/4">
          <div class="text-md font-semibold leading-tight">
            Version: {{ estimate?.version }}
          </div>
          <div class="text-secondary font-medium text-md">
            Date: {{ estimate?.created | date: "dd MMM yyyy" }}
          </div>
          <div class="text-secondary font-medium text-md">
            Status: {{ estimate?.statusText }}
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/4 md:mt-0 md:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tight">
            Unit Total: {{ estimate.unitTotal | currency }}
          </div>
          <div class="text-secondary font-medium text-md">
            On cost %: {{ estimate.onCost }}
          </div>
          <div class="text-secondary font-medium text-md">
            Net quote: {{ estimate.netQuoteAmount | currency }}
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/4 md:mt-0 md:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tigh">
            Profit: {{ estimate?.profitAmount | currency }}
          </div>
          <div class="text-secondary font-medium text-md break-all">
            Quote: {{ estimate?.quoteAmount | currency }}
          </div>
          <div class="text-secondary font-medium text-md break-all">
            GST: {{ estimate?.gstAmount | currency }}
          </div>
        </div>

        <div
          class="flex xs:w-full sm:w-1/4 justify-end items-center xs:ml-auto"
        >
          <button
            class="type-action mr-2"
            mat-stroked-button
            [color]="'primary'"
            (click)="details(estimate)"
          >
            <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
          </button>
          <button
            *ngIf="!isReadOnlyMode"
            class="type-action mr-2"
            mat-stroked-button
            [color]="'primary'"
            (click)="clone(estimate)"
          >
            <mat-icon
              matPrefix
              [svgIcon]="'heroicons_outline:document-duplicate'"
            ></mat-icon>
          </button>
          <button
            *ngIf="!isReadOnlyMode"
            class="type-action"
            mat-stroked-button
            [color]="'primary'"
            [disabled]="!estimate.unitTotal || estimate.status !== 20"
            (click)="accept(estimate)"
          >
            <mat-icon
              matPrefix
              [svgIcon]="'heroicons_outline:check-circle'"
            ></mat-icon>
          </button>
        </div>
      </fuse-card>
    </ng-container>
  </div>
</div>

<ng-template #noData>
  <div class="relative w-full">
    <div
      *ngIf="(isLoaded$ | async) && (estimates$ | async).length === 0"
      class="absolute w-full text-base font-semibold text-center"
    >
      No result for your search
    </div>
  </div>
</ng-template>
