/* eslint-disable arrow-parens */
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { JobService } from 'app/views/jobs/services/job.service';
import { JobDetailFacade } from 'app/views/jobs/store';
import moment from 'moment';
import { filter, tap } from 'rxjs/operators';
import { GoogleMapComponent } from '../../../../../layout/common/google-map/google-map.component';
import { Job, JobStatus, JobType } from '../../../../../shared/models';

@Component({
    selector: 'app-job-info',
    templateUrl: './job-info.component.html',
    styleUrls: ['./job-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        AsyncPipe,
        DatePipe,
        GoogleMapComponent,
    ],
})
export class JobInfoComponent implements OnInit {
    isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];
    jobStatus = JobStatus;
    jobId: string;
    startDateControl = this.fb.control(null);
    job: Job;
    jobType = JobType;
    canResetToEstimate$;

    title$ = this.facade.title$;
    job$ = this.facade.job$.pipe(
        filter((job) => !!job),
        tap((job) => {
            this.job = job;
            this.jobId = job.id;
            this.startDateControl.setValue(job.startDate);
        })
    );

    constructor(
        private fb: FormBuilder,
        private facade: JobDetailFacade,
        private confirm: FuseConfirmationService,
        private jobService: JobService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {}

    onDateChange(): void {
        this.startDateControl.setValue(
            moment(this.startDateControl.value).format()
        );
        this.job = { ...this.job };
        this.job.startDate = this.startDateControl.value;
        this.facade.updateJob(this.job);
    }
}
