import { createReducer, on } from '@ngrx/store';
import {
  SingleSaveEstimate,
} from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import { flatten, max, sortBy, sumBy, uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getEstimate, getEstimateFailure, getEstimateSuccess } from '..';
import {
  addEstimateSection,
  addRemoveEstimatePriceListItems,
  deleteEstimatePriceListItem,
  deleteEstimateSection,
  deselectEstimatePriceListItems,
  moveEstimateSectionDown,
  moveEstimateSectionUp,
  renameEstimateSection,
  resetEstimate,
  saveEstimate,
  saveEstimateSuccess,
  selectEstimatePriceListItems,
  updateEstimateOnCost,
  updateEstimatePriceListItem,
  updateEstimateSectionPriceListItems,
  updateEstimateStatus,
  updateEstimateStatusFailure,
  updateEstimateStatusSuccess,
  updateEstimateTotals,
  updateEstimate,
} from '../actions';
import * as fromUserActions from 'app/shared/store/actions/account-user.actions';

export interface EstimateDetailState {
  originalEstimate: SingleSaveEstimate;
  estimate: SingleSaveEstimate;
  selectedPriceListItemIds: string[];
  editing: boolean;
  callState: CallState;
  error: any;
}

export const initialState: EstimateDetailState = {
  originalEstimate: null,
  estimate: null,
  selectedPriceListItemIds: [],
  editing: false,
  callState: LoadingState.INIT,
  error: null,
};

export const estimateDetailReducer = createReducer(
  initialState,
  on(saveEstimateSuccess, (state, { estimate }) => ({
    ...state,
    estimate: { ...estimate },
    editing: false,
    originalEstimate: { ...estimate },
    callState: LoadingState.LOADED,
  })),
  on(updateEstimateOnCost, (state, { onCost }) => ({
    ...state,
    editing: state.estimate.onCost === onCost ? false : true,
    estimate: {
      ...state.estimate,
      onCost,
      items: state.estimate.items.map((i) => {
        return {
          ...i,
          onCost,
          netQuote: i.quotable ? i.unitTotal * (1 + onCost / 100) : 0,
        };
      }),
    },
  })),
  on(updateEstimateTotals, (state, _) => {
      const quotableItems = state.estimate.items.filter((i) => i.quotable);
      const unitTotal = sumBy(state.estimate.items, (i) => i.unitTotal);
      const quotableUnitTotal = sumBy(quotableItems, (i) => i.unitTotal);
      const netQuoteAmount = sumBy(quotableItems, (i) => i.netQuote);
      const profitAmount = netQuoteAmount - unitTotal;
      let onCost =
        +((netQuoteAmount / unitTotal - 1) * 100).toFixed(2);
      //  onCost can be set as zero only if calculation fails
      if (Number.isNaN(onCost)) {
        onCost = 0;
      }
      if (state.estimate.items.length === 0) {
        onCost = state.originalEstimate.onCost;
      }

      const quoteAmount =
        Math.round(
          100 * sumBy(quotableItems, (i) => i.netQuote * (1 + i.gstRate / 100)),
        ) / 100;
      const gstAmount = sumBy(
        quotableItems,
        (i) => (i.unitTotal * (1 + onCost / 100) * i.gstRate) / 100,
      );

      return {
        ...state,
        estimate: {
          ...state.estimate,
          unitTotal,
          netQuoteAmount,
          profitAmount,
          quoteAmount,
          quotableUnitTotal,
          gstAmount,
          onCost,
        }
      };
  }),
  on(saveEstimate, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  // on(saveEstimateSuccess, (state, { estimate }) => ({
  //   ...state,
  //   editing: false,
  //   estimate,
  //   originalEstimate: { ...estimate },
  //   callState: LoadingState.LOADED,
  // })),
  on(updateEstimate, (state, { estimate }) => ({
    ...state,
    estimate: { ...estimate },
  })),
  on(resetEstimate, (state, _) => ({
    ...state,
    editing: false,
    estimate: { ...state.originalEstimate },
    callState: LoadingState.LOADED,
  })),
  on(addEstimateSection, (state, { name }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      // sections: [
      //   ...state.estimate.sections,
      //   <SingleSaveEstimateSection>{
      //     id: uuidv4(),
      //     estimateId: state.estimate.id,
      //     name,
      //     items: [],
      //     // order: 1 + (max(state.estimate.sections.map((s) => s.order)) ?? 0),
      //     order: 1,
      //   },
      // ],
    },
  })),
  on(renameEstimateSection, (state, { id, name }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      // sections: state.estimate.sections.map((s) => {
      //   if (s.id === id) {
      //     return { ...s, name };
      //   }
      //   return s;
      // }),
    },
  })),
  on(moveEstimateSectionUp, (state, { id }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      // sections: (function () {
      //   let sections = sortBy(state.estimate.sections, (s) => s.order);
      //   const index = sections.findIndex((s) => s.id === id);
      //   const order = sections[index].order;
      //
      //   sections = sections.map((s, i) => {
      //     if (i == index) {
      //       return { ...s, order: sections[i - 1].order };
      //     } else if (i === index - 1) {
      //       return { ...s, order };
      //     } else {
      //       return s;
      //     }
      //   });
      //
      //   return sortBy(sections, (s) => s.order).map((s, i) => {
      //     return { ...s, order: i };
      //   });
      // })(),
    },
  })),
  on(moveEstimateSectionDown, (state, { id }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      // sections: (function () {
      //   let sections = sortBy(state.estimate.sections, (s) => s.order);
      //   const index = sections.findIndex((s) => s.id === id);
      //   const order = sections[index].order;
      //
      //   sections = sections.map((s, i) => {
      //     if (i == index) {
      //       return { ...s, order: sections[i + 1].order };
      //     } else if (i === index + 1) {
      //       return { ...s, order };
      //     } else {
      //       return s;
      //     }
      //   });
      //
      //   return sortBy(sections, (s) => s.order).map((s, i) => {
      //     return { ...s, order: i };
      //   });
      // })(),
    },
  })),
  on(
    updateEstimateSectionPriceListItems,
    (state, { estimateSectionId, added, removed }) => ({
      ...state,
      editing: true,
      estimate: {
        ...state.estimate,
        // sections: state.estimate.sections.map((section) => {
        //   if (section.id === estimateSectionId) {
        //     const onCost = state.estimate.onCost ?? 0;
        //     const removedIds = removed.map((r) => r.id);
        //     const items = [...section.items, ...added]
        //       .filter((i) => !removedIds.includes(i.id))
        //       .map((i) => {
        //         return {
        //           ...i,
        //           id: uuidv4(),
        //           onCost,
        //           netQuote: i.unitTotal * (1 + onCost / 100),
        //         };
        //       });
        //
        //     return { ...section, items };
        //   }
        //   return section;
        // }),
      },
    }),
  ),
  on(
    addRemoveEstimatePriceListItems,
    (state, {added, removed }) => {
      const removedIds = removed.map((r) => r.id);
      const onCost = state.estimate.onCost ?? 0;
      return {
        ...state,
        editing: true,
        estimate: {
          ...state.estimate,
          items: [...state.estimate.items, ...added]
            .filter((i) => !removedIds.includes(i.id))
            .map((i) => {
              return {
                ...i,
                id: uuidv4(),
                onCost,
                netQuote: i.unitTotal * (1 + onCost / 100),
              };
            })
        }
      }
    }),
  on(selectEstimatePriceListItems, (state, { ids }) => ({
    ...state,
    selectedPriceListItemIds: uniq([...state.selectedPriceListItemIds, ...ids]),
  })),
  on(deselectEstimatePriceListItems, (state, { ids }) => ({
    ...state,
    selectedPriceListItemIds: state.selectedPriceListItemIds.filter(
      (id) => !ids.includes(id),
    ),
  })),
  on(deleteEstimatePriceListItem, (state, { id }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      items: state.estimate.items.filter((i) => i.id !== id),
    }
  })),
  on(updateEstimatePriceListItem, (state, { item }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      items: state.estimate.items.map((i) => (i.id === item.id ? item : i)),
    },
  })),
  on(deleteEstimateSection, (state, { id }) => ({
    ...state,
    editing: true,
    estimate: {
      ...state.estimate,
      // sections: state.estimate.sections.filter((s) => s.id !== id),
    },
  })),
  on(getEstimate, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getEstimateFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getEstimateSuccess, (state, { estimate }) => ({
    ...state,
    estimate: estimate,
    originalEstimate: estimate,
    callState: LoadingState.LOADED,
  })),
  on(updateEstimateStatus, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateEstimateStatusFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateEstimateStatusSuccess, (state) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),
  on(fromUserActions.uploadQuoteLogoSuccess, (state, payload) => ({
    ...state,
    estimate: {
      ...state.estimate,
      quote: {
        ...state.estimate.quote,
        logo: payload.fileLocation,
      },
    },
  })),
);
