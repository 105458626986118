import { createSelector } from '@ngrx/store';
import { selectSettingsState, SettingsState } from '../reducers';
import { BillingSettingsState } from '../reducers/billing-settings.reducer';

export const selectBillingSettingsState = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.billingSettings,
);

export const selectBillingSettings = createSelector(
  selectBillingSettingsState,
  (state: BillingSettingsState) => state.settings,
);
