import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { JobFile } from 'app/shared/models';
import { NgIf } from '@angular/common';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-job-files-file-edit',
  templateUrl: './job-files-file-edit.component.html',
  styleUrls: ['./job-files-file-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxTrimDirectiveModule,
    NgIf,
  ],
})
export class JobFilesFileEditComponent implements OnInit {
  editForm: FormGroup = undefined;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<JobFilesFileEditComponent>,
    @Inject(MAT_DIALOG_DATA) public file: JobFile,
  ) {
    this.editForm = this.fb.group({
      name: [this.file.name, Validators.required],
    });
  }

  get folderExists(): boolean {
    return (
      this.editForm.get('name').hasError('folderExists') &&
      this.editForm.get('name').touched
    );
  }

  get required(): boolean {
    return (
      this.editForm.get('name').hasError('required') &&
      this.editForm.get('name').touched
    );
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  save(): void {
    const response = {
      ...this.file,
      name: this.editForm.controls.name.value,
    };
    this.dialogRef.close(response);
  }
}
