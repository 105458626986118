import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Snippet, SnippetArea, SnippetType } from 'app/shared/models';
import { MatButtonModule } from '@angular/material/button';
import { QuillEditorElementComponent } from '../quill-editor-element/quill-editor-element.component';
import { NgClass } from '@angular/common';
import { Observable } from 'rxjs';

export interface ConfigQuill {
  content?: string;
  title?: string;
  titleClass?: string;
  noRequired?: boolean;
  minlength?: number;
  maxlength?: number;
  snippetContent$?: Observable<Snippet>;
  snippetsParams?: {
    snippetType?: SnippetType;
    snippetArea?: SnippetArea;
  };
}

@Component({
  selector: 'app-quill-editor-modal',
  templateUrl: './quill-editor-modal.component.html',
  styleUrls: ['./quill-editor-modal.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    QuillEditorElementComponent,
    MatButtonModule,
  ],
})
export class QuillEditorModalComponent implements OnInit {
  form = this.fb.group({
    content: [''],
  });

  get content() {
    return this.form.get('content');
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<QuillEditorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfigQuill,
  ) {
    let validators = [];
    if (!data?.noRequired) {
      validators.push(Validators.required);
    }
    if (data?.minlength !== 0 && !data?.minlength) {
      if (!data) {
        data = {};
      }
      data.minlength = 3;
    }
    if (!data?.maxlength) {
      if (!data) {
        data = {};
      }
      data.maxlength = 1024;
    }
    this.content.setValidators(validators);
  }

  ngOnInit(): void {
    if (this.data) {
      this.content.setValue(this.data.content);
    }
  }

  save(): void {
    this.dialogRef.close(this.form.value.content);
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }
}
