import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { FileUpload } from '../models';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  upload(file: FileUpload): Observable<HttpEvent<{}>> {
    file.formData.append('file', file, file.name);

    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${environment.urls.api}/${file.url}`,
      file.formData,
      options,
    );

    return this.http.request(req);
  }
}
