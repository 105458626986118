import { tap } from 'rxjs';
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable arrow-parens */
import {
  AsyncPipe,
  NgClass,
  NgIf,
  NgStyle,
  NgFor,
  SlicePipe,
  PercentPipe,
  CurrencyPipe,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  PageEvent,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTableDataSource as MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { PriceListCategory, PriceListItem } from 'app/shared/models';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { PriceListCategoryDialogComponent } from '..';
import { CurrentPriceListFacade } from '../../store/facades';
import { PriceListItemDialogComponent } from '../price-list-item-dialog/price-list-item-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-price-list-category',
  templateUrl: './price-list-category.component.html',
  styleUrls: ['./price-list-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    NgClass,
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    NgStyle,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgFor,
    MatPaginatorModule,
    AsyncPipe,
    SlicePipe,
    PercentPipe,
    CurrencyPipe,
  ],
})
export class PriceListCategoryComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @HostListener('mousedown', ['$event'])
  handleClick(event): void {
    if (event.target.innerText === 'drag_indicator') {
      this.expanded = false;
    }
  }
  @Input() category: PriceListCategory;
  @Input() items: PriceListItem[];
  @Input() expanded = false;
  @Input() isReadOnly;

  public itemsSubject$ = new BehaviorSubject<PriceListItem[]>([]);
  public items$ = this.itemsSubject$.asObservable();

  public pageSize = 10;
  public pageIndex = 0;

  private unsubscriber$ = new Subject<void>();
  private selectedItemIdsSubject$ = new BehaviorSubject<string[]>([]);
  private selectedItemIds$ = this.selectedItemIdsSubject$.asObservable();

  public dataSource: MatTableDataSource<PriceListItem>;
  public displayedColumns: string[] = [
    'selected',
    'itemCode',
    'description',
    'supplier',
    // 'supplierItemCode',
    'unitPrice',
    'gstRate',
    'qty',
    'unitOfMeasure',
    'action',
  ];

  public allSelected = false;
  public anySelected = false;
  public selectedCount = 0;

  public isSelected$ = (item: PriceListItem): Observable<boolean> =>
    this.selectedItemIds$.pipe(map((ids) => ids.includes(item.id)));

  constructor(
    private dialog: MatDialog,
    private facade: CurrentPriceListFacade,
    private cd: ChangeDetectorRef,
    private async: AsyncPipe,
  ) {}

  getCurrentPageItems(): PriceListItem[] {
    const skip: number = this.paginator.pageSize * this.paginator.pageIndex;
    const items = this.dataSource
      .sortData(this.dataSource.filteredData, this.dataSource.sort)
      .filter((u, i) => i >= skip)
      .filter((u: PriceListItem, i: number) => i < this.paginator.pageSize);

    return items;
  }

  selectItem(item: PriceListItem, ob: MatCheckboxChange): void {
    if (ob.checked) {
      this.selectedItemIdsSubject$.next([
        ...this.selectedItemIdsSubject$.value,
        item.id,
      ]);
    } else {
      this.selectedItemIdsSubject$.next(
        this.selectedItemIdsSubject$.value.filter((i) => i !== item.id),
      );
    }
  }

  selectAll(ob: MatCheckboxChange): void {
    const theseIds = this.getCurrentPageItems().map((i) => i.id);
    const currentIds = this.selectedItemIdsSubject$.value;

    let newIds = [];
    if (ob.checked) {
      newIds = [...currentIds, ...theseIds];
    } else {
      newIds = currentIds.filter((id) => !theseIds.includes(id));
    }

    this.selectedItemIdsSubject$.next(newIds);
  }

  addCategoryItem(category: PriceListCategory, e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.dialog.open(PriceListItemDialogComponent, {
      width: '626px',
      data: {
        isEdit: false,
        category: category,
        priceListItem: null,
      },
    });
  }

  editCategory(category: PriceListCategory, e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.dialog.open(PriceListCategoryDialogComponent, {
      width: '626px',
      data: {
        isEdit: true,
        category: category,
        priceListItem: null,
      },
    });
  }

  deleteCategory(category: PriceListCategory, e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.facade.deletePriceListCategoryConfirm(category);
  }

  deleteSelectedItems(): void {
    const selectedIds = this.async.transform(this.selectedItemIds$);
    const items = this.async.transform(this.items$);
    const selectedItems = items.filter((i) => selectedIds.includes(i.id));
    if (selectedItems.length > 0) {
      this.deleteItems(selectedItems);
    }
  }

  editItem(item: PriceListItem, category: PriceListCategory): void {
    this.dialog.open(PriceListItemDialogComponent, {
      width: '626px',
      data: {
        isEdit: true,
        category: category,
        priceListItem: item,
      },
    });
  }

  deleteItems(items: PriceListItem[]): void {
    this.facade.deletePriceListItemsConfirm(items);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.items$.pipe(distinctUntilChanged()),
      this.selectedItemIds$,
      this.sort.sortChange.pipe(startWith(null)),
      this.paginator.page.pipe(startWith(null)),
    ])
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(([items, selectedIds]) => {
        this.dataSource = new MatTableDataSource(items);

        // Don't remove this, it's needed otherwise the paginator won't work.
        // Must be here right after dataSource is set and before paginator is set.
        this.cd.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        const data = this.dataSource._pageData(this.dataSource.filteredData);

        this.selectedCount = items.filter((i) =>
          selectedIds.includes(i.id),
        ).length;
        this.anySelected = items.some((i) => selectedIds.includes(i.id));
        this.allSelected = items.every((i) => selectedIds.includes(i.id));
      });
    this.getCurrentPageItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.itemsSubject$.next(this.items);
  }

  paginatorChange(pageEvent: PageEvent): void {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
  }
}
