import { createAction, props } from '@ngrx/store';
import {
  PaginatedListOfJobCosting,
  JobCostingParameters,
} from 'app/shared/models';

export enum JobVariationsListActionTypes {
  GetJobVariations = '[Job Variations List] Get Job Variations',
  GetJobVariationsSuccess = '[Job Variations List] Get Job Variations Success',
  GetJobVariationsFailure = '[Job Variations List] Get Job Variations Failure',
  GetJobCostingSummary = '[Job Variations List] Get JobCostingSummary',
  GetJobCostingSummarySuccess = '[Job Variations List] Get JobCostingSummary Success',
  GetJobCostingSummaryFailure = '[Job Variations List] Get JobCostingSummary Failure',
}

export const getJobVariations = createAction(
  JobVariationsListActionTypes.GetJobVariations,
  props<{ request: JobCostingParameters }>(),
);

export const getJobVariationsSuccess = createAction(
  JobVariationsListActionTypes.GetJobVariationsSuccess,
  props<{ variations: PaginatedListOfJobCosting }>(),
);

export const getJobVariationsFailure = createAction(
  JobVariationsListActionTypes.GetJobVariationsFailure,
  props<{ error: string }>(),
);

export const getJobCostingSummary = createAction(
  JobVariationsListActionTypes.GetJobCostingSummary,
  props<{ id: string }>(),
);

export const getJobCostingSummarySuccess = createAction(
  JobVariationsListActionTypes.GetJobCostingSummarySuccess,
  props<{ summary: any }>(), //CostingSummaryFiguresDto }>()
);

export const getJobCostingSummaryFailure = createAction(
  JobVariationsListActionTypes.GetJobCostingSummaryFailure,
  props<{ error: string }>(),
);
