import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchArrayItem',
  standalone: true,
})
export class SearchArrayItemPipe implements PipeTransform {
  transform(arr: any[], key: string, value: string): any[] {
    if (value && arr) {
      arr = arr.filter((item) => {
        return item[key].includes(value);
      });
    }
    return arr;
  }
}
