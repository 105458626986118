import { createReducer, on } from '@ngrx/store';
import { SingleSaveVariation, VariationChanges } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import { flatten, max, sortBy, sumBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  addSection,
  changeVariationStatusSuccess, clearVariationChangesState,
  deleteJobVariationPriceListItem,
  deleteSection,
  getJobVariation,
  getJobVariationFailure,
  getJobVariationSuccess,
  moveVariationSectionDown,
  moveVariationSectionUp,
  renameVariationSection,
  resetJobVariation,
  saveJobVariationSuccess,
  updateJobCosting,
  updateJobVariationName,
  updateJobVariationOnCost,
  updateJobVariationPriceListItem,
  updateJobVariationSectionPriceListItems,
  updateJobVariationTotals,
  uploadVariationLetterLogoSuccess,
} from '../actions';
import { cloneDeep } from 'lodash-es';

export interface JobVariationDetailState {
  variation: SingleSaveVariation;
  originalVariation: SingleSaveVariation;
  callState: CallState;
  editing: boolean;
  status: number;
  error: any;
  variationChanges: VariationChanges
}

export const initialState: JobVariationDetailState = {
  variation: null,
  status: null,
  originalVariation: null,
  callState: LoadingState.INIT,
  editing: false,
  error: null,
  variationChanges: null,
};

export const jobVariationDetailReducer = createReducer(
  initialState,
  on(
    updateJobVariationSectionPriceListItems,
    (state, { jobCostingSectionId, added, removed }) => ({
      ...state,
      editing: true,
      variation: {
        ...state.variation,
        sections: state.variation.sections.map((section) => {
          if (section.id === jobCostingSectionId) {
            const removedIds = removed.map((r) => r.id);
            added = [...added].map((item) => {
              return {
                ...item,
                id: uuidv4(),
                onCost: state.variation.onCost,
                netQuote: item.unitTotal * (1 + state.variation.onCost / 100),
                quotable: true,
              };
            });
            const items = [...section.items, ...added].filter(
              (i) => !removedIds.includes(i.id),
            );

            return { ...section, items };
          }
          return section;
        }),
      },
    }),
  ),
  on(updateJobVariationOnCost, (state, { onCost }) => ({
    ...state,
    editing: state.variation.onCost === onCost ? false : true,    
    variation: {
      ...state.variation,
      onCost,
      sections: state.variation.sections.map((s) => {
        return {
          ...s,
          items: s.items.map((i) => {
            return {
              ...i,
              onCost,
              netQuote: i.quotable ? i.unitTotal * (1 + onCost / 100) : 0,
            };
          }),
        };
      }),
    },
  })),
  on(updateJobVariationName, (state, { name }) => ({
    ...state,
    editing: true,
    variation: { ...state.variation, name },
  })),
  on(saveJobVariationSuccess, (state, { variation }) => ({
    ...state,
    variation,
    originalVariation: { ...variation },
    editing: false,
  })),
  on(resetJobVariation, (state, _) => ({
    ...state,
    editing: false,
    variation: { ...state.originalVariation },
  })),
  on(deleteJobVariationPriceListItem, (state, { id }) => ({
    ...state,
    editing: true,
    variation: {
      ...state.variation,
      sections: state.variation.sections.map((section) => {
        if (section.items.find((i) => i.id === id)) {
          return {
            ...section,
            items: section.items.filter((i) => i.id !== id),
          };
        }
        return section;
      }),
    },
  })),
  on(updateJobVariationPriceListItem, (state, { item }) => ({
    ...state,
    editing: true,
    variation: {
      ...state.variation,
      sections: state.variation.sections.map((section) => {
        if (section.items.find((i) => i.id === item.id)) {
          return {
            ...section,
            items: section.items.map((i) => (i.id === item.id ? item : i)),
          };
        }
        return section;
      }),
    },
  })),
  on(updateJobVariationTotals, (state, _) => ({
    ...state,
    variation: (function () {
      const items = flatten(state.variation.sections.map((s) => s.items));
      const quotableItems = items.filter((i) => i.quotable);
      const unitTotal = sumBy(items, (i) => i.unitTotal);
      const quotableUnitTotal = sumBy(quotableItems, (i) => i.unitTotal);
      const netQuoteAmount = sumBy(quotableItems, (i) => i.netQuote);
      const profitAmount = netQuoteAmount - unitTotal;
      const onCost =
        +((netQuoteAmount / unitTotal - 1) * 100).toFixed(2) ||
        state.variation.onCost;
      const quoteAmount =
        Math.round(
          100 * sumBy(quotableItems, (i) => i.netQuote * (1 + i.gstRate / 100)),
        ) / 100;
      const gstAmount = sumBy(
        quotableItems,
        (i) => (i.unitTotal * (1 + i.onCost / 100) * i.gstRate) / 100,
      );

      return {
        ...state.variation,
        unitTotal,
        netQuoteAmount,
        profitAmount,
        quoteAmount,
        quotableUnitTotal,
        gstAmount,
        onCost,
      };
    })(),
  })),
  on(getJobVariation, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobVariationSuccess, (state, { variationEx }) => {
    return {
      ...state,
      variation: {
        ...variationEx?.singleSaveVariation,
        sections: variationEx?.singleSaveVariation.sections.map((s, i) => {
          if (!s.order) {
            return {
              ...s,
              order: i + 1,
            };
          }
          return s;
        }),
      },
      variationChanges: variationEx?.variationChanges,
      status: variationEx?.singleSaveVariation.status,
      originalVariation: {
        ...variationEx?.singleSaveVariation,
        sections: variationEx?.singleSaveVariation.sections.map((s, i) => {
          if (!s.order) {
            return {
              ...s,
              order: i + 1,
            };
          }
          return s;
        }),
      },
      callState: LoadingState.LOADED,
    };
  }),
  on(getJobVariationFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  // on(changeVariationStatus, (state) => ({
  //   ...state,
  //   callState: LoadingState.LOADING,
  // })),
  on(changeVariationStatusSuccess, (state, { variation }) => {
    return {
      ...state,
      variation: { ...state.variation, status: variation.status },
      status: variation.status,
      callState: LoadingState.LOADED,
    };
  }),

  on(addSection, (state, { name }) => {
    return {
      ...state,
      editing: true,
      variation: {
        ...state.variation,
        sections: [
          ...state.variation.sections,
          <SingleSaveVariation>{
            id: uuidv4(),
            jobCostingId: state.variation.id,
            name,
            items: [],
            order: 1 + (max(state.variation.sections.map((s) => s.order)) ?? 0),
          },
        ],
      },
    };
  }),

  on(deleteSection, (state, { id }) => ({
    ...state,
    editing: true,
    variation: {
      ...state.variation,
      sections: state.variation.sections.filter((s) => s.id !== id),
    },
  })),

  on(moveVariationSectionUp, (state, { id }) => {
    let sections = cloneDeep(state.variation.sections);
    const index = sections.findIndex((s) => s.id === id);
    sections[index].order = sections[index].order - 1;
    sections[index - 1].order = sections[index - 1].order + 1;
    sections = sortBy(sections, (s) => s.order);
    return {
      ...state,
      editing: true,
      variation: {
        ...state.variation,
        sections,
      },
    };
  }),

  on(moveVariationSectionDown, (state, { id }) => {
    let sections = cloneDeep(state.variation.sections);
    const index = sections.findIndex((s) => s.id === id);
    sections[index].order = sections[index].order + 1;
    sections[index + 1].order = sections[index + 1].order - 1;
    sections = sortBy(sections, (s) => s.order);
    return {
      ...state,
      editing: true,
      variation: {
        ...state.variation,
        sections,
      },
    };
  }),

  on(renameVariationSection, (state, { id, name }) => ({
    ...state,
    editing: true,
    variation: {
      ...state.variation,
      sections: state.variation.sections.map((s) => {
        if (s.id === id) {
          return { ...s, name };
        }
        return s;
      }),
    },
  })),
  on(updateJobCosting, (state, { jobCosting }) => ({
    ...state,
    variation: {
      ...state.variation,
      ...jobCosting,
    },
  })),
  // on(uploadVariationLetterLogo, ((state) => ({
  //   ...state,
  //   callState: LoadingState.LOADING
  //   }))),
  on(uploadVariationLetterLogoSuccess, (state, { fileLocation }) => ({
    ...state,
    variation: {
      ...state.variation,
      quote: {
        ...state.variation.quote,
        logo: fileLocation,
      },
    },
    callState: LoadingState.LOADED,
  })),

  on(clearVariationChangesState,  (state)  => ({
    ...state,
    variationChanges: null
  })),
);
