import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';

import { TeamSettingsFacade } from '../../store/facades';
import { TeamMember, UserRole } from 'app/shared/models';
import { TeamSettingsCommon } from '../../store/reducers/team-settings.reducer';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-team-member-attributes-dialog',
  templateUrl: 'team-member-attributes-dialog.component.html',
  styleUrls: ['./team-member-attributes-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    NgFor,
    NgIf,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
  ],
})
export class TeamMemberAttributesDialogComponent implements OnInit {
  masterSelected = false;
  selectedItems: any[] = [];

  private originalRoles = undefined;

  getUserRole(name: string): UserRole {
    return this.data.roles.filter((r) => r.name === name)[0];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      member: TeamMember;
      roles: UserRole[];
      common: TeamSettingsCommon;
    },
    public dialogRef: MatDialogRef<TeamMemberAttributesDialogComponent>,
    public facade: TeamSettingsFacade,
  ) {
    this.originalRoles = JSON.stringify(this.data.roles);
  }

  ngOnInit() {}

  get hasFormChanged(): boolean {
    return this.originalRoles === JSON.stringify(this.data.roles);
  }

  closeOk() {
    this.dialogRef.close(this.data.roles);
  }

  changeAccessLevel($event, role: UserRole): void {
    role.accessLevel = $event.value;
  }

  changeSelected($event, role: UserRole): void {
    role.isSelected = $event.checked;
  }

  changeSelectedJob($event): void {
    const jobs = this.getUserRole('Jobs');
    const invoicing = this.getUserRole('Invoicing');
    const ordering = this.getUserRole('Ordering');
    jobs.isSelected = $event.checked;
    if ($event.checked === false) {
      invoicing.isSelected = false;
      ordering.isSelected = false;
    }
  }

  togle(event, i: number) {
    this.data.roles[i].isSelected = event.checked;
  }

  handleUpdateTeamMemberRoleChange($event, role: UserRole, i): void {
    role.accessLevel = $event.value;
  }
}
