<div class="shell">
  <ngx-extended-pdf-viewer
    *ngIf="data?.generatedFile"
    [filenameForDownload]="data?.fileName"
    [base64Src]="data?.generatedFile"
    useBrowserLocale="true"
    [zoom]="75"
    height="70vh"
    [customToolbar]="customToolbar"
  ></ngx-extended-pdf-viewer>
</div>
<!--<div class="output-section__error" *ngIf="fileFetchingError">-->
<!--  <h3>There are no data matching your request</h3>-->
<!--</div>-->

<ng-template #customToolbar>
  <div class="header flex items-center">
    <button mat-icon-button id="zoomIn">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-icon-button id="zoomOut">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <button
      class="text-xs rounded-xl min-h-8 h-8 px-4"
      mat-stroked-button
      id="download"
    >
      <div class="w-full flex flex-row items-center text-secondary">
        <mat-icon class="mr-2 text-hint">download</mat-icon>
        Download
      </div>
    </button>
    <button
      (click)="dialogRef.close(); router.navigateByUrl(data.routerLink)"
      *ngIf="data.routerLink"
      class="text-xs rounded-xl min-h-8 h-8 px-4 ml-4"
      mat-stroked-button
      id="link"
    >
      <div class="w-full flex flex-row items-center text-secondary">
        Navigate to the Job {{ data.type }}
      </div>
    </button>
  </div>
</ng-template>

<!--<div id="care-plan-modal" class="modal-body">-->
<!--  <iframe-->
<!--    [src]="data.fileUrl"-->
<!--    style="width: 100%; height: 75vh"-->
<!--    frameborder="0"-->
<!--    scrolling="no"-->
<!--  >-->
<!--    <p>It appears your web browser doesn't support iframes.</p>-->
<!--  </iframe>-->
<!--</div>-->
