import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectSuppliersListByJobState = createSelector(
  selectJobState,
  (s: JobState) => s.suppliersDesc,
);

export const selectSuppliersListByJobItems = createSelector(
  selectSuppliersListByJobState,
  (s) => s.suppliersDesc,
);

export const selectUseCategories = createSelector(
  selectSuppliersListByJobState,
  (s) => s.useCategories,
);

export const selectSuppliersListByJobLoading = createSelector(
  selectSuppliersListByJobState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectSuppliersListByJobLoaded = createSelector(
  selectSuppliersListByJobState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectSuppliersListByJobError = createSelector(
  selectSuppliersListByJobState,
  (s) => getError(s.callState),
);
