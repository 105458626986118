import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ToastService } from '@shared/services/toast.service';
import {
    EstimatePriceListItem,
    SingleSaveEstimate,
    Template,
} from 'app/shared/models';
import { JobService } from 'app/views/jobs/services/job.service';
import { flatten, sumBy } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import {
    addRemoveEstimatePriceListItems,
    addRemoveEstimatePriceListItemsShowDialog,
    deleteEstimatePriceListItem,
    deleteEstimatePriceListItemConfirm,
    deleteEstimateSection,
    deleteEstimateSectionConfirm,
    getEstimate,
    getEstimateFailure,
    getEstimateSuccess,
    updateEstimatePriceListItem,
    updateEstimateSectionPriceListItems,
    updateEstimateSectionPriceListItemsShowDialog,
} from '..';
import { AddRemovePriceListItemsDialogComponent } from '../../../../shared/components';
import {
    EstimatePriceListItemDialogComponent,
    EstimateSectionDialogComponent,
} from '../../components';
import { EstimateService } from '../../services/estimate.service';
import {
    addEstimateSection,
    createTemplate,
    createTemplateFailure,
    createTemplateSuccess,
    renameEstimateSection,
    saveEstimate,
    saveEstimateFailure,
    saveEstimateSuccess,
    showAddEstimateSectionDialog,
    showRenameEstimateSectionDialog,
    updateEstimateCustomerQuote,
    updateEstimateOnCost,
    updateEstimatePriceListItemShowDialog,
    updateEstimateProfit,
    updateEstimateStatus,
    updateEstimateStatusFailure,
    updateEstimateStatusSuccess,
    updateEstimateTotals,
} from '../actions';
import { selectEstimateDetailEstimate } from '../selectors';

@Injectable({
    providedIn: 'root',
})
export class EstimateDetailEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private estimateService: EstimateService,
        private jobService: JobService,
        private confirm: FuseConfirmationService,
        private dialog: MatDialog,
        private toast: ToastService
    ) {}

    updateEstimatePriceListItemShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimatePriceListItemShowDialog),
            switchMap(({ item }) =>
                this.dialog
                    .open(EstimatePriceListItemDialogComponent, { data: item })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map((item: EstimatePriceListItem) =>
                updateEstimatePriceListItem({ item })
            )
        )
    );

    showAddEstimateSectionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showAddEstimateSectionDialog),
            switchMap(() =>
                this.dialog
                    .open(EstimateSectionDialogComponent, {
                        width: '325px',
                        panelClass: 'app-estimate-section-dialog',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ name }) => addEstimateSection({ name }))
        )
    );

    showRenameEstimateSectionDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showRenameEstimateSectionDialog),
            switchMap(({ id, name }) =>
                this.dialog
                    .open(EstimateSectionDialogComponent, {
                        width: '325px',
                        data: { id, name },
                        panelClass: 'app-estimate-section-dialog',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ id, name }) => renameEstimateSection({ id, name }))
        )
    );

    saveEstimate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveEstimate),
            concatLatestFrom(() =>
                this.store.select(selectEstimateDetailEstimate)
            ),
            switchMap(([_, estimate]) =>
                this.estimateService.save(estimate).pipe(
                    map((estimate: SingleSaveEstimate) =>
                        saveEstimateSuccess({ estimate })
                    ),
                    catchError((error) => {
                        this.toast.error('Operation failed');
                        return of(saveEstimateFailure({ error }));
                    })
                )
            )
        )
    );

    updateEstimateSectionPriceListItemsShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimateSectionPriceListItemsShowDialog),
            switchMap(({ section }) =>
                this.dialog
                    .open(AddRemovePriceListItemsDialogComponent, {
                        data: section,
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => !!result),
                        map((data) => updateEstimateSectionPriceListItems(data))
                    )
            )
        )
    );

    addRemoveEstimatePriceListItemsShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addRemoveEstimatePriceListItemsShowDialog),
            concatLatestFrom(() =>
                this.store.select(selectEstimateDetailEstimate)
            ),
            switchMap(([{}, estimate]) =>
                this.dialog
                    .open(AddRemovePriceListItemsDialogComponent, {
                        data: estimate,
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => !!result),
                        map((data) => addRemoveEstimatePriceListItems(data))
                    )
            )
        )
    );

    deleteEstimateSectionConfirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteEstimateSectionConfirm),
            switchMap(({ id }) =>
                this.confirm
                    .open({
                        title: 'Delete section',
                        message:
                            'Are you sure you want to delete this section? This action cannot be undone.',
                        icon: {
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        actions: {
                            cancel: { label: 'No' },
                            confirm: { label: 'Yes', color: 'warn' },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => result === 'confirmed'),
                        map(() => deleteEstimateSection({ id }))
                    )
            )
        )
    );

    deleteEstimatePriceListItemConfirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteEstimatePriceListItemConfirm),
            switchMap(({ id }) =>
                this.confirm
                    .open({
                        title: 'Delete item',
                        message:
                            'Are you sure you want to delete this item? This action cannot be undone.',
                        icon: {
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        actions: {
                            cancel: { label: 'No' },
                            confirm: { label: 'Yes', color: 'warn' },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => result === 'confirmed'),
                        map(() => deleteEstimatePriceListItem({ id }))
                    )
            )
        )
    );

    deleteEstimateSection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteEstimateSection),
            map(updateEstimateTotals)
        )
    );

    updateEstimateCustomerQuote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimateCustomerQuote),
            concatLatestFrom(() =>
                this.store.select(selectEstimateDetailEstimate)
            ),
            map(([{ customerQuote }, estimate]) => {
                const netQuote = customerQuote / 1.1;
                const profit = netQuote - estimate.quotableUnitTotal;
                const onCost = 100 * (profit / estimate.quotableUnitTotal);
                return updateEstimateOnCost({ onCost });
            })
        )
    );

    updateEstimateProfit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimateProfit),
            concatLatestFrom(() =>
                this.store.select(selectEstimateDetailEstimate)
            ),
            map(([{ profit }, estimate]) => {
                const nonQuotableUnitTotal = sumBy(
                    flatten(estimate.items.filter((i) => !i.quotable)),
                    (i) => i.unitTotal
                );
                const onCost =
                    100 *
                    ((profit + nonQuotableUnitTotal) /
                        estimate.quotableUnitTotal);
                return updateEstimateOnCost({ onCost });
            })
        )
    );

    updateEstimateSectionPriceListItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimateSectionPriceListItems),
            map(updateEstimateTotals)
        )
    );

    updateEstimateOnCost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimateOnCost),
            map(updateEstimateTotals)
        )
    );

    updateEstimatePriceListItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                updateEstimatePriceListItem,
                addRemoveEstimatePriceListItems
            ),
            map(updateEstimateTotals)
        )
    );

    deleteEstimatePriceListItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteEstimatePriceListItem),
            map(updateEstimateTotals)
        )
    );

    getEstimate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getEstimate),
            // distinctUntilChanged((a, b) => a.id === b.id),
            switchMap(({ id }) =>
                this.estimateService.getSingleSaveEstimate(id).pipe(
                    map((estimate: SingleSaveEstimate) =>
                        getEstimateSuccess({
                            estimate: {
                                ...estimate,
                                // sections: sortBy(estimate.sections, (s) => s.order).map(
                                //   (s, i) => {
                                //     return { ...s, order: i };
                                //   },
                                // ),
                            },
                        })
                    ),
                    catchError((error) => {
                        return of(getEstimateFailure({ error }));
                    })
                )
            )
        )
    );

    saveAsTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createTemplate),
            switchMap(({ template }) =>
                this.jobService.saveAsTemplate(template).pipe(
                    map((template: Template) =>
                        createTemplateSuccess({ template })
                    ),
                    catchError((error: any) =>
                        of(createTemplateFailure({ error }))
                    )
                )
            )
        )
    );

    updateEstimateStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEstimateStatus),
            switchMap(({ params }) =>
                this.jobService.updateEstimateStatus(params).pipe(
                    map(() => updateEstimateStatusSuccess()),
                    catchError((error) =>
                        of(updateEstimateStatusFailure({ error }))
                    )
                )
            )
        )
    );

    // updateEstimateFull$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(updateEstimateFull),
    //     tap((_) => this.spinner.show()),
    //     switchMap(({ query }) => {
    //       let observerArr = [this.estimateService.updateEstimate(query.estimate)];
    //       if (query.sections.length) {
    //         query.sections.forEach((s) =>
    //           observerArr.push(this.estimateService.updateEstimateSection(s))
    //         );
    //       }

    //       return forkJoin(observerArr).pipe(
    //         map((result) => {
    //           let res = {
    //             query: {
    //               estimate: result[0],
    //               sections: result.slice(1, result.length),
    //             },
    //           };

    //           return updateEstimateFullSuccess(res);
    //         }),
    //         catchError((error) => {
    //           return of(updateEstimateFullFailure({ error }));
    //         }),
    //         finalize(() => this.spinner.hide())
    //       );
    //     })
    //   )
    // );
}
