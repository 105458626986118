import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Photo } from 'app/shared/models';
import { FileDownloadFacade } from 'app/shared/store/facades';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';
import { SafeUrl } from '@angular/platform-browser';
import { getEditorDefaults } from '@pqina/pintura';
import { MatDialog } from '@angular/material/dialog';
import { PhotosAddNameDialog } from '../photos-add-name-dialog/photos-add-name-dialog';
import { Actions, ofType } from '@ngrx/effects';
import { fileUploadCompleted } from 'app/shared/store/actions';
import { JobPhotosActionTypes, PhotosFacade } from '../../store';
import { ActivatedRoute } from '@angular/router';
import { ActionsSubject } from '@ngrx/store';
import { PhotosPhotoInfoComponent } from '../photos-photo-info/photos-photo-info.component';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-photos-photo-detail',
  templateUrl: './photos-photo-detail.component.html',
  styleUrls: ['./photos-photo-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    AngularPinturaModule,
    PhotosPhotoInfoComponent,
    AsyncPipe,
  ],
  providers: [AsyncPipe],
})
export class PhotosPhotoDetailComponent implements OnInit, OnDestroy {
  @ViewChild('editor') editor: any;
  @Input() isReadOnlyMode = false;
  constructor(
    private jobPhotosFacade: PhotosFacade,
    private fileDownloadFacade: FileDownloadFacade,
    private async: AsyncPipe,
    private dialog: MatDialog,
    private actions$: Actions,
    private actionsSubj: ActionsSubject,
    private route: ActivatedRoute,
  ) {}
  private unsubscriber$ = new Subject<void>();

  currentIndex: number;
  photoCount: number;
  detailPhoto$ = this.jobPhotosFacade.detailPhoto$;
  photo: Photo;
  photoUrl: SafeUrl;
  // Our editor configuration
  options: any = {
    // Pass the editor default configuration options
    ...getEditorDefaults(),

    // This will set a square crop aspect ratio
    // imageCropAspectRatio: 1
  };
  jobId;

  ngOnInit(): void {
    this.actionsSubj
      .pipe(
        ofType(JobPhotosActionTypes.DeleteJobPhotosSuccess),
        takeUntil(this.unsubscriber$),
      )
      .subscribe(() => this.close());

    this.jobId = this.route.snapshot.parent.parent.params['id'];

    this.jobPhotosFacade.detailPhoto$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((photo) => {
        this.photo = photo;
        const sas = this.async.transform(this.jobPhotosFacade.sasToken$);
        this.photoUrl = this.photo?.url + '?' + sas;
      });

    this.jobPhotosFacade.detailPhotoIndex$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((index) => (this.currentIndex = index));

    this.jobPhotosFacade.currentPhotos$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((photos) => (this.photoCount = photos.length));

    this.actions$
      .pipe(takeUntil(this.unsubscriber$), ofType(fileUploadCompleted))
      .subscribe((response) => {
        if (response.type === '[File Upload] Completed') {
          this.close();
        }
      });
  }

  handleEditorProcess(imageWriterResult: any): void {
    console.log('asdasd');

    this.dialog
      .open(PhotosAddNameDialog, {
        width: '325px',
      })
      .afterClosed()
      .subscribe(({ name }) => {
        const resultImage = new File(
          [
            imageWriterResult.dest.slice(
              0,
              imageWriterResult.dest.size,
              'image/jpeg',
            ),
          ],
          name + '.jpeg',
          { type: 'image/jpeg' },
        );
        this.jobPhotosFacade.uploadPhotos(
          this.route.snapshot.parent.parent.params['id'],
          this.async.transform(this.jobPhotosFacade.currentFolder$).path,
          [resultImage],
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  close() {
    this.jobPhotosFacade.setDetailPhotoIndex(null);
  }

  delete() {
    this.jobPhotosFacade.deletePhotos(this.jobId, [this.photo.id]);
  }

  download() {
    const sas = this.async.transform(this.jobPhotosFacade.sasToken$);
    const photoUrl = this.photo.url + '?' + sas;
    this.fileDownloadFacade.downloadFile(photoUrl, this.photo.name);
  }

  canGoNext() {
    return this.currentIndex < this.photoCount - 1;
  }

  canGoPrevious() {
    return this.currentIndex > 0;
  }

  goNext() {
    if (this.canGoNext) {
      this.jobPhotosFacade.setDetailPhotoIndex(this.currentIndex + 1);
    }
  }

  goPrevious() {
    if (this.canGoPrevious) {
      this.jobPhotosFacade.setDetailPhotoIndex(this.currentIndex - 1);
    }
  }
}
