import { Inject, Injectable } from '@angular/core';
import { FUSE_CONFIG } from '@fuse/services/config/config.constants';
import { AccountUserFacade } from 'app/shared/store/facades';
import { merge } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FuseConfigService {
    private _config: BehaviorSubject<any>;

    /**
     * Constructor
     */
    constructor(
        @Inject(FUSE_CONFIG) config: any,
        private accountUserFacade: AccountUserFacade
    ) {
        // Private
        this._config = new BehaviorSubject(config);
        this.accountUserFacade.themeMode$.subscribe((x) => {
            // console.log('FuseConfigService:::', x);

            const cfg = merge({}, this._config.getValue(), { scheme: x });

            // Execute the observable
            this._config.next(cfg);

            // console.log('FuseConfigService:::', cfg);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for config
     */
    set config(value: any) {
        // Merge the new config over to the current config
        const config = merge({}, this._config.getValue(), value);

        // Execute the observable
        this._config.next(config);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get config$(): Observable<any> {
        return this._config.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resets the config to the default
     */
    reset(): void {
        // Set the config
        this._config.next(this.config);
    }
}
