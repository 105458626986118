/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Estimate, PaginatedListOfEstimate } from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  acceptEstimateFailure,
  acceptEstimateSuccess,
  cloneEstimate,
  cloneEstimateSuccess,
  getEstimates,
  getEstimatesFailure,
  getEstimatesSuccess,
} from '..';
import { EstimateService } from '../../services/estimate.service';
import {
  acceptEstimate,
  cloneEstimateFailure,
  getEstimatesHistory,
  getEstimatesHistoryFailure,
  getEstimatesHistorySuccess,
} from '../actions';
import { ToastService } from '../../../../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class EstimateListEffects {
  getEstimates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getEstimates),
      switchMap(({ request }) =>
        this.estimateService.getCurrentEstimates(request).pipe(
          map((estimates: PaginatedListOfEstimate) =>
            getEstimatesSuccess({ estimates }),
          ),
          catchError((error) => {
            return of(getEstimatesFailure({ error }));
          }),
        ),
      ),
    );
  });

  getEstimatesHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getEstimatesHistory),
      switchMap(({ request }) =>
        this.estimateService.getCurrentEstimatesHistory(request).pipe(
          map((estimates: PaginatedListOfEstimate) =>
            getEstimatesHistorySuccess({ estimates }),
          ),
          catchError((error) => {
            return of(getEstimatesHistoryFailure({ error }));
          }),
        ),
      ),
    );
  });

  cloneEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cloneEstimate),
      switchMap(({ id }) =>
        this.estimateService.cloneEstimate(id).pipe(
          map((estimate: Estimate) => cloneEstimateSuccess({ estimate })),
          catchError((error) => {
            this.toast.error(error.error.Message || 'Operation failed');
            return of(cloneEstimateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  acceptEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptEstimate),
      switchMap(({ id }) =>
        this.estimateService.acceptEstimate(id).pipe(
          map((estimate: Estimate) => acceptEstimateSuccess({ estimate })),
          catchError((error) => {
            this.toast.error(error.error?.Message || 'Operation failed');
            return of(acceptEstimateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private estimateService: EstimateService,
    private toast: ToastService,
  ) {}
}
