import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  PaginatedListOfJobHistory,
  SingleGetJobHistory,
} from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  getJobHistorySingle,
  getJobHistorySingleSuccess,
  getJobHistoryList,
  getJobHistoryListSuccess,
  getJobHistoryListFailure,
  getJobHistorySingleFailure,
} from '../actions/job-history.actions';

@Injectable({
  providedIn: 'root',
})
export class JobHistoryEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobHistorySingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobHistorySingle),
      switchMap(({ id }) =>
        this.jobService.getJobHistorySingle(id).pipe(
          map((jobHistorySingle: SingleGetJobHistory) => getJobHistorySingleSuccess({ jobHistorySingle })),
          catchError((error) => {
            return of(getJobHistorySingleFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getJobHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobHistoryList),
      switchMap(({ query }) =>
        this.jobService.getJobHistory(query).pipe(
          map((jobHistory: PaginatedListOfJobHistory) => getJobHistoryListSuccess({ jobHistory })),
          catchError((error) => {
            return of(getJobHistoryListFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
