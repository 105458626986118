import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class JobStateService {
  private _currentInvoicesListStatus = 999;
  private _currentJobListStatus = 999;
  private _currentEstimateListStatus = 999;

  public static invoiceGridAutoFillValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const unitPrice = group.controls.unitPrice;
      const qty = group.controls.qty;
      const gstAmount = group.controls.gstAmount;
      const amount = group.controls.amount;
      if (
        (unitPrice.value && qty.value && !amount && !gstAmount) ||
        (unitPrice.value && qty.value && (!unitPrice.pristine || !qty.pristine))
      ) {
        const rawAmount = unitPrice.value * qty.value; //total amount
        gstAmount.patchValue(rawAmount * 0.1, {
          emitEvent: false,
          onlySelf: true,
        });
        amount.patchValue(gstAmount.value + rawAmount, {
          emitEvent: false,
          onlySelf: true,
        });
      }
      return null;
    };
  }

  get currentJobListStatus(): number {
    return this._currentJobListStatus;
  }
  set currentJobListStatus(value: any) {
    this._currentJobListStatus = value;
  }
  get currentEstimateListStatus(): number {
    return this._currentEstimateListStatus;
  }
  set currentEstimateListStatus(value: any) {
    this._currentEstimateListStatus = value;
  }
  get currentInvoicesListStatus(): number {
    return this._currentInvoicesListStatus;
  }
  set currentInvoicesListStatus(value: any) {
    this._currentInvoicesListStatus = value;
  }
}
