import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { of } from 'rxjs';
import { QuickBooksAccountSettingsDto } from '../quickbooks/quickbooks.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-quickbooks-integration-method-customization',
  templateUrl: './quickbooks-integration-method-customization.component.html',
  styleUrls: ['./quickbooks-integration-method-customization.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class QuickBooksIntegrationMethodCustomizationComponent
  implements OnInit
{
  @Input() settings: QuickBooksAccountSettingsDto;
  @Output() change = new EventEmitter();
  // purchaseOrderStatuses$ = of([
  //   {
  //     id: 1,
  //     name: 'AUTHORISED'
  //   },
  //   {
  //     id: 2,
  //     name: 'NON-AUTHORISED'
  //   },
  // ]);
  invoiceStatuses$ = of([
    {
      id: 1,
      name: 'AUTHORISED',
    },
    {
      id: 2,
      name: 'NON-AUTHORISED',
    },
  ]);
  paymentStatuses$ = of([
    {
      id: 10,
      name: 'Synced one way',
    },
    {
      id: 20,
      name: 'Synced both way',
    },
  ]);
  // salesAccountList = of([
  //   {
  //     id: 1,
  //     name: '100 - Invoice'
  //   },
  //   {
  //     id: 2,
  //     name: '200 - Sales'
  //   },
  // ]);
  // bankAccountList = of([
  //   {
  //     id: 1,
  //     name: '010 - Customer Account'
  //   },
  //   {
  //     id: 2,
  //     name: '090 - Bank Account'
  //   },
  // ]);

  form = this._formBuilder.group({
    // export: [true],
    // purchaseOrderStatus: [1],
    paymentSychronizationType: [10],
    invoiceStatus: [2],
    gstAccountName: [''],
    defaultSaleItemIncomeAccountName: [''],
  });
  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    console.log(this.settings);
    this.form.patchValue(this.settings);
  }

  cancel() {
    console.log('cancel');
  }
  save() {
    console.log('save');
    this.change.emit(this.form.value);
  }
}
