/* eslint-disable ngrx/no-typed-global-store */
/* eslint-disable arrow-parens */
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import * as fromStoreSelectors from '../store/selectors/account-user.selectors';
import * as fromStoreActions from '../store/actions/account-user.actions';
import * as fromStoreReducers from '../store/reducers/account-user.reducer';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AccountUserGuard {
  constructor(
    private readonly store: Store<fromStoreReducers.AccountUserState>,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap((_) =>
        this.checkStore().pipe(
          switchMap(() => of(true)),
          catchError(() => of(false)),
        ),
      ),
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStoreSelectors.selectIsInitialised).pipe(
      tap((initialised) => {
        if (!initialised) {
          this.store.dispatch(fromStoreActions.loginAccountUser());
        }
      }),
      filter((initialised) => initialised),
      take(1),
    );
  }
}

export const AccountUserGuardG: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(AccountUserGuard).canActivate(next, state);
};
