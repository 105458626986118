import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AddPriceListCategory,
  AddPriceListItem,
  BulkSoftDeleteTransactionResponse,
  EstimatePriceListUnitPriceUpdate,
  FullPriceList,
  PriceListCategory,
  PriceListItem,
  PriceListItemPriceChangeCheck,
  PriceListItemPriceChangeJob,
} from 'app/shared/models';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PriceListService {
  constructor(private http: HttpClient) {}

  deletePriceListItems(
    ids: string[],
  ): Observable<BulkSoftDeleteTransactionResponse> {
    const url = `${environment.urls.api}/pricelistitem/bulk/hard`;
    return this.http.delete<BulkSoftDeleteTransactionResponse>(url, {
      body: ids,
    });
  }

  updateUnitPrice(params: EstimatePriceListUnitPriceUpdate) {
    const url = `${environment.urls.api}/estimatepricelistitem/updateunitprice`;
    return this.http.post(url, params);
  }

  getItemPriceCheck(params: PriceListItemPriceChangeCheck) {
    const url = `${environment.urls.api}/job/itempricecheck`;
    return this.http.post<PriceListItemPriceChangeJob>(url, params);
  }

  getCurrentPriceList(): Observable<FullPriceList> {
    const url = `${environment.urls.api}/pricelist/full`;
    return this.http.get<FullPriceList>(url);
  }

  addPriceListCategory(
    payload: AddPriceListCategory,
  ): Observable<PriceListCategory> {
    const url = `${environment.urls.api}/pricelistcategory`;

    return this.http.post<PriceListCategory>(url, payload);
  }

  editPriceListCategory(
    payload: PriceListCategory,
  ): Observable<PriceListCategory> {
    const url = `${environment.urls.api}/pricelistcategory`;

    return this.http.put<PriceListCategory>(url, payload);
  }
  reorderPriceListCategory(
    payload: PriceListCategory[],
  ): Observable<PriceListCategory[]> {
    const url = `${environment.urls.api}/pricelistcategory/order`;
    return this.http.put<PriceListCategory[]>(url, payload);
  }

  deletePriceListCategory(
    payload: PriceListCategory,
  ): Observable<PriceListCategory> {
    const url = `${environment.urls.api}/pricelistcategory/${payload.id}`;

    return this.http.delete<PriceListCategory>(url);
  }

  addPriceListItem(payload: AddPriceListItem): Observable<PriceListItem> {
    const url = `${environment.urls.api}/pricelistitem`;

    return this.http.post<PriceListItem>(url, payload);
  }

  editPriceListItem(payload: PriceListItem): Observable<PriceListItem> {
    const url = `${environment.urls.api}/pricelistitem`;

    return this.http.put<PriceListItem>(url, payload);
  }

  deletePriceListItem(id: string): Observable<void> {
    const url = `${environment.urls.api}/pricelistitem/${id}`;

    return this.http.delete<void>(url);
  }
}
