import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { JobNote } from 'app/shared/models';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotesEditorAction } from '../../enums/editor-action.enum';
import { DateTime } from 'luxon';
import { QuillEditorComponent } from 'ngx-quill';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DatePipe } from '@angular/common';
import { QuillEditorElementComponent } from '../quill-editor-element/quill-editor-element.component';

@Component({
  selector: 'app-job-notes-editor',
  templateUrl: './job-notes-editor.component.html',
  styleUrls: ['./job-notes-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    QuillEditorComponent,
    DatePipe,
    QuillEditorElementComponent
  ],
})
export class JobNotesEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() note: JobNote;
  @Input() isReadOnlyMode: boolean;
  @Input() actionResult$: EventEmitter<any>;
  @Output() action = new EventEmitter<{
    action: NotesEditorAction;
    id?: string;
    payload?: any;
  }>();
  isEditing = false;

  unsubscribe$ = new Subject<void>();

  notesForm: FormGroup;
  get content() {
    return this.notesForm.get('content');
  }

  constructor(
    private fb: FormBuilder,
    private confirm: FuseConfirmationService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.notesForm = this.fb.group({
      content: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.notesForm.patchValue({ content: this.note.content });
    this.actionResult$
      .pipe(filter((r: any) => r.id === this.note.id))
      .subscribe((r) => {
        this.note = r;
        this.notesForm.markAsPristine();
        this.isEditing = false;
        this.cdRef.detectChanges();
      });
  }
  onContentChanged(): void {}

  save(): void {
    this.action.emit({
      action: NotesEditorAction.create,
      payload: {
        ...this.note,
        content: this.notesForm.value.content,
        updated: DateTime.now().setLocale('en-AU'),
        updatedBy: this.note.accountId,
      },
    });
  }

  onDelete(): void {
    this.confirm
      .open({
        title: 'Delete note',
        message:
          'Are you sure you want to delete this note? This action cannot be undone.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Delete', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result) => result === 'confirmed'))
      .subscribe(() => {
        this.action.emit({
          action: NotesEditorAction.delete,
          id: this.note.id,
        });
      });
  }

  cancel(): void {
    this.notesForm.markAsPristine();
    this.notesForm.patchValue({ content: this.note.content });
    this.isEditing = false;
  }

  onEdit(): void {
    this.isEditing = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
