import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import {
  billingSettingsReducer,
  BillingSettingsState,
} from './billing-settings.reducer';

import {
  teamSettingsReducer,
  TeamSettingsState,
} from './team-settings.reducer';

import {
  generalSettingsReducer,
  GeneralSettingsState,
} from './general-settings.reducer';

export interface SettingsState {
  billingSettings: BillingSettingsState;
  teamSettings: TeamSettingsState;
  generalSettings: GeneralSettingsState;
}

export const reducers: ActionReducerMap<SettingsState> = {
  billingSettings: billingSettingsReducer,
  teamSettings: teamSettingsReducer,
  generalSettings: generalSettingsReducer,
};

export const selectSettingsState =
  createFeatureSelector<SettingsState>('settings');
