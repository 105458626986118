import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { jobFilesReducer, JobFilesState } from './job-files.reducer';

export interface JobState {
  files: JobFilesState;
}

export const reducers: ActionReducerMap<JobState> = {
  files: jobFilesReducer,
};

export const selectJobState = createFeatureSelector<JobState>('files');
