/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AddJobOrder,
  BulkSoftDeleteResponse,
  Estimate,
  EstimateParameters,
  Job,
  JobFile,
  JobHistoryParameters,
  JobInvoice,
  JobInvoiceByCustomerParameters,
  JobInvoiceParameters,
  JobInvoicePayment,
  JobInvoiceTotals,
  JobNote,
  JobNoteParameters,
  JobOrder,
  JobOrderParameters,
  JobParameters,
  JobPhoto,
  JobType,
  PaginatedListOfEstimate,
  PaginatedListOfJob,
  PaginatedListOfJobInvoice,
  PaginatedListOfJobInvoicePayment,
  PaginatedListOfJobOrderListItem,
  PaginatedListOfTemplate,
  SendCallForwardCallFor,
  SendJobInvoice,
  SendJobOrder,
  SendJobOrderCallFor,
  SendQuote,
  SingleSaveJobCosting,
  Template,
  TemplateParameters,
  VariationParameters,
} from 'app/shared/models';
import { EstimateStatusParams } from 'app/views/estimates/models/estimate-statuses';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  JobOrderSendStatusParams,
  JobOrderStatusParams,
} from '../models/job-orders';
import { JobStatusParams } from '../models/job-statuses';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private http: HttpClient) { }

  getAcceptedEstimate(jobId: string) {
    const url = `${environment.urls.api}/estimate/job/${jobId}`;
    return this.http.get(url);
  }

  getOriginalitems(jobId: string) {
    const url = `${environment.urls.api}/variation/orginalitems/${jobId}`;
    return this.http.get(url);
  }

  getWorkitems(jobId: string) {
    const url = `${environment.urls.api}/jobCosting/workingitems/filtered/${jobId}`;
    return this.http.get(url);
  }

  getJobCosting(jobId: string) {
    const url = `${environment.urls.api}/jobCosting/job/${jobId}`;
    return this.http.get(url);
  }
  getJobCostingSingle(jobCostingId: string) {
    const url = `${environment.urls.api}/jobCosting/${jobCostingId}/single`;
    return this.http.get(url);
  }
  getJobHistorySingle(jobCostingId: string) {
    const url = `${environment.urls.api}/jobHistory/${jobCostingId}/single`;
    return this.http.get(url);
  }
  saveJobCostingSingle(
    jobCosting: SingleSaveJobCosting,
  ): Observable<SingleSaveJobCosting> {
    const url = `${environment.urls.api}/jobCosting/single`;
    return this.http.put<SingleSaveJobCosting>(url, jobCosting);
  }

  updateJobCostingStatus(param: { id: string; type: string }): Observable<any> {
    const url = `${environment.urls.api}/jobCosting/${param.id}/status/${param.type}`;
    return this.http.post<any>(url, null);
  }

  uploadJobCostingQuoteLogo(id: string, file: File): Observable<any> {
    const url = `${environment.urls.api}/jobCosting/${id}/logo`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', url, formData);

    return this.http.post<any>(url, formData);
  }

  getJobVariation(query: VariationParameters) {
    const url = `${environment.urls.api}/variation/list`;
    return this.http.post(url, query);
  }
  getJobHistory(query: JobHistoryParameters) {
    const url = `${environment.urls.api}/jobhistory/list`;
    return this.http.post(url, query);
  }

  jobOrderBuildJob(jobId: string) {
    const url = `${environment.urls.api}/joborder/build/job/${jobId}`;
    return this.http.get(url);
  }

  jobOrderBuildJobEmail(jobId: string) {
    const url = `${environment.urls.api}/joborder/build/job/${jobId}/email`;
    return this.http.get(url);
  }

  jobOrderBuildJobSupplier(jobId: string, supplierId: string) {
    const url = `${environment.urls.api}/joborder/build/job/${jobId}/supplier/${supplierId}`;
    return this.http.get(url);
  }

  jobOrderBuildJobBySupplierAndCategory(
    jobId: string,
    supplierId: string,
    categoryId: string,
  ) {
    const url = `${environment.urls.api}/joborder/build/job/${jobId}/supplier/${supplierId}/category/${categoryId}`;
    return this.http.get(url);
  }

  jobOrderBuildJobSupplierEmail(jobId: string, supplierId: string) {
    const url = `${environment.urls.api}/joborder/build/job/${jobId}/supplier/${supplierId}/email`;
    return this.http.get(url);
  }

  joboOrderItemsToOrder(query: { jobId: string; supplierId: string }) {
    const url = `${environment.urls.api}/joborder/itemstoorder/job/${query.jobId}/supplier/${query.supplierId}`;
    return this.http.get(url);
  }

  joborderListSuppliersItemsToOrderJob(id: string) {
    const url = `${environment.urls.api}/joborder/list/suppliers/itemstoorder/job/${id}`;
    return this.http.get(url);
  }

  joborderListSuppliersJob(id: string) {
    const url = `${environment.urls.api}/joborder/list/suppliers/job/${id}`;
    return this.http.get(url);
  }

  getUseCategories(id: string) {
    const url = `${environment.urls.api}/job/usecategories/${id}`;
    return this.http.get(url);
  }
  updateUseCategories(id: string, canEdit: boolean) {
    const url = `${environment.urls.api}/job/usecategories/${id}/${canEdit}`;
    return this.http.put(url, {});
  }

  getJobOrderPriceListItemSuppliers(query: any) {
    const url = `${environment.urls.api}/joborderpricelistitem/list/suppliers`;
    return this.http.post<any>(url, query);
  }

  getUniqueSuppliersListInPriceList() {
    const url = `${environment.urls.api}/pricelistitem/list/suppliers`;
    return this.http.get<any>(url);
  }

  getCategoriesBySupplierId(supplierId: string) {
    const url = `${environment.urls.api}/priceListItem/list/categories/${supplierId}`;
    return this.http.get<any>(url);
  }

  getJobOrderPriceListItem(query: any) {
    const url = `${environment.urls.api}/joborderpricelistitem/list`;
    return this.http.post<any>(url, query);
  }

  addJobOrderPriceListItem(query: any) {
    const url = `${environment.urls.api}/joborderpricelistitem/add`;
    return this.http.post<any>(url, query);
  }
  deleteJobOrderPriceListItem(id: string) {
    const url = `${environment.urls.api}/joborderpricelistitem/${id}`;
    return this.http.delete<any>(url);
  }
  updateJobOrderPriceListItem(query: any) {
    const url = `${environment.urls.api}/joborderpricelistitem/save`;
    return this.http.put<any>(url, query);
  }
  jobOrderSendStatus(params: JobOrderSendStatusParams) {
    const url = `${environment.urls.api}/joborder/${params.type}/${params.id}`;
    return this.http.put<JobOrder>(url, null);
  }

  syncJobInvoice(id: string) {
    const url = `${environment.urls.api}/jobinvoice/sync/${id}`;
    return this.http.post(url, null);
  }

  syncJobInvoiceBulk(ids: string[]) {
    const url = `${environment.urls.api}/jobinvoice/bulksync`;
    return this.http.post(url, { ids: ids });
  }

  syncJobOrderBulk(ids: string[]) {
    const url = `${environment.urls.api}/joborder/bulksync`;
    return this.http.post(url, { ids: ids });
  }

  getSyncJobInvoiceStatus(id: string) {
    const url = `${environment.urls.api}/jobinvoice/sync/${id}`;
    return this.http.get(url, null);
  }

  syncJobOrder(id: string) {
    const url = `${environment.urls.api}/joborder/sync/${id}`;
    return this.http.post(url, null);
  }

  getSyncJobOrderStatus(id: string) {
    const url = `${environment.urls.api}/joborder/sync/${id}`;
    return this.http.get(url, null);
  }
  jobOrderStatus(params: JobOrderStatusParams) {
    const url = `${environment.urls.api}/joborder/status/${params.type}/${params.id}`;
    return this.http.put<JobOrder>(url, null);
  }
  jobOrderSendemail(request: SendJobOrder) {
    const url = `${environment.urls.api}/joborder/sendemail`;
    return this.http.post<JobOrder>(url, request);
  }
  sendCallForCallForwardItem(request: SendCallForwardCallFor) {
    const url = `${environment.urls.api}/joborder/callfor/callforwarditem`;
    return this.http.post<SendCallForwardCallFor>(url, request);
  }
  sendJobOrderCallFor(request: SendJobOrderCallFor) {
    const url = `${environment.urls.api}/joborder/callfor/joborder`;
    return this.http.post<SendJobOrderCallFor>(url, request);
  }
  addJobOrders(item: AddJobOrder) {
    const url = `${environment.urls.api}/joborder/add`;
    return this.http.post<JobOrder>(url, item);
  }

  fileFolderExists(jobId: string, path: string): Observable<boolean> {
    const url = `${environment.urls.api}/jobfile/folderexists`;
    return this.http
      .post(url, { jobId, path })
      .pipe(map((response: any) => response.exists));
  }

  deleteJobFiles(ids: string[]): Observable<BulkSoftDeleteResponse> {
    const url = `${environment.urls.api}/jobfile/bulk/delete`;
    return this.http.delete<BulkSoftDeleteResponse>(url, { body: ids });
  }

  getJobFiles(jobId: string): Observable<JobFile[]> {
    const url = `${environment.urls.api}/jobfile/list`;
    const files = this.http
      .post<PaginatedListOfJob>(url, { jobId, pageSize: 65536 })
      .pipe(map((data: PaginatedListOfJob) => data.items));
    return files;
  }

  addJobNote(note: JobNote): Observable<JobNote> {
    const url = `${environment.urls.api}/jobnote/add`;
    return this.http.post<JobNote>(url, note);
  }

  updateJobNote(note: JobNote): Observable<JobNote> {
    const url = `${environment.urls.api}/jobnote/save`;
    return this.http.put<JobNote>(url, note);
  }

  deleteJobNote(id: string): Observable<string> {
    const url = `${environment.urls.api}/jobnote/${id}`;
    return this.http.delete<string>(url);
  }

  getJobNotes(request: JobNoteParameters): Observable<PaginatedListOfJob> {
    const url = `${environment.urls.api}/jobnote/list`;
    return this.http.post<PaginatedListOfJob>(url, request);
  }

  getJobs(request: JobParameters): Observable<PaginatedListOfJob> {
    const baseUrl = `${environment.urls.api}/job/list`;
    const url = request.jobType === 10 ? baseUrl + '/estimate' : baseUrl;
    return this.http.post<PaginatedListOfJob>(url, request);
  }

  resetJob(jobId: string): Observable<PaginatedListOfJob> {
    const url = `${environment.urls.api}/job/${jobId}/reset`;
    return this.http.post<PaginatedListOfJob>(url, null);
  }

  canResetJob(jobId: string): Observable<any> {
    const url = `${environment.urls.api}/job/${jobId}/canreset`;
    return this.http.post<PaginatedListOfJob>(url, null);
  }

  getEstimates(
    request: EstimateParameters,
  ): Observable<PaginatedListOfEstimate> {
    const url = `${environment.urls.api}/estimate/list`;
    return this.http.post<PaginatedListOfEstimate>(url, request);
  }

  updateEstimateStatus(param: EstimateStatusParams): Observable<any> {
    const url = `${environment.urls.api}/estimate/${param.id}/status/${param.type}`;
    return this.http.post<any>(url, null);
  }

  getStatuses(jobType: number): Observable<any[]> {
    const url = `${environment.urls.api}/job/list/status/${jobType === JobType.Job ? 'job' : 'estimate'
      }`;
    return this.http.get<Job[]>(url);
  }

  previewEstimateQuote(id: string): Observable<any> {
    const url = `${environment.urls.api}/estimate/export/pdf/${id}`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map((response: any) => {
        return response.body;
      }),
    );
  }

  previewJobCostingQuote(id: string): Observable<any> {
    const url = `${environment.urls.api}/jobCosting/export/pdf/${id}`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map((response: any) => {
        return response.body;
      }),
    );
  }

  sendJobCostingQuote(sendQuote: SendQuote): Observable<any> {
    const url = `${environment.urls.api}/jobCosting/sendemail`;
    return this.http.post<any>(url, sendQuote);
  }

  previewJobQuote(id: string): Observable<any> {
    const url = `${environment.urls.api}/estimate/export/pdf/job/${id}`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map((response: any) => {
        return response.body;
      }),
    );
  }

  sendQuoteJob(sendQuote: SendQuote): Observable<Estimate> {
    const url = `${environment.urls.api}/estimate/sendemail/job`;
    return this.http.post<Estimate>(url, sendQuote);
  }

  getRecentJobs(): Observable<Job[]> {
    const url = `${environment.urls.api}/job/recent/job`;
    return this.http.get<Job[]>(url);
  }

  getRecentEstimates(): Observable<Job[]> {
    const url = `${environment.urls.api}/job/recent/estimate`;
    return this.http.get<Job[]>(url);
  }

  saveAsTemplate(dto: any): Observable<Template> {
    const url = `${environment.urls.api}/job/saveas/template`;
    return this.http.put<any>(url, dto);
  }

  getTemplates(
    request: TemplateParameters,
  ): Observable<PaginatedListOfTemplate> {
    const url = `${environment.urls.api}/template/list`;
    return this.http.post<any>(url, request);
  }

  getJobOrdersList(request: JobOrderParameters) {
    const url = `${environment.urls.api}/joborder/list`;
    return this.http.post<PaginatedListOfJobOrderListItem>(url, request);
  }
  getJobOrderSummaryList(id: string) {
    const url = `${environment.urls.api}/joborder/summary/${id}`;
    return this.http.get<any>(url);
  }

  deleteJobOrders(id: string) {
    const url = `${environment.urls.api}/joborder/${id}`;
    return this.http.delete<JobOrder>(url);
  }

  getJobOrders(id: any) {
    const url = `${environment.urls.api}/joborder/${id}`;
    return this.http.get<any>(url);
  }

  updateJobOrders(item: any) {
    const url = `${environment.urls.api}/joborder/save`;
    return this.http.put<any>(url, item);
  }

  getJob(id: string): Observable<Job> {
    const url = `${environment.urls.api}/job/${id}`;
    return this.http.get<Job>(url);
  }

  deleteJob(id: string): Observable<Job> {
    const url = `${environment.urls.api}/job/${id}`;
    return this.http.delete<Job>(url);
  }

  addJob(param): Observable<Job> {
    const url = `${environment.urls.api}/job/add`;
    return this.http.post<Job>(url, param);
  }

  updateJob(param): Observable<Job> {
    const url = `${environment.urls.api}/job/save`;
    return this.http.put<Job>(url, param);
  }

  updateJobStatus(param: JobStatusParams): Observable<Job> {
    const url = `${environment.urls.api}/job/${param.id}/status/${param.type}`;
    return this.http.post<Job>(url, null);
  }

  createJobFile(file: JobFile): Observable<JobFile> {
    const url = `${environment.urls.api}/jobfile/add`;
    return this.http.post<JobFile>(url, file);
  }

  renameJobFile(file: JobFile): Observable<JobFile> {
    const url = `${environment.urls.api}/jobfile/renamefile`;
    return this.http.post<JobFile>(url, file);
  }

  renameJobFilesFolder(
    jobId: string,
    oldPath: string,
    newPath: string,
  ): Observable<JobFile[]> {
    const url = `${environment.urls.api}/jobfile/renamefolder`;
    return this.http.post<JobFile[]>(url, { jobId, oldPath, newPath });
  }

  getJobInvoices(
    request: JobInvoiceParameters,
  ): Observable<PaginatedListOfJobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/list`;
    return this.http.post<PaginatedListOfJobInvoice>(url, request);
  }

  getCustomerJobInvoices(id: string): Observable<PaginatedListOfJobInvoice> {
    const request: JobInvoiceByCustomerParameters = {
      customerId: id,
    };
    const url = `${environment.urls.api}/jobinvoice/list/customer`;
    return this.http.post<PaginatedListOfJobInvoice>(url, request);
  }

  saveJobInvoice(request: JobInvoice): Observable<JobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/save`;
    return this.http.put<JobInvoice>(url, request);
  }

  addJobInvoice(request: JobInvoice): Observable<JobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/add`;
    return this.http.post<JobInvoice>(url, request);
  }

  getJobInvoiceById(id: string): Observable<JobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/${id}`;
    return this.http.get(url);
  }

  deleteJobInvoice(id: string): Observable<JobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/${id}`;
    return this.http.delete(url);
  }

  changeJobInvoiceStatus(action: string, id: string): Observable<JobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/status/${action}/${id}`;
    return this.http.put(url, {});
  }

  sendJobInvoice(action: string, id: string): Observable<JobInvoice> {
    const url = `${environment.urls.api}/jobInvoice/${action}/${id}`;
    return this.http.put(url, {});
  }

  getJobinvoicepaymentById(id: string) {
    const url = `${environment.urls.api}/jobinvoicepayment/${id}`;
    return this.http.get(url);
  }

  getJobInvoicepayments(params): Observable<PaginatedListOfJobInvoicePayment> {
    const url = `${environment.urls.api}/jobinvoicepayment/list`;
    return this.http.post<any>(url, params);
  }

  updateInvoicePayment(params): Observable<JobInvoicePayment> {
    const url = `${environment.urls.api}/jobinvoicepayment/save`;
    return this.http.put(url, params);
  }

  previewJobInvoice(id: string): Observable<any> {
    const url = `${environment.urls.api}/jobinvoice/export/pdf/${id}`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map((response: any) => {
        return response.body;
      }),
    );
  }

  previewJobOrder(id: string): Observable<any> {
    const url = `${environment.urls.api}/joborder/export/pdf/${id}`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map((response: any) => {
        return response.body;
      }),
    );
  }

  sendEmailJobInvoice(payload: SendJobInvoice): Observable<any> {
    const url = `${environment.urls.api}/jobinvoice/sendemail`;
    return this.http.post<any>(url, payload);
  }

  totalJobInvoice(id: string): Observable<JobInvoiceTotals> {
    const url = `${environment.urls.api}/jobinvoice/total/${id}`;
    return this.http.get<JobInvoiceTotals>(url);
  }

  downloadMultipleJobFiles(photos: JobFile[]): Observable<HttpResponse<Blob>> {
    const url = `${environment.urls.api}/jobfile/bulk/download`;
    return this.http
      .post(url, photos, {
        observe: 'response',
        responseType: 'blob' as 'blob',
      })
      .pipe(map((response: any) => response.body));
  }
}
