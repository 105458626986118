import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromActions from '../actions/supplier-details.actions';
import * as fromReducer from '../reducers/supplier-details.reducer';
import * as fromSelectors from '../selectors/supplier-details.selectors';

@Injectable({
  providedIn: 'root',
})
export class SupplierFacade {
  supplier$ = this.store.pipe(select(fromSelectors.getSupplier));
  error$ = this.store.select(fromSelectors.getSupplierError);
  isLoading$ = this.store.select(fromSelectors.getSupplierIsLoading);

  constructor(private store: Store<fromReducer.SupplierState>) {}

  loadSupplier(query) {
    this.store.dispatch(fromActions.getSupplier({ query }));
  }

  editSupplier(query) {
    this.store.dispatch(fromActions.editSupplier({ query }));
  }

  deleteSupplier(query) {
    this.store.dispatch(fromActions.deleteSupplier({ query }));
  }
}
