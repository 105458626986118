import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  StripeElements,
  StripeElementsOptions,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { StripeServiceInterface, injectStripe, StripeElementsDirective, StripePaymentElementComponent } from 'ngx-stripe';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStoreActions from '../../../../shared/store/actions/account-user.actions';
import * as fromStoreReducers from '../../../../shared/store/reducers/account-user.reducer';
import { AccountUserFacade } from 'app/shared/store/facades';
import { environment } from '@env/environment';

import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { BillingService } from '@app/core/billing/billing.service';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
    (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (
            retryAttempt > maxRetryAttempts ||
            excludedStatusCodes.find((e) => e === error.status)
          ) {
            return throwError(error);
          }
          console.log(
            `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration
            }ms`,
          );
          // retry after 1s, 2s, etc...
          return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('We are done!')),
      );
    };

export interface PaymentDialogData {
  elementsOptions: StripeElementsOptions;
  createSubscriptionResponse: {
    planId,
    customerId,
    subscriptionId,
    priceId,
    clientSecret
  };
}

@Component({
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, FormsModule, MatButtonModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    NgIf
  ],
})
export class PaymentDialogComponent implements OnInit {
  @ViewChild(StripeElementsDirective) elements!: StripeElementsDirective;
  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  paymentSuccessful = false;
  paymentMessage = '';
  requiresPayment = true;
  requiresAction = false;

  elementsOptions: StripeElementsOptions;

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },

  };

  stripe: StripeServiceInterface;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentDialogData,
    private dialogRef: MatDialogRef<PaymentDialogComponent>,
    public dialog: MatDialog,
    private confirmationService: FuseConfirmationService,
    private readonly store: Store<fromStoreReducers.AccountUserState>,
    private accountUserFacade: AccountUserFacade,
    private billingService: BillingService
  ) {
    this.stripe = injectStripe(environment.stripe);
  }

  ngOnInit(): void {
    this.elementsOptions = this.data.elementsOptions;
  }

  fetchUpdates() {
    this.elements.fetchUpdates();
  }

  update(options: Partial<StripePaymentElementOptions>) {
    this.paymentElement.update(options);
  }

  pay(): void {
    this.stripe
      .confirmPayment({
        elements: this.paymentElement.elements,
        redirect: 'if_required',
      })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((x) => {
        if (x.error) {
          //show the payment error message
          this.paymentMessage = x.error.message;
        } else {
          if (x.paymentIntent.status === 'succeeded') {
            //reset our message
            this.paymentMessage = '';
            //close the payment dialog
            this.dialogRef.close();

            this.billingService.createAccountPlanPaid(this.data.createSubscriptionResponse)
              .subscribe(result => {

                const paymentMessageDialog = this.confirmationService.open({
                  title: 'Payment successful',
                  message: 'Thank you for subscribing',
                  icon: {
                    name: 'heroicons_outline:check-circle',
                    color: 'success',
                  },
                  actions: {
                    confirm: {
                      label: 'Ok',
                      color: 'primary',
                    },
                    cancel: {
                      show: false,
                    },
                  },
                  dismissible: true,
                });

                paymentMessageDialog.afterClosed().subscribe(() => {
                  this.store.dispatch(fromStoreActions.loginAccountUser());
                });

              });
          }
        }
      }, (err) => {
        console.log(err);
      });

    this.accountUserFacade.loggedInUser$.subscribe((data) => {
      if (data) {
      }
    });
  }
}
