import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobHistoryState = createSelector(
  selectJobState,
  (s: JobState) => s?.jobHistory,
);

export const selectJobHistoryIsLoading = createSelector(
  selectJobHistoryState,
  (s) => s?.callState === LoadingState.LOADING,
);

export const selectJobHistoryIsLoaded = createSelector(
  selectJobHistoryState,
  (s) => s?.callState === LoadingState.LOADED,
);

export const selectJobHistoryError = createSelector(selectJobHistoryState, (s) =>
  getError(s?.callState),
);

export const selectJobHistory = createSelector(
  selectJobHistoryState,
  (s) => s?.jobHistory,
);
export const selectJobHistorySingle = createSelector(
  selectJobHistoryState,
  (s) => s?.jobHistorySingle,
);
