<div class="px-6 w-full h-full">
  <div class="rounded-xl overflow-hidden w-full h-full">
    <ngx-extended-pdf-viewer
      *ngIf="pdfData?.generatedFile"
      [filenameForDownload]="pdfData?.fileName"
      [base64Src]="pdfData?.generatedFile"
      useBrowserLocale="true"
      [zoom]="100"
      height="100%"
      [customToolbar]="customToolbar"
    ></ngx-extended-pdf-viewer>
  </div>
</div>
<ng-template #customToolbar>
  <div
    class="border-b pt-9 px-8 pb-3 flex justify-between flex-row items-center"
  >
    <div class="flex flex-row items-center">
      <button mat-icon-button id="zoomIn">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button mat-icon-button id="zoomOut">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
    <div class="flex flex-row items-center">
      <button
        (click)="sendModal()"
        class="text-xs rounded-xl min-h-8 h-8 px-4 mr-2"
        mat-stroked-button
      >
        <div class="w-full flex flex-row items-center text-secondary">
          <mat-icon
            class="mr-2 text-hint"
            [svgIcon]="'heroicons_solid:paper-airplane'"
          ></mat-icon>
          Send
        </div>
      </button>
      <button
        class="text-xs rounded-xl min-h-8 h-8 px-4"
        mat-stroked-button
        id="print"
      >
        <div class="w-full flex flex-row items-center text-secondary">
          <mat-icon
            class="mr-2 text-hint"
            [svgIcon]="'heroicons_solid:printer'"
          ></mat-icon>
          Print
        </div>
      </button>
    </div>
  </div>
</ng-template>
