import { createReducer, on } from '@ngrx/store';
import {
  IntIdName,
  SettingsTeamMemberMergedRoles,
  TeamMember,
  TeamMemberType,
  UserRole,
} from 'app/shared/models/eazimate.models';
import { AccountTeamMember } from 'app/shared/models/form-ui.models';
import * as fromActions from '../actions/team-settings.actions';

export interface TeamSettingsCommon {
  accessLevels: IntIdName[];
  features: UserRole[];
  teamMemberTypes: TeamMemberType[];
  availableSeats: number;
}

export interface TeamSettingsState {
  common: TeamSettingsCommon;
  team: TeamMember[];
  selectedTeamMember: {
    member: SettingsTeamMemberMergedRoles;
    roles: UserRole[];
  };
  isInitialised: boolean;
  isLoading: boolean;
  error: any;
}

export const initialState: TeamSettingsState = {
  common: {
    accessLevels: [],
    features: [],
    teamMemberTypes: [],
    availableSeats: 0
  },
  selectedTeamMember: undefined,
  team: undefined,
  isInitialised: false,
  isLoading: false,
  error: null,
};

export const teamSettingsReducer = createReducer<TeamSettingsState>(
  initialState,
  on(
    fromActions.getSelectedTeamMemberMergedRoles,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: true,
      selectedTeamMember: {
        member: payload,
        roles: undefined,
      },
    }),
  ),
  on(
    fromActions.getSelectedTeamMemberMergedRolesSuccess,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: false,
      selectedTeamMember: {
        ...state.selectedTeamMember,
        roles: payload,
      },
    }),
  ),
  on(
    fromActions.getSelectedTeamMemberMergedRolesFailure,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: false,
      selectedTeamMember: undefined,
      error: payload,
    }),
  ),
  on(
    fromActions.updateTeamMemberRoles,
    (state): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.updateTeamMemberAccessLevel,
    (state): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.updateTeamMemberAccessLevelSuccess,
    fromActions.updateTeamMemberRolesSuccess,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: false,
      team: payload,
    }),
  ),
  on(
    fromActions.updateTeamMemberAccessLevelFailure,
    fromActions.updateTeamMemberRolesFailure,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  ),
  on(
    fromActions.getCommonTeamMemberData,
    (state): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.getCommonTeamMemberDataSuccess,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: false,
      common: {
        ...state.common,
        ...payload,
        availableSeats: payload.availableSeats
      },
    }),
  ),
  on(
    fromActions.getCommonTeamMemberDataFailure,
    (state, { payload }): TeamSettingsState => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  ),
  on(
    fromActions.addExtraSeat,
    (state): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.addExtraSeatSuccess,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      common: {
        ...state.common,
        availableSeats: action.payload
      }
    }),
  ),
  on(
    fromActions.addExtraSeatFailure,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: false,
      error: action.payload,
    }),
  ),
  on(
    fromActions.getAvailableSeats,
    (state): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.getAvailableSeatsSuccess,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      common: {
        ...state.common,
        availableSeats: action.payload
      }
    }),
  ),
  on(
    fromActions.getAvailableSeatsFailure,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: false,
      error: action.payload,
    }),
  ),
  on(
    fromActions.getTeam,
    (state): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.getTeamSuccess,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      team: action.payload,
    }),
  ),
  on(
    fromActions.getTeamFailure,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: false,
      error: action.payload,
    }),
  ),
  on(
    fromActions.inviteTeamMember,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.inviteTeamMemberSuccess,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      team: action.payload,
      common: {
        ...state.common,
        availableSeats: action.availableSeats
      }
    }),
  ),
  on(
    fromActions.inviteTeamMemberFailure,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: false,
      error: action.payload,
    }),
  ),
  on(
    fromActions.removeTeamMemberSuccess,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      team: action.payload,
      common: {
        ...state.common,
        availableSeats: action.availableSeats
      }
    }),
  ),
  on(
    fromActions.removeTeamMemberFailure,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      isInitialised: false,
      error: action.payload,
    }),
  ),
  on(
    fromActions.updateTeamMemberRoleSuccess,
    (state, action): TeamSettingsState => ({
      ...state,
      isLoading: false,
      team: action.payload,
    }),
  ),
);

export const getAvailableSeats = (state: TeamSettingsState): number =>
  state?.common?.availableSeats;
export const getIsInitialised = (state: TeamSettingsState): boolean =>
  state.isInitialised;
export const getIsLoading = (state: TeamSettingsState): boolean =>
  state.isLoading;
export const getError = (state: TeamSettingsState): any => state.error;
export const getTeam = (state: TeamSettingsState): TeamMember[] => state.team;
export const getCommon = (state: TeamSettingsState): TeamSettingsCommon =>
  state.common;
