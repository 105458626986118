import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import * as fromReducer from '../reducers/supplier-price-list.reducer';
import * as fromFeature from '../reducers';

export const getSupplierPriceListItemState = createSelector(
  fromFeature.getSupplierReviewState,
  (state: fromFeature.SupplierState) => state.supplierPrice,
);

export const getSupplierPriceListItem = createSelector(
  getSupplierPriceListItemState,
  fromReducer.getSupplierPriceListItem,
);

export const getSupplierPriceListItemPageNumber = createSelector(
  getSupplierPriceListItemState,
  fromReducer.getSupplierPriceListItemPageNumber,
);

export const getSupplierPriceListItemItems = createSelector(
  getSupplierPriceListItemState,
  fromReducer.getSupplierPriceListItemItems,
);

export const getSupplierTotalCount = createSelector(
  getSupplierPriceListItemState,
  fromReducer.getSupplierTotalCount,
);

export const getSupplierPriceListItemIsLoading = createSelector(
  getSupplierPriceListItemState,
  (s) => s.callState === LoadingState.LOADING,
);

export const getSupplierPriceListItemIsLoaded = createSelector(
  getSupplierPriceListItemState,
  (s) => s.callState === LoadingState.LOADED,
);

export const getSupplierPriceListItemError = createSelector(
  getSupplierPriceListItemState,
  (s) => getError(s.callState),
);
