import { HttpClient, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  PaginatedListOfJobCosting,
  JobCostingParameters,
  SingleSaveVariation, VariationEx, ResetVariationToQuotedResponse,
} from 'app/shared/models';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VariationsService {
  constructor(private http: HttpClient) {}

  getVariations(
    request: JobCostingParameters,
  ): Observable<PaginatedListOfJobCosting> {
    const url = `${environment.urls.api}/variation/list`;
    return this.http.post<PaginatedListOfJobCosting>(url, request);
  }

  getVariation(variationId: string): Observable<VariationEx> {
    const url = `${environment.urls.api}/Variation/${variationId}/single`;
    return this.http.get<VariationEx>(url);
  }

  save(variation: SingleSaveVariation): Observable<SingleSaveVariation> {
    const url = `${environment.urls.api}/variation/single`;
    return this.http.put<SingleSaveVariation>(url, variation);
  }
  changeStatus(jobId: string, variationId: string, status: string): Observable<SingleSaveVariation> {
    let url;
    if (status === 'accepted') {
      url = `${environment.urls.api}/variation/${jobId}/${variationId}/status/${status}`;
    } else {
      url = `${environment.urls.api}/variation/${variationId}/status/${status}`;
    }
    return this.http.post<SingleSaveVariation>(url, {});
  }
  addVariation(jobId: string, name: string): Observable<SingleSaveVariation> {
    const url = `${environment.urls.api}/variation/add`;
    return this.http.post<SingleSaveVariation>(url, {
      jobId,
      name,
    });
  }
  checkCanModify(variationId: string, sectionId: string): Observable<Boolean> {
    const url = `${environment.urls.api}/variation/${variationId}/${sectionId}/canmodify`;
    return this.http.get<Boolean>(url).pipe(shareReplay(1));
  }

  getJobCostingSummary(jobId: string) {
    const url = `${environment.urls.api}/variation/CostingSummary/${jobId}`;
    return this.http.get(url);
  }
  
  canResetVariation(jobId: string, variationId: string): Observable<ResetVariationToQuotedResponse> {
    const url = `${environment.urls.api}/variation/${jobId}/${variationId}/canreset`;
    return this.http.post<ResetVariationToQuotedResponse>(url, null);
  }
  
  resetVariation(jobId: string, variationId: string): Observable<ResetVariationToQuotedResponse> {
    const url = `${environment.urls.api}/variation/${jobId}/${variationId}/reset`;
    return this.http.post<ResetVariationToQuotedResponse>(url, null);
  }

  uploadVariationLetterLogo(variationId: string, file: File): Observable<any> {
    const url = `${environment.urls.api}/variation/${variationId}/logo`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', url, formData);

    return this.http.post<any>(url, formData);
  }
}
