<div class="p-6 pb-0">
  <div class="xs:text-xl flex sm:text-3xl font-bold items-center tracking-tight leading-none border-b pb-6">
    <div class="rounded-full w-10 h-10 flex justify-center items-center bg-warn-600 mr-4">
      <mat-icon class="text-white" [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
    </div>
    Variation changes occurred
  </div>
  <mat-dialog-content class="overflow-y-auto">
    <div class="text-lg	 mb-6">
      The following items were removed from the variation because they no longer exist in the complete working estimate.
    </div>
    <div *ngFor="let name of variationNames" class="text-xl mb-6">
      <div class="mb-2 font-medium">{{name}} </div>
      <div *ngFor="let item of variations[name]" class="ml-8 mb-2">
        <span>({{ item.itemCode}}) {{ item.itemDescription}}</span>
      </div>
    </div>
  </mat-dialog-content>
</div>
<div
  class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
  <button (click)="dialogRef.close()" [autofocus]="false" class="fuse-mat-button-large" mat-stroked-button>
    <span class="ml-2 mr-1">Close</span>
  </button>
</div>