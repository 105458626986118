/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/naming-convention */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import {
  MatPaginator as MatPaginator,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Actions, ofType } from '@ngrx/effects';
import { JobWizardModalComponent } from 'app/shared/components/job-wizard-modal/job-wizard-modal.component';
import { Job, JobParameters, JobStatus, JobType } from 'app/shared/models';
import { BackNavigationService } from 'app/shared/services/back-navigation.service';
import { GuidedTourService } from 'app/shared/services/guided-tour.service';
import {
  GuidedTourNameEnum,
  IntroJsService,
} from 'app/shared/services/introjs.service';
import { AccountUserFacade, CommonDataFacade } from 'app/shared/store/facades';
import { combineLatest, merge, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { JobStateService } from '../../services/job-state.service';
import { JobListActionTypes, JobListFacade } from '../../store';
import { InvoiceStatusText } from '../job-invoices/invoice-list/invoice-list.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { JobStatusPillComponent } from '../../../../shared/components/job-status-pill/job-status-pill.component';
import { FuseCardComponent } from '../../../../../@fuse/components/card/card.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, NgClass, AsyncPipe, DatePipe } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export enum JobStatusText {
  All = 999,
  New = 10,
  Estimating = 20,
  Quoted = 30,
  Approved = 40,
  InProgress = 50,
  Complete = 60,
  Cancelled = 70,
}

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    NgFor,
    FuseCardComponent,
    SharedModule,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    AsyncPipe,
    DatePipe,
    MatProgressSpinnerModule,
  ],
})
export class JobListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(MatSort) matSortChildComponent: QueryList<MatSort>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public defaultSort: Sort = { active: 'estimateCode', direction: 'desc' };
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];

  public query: JobParameters = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: [{ orderBy: 'estimateCode', descending: true }],
  };
  searchForm: FormGroup = this.fb.group({
    searchField: [null],
  });
  title: string;
  isSmallPortraitScreen = false;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Job>;
  jobs: Job[] = [];
  jobType = JobType;
  statusControl: FormControl;

  showMobile = false;
  introEstimateNew;
  introJob;
  newEstimateClicked = false;

  estimateStatuses$ = this.commonDataFacade.estimateStatuses$.pipe(
    map((statuses) =>
      statuses.filter((s) => s.id !== JobStatus.Estimate_Accepted),
    ),
  );
  jobStatuses$ = this.commonDataFacade.jobStatuses$;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public jobsFacade: JobListFacade,
    public commonDataFacade: CommonDataFacade,
    private jobState: JobStateService,
    private chRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private fuseMediaWatcherService: FuseMediaWatcherService,
    private introJsService: IntroJsService,
    public breakpointObserver: BreakpointObserver,
    private actions$: Actions,
    private guidedTourService: GuidedTourService,
    private backNavigationService: BackNavigationService,
    private auf: AccountUserFacade,
  ) {}

  get searchField(): FormGroup {
    return this.searchForm.get('searchField') as FormGroup;
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 1280px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showMobile = false;
        } else {
          this.showMobile = true;
        }
      });
    this.fuseMediaWatcherService
      .onMediaQueryChange$(['(max-height: 440px)', '(max-width: 440px)'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isSmallPortraitScreen = state.matches;
        // Mark for check
        this.chRef.markForCheck();
      });

    combineLatest([this.auf.activeAccount$, this.route.data])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([tm, data]) => {
        // let data = JSON.parse(JSON.stringify(dt));
        // console.log('initialise:::', tm, data);
        this.query = JSON.parse(JSON.stringify(this.query)) as JobParameters;
        this.query.jobType = data.jobType;
        this.title = JobType[this.query.jobType];

        if (
          this.jobState[`current${this.title}ListStatus`] !== JobStatusText.All
        ) {
          this.query.status = this.jobState[`current${this.title}ListStatus`];
          this.statusControl = new FormControl(this.query.status);
        } else {
          this.statusControl = new FormControl(JobStatusText.All);
        }

        this.statusControl.valueChanges
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((statusChanges) => {
            console.log('statusControl.valueChanges:::', this.query);
            if (!statusChanges) {
              return;
            }
            this.query = {
              ...this.query,
              status: statusChanges,
            };
            if (statusChanges === InvoiceStatusText.All) {
              delete this.query.status;
            }
            this.jobState[`current${this.title}ListStatus`] = statusChanges;
            this.jobsFacade.getJobs(this.query);
          });

        if (this.query.jobType === JobType.Estimate) {
          this.defaultSort = {
            active: 'estimateCode',
            direction: 'desc',
          };
          this.query = {
            ...this.query,
            orderBy: [{ orderBy: 'estimateCode', descending: true }],
          };
        } else {
          this.defaultSort = { active: 'jobCode', direction: 'desc' };
          this.query = {
            ...this.query,
            orderBy: [{ orderBy: 'jobCode', descending: true }],
          };
        }
        this.jobsFacade.getRecentJobs(data.jobType);
        if (data.jobType === JobType.Estimate) {
          this.displayedColumns = [
            'estimateCode',
            'name',
            'address',
            'suburb',
            'customer',
            'email',
            'phone',
            'status',
            'expires',
            'action',
          ];
        } else {
          this.displayedColumns = [
            'jobCode',
            'jobTitle',
            'customer',
            'address',
            'suburb',
            'email',
            'phone',
            'status',
            'action',
          ];
        }
      });

    this.searchField.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe((value) => {
        this.query = {
          ...this.query,
        };
        this.query.filter = value;
        this.query['pageNumber'] = 1;
        this.jobsFacade.getJobs(this.query);
      });

    this.jobsFacade.jobs$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((x) => x != null),
      )
      .subscribe((res) => {
        this.jobs = res.map((e) => ({
          ...e,
          statusText: JobStatus[e.status],
        }));
        this.dataSource = new MatTableDataSource(this.jobs);
        this.dataSource.sort = this.sort;
        this.chRef.detectChanges();
      });

    this.actions$
      .pipe(ofType(JobListActionTypes.GetJobsSuccess), take(1))
      .subscribe(() => {
        if (this.title === JobType[JobType.Estimate]) {
          this.guidedTourService
            .getGuidedTourShow(btoa(GuidedTourNameEnum.EstimateList))
            .subscribe((show) => {
              if (show) {
                this.introJsService.estimateListAdd(
                  this.introEstimateNew,
                  !!this.jobs?.length,
                );
              }
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.jobsFacade.clearJobListState();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.introJsService.currentIntroPage$.next(null);
  }

  ngAfterViewInit(): void {
    this.matSortChildComponent.changes
      .pipe(
        filter((x) => !!x),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((x) => {
        if (x === undefined) {
          return;
        }
        this.initMatSort();
      });

    this.introJsService.currentIntroPage$.next(
      this.title === JobType[JobType.Estimate]
        ? GuidedTourNameEnum.EstimateList
        : GuidedTourNameEnum.JobsList,
    );
    this.introJsService.resetedTour$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.introInit(true);
      });
    this.guidedTourService
      .getGuidedTourShow(
        btoa(
          this.title === JobType[JobType.Estimate]
            ? GuidedTourNameEnum.EstimateList
            : GuidedTourNameEnum.JobsList,
        ),
      )
      .subscribe((show) => {
        if (show) {
          this.introInit();
        }
      });

    combineLatest([this.auf.activeAccount$, this.route.data])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([tm, data]) => {
        this.loadJobs();
      });
  }

  initMatSort(): void {
    if (this.sort === undefined) {
      return;
    }
    const sort$ = this.sort.sortChange.pipe(
      tap((x) => {
        let filterColumn = '';
        if (this.query.jobType === JobType.Job) {
          switch (x.active) {
            case 'jobTitle': {
              filterColumn = 'jobTitle';
              break;
            }
            case 'jobCode': {
              filterColumn = 'jobCode';
              break;
            }
            case 'customer': {
              filterColumn = 'customer.person.fullName';
              break;
            }
            case 'address': {
              filterColumn = 'customer.address.streetAddress';
              break;
            }
            case 'email': {
              filterColumn = 'customer.person.email';
              break;
            }
            case 'phone': {
              filterColumn = 'customer.person.phone';
              break;
            }
            case 'status': {
              filterColumn = 'status';
              break;
            }
            default: {
              filterColumn = 'jobTitle';
              break;
            }
          }
        } else if (this.query.jobType === JobType.Estimate) {
          switch (x.active) {
            case 'name': {
              filterColumn = 'jobTitle';
              break;
            }
            case 'estimateCode': {
              filterColumn = 'estimateCode';
              break;
            }
            case 'customer': {
              filterColumn = 'customer.person.fullName';
              break;
            }
            case 'suburb': {
              filterColumn = 'customer.address.suburb';
              break;
            }
            case 'address': {
              filterColumn = 'customer.address.streetAddress';
              break;
            }
            case 'email': {
              filterColumn = 'customer.person.email';
              break;
            }
            case 'phone': {
              filterColumn = 'customer.person.phone';
              break;
            }
            case 'status': {
              filterColumn = 'status';
              break;
            }
            case 'expires': {
              filterColumn = 'expires';
              break;
            }
            default: {
              filterColumn = 'name';
              break;
            }
          }
        }

        this.query = {
          ...this.query,
          orderBy: [
            {
              orderBy: filterColumn,
              descending: x.direction === 'desc' ? true : false,
            },
          ],
        };

        this.paginator.pageIndex = 0;
      }),
    );
    merge(sort$, this.paginator?.page)
      .pipe(
        tap(() => this.loadJobs()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((x) => this.chRef.detectChanges());
  }

  introInit(afterReset?: boolean): void {
    if (this.title === JobType[JobType.Estimate]) {
      this.introEstimateNew = this.introJsService.getIntro();
      this.introEstimateNew.onexit(() => {
        if (!this.newEstimateClicked) {
          this.guidedTourService
            .completeGuidedTour(btoa(GuidedTourNameEnum.EstimateList))
            .subscribe();
        } else {
          this.newEstimateClicked = false;
        }
      });
      if (afterReset) {
        this.introJsService.estimateListAdd(
          this.introEstimateNew,
          !!this.jobs?.length,
        );
      }
    } else {
      this.introJob = this.introJsService.getIntro();
      this.introJob.onexit(() => {
        this.guidedTourService
          .completeGuidedTour(btoa(GuidedTourNameEnum.JobsList))
          .subscribe();
        this.introJob = null;
      });
      this.chRef.detectChanges();
      this.introJsService.jobsList(this.introJob);
    }
  }

  new(): void {
    let data: any = { title: this.title };

    if (this.title === JobType[JobType.Estimate]) {
      if (this.introEstimateNew) {
        this.newEstimateClicked = true;
        this.introJsService.exitItroJs(this.introEstimateNew);
        data.intro = this.introEstimateNew;
      }
    }

    const dialogRef = this.dialog.open(JobWizardModalComponent, {
      width: '626px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.jobsFacade.getJobs(this.query);
        this.actions$
          .pipe(ofType(JobListActionTypes.GetJobsSuccess), take(1))
          .subscribe(() => {
            if (this.title === JobType[JobType.Estimate]) {
              this.guidedTourService
                .getGuidedTourShow(btoa(GuidedTourNameEnum.EstimateList))
                .subscribe((show) => {
                  if (show) {
                    this.introJsService.estimateListAdd(
                      this.introEstimateNew,
                      !!this.jobs?.length,
                    );
                    this.introEstimateNew.goToStepNumber(2);
                  }
                });
            }
          });
      }
    });
  }

  details(item): void {
    if (this.title === JobType[JobType.Estimate]) {
      if (this.introEstimateNew) {
        this.introJsService.exitItroJs(this.introEstimateNew);
      }
    }
    this.backNavigationService.tooltip = `Back to ${this.title}s`;
    this.backNavigationService.url = this.router.url;
    this.router.navigate([`${item.id}/info`], { relativeTo: this.route });
  }

  private loadJobs(): void {
    this.query = {
      ...this.query,
    };
    this.query.pageNumber = this.paginator.pageIndex + 1;
    this.query.pageSize = this.paginator.pageSize;
    this.jobsFacade.getJobs(this.query);
  }
}
