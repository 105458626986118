<div
  class="text-4xl font-extrabold tracking-tight leading-none border-b pb-3 mb-5"
>
  Update item price in order estimates
</div>

<mat-dialog-content
  fuseScrollbar
  [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
  class="pb-1 relative"
>
  <div
    *ngIf="itemPriceCheckFacade.isLoading$ | async"
    class="absolute w-full h-full flex items-center justify-center"
  ></div>

  <p class="mb-2">Item: {{ data.itemName }}</p>
  <p class="mb-0">New price: {{ data.itemPrice | currency }}</p>
  <p class="mb-2">Select estimates to update with new item price.</p>

  <ng-container
    *ngIf="itemPriceCheckFacade.isLoaded$ | async"
    [formGroup]="form"
  >
    <div class="mb-2">
      <mat-checkbox
        formControlName="all"
        [color]="'primary'"
        (change)="changeAll($event)"
      >
        All draft estimates
      </mat-checkbox>
    </div>
    <ng-container
      *ngIf="itemPriceCheckFacade.isLoaded$ | async"
      formArrayName="items"
    >
      <div
        *ngFor="
          let item of itemPriceCheckFacade.priceListItemPriceChangeJob$ | async;
          let i = index
        "
        class="mb-2"
      >
        <ng-container [formGroupName]="i">
          <mat-checkbox
            formControlName="checked"
            [color]="'primary'"
            (change)="change()"
          >
            {{ item.code }} ({{ item.jobTitle }})
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <div
    *ngIf="itemPriceCheckFacade.isLoading$ | async"
    class="flex items-center justify-center"
  >
    <mat-progress-spinner
      [diameter]="24"
      [mode]="'indeterminate'"
    ></mat-progress-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="flex flex-row border-t pt-2 w-full">
    <button (click)="close()" class="fuse-mat-button-large" mat-stroked-button>
      <span class="ml-2 mr-1">Cancel</span>
    </button>
    <button
      [disabled]="!isValid"
      (click)="update()"
      class="ml-4 fuse-mat-button-large"
      mat-flat-button
      [color]="'primary'"
    >
      <span class="ml-2 mr-1">Update price</span>
    </button>
  </div>
</mat-dialog-actions>
