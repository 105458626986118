import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { JobNoteParameters } from 'app/shared/models';
import { JobNotesFacade } from 'app/views/jobs/store/facades/job-notes.facade';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { deleteJobNoteSuccess, updateJobNoteSuccess } from '../../../store';
import { NotesEditorAction } from 'app/shared/enums/editor-action.enum';
import { JobNotesEditorComponent } from '../../../../../shared/components/job-notes-editor/job-notes-editor.component';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'app-job-notes-container',
  templateUrl: './job-notes-container.component.html',
  styleUrls: ['./job-notes-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    NgFor,
    SharedModule,
    MatPaginatorModule,
    NgClass,
    AsyncPipe,
  ],
})
export class JobNotesComponent implements OnInit, OnDestroy {
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];
  jobId: string;
  public query: JobNoteParameters = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: [{ orderBy: 'updated', descending: true }],
  };

  searchForm: FormGroup = this.fb.group({
    searchField: [null],
  });

  get searchField(): FormGroup {
    return this.searchForm.get('searchField') as FormGroup;
  }
  actionResult$ = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();

  constructor(
    private facade: JobNotesFacade,
    private route: ActivatedRoute,
    private actions$: Actions,
    private fb: FormBuilder,
  ) {}

  notes$ = this.facade.notes$;
  totalCount$ = this.facade.totalCount$;
  isLoaded$ = this.facade.isLoaded$;

  ngOnInit(): void {
    this.jobId = this.route.snapshot.parent.params['id'];
    this.query.jobId = this.jobId;
    this.facade.getNotes(this.query);
    this.actions$
      .pipe(ofType(deleteJobNoteSuccess))
      .subscribe(() => this.facade.getNotes(this.query));

    this.actions$
      .pipe(ofType(updateJobNoteSuccess))
      .subscribe((r) => this.actionResult$.emit(r.note));

    this.searchField.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(1000))
      .subscribe((value) => {
        this.query = {
          ...this.query,
          filter: value,
          pageNumber: 1,
        };
        this.facade.getNotes(this.query);
      });
  }

  paginatorChange(pageEvent: PageEvent): void {
    this.query = {
      ...this.query,
    };
    this.query.pageNumber = pageEvent.pageIndex + 1;
    this.query.pageSize = pageEvent.pageSize;
    this.facade.getNotes(this.query);
  }

  onEditorChange(e): void {
    switch (e.action) {
      case NotesEditorAction.delete: {
        this.facade.deleteNote(e.id);
        break;
      }
      case NotesEditorAction.create: {
        this.facade.updateNote(e.payload);
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
