<div
  *ngIf="summary$ | async as summary"
  class="flex flex-row flex-wrap justify-around rounded border border-gray-300 dark:border-gray-500"
>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Unit total</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      $ {{ summary.unitTotal | number: "1.2-2" }}
    </div>
  </div>
  <!--  <div class="col-span-2">-->
  <!--    <div class="fl  ex flex-col w-40 max-w-full">-->
  <!--      <div>Quotable total</div>-->
  <!--      <div class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end">-->
  <!--        $ {{ summary.quotableUnitTotal | number:'1.2-2' }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>On cost %</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      {{ summary.onCost | number: "1.2-2" }} %
    </div>
  </div>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Profit</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      $ {{ summary.profitAmount | number: "1.2-2" }}
    </div>
  </div>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Net quote</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      $ {{ summary.netQuoteAmount | number: "1.2-2" }}
    </div>
  </div>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Customer quote</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      $ {{ summary.quoteAmount | number: "1.2-2" }}
    </div>
  </div>
</div>
