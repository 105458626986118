import { SelectionModel } from "@angular/cdk/collections";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table";
import { IntegrationType, JobInvoiceParameters, JobOrderParameters } from "@app/shared/models";
import { JobService } from "@app/views/jobs/services/job.service";
import { JobInvoicesFacade, JobOrdersListFacade, SuppliersListByJobFacade } from "@app/views/jobs/store";
import { uniqBy } from "lodash";
import { Subject, catchError, forkJoin, map, of, takeUntil, tap } from "rxjs";
import { IntegrationsService } from "../../services/integration.service";
import { FuseAlertComponent } from "@fuse/components/alert";
import { error } from "console";

@Component({
  selector: 'app-integration-summary',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatButtonModule,
    MatTableModule,
    MatCheckboxModule,
    FuseAlertComponent
  ],
  templateUrl: './integration-summary.component.html',
  styleUrl: './integration-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IntegrationSummaryComponent implements
  OnInit, OnDestroy {

  private unsubscribeAll: Subject<any> = new Subject<any>();
  ordersNumber = 0;
  invoicesNumber = 0;
  issuesNumber = 0;
  syncingOrders = false;
  syncingInvoices = false;
  syncingIssues = false;

  ordersDisplayedColumns: string[] = [
    'select',
    'orderCode',
    'orderDate',
    'supplier',
    'amount',
  ];
  ordersDataSource;

  public ordersQuery: JobOrderParameters = {
    pageNumber: 1,
    pageSize: 9999,
    orderBy: [{ orderBy: 'orderCode', descending: false }],
  };

  @ViewChild(MatSort) sort: MatSort;

  issueDisplayedColumns = [
    'select',
    'referenceId',
    'message',
    'type',
  ];
  issuesDataSource;

  public invoiceQuery: JobInvoiceParameters = {
    pageNumber: 1,
    pageSize: 9999,
    orderBy: [{ orderBy: '', descending: false }],
  };

  invoiceDisplayedColumns = [
    'select',
    'invoiceCode',
    'description',
    'invoiceDate',
    'paymentDueDate',
    'invoicedAmount'
  ];
  invoiceDataSource;

  selectionOrders = new SelectionModel<any>(true, []);
  selectionInvoice = new SelectionModel<any>(true, []);
  selectionIssues = new SelectionModel<any>(true, []);

  connectionActive = false;

  constructor(
    public jobOrdersListFacade: JobOrdersListFacade,
    public suppliersListByJobFacade: SuppliersListByJobFacade,
    public invoicesFacade: JobInvoicesFacade,
    private snackbarService: MatSnackBar,
    private jobService: JobService,
    private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {

    this.integrationsService.getXeroSettings()
      .subscribe(res => {
        this.connectionActive = res.connectionActive;

        if (this.connectionActive) {
          this.jobOrdersListFacade.getJobsOrderList(this.ordersQuery);

          this.jobOrdersListFacade.jobOrders$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
              const ordersData = res.filter(x => x.status === 30 && x.integrationStatus === 30);
              this.ordersNumber = ordersData.length;

              this.ordersDataSource = new MatTableDataSource(ordersData);
              this.ordersDataSource.sortingDataAccessor = (item, property) => {
                switch (property) {
                  case 'supplier':
                    return item.supplier.name;
                  default:
                    return item[property];
                }
              };
              this.ordersDataSource.sort = this.sort;

              if (this.syncingOrders) {
                this.syncingOrders = false;
              }

            });

          this.invoicesFacade.getInvoices(this.invoiceQuery);
          this.invoicesFacade.invoices$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((res) => {

              const invoicesData = res.filter(x => x.status === 20 && x.integrationStatus === 30);
              this.invoicesNumber = invoicesData.length;
              this.invoiceDataSource = new MatTableDataSource(invoicesData);

              if (this.syncingInvoices) {
                this.syncingInvoices = false;
              }
            });

          const topResultCount = 20;
          this.integrationsService
            .getTransactionHistoryIssues(topResultCount)
            .subscribe((res) => {
              this.issuesNumber = res.length;
              console.log("transaction history", res);
              this.issuesDataSource = new MatTableDataSource(res);
            });
        }
      });

  }


  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  syncOrdersOnClick() {
    const selectionList = this.selectionOrders.selected.map(x => x.id);

    if (selectionList.length > 0) {
      this.syncingOrders = true;

      this.jobService.syncJobOrderBulk(selectionList)
        .subscribe(res => {
          this.syncingOrders = false;
          this.snackbarService.open("Orders have been sycned successfully", "Close");
          this.jobOrdersListFacade.getJobsOrderList(this.ordersQuery);
          this.selectionOrders.clear();
        }, err => {
          this.syncingOrders = false;
          this.snackbarService.open("Orders have failed to sync", "Close");
          this.jobOrdersListFacade.getJobsOrderList(this.ordersQuery);
          this.selectionOrders.clear();

        });
    }
  }

  getIssues() {
    const topResultCount = 20;
    this.integrationsService
      .getTransactionHistoryIssues(topResultCount)
      .subscribe((res) => {
        this.issuesNumber = res.length;
        console.log("transaction history", res);
        this.issuesDataSource = new MatTableDataSource(res);
      });
  }

  syncInvoicesOnClick() {
    const selectionList = this.selectionInvoice.selected.map(x => x.id);

    if (selectionList.length > 0) {
      this.syncingInvoices = true;
      this.jobService.syncJobInvoiceBulk(selectionList)
        .subscribe(res => {
          this.syncingInvoices = false;
          this.snackbarService.open("Invoices have been sycned successfully", "Close");
          this.invoicesFacade.getInvoices(this.invoiceQuery);
          this.selectionInvoice.clear();
        }, err => {
          console.log(err);
          this.syncingInvoices = false;
          this.snackbarService.open("Invoices have failed to sync", "Close");
          this.invoicesFacade.getInvoices(this.invoiceQuery);
          this.selectionInvoice.clear();
        })
    }
  }

  removeIssuesOnClick() {
    console.log(this.selectionIssues.selected);

    if (this.selectionIssues.selected.length > 0) {

      const selectionList = this.selectionIssues.selected.map(x => x.id);
      const topResultCount = 20;

      this.syncingIssues = true;
      this.integrationsService.removeIssues(selectionList)
        .subscribe(res => {
          this.snackbarService.open("Successfully removed issues", "Close");

          this.integrationsService
            .getTransactionHistoryIssues(topResultCount)
            .subscribe((res) => {
              this.issuesNumber = res.length;
              this.issuesDataSource = new MatTableDataSource(res);
              this.syncingIssues = false;
            });
          this.selectionIssues.clear();
        }, err => {
          this.snackbarService.open("Failed to remove issues", "Close");
          this.integrationsService
            .getTransactionHistoryIssues(topResultCount)
            .subscribe((res) => {
              this.issuesNumber = res.length;
              this.issuesDataSource = new MatTableDataSource(res);
              this.syncingIssues = false;
            });
          this.selectionIssues.clear();
        });
    }
  }

  syncIssuesOnClick() {
    console.log(this.selectionIssues.selected);

    if (this.selectionIssues.selected.length > 0) {

      const invoicesList = this.selectionIssues.selected.filter(x => x.exportType === 10).map(x => x.id);
      const ordersList = this.selectionIssues.selected.filter(x => x.exportType === 20).map(x => x.id);

      const join = [];
      if (invoicesList.length > 0) {
        join.push(this.jobService.syncJobInvoiceBulk(invoicesList));
      }

      if (ordersList.length > 0) {
        join.push(this.jobService.syncJobOrderBulk(ordersList));
      }

      if (join.length > 0) {
        forkJoin(join).pipe(
          tap(x => {
            this.syncingIssues = false;
            this.snackbarService.open("Issues have been sycned successfully", "Close");
            this.selectionIssues.clear();
            this.getIssues();
          }),
          catchError((err) => {
            this.syncingIssues = false;
            this.snackbarService.open("Issues have failed to sync", "Close");
            this.selectionIssues.clear();
            this.getIssues();
            return of(1)
          })
        ).subscribe(res => console.log(res));
      }


    }
  }

  getExportType(exportType: number) {
    if (exportType === 10) {
      return "Invoice";
    }
    else {
      return "Order"
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedOrders() {
    const numSelected = this.selectionOrders.selected.length;
    const numRows = this.ordersDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRowsOrders() {
    if (this.isAllSelectedOrders()) {
      this.selectionOrders.clear();
      return;
    }

    this.selectionOrders.select(...this.ordersDataSource.data);
  }

  checkboxLabelOrders(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedOrders() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionOrders.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  isAllSelectedInvoice() {
    const numSelected = this.selectionInvoice.selected.length;
    const numRows = this.invoiceDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRowsInvoice() {
    if (this.isAllSelectedInvoice()) {
      this.selectionInvoice.clear();
      return;
    }

    this.selectionInvoice.select(...this.invoiceDataSource.data);
  }

  checkboxLabelInvoice(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedInvoice() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionInvoice.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }


  isAllSelectedIssues() {
    const numSelected = this.selectionIssues.selected.length;
    const numRows = this.issuesDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRowsIssues() {
    if (this.isAllSelectedIssues()) {
      this.selectionIssues.clear();
      return;
    }

    this.selectionIssues.select(...this.issuesDataSource.data);
  }

  checkboxLabelIssues(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedIssues() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectionIssues.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }


}
