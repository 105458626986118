import { tap } from 'rxjs';
/* eslint-disable arrow-body-style */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Account,
  AccountUser,
  LoggedInUser,
  PriceListCompleted,
  TeamMember,
} from 'app/shared/models/eazimate.models';
import { AccountUserService } from 'app/shared/services/account-user.service';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromActions from 'app/shared/store/actions/account-user.actions';
import * as fromSelectors from 'app/shared/store/selectors/account-user.selectors';
import moment from 'moment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastService } from '../../services/toast.service';
import { ApplicationInsightsService } from '@app/shared/services/telemetry.service';

@Injectable({
  providedIn: 'root',
})
export class AccountUserEffects {
  removeShoMessageFlagOnRemovedAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markRemovedAccountsMessageShown),
      switchMap(({ payload }) => {
        return this.api.removeShowMessageFlagOnRemovedAccount(payload).pipe(
          map(() => {
            return fromActions.markRemovedAccountsMessageShownSuccess();
          }),

          catchError((error: any) => {
            return of(
              fromActions.markRemovedAccountsMessageShownFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  saveThemeMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setThemeMode),
      switchMap((val) => {
        const requestPayload = {
          settingKey: 'themeMode',
          settingValue: val.payload,
        };

        return this.api.saveThemeMode(requestPayload).pipe(
          map((payload: any) => {
            return fromActions.saveThemeModeSuccess({ payload });
          }),

          catchError((error: any) => {
            return of(
              fromActions.saveThemeModeFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  generatSasToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSasToken),
      switchMap(() => {
        return this.api.getSasToken().pipe(
          map((token: string) => {
            return fromActions.getSasTokenSuccess({
              payload: token,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.getSasTokenFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  logInEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loginAccountUser),
      concatMap(() => {
        return this.api.logIn().pipe(
          map((data: LoggedInUser) => {
            this.ga.event(
              'login',
              'account_user',
              'Account User Login',
              -1,
              false,
              {
                user_id: data?.user?.identityId ?? '',
                user_email: data?.user?.person?.email ?? '',
                account_id: `${data?.account?.name}(${data?.account?.id})`,
              },
            );
            this.telemetry.appInsights.setAuthenticatedUserContext(
              data?.user?.identityId ?? 'notset',
              data?.account?.id ?? 'notset',
              true,
            );

            return fromActions.loginAccountUserSuccess({
              payload: data,
            });
          }),

          catchError((pack: any) => {
            if ((pack.error.StatusCode = '404')) {
              this.router.navigate(['account-upgrade']);
              return of(null);
            }
            return of(
              fromActions.loginAccountUserFailure({
                payload: pack,
              }),
            );
          }),
        );
      }),
    ),
  );

  handleLoginError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loginAccountUserNotFoundFailure),
        tap(({ payload }) => {
          console.log(payload);
          this.router.navigate(['account-upgrade']);
        }),
      ),
    { dispatch: false },
  );

  saveAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.saveAccountSettingsForm),
      withLatestFrom(this.store.select(fromSelectors.selectAccount)),
      switchMap(([_, account]) => {
        return this.api.saveAccount(account).pipe(
          take(1),
          map((data: Account) => {
            return fromActions.saveAccountSuccess({
              account: data,
            });
          }),
          catchError((error: any) => {
            this.toast.error('An error occurred while saving your account.');
            return of(error);
          }),
        );
      }),
    );
  });

  cancelAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.cancelAccount),
      withLatestFrom(this.store.select(fromSelectors.selectAccount)),
      switchMap(([_, account]) => {
        return this.api.cancelAccount().pipe(
          take(1),
          map((data: Account) => {
            return fromActions.cancelAccountSuccess({
              payload: data,
            });
          }),
        );
      }),
    );
  });

  saveAccountUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.saveProfileSettingsForm),
      withLatestFrom(this.store.select(fromSelectors.selectAccountUser)),
      switchMap(([_, accountUser]) => {
        return this.api.saveAccountUser(accountUser).pipe(
          take(1),
          map((payload: AccountUser) => {
            return fromActions.saveAccountUserSuccess({
              payload,
            });
          }),
          catchError((error: any) => {
            this.toast.error(
              'An error occurred while saving your Account user.',
            );
            return of(null);
          }),
        );
      }),
    );
  });

  updateTeamMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.modifyInvite),
      switchMap(({ teamMember }) => {
        return this.api.updateTeamMember(teamMember).pipe(
          take(1),
          map((updatedTeamMember: TeamMember) => {
            return fromActions.teamMemberUpdateSuccess({
              teamMember: updatedTeamMember,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.teamMemberUpdateFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  acceptTeamMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.acceptInvite),
      switchMap(({ teamMember }) => {
        //teamMember.acceptedDate = moment();
        return this.api.acceptTeamMemberInvite(teamMember).pipe(
          take(1),
          map((updatedTeamMember: TeamMember) => {
            return fromActions.teamMemberUpdateSuccess({
              teamMember: updatedTeamMember,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.teamMemberUpdateFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  rejectTeamMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.rejectInvite),
      switchMap(({ teamMember }) => {
        console.log('CHANGES rejectTeamMember$::::::');
        teamMember.acceptedDate = moment();
        return this.api.updateTeamMember(teamMember).pipe(
          take(1),
          map((updatedTeamMember: TeamMember) => {
            return fromActions.teamMemberUpdateSuccess({
              teamMember: updatedTeamMember,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.teamMemberUpdateFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  uploadCompanyLogo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.uploadCompanyLogo),
      switchMap(({ file }) => {
        return this.api.uploadCompanyLogo(file).pipe(
          take(1),
          map((data) => {
            return fromActions.uploadCompanyLogoSuccess({
              fileLocation: data.fileLocation,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.uploadCompanyLogoFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  uploadQuoteLogo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.uploadQuoteLogo),
      switchMap(({ estimateId, file }) => {
        return this.api.uploadQuoteLogo(estimateId, file).pipe(
          take(1),
          map((data) => {
            return fromActions.uploadQuoteLogoSuccess({
              fileLocation: data.fileLocation,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.uploadQuoteLogoFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  swapAccountEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setActiveAccount),
      exhaustMap(({ payload }) => {
        return this.api.getAccount().pipe(
          map((data: Account) => {
            return fromActions.setActiveAccountSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            this.router.navigate(['/settings']);
            this.toast.warn(
              'Subscription Expired. See Billing -> Update Subscription.',
            );

            return of(
              fromActions.setActiveAccountFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  // navigate$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(fromActions.setActiveAccountSuccess),
  //       tap((_) => {
  //         this.router.navigate(['/dashboard']);
  //       }),
  //     );
  //   },
  //   { dispatch: false },
  // );

  teamMemberLeavesAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.leaveAccount),
      switchMap(({ payload }) => {
        return this.api.teamMemberLeavesAccount(payload).pipe(
          map(() => {
            console.log('Team member left account');
            return fromActions.leaveAccountSuccess({
              accountIdToRemove: payload.accountId,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.leaveAccountFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  getPriceListSetupProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getPriceListSetupProgress),
      switchMap(() => {
        // this.spinner.show();
        return this.api.getPriceListSetupPogress().pipe(
          map((payload: PriceListCompleted) => {
            // this.spinner.hide();
            const tmp =
              payload.percentComplete.toString().toLocaleLowerCase() === 'nan'
                ? 0
                : payload.percentComplete;
            const payloadModified = {
              ...payload,
              percentComplete: tmp,
            };
            return fromActions.getPriceListSetupProgressSuccess({
              payload: payloadModified,
            });
          }),
          catchError((error: any) => {
            return of(
              fromActions.getPriceListSetupProgressFailure({
                error,
              }),
            );
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private api: AccountUserService,
    private toast: ToastService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly ga: GoogleAnalyticsService,
    private telemetry: ApplicationInsightsService,
  ) {}
}
