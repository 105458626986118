import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-create-template-modal',
  templateUrl: './create-template-modal.component.html',
  styleUrls: ['./create-template-modal.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxTrimDirectiveModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
  ],
})
export class CreateTemplateModalComponent implements OnInit {
  templateForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateTemplateModalComponent>,
    private fb: FormBuilder,
  ) {
    this.templateForm = this.fb.group({
      name: ['', [Validators.required]],
      description: [''],
    });
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.templateForm.value);
  }
}
