import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Job,
  JobCosting, JobCostingWithMetrics,
  JobHistory,
  JobStatus,
  Variation,
  VariationParameters,
} from 'app/shared/models';
import { JobDetailFacade, JobVariationDetailFacade } from 'app/views/jobs/store';
import { Observable, Subject } from 'rxjs';

import { filter, take, takeUntil } from 'rxjs/operators';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FuseCardComponent } from '../../../../../../../@fuse/components/card/card.component';
import {
  NgIf,
  NgFor,
  NgClass,
  AsyncPipe,
  CurrencyPipe,
  DatePipe,
} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { JobCostingSummaryComponent } from '../job-costing-summary/job-costing-summary.component';
import { SharedModule } from 'app/shared/shared.module';
import { JobCostingFacade } from '@app/views/jobs/store/facades/job-costing.facade';
import { VariationChangesDialogComponent } from '@app/views/jobs/components';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-job-variations-list',
  templateUrl: './job-variations-list.component.html',
  styleUrls: ['./job-variations-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    JobCostingSummaryComponent,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule,
    NgIf,
    NgFor,
    FuseCardComponent,
    MatIconModule,
    MatFormFieldModule,
    MatPaginatorModule,
    NgClass,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
  ],
})
export class JobVariationsListComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private jobDetailFacade: JobDetailFacade,
    public jobCostingFacade: JobCostingFacade,
    private router: Router,
    private route: ActivatedRoute,
    private chRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private variationFacade: JobVariationDetailFacade
  ) {}

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  private unsubscriber$ = new Subject<void>();

  public query: VariationParameters = {
    pageNumber: 1,
    pageSize: 999,
    orderBy: [{ orderBy: 'code', descending: true }],
    filter: '',
    includeDeleted: false,
  };
  status = null;

  job$: Observable<Job> = this.jobDetailFacade.job$.pipe(filter((job) => !!job));
  dataSourceCosting: MatTableDataSource<JobCosting>;
  dataSourceVariations: MatTableDataSource<Variation>;
  dataSourceHistory: MatTableDataSource<JobHistory>;
  displayedColumns: string[] = [
    'name',
    'created',
    'unitTotal',
    'onCost',
    'profit',
    'netCost',
    'gstAmount',
    'totalCost',
  ];
  displayedColumnsCosting: string[]  = [...this.displayedColumns, 'percentOrdered', 'percentCalledFor', 'percentAcknowledged', 'percentIssue', 'percentCompleted', 'status','action'];
  displayedColumnsVariations: string[]  = [...this.displayedColumns, 'status','expires', 'action'];
  displayedColumnsHistory: string[]  = [...this.displayedColumns, 'status','action'];

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.job$
      .pipe(
        filter((job) => !!job),
        take(1),
      )
      .subscribe((job) => {
        this.status = job.status;
        this.jobCostingFacade.getJobCosting(job.id);
        this.jobCostingFacade.getJobHistory({
          ...this.query,
          jobId: job.id,
          status: null,
          orderBy: [{ orderBy: 'Updated', descending: true }],
        });
      });
    this.jobCostingFacade.jobCosting$
      .pipe(
        filter((jobCosting) => !!jobCosting),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((jobCosting) => {
        // fetch complete estimate items
        this.jobCostingFacade.getJobCostingSingle(jobCosting.id);
        const jobCostingArr = [jobCosting];
        this.dataSourceCosting = new MatTableDataSource<JobCostingWithMetrics>(
          jobCostingArr,
        );
        this.chRef.detectChanges();
      });
    this.jobCostingFacade.jobVariation$
      .pipe(
        filter((jobVariation) => !!jobVariation),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((jobVariation) => {
        this.dataSourceVariations = new MatTableDataSource<Variation>(
          jobVariation.items,
        );
        this.chRef.detectChanges();
      });
    this.jobCostingFacade.jobHistory$
      .pipe(
        filter((jobHistory) => !!jobHistory),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((jobHistory) => {
        this.dataSourceHistory = new MatTableDataSource<JobHistory>(
          jobHistory.items,
        );
        this.chRef.detectChanges();
      });
  }

  selectVariation(variation: JobCosting) {
    this.variationFacade.getJobVariation(variation.id);
    this.variationFacade.variationChanges$
      .pipe(takeUntil(this.unsubscriber$),
        filter(r => !!r))
      .subscribe(changes => {
        if (changes.count) {
          this.dialog
            .open(VariationChangesDialogComponent, {
              width: '720px',
              maxHeight: '70vh',
              panelClass: 'cdk-pane-p-0',
              autoFocus: false,
              data: changes
            })
            .afterClosed()
            .subscribe(() => {
              this.router.navigate([`../${ variation.id }`], {
                relativeTo: this.route,
                queryParams: {isReadOnly: this.status === JobStatus.Job_Completed ? true : null}
              });
            });
        } else {
          this.router.navigate([`../${ variation.id }`], {
            relativeTo: this.route,
            queryParams: {isReadOnly: this.status === JobStatus.Job_Completed ? true : null}
          });
        }
      })
  }
  completeWorkingEstimate(variation: JobCosting) {
    this.router.navigate([`../${variation.id}`, 'complete-estimate'], {
      relativeTo: this.route,
      queryParams: {isReadOnly: this.status === JobStatus.Job_Completed ? true : null}
    });
  }

  selectJobHistory(variation: JobHistory) {
    this.router.navigate([`../${variation.id}`, 'job-history'], {
      relativeTo: this.route,
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
