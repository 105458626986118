<!-- Button -->
<button [matMenuTriggerFor]="userActions" mat-icon-button>
  <span class="relative">
    <img
      class="w-7 h-7 rounded-full"
      *ngIf="showAvatar && user.avatar"
      [src]="user.avatar"
    />
    <mat-icon
      *ngIf="!showAvatar || !user.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
  </span>
</button>

<mat-menu #userActions="matMenu" [xPosition]="'before'">
  <!-- <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span><span>Active Account</span></span>
      <span class="mt-1.5 text-md font-medium">{{
        (af.activeAccount$ | async).accountName
      }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider> -->
  <button [routerLink]="'/accounts'" mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:briefcase'"></mat-icon>
    <span>Change Account</span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button (click)="signOut()" mat-menu-item>
    <mat-icon
      [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
    ></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
