import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobOrder } from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  jobOrderDeleteFacade,
  jobOrderDeleteFacadeFailure,
  jobOrderDeleteFacadeSuccess,
  getJobsOrderList,
  getJobsOrderListFailure,
  getJobsOrderListSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobOrderListEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobsOrderList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobsOrderList),
      // tap(_ => this.spinner.show()),
      switchMap(({ request }) =>
        this.jobService.getJobOrdersList(request).pipe(
          map((jobOrders: any) => getJobsOrderListSuccess({ jobOrders })),
          catchError((error) => {
            return of(getJobsOrderListFailure({ error }));
          }),
          // finalize(() => this.spinner.hide())
        ),
      ),
    ),
  );

  jobOrderDeleteFacade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(jobOrderDeleteFacade),
      switchMap(({ request }) =>
        this.jobService.deleteJobOrders(request).pipe(
          map((jobOrders: any) => jobOrderDeleteFacadeSuccess({ jobOrders })),
          catchError((error) => {
            return of(jobOrderDeleteFacadeFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
