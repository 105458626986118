/* eslint-disable arrow-body-style */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';

import * as fromActions from '../actions/general-settings.actions';
import * as fromSelectors from '../selectors/general-settings.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class GeneralSettingsEffects {
  getMeasuresList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUnitOfMeasuresList),
      switchMap(({ payload }) => {
        return this.api.listUnitsOfMeasure(payload).pipe(
          map((data: any) => {
            return fromActions.getUnitOfMeasuresListSuccess({
              payload: data.items,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.getUnitOfMeasuresListFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  addUnitOfMeasure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addUnitOfMeasure),
      switchMap(({ payload }) => {
        return this.api.addUnitOfMeasure(payload).pipe(
          map((data: any) => {
            return fromActions.addUnitOfMeasureSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.addUnitOfMeasureFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  updateUnitOfMeasure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateUnitOfMeasure),
      switchMap(({ payload }) => {
        return this.api.updateUnitOfMeasure(payload).pipe(
          map((data: any) => {
            return fromActions.updateUnitOfMeasureSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.updateUnitOfMeasureFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  deleteUnitOfMeasure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteUnitOfMeasure),
      switchMap(({ payload }) => {
        return this.api.deleteUnitOfMeasure(payload).pipe(
          map((data: any) => {
            return fromActions.deleteUnitOfMeasureSuccess({
              payload: data,
            });
          }),

          catchError((error: any) => {
            return of(
              fromActions.deleteUnitOfMeasureFailure({
                payload: error,
              }),
            );
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: SettingsService,
    private readonly store: Store,
  ) {}
}
