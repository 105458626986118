<div>
  <h2 class="text-sm sm:text-base text-center" mat-dialog-title>Update Plan</h2>
  <mat-dialog-content>
    <div class="grid grid-cols-2 gap-4">
      <div class="grid place-items-center" *ngFor="let item of availablePlans">
        <div
          class="py-0.5 px-3 rounded-full text-sm font-semibold"
          *ngIf="isDisabled(item)"
          [ngClass]="
            'text-pink-800 bg-pink-100 dark:text-pink-50 dark:bg-pink-500'
          "
        >
          Current plan
        </div>
        <div>{{ item.planName }}</div>
        <div>{{ item.planDescription }}</div>
        <div>{{ item.feeDescription }}</div>
        <button
          class="rounded-4xl mt-2 hover:bg-primary-600 text-slate-700 dark:text-white hover:text-white hover:dark:bg-primary-700"
          [disabled]="isDisabled(item)"
          (click)="handleOnSubscribeClicked($event, item)"
          mat-stroked-button
          type="button"
          mat-flat-button
        >
          <span>Subscribe</span>
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
