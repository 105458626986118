import { createAction, props } from '@ngrx/store';

export enum JobOrderSummaryActionTypes {
  GetJobOrderSummaryFacade = '[JobOrder Summary List] Get JobOrder',
  GetJobOrderSummaryFacadeSuccess = '[JobOrder Summary List] Get JobOrder Success',
  GetJobOrderSummaryFacadeFailure = '[JobOrder Summary List] Get JobOrder Failure',
}

export const getJobsOrderSummary = createAction(
  JobOrderSummaryActionTypes.GetJobOrderSummaryFacade,
  props<{ id: string }>(),
);
export const getJobsOrderSummarySuccess = createAction(
  JobOrderSummaryActionTypes.GetJobOrderSummaryFacadeSuccess,
  props<{ jobOrderSummary: any }>(),
);
export const getJobsOrderSummaryFailure = createAction(
  JobOrderSummaryActionTypes.GetJobOrderSummaryFacadeFailure,
  props<{ error: any }>(),
);
