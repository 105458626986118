<div
  class="absolute w-full h-full xs:inset-0 flex flex-col flex-auto min-w-0 overflow-y-auto"
>
  <div
    class="flex-col sm:flex-row md:flex-col justify-between px-4 xs:pt-4 pt-6"
  >
    <div class="table-search-wrap w-80 xs:mb-0 mb-3 xs:block sm:hidden md:block">
      <div [formGroup]="searchForm" class="table-search">
        <mat-form-field
          class="fuse-mat-dense fuse-mat-no-subscript sm:large-search w-full min-w-50"
        >
          <mat-icon
            matPrefix
            [svgIcon]="'heroicons_outline:magnifying-glass'"
          ></mat-icon>
          <input
            formControlName="searchField"
            matInput
            [autocomplete]="'off'"
            [placeholder]="'Search anything...'"
          />
          <button
            *ngIf="searchField.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchField.reset()"
          >
            <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div
    *ngIf="notes$ | async as notes"
    class="flex-auto space-y-4 p-4 overflow-y-auto xs:mb-18"
  >
    <ng-container *ngIf="notes?.length > 0; else noNotes">
      <div *ngFor="let note of notes">
        <app-job-notes-editor
          [isReadOnlyMode]="isReadOnlyMode"
          [note]="note"
          [actionResult$]="actionResult$"
          (action)="onEditorChange($event)"
        ></app-job-notes-editor>
      </div>
    </ng-container>
    <ng-template #noNotes>
      <ng-container *ngIf="isLoaded$ | async">
        <div class="w-full top-1/2 xs:text-lg md:text-4xl font-semibold text-center">
          <span class="text-lg" *ngIf="searchField.touched || searchField.dirty"> No result for your search.</span>
          <span class="text-lg"  *ngIf="!(searchField.touched || searchField.dirty)"
            > No notes yet. Select '+ Add Note' to create a note
          </span>
        </div>
      </ng-container>
    </ng-template>
  </div>

  <mat-paginator
    class="xs:absolute xs:inset-x-0 xs:bottom-0 border-b xs:border-t xs:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    [ngClass]="{ 'pointer-events-none': false }"
    showFirstLastButtons="true"
    [length]="totalCount$ | async"
    [pageIndex]="query.pageNumber - 1"
    [pageSize]="query.pageSize"
    (page)="paginatorChange($event)"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</div>
