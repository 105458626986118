import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import {
  AddSnippet,
  BaseIdName,
  PaginatedListOfSnippet,
  Snippet,
  SnippetArea,
  SnippetParameters,
  SnippetType,
} from '../../../shared/models';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnippetsService {
  constructor(private http: HttpClient) {}

  getSnippets(request: SnippetParameters): Observable<PaginatedListOfSnippet> {
    const url = `${environment.urls.api}/snippet/list`;
    return this.http.post<PaginatedListOfSnippet>(url, request);
  }

  getSnippetsListByTypeArea(params: SnippetParameters): Observable<Snippet[]> {
    const url = `${environment.urls.api}/snippet/list/${params.snippetType}/${params.snippetArea}`;
    return this.http.get<Snippet[]>(url);
  }

  getSnippetDefaultByTypeArea(params: SnippetParameters): Observable<Snippet> {
    const url = `${environment.urls.api}/snippet/default/${params.snippetType}/${params.snippetArea}`;
    return this.http.get<Snippet>(url);
  }

  deleteSnippet(id: string): Observable<Snippet> {
    const url = `${environment.urls.api}/snippet/${id}`;
    return this.http.delete<Snippet>(url);
  }

  addSnippet(params: AddSnippet) {
    const url = `${environment.urls.api}/snippet/add`;
    return this.http.post<Snippet>(url, params);
  }

  updateSnippet(params: AddSnippet) {
    const url = `${environment.urls.api}/snippet/save`;
    return this.http.put<Snippet>(url, params);
  }

  listForTypeAndAreaDefaultsMatrix() {
    const url = `${environment.urls.api}/snippet/list/matrix`;
    return this.http.get<any>(url);
  }

  getSnippetAreas(): Observable<BaseIdName[]> {
    const url = `${environment.urls.api}/snippet/list/areas`;
    return this.http.get<BaseIdName[]>(url).pipe(shareReplay(1));
  }

  getSnippetTypes(): Observable<BaseIdName[]> {
    const url = `${environment.urls.api}/snippet/list/types`;
    return this.http.get<BaseIdName[]>(url).pipe(shareReplay(1));
  }
}
