import {
    AsyncPipe,
    CurrencyPipe,
    DecimalPipe,
    Location,
    NgClass,
    NgFor,
    NgIf,
} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {
    MatAutocompleteModule,
    MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { InputMaskModule, createMask } from '@ngneat/input-mask';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import {
    AddRemovePriceListItemsDialogComponent,
    ConfigQuill,
    QuillEditorModalComponent,
    SendModalComponent,
} from 'app/shared/components';
import { CreateSupplierOrderComponent } from 'app/shared/components/create-supplier-order/create-supplier-order.component';
import {
    AddressLookup,
    LoggedInUser,
    OrderStatus,
    PriceListItem,
    SendJobOrder,
    SendJobOrderCallFor,
    SnippetArea,
    SnippetType,
    Supplier,
    TransactionHistory,
} from 'app/shared/models';
import { SendModalData } from 'app/shared/models/form-ui.models';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { LeavePageService } from 'app/shared/services/leave-page.service';
import { ToastService } from 'app/shared/services/toast.service';
import { SharedModule } from 'app/shared/shared.module';
import { AccountUserFacade } from 'app/shared/store/facades';
import { IntegrationsService } from 'app/views/settings/services/integration.service';
import { SnippetsService } from 'app/views/snippets/services/snippets.service';
import { uniqBy, isEqual } from 'lodash';
import moment from 'moment';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { Subject, timer } from 'rxjs';
import { filter, map, skip, take, takeUntil } from 'rxjs/operators';
import { PreviewComponent } from '../../../../shared/components/preview/preview.component';
import { AddressLookupDirective } from '../../../../shared/directives/address-lookup.directive';
import { GuidedTourService } from '../../../../shared/services/guided-tour.service';
import {
    GuidedTourNameEnum,
    IntroJsService,
} from '../../../../shared/services/introjs.service';
import { CurrentPriceListFacade } from '../../../price-list/store/facades';
import {
    JobOrderSendStatusParams,
    JobOrderStatus,
    JobOrderStatusParams,
} from '../../models/job-orders';
import { JobService } from '../../services/job.service';
import {
  JobDetailFacade,
  JobOrderDetailsActionTypes,
  JobOrderListActionTypes,
  JobOrderPriceListItemFacade,
  JobOrdersListFacade,
  SuppliersListByJobFacade, updateJobOrderStatusSuccess,
} from '../../store';
import { JobOrderDetailsFacade } from '../../store/facades/job-order-details.facade';
import { JobOrderItemsToOrderFacade } from '../../store/facades/job-order-items-to-order.facade';
import { CallForwardService } from '../job-detail/job-call-forward/services/call-forward.service';

enum OrderStatusCombo {
    MARK_AWAITING_DELIVERY = 'Awaiting delivery',
    RECEIVED = 'Received',
    DRAFT = 'Draft',
    VOID = 'Void',
}

enum OrderAction {
    Print,
    Preview,
    StatusChange,
    Send,
}

@Component({
    selector: 'app-job-order-details',
    templateUrl: './job-order-details.component.html',
    styleUrls: ['./job-order-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        NgIf,
        SharedModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgClass,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        AddressLookupDirective,
        MatDatepickerModule,
        MatMenuModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatTableModule,
        NgxTrimDirectiveModule,
        InputMaskModule,
        AsyncPipe,
        DecimalPipe,
        CurrencyPipe,
        SharedPipesModule,
    ],
})
export class JobOrderDetailsComponent implements OnInit, AfterViewInit {
    private unsubscribeAll: Subject<any> = new Subject();
    private unsubscribeByStatus: Subject<any> = new Subject();
    isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];

    jobOrderPriceListItemSuppliersQuery = {
        pageNumber: 1,
        pageSize: 5,
        jobOrderId: '',
    };

    statusComboList = [
        {
            name: OrderStatusCombo.MARK_AWAITING_DELIVERY,
            value: OrderStatus.Awaiting_Delivery,
        },
        {
            name: OrderStatusCombo.RECEIVED,
            value: OrderStatus.Received,
        },
        {
            name: OrderStatusCombo.DRAFT,
            value: OrderStatus.Draft,
        },
        {
            name: OrderStatusCombo.VOID,
            value: OrderStatus.Void,
        },
    ];

    orderStatus = OrderStatus;
    jobOrderId: string = '';
    isExtraOverOrder: boolean;
    isNew = true;
    jobId: string;
    filteredPriceListItems: PriceListItem[];

    displayedColumns: string[] = [
        'priceListCategoryName',
        'itemCode',
        'description',
        'supplierItemCode',
        'qty',
        'uom',
        'unitPrice',
        'gstRate',
        'unitTotal',
        'action',
    ];

    calledForOptions = [
        {
            name: 'Today',
            value: 0,
        },
        {
            name: 'In 7 days',
            value: 7,
        },
        {
            name: 'In 14 days',
            value: 14,
        },
        {
            name: 'In 30 days',
            value: 30,
        },
    ];

    dataSource;
    data;

    form: FormGroup;
    initialFormValue;
    isResetingState = false;

    tableForm = this.fb.group({
        listForm: new FormArray([this.fb.array([])]),
    });

    editRowIndex = null;

    savedPreviousItemValue = null;
    formattedAddress: string = null;

    pageSize: number = 5;
    pageNumber: number = 1;

    account: LoggedInUser;
    supplierSearchField = new FormControl('');
    supplierList;

    intermediateFormValueByStatusChange;
    intermediateOrderItemsByStatusChange;
    itemsToOrder;
    itemsToOrderFiltered;
    categories: { id: string; name: string }[];
    selectedCategoryId;

    prevStatus: string;

    selectedSupplier;
    syncEnabled = false;
    transactionHistory?: TransactionHistory;
    xeroDeepLink;

    currencyInputMask = createMask({
        parser: (v) => v.split(',').join(''),
        inputmode: 'numeric',
        alias: 'numeric',
        groupSeparator: ',',
        digits: 2,
        digitsOptional: false,
    });

    get calledForError() {
        let calledDate = this.form.get('calledDate')?.value;
        let calledForDate = this.form.get('calledFor')?.value;
        if (calledDate == null) {
            return false;
        }
        if (calledForDate == null) {
            return false;
        }
        return calledForDate < calledDate;
    }

    get callForMinDate() {
        let calledDate = this.form.get('calledDate').value as Date;
        if (calledDate == null) {
            return null;
        }
        const nextDay = moment(this.form.get('calledDate').value).toDate();
        return nextDay;
    }
    get listForm() {
        return this.tableForm.get('listForm') as FormArray;
    }

    get address() {
        return this.form.get('address');
    }

    get deliveryInstructions() {
        return this.form.get('deliveryInstructions');
    }

    get callForDate() {
        return this.form.get('callForDate');
    }
    get calledDate() {
        return this.form.get('calledDate');
    }

    get supplierId() {
        return this.form.get('supplierId');
    }
    get categoryId() {
        return this.form.get('categoryId');
    }

    get acknowledgedDate() {
        return this.form.get('acknowledgedDate');
    }

    get orderDate() {
        return this.form.get('created');
    }

    get hasChanges() {
        const res = (
            this.leavePageService.needSave &&
            ( !isEqual(this.form.value, this.initialFormValue) || this.tableForm.touched)
        );
        if (res) {
          console.log(this.leavePageService.needSave,isEqual(this.form.value, this.initialFormValue), this.tableForm.touched)
        }
        return res;
    }

    @ViewChild('table') table: MatTable<any>;
    @ViewChild('tableScroll') tableScroll: ElementRef;
    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
    autoComplete: MatAutocompleteTrigger;

    scrollEvent = (event: any): void => {
        if (this.autoComplete.panelOpen)
            // this.autoComplete.closePanel();
            this.autoComplete.updatePosition();
    };

    jobOrderListSuppliers$;
    intro;

    constructor(
        public jobOrderDetailsFacade: JobOrderDetailsFacade,
        public jobOrderPriceListItemFacade: JobOrderPriceListItemFacade,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private chRef: ChangeDetectorRef,
        public dialog: MatDialog,
        private confirm: FuseConfirmationService,
        public leavePageService: LeavePageService,
        private jobOrdersListFacade: JobOrdersListFacade,
        private actionsSubj: ActionsSubject,
        private jobService: JobService,
        private location: Location,
        private asyncPipe: AsyncPipe,
        public jobDetailFacade: JobDetailFacade,
        public af: AccountUserFacade,
        public suppliersListByJobFacade: SuppliersListByJobFacade,
        public jobOrderItemsToOrderFacade: JobOrderItemsToOrderFacade,
        private toast: ToastService,
        private snippetService: SnippetsService,
        private callforwardService: CallForwardService,
        private priceListFacade: CurrentPriceListFacade,
        private integrationService: IntegrationsService,
        private introJsService: IntroJsService,
        private guidedTourService: GuidedTourService,
        private snackbarService: MatSnackBar
    ) {}

    get selectedCategeoryValue(): string {
        let val = this.form.get('categoryId').value;
        if (!!val) {
            val = this.categories.filter((x) => x.id == val)[0].name;
        }
        return val;
    }

    onSupplierChange(supplier: Supplier) {
        if (this.selectedSupplier?.id === supplier.id) return;
        if (
            this.supplierSearchField.value &&
            !supplier.name.includes(this.supplierSearchField.value)
        )
            return;

        if (!this.selectedSupplier?.id) {
            this.updateSupplier(supplier);
        } else {
            this.changeSupplierOrCategoryDialog('Supplier').subscribe(
                (result) => {
                    if (result === 'confirmed') {
                        this.updateSupplier(supplier);
                    } else {
                        this.supplierSearchField.setValue(
                            this.selectedSupplier.name
                        );
                    }
                }
            );
        }
    }

    initForm() {
        this.form = this.fb.group({
            created: [moment().format()],
            orderCode: [],
            acknowledgedDate: [{ value: null, disabled: true }],
            address: [],
            callForDate: [],
            calledDate: [],
            reference: [],
            deliveryInstructions: [],
            supplierId: [''],
            categoryId: [''],
        });
    }

    updateSupplier(supplier) {
        this.selectedSupplier = supplier;
        this.supplierSearchField.setValue(supplier.name);
        this.supplierId.setValue(supplier.id);
    }

    getControlValue(value): string {
        if (value === null) {
            return null;
        }

        const date = typeof value === 'string' ? moment(value) : value;
        return date;
    }

    isDateAGreaterThanB(a, b): boolean {
        const dateA = this.getControlValue(a);
        const dateB = this.getControlValue(b);
        return moment(dateA).diff(dateB, 'days') > 0;
    }

    ngAfterViewInit(): void {
        this.introJsService.currentIntroPage$.next(GuidedTourNameEnum.JobOrder);

        this.form.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
                if (
                    !this.calledDate.value &&
                    (this.acknowledgedDate.enabled || this.callForDate.enabled)
                ) {
                    this.callForDate.setValue(null, {
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.callForDate.disable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.acknowledgedDate.setValue(null, {
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.acknowledgedDate.disable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
                if (!this.callForDate.value && this.acknowledgedDate.enabled) {
                    this.acknowledgedDate.setValue(null, {
                        onlySelf: true,
                        emitEvent: false,
                    });
                    this.acknowledgedDate.disable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
                this.form.markAsTouched();

                // validation rules
                // Called Date <= Called For Date & Acknowledged Date
                // Called For Date >= Called Date
                // Acknowledged >= Called Date
                if (
                    this.acknowledgedDate.value &&
                    this.calledDate.value &&
                    this.isDateAGreaterThanB(
                        this.calledDate.value,
                        this.acknowledgedDate.value
                    )
                ) {
                    this.acknowledgedDate.setErrors({ required: true });
                    this.calledDate.setErrors({ required: true });
                } else if (
                    this.calledDate.value &&
                    this.callForDate.value &&
                    this.isDateAGreaterThanB(
                        this.calledDate.value,
                        this.callForDate.value
                    )
                ) {
                    this.callForDate.setErrors({ required: true });
                    this.calledDate.setErrors({ required: true });
                } else if (
                  this.orderDate.value &&
                  this.calledDate.value &&
                  this.isDateAGreaterThanB(
                    this.orderDate.value,
                    this.calledDate.value
                  )
                ) {
                  this.calledDate.setErrors({ required: true });
                } else {
                    this.callForDate.setErrors(null);
                    this.calledDate.setErrors(null);
                    this.acknowledgedDate.setErrors(null);
                    return null;
                }
            });

        this.acknowledgedDate.valueChanges
            .pipe(skip(2), takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
                if (res === 'Invalid date') {
                    this.acknowledgedDate.setValue(null);
                }
            });
        this.orderDate.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
                if (res === 'Invalid date') {
                    this.orderDate.setValue(moment().format());
                }
            });

        this.callForDate.valueChanges
            .pipe(skip(2), takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
                if (!res) return;
                if (this.acknowledgedDate.disabled) {
                    this.acknowledgedDate.enable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
                if (!this.calledDate.value) {
                    this.calledDate.setValue(moment().format());
                }
            });

        this.calledDate.valueChanges
            .pipe(skip(2), takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
                if (!res) return;

                if (this.callForDate.disabled) {
                    this.callForDate.enable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
            });

        this.leavePageService.dialog = {
            title: 'Leave Page?',
            message: 'You have unsaved changes.',
            icon: {
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn',
            },
            actions: {
                cancel: { label: 'Cancel' },
                confirm: { label: 'Leave Page', color: 'warn' },
            },
        };

        this.route.params.subscribe((res) => {
            this.jobId = res.jobId;
            this.jobOrderId = res.id;
            this.isExtraOverOrder = res.extraOverOrder === 'true';
            if (this.jobId) {
                this.suppliersListByJobFacade.getSuppliersListByJob(this.jobId);

                // to fill up 'To supplier' control
                if (this.isExtraOverOrder) {
                    this.jobOrderListSuppliers$ =
                        this.jobService.getUniqueSuppliersListInPriceList();
                } else {
                    this.jobOrderListSuppliers$ =
                        this.jobService.joborderListSuppliersJob(this.jobId);
                }
            }

            if (this.jobOrderId) {
                this.isNew = false;
                this.chRef.detectChanges();
                this.jobOrderDetailsFacade.getJobsOrderDetails(this.jobOrderId);

                this.jobOrderDetailsFacade.isLoaded$
                    .pipe(skip(1), takeUntil(this.unsubscribeAll))
                    .subscribe((isLoaded) => {
                        if (!isLoaded) {
                            return;
                        }
                        this.jobOrderDetailsFacade.jobOrder$
                            .pipe(take(1))
                            .subscribe((res) => {
                                this.data = JSON.parse(JSON.stringify(res));
                                this.selectedSupplier = this.data.supplier;

                                this.prevStatus = this.data.status;
                                if (!this.jobId) {
                                    this.jobId = this.data.jobId;
                                    this.suppliersListByJobFacade.getSuppliersListByJob(
                                        this.jobId
                                    );
                                }

                                const unsubscribeSuppliersList: Subject<any> =
                                    new Subject();
                                this.suppliersListByJobFacade.items$
                                    .pipe(takeUntil(unsubscribeSuppliersList))
                                    .subscribe((data) => {
                                        if (data) {
                                            this.supplierList = data;
                                            unsubscribeSuppliersList.next(null);
                                            unsubscribeSuppliersList.complete();
                                        }
                                    });

                                if (this.selectedSupplier) {
                                    this.supplierSearchField.setValue(
                                        this.selectedSupplier.name
                                    );
                                }

                                if (this.data.address?.formattedAddress) {
                                    this.formattedAddress =
                                        this.data.address.formattedAddress;
                                }

                                this.form.patchValue(this.data);
                                this.initialFormValue = this.form.value;
                                this.formUpdate(this.data.orderItems);

                                this.integrationService
                                    .getTransactionHistoryByReferenceId(
                                        this.data?.orderCode
                                    )
                                    .subscribe((res) => {
                                        if (!res) return;
                                        this.transactionHistory = res;

                                        if (
                                            res.organisationShortCode &&
                                            res.externalReferenceId
                                        ) {
                                            this.xeroDeepLink = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${res.organisationShortCode}&redirecturl=/Accounts/Payable/PurchaseOrders/View/${res.externalReferenceId}/`;
                                        }
                                    });

                                if (this.intermediateFormValueByStatusChange) {
                                    this.initForm();
                                    this.form.patchValue(
                                        this.intermediateFormValueByStatusChange
                                    );
                                    if (this.supplierList?.length) {
                                        this.supplierSearchField.setValue(
                                            this.supplierList?.filter(
                                                (s) =>
                                                    this
                                                        .intermediateFormValueByStatusChange
                                                        .supplierId === s.id
                                            )[0].name
                                        );
                                    }
                                    this.supplierId.setValue(
                                        this.intermediateFormValueByStatusChange
                                            .supplierId
                                    );
                                    this.changeCategoryHandler();
                                    this.intermediateFormValueByStatusChange =
                                        null;
                                }
                                if (this.intermediateOrderItemsByStatusChange) {
                                    this.formUpdate(
                                        this
                                            .intermediateOrderItemsByStatusChange
                                    );
                                    this.data.orderItems =
                                        this.intermediateOrderItemsByStatusChange;
                                    this.intermediateOrderItemsByStatusChange =
                                        null;
                                }

                                this.form.markAsUntouched();
                                this.tableForm.markAsUntouched();

                                timer(0).subscribe(() => {
                                    this.form.valueChanges
                                        .pipe(
                                            take(1),
                                            takeUntil(this.unsubscribeByStatus)
                                        )
                                        .subscribe(() => {
                                          if ( this.isResetingState ) return;
                                          if (!isEqual(this.form.value, this.initialFormValue))  {
                                            this.leavePageService.needSave = true;
                                          }
                                        });
                                    this.tableForm.valueChanges
                                        .pipe(
                                            take(1),
                                            takeUntil(this.unsubscribeByStatus)
                                        )
                                        .subscribe(() => {
                                          if ( this.isResetingState ) return;
                                          this.leavePageService.needSave = true;
                                        });
                                    this.isResetingState = false;
                                });
                            });
                    });
            } else {
                if (!this.asyncPipe.transform(this.jobDetailFacade.job$)) {
                    this.jobDetailFacade.getJob(this.jobId);
                }
                this.jobDetailFacade.job$
                    .pipe(
                        filter(Boolean),
                        take(1),
                        takeUntil(this.unsubscribeByStatus)
                    )
                    .subscribe((data) => {
                        Object.keys(this.form.controls)
                            .filter(
                                (key) =>
                                    key !== 'supplierId' && key !== 'categoryId'
                            )
                            .forEach((key) => {
                                this.form.controls[key].disable({
                                    onlySelf: true,
                                    emitEvent: false,
                                });
                            });
                        if (data.address?.formattedAddress) {
                            this.formattedAddress =
                                data.address.formattedAddress;
                            this.address.patchValue(data.address);
                        }
                    });

                this.form.valueChanges.subscribe(
                    () => {
                      if (!isEqual(this.form.value, this.initialFormValue))  {
                          this.leavePageService.needSave = true
                      }
                    }
                );
                this.tableForm.valueChanges.subscribe(
                    () => (this.leavePageService.needSave = true)
                );
            }
        });

        this.supplierId.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {
                if (this.supplierId.value) {
                    if (this.isNew) {
                        Object.keys(this.form.controls)
                            .filter(
                                (key) =>
                                    key !== 'supplierId' && key !== 'categoryId'
                            )
                            .forEach((key) => {
                                this.form.controls[key].disable({
                                    onlySelf: true,
                                    emitEvent: false,
                                });
                            });
                    }

                    if (this.isExtraOverOrder) {
                        if (
                            !this.asyncPipe.transform(
                                this.priceListFacade.items$
                            )
                        ) {
                            this.priceListFacade.getCurrentPriceList();
                        }

                        this.jobService
                            .getCategoriesBySupplierId(this.supplierId.value)
                            .subscribe((cat: any[]) => {
                                this.categories = cat;
                                this.resetSelectedCategoryAndRecalculate();
                            });
                    }
                    if (!this.isExtraOverOrder) {
                        const query = {
                            jobId: this.jobId,
                            supplierId: this.supplierId.value,
                        };
                        this.jobOrderItemsToOrderFacade.getJobOrderItemsToOrder(
                            query
                        );

                        this.jobOrderItemsToOrderFacade.jobOrderItemsToOrder$
                            .pipe(skip(1), take(1))
                            .subscribe((data) => {
                                this.itemsToOrder = data;

                                if (this.isNew) {
                                    this.resetSelectedCategoryAndRecalculate();
                                }

                                this.categories = this.setupCategories();
                                this.setCategoryId();

                                this.buildFilteredItemsToOrder();

                                if (this.categories.length) {
                                    if (this.categoryId.disabled) {
                                        this.categoryId.enable();
                                    }
                                } else {
                                    this.toast.info(
                                        'There are no items to order for the chosen Supplier'
                                    );
                                }

                                this.chRef.detectChanges();
                            });
                    }
                } else {
                    this.itemsToOrder = [];
                    this.itemsToOrderFiltered = [];
                }
            });
        this.changeCategoryHandler();

        this.chRef.detectChanges();

        setTimeout(() => {
            this.introJsService.resetedTour$
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe(() => {
                    this.introInit();
                });
            this.guidedTourService
                .getGuidedTourShow(btoa(GuidedTourNameEnum.JobOrder))
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe((show) => {
                    if (show) {
                        this.introInit();
                    }
                });
        }, 1000);
    }

    introInit(): void {
        this.intro = this.introJsService.getIntro();
        this.intro.onexit(() => {
            this.guidedTourService
                .completeGuidedTour(btoa(GuidedTourNameEnum.JobOrder))
                .subscribe();
            this.intro.setOptions({});
            this.intro = null;
        });
        this.chRef.detectChanges();
        this.introJsService.jobOrder(this.intro, this.isReadOnlyMode);
    }

    setupCategories() {
        let categories = uniqBy(
            this.itemsToOrder.map((item) => ({
                id: item.priceListCategoryId,
                name: item.priceListCategoryName,
            })),
            (s: { id: string; name: string }) => s.id
        );

        if (!categories.length) {
            if (this.data?.orderItems?.length) {
                categories = [this.data.orderItems[0]].map((item) => ({
                    id: item.priceListCategoryId,
                    name: item.priceListCategoryName,
                }));
            }
        }

        if (
            this.data?.orderItems?.length &&
            !categories.find(
                (c) => c.id === this.data.orderItems[0].priceListCategoryId
            )
        ) {
            categories.push({
                id: this.data.orderItems[0].priceListCategoryId,
                name: this.data.orderItems[0].priceListCategoryName,
            });
        }

        return categories;
    }

    setCategoryId(): void {
        if (
            this.categories.length === 0 ||
            (this.categories.length > 1 && this.isNew)
        )
            return;
        if (this.categories.length === 1) {
            this.categoryId.setValue(this.categories[0].id, {
                onlySelf: true,
                emitEvent: false,
            });
        } else {
            this.categoryId.setValue(
                this.data.orderItems[0].priceListCategoryId,
                {
                    onlySelf: true,
                    emitEvent: false,
                }
            );
        }
        this.selectedCategoryId = this.categoryId.value;
        Object.keys(this.form.controls)
            .filter((key) => key !== 'supplierId' && key !== 'categoryId')
            .forEach((key) => {
                if (
                    (key === 'callForDate' || key === 'acknowledgedDate') &&
                    !this.calledDate.value
                ) {
                    return;
                }
                if (key === 'acknowledgedDate' && !this.callForDate.value) {
                    return;
                }
                this.form.controls[key].enable({
                    onlySelf: true,
                    emitEvent: false,
                });
            });
    }

    updateCategoryId(categoryId): void {
        this.selectedCategoryId = categoryId;

        if (this.isNew) {
            Object.keys(this.form.controls)
                .filter((key) => key !== 'supplierId' && key !== 'categoryId')
                .forEach((key) => {
                    if (
                        (key === 'callForDate' || key === 'acknowledgedDate') &&
                        !this.calledDate.value
                    ) {
                        return;
                    }
                    if (key === 'acknowledgedDate' && !this.callForDate.value) {
                        return;
                    }
                    this.form.controls[key].enable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                });
        }
        if (this.data?.orderItems) {
            this.data.orderItems = [];
            this.formUpdate(this.data.orderItems);
            this.reCalculateTotals();
        }
        if (this.isExtraOverOrder) {
        } else {
            this.buildFilteredItemsToOrder();
        }

        this.chRef.detectChanges();
    }

    resetSelectedCategoryAndRecalculate(): void {
        // reset category when the supplier changes
        if (this.selectedCategoryId) {
            this.selectedCategoryId = undefined;
            this.categoryId.setValue('', { onlySelf: true, emitEvent: false });
        }
        //reset orderItems when the supplier changes
        if (this.data?.orderItems) {
            this.data.orderItems = [];
            this.formUpdate(this.data.orderItems);
            this.reCalculateTotals();
        }
    }

    buildFilteredItemsToOrder(): void {
        this.itemsToOrderFiltered = this.itemsToOrder
            .filter(
                (supllierItem) =>
                    !this.data?.orderItems?.some(
                        (orderItem) => orderItem.id === supllierItem.id
                    )
            )
            .filter(
                (orderItem) =>
                    orderItem.priceListCategoryId === this.selectedCategoryId
            );
    }

    changeCategoryHandler(): void {
        this.categoryId.valueChanges
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((categoryId) => {
                if (!categoryId) return;
                if (!this.selectedCategoryId || !this.data?.orderItems.length) {
                    this.updateCategoryId(categoryId);
                    return;
                }
                this.changeSupplierOrCategoryDialog('Category').subscribe(
                    (result) => {
                        if (result === 'confirmed') {
                            this.updateCategoryId(categoryId);
                        } else {
                            this.categoryId.setValue(this.selectedCategoryId, {
                                onlySelf: true,
                                emitEvent: false,
                            });
                            // if  there are no changes besides changing the Category, then set needSave to false;
                            if (
                                this.tableForm.pristine &&
                                Object.keys(this.form.controls).filter(
                                    (key) => this.form.controls[key].dirty
                                ).length === 1
                            ) {
                                this.leavePageService.needSave = false;
                            }
                        }
                    }
                );
            });
    }

    changeSupplierOrCategoryDialog(item) {
        return this.confirm
            .open({
                title: `Change ${item}`,
                message: `Are you sure you want to change the ${item}? All added items will be removed.`,
                icon: {
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                actions: {
                    cancel: { label: 'Cancel' },
                    confirm: { label: 'OK', color: 'warn' },
                },
            })
            .afterClosed();
    }

    orderDateSetDefaultValue() {
        if (!this.orderDate.value) {
            this.orderDate.setValue(moment().format());
        }
    }

    changeSend(value: MatCheckbox) {
        if (value.checked) {
            this.updateJobOrderStatus('sent');
        } else {
            this.updateJobOrderStatus('notsent');
        }
    }

    ngOnInit(): void {
        this.initForm();
        window.addEventListener('scroll', this.scrollEvent, true);
        this.af.loggedInUser$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((res) => {
                (this.account = res),
                    (this.syncEnabled = res.accountingIntegrationActive);
            });

        this.actionsSubj
            .pipe(
                ofType(JobOrderListActionTypes.JobOrderDeleteFacadeSuccess),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(() =>
                this.jobOrderDetailsFacade.getJobsOrderDetails(this.jobOrderId)
            );
        this.actionsSubj
            .pipe(
                ofType(
                    JobOrderDetailsActionTypes.AddJobOrderDetailsFacadeSuccess
                ),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(() => {
                this.leavePageService.needSave = false;
                this.back();
            });
        this.actionsSubj
            .pipe(
                ofType(JobOrderListActionTypes.JobOrderDeleteFacadeSuccess),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe(() => {
                this.leavePageService.needSave = false;
                this.back();
            });
        this.actionsSubj
            .pipe(
                ofType(
                    JobOrderDetailsActionTypes.UpdateJobOrderSendStatusSuccess
                ),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe((r: { jobOrder: any }) => {
                this.data.sent = r.jobOrder.sent;
            });
        this.actionsSubj
            .pipe(
                ofType(
                    JobOrderDetailsActionTypes.UpdateJobOrderStatusFacadeSuccess
                ),
                takeUntil(this.unsubscribeAll)
            )
            .subscribe((r: { jobOrder: any }) => {
                this.data.status = r.jobOrder.status;
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all observables
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
        this.unsubscribeByStatus.next(null);
        this.unsubscribeByStatus.complete();

        this.jobOrderDetailsFacade.clearJobOrderDetailsState();
        this.jobOrderItemsToOrderFacade.clearJobOrderItemsToOrder();
    }

    formUpdate(data) {
        this.listForm.clear();
        data.forEach((element) => {
            const control: FormGroup = this.fb.group({
                description: [null, [Validators.required]],
                qty: [
                    null,
                    [Validators.required, Validators.min(Number.MIN_VALUE)],
                ],
                unitPrice: [
                    null,
                    [Validators.required, Validators.min(Number.MIN_VALUE)],
                ],
                id: [null],
            });
            control.patchValue(element);
            this.listForm.push(control);
        });
    }

    deleteOrder() {
        this.confirm
            .open({
                title: 'Delete Order',
                message:
                    'Are you sure you want to delete this order? This action cannot be undone.',
                icon: {
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                actions: {
                    cancel: { label: 'Cancel' },
                    confirm: { label: 'Delete', color: 'warn' },
                },
            })
            .afterClosed()
            .pipe(filter((result) => result === 'confirmed'))
            .subscribe(() =>
                this.jobOrdersListFacade.deleteJobsOrder(this.jobOrderId)
            );
    }

    removeItem(index) {
        this.confirm
            .open({
                title: 'Delete order item',
                message: 'Are you sure you want to delete this item?',
                icon: {
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                actions: {
                    cancel: { label: 'Cancel' },
                    confirm: { label: 'Delete', color: 'warn' },
                },
            })
            .afterClosed()
            .pipe(filter((result) => result === 'confirmed'))
            .subscribe(() => {
                this.data = JSON.parse(JSON.stringify(this.data));
                const removedItem = this.data.orderItems[index];
                removedItem.supplier = this.selectedSupplier;
                if (this.itemsToOrderFiltered) {
                    const existingFilteredItem =
                        this.itemsToOrderFiltered?.find(
                            (orderItem) => orderItem.id === removedItem.id
                        );
                    if (existingFilteredItem) {
                        existingFilteredItem.qty += removedItem.qty;
                    } else {
                        this.itemsToOrderFiltered.push(removedItem);
                    }
                }

                this.data.orderItems.splice(index, 1);

                this.listForm.removeAt(index);
                this.reCalculateTotals();

                // triggeer Cancel and Save buttons to update their states
                this.tableForm.markAllAsTouched();
                this.leavePageService.needSave = true;
                this.chRef.detectChanges();
            });
    }

    sync() {
        if (this.form.valid && this.tableForm.valid) {
            this.editRowIndex = null;
            this.data = { ...this.data, ...this.form.value };
            //delete this.data.supplier;

            if (!this.isNew) {
                this.jobService.syncJobOrder(this.data.id).subscribe(
                    (r) => {
                        if (r === 10) {
                            this.snackbarService.open(
                                'Order has been synced successfully',
                                'Close'
                            );

                            this.integrationService
                                .getTransactionHistoryByReferenceId(
                                    this.data?.orderCode
                                )
                                .subscribe((res) => {
                                    this.transactionHistory = res;
                                    this.xeroDeepLink = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${res.organisationShortCode}&redirecturl=/Accounts/Payable/PurchaseOrders/View/${res.externalReferenceId}/`;
                                });
                        } else if (r === 20) {
                            this.snackbarService.open(
                                'Order has failed to sync - check the integration summary under issues for more details',
                                'Close'
                            );
                        }
                    },
                    (err) => {
                        this.snackbarService.open(
                            'Order has failed to sync - check the integration summary under issues for more details',
                            'Close'
                        );
                    }
                );
            }
        }
    }

    saveChanges() {
        if (this.form.valid && this.tableForm.valid) {
            this.editRowIndex = null;
            this.data = { ...this.data, ...this.form.getRawValue() };
            delete this.data.supplier;
            delete this.data.categoryId;
            this.data.priceListCategoryCode =
                this.data.orderItems[0].priceListCategoryCode;
            this.data.priceListCategoryId =
                this.data.orderItems[0].priceListCategoryId;
            this.data.priceListCategoryName =
                this.data.orderItems[0].priceListCategoryName;
            this.data.priceListCategoryOrder =
                this.data.orderItems[0].priceListCategoryOrder;
            this.data.orderItems.forEach((i) => {
                if (i.hasOwnProperty('jobCostingId')) {
                    delete i.jobCostingId;
                }
                if (i.hasOwnProperty('jobCostingSectionId')) {
                    delete i.jobCostingSectionId;
                }
                if (i.hasOwnProperty('supplier')) {
                    i.supplier = null;
                }
            });

            if (!this.isNew) {
                this.jobOrderDetailsFacade.updateJobsOrderDetails(this.data);
            } else {
                this.data.jobId = this.jobId;
                this.data.status = OrderStatus.Draft;
                this.jobOrderDetailsFacade.addJobsOrderDetails(this.data);
            }
            this.unsubscribeByStatus.next(null);
            this.leavePageService.needSave = false;
        } else {
            this.form.markAllAsTouched();
            this.tableForm.markAllAsTouched();
        }
    }

    discardChanges() {
        this.dataSource = this.dataSource.map((element) => {
            return element;
        });
        this.formUpdate(this.dataSource);
        this.editRowIndex = null;
        this.table.renderRows();
    }

    editItem(index) {
        if (this.editRowIndex && this.listForm.at(this.editRowIndex).valid) {
            this.savedPreviousItemValue = this.listForm.at(index).value;
            this.editRowIndex = index;
        }
        if (!this.editRowIndex && this.editRowIndex !== 0) {
            this.savedPreviousItemValue = this.listForm.at(index).value;
            this.editRowIndex = index;
        }
        if (
            this.editRowIndex === 0 &&
            this.listForm.at(this.editRowIndex).valid
        ) {
            this.savedPreviousItemValue = this.listForm.at(index).value;
            this.editRowIndex = index;
        }
    }

    saveItemChanges(item, index) {
        if (this.listForm.at(index).valid) {
            if (this.checkChangedValue(index)) {
                this.editRowIndex = null;
                return;
            }
            this.data = { ...this.data };
            this.data.orderItems = [...this.data.orderItems];
            const { value } = this.listForm;
            this.data.orderItems[index] = {
                ...item,
                ...{
                    qty: +value[index].qty,
                    unitPrice: +value[index].unitPrice,
                },
            };

            this.data.orderItems[index].unitTotal =
                this.data.orderItems[index].unitPrice *
                this.data.orderItems[index].qty;
            this.editRowIndex = null;
            this.reCalculateTotals();
        } else {
            this.listForm.at(index).markAllAsTouched();
            this.listForm.at(index).markAsDirty();
        }
    }

    discardItemChanges(index) {
        if (this.checkChangedValue(index)) {
            this.editRowIndex = null;
            return;
        }
        this.listForm.at(index).patchValue(this.savedPreviousItemValue);
        this.editRowIndex = null;
    }

    checkChangedValue(index) {
        return (
            this.savedPreviousItemValue.qty ===
                this.listForm.at(index).value.qty &&
            this.savedPreviousItemValue.unitPrice ===
                +this.listForm.at(index).value.unitPrice
        );
    }

    addressSelected(address: AddressLookup): void {
        this.leavePageService.needSave = true;
        this.address.patchValue(address, { emitEvent: false });
        this.address.markAsDirty();
        this.chRef.detectChanges();
    }

    callForDateUpdate(value) {
        this.callForDate.setValue(
            moment(Date.now()).startOf('day').add(value, 'days').format()
        );
        this.callForDate.updateValueAndValidity();
    }

    sendOrderModal(callForEmail = false) {
        if (this.leavePageService.needSave && this.data.totalAmount) {
            this.saveBeforeAction(OrderAction.Send);
            return;
        }
      const snippetContent$ = this.snippetService.getSnippetDefaultByTypeArea({
        snippetType: SnippetType.Order,
        snippetArea: SnippetArea.Email_Body,
      });
        const data: SendModalData = {
            title: 'Send Order',
            from: this.account.account.email,
            message: `<p>Hi ${this.selectedSupplier?.person.firstName},</p> <br> <p>Please find attached purchase order.</p> <br> <p>Any questions please let us know.</p> <br> <p>Thanks</p> <p>${this.account.user.person.firstName} ${this.account.user.person.lastName}</p> <p>${this.account.account.name}</p>`,
            subject: `Purchase order ${this.data.orderCode} from ${this.account.account.name} for ${this.selectedSupplier?.person.fullName}`,
            to: this.selectedSupplier?.person.email
                ? [this.selectedSupplier?.person.email]
                : null,
            quillOptions: {
                snippetsParams: {
                    snippetArea: SnippetArea.Email_Body,
                    snippetType: SnippetType.Order,
                },
              snippetContent$
            },
            tags: {
                '{Name}': this.selectedSupplier?.person.firstName,
                '{AccountFullName}': `${this.account.user.person.firstName} ${this.account.user.person.lastName}`,
                '{AccountName}': `${this.account.account.name}`,
            },
            jobId: this.jobId,
        };

        if (callForEmail) {
            data.quillOptions.snippetsParams.snippetArea =
                SnippetArea.Call_For_Email_Body;
            data.title = 'Send Call For Order';
            const snippet$ = this.snippetService.getSnippetDefaultByTypeArea({
                snippetType: 50,
                snippetArea: 70,
            });
            const deliveryInstructions$ = this.callforwardService
                .getDeliveryInstructions(this.jobOrderId)
                .pipe(
                    map((r: string) => {
                        const data: ConfigQuill = {
                            title: 'Delivery Note',
                            titleClass:
                                'text-4xl font-extrabold tracking-tight',
                            content: r,
                            noRequired: true,
                            minlength: 1,
                        };
                        return data;
                    })
                );
            data.callFor = {
                calledFor: this.callForDate.value,
                minDate: this.orderDate.value,
                snippet$,
                deliveryInstructions$,
                calledForMenuOptions: this.calledForOptions,
            };
        }

        const dialogRef = this.dialog.open(SendModalComponent, {
            width: '720px',
            maxHeight: '70vh',
            data: data,
            panelClass: 'app-send-modal',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let payload: SendJobOrder = {
                    jobOrderId: this.jobOrderId,
                    emailTo: result.to.join(';'),
                    message: result.message,
                    subject: result.subject,
                    sendCopy: !!result.sendMe,
                    attachments: result.attachments ? result.attachments : null,
                };

                let entity = 'Order';

                if (callForEmail) {
                    this.callForDate.patchValue(result.calledFor);
                    this.deliveryInstructions.setValue(
                        result.deliveryInstructions
                    );
                    payload = {
                        ...payload,
                        calledFor: result.calledFor,
                        deliveryInstructions: result.deliveryInstructions,
                    } as SendJobOrderCallFor;
                    entity = 'Call For Order';

                    this.saveChanges();
                    this.jobService.sendJobOrderCallFor(payload).subscribe(
                        () => {
                            this.toast.success('Sent successfully');
                        },
                        () => {
                            this.toast.error(`Sending the ${entity} failed`);
                        }
                    );
                    return;
                } else  {
                  // this.unsubscribeByStatus.pipe(take(1)).subscribe(() => {
                    this.jobService.jobOrderSendemail(payload).subscribe(
                      () => {
                        this.toast.success('Sent successfully');
                        // this.jobOrderDetailsFacade.getJobsOrderDetails(
                        //   this.jobOrderId
                        // );
                        this.data.sent = true;

                      },
                      () => {
                        this.toast.error(`Sending the ${entity} failed`);
                      }
                    );
                  // });
                // this.unsubscribeByStatus.next(null);
                }


            }
        });
    }

    createSupplierOrder() {
        const dialogRef = this.dialog.open(CreateSupplierOrderComponent, {
            width: '80vw',
            maxHeight: '85vh',
            height: 'inherit',
            data: this.itemsToOrderFiltered,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.length) {
                result = [
                    ...result.map((r: any) => {
                        r = { ...r };
                        r.jobOrderId = this.jobOrderId;
                        r.supplier = null;
                        r.jobCostingPriceListItemId = r.id;
                        return r;
                    }),
                ];
                this.data = { ...this.data };
                if (!this.data.orderItems) {
                    this.data.orderItems = result;
                } else {
                    this.data.orderItems = [...this.data.orderItems, ...result];
                }

                this.buildFilteredItemsToOrder();

                this.formUpdate(this.data.orderItems);
                this.reCalculateTotals();

                timer(2)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.tableScroll.nativeElement.scrollTop =
                            this.tableScroll.nativeElement.scrollHeight;
                    });
                this.chRef.detectChanges();
            }
        });
    }

    addFromItemList() {
        this.dialog
            .open(AddRemovePriceListItemsDialogComponent, {
                data: {
                    items: this.data?.orderItems || [],
                    selectedSupplier: this.selectedSupplier,
                    selectedCategory: this.categories.find(
                        (c) => c.id === this.selectedCategoryId
                    ),
                },
            })
            .afterClosed()
            .subscribe((r) => {
                if (!r) {
                    return;
                }
                if (r.added.length) {
                    if (!this.data) {
                        this.data = { orderItems: [] };
                    }
                    this.data.orderItems = this.data.orderItems.concat(r.added);
                }
                if (r.removed.length) {
                    if (!this.data) {
                        return;
                    }
                    const removedIds = r.removed.map((i) => i.priceListItemId);
                    this.data.orderItems = this.data.orderItems.filter(
                        (i) => !removedIds.includes(i.priceListItemId)
                    );
                }

                this.formUpdate(this.data.orderItems);
                this.reCalculateTotals();
                this.chRef.detectChanges();
            });
    }

    editInstructionDialog(): void {
        if (this.data?.status !== this.orderStatus.Draft && !this.isNew) {
            return;
        }

        const snippetParams = {
            snippetArea: SnippetArea.Delivery_Note,
            snippetType: SnippetType.Order,
        };

        const data: ConfigQuill = {
            content: this.deliveryInstructions.value,
            title: 'Delivery instructions',
            noRequired: true,
            minlength: 0,
            maxlength: 512,
            snippetsParams: snippetParams,
        };
        const dialogRef = this.dialog.open(QuillEditorModalComponent, {
            width: '700px',
            data: data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== undefined) {
                this.deliveryInstructions.setValue(result);
                this.chRef.detectChanges();
            }
        });
    }

    reCalculateTotals() {
        let subTotal = this.data.orderItems.reduce(
            (a, b) => a + b.unitPrice * b.qty,
            0
        );
        let gstAmount = subTotal * 0.1;
        let totalAmount = subTotal + gstAmount;

        this.data.subTotal = subTotal;
        this.data.gstAmount = gstAmount;
        this.data.totalAmount = totalAmount;
    }

    stopProp(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    back() {
        this.leavePageService.needSave = this.hasChanges;
        if (this.router.navigated) {
            this.location.back();
        } else {
            this.router.navigate(['/jobs']);
        }
    }

    formatDate(controlName) {
        this.form
            .get(controlName)
            .setValue(
                this.form.get(controlName).value
                    ? moment(this.form.get(controlName).value).format()
                    : null
            );
    }

    saveBeforeAction(
        action: OrderAction,
        event?: {
            name: string;
            value: OrderStatus;
        }
    ) {
        this.confirm
            .open({
                title: 'Save changes?',
                message: 'You have unsaved changes.',
                icon: {
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                actions: {
                    cancel: { label: 'Cancel' },
                    confirm: { label: 'Save', color: 'warn' },
                },
            })
            .afterClosed()
            .pipe(filter((result) => result === 'confirmed'))
            .subscribe(() => {
                // this.unsubscribeByStatus
                //     .pipe(take(1))
                //     .subscribe(() =>
                      this.saveChanges()
                    // );
                if (this.form.valid && this.tableForm.valid) {
                    this.actionsSubj
                        .pipe(
                            ofType(
                                JobOrderDetailsActionTypes.UpdateJobOrderDetailsFacadeSuccess
                            ),
                            take(1)
                        )
                        .subscribe(() => {
                            this.leavePageService.needSave = false;
                            switch (action) {
                                case OrderAction.StatusChange:
                                    timer(0).subscribe(() =>
                                        this.changeStatus(event)
                                    );
                                    break;
                                case OrderAction.Preview:
                                    this.onPreview();
                                    break;
                                case OrderAction.Print:
                                    this.downloadPdf();
                                    break;
                                case OrderAction.Send:
                                    this.sendOrderModal();
                                    break;
                            }
                        });
                } else {
                    this.form.markAllAsTouched();
                    this.tableForm.markAllAsTouched();
                }
            });
    }

    changeStatus(e: { name: string; value: OrderStatus }) {
      // prevent changing status if users picked the same status as the current one
      if (e.value === this.data.status) {
        return;
      }
      if (!this.form.valid || !this.tableForm.valid) {
        this.form.markAllAsTouched();
        this.tableForm.markAllAsTouched();
        return;
      }
      if (this.leavePageService.needSave && this.data.totalAmount) {
          this.saveBeforeAction(OrderAction.StatusChange, e);
          return;
      }

      const jobOrderStatusParams: JobOrderStatusParams = {
          id: this.jobOrderId,
          type: JobOrderStatus[e.name],
      };

      this.jobOrderDetailsFacade.updateJobOrderStatus(jobOrderStatusParams);
      this.leavePageService.needSave = false;
    }

    updateJobOrderStatus(type: 'sent' | 'notsent') {
        let request: JobOrderSendStatusParams = {
            id: this.jobOrderId,
            type: type,
        };
        this.jobOrderDetailsFacade.updateJobOrderSendStatus(request);
    }

    downloadPdf() {
        if (this.hasChanges) {
            this.saveBeforeAction(OrderAction.Print);
            return;
        }
        this.jobService.previewJobOrder(this.jobOrderId).subscribe(
            (r: any) => {
                const source = `data:application/pdf;base64,${r}`;
                const link = document.createElement('a');
                link.href = source;
                link.download = this.pdfFileName;
                link.click();
                link.remove();
            },
            (error) => {}
        );
    }

    onPreview(): void {
        if (this.hasChanges) {
            this.saveBeforeAction(OrderAction.Preview);
            return;
        }
        this.jobService.previewJobOrder(this.jobOrderId).subscribe(
            (r: any) => {
                const dialogRef = this.dialog.open(PreviewComponent, {
                    width: '900px',
                    data: {
                        generatedFile: r,
                        fileName: this.pdfFileName,
                    },
                });
            },
            (error) => {}
        );
    }

    cancel() {
        this.confirm
            .open({
                title: 'Discard Changes',
                message:
                    'Are you sure you want to discard the changes? All changes will be lost.',
                icon: {
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                actions: {
                    cancel: { label: 'Cancel' },
                    confirm: { label: 'Discard', color: 'warn' },
                },
            })
            .afterClosed()
            .pipe(filter((result) => result === 'confirmed'))
            .subscribe(() => {
                this.jobOrderDetailsFacade.getJobsOrderDetails(this.jobOrderId);
                this.leavePageService.needSave = false;
                this.isResetingState = true;
            });
    }

    get pdfFileName() {
        return `Order ${this.data.orderCode} for ${this.data.job.jobCode} - ${
            this.data.job.jobTitle
        } - ${new Date().toISOString().split('T')[0]}.pdf`;
    }
}
