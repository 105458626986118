import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigQuill } from 'app/shared/components';
import { SnippetArea, SnippetType } from 'app/shared/models';
import { LeavePageService } from 'app/shared/services/leave-page.service';
import { QuoteFormService } from 'app/views/estimates/services/quote-form.service';
import { Subject } from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';
import { QuillEditorElementComponent } from '../../../../../shared/components/quill-editor-element/quill-editor-element.component';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'app-quote-footer',
  templateUrl: './quote-footer.component.html',
  styleUrls: ['./quote-footer.component.scss'],
  standalone: true,
  imports: [MatCardModule, SharedModule, FormsModule, ReactiveFormsModule],
})
export class JobQuoteFooterComponent implements OnInit, OnDestroy {
  configQuill: ConfigQuill = {
    snippetsParams: {
      snippetArea: SnippetArea.Instructions,
      snippetType: SnippetType.Quote,
    },
  };
  control = this.fb.control(null);
  unsubscribeAll$ = new Subject<void>();

  constructor(
    public quoteFormService: QuoteFormService,
    private fb: FormBuilder,
    public leavePageService: LeavePageService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  ngOnInit(): void {
    this.quoteFormService.formUpdated$
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((r) => {
        if (
          this.quoteFormService.quoteForm.get('footer').value !==
          this.control.value
        ) {
          this.control.setValue(
            this.quoteFormService.quoteForm.get('footer').value,
          );
        }

        if (this.quoteFormService.quoteForm.get('footer').disabled) {
          setTimeout(() => {
            this.control.disable();
            this.changeDetectorRef.detectChanges();
          });
          this.configQuill.snippetsParams = null;
        }

        this.control.valueChanges
          .pipe(debounceTime(500), takeUntil(this.unsubscribeAll$))
          .subscribe((value) => {
            if (this.quoteFormService.quoteForm.get('footer').disabled) {
              return;
            }
            if (this.quoteFormService.quoteForm.get('footer').value !== value) {
              this.quoteFormService.quoteForm.get('footer').patchValue(value);
            }
          });
      });
  }
}
