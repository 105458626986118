import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import {
  currentPriceListReducer,
  CurrentPriceListState,
} from './current-price-list.reducer';
import {
  currentItemPriceCheckReducer,
  ItemPriceCheckState,
} from './item-price-check.reducer';

export interface PriceListState {
  currentPriceList: CurrentPriceListState;
  itemPriceCheck: ItemPriceCheckState;
}

export const reducers: ActionReducerMap<PriceListState> = {
  currentPriceList: currentPriceListReducer,
  itemPriceCheck: currentItemPriceCheckReducer,
};
export const selectPriceListState =
  createFeatureSelector<PriceListState>('price-list');
