import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { filter, Observable, Subject, take } from 'rxjs';
import { Job, VariationParameters } from '@shared/models';
import { JobDetailFacade } from '@app/views/jobs/store';
import { JobCostingFacade } from '@app/views/jobs/store/facades/job-costing.facade';

@Component({
  selector: 'app-job-variations-container',
  templateUrl: './job-variations-container.component.html',
  styleUrls: ['./job-variations-container.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class JobVariationsContainerComponent implements OnInit, OnDestroy {

  job$: Observable<Job> = this.jobDetailFacade.job$.pipe(filter((job) => !!job));
  unsubscribeAll$ = new Subject<void>();

  public query: VariationParameters = {
    pageNumber: 1,
    pageSize: 999,
    orderBy: [{ orderBy: 'code', descending: true }],
    filter: '',
    includeDeleted: false,
  };

  constructor(
    private router: Router,
    private jobDetailFacade: JobDetailFacade,
    public jobCostingFacade: JobCostingFacade,
  ) {}

  ngOnInit(): void {
    this.job$
      .pipe(
        filter((job) => !!job),
        take(1),
      )
      .subscribe((job) => {
        // fetch job variations each time to make a decision we can show 'Add Variation' button
        // based on current variation statuses
        this.query = {...this.query, jobId: job.id};
        this.jobCostingFacade.getJobVariation(this.query);
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe((event:NavigationEnd) =>  {
        if (event.url.endsWith('variations/list')) {
          this.jobCostingFacade.getJobVariation(this.query);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
