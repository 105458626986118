<div class="container mr-auto mt-4">
  <div class="flex items-center mb-2">
    <form (ngSubmit)="onSubmit()" novalidate>
      <button
        class="rounded-4xl"
        [color]="'primary'"
        [disabled]="isReadOnly"
        mat-flat-button
        type="submit"
      >
        <span>Update Billing Info</span>
      </button>
    </form>

    <button
      class="rounded-4xl ml-4 hover:bg-primary-600 text-slate-700 dark:text-white hover:text-white hover:dark:bg-primary-700"
      [disabled]="isReadOnly"
      (click)="handleOnChangePlanClicked($event)"
      mat-stroked-button
      type="button"
      mat-flat-button
    >
      <span>{{accountCancelled ?  'Subscribe' : 'Change Plan'}}</span>
    </button>

    <button
      class="rounded-4xl ml-4 hover:bg-primary-600 text-slate-700 dark:text-white hover:text-white hover:dark:bg-primary-700"
      [disabled]="isReadOnly"
      (click)="handleOnManageExtraSeats($event)"
      mat-stroked-button
      type="button"
      mat-flat-button
    >
      <span>Remove Extra Seat</span>
    </button>

  </div>
</div>
