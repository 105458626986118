import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  addJobOrderPriceListItem,
  clearJobOrderPriceListItemState,
  deleteJobOrderPriceListItem,
  getJobOrderPriceListItem,
  updateJobOrderPriceListItem,
} from '../actions';
import { JobState } from '../reducers';
import {
  selectJobOrderPriceListItem,
  selectJobOrderPriceListItemError,
  selectJobOrderPriceListItemIsLoaded,
  selectJobOrderPriceListItemIsLoading,
  selectJobOrderPriceListItemPageNumber,
  selectJobOrderPriceListItemTotalCount,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobOrderPriceListItemFacade {
  isLoading$ = this.store.select(selectJobOrderPriceListItemIsLoading);
  isLoaded$ = this.store.select(selectJobOrderPriceListItemIsLoaded);
  error$ = this.store.select(selectJobOrderPriceListItemError);
  jobOrderPriceList$ = this.store.select(selectJobOrderPriceListItem);
  totalCount$ = this.store.select(selectJobOrderPriceListItemTotalCount);
  pageIndex$ = this.store.select(selectJobOrderPriceListItemPageNumber);

  constructor(private store: Store<JobState>) {}

  getJobOrderPriceListItem(request: any) {
    this.store.dispatch(getJobOrderPriceListItem({ request }));
  }

  updateJobOrderPriceListItem(request: any) {
    this.store.dispatch(updateJobOrderPriceListItem({ request }));
  }

  addJobOrderPriceListItem(request: any) {
    this.store.dispatch(addJobOrderPriceListItem({ request }));
  }

  deleteJobOrderPriceListItem(request: any) {
    this.store.dispatch(deleteJobOrderPriceListItem({ request }));
  }

  clearJobOrderPriceListItemState() {
    this.store.dispatch(clearJobOrderPriceListItemState());
  }
}
