import { createAction, props } from '@ngrx/store';
import { BillingSettings } from 'app/shared/models';

export enum BillingSettingsActionTypes {
  GetBillingSettings = '[BillingSettings] Get BillingSettings',
  GetBillingSettingsSuccess = '[BillingSettings API] Get BillingSettings Success',
  GetBillingSettingsFailure = '[BillingSettings API] Get BillingSettings Failure',
}

export const getBillingSettings = createAction(
  BillingSettingsActionTypes.GetBillingSettings,
);

export const getBillingSettingsSuccess = createAction(
  BillingSettingsActionTypes.GetBillingSettingsSuccess,
  props<{ settings: BillingSettings }>(),
);

export const getBillingSettingsFailure = createAction(
  BillingSettingsActionTypes.GetBillingSettingsFailure,
  props<{ error: any }>(),
);
