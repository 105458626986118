import { createAction, props } from '@ngrx/store';
import {
  AddTeamMember,
  SettingsTeamMemberAccessLevelUpdate,
  SettingsTeamMemberDelete,
  SettingsTeamMemberMergedRoles,
  SettingsTeamMemberModifyRoles,
  TeamMember,
  UserRole,
} from 'app/shared/models/eazimate.models';
import {
  AccountTeamMember,
  TeamMemberData,
} from 'app/shared/models/form-ui.models';

export enum TeamSettingsActionTypes {
  getTeamAction = '[Team Settings] Get Team',
  getTeamSuccessAction = '[Team Settings API] Get Team Success',
  getTeamFailureAction = '[Team Settings API] Get Team Failure',

  getAvailableSeats = '[Team Settings] Get Available Seats',
  getAvailableSeatsSuccess = '[Team Settings API] Get Available Seats Success',
  getAvailableSeatsFailure = '[Team Settings API] Get Available Seats Failure',

  addExtraSeat = '[Team Settings] Add Extra Seat',
  addExtraSeatSuccess = '[Team Settings API] Add Extra Seat Success',
  addExtraSeatFailure = '[Team Settings API] Add Extra Seat Failure',

  inviteTeamMemberAction = '[Team Settings] Invite Team Member',
  inviteTeamMemberSuccessAction = '[Profile Settings API] Invite Team Member Success',
  inviteTeamMemberFailureAction = '[Profile Settings API] Invite Team Member Failure',

  updateTeamMemberRoleAction = '[Team Settings] Update Team Member Role',
  updateTeamMemberRoleSuccessAction = '[Profile Settings API] Update Team Member Role Success',
  updateTeamMemberRoleFailureAction = '[Profile Settings API] Update Team Member Role Failure',

  removeTeamMemberAction = '[Team Settings] Remove Team Member',
  removeTeamMemberSuccessAction = '[Profile Settings API] Remove Team Member Success',
  removeTeamMemberFailureAction = '[Profile Settings API] Remove Team Member Failure',

  getCommonTeamMemberData = '[Team Members] Get Common Member Data',
  getCommonTeamMemberDataSuccess = '[Team Members API] Get Common Member Data Success',
  getCommonTeamMemberDataFailure = '[Team Members API] Get Common Member Data Failure',

  getSelectedTeamMemberMergedRoles = '[Team Members] Get Selected Team Member Merged Roles',
  getSelectedTeamMemberMergedRolesSuccess = '[Team Members API] Get Selected Team Member Merged Roles Success',
  getSelectedTeamMemberMergedRolesFailure = '[Team Members API] Get Selected Team Member Merged Roles Failure',

  updateTeamMemberAccessLevel = '[Team Members] Update Team Member Access Level',
  updateTeamMemberAccessLevelSuccess = '[Team Members API] Update Team Member Access Level Success',
  updateTeamMemberAccessLevelFailure = '[Team Members API] Update Team Member Access Level Failure',

  updateTeamMemberRoles = '[Team Members] Update Team Member Roles',
  updateTeamMemberRolesSuccess = '[Team Members] Update Team Member Roles Success',
  updateTeamMemberRolesFailure = '[Team Members] Update Team Member Roles Failure',
}

export const updateTeamMemberRoles = createAction(
  TeamSettingsActionTypes.updateTeamMemberRoles,
  props<{ payload: SettingsTeamMemberModifyRoles }>(),
);

export const updateTeamMemberRolesSuccess = createAction(
  TeamSettingsActionTypes.updateTeamMemberRolesSuccess,
  props<{ payload: TeamMember[] }>(),
);

export const updateTeamMemberRolesFailure = createAction(
  TeamSettingsActionTypes.updateTeamMemberRolesFailure,
  props<{ payload: any }>(),
);

export const updateTeamMemberAccessLevel = createAction(
  TeamSettingsActionTypes.updateTeamMemberAccessLevel,
  props<{ payload: SettingsTeamMemberAccessLevelUpdate }>(),
);

export const updateTeamMemberAccessLevelSuccess = createAction(
  TeamSettingsActionTypes.updateTeamMemberAccessLevelSuccess,
  props<{ payload: TeamMember[] }>(),
);

export const updateTeamMemberAccessLevelFailure = createAction(
  TeamSettingsActionTypes.updateTeamMemberAccessLevelFailure,
  props<{ payload: any }>(),
);

export const getSelectedTeamMemberMergedRoles = createAction(
  TeamSettingsActionTypes.getSelectedTeamMemberMergedRoles,
  props<{ payload: SettingsTeamMemberMergedRoles }>(),
);

export const getSelectedTeamMemberMergedRolesSuccess = createAction(
  TeamSettingsActionTypes.getSelectedTeamMemberMergedRolesSuccess,
  props<{ payload: UserRole[] }>(),
);

export const getSelectedTeamMemberMergedRolesFailure = createAction(
  TeamSettingsActionTypes.getSelectedTeamMemberMergedRolesFailure,
  props<{ payload: any }>(),
);

export const getCommonTeamMemberData = createAction(
  TeamSettingsActionTypes.getCommonTeamMemberData,
);

export const getCommonTeamMemberDataSuccess = createAction(
  TeamSettingsActionTypes.getCommonTeamMemberDataSuccess,
  props<{ payload: any }>(),
);

export const getCommonTeamMemberDataFailure = createAction(
  TeamSettingsActionTypes.getCommonTeamMemberDataFailure,
  props<{ payload: any }>(),
);

export const getTeam = createAction(TeamSettingsActionTypes.getTeamAction);
export const getTeamSuccess = createAction(
  TeamSettingsActionTypes.getTeamSuccessAction,
  props<{ payload: AccountTeamMember[] }>(),
);
export const getTeamFailure = createAction(
  TeamSettingsActionTypes.getTeamFailureAction,
  props<{ payload: any }>(),
);

export const getAvailableSeats = createAction(TeamSettingsActionTypes.getAvailableSeats);
export const getAvailableSeatsSuccess = createAction(
  TeamSettingsActionTypes.getAvailableSeatsSuccess,
  props<{ payload: number }>(),
);
export const getAvailableSeatsFailure = createAction(
  TeamSettingsActionTypes.getAvailableSeatsFailure,
  props<{ payload: any }>(),
);

export const addExtraSeat = createAction(TeamSettingsActionTypes.addExtraSeat);
export const addExtraSeatSuccess = createAction(
  TeamSettingsActionTypes.addExtraSeatSuccess,
  props<{ payload: number }>(),
);
export const addExtraSeatFailure = createAction(
  TeamSettingsActionTypes.addExtraSeatFailure,
  props<{ payload: any }>(),
);

export const inviteTeamMember = createAction(
  TeamSettingsActionTypes.inviteTeamMemberAction,
  props<{ payload: AddTeamMember }>(),
);
export const inviteTeamMemberSuccess = createAction(
  TeamSettingsActionTypes.inviteTeamMemberSuccessAction,
  props<{ payload: AccountTeamMember[], availableSeats: number }>(),
);
export const inviteTeamMemberFailure = createAction(
  TeamSettingsActionTypes.inviteTeamMemberFailureAction,
  props<{ payload: any }>(),
);

export const updateTeamMemberRole = createAction(
  TeamSettingsActionTypes.updateTeamMemberRoleAction,
  props<{ payload: TeamMemberData }>(),
);
export const updateTeamMemberRoleSuccess = createAction(
  TeamSettingsActionTypes.updateTeamMemberRoleSuccessAction,
  props<{ payload: AccountTeamMember[] }>(),
);
export const updateTeamMemberRoleFailure = createAction(
  TeamSettingsActionTypes.updateTeamMemberRoleFailureAction,
  props<{ payload: any }>(),
);

export const removeTeamMember = createAction(
  TeamSettingsActionTypes.removeTeamMemberAction,
  props<{ payload: SettingsTeamMemberDelete }>(),
);
export const removeTeamMemberSuccess = createAction(
  TeamSettingsActionTypes.removeTeamMemberSuccessAction,
  props<{ payload: AccountTeamMember[], availableSeats: number }>(),
);
export const removeTeamMemberFailure = createAction(
  TeamSettingsActionTypes.removeTeamMemberFailureAction,
  props<{ payload: any }>(),
);
