import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fileDownloadRequest } from '../actions/file-download.actions';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadFacade {
  constructor(private store: Store) {}

  downloadFile(url: string, filename: string): void {
    console.log('downloadFIle:::', url, filename);
    this.store.dispatch(fileDownloadRequest({ url, filename }));
  }
}
