import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { selectSnippetsState } from '../reducers/snippets.reducer';

export const selectSnippetsIsLoading = createSelector(
  selectSnippetsState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectSnippetsIsLoaded = createSelector(
  selectSnippetsState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectSnippetsError = createSelector(selectSnippetsState, (s) =>
  getError(s.callState),
);

export const selectSnippets = createSelector(
  selectSnippetsState,
  (s) => s.snippets.items,
);

export const selectSnippetDefaulsByTypeArea = createSelector(
  selectSnippetsState,
  (s) => s.snippetDefaulsByTypeArea,
);

export const selectSnippetsByTypeArea = createSelector(
  selectSnippetsState,
  (s) => s.snippetsByTypeArea,
);

export const selectSnippetsTotalCount = createSelector(
  selectSnippetsState,
  (s) => s.snippets.pageMetaData?.records,
);

export const selectSnippetsPageNumber = createSelector(
  selectSnippetsState,
  (s) => s.snippets.pageMetaData?.pageNumber,
);
