<div
  class="absolute inset-0 flex flex-col min-w-0 overflow-x-hidden overflow-y-auto"
>
  <div class="flex-auto h-full bg-card dark:bg-transparent">
    <div class="flex flex-col h-full bg-gray-100 dark:bg-transparent">
      <div class="flex flex-col p-4">
        <div class="flex flex-row flex-wrap">
          <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            <a
              mat-tab-link
              *ngFor="let link of menuItems"
              [routerLink]="'./' + link.route"
              routerLinkActive
              #rla="routerLinkActive"
              [active]="rla.isActive"
            >
              {{ link.text }}
            </a>
          </nav>
        </div>
      </div>
      <div class="mt-3 h-full">
        <mat-tab-nav-panel #tabPanel>
          <router-outlet></router-outlet>
        </mat-tab-nav-panel>
      </div>
    </div>
  </div>
</div>
