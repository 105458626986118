import { createReducer, on } from '@ngrx/store';
import { PaginatedListOfJobOrderListItem } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  jobOrderDeleteFacade,
  jobOrderDeleteFacadeFailure,
  jobOrderDeleteFacadeSuccess,
  getJobsOrderList,
  getJobsOrderListFailure,
  getJobsOrderListSuccess,
} from '../actions';

export interface JobOrdersListState {
  jobOrders: PaginatedListOfJobOrderListItem;
  callState: CallState;
  error: any;
}

export const initialState: JobOrdersListState = {
  jobOrders: <any>{ items: [] },
  callState: LoadingState.INIT,
  error: null,
};

export const jobOrdersListReducer = createReducer(
  initialState,
  on(getJobsOrderList, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobsOrderListFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobsOrderListSuccess, (state, { jobOrders }) => ({
    ...state,
    jobOrders,
    callState: LoadingState.LOADED,
  })),

  on(jobOrderDeleteFacade, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(jobOrderDeleteFacadeFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(jobOrderDeleteFacadeSuccess, (state, { jobOrders }) => ({
    ...state,
    jobOrders: {
      items: state?.jobOrders?.items?.filter(
        (item) => jobOrders.id !== item.id,
      ),
    },
    callState: LoadingState.LOADED,
  })),
);
