import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgIf, NgClass, NgFor } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { ofType } from '@ngrx/effects';
import { ScannedActionsSubject } from '@ngrx/store';
import {
  AddPriceListItem,
  PriceListCategory,
  PriceListItem,
  PriceListItemPriceChangeCheck,
  SupplierParameters,
} from 'app/shared/models';
import { Observable, race, Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';
import { PriceListItemAddEdit } from '../../models';
import {
  addCurrentPriceListItemSuccess,
  editCurrentPriceListItemSuccess,
} from '../../store/actions';
import { CurrentPriceListFacade } from '../../store/facades';
import { GeneralSettingsFacade } from 'app/views/settings/store/facades';
import { SupplierListFacade } from 'app/views/suppliers/store/facades/supplier-list.facade';
import { ItemPriceCheckFacade } from '../../store/facades/item-price-check.facade';
import { PriceListItemPriceJobComponent } from '../price-list-item-price-job/price-list-item-price-job.component';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { FuseMediaWatcherService } from '../../../../../@fuse/services/media-watcher';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NumbersOnlyDirective } from '../../../../shared/directives/numbers-only.directive';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from 'app/shared/shared.module';

import { SpeachToTextComponent } from 'app/shared/components';

@Component({
  selector: 'app-price-list-item-dialog',
  templateUrl: './price-list-item-dialog.component.html',
  styleUrls: ['./price-list-item-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgClass,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgxTrimDirectiveModule,
    NumbersOnlyDirective,
    InputMaskModule,
    MatSlideToggleModule,
    MatButtonModule,
    AsyncPipe,
    SpeachToTextComponent,
  ],
  providers: [AsyncPipe],
})
export class PriceListItemDialogComponent implements OnInit, OnDestroy {
  public itemForm = this.fb.group({
    categoryCode: [''],
    itemCode: [''],
    description: ['', [Validators.required, this.noWhitespaceValidator]],
    unitOfMeasure: ['', Validators.required],
    unitPrice: [
      '',
      [Validators.required, Validators.min(0), Validators.max(999000000)],
    ],
    qty: [
      '',
      [Validators.required, Validators.min(0), Validators.max(999000000)],
    ],
    gstRate: [true],
    supplier: [null, Validators.required],
    // supplierItemCode: [''],
  });

  public title: string;
  public isEditMode = false;

  inputOnFocus = false;
  isTabletLandscapeScreen = false;
  virtualKeyBoardOpened = false;

  private valueChanged$: Observable<boolean>;
  private unsubscriber$ = new Subject<void>();

  currencyInputMask = createMask({
    parser: (v) => parseFloat(v.split(',').join('').replace("$", '')),
    prefix: '$',
    inputmode: 'numeric',
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
  });

  constructor(
    private dialogRef: MatDialogRef<PriceListItemDialogComponent>,
    private dialog: MatDialog,
    public facade: CurrentPriceListFacade,
    public supplierListFacade: SupplierListFacade,
    private fb: FormBuilder,
    private actions$: ScannedActionsSubject,
    private async: AsyncPipe,
    public generalFacade: GeneralSettingsFacade,
    public itemPriceCheckFacade: ItemPriceCheckFacade,
    private fuseMediaWatcherService: FuseMediaWatcherService,
    @Inject(MAT_DIALOG_DATA) public data?: PriceListItemAddEdit,
  ) {
    window.addEventListener(
      'native.showkeyboard',
      () => (this.virtualKeyBoardOpened = true),
    );
    window.addEventListener(
      'native.hidekeyboard',
      () => (this.virtualKeyBoardOpened = false),
    );
    this.fuseMediaWatcherService
      .onMediaQueryChange$(['(max-height: 1024px)'])
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((state) => {
        this.isTabletLandscapeScreen =
          state.matches && window.innerWidth > window.innerHeight;
      });

    this.title = 'Add Price List Item';
    this.itemForm.get('categoryCode').patchValue(this.data.category.code);
    this.itemForm.controls['categoryCode'].disable();

    if (this.data.isEdit) {
      this.title = 'Edit Price List Item';
      this.itemForm.patchValue(this.data.priceListItem as any);
    }
    const supplierList = this.async.transform(this.facade.supplierList$);
    if (!supplierList.items.length) {
      const query: SupplierParameters = {
        pageNumber: 1,
        pageSize: 99999,
        orderBy: [{ orderBy: 'name', descending: false }],
      };

      this.supplierListFacade.loadSupplierList(query);
    }
    if (this.data.currentSupplier) {
      this.itemForm
        .get('supplier')
        .patchValue(this.data.currentSupplier as any);
      this.itemForm.get('supplier').disable();
    }
  }

  ngOnInit(): void {
    this.actions$
      .pipe(ofType(editCurrentPriceListItemSuccess))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (
          +this.data.priceListItem.unitPrice !== +this.itemForm.value.unitPrice
        ) {
          const params: PriceListItemPriceChangeCheck = {
            priceListItemId: this.data.priceListItem.id,
            unitPrice: +this.itemForm.value.unitPrice,
          };
          this.itemPriceCheckFacade.getItemPriceCheck(params);

          this.itemPriceCheckFacade.priceListItemPriceChangeJob$
            .pipe(skip(1), take(1))
            .subscribe((res) => {
              if (res && res.length) {
                this.dialog.open(PriceListItemPriceJobComponent, {
                  width: '626px',
                  height: 'auto',
                  data: {
                    itemName: this.itemForm.value.description,
                    itemPrice: +this.itemForm.value.unitPrice,
                    priceListItemId: this.data.priceListItem.id,
                  },
                  panelClass: 'app-price-list-item-price-job',
                }).afterClosed().subscribe(() => this.close());
              } else {
                this.close();
              }
            });
        } else {
          this.close();
        }
      });

      this.actions$.pipe(ofType(addCurrentPriceListItemSuccess))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => this.close());
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  save(): void {
    if (this.data.isEdit) {
      const priceListItem: PriceListItem = {
        description: this.itemForm.value.description,
        // supplierItemCode: this.itemForm.value.supplierItemCode || '',
        unitPrice: +this.itemForm.value.unitPrice,
        unitOfMeasure: this.itemForm.value.unitOfMeasure || '',
        supplier: this.itemForm.value.supplier as any,
        qty: +this.itemForm.value.qty,
        itemCode: this.itemForm.value.itemCode,
        gstRate: this.itemForm.value.gstRate ? 10 : 0,
        priceListCategoryId: this.data?.category?.id,
        accountId: this.data?.category?.accountId,
        priceListId: this.async.transform(this.facade.priceList$).id,
        priceListCategory: this.data.category,
        id: this.data.priceListItem.id,
        supplierId: (this.itemForm.value.supplier as any).id,
      };

      this.facade.editItem(priceListItem);
    } else {
      const priceListItem: AddPriceListItem = {
        description: this.itemForm.value.description,
        // supplierItemCode: this.itemForm.value.supplierItemCode || '',
        unitPrice: +this.itemForm.value.unitPrice,
        unitOfMeasure: this.itemForm.value.unitOfMeasure || '',
        supplierId: this.data.currentSupplier ? (this.itemForm.getRawValue().supplier as  any).id : (this.itemForm.value.supplier as any).id,
        qty: +this.itemForm.value.qty,
        gstRate: this.itemForm.value.gstRate ? 10 : 0,
        priceListCategoryId: this.data?.category?.id,
        accountId: this.data?.category?.accountId,
        priceListId: this.async.transform(this.facade.priceList$).id,
      };
      this.facade.addItem(priceListItem);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  compareSupplier(o1: any, o2: any): boolean {
    if (!o1 || !o2) return false;
    return o1.id === o2.id;
  }

  // validators
  checkRequired(controlName: string): boolean {
    return (
      this.itemForm.get(controlName).hasError('required') &&
      this.itemForm.get(controlName).dirty
    );
  }

  checkMax(controlName: string): boolean {
    return (
      this.itemForm.get(controlName).hasError('max') &&
      this.itemForm.get(controlName).dirty
    );
  }

  checkNegativeValue(controlName: string): boolean {
    return (
      this.itemForm.get(controlName).hasError('min') &&
      this.itemForm.get(controlName).dirty
    );
  }

  checkEmptyStrings(controlName: string): boolean {
    return (
      this.itemForm.get(controlName).hasError('whitespace') &&
      this.itemForm.get(controlName).dirty
    );
  }

  public noWhitespaceValidator(control: FormControl): any {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
}
