/* eslint-disable arrow-body-style */
import { createSelector } from '@ngrx/store';
import { selectSettingsState, SettingsState } from '../reducers';

import * as fromReducer from '../reducers/general-settings.reducer';

export const selectGeneralSettingsState = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.generalSettings,
);

export const selectUnitsOfMeasuresList = createSelector(
  selectGeneralSettingsState,
  (state: fromReducer.GeneralSettingsState) => state.unitsOfMeasures,
);

export const selectInitialised = createSelector(
  selectGeneralSettingsState,
  fromReducer.getIsInitialised,
);
export const selectIsLoading = createSelector(
  selectGeneralSettingsState,
  fromReducer.getIsLoading,
);
export const selectError = createSelector(
  selectGeneralSettingsState,
  fromReducer.getError,
);
