import {
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  on,
} from '@ngrx/store';
import { CallState, LoadingState } from 'app/store';
import { PaginatedListOfSnippet, Snippet } from '../../../../shared/models';
import {
  getSnippetDefaultByTypeArea,
  getSnippetDefaultByTypeAreaFailure,
  getSnippetDefaultByTypeAreaSuccess,
  getSnippetsList,
  getSnippetsListByTypeArea,
  getSnippetsListByTypeAreaFailure,
  getSnippetsListByTypeAreaSuccess,
  getSnippetsListFailure,
  getSnippetsListSuccess,
} from '../actions/snippets.actions';

export interface SnippetsState {
  snippets: PaginatedListOfSnippet;
  snippetsByTypeArea: Snippet[];
  snippetDefaulsByTypeArea: Snippet;
  callState: CallState;
  error: any;
}

export const initialState: SnippetsState = {
  snippets: <PaginatedListOfSnippet>{ items: [] },
  snippetsByTypeArea: null,
  snippetDefaulsByTypeArea: null,
  callState: LoadingState.INIT,
  error: null,
};

export const selectSnippetsState =
  createFeatureSelector<SnippetsState>('snippets');

export const snippetsReducer = createReducer(
  initialState,
  on(getSnippetsList, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getSnippetsListFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getSnippetsListSuccess, (state, { snippets }) => ({
    ...state,
    snippets,
    callState: LoadingState.LOADED,
  })),

  on(getSnippetsListByTypeArea, (state) => ({
    ...state,
    snippetsByTypeArea: null,
    callState: LoadingState.LOADING,
  })),
  on(getSnippetsListByTypeAreaFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getSnippetsListByTypeAreaSuccess, (state, { snippets }) => ({
    ...state,
    snippetsByTypeArea: snippets,
    callState: LoadingState.LOADED,
  })),

  on(getSnippetDefaultByTypeArea, (state) => ({
    ...state,
    snippetDefaulsByTypeArea: null,
    callState: LoadingState.LOADING,
  })),
  on(getSnippetDefaultByTypeAreaFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getSnippetDefaultByTypeAreaSuccess, (state, { snippet }) => ({
    ...state,
    snippetDefaulsByTypeArea: snippet,
    callState: LoadingState.LOADED,
  })),

  // on(deleteTemplate, (state) => ({
  //     ...state,
  //     callState: LoadingState.LOADING
  // })),
  // on(deleteTemplateFailure, (state, { error }) => ({
  //     ...state,
  //     callState: { error }
  // })),
  // on(deleteTemplateSuccess, (state, { template }) => ({
  //     ...state,
  //     callState: LoadingState.LOADED
  // })),
);
