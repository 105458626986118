import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobListState = createSelector(
  selectJobState,
  (s: JobState) => s?.jobs,
);

export const selectJobListIsLoading = createSelector(
  selectJobListState,
  (s) => s?.callState === LoadingState.LOADING,
);

export const selectJobListIsLoaded = createSelector(
  selectJobListState,
  (s) => s?.callState === LoadingState.LOADED,
);

export const selectJobListError = createSelector(selectJobListState, (s) =>
  getError(s?.callState),
);

export const selectJobList = createSelector(
  selectJobListState,
  (s) => s?.jobs?.items,
);

export const selectJobListTotalCount = createSelector(
  selectJobListState,
  (s) => s?.jobs?.pageMetaData?.records,
);

export const selectJobListPageNumber = createSelector(
  selectJobListState,
  (s) => s?.jobs?.pageMetaData?.pageNumber,
);

export const selectRecentJobs = createSelector(
  selectJobListState,
  (s) => s?.recentJobs,
);
