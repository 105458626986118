import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectSnippetDefaulsByTypeArea,
  selectSnippets,
  selectSnippetsByTypeArea,
  selectSnippetsError,
  selectSnippetsIsLoaded,
  selectSnippetsIsLoading,
  selectSnippetsPageNumber,
  selectSnippetsTotalCount,
} from '../selectors/snippets.selector';
import {
  addSnippet,
  deleteSnippet,
  getSnippetDefaultByTypeArea,
  getSnippetsList,
  getSnippetsListByTypeArea,
  updateSnippet,
} from '../actions/snippets.actions';
import { Snippet, SnippetParameters } from 'app/shared/models/eazimate.models';

@Injectable({
  providedIn: 'root',
})
export class SnippetsFacade {
  isLoading$ = this.store.select(selectSnippetsIsLoading);
  isLoaded$ = this.store.select(selectSnippetsIsLoaded);
  error$ = this.store.select(selectSnippetsError);
  snippets$ = this.store.select(selectSnippets);
  snippetsByTypeArea$ = this.store.select(selectSnippetsByTypeArea);
  snippetDefaulsByTypeArea$ = this.store.select(selectSnippetDefaulsByTypeArea);
  totalCount$ = this.store.select(selectSnippetsTotalCount);
  pageIndex$ = this.store.select(selectSnippetsPageNumber);

  constructor(private store: Store) {}

  getSnippets(request: SnippetParameters): void {
    this.store.dispatch(getSnippetsList({ request }));
  }

  getSnippetsByTypeArea(params: SnippetParameters): void {
    this.store.dispatch(getSnippetsListByTypeArea({ params }));
  }

  getSnippetDefaulsByTypeArea(params: SnippetParameters): void {
    this.store.dispatch(getSnippetDefaultByTypeArea({ params }));
  }

  deleteSnippet(id: string): void {
    this.store.dispatch(deleteSnippet({ id }));
  }

  addSnippet(snippet: Snippet): void {
    this.store.dispatch(addSnippet({ snippet }));
  }

  updateSnippet(snippet: Snippet): void {
    this.store.dispatch(updateSnippet({ snippet }));
  }
}
