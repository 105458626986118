/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { Photo } from 'app/shared/models';

export enum JobPhotosActionTypes {
  GetJobPhotos = '[JobPhotos] Get Job Photos',
  GetJobPhotosSuccess = '[JobPhotos] Get Job Photos Success',
  GetJobPhotosFailure = '[JobPhotos] Get Job Photos Failure',
  SetJobPhotosCurrentFolderPath = '[JobPhotos] Set Current Folder Path',
  SetJobPhotosSortField = '[JobPhotos] Set Sort Field',
  SetJobPhotosSortOrder = '[JobPhotos] Set Sort Order',
  DeleteJobPhotosFolders = '[JobPhotos] Delete Folders',
  DeleteJobPhotosFoldersSuccess = '[JobPhotos] Delete Folders Success',
  DeleteJobPhotosFoldersFailure = '[JobPhotos] Delete Folders Failure',
  DeleteJobPhotos = '[JobPhotos] Delete Photos',
  DeleteJobPhotosSuccess = '[JobPhotos] Delete Photos Success',
  DeleteJobPhotosFailure = '[JobPhotos] Delete Photos Failure',
  SetDetailPhotoIndex = '[JobPhotos] Set Detail Photo Index',
  ShowCreateJobPhotosFolderDialog = '[JobPhotos] Show Create Job Folder Dialog',
  CreateJobPhotosFolder = '[JobPhotos] Create Job Folder',
  CreateJobPhotosFolderSuccess = '[JobPhotos] Create Job Folder Success',
  CreateJobPhotosFolderFailure = '[JobPhotos] Create Job Folder Failure',
  ShowRenameJobPhotosFolderDialog = '[JobPhotos] Show Rename Folder Dialog',
  RenameJobPhotosFolder = '[JobPhotos] Rename Folder',
  RenameJobPhotosFolderSuccess = '[JobPhotos] Rename Folder Success',
  RenameJobPhotosFolderFailure = '[JobPhotos] Rename Folder Failure',

  ToggleJobPhotoFileSelected = '[JobPhotos] Toggle Photo Selected',
  ToggleJobPhotosFolderSelected = '[JobPhotos] Toggle Folder Selected',
  SelectJobPhotos = '[JobPhotos] Select Photos',
  DeselectJobPhotos = '[JobPhotos] Deselect Photos',
  SelectJobPhotosFolders = '[JobPhotos] Select Folders',
  DeselectJobPhotosFolders = '[JobPhotos] Deselect Folders',

  DownloadMultipleJobPhotos = '[JobPhotos] Download Multiple Job Photos',
  DownloadMultipleJobPhotosSuccess = '[JobPhotos API] Download Multiple Job Photos Success',
  DownloadMultipleJobPhotosFailure = '[JobPhotos API] Download Multiple Job Photos Failure',
}

export const downloadMultiplePhotos = createAction(
  JobPhotosActionTypes.DownloadMultipleJobPhotos,
  props<{ jobId: string; photos: Photo[] }>(),
);

export const downloadMultiplePhotosSuccess = createAction(
  JobPhotosActionTypes.DownloadMultipleJobPhotosSuccess,
  props<{ blob: Blob; filename: string }>(),
);

export const downloadMultiplePhotosFailure = createAction(
  JobPhotosActionTypes.DownloadMultipleJobPhotosFailure,
  props<{ error: string }>(),
);

export const selectJobPhotosFolderPaths = createAction(
  JobPhotosActionTypes.SelectJobPhotosFolders,
  props<{ paths: string[] }>(),
);

export const deselectJobPhotosFolderPaths = createAction(
  JobPhotosActionTypes.DeselectJobPhotosFolders,
  props<{ paths: string[] }>(),
);

export const selectPhotoIds = createAction(
  JobPhotosActionTypes.SelectJobPhotos,
  props<{ ids: string[] }>(),
);

export const deselectPhotoIds = createAction(
  JobPhotosActionTypes.DeselectJobPhotos,
  props<{ ids: string[] }>(),
);

export const toggleJobPhotosFolderSelected = createAction(
  JobPhotosActionTypes.ToggleJobPhotosFolderSelected,
  props<{ path: string }>(),
);

export const toggleJobPhotoFileSelected = createAction(
  JobPhotosActionTypes.ToggleJobPhotoFileSelected,
  props<{ id: string }>(),
);

export const showRenameJobPhotosFolderDialog = createAction(
  JobPhotosActionTypes.ShowRenameJobPhotosFolderDialog,
  props<{ jobId: string; path: string }>(),
);

export const renameJobPhotosFolder = createAction(
  JobPhotosActionTypes.RenameJobPhotosFolder,
  props<{ jobId: string; oldPath: string; newPath: string }>(),
);

export const renameJobPhotosFolderSuccess = createAction(
  JobPhotosActionTypes.RenameJobPhotosFolderSuccess,
  props<{ photos: Photo[] }>(),
);

export const renameJobPhotosFolderFailure = createAction(
  JobPhotosActionTypes.RenameJobPhotosFolderFailure,
  props<{ error: string }>(),
);

export const showCreateJobPhotosFolderDialog = createAction(
  JobPhotosActionTypes.ShowCreateJobPhotosFolderDialog,
  props<{ jobId: string; path: string }>(),
);

export const createJobPhotosFolder = createAction(
  JobPhotosActionTypes.CreateJobPhotosFolder,
  props<{ jobId: string; path: string }>(),
);

export const createJobPhotosFolderSuccess = createAction(
  JobPhotosActionTypes.CreateJobPhotosFolderSuccess,
  props<{ photo: Photo }>(),
);

export const createJobPhotosFolderFailure = createAction(
  JobPhotosActionTypes.CreateJobPhotosFolderFailure,
  props<{ error: string }>(),
);

export const getJobPhotos = createAction(
  JobPhotosActionTypes.GetJobPhotos,
  props<{ jobId: string }>(),
);

export const getJobPhotosSuccess = createAction(
  JobPhotosActionTypes.GetJobPhotosSuccess,
  props<{ photos: Photo[] }>(),
);

export const getJobPhotosFailure = createAction(
  JobPhotosActionTypes.GetJobPhotosFailure,
  props<{ error: string }>(),
);

export const setDetailPhotoIndex = createAction(
  JobPhotosActionTypes.SetDetailPhotoIndex,
  props<{ index: number }>(),
);

export const setJobPhotosCurrentFolderPath = createAction(
  JobPhotosActionTypes.SetJobPhotosCurrentFolderPath,
  props<{ path: string }>(),
);

export const setJobPhotosSortField = createAction(
  JobPhotosActionTypes.SetJobPhotosSortField,
  props<{ sortField: string }>(),
);

export const setJobPhotosSortOrder = createAction(
  JobPhotosActionTypes.SetJobPhotosSortOrder,
  props<{ sortOrder: 'asc' | 'desc' }>(),
);

export const deleteJobPhotosFolders = createAction(
  JobPhotosActionTypes.DeleteJobPhotosFolders,
  props<{ jobId: string; paths: string[] }>(),
);

export const deleteJobPhotosFoldersSuccess = createAction(
  JobPhotosActionTypes.DeleteJobPhotosFoldersSuccess,
  props<{ ids: string[] }>(),
);

export const deleteJobPhotosFoldersFailure = createAction(
  JobPhotosActionTypes.DeleteJobPhotosFoldersFailure,
  props<{ error: string }>(),
);

export const deleteJobPhotos = createAction(
  JobPhotosActionTypes.DeleteJobPhotos,
  props<{ jobId: string; ids: string[] }>(),
);

export const deleteJobPhotosSuccess = createAction(
  JobPhotosActionTypes.DeleteJobPhotosSuccess,
  props<{ ids: string[] }>(),
);

export const deleteJobPhotosFailure = createAction(
  JobPhotosActionTypes.DeleteJobPhotosFailure,
  props<{ error: string }>(),
);
