import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileUpload, FileUploadType, JobFile } from 'app/shared/models';
import { fileUploadRequest } from 'app/shared/store/actions/file-upload.actions';
import {
  clearJobFilesState,
  createJobFile,
  deleteFiles,
  deleteFolders,
  deselectFileIds,
  deselectJobFilesFolderPaths,
  downloadMultipleJobFiles,
  getJobFiles,
  renameJobFile,
  selectFileIds,
  selectJobFilesFolderPaths,
  setJobFilesCurrentFolderPath,
  setJobFilesSortField,
  setJobFilesSortOrder,
  showCreateJobFilesFolderDialog,
  showRenameJobFileDialog,
  showRenameJobFilesFolderDialog,
  toggleJobFilesFileSelected,
  toggleJobFilesFolderSelected,
} from '../actions/job-files.actions';

import { selectSasToken } from 'app/shared/store/selectors/account-user.selectors';
import {
  selectJobFilesAllFiles,
  selectJobFilesCurrentFiles,
  selectJobFilesCurrentFolder,
  selectJobFilesCurrentFolders,
  selectJobFilesFolders,
  selectJobFilesIsLoaded,
  selectJobFilesSortField,
  selectJobFilesSortOrder,
  selectSelectedJobFileIds,
  selectSelectedJobFolderPaths,
} from '../selectors/job-files.selectors';

@Injectable({
  providedIn: 'root',
})
export class FilesFacade {
  selectedJobFolderPaths$ = this.store.select(selectSelectedJobFolderPaths);
  selectedJobFileIds$ = this.store.select(selectSelectedJobFileIds);
  currentFolder$ = this.store.select(selectJobFilesCurrentFolder);
  folders$ = this.store.select(selectJobFilesFolders);
  currentFolders$ = this.store.select(selectJobFilesCurrentFolders);
  currentFiles$ = this.store.select(selectJobFilesCurrentFiles);
  allFiles$ = this.store.select(selectJobFilesAllFiles);
  sortField$ = this.store.select(selectJobFilesSortField);
  sortOrder$ = this.store.select(selectJobFilesSortOrder);
  isLoaded$ = this.store.select(selectJobFilesIsLoaded);
  sasToken$ = this.store.select(selectSasToken);

  constructor(private store: Store) {}

  downloadMultipleJobFiles(jobId: string, files: JobFile[]): void {
    this.store.dispatch(downloadMultipleJobFiles({ jobId, files }));
  }

  uploadFiles(jobId: string, path: string, files: FileList): any {
    const filesToUpload = Array.from(files).map((f) => {
      const file = f as FileUpload;
      file.uploadType = FileUploadType.JobFile;
      file.formData = new FormData();
      if (jobId) {
        file.url = 'jobfile/upload';
        file.formData.append('jobId', jobId);
      } else {
        file.url = 'libraryfile/upload';
      }
      file.formData.append('path', path);

      return file;
    });

    this.store.dispatch(fileUploadRequest({ files: filesToUpload }));
  }

  showRenameFileDialog(file: JobFile): void {
    this.store.dispatch(showRenameJobFileDialog({ file }));
  }

  showCreateFolderDialog(jobId: string, path: string): void {
    this.store.dispatch(showCreateJobFilesFolderDialog({ jobId, path }));
  }

  showRenameFolderDialog(jobId: string, path: string): void {
    this.store.dispatch(showRenameJobFilesFolderDialog({ jobId, path }));
  }

  getFiles(jobId: string): void {
    this.store.dispatch(getJobFiles({ jobId }));
  }

  deleteFolders(jobId: string, paths: string[]): void {
    this.store.dispatch(deleteFolders({ jobId, paths }));
  }

  deleteFiles(jobId: string, ids: string[]): void {
    this.store.dispatch(deleteFiles({ jobId, ids }));
  }

  toggleFileSelected(id: string): void {
    this.store.dispatch(toggleJobFilesFileSelected({ id }));
  }

  toggleFolderSelected(path: string): void {
    this.store.dispatch(toggleJobFilesFolderSelected({ path }));
  }

  selectFolders(paths: string[]): void {
    this.store.dispatch(selectJobFilesFolderPaths({ paths }));
  }

  deselectFolders(paths: string[]): void {
    this.store.dispatch(deselectJobFilesFolderPaths({ paths }));
  }

  selectFileIds(ids: string[]): void {
    this.store.dispatch(selectFileIds({ ids }));
  }

  deselectFileIds(ids: string[]): void {
    this.store.dispatch(deselectFileIds({ ids }));
  }

  setSortField(sortField: string): void {
    this.store.dispatch(setJobFilesSortField({ sortField }));
  }

  setSortOrder(sortOrder: 'asc' | 'desc'): void {
    this.store.dispatch(setJobFilesSortOrder({ sortOrder }));
  }

  setCurrentFolderPath(path: string): void {
    this.store.dispatch(setJobFilesCurrentFolderPath({ path }));
  }

  createJobFile(file: JobFile): void {
    this.store.dispatch(createJobFile({ file }));
  }

  renameJobFile(file: JobFile): void {
    this.store.dispatch(renameJobFile({ file }));
  }

  clearJobFilesState(): void {
    this.store.dispatch(clearJobFilesState());
  }
}
