<div class="absolute inset-0 flex flex-col flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-auto flex-col overflow-y-auto">

    <div *ngIf="jobOrderSummaryFacade.isLoaded$ | async" class="px-4 mb-8">
      <app-job-order-summary
        class="h-full"
        [isReadOnlyMode]="isReadOnlyMode"
        [expanded]="expandedSummary"
        (createOrder)="createOrder($event)"
        [suppliersOptions]="suppliersListByJobFacade.items$ | async"
      >
      </app-job-order-summary>
    </div>

    <div class="px-4">
      <div class="mb-3">Orders</div>
      <div class="table-search-wrap rounded-none mb-2 lg:mb-0">
        <div [formGroup]="searchForm" class="flex flex-row w-full mb-4">
          <mat-form-field
            id="suppliers-filter"
            class="fuse-mat-no-subscript max-w-xs w-full mr-4">
            <mat-select formControlName="supplier">
              <mat-option [value]="0"> All Suppliers </mat-option>
              <mat-option *ngFor="let s of suppliersList" [value]="s?.id">
                {{ s?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            id="statuses-filter"
            class="fuse-mat-no-subscript max-w-xs w-full">
            <mat-select formControlName="status">
              <mat-option [value]="0">Status - All</mat-option>
              <mat-option *ngFor="let s of statusOptions" [value]="s.value">
                Status - {{ s.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="flex flex-auto  w-full mb-4"
      [hidden]="jobOrdersListFacade.isLoading$ | async"
    >
      <div [hidden]="!dataSource?.data?.length" class="w-full">
        <div class="flex flex-auto overflow-hidden w-full min-h-64">
          <div class="flex-col flex-auto overflow-hidden hidden lg:flex px-4">
            <ng-container>
              <div class="flex-auto overflow-hidden h-full">
                <table
                  [hidden]="jobOrdersListFacade.isLoading$ | async"
                  mat-table
                  #table
                  [dataSource]="dataSource"
                  matSort
                  class="w-full border-t border-slate-200 overflow-y-auto job-order-table"
                >
                  <ng-container matColumnDef="orderCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Number
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.orderCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="calledDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Called Date
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.calledDate | date: "dd MMM yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="supplier">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Supplier
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.supplier?.name }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="priceListCategoryName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Category
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.priceListCategoryName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="callForDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Call For
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.callForDate | date: "dd MMM yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="acknowledgedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Acknowledged
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.acknowledgedDate | date: "dd MMM yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>Total Price</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.totalAmount | currency }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">
                      <app-order-status-pill
                        class="mx-1"
                        [status]="element?.status"
                      >
                      </app-order-status-pill>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="integrationStatus"
                    *ngIf="syncEnabled"
                  >
                    <th mat-header-cell *matHeaderCellDef>Sync</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-icon
                        class="text-green-600"
                        *ngIf="element.integrationStatus === 10"
                        >{{
                          element.integrationStatus === 10 ? "check_circle" : ""
                        }}
                      </mat-icon>
                      <mat-icon
                        class="text-red-600"
                        *ngIf="element.integrationStatus === 20"
                        >{{ element.integrationStatus === 20 ? "error" : "" }}
                      </mat-icon>
                      <mat-icon
                        class="text-blue-600"
                        *ngIf="element.integrationStatus === 30"
                        >{{
                          element.integrationStatus === 30 ? "change_circle" : ""
                        }}
                      </mat-icon>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sent">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Sent
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-icon class="text-green-600"
                        >{{ element.sent ? "check_circle" : "" }}
                      </mat-icon>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div class="flex flex-row items-center">
                        <button
                          (click)="
                            details('/jobs/order/' + element.id, {
                              jobId: query.jobId
                            })
                          "
                          class="manage-button text-xs rounded-xl min-h-8 h-8 px-4"
                          mat-stroked-button
                          [color]="'primary'"
                        >
                          <span class="ml-2 mr-1">Manage</span>
                        </button>
                        <button
                          *ngIf="!isReadOnlyMode"
                          (click)="$event.stopPropagation(); deleteOrder(element)"
                          mat-icon-button
                        >
                          <mat-icon
                            aria-hidden="true"
                            class="icon-size-5 text-secondary"
                            matPrefix
                            [svgIcon]="'heroicons_outline:trash'"
                          ></mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns; let i = index"
                    class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
                    (click)="
                      details('/jobs/order/' + row.id, { jobId: query.jobId })
                    "
                  ></tr>
                </table>
              </div>
            </ng-container>
          </div>
          <!-- tablet and mobile list  -->
          <div
            class="lg:hidden flex flex-col flex-auto xs:mb-18 overflow-hidden xs:overflow-y-auto px-4"
          >
            <fuse-card
              *ngFor="let order of dataSource?.data"
              class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2"
            >
              <div class="flex flex-col xs:w-full sm:w-1/3">
                <div class="text-md font-semibold leading-tight">
                  Number: {{ order?.orderCode }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Status:
                  <app-order-status-pill class="mx-1" [status]="order.status">
                  </app-order-status-pill>
                </div>
                <div class="text-secondary font-medium text-md">
                  Supplier: {{ order.supplier?.name }}
                </div>
              </div>

              <div
                class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
              >
                <div class="text-md font-semibold leading-tight">
                  Date: {{ order.orderDate | date: "dd MMM yyyy" }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Call For: {{ order.callForDate | date: "dd MMM yyyy" }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Acknowledged: {{ order.acknowledgedDate | date: "dd MMM yyyy" }}
                </div>
                <div
                  *ngIf="order?.sent"
                  class="flex items-center text-secondary font-medium text-md"
                >
                  <span class="mr-1">Sent:</span>
                  <mat-icon class="text-green-600">{{
                    order?.sent ? "check_circle" : ""
                  }}</mat-icon>
                </div>
              </div>

              <div
                class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
              >
                <div class="text-md font-semibold leading-tigh">
                  Total Price: {{ order.totalAmount | currency }}
                </div>
              </div>

              <div
                class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto"
              >
                <button
                  class="type-action"
                  mat-stroked-button
                  [color]="'primary'"
                  (click)="
                    details('/jobs/order/' + order.id, { jobId: query.jobId })
                  "
                >
                  <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
                </button>
              </div>
            </fuse-card>
          </div>
        </div>
      </div>
      <div
        *ngIf="!(jobOrdersListFacade.isLoading$ | async)"
        [hidden]="dataSource?.data?.length"
        class="w-full top-1/2 xs:text-lg md:text-4xl font-semibold text-center">
        <span class="text-lg" *ngIf="searchForm.touched || searchForm.dirty"> No result for your search.</span>
        <span class="text-lg" *ngIf="!(searchForm.touched || searchForm.dirty)">
           No orders yet. Select '+ Create Orders' or '+ Add Extra/Over Order' to create an order
        </span>
      </div>
    </div>

  </div>
</div>
