import { createReducer, on } from '@ngrx/store';
import { JobOrder } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  addJobsOrderDetails,
  addJobsOrderDetailsFailure,
  addJobsOrderDetailsSuccess,
  clearJobOrderDetailsState,
  getJobsOrderDetails,
  getJobsOrderDetailsFailure,
  getJobsOrderDetailsSuccess,
  updateJobOrderSendStatus,
  updateJobOrderSendStatusFailure,
  updateJobOrderSendStatusSuccess,
  updateJobOrderStatus,
  updateJobOrderStatusFailure,
  updateJobOrderStatusSuccess,
  updateJobsOrderDetails,
  updateJobsOrderDetailsFailure,
  updateJobsOrderDetailsSuccess,
} from '../actions';

export interface JobOrdersDetailsState {
  jobOrder: JobOrder;
  callState: CallState;
  error: any;
}

export const initialState: JobOrdersDetailsState = {
  jobOrder: null,
  callState: LoadingState.INIT,
  error: null,
};

export const jobOrdersDetailsReducer = createReducer(
  initialState,
  on(getJobsOrderDetails, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobsOrderDetailsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobsOrderDetailsSuccess, (state, { jobOrder }) => ({
    ...state,
    jobOrder,
    callState: LoadingState.LOADED,
  })),

  on(updateJobsOrderDetails, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobsOrderDetailsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateJobsOrderDetailsSuccess, (state, { jobOrder }) => ({
    ...state,
    jobOrder,
    callState: LoadingState.LOADED,
  })),
  // do not fire this action, it causes full order page reload instead of just changing the status
  // on(updateJobOrderStatus, (state) => ({
  //   ...state,
  //   callState: LoadingState.LOADING,
  // })),
  on(updateJobOrderStatusFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  // do not fire this action, it causes full order page reload instead of just changing the status
  // on(updateJobOrderStatusSuccess, (state, { jobOrder }) => ({
  //   ...state,
  //   // jobOrder,
  //   callState: LoadingState.LOADED,
  // })),

  on(updateJobOrderSendStatus, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobOrderSendStatusFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateJobOrderSendStatusSuccess, (state, { jobOrder }) => ({
    ...state,
    // jobOrder,
    // callState: LoadingState.LOADED,
  })),

  on(addJobsOrderDetails, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(addJobsOrderDetailsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(addJobsOrderDetailsSuccess, (state, { jobOrder }) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),

  on(clearJobOrderDetailsState, (state) => ({
    ...state,
    jobOrder: null,
  })),
);
