import { createSelector } from '@ngrx/store';
import { LoadingState } from 'app/store';
import { selectJobState } from '../reducers';

export const selectJobInvoicesState = createSelector(
  selectJobState,
  (s) => s.invoices,
);

export const selectJobInvoicesIsLoaded = createSelector(
  selectJobInvoicesState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobInvoices = createSelector(
  selectJobInvoicesState,
  (s) => s.invoices,
);

export const selectCustomerJobInvoices = createSelector(
  selectJobInvoicesState,
  (s) => s.customerInvoices,
);

export const selectJobInvoice = createSelector(
  selectJobInvoicesState,
  (s) => s.invoice,
);

export const selectJobInvoicesTotalCount = createSelector(
  selectJobInvoicesState,
  (s) => s.totalCount,
);
