/* eslint-disable arrow-parens */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountUserFacade } from '../store/facades';

@Injectable({
  providedIn: 'root',
})
export class AdminPageGuard implements CanActivate {
  constructor(private facade: AccountUserFacade) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.facade.role$.pipe(
      map(
        (role: string) =>
          role.includes('systemadmin') || role.includes('industryadmin'),
      ),
      catchError(() => of(false)),
    );
  }
}
