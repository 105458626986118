/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
import { AccountingIntegrationStatus } from '@app/shared/models';
import { createSelector } from '@ngrx/store';
import {
  AccountSettingsModel,
  ProfileSettingsModel,
} from 'app/shared/models/form-ui.models';
import { selectSharedState, SharedState } from 'app/shared/store/reducers';

import * as fromReducer from 'app/shared/store/reducers/account-user.reducer';

export const selectAccountUserState = createSelector(
  selectSharedState,
  (state: SharedState) => state.accountUser,
);
export const selectThemeMode = createSelector(
  selectAccountUserState,
  fromReducer.getThemeMode,
);
export const selectSasToken = createSelector(
  selectAccountUserState,
  fromReducer.getSasToken,
);

export const selectLoggedInUser = createSelector(
  selectAccountUserState,
  fromReducer.getLoggedInUser,
);
export const selectIsInitialised = createSelector(
  selectAccountUserState,
  fromReducer.getIsInitialised,
);
export const selectIsLoading = createSelector(
  selectAccountUserState,
  fromReducer.getIsLoading,
);
export const selectError = createSelector(
  selectAccountUserState,
  fromReducer.getError,
);

export const selectAccounts = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) =>
    state.loggedInUser.memberships.filter(
      (m) => m.acceptedDate && m.deleted === false,
    ),
);

export const selectInvitedAccounts = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) =>
    state.loggedInUser.memberships.filter(
      (m) =>
        m.acceptedDate == null && m.rejectedDate == null && m.deleted === false,
    ),
);

export const selectRecentTeamUserRemovedFrom = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) =>
    state.loggedInUser.memberships.filter(
      (m) => m.deleted && m.showNotification && m.acceptedDate != null,
    ),
);

export const selectAccountsToDisplayDialogFor = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) =>
    state.loggedInUser.memberships.filter(
      (m) =>
        m.acceptedDate == null &&
        m.rejectedDate == null &&
        m.showNotification === true &&
        m.deleted === false,
    ),
);

export const selectActiveAccount = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => state.selectedAccount,
);

export const selectAccountRole = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) =>
    state.selectedAccount.roles
      .map((x) => {
        return x.name;
      })
      .join()
      .toLowerCase(),
);

export const selectAccountRoles = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => state.selectedAccount.roles,
);

export const selectAccountForm = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState): AccountSettingsModel => {
    if (state.loggedInUser.account == null) {
      return undefined;
    }
    return {
      id: state?.loggedInUser?.account?.id,
      businessEntityName: state?.loggedInUser?.account?.name,
      abn: state?.loggedInUser?.account?.abn,
      email: state?.loggedInUser?.account?.email,
      website: state?.loggedInUser?.account?.website,
      phoneNumber: state?.loggedInUser?.account?.phoneNumber,
      mobileNumber: state?.loggedInUser?.account?.mobileNumber,
      logo: state?.loggedInUser?.account?.logo,
      defaultOnCost: state?.loggedInUser?.account?.defaultOnCost,
      address: {
        country: state?.loggedInUser?.account?.address?.country,
        suburb: state?.loggedInUser?.account?.address?.suburb,
        streetAddress: state?.loggedInUser?.account?.address?.streetAddress,
        state: state?.loggedInUser?.account?.address?.state,
        postCode: state?.loggedInUser?.account?.address?.postCode,
        placeId: state?.loggedInUser?.account?.address?.placeId,
        longitude: state?.loggedInUser?.account?.address?.longitude,
        latitude: state?.loggedInUser?.account?.address?.latitude,
        locationType: state?.loggedInUser?.account?.address?.locationType,
        formattedAddress:
          state?.loggedInUser?.account?.address?.formattedAddress,
      },
      quotePrefix: state?.loggedInUser?.account?.quotePrefix,
      quoteStartNumber: state?.loggedInUser?.account?.quoteStartNumber,
      invoicePrefix: state?.loggedInUser?.account?.invoicePrefix,
      invoiceStartNumber: state?.loggedInUser?.account?.invoiceStartNumber,
      purchaseOrderPrefix: state?.loggedInUser?.account?.purchaseOrderPrefix,
      purchaseOrderStartNumber:
        state?.loggedInUser?.account?.purchaseOrderStartNumber,
      // purchaseOrderAtCategoryLevel:
      //   state?.loggedInUser?.account?.purchaseOrderAtCategoryLevel,
      quoteTerms: state?.loggedInUser?.account?.quoteTerms,
      variationTerms: state?.loggedInUser?.account?.variationTerms,
      invoiceTerms: state?.loggedInUser?.account?.invoiceTerms,
      bankAccount: state?.loggedInUser?.account?.bankAccount,
      bankAccountName: state?.loggedInUser?.account?.bankAccountName,
      bankBSB: state?.loggedInUser?.account?.bankBSB,
      license: state?.loggedInUser?.account?.license,
      jobPrefix: state?.loggedInUser?.account?.jobPrefix,
      jobStartNumber: state?.loggedInUser?.account?.jobStartNumber,
    };
  },
);

export const selectProfileForm = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState): ProfileSettingsModel => {
    if (state.loggedInUser.user == null) {
      return undefined;
    }
    return {
      email: state.loggedInUser.user.person.email,
      phone: state.loggedInUser.user.person.phone,
      firstName: state.loggedInUser.user.person.firstName,
      lastName: state.loggedInUser.user.person.lastName,
    };
  },
);

export const selectAccount = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    if (state?.loggedInUser?.account == null) {
      return undefined;
    }
    return state.loggedInUser.account;
  },
);

export const selectAccountUser = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    if (state?.loggedInUser?.user == null) {
      return undefined;
    }
    return state.loggedInUser.user;
  },
);

export const selectAccountSections = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    const data = {
      company: !!state?.loggedInUser?.account?.name,
      address: !!state?.loggedInUser?.account?.address,
      abn: !!state?.loggedInUser?.account?.abn,
      payment:
        !!state?.loggedInUser?.account?.bankBSB &&
        !!state?.loggedInUser?.account?.bankAccount &&
        !!state?.loggedInUser?.account?.bankAccountName,
      phoneNumber: !!state?.loggedInUser?.account?.phoneNumber,
      //by default set it to true
      accountingSetupFinished: true,
    };

    //only check if the integration is Active
    if (state?.loggedInUser?.accountingIntegrationActive) {
      data.accountingSetupFinished = state?.loggedInUser.accountingSetupFinished
    }

    return data;
  }
);

export const selectIsAccountSetup = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    const setupState = (
      state?.loggedInUser?.account?.name != null &&
      state?.loggedInUser?.account?.name !== '' &&
      state?.loggedInUser?.account?.email != null &&
      state?.loggedInUser?.account?.email !== ''
    );

    if (state?.loggedInUser?.accountingIntegrationActive) {
      return setupState && state?.loggedInUser?.accountingSetupFinished;
    }

    return setupState;
  },
);

export const selectAccountPlans = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    return state.loggedInUser.account.accountPlans;
  },
);

export const selectActiveAccountPlan = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    const accPlans = state.loggedInUser.account.accountPlans.filter(
      (x) => x.accountId === state.selectedAccount.accountId && x.current,
    );

    if (accPlans.length === 0) {
      console.log('Account plans legth = 0');
      return null;
    }
    return accPlans[0];
  },
);

export const selectAccountPriceListStupProgress = createSelector(
  selectAccountUserState,
  (state: fromReducer.AccountUserState) => {
    return state.accountPriceListSetupProgress;
  },
);
