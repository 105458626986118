<mat-expansion-panel
  class="mb-2.5"
  [expanded]="expanded"
  (opened)="expanded = true"
  (closed)="expanded = false"
  hideToggle
>
  <mat-expansion-panel-header class="h-fit">
    <div class="w-full py-4 flex items-center">
      <div id="items-move-category-icon">
        <ng-content></ng-content>
      </div>
      <div class="mr-5 flex" id="items-expand-category-icon">
        <mat-icon [ngClass]="{ 'rotate-180': expanded }">
          expand_less
        </mat-icon>
      </div>
      <div>
        <span>{{ category.code }} | {{ category.name }}</span>
        <span>&nbsp;</span>
        <span>({{ items.length }} items)</span>
      </div>
      <div class="ml-auto flex items-center">
        <button
          *ngIf="!isReadOnly"
          id="items-add-category-icon"
          (click)="addCategoryItem(category, $event)"
          mat-icon-button
          matTooltip="Add new item"
          aria-label="Add new item"
        >
          <mat-icon
            class="icon-size-5 text-secondary"
            [svgIcon]="'heroicons_outline:plus-circle'"
          ></mat-icon>
        </button>
        <button
          *ngIf="!isReadOnly"
          id="items-edit-category-icon"
          (click)="editCategory(category, $event)"
          mat-icon-button
          matTooltip="Edit category"
          aria-label="Edit category"
        >
          <mat-icon
            class="icon-size-5 text-secondary"
            [svgIcon]="'heroicons_outline:pencil'"
          ></mat-icon>
        </button>
        <button
          *ngIf="!isReadOnly"
          (click)="deleteCategory(category, $event)"
          mat-icon-button
          aria-label="Delete category"
          matTooltip="Delete category"
        >
          <mat-icon
            class="icon-size-5 text-secondary"
            [svgIcon]="'heroicons_outline:trash'"
            aria-hidden="true"
            matPrefix
          ></mat-icon>
        </button>
      </div>
    </div>
  </mat-expansion-panel-header>
  <div class="flex flex-col flex-auto overflow-hidden">
    <div class="flex flex-row items-center mb-4">
      <ng-container *ngIf="!isReadOnly">
        <button
          class="fuse-mat-button-rounded"
          *ngIf="items.length > 0"
          [disabled]="!anySelected"
          [color]="'primary'"
          (click)="deleteSelectedItems()"
          mat-flat-button
        >
          <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:trash'">
          </mat-icon>
          <span class="ml-2"
            >Delete
            {{ selectedCount > 0 ? selectedCount : null }} selected</span
          >
        </button>
      </ng-container>
    </div>
    <div
      class="flex-col flex-auto"
      [ngStyle]="{ display: items.length === 0 ? 'none' : 'flex' }"
    >
      <div class="hidden lg:block">
        <table
          class="w-full"
          #table
          [dataSource]="dataSource"
          mat-table
          matSort
          matSortDisableClear
          matSortActive="itemCode"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="selected">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox
                *ngIf="!isReadOnly"
                [checked]="allSelected"
                (change)="selectAll($event)"
                color="primary"
              >
              </mat-checkbox>
            </th>
            <td *matCellDef="let element; let i = index" mat-cell>
              <mat-checkbox
                *ngIf="!isReadOnly"
                [checked]="isSelected$(element) | async"
                (change)="selectItem(element, $event)"
                color="primary"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="itemCode">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Cost Code</th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.itemCode }}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Description
            </th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="supplier">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Preferred Supplier
            </th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.supplier.name }}
            </td>
          </ng-container>
          <!--          <ng-container matColumnDef="supplierItemCode">-->
          <!--            <th *matHeaderCellDef mat-header-cell mat-sort-header>-->
          <!--              Supplier Item No.-->
          <!--            </th>-->
          <!--            <td *matCellDef="let element; let i = index" mat-cell>-->
          <!--              {{ element.supplierItemCode }}-->
          <!--            </td>-->
          <!--          </ng-container>-->
          <ng-container matColumnDef="unitPrice">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Unit Price
            </th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.unitPrice | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gstRate">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>GST Rate</th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.gstRate / 100 | percent }}
            </td>
          </ng-container>
          <ng-container matColumnDef="qty">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Qty</th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.qty }}
            </td>
          </ng-container>
          <ng-container matColumnDef="unitOfMeasure">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>UOM</th>
            <td *matCellDef="let element; let i = index" mat-cell>
              {{ element.unitOfMeasure }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let element; let i = index" mat-cell>
              <div class="flex justify-end" *ngIf="!isReadOnly">
                <button
                  (click)="editItem(element, category)"
                  mat-icon-button
                  aria-label="Edit item"
                >
                  <mat-icon
                    class="icon-size-5 text-secondary"
                    [svgIcon]="'heroicons_outline:pencil'"
                    aria-hidden="true"
                    matPrefix
                  ></mat-icon>
                </button>
                <button
                  (click)="deleteItems([element])"
                  mat-icon-button
                  aria-label="Delete item"
                >
                  <mat-icon
                    class="icon-size-5 text-secondary"
                    [svgIcon]="'heroicons_outline:trash'"
                    aria-hidden="true"
                    matPrefix
                  ></mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: displayedColumns; let i = index"
            mat-row
          ></tr>
        </table>
      </div>
      <!-- tablet and mobile list  -->
      <div
        class="lg:hidden flex flex-col flex-auto wrapper mt-2 overflow-y-auto"
      >
        <fuse-card
          class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2 dark:bg-gray-900"
          *ngFor="
            let element of dataSource?.data
              | slice: pageIndex * pageSize : pageIndex * pageSize + pageSize
          "
        >
          <div class="flex flex-col xs:w-full sm:w-1/3">
            <div class="text-md font-semibold leading-tight mb-2">
              <mat-checkbox
                *ngIf="!isReadOnly"
                [color]="'primary'"
                [checked]="isSelected$(element) | async"
                (change)="selectItem(element, $event)"
              >
              </mat-checkbox>
              Cost Code: {{ element.itemCode }}
            </div>
            <div class="text-secondary font-medium text-md">
              Description: {{ element.description }}
            </div>
            <div class="text-secondary font-medium text-md">
              Supplier: {{ element.supplier?.name }}
            </div>
            <div class="text-secondary font-medium text-md">
              Supplier Item No.: {{ element.supplierItemCode }}
            </div>
          </div>

          <div
            class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
          >
            <div class="text-md font-semibold leading-tight">
              Unit Price: {{ element.unitPrice | currency }}
            </div>
            <div class="text-secondary font-medium text-md">
              GST Rate: {{ element.gstRate / 100 | percent }}
            </div>
          </div>

          <div
            class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
          >
            <div class="text-md font-semibold leading-tigh">
              Unit total: {{ element.unitTotal | currency }}
            </div>
            <div class="text-secondary font-medium text-md">
              Unit price: {{ element.unitPrice | currency }}
            </div>
            <div class="text-secondary font-medium text-md">
              Qty: {{ element.qty }}
            </div>
            <div class="text-secondary font-medium text-md">
              UOM: {{ element.unitOfMeasure }}
            </div>
          </div>

          <div
            class="flex flex-col items-end w-1/4 space-y-2"
            *ngIf="!isReadOnly"
          >
            <button
              class="type-action mb-2"
              [color]="'primary'"
              (click)="editItem(element, category)"
              mat-stroked-button
            >
              <mat-icon [svgIcon]="'edit'" matPrefix></mat-icon>
            </button>
            <button
              class="type-action"
              [color]="'primary'"
              (click)="deleteItems([element])"
              mat-stroked-button
            >
              <mat-icon [svgIcon]="'delete_outline'" matPrefix></mat-icon>
            </button>
          </div>
        </fuse-card>
      </div>
      <mat-paginator
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="paginatorChange($event)"
        showFirstLastButtons="true"
      >
      </mat-paginator>
    </div>
    <div
      class="flex-col flex-auto"
      [ngStyle]="{ display: (items$ | async).length > 0 ? 'none' : 'flex' }"
    >
      <div class="mx-4 italic">No items in this category</div>
    </div>
  </div>
</mat-expansion-panel>
