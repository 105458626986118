import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EstimatePriceListItem, EstimateStatus,
  SingleSaveVariation,
  SingleSaveVariationSection,
} from 'app/shared/models';
import { filter, map } from 'rxjs';
import {
  addRemoveVariationPriceListItemsShowDialog,
  changeVariationStatus, clearVariationChangesState,
  deleteJobVariationPriceListItemConfirm,
  deleteVariationSectionConfirm,
  getJobVariation,
  moveVariationSectionDown,
  moveVariationSectionUp,
  removeJobVariationSectionPriceItemsFromCostingShowDialog,
  renameVariationSection,
  resetJobVariation,
  saveJobVariation,
  showAddSectionDialog,
  showAddVariationDialog,
  showRenameSectionDialog,
  updateJobCosting,
  updateJobVariationCustomerQuote,
  updateJobVariationName,
  updateJobVariationOnCost,
  updateJobVariationPriceListItem,
  updateJobVariationProfit,
  updateJobVariationSectionPriceListItemsShowDialog,
  updateVariationPriceListItemShowDialog,
  uploadVariationLetterLogo,
} from '../actions';
import { JobVariationDetailState } from '../reducers/job-variation-detail.reducer';

import {
  selectJobVariation,
  selectJobVariationError,
  selectJobVariationIsEditing,
  selectJobVariationIsLoaded,
  selectJobVariationIsLoading,
  selectJobVariationStatus,
  selectOriginatedVariation, selectVariationChanges,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobVariationDetailFacade {
  isLoading$ = this.store.select(selectJobVariationIsLoading);
  isLoaded$ = this.store.select(selectJobVariationIsLoaded);
  error$ = this.store.select(selectJobVariationError);
  variation$ = this.store.select(selectJobVariation);
  originatedVariation$ = this.store.select(selectOriginatedVariation);
  editing$ = this.store.select(selectJobVariationIsEditing);
  status$ = this.store.select(selectJobVariationStatus);
  variationChanges$ = this.store.select(selectVariationChanges);

  readonly$ = this.variation$.pipe(
    filter((v) => !!v),
    map(
      (variation) =>
        // !(
        //   variation.status === EstimateStatus.Draft ||
        //   variation.status === EstimateStatus.Quoted
        // )
        // false,
        variation.status === EstimateStatus.Accepted
    ),
  );

  isOriginal$ = this.variation$.pipe(
    filter((v) => !!v),
    map((variation) => variation.code === 0),
  );

  constructor(private store: Store<JobVariationDetailState>) {}

  showRemovePriceListItemsFromCosting(
    variation: SingleSaveVariation,
    section: SingleSaveVariationSection,
  ) {
    this.store.dispatch(
      removeJobVariationSectionPriceItemsFromCostingShowDialog({
        variation,
        section,
      }),
    );
  }

  showAddRemovePriceListItemsDialog(section: SingleSaveVariationSection) {
    this.store.dispatch(
      updateJobVariationSectionPriceListItemsShowDialog({ section }),
    );
  }

  updateOnCost(onCost: number): void {
    this.store.dispatch(updateJobVariationOnCost({ onCost }));
  }

  updateProfit(profit: number): void {
    this.store.dispatch(updateJobVariationProfit({ profit }));
  }

  updateCustomerQuote(customerQuote: number): void {
    this.store.dispatch(updateJobVariationCustomerQuote({ customerQuote }));
  }

  updateName(name: string) {
    this.store.dispatch(updateJobVariationName({ name }));
  }

  save() {
    this.store.dispatch(saveJobVariation());
  }

  updateJobVariation(jobCosting: SingleSaveVariation) {
    this.store.dispatch(updateJobCosting({ jobCosting }));
  }

  reset() {
    this.store.dispatch(resetJobVariation());
  }

  getJobVariation(variationId: string): void {
    this.store.dispatch(getJobVariation({ variationId }));
  }

  updatePriceListItem(item: EstimatePriceListItem) {
    this.store.dispatch(updateJobVariationPriceListItem({ item }));
  }

  deletePriceListItem(id: string) {
    this.store.dispatch(deleteJobVariationPriceListItemConfirm({ id }));
  }

  changeStatus(jobId: string, variationId: string, status: string) {
    this.store.dispatch(changeVariationStatus({ jobId, variationId, status }));
  }

  addSection(id: string) {
    this.store.dispatch(showAddSectionDialog());
  }
  addVariation(id: string) {
    this.store.dispatch(showAddVariationDialog({ jobId: id }));
  }

  moveVariationSectionDown(id: string) {
    this.store.dispatch(moveVariationSectionDown({ id }));
  }

  moveVariationSectionUp(id: string) {
    this.store.dispatch(moveVariationSectionUp({ id }));
  }

  deleteSection(id: string) {
    this.store.dispatch(deleteVariationSectionConfirm({ id }));
  }

  showRenameSectionDialog(sectionId: string, name: string): void {
    this.store.dispatch(showRenameSectionDialog({ sectionId, name }));
  }

  renameSection(id: string, name: string): void {
    this.store.dispatch(renameVariationSection({ id, name }));
  }

  updateVariationPriceListItemShowDialog(item: any) {
    this.store.dispatch(updateVariationPriceListItemShowDialog({ item }));
  }

  uploadVariationLetterLogo(variationId: string, file: File): void {
    this.store.dispatch(uploadVariationLetterLogo({ variationId, file }));
  }

  addRemoveVariationPriceListItemsShowDialog()  {
    this.store.dispatch(addRemoveVariationPriceListItemsShowDialog());
  }

  clearVariationChangesState() {
    this.store.dispatch(clearVariationChangesState());
  }
}
