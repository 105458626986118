<div class="grid gap-2" style="grid-template-columns: auto 1fr">
  <div>Name</div>
  <div>{{ photo.name }}</div>
  <div>Date</div>
  <div>
    {{ photo.takenDate ?? photo.updated | date: "dd MMM yyyy HH:mm:ss" }}
  </div>
  <div>Size</div>
  <div>{{ photo.size | filesize }}</div>
  <div>Link</div>
  <div class="underline text-blue cursor-pointer" (click)="openLink()">
    {{ photo.url + "?" + (photosFacade.sasToken$ | async) }}
  </div>
  <ng-container *ngIf="photo.description">
    <div>Description</div>
    <div>{{ photo.description }}</div>
  </ng-container>
</div>
<div class="w-1/3 mt-2" *ngIf="photo.latitude && photo.longitude">
  <app-google-map
    [longitude]="photo.longitude"
    [latitude]="photo.latitude"
    [height]="'30vh'"
  ></app-google-map>
</div>
