import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfigQuill, QuillEditorModalComponent } from 'app/shared/components';
import {
  SnippetArea,
  SnippetType,
  VariationSection,
} from 'app/shared/models/eazimate.models';
import { VariationLetterQuoteFacade } from '../../../../../store/facades/job-variation-letter.facade';
import { of } from 'rxjs';
import { JobVariationLetterFormService } from '../../../../../services/job-variation-letter-form.service';
import { AsyncPipe, NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-job-variation-letter-details',
  templateUrl: './job-variation-letter-details.component.html',
  styleUrls: ['./job-variation-letter-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatButtonModule,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    AsyncPipe,
  ],
})
export class JobVariationLetterDetailsComponent implements OnInit {
  variationSections: VariationSection[];
  selectedSectionIndex = 0;
  allowMultipleSections$ = of(true);

  constructor(
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    public variationLetterQuoteFacade: VariationLetterQuoteFacade,
    public variationFormService: JobVariationLetterFormService,
  ) {}

  ngOnInit(): void {}

  editDialog(formControl: FormControl, title: string): void {
    const snippetParams = {
      snippetType: SnippetType.Quote,
      snippetArea: SnippetArea.General,
    };

    const data: ConfigQuill = {
      content: formControl.value,
      title: title,
      noRequired: true,
      minlength: 0,
      snippetsParams: snippetParams,
    };
    const dialogRef = this.dialog.open(QuillEditorModalComponent, {
      width: '626px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        formControl.setValue(result);
        this.cdRef.detectChanges();
      }
    });
  }
}
