import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import {
  AccountUserGuard,
  AdminPageGuard,
  RequiredGuard,
} from './shared/guards';
import { DefaultPageGuard } from './shared/guards/default-page.guard';
import { SasGuard } from './shared/guards/sas.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Payment Required Routes
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(MsalGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AccountUserGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(RequiredGuard).canActivate(next, state),
    ],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'accounts',
        title: 'Accounts',
        loadChildren: () =>
          import('./views/accounts/accounts.module').then(
            (m) => m.AccountsModule,
          ),
      },
      {
        path: 'dashboard',
        title: 'Dashboard',
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'customers',
        title: 'Customers',
        canActivate: [DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/customers/customers.module').then(
            (m) => m.CustomersModule,
          ),
      },
      {
        path: 'call-forwards',
        title: 'Call Forwards',
        canActivate: [DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import(
            './views/jobs/components/job-detail/job-call-forward/job-call-forward.module'
          ).then((m) => m.JobCallForwardModule),
      },
      {
        path: 'suppliers',
        title: 'Suppliers',
        canActivate: [DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/suppliers/suppliers.module').then(
            (m) => m.SuppliersModule,
          ),
      },
      {
        path: 'jobs',
        title: 'Jobs',
        canActivate: [SasGuard, DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/jobs/jobs.module').then((m) => m.JobsModule),
      },
      {
        path: 'estimates',
        title: 'Estimates',
        canActivate: [SasGuard, DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/estimates/estimates.module').then(
            (m) => m.EstimatesModule,
          ),
      },
      {
        path: 'item-list',
        title: 'Item List',
        canActivate: [DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/price-list/price-list.module').then(
            (m) => m.PriceListModule,
          ),
      },
      {
        path: 'admin',
        title: 'Admin',
        canActivate: [AdminPageGuard],
        loadChildren: () =>
          import('./views/industries/industries.module').then(
            (m) => m.IndustryModule,
          ),
      },
      {
        path: 'templates',
        title: 'Templates',
        canActivate: [DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/templates/templates.module').then(
            (m) => m.TemplatesModule,
          ),
      },
      {
        path: 'library',
        title: 'library',
        canActivate: [SasGuard, DefaultPageGuard],
        canActivateChild: [
          (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(RequiredGuard).canActivateChild(next, state),
        ],
        loadChildren: () =>
          import('./views/library/library.module').then((m) => m.LibraryModule),
        data: { title: 'Library', breadcrumb: 'Library' },
      },
    ],
  },
  // Unpaid Routes
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(MsalGuard).canActivate(next, state),
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(AccountUserGuard).canActivate(next, state),
    ],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'industry-selector',
        title: 'Industry Selector',
        loadChildren: () =>
          import(
            './modules/auth/industry-selector/industry-selector.component.module'
          ).then((m) => m.IndustrySelectorModule),
      },
      {
        path: 'terms-and-conditions',
        title: 'Terms And Conditions',
        loadChildren: () =>
          import(
            './modules/auth/terms-and-conditions/terms-and-conditions.module'
          ).then((m) => m.TermsAndConditionsModule),
      },
      {
        path: 'payment-required',
        title: 'Payment Required',
        loadChildren: () =>
          import(
            './modules/auth/payment-required/payment-required.module'
          ).then((m) => m.PaymentRequiredModule),
      },
      {
        path: 'settings',
        title: 'Settings',
        loadChildren: () =>
          import('./views/settings/settings.module').then(
            (m) => m.SettingsModule,
          ),
        data: { title: 'Settings', breadcrumb: 'Settings' },
      },
      // Error
      {
        path: 'error',
        title: 'Error',
        children: [
          {
            path: '404',
            title: '404',
            loadChildren: () =>
              import(
                'app/modules/admin/pages/error/error-404/error-404.module'
              ).then((m) => m.Error404Module),
          },
          {
            path: '500',
            title: '500',
            loadChildren: () =>
              import(
                'app/modules/admin/pages/error/error-500/error-500.module'
              ).then((m) => m.Error500Module),
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'account-upgrade',
        title: 'Account Upgrade',
        loadChildren: () =>
          import(
            './modules/admin/pages/account-upgrade/account-upgrade.module'
          ).then((m) => m.AccountUpgradeModule),
      },
    ],
  },
];
