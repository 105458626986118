export class BillingStatus {
  public static readonly Init = 'Init';
  public static readonly Paid = 'Paid';
  public static readonly CancelledAtPeriodEnd = 'CancelledAtPeriodEnd';
  public static readonly RequiresActionPaymentFailed =
    'RequiresActionPaymentFailed';
  public static readonly RequiresActionPaymentFailedAllRetriesFailed =
    'RequiresActionPaymentFailedAllRetriesFailed';
  public static readonly SubscriptionNotActivated = 'SubscriptionNotActivated';
  public static readonly Cancelled = 'Cancelled';
}
