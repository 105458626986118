import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobCostingState = createSelector(
  selectJobState,
  (s: JobState) => s?.jobCosting,
);

export const selectJobCostingIsLoading = createSelector(
  selectJobCostingState,
  (s) => s?.callState === LoadingState.LOADING,
);

export const selectJobCostingIsLoaded = createSelector(
  selectJobCostingState,
  (s) => s?.callState === LoadingState.LOADED,
);

export const selectJobCostingError = createSelector(selectJobCostingState, (s) =>
  getError(s?.callState),
);

export const selectJobCosting = createSelector(
  selectJobCostingState,
  (s) => s?.job,
);
export const selectJobCostingSingle = createSelector(
  selectJobCostingState,
  (s) => s?.jobCosting,
);
export const selectJobCostingOriginal = createSelector(
  selectJobCostingState,
  (s) => s?.jobCostingOriginal,
);
export const selectJobCostingIsEditing = createSelector(
  selectJobCostingState,
  (s) => s?.editing,
);
export const selectJobCostingStatus = createSelector(
  selectJobCostingState,
  (s) => s?.jobCostingStatus,
);
export const selectVariation = createSelector(
  selectJobCostingState,
  (s) => s?.variation,
);
export const selectHistory = createSelector(
  selectJobCostingState,
  (s) => s?.history,
);
