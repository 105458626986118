import { createSelector } from '@ngrx/store';
import { LoadingState } from 'app/store';
import { selectJobState } from '../reducers';

export const selectJobNotesState = createSelector(
  selectJobState,
  (s) => s.notes,
);

export const selectJobNotesIsLoaded = createSelector(
  selectJobNotesState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobNotes = createSelector(
  selectJobNotesState,
  (s) => s.notes,
);
export const selectJobNotesTotalCount = createSelector(
  selectJobNotesState,
  (s) => s.totalCount,
);

export const selectJobNotesSortOrder = createSelector(
  selectJobNotesState,
  (s) => s.sortOrder,
);
