import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobOrderItemsToOrderState = createSelector(
  selectJobState,
  (s: JobState) => s.jobOrderItemsToOrderReducer,
);

export const selectJobOrderItemsToOrderIsLoading = createSelector(
  selectJobOrderItemsToOrderState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobOrderItemsToOrderIsLoaded = createSelector(
  selectJobOrderItemsToOrderState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobOrderItemsToOrderError = createSelector(
  selectJobOrderItemsToOrderState,
  (s) => getError(s.callState),
);

export const selectJobOrderItemsToOrder = createSelector(
  selectJobOrderItemsToOrderState,
  (s) => s.jobOrderItemsToOrder,
);
