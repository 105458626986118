import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  addJobOrderPriceListItem,
  addJobOrderPriceListItemFailure,
  addJobOrderPriceListItemSuccess,
  deleteJobOrderPriceListItem,
  deleteJobOrderPriceListItemFailure,
  deleteJobOrderPriceListItemSuccess,
  getJobOrderPriceListItem,
  getJobOrderPriceListItemFailure,
  getJobOrderPriceListItemSuccess,
  updateJobOrderPriceListItem,
  updateJobOrderPriceListItemFailure,
  updateJobOrderPriceListItemSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobOrderPriceListItemEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobOrderPriceListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobOrderPriceListItem),
      switchMap(({ request }) =>
        this.jobService.getJobOrderPriceListItem(request).pipe(
          map((jobOrder: any) => getJobOrderPriceListItemSuccess({ jobOrder })),
          catchError((error) => {
            return of(getJobOrderPriceListItemFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateJobOrderPriceListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobOrderPriceListItem),
      switchMap(({ request }) =>
        this.jobService.updateJobOrderPriceListItem(request).pipe(
          map((jobOrder: any) =>
            updateJobOrderPriceListItemSuccess({ jobOrder }),
          ),
          catchError((error) => {
            return of(updateJobOrderPriceListItemFailure({ error }));
          }),
        ),
      ),
    ),
  );

  addJobOrderPriceListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJobOrderPriceListItem),
      switchMap(({ request }) =>
        this.jobService.addJobOrderPriceListItem(request).pipe(
          map((jobOrder: any) => addJobOrderPriceListItemSuccess({ jobOrder })),
          catchError((error) => {
            return of(addJobOrderPriceListItemFailure({ error }));
          }),
        ),
      ),
    ),
  );

  deleteJobOrderPriceListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJobOrderPriceListItem),
      switchMap(({ request }) =>
        this.jobService.deleteJobOrderPriceListItem(request).pipe(
          map((jobOrder: any) =>
            deleteJobOrderPriceListItemSuccess({ jobOrder }),
          ),
          catchError((error) => {
            return of(deleteJobOrderPriceListItemFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
