/* eslint-disable arrow-parens */
import { createReducer, on } from '@ngrx/store';
import { UnitOfMeasure } from 'app/shared/models/eazimate.models';
import * as fromActions from '../actions/general-settings.actions';

export interface GeneralSettingsState {
  unitsOfMeasures: UnitOfMeasure[];
  isInitialised: boolean;
  isLoading: boolean;
  error: any;
}

export const initialState: GeneralSettingsState = {
  unitsOfMeasures: undefined,
  isInitialised: false,
  isLoading: false,
  error: undefined,
};

export const generalSettingsReducer = createReducer<GeneralSettingsState>(
  initialState,
  on(
    fromActions.getUnitOfMeasuresList,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.getUnitOfMeasuresListSuccess,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      unitsOfMeasures: payload,
      isLoading: false,
      isInitialised: true,
    }),
  ),
  on(
    fromActions.getUnitOfMeasuresListFailure,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  ),
  on(
    fromActions.addUnitOfMeasureSuccess,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      unitsOfMeasures: [...state.unitsOfMeasures, payload],
      isLoading: false,
      isInitialised: true,
    }),
  ),
  on(
    fromActions.addUnitOfMeasureFailure,
    fromActions.deleteUnitOfMeasureFailure,
    fromActions.updateUnitOfMeasureFailure,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      isLoading: false,
      error: payload,
    }),
  ),
  on(
    fromActions.deleteUnitOfMeasureSuccess,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      unitsOfMeasures: state.unitsOfMeasures.filter(
        (f) => !f.id.includes(payload.id),
      ),
      isLoading: false,
      isInitialised: true,
    }),
  ),
  on(
    fromActions.updateUnitOfMeasureSuccess,
    (state, { payload }): GeneralSettingsState => ({
      ...state,
      unitsOfMeasures: state.unitsOfMeasures.map((u) => {
        if (u.id === payload.id) {
          return payload;
        }
        return u;
      }),
      isLoading: false,
      isInitialised: true,
    }),
  ),
);

export const getIsInitialised = (state: GeneralSettingsState): boolean =>
  state.isInitialised;
export const getIsLoading = (state: GeneralSettingsState): boolean =>
  state.isLoading;
export const getError = (state: GeneralSettingsState): any => state.error;
export const getTeam = (state: GeneralSettingsState): UnitOfMeasure[] =>
  state.unitsOfMeasures;
