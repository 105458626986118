import { createAction, props } from '@ngrx/store';
import { FullQuote } from 'app/shared/models';

export enum EstimateQuoteActionTypes {
  GetEstimateQuote = '[Estimate List] Get Estimate Quote',
  GetEstimateQuoteSuccess = '[Estimate List] Get Estimate Quote Success',
  GetEstimateQuoteFailure = '[Estimate List] Get Estimate Quote Failure',

  UpdateEstimateQuote = '[Estimate List] Update Estimate Quote',
  UpdateEstimateQuoteSuccess = '[Estimate List] Update Estimate Quote Success',
  UpdateEstimateQuoteFailure = '[Estimate List] Update Estimate Quote Failure',

  GetQuoteDocumentSection = '[Estimate List] Get QuoteDocumentSection',
  GetQuoteDocumentSectionSuccess = '[Estimate List] Get QuoteDocumentSection Success',
  GetQuoteDocumentSectionFailure = '[Estimate List] Get QuoteDocumentSection Failure',

  UpdateQuoteDocumentSection = '[Estimate List] UpdateQuoteDocumentSection',
  UpdateQuoteDocumentSectionSuccess = '[Estimate List] UpdateQuoteDocumentSection Success',
  UpdateQuoteDocumentSectionFailure = '[Estimate List] UpdateQuoteDocumentSection Failure',
}

export const getEstimateQuote = createAction(
  EstimateQuoteActionTypes.GetEstimateQuote,
  props<{ estimateid: string }>(),
);
export const getEstimateQuoteSuccess = createAction(
  EstimateQuoteActionTypes.GetEstimateQuoteSuccess,
  props<{ estimateQuote: FullQuote }>(),
);
export const getEstimateQuoteFailure = createAction(
  EstimateQuoteActionTypes.GetEstimateQuoteFailure,
  props<{ error: any }>(),
);

export const updateEstimateQuote = createAction(
  EstimateQuoteActionTypes.UpdateEstimateQuote,
  props<{ fullQuote: FullQuote }>(),
);
export const updateEstimateQuoteSuccess = createAction(
  EstimateQuoteActionTypes.UpdateEstimateQuoteSuccess,
  props<{ estimateQuote: FullQuote }>(),
);
export const updateEstimateQuoteFailure = createAction(
  EstimateQuoteActionTypes.UpdateEstimateQuoteFailure,
  props<{ error: any }>(),
);

// quote document sections
export const getQuoteDocumentSection = createAction(
  EstimateQuoteActionTypes.GetQuoteDocumentSection,
  props<{ estimateid: string }>(),
);
export const getQuoteDocumentSectionSuccess = createAction(
  EstimateQuoteActionTypes.GetQuoteDocumentSectionSuccess,
  props<{ quoteDocumentSections: any[] }>(),
);
export const getQuoteDocumentSectionFailure = createAction(
  EstimateQuoteActionTypes.GetQuoteDocumentSectionFailure,
  props<{ error: any }>(),
);

export const updateQuoteDocumentSection = createAction(
  EstimateQuoteActionTypes.UpdateQuoteDocumentSection,
  props<{ quoteSections: any }>(),
);
export const updateQuoteDocumentSectionSuccess = createAction(
  EstimateQuoteActionTypes.UpdateQuoteDocumentSectionSuccess,
  props<{ quoteDocumentSections: any[] }>(),
);
export const updateQuoteDocumentSectionFailure = createAction(
  EstimateQuoteActionTypes.UpdateQuoteDocumentSectionFailure,
  props<{ error: any }>(),
);
