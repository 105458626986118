/* eslint-disable arrow-parens */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BaseIdNameDesc } from 'app/shared/models';
import { SearchArrayItemPipe } from 'app/shared/pipes/filter-array.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JobOrderSummaryFacade } from '../../store';
import { FuseCardComponent } from '../../../../../@fuse/components/card/card.component';
import { MatButtonModule } from '@angular/material/button';
import { FuseScrollbarDirective } from '../../../../../@fuse/directives/scrollbar/scrollbar.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgFor, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-job-order-summary',
  templateUrl: './job-order-summary.component.html',
  styleUrls: ['./job-order-summary.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    NgClass,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    NgFor,
    MatTooltipModule,
    FuseScrollbarDirective,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    FuseCardComponent,
    AsyncPipe,
  ],
})
export class JobOrderSummaryComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  @Input() suppliersOptions: BaseIdNameDesc[];
  @Input() isReadOnlyMode = false;
  @Input() expanded = false;
  @Output() createOrder = new EventEmitter();

  supplier = this.fb.control(0);
  displayedColumns: string[] = [
    'supplier',
    'priceListCategoryName',
    'notOrdered',
    'draft',
    'awaitingDelivery',
    'received',
    'action',
  ];
  dataSource;
  isOrdered = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public jobOrderSummaryFacade: JobOrderSummaryFacade,
    private fb: FormBuilder,
    private searchArrayItemPipe: SearchArrayItemPipe,
  ) {}

  ngAfterViewInit(): void {
    let summaryData;
    this.jobOrderSummaryFacade.jobOrderSummary$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((summary) => {
        summaryData = summary;
        this.dataSource = new MatTableDataSource(summary);
        this.dataSource.sort = this.sort;
      });
    this.supplier.valueChanges.subscribe((value) => {
      if (value) {
        const data = this.searchArrayItemPipe.transform(
          summaryData,
          'supplier',
          value.toString(),
        );
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource(summaryData);
        this.dataSource.sort = this.sort;
      }
    });
  }
  onCreateOrder(supplier) {
    this.isOrdered = true;
    this.createOrder.emit(supplier)
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
