/* eslint-disable arrow-parens */
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PhotosFacade } from '../../store';
import { NgxFilesizeModule } from 'ngx-filesize';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-photos-folder-list',
  templateUrl: './photos-folder-list.component.html',
  styleUrls: ['./photos-folder-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    NgClass,
    NgFor,
    MatButtonModule,
    MatTooltipModule,
    NgxFilesizeModule,
  ],
})
export class JobPhotosFolderListComponent implements OnInit, OnDestroy {
  @Input() folders: any;
  @Input() isReadOnlyMode: boolean = false;

  @Output() setCurrentFolderPath = new EventEmitter<string>();

  currentPath: string;
  jobId: string;
  showAllSelectors: boolean;
  allSelected: boolean;
  anySelected: boolean;

  private unsubscriber$ = new Subject<void>();

  constructor(
    private facade: PhotosFacade,
    private confirm: FuseConfirmationService,
    private route: ActivatedRoute,
  ) {}

  toggleSelectAll(): void {
    const paths = this.folders.map((f) => f.path);
    if (this.allSelected) {
      this.facade.deselectFolders(paths);
    } else {
      this.facade.selectFolders(paths);
    }
  }

  toggleSelected(folder: any): void {
    this.facade.toggleFolderSelected(folder.path);
  }

  deleteSelected(): void {
    const paths = this.folders.filter((f) => f.selected).map((f) => f.path);
    this.confirm
      .open({
        title: `Delete folder${paths.length > 1 ? 's' : ''}`,
        message: `Are you sure you want to delete ${
          paths.length > 1 ? 'these folders' : 'this folder'
        }?`,
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Delete' },
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((result) => result === 'confirmed'),
      )
      .subscribe(() => this.facade.deleteFolders(this.jobId, paths));
  }

  createNewFolder(): void {
    this.facade.createNewFolder(this.jobId, this.currentPath);
  }

  edit(path: string, e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.facade.showRenameFolderDialog(this.jobId, path);
  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.parent.parent.params['id'];

    // this.facade.jobId$
    //   .pipe(takeUntil(this.unsubscriber$))
    //   .subscribe((jobId) => (this.jobId = jobId));

    this.facade.currentFolder$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((folder) => (this.currentPath = folder?.path));

    this.facade.selectedJobFolderPaths$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((paths) => {
        this.showAllSelectors = paths.length > 0;
        this.allSelected = paths.length && paths.length === this.folders.length;
        this.anySelected = paths.length > 0;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
