import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { CurrencyPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseCardComponent } from '@fuse/components/card';
import {
  EstimatePriceListItem, JobCostingPriceListItem,
  PriceListCategory,
  Supplier,
} from '@shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { sortBy, uniqBy } from 'lodash';
import { JobVariationDetailFacade } from '@app/views/jobs/store';

@Component({
  selector: 'app-variation-price-list-items',
  templateUrl: './variation-price-list-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatExpansionModule,
    NgClass,
    NgIf,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTrimDirectiveModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    NgStyle,
    MatTableModule,
    MatCheckboxModule,
    FuseCardComponent,
    CurrencyPipe,
  ],
})
export class VariationPriceListItemsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() items: JobCostingPriceListItem[];
  @Input() readOnly: boolean;

  filterForm: FormGroup;
  nameForm: FormGroup;

  suppliers: Supplier[] = [];
  categories: PriceListCategory[] = [];

  displayedColumns: string[] = [
    'category',
    'itemcode',
    'description',
    'supplier',
    'qty',
    'uom',
    'unitPrice',
    'unitTotal',
    'onCost',
    'netQuote',
    'action',
  ];
  dataSource: any;

  get searchTerm(): FormGroup {
    return this.filterForm.get('searchTerm') as FormGroup;
  }

  private unsubscriber$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private facade: JobVariationDetailFacade,

  ) {
    this.filterForm = this.fb.group({
      searchTerm: [''],
      supplierId: [''],
      categoryId: [''],
    });
  }

  ngOnInit() {
    this.filterForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((filter) => {
        this.dataSource.filter = filter;
      });
  }

  ngOnChanges() {
    if (this.items) {
      this.dataSource = new MatTableDataSource(this.items);

      this.dataSource.filterPredicate = (item: JobCostingPriceListItem, filter) =>
        (item.description
            ?.toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.priceListCategoryName
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.priceListCategoryCode
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.supplier.name
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase())) &&
        (!filter.supplierId || item.supplierId === filter.supplierId) &&
        (!filter.categoryId || item.priceListCategoryId === filter.categoryId);

      this.suppliers = sortBy(
        uniqBy(
          this.items
            ?.filter((i) => i.supplier)
            .map((item) => item.supplier),
          (s) => s.id,
        ),
        (s) => s.name,
      );
      this.categories = sortBy(
        uniqBy(
          this.items?.map(
            (item) =>
              <PriceListCategory>{
                id: item.priceListCategoryId,
                code: item.priceListCategoryCode,
                name: item.priceListCategoryName,
              },
          ),
          (s) => s.id,
        ),
        (s) => s.name,
      );
    }
  }

  setQuotable(item: JobCostingPriceListItem): void {
    this.facade.updatePriceListItem({
      ...item,
      quotable: !item.quotable,
      netQuote: !item.quotable
        ? parseFloat((item.unitTotal * (1 + item.onCost / 100)).toFixed(2))
        : 0,
    });
  }

  addRemoveItems(): void {
    this.facade.addRemoveVariationPriceListItemsShowDialog();
  }

  deleteItem(id: string): void {
    this.facade.deletePriceListItem(id);
  }

  editItem(item: JobCostingPriceListItem): void {
    this.facade.updateVariationPriceListItemShowDialog(item);
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
