import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { XeroAccountSettingsDto } from '../xero/xero.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-integration-method-customization',
  templateUrl: './integration-method-customization.component.html',
  styleUrls: ['./integration-method-customization.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
  ],
})
export class IntegrationMethodCustomizationComponent implements OnInit {
  @Input() settings: XeroAccountSettingsDto;
  @Output() change = new EventEmitter();
  invoiceStatuses$ = of([
    {
      id: 1,
      name: 'AUTHORISED',
    },
    {
      id: 2,
      name: 'NON-AUTHORISED',
    },
  ]);
  paymentStatuses$ = of([
    {
      id: 10,
      name: 'Synced one way',
    },
    {
      id: 20,
      name: 'Synced both way',
    },
  ]);
  @Input()
  accountList: XeroAccountSettingsDto[];
  form = this._formBuilder.group({
    // export: [true],
    // purchaseOrderStatus: [1],
    paymentSychronizationType: [10],
    invoiceStatus: [2],
    salesAccountCode: ['', Validators.required],
    purchaseOrderAccountCode: ['', Validators.required],
  });
  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    console.log(this.settings);
    this.form.patchValue(this.settings);
  }

  cancel() {
    console.log('cancel');
  }
  save() {
    console.log('save');
    this.change.emit(this.form.value);
  }
}
