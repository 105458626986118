import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobNote } from 'app/shared/models';
import { of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  addJobNote,
  addJobNoteFailure,
  addJobNoteSuccess,
  deleteJobNote,
  deleteJobNoteFailure,
  deleteJobNoteSuccess,
  getJobNotes,
  getJobNotesFailure,
  getJobNotesSuccess,
  updateJobNote,
  updateJobNoteFailure,
  updateJobNoteSuccess,
} from '..';
import { JobService } from '../../services/job.service';

@Injectable({
  providedIn: 'root',
})
export class JobNotesEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  addJobNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJobNote),
      switchMap(({ note }) =>
        this.jobService.addJobNote(note).pipe(
          map((note: JobNote) => addJobNoteSuccess({ note })),
          catchError((error) => {
            return of(addJobNoteFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateJobNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobNote),
      switchMap(({ note }) =>
        this.jobService.updateJobNote(note).pipe(
          map((note: JobNote) => updateJobNoteSuccess({ note })),
          catchError((error) => {
            return of(updateJobNoteFailure({ error }));
          }),
        ),
      ),
    ),
  );

  deleteJobNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJobNote),
      switchMap(({ id }) =>
        this.jobService.deleteJobNote(id).pipe(
          map((id: string) => deleteJobNoteSuccess({ id })),
          catchError((error) => {
            return of(deleteJobNoteFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getJobNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobNotes),
      switchMap(({ request }) =>
        this.jobService.getJobNotes(request).pipe(
          map((response) => getJobNotesSuccess({ notes: response })),
          catchError((error) => of(getJobNotesFailure({ error }))),
        ),
      ),
    ),
  );
}
