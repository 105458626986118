import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) { }

  getSubscriptionClientSecret() {
    const url = `${environment.urls.api}/billing/subscription/clientsecret`;
    return this._httpClient.get<any>(url);
  }

  createSubscription() {
    const url = `${environment.urls.api}/billing/subscription`;
    return this._httpClient.post<any>(url, {});
  }

  getManagementPortal() {
    const url = `${environment.urls.api}/billing/management`;
    return this._httpClient.post<any>(url, {});
  }

  resubscribe(planId: string) {
    const url = `${environment.urls.api}/billing/resubscribe?planid=${planId}`;
    return this._httpClient.put<any>(url, {});
  }

  switchPlan(planId: string) {
    const url = `${environment.urls.api}/billing/changeplan?newplanid=${planId}`;
    return this._httpClient.put<any>(url, {});
  }

  getSwitchPlanCosts(planId: string) {
    const url = `${environment.urls.api}/billing/changeplan?newplanid=${planId}`;
    return this._httpClient.get<any>(url, {});
  }

  getExtraSeatCosts() {
    const url = `${environment.urls.api}/billing/extraseatchange`;
    return this._httpClient.get<any>(url);
  }

  getRemoveExtraSeatCosts() {
    const url = `${environment.urls.api}/billing/removeextraseatchange`;
    return this._httpClient.get<any>(url);
  }

  addExtraSeat() {
    const url = `${environment.urls.api}/billing/extraseat`;
    return this._httpClient.put<number>(url, {});
  }

  removeExtraSeat() {
    const url = `${environment.urls.api}/billing/removeextraseat`;
    return this._httpClient.put<number>(url, {});
  }

  createAccountPlanPaid(payload: {
    planId,
    customerId,
    subscriptionId,
    priceId
  }) {
    const url = `${environment.urls.api}/accountplan/paid`;
    return this._httpClient.post<any>(url, payload);
  }
}
