<div
  class="xs:text-xl sm:text-2xl md:text-4xl font-extrabold tracking-tight leading-none border-b pb-3 xs:-mx-3 md:mx-0"
>
  {{ title }}
</div>
<div
  class="xs:-mx-3 md:mx-0"
  [ngStyle]="{ 'max-height': '80vh', 'overflow-y': 'auto' }"
>
  <mat-stepper
    class=""
    #stepper
    [formGroup]="form"
    [class.small-screen]="isScreenSmall"
    [orientation]="isMobile ? 'vertical' : 'horizontal'"
    linear
  >
    <!-- nameGroup -->
    <mat-step [stepControl]="form.get('nameGroup')" formGroupName="nameGroup">
      <ng-template matStepLabel>Name</ng-template>
      <div class="mb-3">
        Give the {{ data.title.toLowerCase() }} name. e.g. French Street Reno
      </div>
      <mat-form-field class="w-full">
        <mat-label>{{ data.title }} name</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_outline:calendar'"
          matPrefix
        ></mat-icon>
        <input
          formControlName="name"
          matInput
          placeholder="Type an Estimate name"
        />
        <mat-error
          *ngIf="form.get('nameGroup').get('name').hasError('required')"
        >
          This field is required
        </mat-error>
      </mat-form-field>
      <div class="mb-3">
        Optionally add a description of the work. this doesn't appear on the
        quote.
      </div>
      <div class="flex items-center">
        <div class="mr-2">Description</div>
        <app-speach-to-text
          [isClearText]="true"
          formControlName="description"
        ></app-speach-to-text>
      </div>
      <mat-form-field class="fuse-mat-no-subscript fuse-mat-textarea w-full">
        <!--            <mat-icon class="icon-size-5  opacity-100 text-primary-600 dark:text-primary-100" matPrefix [svgIcon]="'heroicons_outline:user'"></mat-icon>-->
        <textarea
          formControlName="description"
          matInput
          placeholder="Type description"
        ></textarea>
      </mat-form-field>
      <div class="mt-4 flex flex-row justify-end">
        <button
          class="rounded-4xl px-8"
          [color]="'primary'"
          matStepperNext
          type="button"
          mat-flat-button
        >
          Next
        </button>
      </div>
    </mat-step>

    <!--    customerGroup    -->
    <mat-step
      [stepControl]="form.get('customerGroup')"
      formGroupName="customerGroup"
    >
      <ng-template matStepLabel>Customer</ng-template>

      <div class="mb-4">Look up or create a new customer</div>
      <div class="mb-4">
        <mat-radio-group
          aria-label="Select type"
          formControlName="customerCreationType"
          color="primary"
        >
          <mat-radio-button class="mr-3" value="1">Look up</mat-radio-button>
          <mat-radio-button value="2">Add new</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field
        class="w-full"
        #origin="matAutocompleteOrigin"
        *ngIf="customerGroup.get('customerCreationType').value === '1'"
        matAutocompleteOrigin
      >
        <mat-label>Customer</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_outline:magnifying-glass'"
          matPrefix
        ></mat-icon>
        <input
          [matAutocomplete]="auto"
          [matAutocompleteConnectedTo]="origin"
          matInput
          placeholder="Type to search a customer by name"
          formControlName="customerSearch"
        />
        <mat-error
          *ngIf="customerGroup.get('customerSearch')?.hasError('required')"
        >
          This field is required
        </mat-error>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let customer of customers"
          [value]="customer.person.fullName"
          (onSelectionChange)="onCustomerChange(customer)"
        >
          {{ customer.person.fullName }}
          <span class="ml-2 text-gray-600">{{
            customer.address.streetAddress
          }}</span>
        </mat-option>
      </mat-autocomplete>

      <div
        class="mb-4 mt-2"
        *ngIf="customerGroup.get('customerCreationType').value === '2'"
      >
        <app-customer-form
          [displayActionButtons]="false"
          (formInit)="onNewCustomerInitialized($event)"
        ></app-customer-form>
      </div>
      <div class="flex flex-row justify-end">
        <button
          class="rounded-4xl px-8 mr-4"
          mat-stroked-button
          matStepperPrevious
          type="button"
        >
          Back
        </button>
        <button
          class="rounded-4xl px-8"
          [color]="'primary'"
          (click)="customerHandler()"
          matStepperNext
          mat-flat-button
        >
          Next
        </button>
      </div>
    </mat-step>

    <!--    addressGroup    -->
    <mat-step
      [stepControl]="form.get('addressGroup')"
      formGroupName="addressGroup"
    >
      <div class="mb-4">Optionally specify the address of work</div>
      <div class="mb-4">
        <mat-checkbox color="primary" formControlName="useCustomerAddress"
          >Same as customer address
        </mat-checkbox>
      </div>
      <ng-template matStepLabel>Address</ng-template>
      <div class="mb-4">
        <mat-form-field
          class="w-full"
          *ngIf="!form.get('addressGroup').get('useCustomerAddress').value"
          floatLabel="always"
        >
          <mat-label>Address lookup</mat-label>
          <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_solid:magnifying-glass'"
            matPrefix
          ></mat-icon>
          <input
            matInput
            addressLookup
            [value]="formattedAddress"
            (addressSelected)="addressSelected($event)"
            placeholder="Type to search for an address"
          />
          <mat-error
            *ngIf="
              form
                .get('addressGroup')
                .get('formattedAddress')
                .hasError('required')
            "
          >
            This field is required
          </mat-error>
        </mat-form-field>

        <div class="sm:col-span-10">
          <mat-form-field class="w-full">
            <mat-label>Address</mat-label>
            <input type="text" matInput formControlName="streetAddress" />
            <mat-error
              *ngIf="
                form
                  .get('addressGroup')
                  .get('streetAddress')
                  .hasError('required')
              "
            >
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="w-full flex flex-none flex-col md:flex-row">
          <div class="w-full sm:w-1/2 mr-2">
            <mat-form-field class="w-full">
              <mat-label>Suburb</mat-label>
              <input type="text" matInput formControlName="suburb" />
              <mat-error
                *ngIf="
                  form.get('addressGroup').get('suburb').hasError('required')
                "
              >
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/4 mr-2">
            <mat-form-field class="w-full">
              <mat-label>State</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state">
                  {{ state }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  form.get('addressGroup').get('state').hasError('required')
                "
              >
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/4">
            <mat-form-field class="w-full">
              <mat-label>Postcode</mat-label>
              <input
                type="text"
                matInput
                formControlName="postCode"
                maxlength="4"
              />
              <mat-error
                *ngIf="
                  form.get('addressGroup').get('postCode').hasError('required')
                "
              >
                This field is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <button
          class="rounded-4xl px-8 mr-4"
          mat-stroked-button
          matStepperPrevious
          type="button"
        >
          Back
        </button>
        <button
          class="rounded-4xl px-8"
          [color]="'primary'"
          matStepperNext
          mat-flat-button
        >
          Next
        </button>
      </div>
    </mat-step>

    <!--  CopyFromGroup      -->
    <mat-step
      [stepControl]="form.get('copyFromGroup')"
      formGroupName="copyFromGroup"
    >
      <ng-template matStepLabel>Copy</ng-template>
      <div class="mb-4">
        Optionally copy the item list from another {{ data.title }} or template
      </div>

      <div class="mb-4">
        <mat-radio-group
          aria-label="Select type"
          formControlName="jobCloneType"
          color="primary"
        >
          <mat-radio-button class="mr-3" value="0">Don't copy</mat-radio-button>
          <mat-radio-button class="mr-3" value="1"
            >Copy from template</mat-radio-button
          >
          <mat-radio-button value="2"
            >Copy from Job or Estimate</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <mat-form-field
        class="w-full"
        *ngIf="copyFromGroup.get('jobCloneType').value === '1'"
      >
        <mat-label>Template to copy</mat-label>
        <mat-select formControlName="template">
          <mat-option
            *ngFor="let template of templates$ | async"
            [value]="template.id"
          >
            {{ template.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get('copyFromGroup.template').hasError('required')"
        >
          This field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="w-full"
        *ngIf="copyFromGroup.get('jobCloneType').value === '2'"
      >
        <mat-label>Job or Estimate to copy</mat-label>
        <mat-select formControlName="job">
          <mat-option *ngFor="let job of jobs$ | async" [value]="job.id">
            {{ job.jobTitle || job.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('copyFromGroup.job').hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="w-full"
        *ngIf="
          copyFromGroup.get('jobCloneType').value === '2' &&
          copyFromGroup.get('job').value &&
          jobType === 10
        "
      >
        <mat-label>Select estimate</mat-label>
        <mat-select formControlName="estimate">
          <mat-option *ngFor="let estimate of estimates" [value]="estimate.id">
            {{ estimate.name || estimate.id }} {{ estimate.version ? ' - Ver.' + estimate.version : ''}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get('copyFromGroup.estimate').hasError('required')"
        >
          This field is required
        </mat-error>
      </mat-form-field>

      <div class="flex flex-row justify-end mt-8">
        <button
          class="rounded-4xl px-8 mr-4"
          mat-stroked-button
          matStepperPrevious
          type="button"
        >
          Back
        </button>
        <button
          [disabled]="isWizardFinished"
          class="rounded-4xl px-8 mr-4"
          [color]="'primary'"
          (click)="save()"
          matStepperNext
          mat-flat-button
        >
          Finish
        </button>
        <button
          [disabled]="isWizardFinished"
          class="rounded-4xl px-8"
          [color]="'primary'"
          (click)="save(true)"
          matStepperNext
          mat-flat-button
        >
          Finish & Open
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
