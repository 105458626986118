import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import {
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { BillingService } from 'app/core/billing/billing.service';
import { FuseConfig } from '@fuse/services/config';
import { AccountUserService } from 'app/shared/services/account-user.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { BillingStatus } from 'app/views/settings/components/billing-settings/billing-settings.types';
import {
  StripeElementsDirective,
  StripePaymentElementComponent,
  StripeServiceInterface,
  injectStripe,
} from 'ngx-stripe';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { tap, takeUntil, finalize, mergeMap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { environment } from '@env/environment';

export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
    (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (
            retryAttempt > maxRetryAttempts ||
            excludedStatusCodes.find((e) => e === error.status)
          ) {
            return throwError(error);
          }
          console.log(
            `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration
            }ms`,
          );
          // retry after 1s, 2s, etc...
          return timer(retryAttempt * scalingDuration);
        }),
        finalize(() => console.log('We are done!')),
      );
    };

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-payment-required',
  templateUrl: './payment-required.component.html',
  styleUrls: ['./payment-required.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    NgIf,
  ],
})
export class PaymentRequiredComponent implements OnInit {
  @ViewChild(StripeElementsDirective) elements!: StripeElementsDirective;
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  config: FuseConfig;
  payMessage = "";
  private isTrial = false;

  private clientSecret = '';
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
    },
  };

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  paymentSuccessful = false;
  paymentMessage = '';
  requiresPayment = true;
  requiresAction = false;
  disabled = false;
  paying = false;
  stripe: StripeServiceInterface;
  createSubscriptionResponse: {
    planId,
    customerId,
    subscriptionId,
    priceId,
    clientSecret,
    type
  };

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private billingService: BillingService,
    private confirmationService: FuseConfirmationService,
    private fuseConfigService: FuseConfigService,
    private accountUserService: AccountUserService,
  ) {
    this.stripe = injectStripe(environment.stripe);
  }

  private elementsSetup = (response) => {
    console.log('elements response', response);
    //get our client secret and use that to initialize stripe elements
    if (response.clientSecret) {
      this.clientSecret = response.clientSecret;
      this.elementsOptions.clientSecret = this.clientSecret;

      switch (this.config.scheme) {
        case 'dark': {
          this.elementsOptions.appearance.theme = 'night';
          break;
        }
        case 'light': {
          this.elementsOptions.appearance.theme = 'stripe';
          break;
        }
        case 'auto': {
          this.elementsOptions.appearance.theme = 'night';
          break;
        }
      }

      this.elements?.update(this.elementsOptions)
    }
  };

  fetchUpdates() {
    this.elements.fetchUpdates();
  }

  update(options: Partial<StripePaymentElementOptions>) {
    this.paymentElement.update(options);
  }
  ngOnInit(): void {
    // Subscribe to config changes
    this.fuseConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: FuseConfig) => {
        // Store the config
        this.config = config;

        this.accountUserService
          .getSubscriptionStatus()
          .pipe(
            tap((data) => {
              const subscriptionStatus = data.status;
              console.log('PAYMENT REQUIRED check data:::', data);
              //check our subscription status

              if (subscriptionStatus === BillingStatus.Paid) {
                //this means the subscription is active and paid
                this.router.navigate(['/']);
              } else {
                //anything else we want to create a new subscription
                this.billingService
                  .createSubscription()
                  .subscribe((response: {
                    subscriptionId,
                    clientSecret,
                    planId,
                    priceId,
                    customerId,
                    type
                  }) => {
                    this.elementsSetup(response);
                    this.createSubscriptionResponse = response;

                    this.isTrial = response.type === "setup";

                    if (this.isTrial) {
                      this.payMessage = "Start Trial"
                    }
                    else {
                      this.payMessage = "Pay";
                    }

                  },
                    (err) => {
                      console.log(err);
                    });
              }
            }),
          )
          .subscribe();
      });
  }

  pay(): void {
    if (this.paying) return;
    this.paying = true;

    if (this.createSubscriptionResponse.type === "setup") {

      const title = 'Welcome to Eazimate'; 
      const message = 'Thank you for subscribing'; 

      this.stripe.confirmSetup({
        elements: this.paymentElement.elements,
        redirect: 'if_required',
      })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (x) => {
            console.log('pay response', x);
            if (x.error) {
              //show the payment error message
              this.paymentMessage = x.error.message;
              this.paying = false;
            } else {
              if (x.setupIntent.status === 'succeeded') {
                //reset our message
                this.paymentMessage = '';
                this.disabled = true;
                this.paying = false;

                this.billingService.createAccountPlanPaid(this.createSubscriptionResponse)
                  .subscribe(result => {
                    console.log(result);
                    const dialogRef = this.confirmationService.open({
                      title: title,
                      message: message,
                      icon: {
                        name: 'heroicons_outline:check-circle',
                        color: 'success',
                      },
                      actions: {
                        confirm: {
                          label: 'Ok',
                          color: 'primary',
                        },
                        cancel: {
                          show: false,
                        },
                      },
                      dismissible: true,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                      this.router.navigate(['/']);
                    });

                  });

              }
            }
          },
          (err) => {
            console.log(err);
          },
        );

    }
    else {
      this.stripe
        .confirmPayment({
          elements: this.paymentElement.elements,
          redirect: 'if_required',
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (x) => {
            console.log('pay response', x);
            if (x.error) {
              //show the payment error message
              this.paymentMessage = x.error.message;
              this.paying = false;
            } else {
              if (x.paymentIntent.status === 'succeeded') {
                //reset our message
                this.paymentMessage = '';
                this.disabled = true;
                this.paying = false;

                this.billingService.createAccountPlanPaid(this.createSubscriptionResponse)
                  .subscribe(result => {
                    console.log(result);

                    const dialogRef = this.confirmationService.open({
                      title: 'Payment successful',
                      message: 'Thank you for subscribing',
                      icon: {
                        name: 'heroicons_outline:check-circle',
                        color: 'success',
                      },
                      actions: {
                        confirm: {
                          label: 'Ok',
                          color: 'primary',
                        },
                        cancel: {
                          show: false,
                        },
                      },
                      dismissible: true,
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                      this.router.navigate(['/']);
                    });

                  });

              }
            }
          },
          (err) => {
            console.log(err);
          },
        );

    }


  }
}
