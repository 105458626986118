import { JobDetailFacade } from './job-detail.facade';
import { JobHistoryFacade } from './job-history.facade';
import { JobInvoicesFacade } from './job-invoices.facade';
import { JobListFacade } from './job-list.facade';
import { JobNotesFacade } from './job-notes.facade';
import { JobOrderDetailsFacade } from './job-order-details.facade';
import { JobOrderItemsToOrderFacade } from './job-order-items-to-order.facade';
import { JobOrdersListFacade } from './job-order-list.facade';
import { JobOrderPriceListItemFacade } from './job-order-price-list-item.facade';
import { JobOrderSummaryFacade } from './job-order-summary.facade';
import { JobVariationAddRemoveCostingFacade } from './job-variation-add-remove-costing.facade';
import { JobVariationDetailFacade } from './job-variation-detail.facade';
import { VariationLetterQuoteFacade } from './job-variation-letter.facade';
import { JobVariationsListFacade } from './job-variations-list.facade';
import { SuppliersListByJobFacade } from './suppliers-list-by-job.facade';

export * from './job-history.facade';
export * from './job-detail.facade';
export * from './job-invoices.facade';
export * from './job-list.facade';
export * from './job-notes.facade';
export * from './job-order-details.facade';
export * from './job-order-items-to-order.facade';
export * from './job-order-list.facade';
export * from './job-order-price-list-item.facade';
export * from './job-order-summary.facade';
export * from './job-variation-add-remove-costing.facade';
export * from './job-variation-detail.facade';
export * from './job-variations-list.facade';
export * from './suppliers-list-by-job.facade';

export const facades = [
  JobListFacade,
  JobVariationAddRemoveCostingFacade,
  JobDetailFacade,
  JobOrdersListFacade,
  JobOrderDetailsFacade,
  JobNotesFacade,
  JobHistoryFacade,
  JobOrderPriceListItemFacade,
  JobInvoicesFacade,
  SuppliersListByJobFacade,
  JobOrderSummaryFacade,
  JobOrderItemsToOrderFacade,
  JobVariationsListFacade,
  JobVariationDetailFacade,
  VariationLetterQuoteFacade,
];
