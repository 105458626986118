export interface JobOrderStatusParams {
  id: string;
  type: 'awaitingdelivery' | 'received' | 'draft' | 'void';
}

export interface JobOrderSendStatusParams {
  id: string;
  type: 'sent' | 'notsent';
}

export enum JobOrderStatus {
  'Draft' = 'draft',
  'Awaiting delivery' = 'awaitingdelivery',
  'Received' = 'received',
  'Void' = 'void',
}
