/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  PriceList,
  PriceListCategory,
  PriceListItem,
  Supplier,
  SupplierParameters,
} from 'app/shared/models';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  skipUntil,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { PriceListCategoryDialogComponent } from '..';
import { PriceListItemAddEdit } from '../../models';
import { CurrentPriceListFacade } from '../../store/facades';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
  CdkDragHandle,
} from '@angular/cdk/drag-drop';
import { SupplierListFacade } from 'app/views/suppliers/store/facades';
import { AsyncPipe, NgIf, NgFor, NgClass } from '@angular/common';
import { cloneDeep } from 'lodash-es';
import { ToastService } from 'app/shared/services/toast.service';
import {
  GuidedTourNameEnum,
  IntroJsService,
} from 'app/shared/services/introjs.service';
import { GuidedTourService } from 'app/shared/services/guided-tour.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { PriceListCategoryComponent } from '../price-list-category/price-list-category.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

interface PriceListFilter {
  searchTerm: string;
  category: PriceListCategory | undefined;
  supplier: Supplier | undefined;
}

@Component({
  selector: 'app-price-list-container',
  templateUrl: './price-list-container.component.html',
  styleUrls: ['./price-list-container.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgFor,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    NgClass,
    PriceListCategoryComponent,
  ],
})
export class PriceListContainerComponent implements OnInit, OnDestroy {
  filter$: Observable<string>;
  showEmpty$: Observable<boolean>;
  allCategories$ = this.facade.allCategories$;
  categories$: Observable<
    {
      items: PriceListItem[];
      category: PriceListCategory;
    }[]
  >;

  isLoaded$ = this.facade.isLoaded$.pipe(filter((loaded) => loaded));

  searchControl = new FormControl();
  showEmptyControl = new FormControl(true);
  isReadOnly;
  category;
  categories;
  items;

  intro;

  private unsubscriber$ = new Subject<void>();
  constructor(
    private facade: CurrentPriceListFacade,
    private dialog: MatDialog,
    private supplierListFacade: SupplierListFacade,
    private async: AsyncPipe,
    private toast: ToastService,
    private accountUserFacade: AccountUserFacade,
    private introJsService: IntroJsService,
    private guidedTourService: GuidedTourService,
    private cdRef: ChangeDetectorRef,
  ) {
    const query: SupplierParameters = {
      pageNumber: 1,
      pageSize: 99999,
      orderBy: [{ orderBy: 'name', descending: false }],
    };

    this.supplierListFacade.loadSupplierList(query);
    this.isReadOnly =
      this.async
        .transform(this.accountUserFacade.activeAccount$)
        .roles.filter(
          (r) =>
            r.name.toLocaleLowerCase() === 'itemlist' && r.accessLevel === 0,
        ).length === 1;
  }

  newCategory(): void {
    this.dialog.open(PriceListCategoryDialogComponent, {
      width: '626px',
      data: {
        isEdit: false,
        category: null,
        priceListItem: null,
        currentPriceList: null,
      },
    });
  }

  trackByFn(index: number, item: any): any {
    return item.category.id;
  }

  textFilter = (item: PriceListItem, searchTerm: string): boolean => {
    return (
      item.itemCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.supplierItemCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.priceListCategory.description
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.supplier.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  ngOnInit(): void {
    this.introJsService.currentIntroPage$.next(GuidedTourNameEnum.ItemsList);

    this.facade.getCurrentPriceList();
    this.filter$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      takeUntil(this.unsubscriber$),
      debounceTime(250),
    );

    combineLatest([this.facade.fullPriceList$, this.filter$])
      .pipe(
        takeUntil(this.unsubscriber$),
        skipUntil(this.isLoaded$),
        map(([{ priceListCategories, priceListItems }, filter]) =>
          priceListCategories.map((category) => {
            const items = priceListItems
              .filter((i) => i.priceListCategory.id === category.id)
              .filter((i) => this.textFilter(i, filter));
            return { category, items };
          }),
        ),
        map((items) => items.sort(this.sortByOrder)),
      )
      .subscribe((categories) => {
        this.categories = categories;

        this.introJsService.resetedTour$
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe(() => {
            this.introInit();
          });
        this.guidedTourService
          .getGuidedTourShow(btoa(GuidedTourNameEnum.ItemsList))
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe((show) => {
            if (show) {
              this.introInit();
            }
          });
      });
  }

  introInit(): void {
    this.intro = this.introJsService.getIntro();
    this.intro.onexit(() => {
      this.guidedTourService
        .completeGuidedTour(btoa(GuidedTourNameEnum.ItemsList))
        .subscribe();
      this.intro.setOptions({});
      this.intro = null;
    });
    this.cdRef.detectChanges();
    this.introJsService.itemsList(this.intro, this.isReadOnly);
  }

  sortByOrder(a, b): number {
    if (a.category.displayOrder < b.category.displayOrder) {
      return -1;
    }
    if (a.category.displayOrder > b.category.displayOrder) {
      return 1;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.introJsService.currentIntroPage$.next(null);

    // Unsubscribe from all subscriptions
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (this.async.transform(this.facade.error$)) {
      this.toast.error('Reordering operation failed. Please try again later');
      return;
    }
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    const data = cloneDeep(this.categories).map((c: any, index) => {
      c.category.displayOrder = index;
      return c.category;
    });
    this.facade.updateCurrentPriceListCategory(data);
  }
}
