import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GuidedTourService } from './guided-tour.service';
// import introJs, { IntroJs } from 'intro.js';
// import * as introJs from 'intro.js/intro.js';

declare const introJs: any;

// USE btoa(GuidedTourNameEnum) for guidedTourName
export enum GuidedTourNameEnum {
  Empty = 'Empty',
  EstimateList = 'EstimateList',
  JobsList = 'JobsList',
  EstimateCosting = 'EstimateCosting',
  EstimateCostingDetails = 'EstimateCostingDetails',
  DashboardWelcome = 'DashboardWelcome',
  ItemsList = 'ItemsList',
  CallForwards = 'CallForwards',
  JobOrderList = 'JobOrderList',
  JobOrder = 'JobOrder',
  JobVariationList = 'JobVariationList',
  JobVariation = 'JobVariation',
  InvoiceList = 'InvoiceList',
  Invoice = 'Invoice',
  SnippetsList = 'SnippetsList',
  TemplatesList = 'TemplatesList',
  Template = 'Template',
}

@Injectable({
  providedIn: 'root',
})
export class IntroJsService {
  public introMenuMode$ = new Subject<any>();
  public resetedTour$ = new Subject<any>();
  public currentIntroPage$ = new BehaviorSubject<GuidedTourNameEnum>(
    GuidedTourNameEnum.Empty,
  );
  public isTourMode$ = new Subject<any>();
  public isReadOnly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  constructor(private guidedTourService: GuidedTourService) {}

  setIntoPage(introPage: GuidedTourNameEnum) {
    this.currentIntroPage$.next(introPage);
  }

  filterVisibleItems(items) {
    return items.filter((s) => {
      if (!s.hasOwnProperty('element')) {
        return true;
      } else {
        return s.element;
      }
    });
  }

  itemsList(intro, isReadOnlyMode): void {
    let steps = [
      {
        title: 'Item list',
        intro: `Use the Items list, to add all the products and services by category you need to create an estimate.
            We have added some sample categories based on the industry you selected during sign up.`,
        tooltipClass: 'w-80 introjs-tooltip-mat-theme',
      },
      {
        element: document.getElementById('items-expand-category-icon'),
        intro: `<div class="mb-4">Select the caret icon to show or hide the items in a category</div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/show-items-in-category.png "/>
            </div>
            `,
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
        position: 'bottom',
        disableInteraction: true,
      },
      {
        element: document.getElementById('items-new-category-button'),
        intro: `
            <div class="mb-4">Select <b>+ New Category</b> to create a new category for your items</div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/add-category.png "/>
            </div>
            `,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
        disableInteraction: true,
      },
      {
        element: document.getElementById('items-add-category-icon'),
        intro: `
            <div class="mb-4">
              <span class="text-nowrap">Select <img class="w-6 h-6 object-left object-cover inline-block align-middle" src="images/tour/add-item-to-category-icon.png "/> to create a new item in the category. </span><br>
              If you are providing the service i.e. Labour, select your company as the supplier.
            </div>
            <div class="flex justify-end">
              <img class="h-44 object-contain w-full" src="images/tour/category-add-item.png "/>
            </div>
            `,
        autoPosition: false,
        position: 'left',
        tooltipPosition: 'left',
        positionPrecedence: false,
        tooltipClass: `min-w-96 w-fit introjs-tooltip-mat-theme tooltip-large-left-middle`,
        disableInteraction: true,
      },
      {
        element: document.getElementById('items-edit-category-icon'),
        intro: `
            <div class="mb-4">
              Select the pencil icon to change the name of the category.
            </div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/edit-category.png "/>
            </div>
            `,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
        disableInteraction: true,
      },
      {
        element: document.getElementById('items-move-category-icon'),
        intro: `
            <div class="mb-4">
              The order of the categories can be changed by selecting and dragging the category to the preferred order.
            </div>
            <div class="flex justify-end">
              <video loop autoplay class="w-full" src="images/tour/item-reorder.mp4"></video>
            </div>
            `,
        position: 'right',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
        disableInteraction: true,
      },
      {
        element: document.getElementById('items-search-field'),
        intro: `
            <div class="mb-4">
              You can use the <b>Search anything…</b> to filter the items, categories, and item suppliers.
            </div>
            `,
        position: 'bottom',
        disableInteraction: true,
      },
    ];

    if (isReadOnlyMode) {
      steps = [steps[1], steps[5], steps[6]];
    }
    intro
      .setOptions({
        exitOnOverlayClick: false,
        scrollToElement: true,
        disableInteraction: false,
        exitOnEsc: true,
        showBullets: false,
        hidePrev: true,
        tooltipClass: 'introjs-tooltip-mat-theme',
        tooltipPosition: 'bottom',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  callForwards(intro, isReadOnlyMode) {
    let steps = [
      {
        title: 'CallForwards',
        intro: `Use the CallForward to manage your job, including Orders, Invoicing and general tasks.`,
        tooltipClass: 'w-80 introjs-tooltip-mat-theme',
      },
      {
        element: document.getElementById('add-new-callforward'),
        intro: `When you have finished the tour you can create a new CallForward from here.`,
        title: 'New CallForward',
        position: 'left',
        disableInteraction: true,
      },
      {
        element: document.getElementById('cf-search-field'),
        intro: `
            <div class="mb-4">
              You can use the <b>Search anything…</b> to filter the results in the list.
            </div>
            `,
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('cf-filter-bar'),
        intro: `
            <div class="mb-4">
              You can use this filter bar to reduce the results by Issue Type, Job, Supplier or Status.
            </div>
            `,
        position: 'bottom',
        disableInteraction: true,
      },
      {
        element: document.querySelector('.callForwardItemType'),
        intro: `
            <div class="mb-4">
              Clicking on a record Type will show you the document preview.
            </div>
            `,
        position: 'right',
        disableInteraction: true,
      },
      {
        title: 'Send Call For Order',
        element: document.querySelector('.callForEmail'),
        intro: `
            <div class="mb-4">
              Clicking on the <b>Campaign</b> icon opens a <b>Send Call For Order dialog</b> for the selected Supplier.
            </div>
<!--            <div class="flex justify-end">-->
<!--              <img class="w-full" src="images/tour/cf-campaign-tour.png"/>-->
<!--            </div>-->
            `,
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.annotation'),
        intro: `
            <div class="mb-4">
              Clicking on the <b>Annotation</b> icon opens a dialog where <b>Delivery Notes</b> can be left.
            </div>
<!--            <div class="flex justify-end">-->
<!--              <img class="w-full" src="images/tour/cf-delivery-tour.png"/>-->
<!--            </div>-->
            `,
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.notice'),
        intro: `
            <div class="mb-4">
              Here <b>Notes</b> for the selected CallForward can be left. <br>
              You can <b>Add</b> a new Note or modify existing one.
            </div>
<!--            <div class="flex justify-end">-->
<!--              <img class="w-full" src="images/tour/cf-note-tour.png"/>-->
<!--            </div>-->
            `,
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.email'),
        intro: `
            <div class="mb-4">
              Clicking on the <b>Email</b> icon opens a <b>Send Order dialog</b> for the selected Supplier.
            </div>
<!--            <div class="flex justify-end">-->
<!--              <img class="w-full" src="images/tour/cf-send-order-tour.png"/>-->
<!--            </div>-->
            `,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.action').children[0],
        intro: `
            <div class="mb-4">
              The <b>Pencil</b> icon gives  ability for modifying a selected CallForward. <br>
              The <b>Trash</b> icon is for removing a selected record.
            </div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/cf-crud-tour.png"/>
            </div>
            `,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
    ];

    if (isReadOnlyMode) {
      // steps = [steps[1], steps[5], steps[6]];
    }
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showBullets: false,
        hidePrev: true,
        tooltipClass: 'introjs-tooltip-mat-theme',
        tooltipPosition: 'bottom',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .onbeforechange(() => {
        let introOverlay = document.querySelector('html');
        let scrollbar: any = document.querySelector('.callforward-scroll');
        introOverlay?.scroll(0, 0);
        if (intro._currentStep < 5) {
          scrollbar?.scroll(0, 0);
        } else {
          scrollbar?.scroll(scrollbar?.offsetWidth, 0);
        }
      })
      .start();
  }

  jobsList(intro) {
    let steps = [
      {
        element: document.getElementById('recent-list'),
        intro: 'The quick access to the recent jobs.',
        position: 'bottom',
      },
      {
        element: document.getElementById('search-estimate-estimate-list'),
        intro:
          'You can use the <b>Search anything…</b> to filter the jobs by name, customers.',
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('status-filter-estimate-list'),
        intro:
          'You can use <b>Status</b> to filter the jobs by their current status.',
        position: 'right',
        highlightClass: 'h-13',
        disableInteraction: true,
      },
      {
        element: document.querySelector('.manage-job-button'),
        intro: 'Click <b>Manage</b> to view and make changes to a job.',
        position: 'left',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .onbeforechange(() => {
        let introOverlay = document.querySelector('html');
        let scrollbar: any = document.querySelector('.scroll-bar');
        introOverlay?.scroll(0, 0);
        if (intro._currentStep < 3) {
          scrollbar?.scroll(0, 0);
        } else {
          scrollbar?.scroll(scrollbar?.offsetWidth, 0);
        }
      })
      .start();
  }

  jobOrderList(intro, isReadOnlyMode) {
    let steps = [
      {
        element: document.querySelector('app-job-order-summary'),
        intro: `Expand the Summary bar to make a <b>quick Order</b> for a selected Supplier and Category.`,
        position: 'right',
      },
      {
        element: document.getElementById('create-orders'),
        intro: `Clicking the <b>Create orders</b> button will open the dialog where you can create <b>multiple orders</b> and <b>email</b> them to suppliers.`,
        position: 'left',
      },
      {
        element: document.getElementById('create-extra-over-order'),
        intro: `Clicking the <b>Add Extra / Over Order</b> button navigates you to the page where you can create a new order for <b>any</b> supplier and category.`,
        position: 'left',
      },
      {
        element: document.getElementById('suppliers-filter'),
        intro: 'You can filter out the Orders list by a selected Supplier.',
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('statuses-filter'),
        intro: 'You can filter out the Orders list by a selected Status.',
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.querySelector('.manage-button'),
        intro: 'Click <b>Manage</b> to view and make changes to a Job Order.',
        position: 'left',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  jobOrder(intro, isReadOnlyMode) {
    let steps = [
      {
        element: document.querySelector('app-order-status-pill'),
        intro: `Here you can see a <b>Status</b> of the current order.`,
        position: 'right',
      },
      {
        element: document.getElementById('sent-label'),
        intro: `The label indicates that the current order has already been sent to the supplier.`,
        position: 'right',
      },
      {
        element: document.getElementById('change-status'),
        intro: `Here you can manually change a status of the current order.`,
        position: 'left',
      },
      {
        element: document.getElementById('preview'),
        intro: `Clicking <b>Preview</b> button opens a popup where you can see how the current order looks like at the moment.`,
        position: 'left',
      },
      {
        element: document.getElementById('download'),
        intro: `Clicking <b>Print</b> button will download the current order to your system.`,
        position: 'left',
      },
      {
        element: document.getElementById('send'),
        intro: `Clicking the <b>Send</b> button opens a <b>Send Order dialog</b> where you can send an email for a list of Suppliers.`,
        position: 'left',
      },
      {
        element: document.getElementById('add-from-costing'),
        intro: `Fill out the <b>To Supplier</b> and <b>Category</b> controls to enable the button in order to add or remove item to the order.`,
        position: 'left',
      },
      {
        element: document.getElementById('add-from-item-list'),
        intro: `Fill out the <b>To Supplier</b> and <b>Category</b> controls to enable the button in order to add or remove item to the order.`,
        position: 'left',
      },
      {
        element: document.getElementById('delivery-instructions'),
        intro: 'Here you can leave delivery instructions for the order.',
        position: 'right',
        disableInteraction: true,
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  snippetsList(intro) {
    let steps = [
      {
        element: document.querySelector('.table-search'),
        intro: `You can use the <b>Search anything…</b> to filter the snippets list by your query.`,
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('add-snippet'),
        intro: `<div class="mb-4">Clicking the <b>Add Snippet</b> adds a new record at the top of the Snippets list where you can fill out all mandatory fields.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/add-new-snippet.png"/>
          </div>`,
        position: 'left',
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.manage-buttons'),
        intro: `<div class="mb-4">You can manage a selected record by copying, editing or deleting it.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/snippets-manage-item.png"/>
          </div>`,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  invoicesList(intro) {
    let steps = [
      {
        element: document.getElementById('add-invoice'),
        intro: `Clicking the <b>Add Invoice</b> button navigates you to a blank Invoice page.`,
        position: 'left',
      },
      {
        element: document.querySelector(
          '#status-filter-invoices .mat-mdc-text-field-wrapper',
        ),
        intro:
          'You can use <b>Status</b> to filter the Invoices list by chosen status.',
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.querySelector('.invoice-summary'),
        intro: `The Summary bar shows the key Invoices values.`,
        position: 'right',
      },
      {
        element: document.querySelector('.manage-button'),
        intro: 'Click <b>Manage</b> to view and make changes to an Invoice.',
        position: 'left',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  invoice(intro, isReadOnlyMode) {
    let steps = [
      {
        element: document.querySelector('.invoice-summary'),
        intro: `The Summary bar shows the key Invoices values.`,
        position: 'right',
      },
      {
        element: document.querySelector('app-invoice-status-badge'),
        intro: `Here you can see a <b>Status</b> of the current Invoice.`,
        position: 'right',
      },
      {
        element: document.getElementById('sent-label'),
        intro: `The label indicates that the current invoice has already been sent to the supplier.`,
        position: 'right',
      },
      {
        element: document.getElementById('change-status'),
        intro: `Here you can manually change a status of the current invoice.`,
        position: 'left',
      },
      {
        element: document.getElementById('preview'),
        intro: `Clicking <b>Preview</b> button opens a popup where you can see how the current invoice looks like at the moment.`,
        position: 'left',
      },
      {
        element: document.getElementById('download'),
        intro: `Clicking <b>Print</b> button will download the current invoice to your system.`,
        position: 'left',
      },
      {
        element: document.getElementById('send'),
        intro: `Clicking the <b>Send</b> button opens a <b>Send Order dialog</b> where you can send an email for a list of Suppliers.`,
        position: 'left',
      },
      {
        element: document.getElementById('sent-control'),
        intro: `Checking out the <b>Sent</b> box immediately marks the current Invoice in the system as Sent.`,
        position: 'left',
      },
      {
        element: document.getElementById('add-new-invoice-record'),
        intro: `Here you can choose a template a new Invoice record will add from. The options are <b>Percentage of total</b>, <b>Fixed amount</b>, <b>Remaining amount</b>, <b>Credit amount</b>.`,
        position: 'left',
      },
      {
        element: document.getElementById('add-from-item-list'),
        intro: `Fill out the <b>To Supplier</b> and <b>Category</b> controls to enable the button in order to add or remove item to the order.`,
        position: 'left',
      },
      {
        element: document.getElementById('edit-footer'),
        intro: 'You can leave a footer description for the Invoice here.',
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('add-item'),
        intro:
          'Clicking <b>Add payment</b> button you can add a new record to  the payment table manually.',
        position: 'right',
        disableInteraction: true,
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  templatesList(intro) {
    let steps = [
      {
        element: document.getElementById('add-template'),
        intro: `Clicking the <b>Create template</b> button shows you a dialog where you can type a Name and Description for a new template.
            When all mandatory fields are filled out, the system navigates you to a blank Template page.`,
        position: 'left',
      },
      {
        element: document.querySelector('.table-search').children[0],
        intro: `You can use the <b>Search anything…</b> to filter the templates list by your query.`,
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.querySelector('.manage-action-icon'),
        intro: 'Click <b>Manage</b> to view and make changes to an Template.',
        position: 'left',
      },
      {
        element: document.querySelector('.delete-action-icon'),
        intro:
          'Clicking on <b>Trash</b> icon will delete a selected template from the system.',
        position: 'left',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  template(intro, isReadOnlyMode) {
    let steps = [
      {
        element: document.getElementById('edit-template-name'),
        intro: `Click <b>Edit</b> button to update or change the template Name and Description.`,
        position: 'left',
        disableInteraction: true,
      },
      {
        element: document.getElementById('add-template-section'),
        intro: `Clicking <b>+ Add section</b> a popup dialog will appear where you can type a new section name. Than the system will add a ne w section to the template.`,
        position: 'left',
        disableInteraction: true,
      },
      {
        element: document.querySelector('app-estimate-header'),
        intro: `The summary tracks the estimate costs as you add or change the items if you have permissions. The default On Cost% can be changed in Settings.`,
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('estimate-summary-on-cost'),
        intro: `You can change the On Cost % if you have permissions and the Profit, Net Quote and Customer quote will automatically recalculate.`,
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('estimate-summary-profit'),
        intro: `You can change the Profit if you have permissions and the On cost, Net Quote and Customer quote will automatically recalculate.`,
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('estimate-summary-customer-quote'),
        intro: `You can change the Customer quote if you have permissions and the On cost, Profit and Net Quote will automatically recalculate.`,
        position: 'right',
        disableInteraction: true,
      },

      {
        element: document.querySelector('.estimate-section-add-remove-items'),
        intro: `
          <div class="mb-4">Select <b>Add items from Item List</b> to add items from your Item List into the costing.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/add-remove-items.png"/>
          </div>`,
        position: 'right',
        disableInteraction: true,
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.estimate-summary-section-caret'),
        intro: `
            <div class="mb-4">Select the caret icon to show or hide the items.</div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/costing-show-items.png"/>
            </div>`,
        position: 'right',
        disableInteraction: true,
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.estimate-summary-edit'),
        intro: `
          <div class="mb-4">Select the pencil icon to change the Qty, UOM, Unit price, On cost% and GST rate of an item.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-edit-item.png"/>
          </div>`,
        position: 'top',
        disableInteraction: true,
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.estimate-rename'),
        intro: `
          <div class="mb-4">Select the name of the section to rename it.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/click-to-rename.png"/>
          </div>`,
        position: 'right',
        disableInteraction: true,
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('.estimate-reorder-buttons'),
        intro: `
          <div class="mb-4">Select the up and down arrows to reorder the sections.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-reorder-section.png"/>
          </div>`,
        position: 'right',
        disableInteraction: true,
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
      },
    ];

    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showBullets: false,
        hidePrev: true,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  // templatesList(intro) {
  //   let steps = [
  //     {
  //       element: document.getElementById('add-template'),
  //       intro:
  //         `Clicking the <b>Create template</b> button shows you a dialog where you can type a Name and Description for a new template.
  //           When all mandatory fields are filled out, the system navigates you to a blank Template page.`,
  //       position: 'left',
  //     },
  //     {
  //       element: document.querySelector('.table-search'),
  //       intro:
  //         `You can use the <b>Search anything…</b> to filter the templates list by your query.`,
  //       position: 'right',
  //       disableInteraction: true,
  //     },
  //     {
  //       element: document.querySelector('.manage-action-icon'),
  //       intro:
  //         'Click <b>Manage</b> to view and make changes to an Template.',
  //       position: 'left',
  //     },
  //     {
  //       element: document.querySelector('.delete-action-icon'),
  //       intro:
  //         'Clicking on <b>Trash</b> icon will delete a selected template from the system.',
  //       position: 'left',
  //     },

  //   ];
  //   intro
  //     .setOptions({
  //       exitOnOverlayClick: false,
  //       disableInteraction: false,
  //       exitOnEsc: true,
  //       showButtons: true,
  //       showProgress: false,
  //       hidePrev: true,
  //       showBullets: false,
  //       tooltipClass: 'introjs-tooltip-mat-theme',
  //       highlightClass:
  //         'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
  //       steps:  this.filterVisibleItems(steps),
  //     })
  //     .start();
  // }

  // template(intro, isReadOnlyMode) {
  //   let steps = [
  //     {
  //       element: document.getElementById('edit-template-name'),
  //       intro: `Click <b>Edit</b> button to update or change the template Name and Description.`,
  //       position: 'left',
  //       disableInteraction: true,
  //     },
  //     {
  //       element: document.getElementById('add-template-section'),
  //       intro: `Clicking <b>+ Add section</b> a popup dialog will appear where you can type a new section name. Than the system will add a ne w section to the template.`,
  //       position: 'left',
  //       disableInteraction: true,
  //     },
  //       {
  //         element: document.querySelector('app-estimate-header'),
  //         intro: `The summary tracks the estimate costs as you add or change the items if you have permissions. The default On Cost% can be changed in Settings.`,
  //         position: 'right',
  //         disableInteraction: true,
  //       },
  //       {
  //         element: document.getElementById('estimate-summary-on-cost'),
  //         intro: `You can change the On Cost % if you have permissions and the Profit, Net Quote and Customer quote will automatically recalculate.`,
  //         position: 'right',
  //         disableInteraction: true,
  //       },
  //       {
  //         element: document.getElementById('estimate-summary-profit'),
  //         intro: `You can change the Profit if you have permissions and the On cost, Net Quote and Customer quote will automatically recalculate.`,
  //         position: 'right',
  //         disableInteraction: true,
  //       },
  //       {
  //         element: document.getElementById('estimate-summary-customer-quote'),
  //         intro: `You can change the Customer quote if you have permissions and the On cost, Profit and Net Quote will automatically recalculate.`,
  //         position: 'right',
  //         disableInteraction: true,
  //       },

  //       {
  //         element: document.querySelector('.estimate-section-add-remove-items'),
  //         intro: `
  //         <div class="mb-4">Select <b>Add items from Item List</b> to add items from your Item List into the costing.</div>
  //         <div class="flex justify-end">
  //           <img class="w-full" src="images/tour/add-remove-items.png"/>
  //         </div>`,
  //         position: 'right',
  //         disableInteraction: true,
  //         tooltipClass: 'w-600 introjs-tooltip-mat-theme',
  //       },
  //     {
  //       element: document.querySelector('.estimate-summary-section-caret'),
  //       intro: `
  //           <div class="mb-4">Select the caret icon to show or hide the items.</div>
  //           <div class="flex justify-end">
  //             <img class="w-full" src="images/tour/costing-show-items.png"/>
  //           </div>`,
  //       position: 'right',
  //       disableInteraction: true,
  //       tooltipClass: 'w-600 introjs-tooltip-mat-theme',
  //     },
  //     {
  //       element: document.querySelector('.estimate-summary-edit'),
  //       intro: `
  //         <div class="mb-4">Select the pencil icon to change the Qty, UOM, Unit price, On cost% and GST rate of an item.</div>
  //         <div class="flex justify-end">
  //           <img class="w-full" src="images/tour/costing-edit-item.png"/>
  //         </div>`,
  //       position: 'top',
  //       disableInteraction: true,
  //       tooltipClass: 'w-400 introjs-tooltip-mat-theme',
  //     },
  //     {
  //       element: document.querySelector('.estimate-rename'),
  //       intro: `
  //         <div class="mb-4">Select the name of the section to rename it.</div>
  //         <div class="flex justify-end">
  //           <img class="w-full" src="images/tour/click-to-rename.png"/>
  //         </div>`,
  //       position: 'right',
  //       disableInteraction: true,
  //       tooltipClass: 'w-400 introjs-tooltip-mat-theme',
  //     },
  //     {
  //       element: document.querySelector('.estimate-reorder-buttons'),
  //       intro: `
  //         <div class="mb-4">Select the up and down arrows to reorder the sections.</div>
  //         <div class="flex justify-end">
  //           <img class="w-full" src="images/tour/costing-reorder-section.png"/>
  //         </div>`,
  //       position: 'right',
  //       disableInteraction: true,
  //       tooltipClass: 'w-600 introjs-tooltip-mat-theme',
  //     },
  //   ];

  //   intro
  //     .setOptions({
  //       exitOnOverlayClick: false,
  //       disableInteraction: false,
  //       exitOnEsc: true,
  //       showBullets: false,
  //       hidePrev: true,
  //       tooltipClass: 'introjs-tooltip-mat-theme',
  //       highlightClass:
  //         'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
  //       steps:  this.filterVisibleItems(steps),
  //     })
  //     .start();
  // }

  variationList(intro) {
    let steps = [
      {
        element: document.getElementById('add-variation'),
        intro: `Clicking the <b>Add Variation</b> button opens a dialog where you type a new variation name and adds a new Variation to the list.`,
        position: 'left',
      },
      {
        element: document.querySelector('app-job-costing-summary'),
        intro: `The Summary bar shows the key values.`,
        position: 'right',
      },
      {
        element: document.querySelector('.manage-button'),
        intro:
          'Click <b>Manage</b> to view and make changes to a Job Variation.',
        position: 'left',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  variation(intro) {
    let steps = [
      {
        element: document.getElementById('variation-add-section-button'),
        intro: `You can divide the costing up into multiple sections to provide a high-level breakdown. i.e., Exterior painting and Interior painting. Select <b>+ Add Variation item</b> to create a new item`,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.querySelector('app-job-variation-header'),
        intro: `The summary tracks the estimate costs as you add or change the items. The default On Cost% can be changed in Settings.`,
        position: 'right',
        hidePrev: true,
        disableInteraction: true,
      },
      {
        element: document.querySelector('app-status-toggle'),
        intro: `
        <div class="mb-4">Shows the status of the Variation. If the customer decides not to proceed with the work, select Rejected or if you don’t hear back from the customer, select <b>Withdrawn</b></div>
        <div class="flex justify-end">
          <img class="w-full" src="images/tour/costing-status.png"/>
        </div>`,
        position: 'right',
        disableInteraction: true,
      },

      {
        element: document.querySelector('.variation-section-caret'),
        intro: `
            <div class="mb-4">Select the caret icon to show or hide the items.</div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/costing-show-items.png"/>
            </div>`,
        position: 'right',
        disableInteraction: true,
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
      },
      {
        element: document.getElementById('estimate-reorder-buttons'),
        intro: `
          <div class="mb-4">Select the up and down arrows to reorder the sections.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-reorder-section.png"/>
          </div>`,
        position: 'right',
        disableInteraction: true,
        tooltipClass: 'w-600 introjs-tooltip-mat-theme',
      },
    ];
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  dashboard(intro): void {
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showBullets: false,
        hidePrev: true,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'bg-transparent dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems([
          {
            intro: `
            <div class="flex items-center justify-center mb-4">
              <img class="w-40" src="images/logo/logo-reversed-web-intro.svg"/>
            </div>
            <div class="text-lg mb-2 font-semibold text-center">Welcome to Eazimate let me show you around!</div>
            <p>To help get you started we have setup some items and created a few samples, so you can see how easy estimating can be.</p>
            <br>
            <p>You start the tour again by selecting the map in the top righthand corner</p>
            <div class="flex justify-end">
              <img class="w-20" src="images/tour/watch-tour-again.png"/>
            </div>
            `,
            tooltipClass: 'w-80 introjs-tooltip-mat-theme',
          },
          {
            element: document.getElementById('dashboard-new-estimate'),
            intro: `When you have finished the tour you can create an estimate from here. <br>
            To help get you started we have setup some items and created a few samples, so you can see how easy estimating can be.
            `,
            title: 'New Estimate',
            position: 'left',
            highlightClass: 'dark:bg-gray-800',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Item List'),
            intro: `The Item list menu contains a categorised list of all the products and services that you need to create an estimate and quote.`,
            title: 'Item list',
            position: 'bottom',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Estimates'),
            intro: `The Estimates menu contains your estimates and quotes. We've created a sample estimate to give you an idea of how it's done.`,
            title: 'Estimates',
            position: 'bottom',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Jobs'),
            intro: `The Jobs menu contains the quotes you have won.`,
            title: 'Jobs',
            position: 'bottom',
            highlightClass:
              'bg-gray-800 border-2 border-white border-solid box-border',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Suppliers'),
            intro: `The Suppliers menu contains your suppliers. Setup your suppliers in here so you can quickly add their products to estimates. You can also see supplier orders. We've added a sample supplier.`,
            title: 'Suppliers',
            position: 'bottom',
            highlightClass:
              'bg-gray-800 border-2 border-white border-solid box-border',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Customers'),
            intro: `The Customers menu contains your customers. Setup your customers in here so you can quickly add them to estimates and quotes. You can also see the customer's estimates and invoices.`,
            title: 'Customers',
            position: 'bottom',
            highlightClass:
              'bg-gray-800 border-2 border-white border-solid box-border',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Templates'),
            intro: `The Templates menu allows you to pre-setup an estimate template so you can quickly and easily create standard estimates.`,
            title: 'Templates',
            position: 'bottom',
            highlightClass:
              'bg-gray-800 border-2 border-white border-solid box-border',
            disableInteraction: true,
          },
          {
            element: document.getElementById('menu-item-Library'),
            intro: `The Library menu allows you to pre-setup snippets of text to add to your quotes, emails, terms and conditions etc.`,
            title: 'Library',
            position: 'bottom',
            highlightClass:
              'bg-gray-800 border-2 border-white border-solid box-border',
            disableInteraction: true,
          },
        ]),
      })
      .start();

    this.introMenuMode$.next(true);
  }

  getIntro() {
    return new introJs();
  }

  estimateListAdd(intro, hasEstimates?: boolean): void {
    let steps: any = [
      {
        element: document.getElementById('add-estimate-estimate-list'),
        intro: `
        <div>Select <b>+ New Estimate</b> to start the New Estimate wizard. Create a new blank estimate or copy an existing estimate or template.</div>
        <div>
          <img class="w-full" src="images/tour/new-estimate-wizard.png"/>
        </div>
        `,
        position: 'left',
        tooltipClass: 'w-400 introjs-tooltip-mat-theme',
      },
      {
        element: document.getElementById('search-estimate-estimate-list'),
        intro:
          'You can use the <b>Search anything…</b> to filter the estimates by name, customers.',
        position: 'right',
        disableInteraction: true,
      },
      {
        element: document.getElementById('status-filter-estimate-list'),
        intro:
          'You can use <b>Status</b> to filter the estimates by their current status.',
        position: 'right',
        highlightClass: 'h-13',
        disableInteraction: true,
      },
    ];
    if (hasEstimates) {
      steps = [
        ...steps,
        ...[
          {
            element: document.querySelector('.manage-job-button'),
            intro:
              'Click <b>Manage</b> to view and make changes to an estimate.',
            position: 'left',
          },
        ],
      ];
    }
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showButtons: true,
        showProgress: false,
        hidePrev: true,
        showBullets: false,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .onbeforechange(() => {
        let introOverlay = document.querySelector('html');
        introOverlay.scroll(0, 0);
        let scrollbar: any = document.querySelector('.scroll-bar');
        if (!scrollbar) return;
        if (intro._currentStep < 3) {
          scrollbar.scroll(0, 0);
        } else {
          scrollbar.scroll(scrollbar.offsetWidth, 0);
        }
      })
      .start();
  }

  estimateCosting(intro): void {
    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showBullets: false,
        hidePrev: true,
        tooltipClass: 'introjs-tooltip-mat-theme',
        autoPosition: false,
        tooltipPosition: 'left',
        positionPrecedence: false,
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems([
          {
            element: document.getElementById('estimate-costings-manage-button'),
            intro:
              'Click <b>Manage</b> to view and make changes to an estimate.',
            hidePrev: true,
            disableInteraction: true,
          },
          {
            element: document.getElementById('estimate-costings-clone-button'),
            intro:
              'If you need to make a new revision of quote for a customer, select <b>Copy</b>. This will create a new copy of the quote and keep a copy in history. You can create multiple revisions of an estimate costing and quote',
            disableInteraction: true,
          },
          {
            element: document.getElementById('estimate-costings-accept-button'),
            intro:
              'When a customer wishes to proceed with an estimate, select <b>Accept</b>. This will change the estimate into a job. You can also Accept past estimate from history, if the customer decides to go with a previous quote.',
            disableInteraction: true,
          },
        ]),
      })
      .start();
  }

  estimateCostingDetails(
    intro,
    hasSectionItems: boolean,
    isProPlan: boolean,
    isReadOnlyMode: boolean,
  ): void {
    let steps: any;
    if (hasSectionItems) {
      document
        .getElementById('estimate-summary-edit')
        .scrollIntoView({ block: 'end' });
      steps = [
        {
          element: document.getElementById('estimate-summary'),
          intro: `The summary tracks the estimate costs as you add or change the items. The default On Cost% can be changed in Settings.`,
          position: 'right',
          hidePrev: true,
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-on-cost'),
          intro: `You can change the On Cost % and the Profit, Net Quote and Customer quote will automatically recalculate.`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-profit'),
          intro: `You can change the Profit and the On cost, Net Quote and Customer quote will automatically recalculate.`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-customer-quote'),
          intro: `You can change the Customer quote and the On cost, Profit and Net Quote will automatically recalculate.`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-add-remove'),
          intro: `
          <div class="mb-4">Select <b>Add or remove items</b> to add items from your Items List into the costing.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/add-remove-items.png"/>
          </div>`,
          position: 'right',
          disableInteraction: true,
          tooltipClass: 'w-600 introjs-tooltip-mat-theme',
        },
        {
          element: document.getElementById('estimate-summary-edit'),
          intro: `
          <div class="mb-4">Select the pencil icon to change the Qty, UOM, Unit price, On cost% and GST rate of an item.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-edit-item.png"/>
          </div>`,
          position: 'top',
          disableInteraction: true,
          tooltipClass: 'w-400 introjs-tooltip-mat-theme',
        },
        {
          element: document.getElementById('estimate-status-sections'),
          intro: `
          <div class="mb-4">Shows the status of the estimate. If the customer decides not to proceed with the work, select Rejected or if you don’t hear back from the customer, select <b>Withdrawn</b></div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-status.png"/>
          </div>`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-create-template-button'),
          intro: `
          <div class="mb-4">Allows you to create template of this estimate costing, so you are about to reuse for future estimates / quotes.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-template-wizard.png"/>
          </div>`,
          position: 'right',
          disableInteraction: true,
          tooltipClass: 'w-400 introjs-tooltip-mat-theme',
        },
      ];
    } else {
      steps = [
        {
          element: document.getElementById('estimate-summary'),
          intro: `The summary tracks the estimate costs as you add or change the items. The default On Cost% can be changed in Settings.`,
          position: 'right',
          hidePrev: true,
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-on-cost'),
          intro: `You can change the On Cost % and the Profit, Net Quote and Customer quote will automatically recalculate.`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-profit'),
          intro: `You can change the Profit and the On cost, Net Quote and Customer quote will automatically recalculate.`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-customer-quote'),
          intro: `You can change the Customer quote and the On cost, Profit and Net Quote will automatically recalculate.`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-summary-add-remove'),
          intro: `
          <div class="mb-4">Select <b>Add or remove items</b> to add items from your Items List into the costing.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/add-remove-items.png"/>
          </div>`,
          position: 'right',
          disableInteraction: true,
          tooltipClass: 'w-600 introjs-tooltip-mat-theme',
        },
        {
          element: document.getElementById('estimate-status-sections'),
          intro: `
          <div class="mb-4">Shows the status of the estimate. If the customer decides not to proceed with the work, select Rejected or if you don’t hear back from the customer, select <b>Withdrawn</b></div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-status.png"/>
          </div>`,
          position: 'right',
          disableInteraction: true,
        },
        {
          element: document.getElementById('estimate-create-template-button'),
          intro: `
          <div class="mb-4">Allows you to create template of this estimate costing, so you are about to reuse for future estimates / quotes.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-template-wizard.png"/>
          </div>`,
          position: 'right',
          disableInteraction: true,
          tooltipClass: 'w-400 introjs-tooltip-mat-theme',
        },
      ];
    }
    if (isProPlan) {
      steps = [
        ...steps,
        ...[
          {
            element: document.getElementById('estimate-summary-section-caret'),
            intro: `
            <div class="mb-4">Select the caret icon to show or hide the items.</div>
            <div class="flex justify-end">
              <img class="w-full" src="images/tour/costing-show-items.png"/>
            </div>`,
            position: 'right',
            disableInteraction: true,
            tooltipClass: 'w-600 introjs-tooltip-mat-theme',
          },
          {
            element: document.getElementById('estimate-add-section-button'),
            intro: `
          <div class="mb-4">You can divide the costing up into multiple sections to provide a high-level breakdown in the quote. i.e., Exterior painting and Interior painting. Select <b>+ Add Section</b> to create a new section</div>
          `,
            position: 'right',
            disableInteraction: true,
          },
          {
            element: document.getElementById('estimate-rename'),
            intro: `
          <div class="mb-4">Select the name of the section to rename it.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/click-to-rename.png"/>
          </div>`,
            position: 'right',
            disableInteraction: true,
            tooltipClass: 'w-400 introjs-tooltip-mat-theme',
          },
          {
            element: document.getElementById('estimate-reorder-buttons'),
            intro: `
          <div class="mb-4">Select the up and down arrows to reorder the sections.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-reorder-section.png"/>
          </div>`,
            position: 'right',
            disableInteraction: true,
            tooltipClass: 'w-600 introjs-tooltip-mat-theme',
          },
        ],
      ];
    }

    if (isReadOnlyMode) {
      let item;
      steps = steps.filter(
        (s) =>
          !(
            s.intro.startsWith('You can change the ') ||
            s.intro.includes('Select the name of the section to rename it')
          ),
      );
      item = steps.find(
        (s) =>
          s.intro ===
          `The summary tracks the estimate costs as you add or change the items. The default On Cost% can be changed in Settings.`,
      );
      if (item) {
        item.intro = `The summary tracks the estimate costs.`;
      }
      item = steps.find((s) =>
        s.intro.includes('Shows the status of the estimate'),
      );
      if (item) {
        item.intro = `
          <div class="mb-4">Shows the status of the estimate.</div>
          <div class="flex justify-end">
            <img class="w-full" src="images/tour/costing-status.png"/>
          </div>`;
      }
    }

    intro
      .setOptions({
        exitOnOverlayClick: false,
        disableInteraction: false,
        exitOnEsc: true,
        showBullets: false,
        hidePrev: true,
        tooltipClass: 'introjs-tooltip-mat-theme',
        highlightClass:
          'dark:bg-gray-800 dark:border-2 dark:border-white dark:border-solid box-border',
        steps: this.filterVisibleItems(steps),
      })
      .start();
  }

  exitItroJs(intro) {
    intro.exit();
  }

  resetTour() {
    this.guidedTourService
      .resetGuidedTour(btoa(this.currentIntroPage$.value))
      .subscribe(() => {
        this.resetedTour$.next(true);
      });
  }
}
