import { createReducer, on } from '@ngrx/store';
import { FileUploadStatus, FileUploadType } from 'app/shared/models';
import {
  fileUploadCompleted,
  fileUploadFailure,
  fileUploadProgress,
  fileUploadRequest,
  fileUploadStarted,
} from '../actions/file-upload.actions';

export interface FileUploadState {
  files: File[];
  loaded: number[];
  status: FileUploadStatus[];
  error: string[];
  uploadType: FileUploadType;
}

export const initialState: FileUploadState = {
  files: [],
  loaded: [],
  status: [],
  error: [],
  uploadType: null,
};

export const fileUploadReducer = createReducer(
  initialState,
  on(fileUploadRequest, (state, { files }) => ({
    ...state,
    files,
    loaded: files.map((f) => 0),
    status: files.map((f) => FileUploadStatus.Requested),
    error: files.map((f) => null),
  })),
  on(fileUploadStarted, (state, { index }) => ({
    ...state,
    status: state.status.map((s, i) =>
      i === index ? FileUploadStatus.Started : s,
    ),
  })),
  on(fileUploadProgress, (state, { index, loaded }) => ({
    ...state,
    loaded: state.loaded.map((l, i) => (i !== index ? l : loaded)),
  })),
  on(fileUploadFailure, (state, { index, error }) => ({
    ...state,
    status: state.status.map((s, i) =>
      i === index ? FileUploadStatus.Failed : s,
    ),
    error: state.error.map((e, i) => (i !== index ? e : error)),
  })),
  on(fileUploadCompleted, (state, { index }) => ({
    ...state,
    status: state.status.map((s, i) =>
      i === index ? FileUploadStatus.Completed : s,
    ),
  })),
);
