import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { shareReplay } from 'rxjs/operators';
import {
  AddCallForwardItem,
  AddCallForwardItemNote,
  CallForwardItem,
  CallForwardItemNote, Job,
  PaginatedListOfCallForwardItem,
  PaginatedListOfCallForwardItemNote,
} from '../../../../../../shared/models';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallForwardService {
  constructor(private http: HttpClient) {}

  getCallForwards(query) {
    const url = `${environment.urls.api}/callforward/list/v2`;
    return this.http.post(url, query);
  }
  getCallForwardItems(query): Observable<PaginatedListOfCallForwardItem> {
    const url = `${environment.urls.api}/callforwarditem/list`;
    return this.http.post(url, query);
  }

  getCallForwardItemStatuses() {
    const url = `${environment.urls.api}/callforwarditem/list/itemstatus`;
    return this.http.get(url);
  }
  getCallForwardItemTypes() {
    const url = `${environment.urls.api}/callforwarditem/list/itemtype`;
    return this.http.get(url);
  }
    getCallForwardsFilterJobs(query): Observable<Job[]> {
    const url = `${environment.urls.api}/callforwarditem/list/filter/jobs`;
    return this.http.post<Job[]>(url, query).pipe(
      shareReplay(1),
      catchError(e => {
        console.log(e);return of([])
      })
    );
  }
  getCallForwardsFilterSuppliers(query) {
    const url = `${environment.urls.api}/callforwarditem/list/filter/suppliers`;
    return this.http.post(url, query).pipe(
      shareReplay(1),
      catchError(e => {
        console.log(e);return of([])
      })
    );
  }
  getCallForwardsFilterItemStatus(query) {
    const url = `${environment.urls.api}/callforwarditem/list/filter/itemstatus`;
    return this.http.post(url, query).pipe(shareReplay(1));
  }

  // saveCallForwards(query: AddCallForward) {
  //   const url = `${environment.urls.api}/callforward/save`;
  //   return this.http.put(url, query);
  // }

  getCallForwardsByJobId(id: string) {
    const url = `${environment.urls.api}/callforward/job/${id}`;
    return this.http.get(url);
  }

  deleteCallForwardItem(id: string) {
    const url = `${environment.urls.api}/callforwarditem/${id}`;
    return this.http.delete(url);
  }

  addCallForwardItem(payload: AddCallForwardItem) {
    const url = `${environment.urls.api}/callforwarditem/add`;
    return this.http.post(url, payload);
  }

  updateCallForwardItem(payload: CallForwardItem) {
    const url = `${environment.urls.api}/callforwarditem/save`;
    return this.http.put(url, payload);
  }

  getCallForwardItemNotes(
    query,
  ): Observable<PaginatedListOfCallForwardItemNote> {
    const url = `${environment.urls.api}/callforwarditemnote/list`;
    return this.http.post(url, query);
  }

  addCallForwardItemNote(payload: AddCallForwardItemNote) {
    const url = `${environment.urls.api}/callforwarditemnote/add`;
    return this.http.post(url, payload);
  }

  updateCallForwardItemNote(note: CallForwardItemNote) {
    const url = `${environment.urls.api}/callforwarditemnote/save`;
    return this.http.put(url, note);
  }

  deleteCallForwardItemNote(id: string) {
    const url = `${environment.urls.api}/callforwarditemnote/${id}`;
    return this.http.delete(url);
  }

  getDeliveryInstructions(id: string) {
    const url = `${environment.urls.api}/joborder/deliveryinstructions/${id}`;
    return this.http.get(url);
  }

  updateDeliveryInstructions(obj: {
    jobOrderId: string;
    deliveryInstructions: string;
  }) {
    const url = `${environment.urls.api}/joborder/deliveryinstructions`;
    return this.http.put(url, obj);
  }

  updateCallForwardItemStatus(callForwardId: string, status: number) {
    const url = `${environment.urls.api}/callforwarditem/status/${callForwardId}/${status}`;
    return this.http.put(url, {});
  }
}
