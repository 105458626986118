import { uploadQuoteLogo } from './../actions/account-user.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  AcceptTeamMember,
  MemberLeavesAccount,
  TeamMember,
} from 'app/shared/models';
import {
  AccountSettingsModel,
  ProfileSettingsModel,
} from 'app/shared/models/form-ui.models';
import * as fromActions from 'app/shared/store/actions/account-user.actions';
import * as fromSelectors from 'app/shared/store/selectors/account-user.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountUserFacade {
  public userAccount$ = this.store.select(fromSelectors.selectAccount);
  public sasToken$ = this.store.select(fromSelectors.selectSasToken);
  public userAccounts$ = this.store.select(fromSelectors.selectAccounts);
  public loggedInUser$ = this.store.select(fromSelectors.selectLoggedInUser);

  public accountPlans$ = this.store.select(fromSelectors.selectAccountPlans);

  public selectActiveAccountPlan$ = this.store.select(
    fromSelectors.selectActiveAccountPlan,
  );

  public activeAccount$ = this.store.select(fromSelectors.selectActiveAccount);

  public invitedAccounts$ = this.store.select(
    fromSelectors.selectInvitedAccounts,
  );

  public recentTeamUserRemovedFrom$ = this.store.select(
    fromSelectors.selectRecentTeamUserRemovedFrom,
  );

  public accountsToDisplayDialogFor$ = this.store.select(
    fromSelectors.selectAccountsToDisplayDialogFor,
  );

  public role$ = this.store.select(fromSelectors.selectAccountRole);
  public roles$ = this.store.select(fromSelectors.selectAccountRoles);

  isLoading$ = this.store.select(fromSelectors.selectIsLoading);
  isInitialised$ = this.store.select(fromSelectors.selectIsInitialised);
  error$ = this.store.select(fromSelectors.selectError);

  // unfinished account selections
  unfinishedAccountSections$ = this.store.select(
    fromSelectors.selectAccountSections,
  );

  // used for Account Settings page
  accountFormModel$ = this.store.select(fromSelectors.selectAccountForm);
  accountSaved$: Observable<Action>;
  accountCancelled$: Observable<Action>;
  setActiveAccountSuccess$: Observable<Action>;
  leftAccountSuccess$: Observable<Action>;

  // Profile Settings page
  profile$ = this.store.select(fromSelectors.selectProfileForm);
  profileSaved$: Observable<Action>;
  themeMode$ = this.store.select(fromSelectors.selectThemeMode);
  isAccountSetup$ = this.store.select(fromSelectors.selectIsAccountSetup);

  accountPriceListStupProgress$ = this.store.select(
    fromSelectors.selectAccountPriceListStupProgress,
  );

  constructor(
    private store: Store,
    private router: Router,
    actions$: Actions,
  ) {
    this.accountSaved$ = actions$.pipe(ofType(fromActions.saveAccountSuccess));
    this.accountCancelled$ = actions$.pipe(
      ofType(fromActions.cancelAccountSuccess),
    );
    this.setActiveAccountSuccess$ = actions$.pipe(
      ofType(fromActions.setActiveAccountSuccess),
    );
    this.leftAccountSuccess$ = actions$.pipe(
      ofType(fromActions.leaveAccountSuccess),
    );

    this.profileSaved$ = actions$.pipe(
      ofType(fromActions.saveAccountUserSuccess),
    );
  }

  loginAccountUser(): void {
    this.store.dispatch(fromActions.loginAccountUser());
  }

  cancelAccount(): void {
    this.store.dispatch(fromActions.cancelAccount());
    this.store.dispatch(fromActions.loginAccountUser());
  }

  setThemeMode(val: any): void {
    this.store.dispatch(fromActions.setThemeMode({ payload: val }));
  }

  generateSasToken(): void {
    this.store.dispatch(fromActions.getSasToken());
  }

  markRemovedAccountsMessageShown(ids: string[]): void {
    this.store.dispatch(
      fromActions.markRemovedAccountsMessageShown({ payload: ids }),
    );
  }

  modifyTeamMemberInvite(teamMember: TeamMember): void {
    this.store.dispatch(fromActions.modifyInvite({ teamMember }));
  }

  acceptTeamMemberInvite(teamMember: AcceptTeamMember): void {
    this.store.dispatch(fromActions.acceptInvite({ teamMember }));
  }

  rejectTeamMemberInvite(teamMember: TeamMember): void {
    this.store.dispatch(fromActions.rejectInvite({ teamMember }));
  }

  saveAccountSettingsForm(accountSettings: AccountSettingsModel): void {
    this.store.dispatch(
      fromActions.saveAccountSettingsForm({ accountSettings }),
    );
  }

  uploadCompanyLogo(file: File): void {
    this.store.dispatch(fromActions.uploadCompanyLogo({ file }));
  }

  uploadQuoteLogo(estimateId: string, file: File): void {
    this.store.dispatch(fromActions.uploadQuoteLogo({ estimateId, file }));
  }

  saveProfileSettingsForm(profile: ProfileSettingsModel): void {
    this.store.dispatch(
      fromActions.saveProfileSettingsForm({ payload: profile }),
    );
  }

  setActiveAccount(account: TeamMember): void {
    this.store.dispatch(
      fromActions.setActiveAccount({
        payload: account,
      }),
    );
  }

  leaveAccount(data: MemberLeavesAccount): void {
    this.store.dispatch(fromActions.leaveAccount({ payload: data }));
  }

  getPriceListSetupProgress(): void {
    this.store.dispatch(fromActions.getPriceListSetupProgress());
  }
}
