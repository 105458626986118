import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  EstimatePriceListItem,
  PriceListCategory,
  SingleSaveVariation,
  SingleSaveVariationSection,
  SnippetArea,
  SnippetType,
  Supplier,
} from 'app/shared/models';
import {
  JobVariationAddRemoveCostingFacade,
  JobVariationDetailFacade,
} from 'app/views/jobs/store';
import { CurrentPriceListFacade } from 'app/views/price-list/store/facades';
import { sortBy, uniqBy } from 'lodash';
import {
  TableVirtualScrollDataSource,
  TableVirtualScrollModule,
} from 'ng-table-virtual-scroll';
import { Subject, takeUntil } from 'rxjs';
import { FuseConfirmationConfig} from '@fuse/services/confirmation';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  ConfigQuill,
  QuillEditorModalComponent,
} from '../../../../../../../shared/components';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FuseCardComponent } from '../../../../../../../../@fuse/components/card/card.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf, NgFor, NgStyle, CurrencyPipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';

@Component({
  selector: 'app-job-variation-section',
  templateUrl: './job-variation-section.component.html',
  styleUrls: ['./job-variation-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatExpansionModule,
    NgClass,
    MatIconModule,
    NgIf,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTrimDirectiveModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
    CdkVirtualScrollViewport,
    TableVirtualScrollModule,
    NgStyle,
    MatTableModule,
    MatCheckboxModule,
    FuseCardComponent,
    CurrencyPipe,
    SharedPipesModule,
  ],
})
export class JobVariationSectionComponent
  implements OnInit, OnDestroy, OnChanges
{
  constructor(
    private fb: FormBuilder,
    private facade: JobVariationDetailFacade,
    private priceListFacade: CurrentPriceListFacade,
    private addRemoveCostingFacade: JobVariationAddRemoveCostingFacade,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.filterForm = this.fb.group({
      searchTerm: [''],
      supplierId: [''],
      categoryId: [''],
    });
  }

  private unsubscriber$ = new Subject<void>();

  @Input() section: SingleSaveVariationSection;
  @Input() index: number;
  @Input() singleMode: boolean;
  @Input() showManegeItem?: boolean = true;
  @Input() expanded: boolean = false;
  @Input() variation: SingleSaveVariation;
  @Input() canMoveUp: boolean = true;
  @Input() canMoveDown: boolean = true;
  @Input() readonly: boolean = false;

  filterForm: FormGroup;
  nameControl = new FormControl();
  suppliers: Supplier[] = [];
  categories: PriceListCategory[] = [];
  priceListItems: EstimatePriceListItem[] = [];
  showMobile = false;

  Math = Math;

  displayedColumns: string[] = [
    // 'variationItemCode',
    // 'variationComment',
    'category',
    'itemcode',
    'description',
    'supplier',
    'qty',
    'uom',
    'unitPrice',
    'unitTotal',
    'onCost',
    'netQuote',
  ];
  refuseModifySectionContext: FuseConfirmationConfig = {
    title: 'Error',
    message:
      'This Section cannot be modified because it has already been used in an Accepted Estimate or Variation.',
    icon: {
      name: 'heroicons_outline:exclamation-triangle',
      color: 'error',
    },
    actions: {
      confirm: { label: 'OK', color: 'warn' },
      cancel: { show: false },
    },
  };

  dataSource: any;
  panelOpenState = false;

  get searchTerm(): FormGroup {
    return this.filterForm.get('searchTerm') as FormGroup;
  }

  removeItemsFromCosting(e: Event): void {
    e.preventDefault();
    this.expanded = true;
    e.stopPropagation();
    this.addRemoveCostingFacade.showDialog(this.variation, this.section.id);
  }

  addRemoveItems(e: Event): void {
    e.preventDefault();
    this.expanded = true;
    e.stopPropagation();
    this.facade.showAddRemovePriceListItemsDialog(this.section);
  }

  setQuotable(item: EstimatePriceListItem): void {
    var newItem = {
      ...item,
      quotable: !item.quotable,
      netQuote: !item.quotable
        ? parseFloat((item.unitTotal * (1 + item.onCost / 100)).toFixed(2))
        : 0,
    };
    this.facade.updatePriceListItem(newItem);
  }

  deleteItem(id: string, e): void {
    e.preventDefault();
    e.stopPropagation();
    this.facade.deletePriceListItem(id);
  }

  onEditName() {
    const snippetParams = {
      snippetType: SnippetType.Quote,
      snippetArea: SnippetArea.General,
    };

    const data: ConfigQuill = {
      content: this.section.name,
      title: '',
      noRequired: true,
      minlength: 0,
      snippetsParams: snippetParams,
    };
    const dialogRef = this.dialog.open(QuillEditorModalComponent, {
      width: '626px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        // formControl.setValue(result);
        this.cdRef.detectChanges();
        if (this.section.name !== result) {
          this.facade.renameSection(this.section.id, result);
        }
      }
    });
  }

  rename(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.section.name !== this.nameControl.value) {
      this.facade.renameSection(this.section.id, this.nameControl.value);
    }
  }

  editItem(item: EstimatePriceListItem): void {
    this.facade.updateVariationPriceListItemShowDialog({
      ...item,
    });
  }

  deleteSection(id: string, e) {
    e.preventDefault();
    e.stopPropagation();
    this.facade.deleteSection(id);
  }

  moveDown(id: string, e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.facade.moveVariationSectionDown(id);
  }

  moveUp(id: string, e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.facade.moveVariationSectionUp(id);
  }

  ngOnInit(): void {
    this.filterForm.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((filter) => (this.dataSource.filter = filter));
    this.facade.readonly$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((readonly) => {
        this.readonly = this.readonly ||
          this.route.snapshot.data['userReadonlyMode'] || readonly;
        if (!this.readonly && !this.displayedColumns.includes('action')) {
          this.displayedColumns.push('action');
        }
        if (this.readonly && this.displayedColumns.includes('action')) {
          this.displayedColumns.pop();
        }
      });
    this.priceListFacade.items$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((items) => (this.priceListItems = items));

    this.breakpointObserver
      .observe(['(min-width: 1440px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showMobile = false;
        } else {
          this.showMobile = true;
        }
        this.cdRef.detectChanges();
      });
  }

  ngOnChanges(): void {
    if (this.section) {
      this.nameControl.setValue(this.section.name);
      this.dataSource = new TableVirtualScrollDataSource(this.section.items);

      this.dataSource.filterPredicate = (
        item: EstimatePriceListItem,
        filter,
      ): boolean =>
        (item.description
          ?.toUpperCase()
          .includes(filter.searchTerm.toUpperCase()) ||
          item.priceListCategoryName
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.priceListCategoryCode
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.supplier.name
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase())) &&
        (!filter.supplierId || item.supplierId === filter.supplierId) &&
        (!filter.categoryId || item.priceListCategoryId === filter.categoryId);

      this.suppliers = sortBy(
        uniqBy(
          this.section.items
            ?.filter((i) => i.supplier)
            .map((item) => item.supplier),
          (s) => s.id,
        ),
        (s) => s.name,
      );
      this.categories = sortBy(
        uniqBy(
          this.section.items?.map(
            (item) =>
              <PriceListCategory>{
                id: item.priceListCategoryId,
                code: item.priceListCategoryCode,
                name: item.priceListCategoryName,
              },
          ),
          (s) => s.id,
        ),
        (s) => s.name,
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
