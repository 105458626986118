/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PhotoService } from '../../services/files.service';
import { NgIf } from '@angular/common';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-job-files-folder-create',
  templateUrl: './job-files-folder-create.component.html',
  styleUrls: ['./job-files-folder-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxTrimDirectiveModule,
    NgIf,
  ],
})
export class JobFilesFolderCreateComponent implements OnInit, OnDestroy {
  editForm: FormGroup;
  unsubscriber$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private photoService: PhotoService,
    public dialogRef: MatDialogRef<JobFilesFolderCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { jobId: string; path: string },
  ) {
    this.editForm = this.fb.group({
      name: [
        '',
        {
          validators: [Validators.required],
          asyncValidators: [this.jobFileFolderExistsValidator.bind(this)],
          updateOn: 'change',
        },
      ],
    });
  }

  get folderExists(): boolean {
    return (
      this.editForm.get('name').hasError('folderExists') &&
      this.editForm.get('name').touched
    );
  }

  get required(): boolean {
    return (
      this.editForm.get('name').hasError('required') &&
      this.editForm.get('name').touched
    );
  }

  jobFileFolderExistsValidator(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    const newPath = `${this.data.path}${control.value}\\`;

    return control.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      switchMap(() =>
        this.photoService.fileFolderExists(this.data.jobId, newPath),
      ),
      tap(() => this.cd.markForCheck()),
      map((exists) => (exists ? { folderExists: true } : null)),
      first(),
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  save(): void {
    const response = {
      jobId: this.data.jobId,
      path: `${this.data.path}${this.editForm.get('name').value}\\`,
    };
    this.dialogRef.close(response);
  }
}
