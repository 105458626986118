import { createAction, props } from '@ngrx/store';
import {
  EstimatePriceListItem,
  JobCostingPriceListItem,
  SingleSaveVariation, VariationEx,
} from 'app/shared/models';

export enum JobVariationActionTypes {
  GetJobVariation = '[Job Variation] Get Job Variation',
  GetJobVariationSuccess = '[Job Variation] Get Job Variation Success',
  GetJobVariationFailure = '[Job Variation] Get Job Variation Failure',
  SaveJobVariation = '[Job Variation] Save Job Variation',
  SaveJobVariationSuccess = '[Job Variation] Save Job Variation Success',
  SaveJobVariationFailure = '[Job Variation] Save Job Variation Failure',
  UpdateJobCosting = '[Job Variation] UpdateJobCosting',
  UpdateJobCostingSuccess = '[Job Variation] UpdateJobCosting Success',
  UpdateJobCostingFailure = '[Job Variation] UpdateJobCosting Failure',
  ResetJobVariation = '[Job Variation] Reset',
  DeleteJobVariationPriceListItemConfirm = '[Job Variation] Delete Price List Item Confirm',
  DeleteJobVariationPriceListItem = '[Job Variation] Delete Price List Item',
  DeleteJobVariationPriceListItemSuccess = '[Job Variation] Delete Price List Item Success',
  DeleteJobVariationPriceListItemFailure = '[Job Variation] Delete Price List Item Failure',
  UpdateJobVariationPriceListItem = '[Job Variation] Update Price List Item',
  UpdateJobVariationTotals = '[Job Variation] Update Totals',
  UpdateJobVariationName = '[Job Variation] Update Name',
  UpdateJobVariationOnCost = '[Job Variation] Update OnCost',
  UpdateJobVariationProfit = '[Job Variation] Update Profit',
  UpdateJobVariationCustomerQuote = '[Job Variation] Update Customer Quote',
  UpdateJobVariationSectionPriceListItemsShowDialog = '[Job Variation] Update Job Variation Section Price List Items Show Dialog',
  UpdateVariationPriceListItemShowDialog = '[Job Variation] Update Job Variation Price List Item Show Dialog',
  UpdateJobVariationSectionPriceListItems = '[Job Variation] Update Job Variation Section Price List Items',
  RemoveJobVariationSectionPriceItemsFromCostingShowDialog = '[Job Variation] Remove Job Variation Price List Items From Costing Show Dialog',

  AddRemoveVariationPriceListItemsShowDialog = '[Variation] Update Variation Price List Items Show Dialog',
  AddRemoveVariationPriceListItems = '[Variation] Update Variation Price List Items',

  ChangeVariationStatus = '[Job Variation] ChangeVariationStatus',
  ChangeVariationStatusSuccess = '[Job Variation] ChangeVariationStatus Success',
  ChangeVariationStatusFailure = '[Job Variation] ChangeVariationStatus Failure',
  ShowAddSectionDialog = '[Job Variation] ShowAddSectionDialog',
  AddSection = '[Job Variation] AddSection',
  DeleteSection = '[Job Variation] DeleteSection',
  MoveVariationSectionDown = '[Job Variation] MoveVariationSectionDown',
  MoveVariationSectionUp = '[Job Variation] MoveVariationSectionUp',
  DeleteVariationSectionConfirm = '[Job Variation] DeleteVariationSectionConfirm',
  ShowAddVariationDialog = '[Job Variation] ShowAddVariationDialog',
  AddVariation = '[Job Variation] AddVariation',
  ShowRenameVariationDialog = '[Job Variation] ShowRenameVariationDialog',
  RenameVariationSection = '[Job Variation] RenameVariationSection',
  RenameVariationSectionSuccess = '[Job Variation] RenameVariationSection Success',
  RenameVariationSectionFailure = '[Job Variation] RenameVariationSection Failure',

  UploadVariationLetterLogo = '[Job Variation] Upload VariationLetter Logo',
  UploadVariationLetterLogoSuccess = '[Job Variation] Upload VariationLetter Logo Success',
  UploadVariationLetterLogoFailure = '[Job Variation] Upload VariationLetter Logo Failure',

  ClearVariationChangesState = '[Job Variation] ClearVariationChangesState'
}

export const removeJobVariationSectionPriceItemsFromCostingShowDialog =
  createAction(
    JobVariationActionTypes.RemoveJobVariationSectionPriceItemsFromCostingShowDialog,
    props<{
      variation: SingleSaveVariation;
      section: any;
    }>(),
  );

export const updateJobVariationSectionPriceListItemsShowDialog = createAction(
  JobVariationActionTypes.UpdateJobVariationSectionPriceListItemsShowDialog,
  props<{ section: any }>(),
);

export const updateJobVariationSectionPriceListItems = createAction(
  JobVariationActionTypes.UpdateJobVariationSectionPriceListItems,
  props<{
    jobCostingSectionId: string;
    added: EstimatePriceListItem[];
    removed: EstimatePriceListItem[];
  }>(),
);

export const updateJobVariationCustomerQuote = createAction(
  JobVariationActionTypes.UpdateJobVariationCustomerQuote,
  props<{ customerQuote: number }>(),
);

export const updateJobVariationOnCost = createAction(
  JobVariationActionTypes.UpdateJobVariationOnCost,
  props<{ onCost: number }>(),
);

export const updateJobVariationProfit = createAction(
  JobVariationActionTypes.UpdateJobVariationProfit,
  props<{ profit: number }>(),
);

export const updateJobVariationName = createAction(
  JobVariationActionTypes.UpdateJobVariationName,
  props<{ name: string }>(),
);

export const saveJobVariation = createAction(
  JobVariationActionTypes.SaveJobVariation,
);

export const saveJobVariationSuccess = createAction(
  JobVariationActionTypes.SaveJobVariationSuccess,
  props<{ variation: SingleSaveVariation }>(),
);

export const saveJobVariationFailure = createAction(
  JobVariationActionTypes.SaveJobVariationFailure,
  props<{ error: any }>(),
);

export const updateJobCosting = createAction(
  JobVariationActionTypes.UpdateJobCosting,
  props<{ jobCosting: SingleSaveVariation }>(),
);

export const updateJobCostingSuccess = createAction(
  JobVariationActionTypes.UpdateJobCostingSuccess,
  props<{ variation: SingleSaveVariation }>(),
);

export const updateJobCostingFailure = createAction(
  JobVariationActionTypes.UpdateJobCostingFailure,
  props<{ error: any }>(),
);

export const deleteJobVariationPriceListItemConfirm = createAction(
  JobVariationActionTypes.DeleteJobVariationPriceListItemConfirm,
  props<{ id: string }>(),
);

export const deleteJobVariationPriceListItem = createAction(
  JobVariationActionTypes.DeleteJobVariationPriceListItem,
  props<{ id: string }>(),
);

export const deleteJobVariationPriceListItemSuccess = createAction(
  JobVariationActionTypes.DeleteJobVariationPriceListItemSuccess,
  props<{ id: string }>(),
);

export const deleteJobVariationPriceListItemFailure = createAction(
  JobVariationActionTypes.DeleteJobVariationPriceListItemFailure,
  props<{ error: any }>(),
);

export const getJobVariation = createAction(
  JobVariationActionTypes.GetJobVariation,
  props<{ variationId: string }>(),
);

export const resetJobVariation = createAction(
  JobVariationActionTypes.ResetJobVariation,
);

export const getJobVariationSuccess = createAction(
  JobVariationActionTypes.GetJobVariationSuccess,
  props<{ variationEx: VariationEx }>(),
);

export const getJobVariationFailure = createAction(
  JobVariationActionTypes.GetJobVariationFailure,
  props<{ error: string }>(),
);

export const updateJobVariationPriceListItem = createAction(
  JobVariationActionTypes.UpdateJobVariationPriceListItem,
  props<{ item: EstimatePriceListItem }>(),
);

export const updateJobVariationTotals = createAction(
  JobVariationActionTypes.UpdateJobVariationTotals,
);

export const changeVariationStatus = createAction(
  JobVariationActionTypes.ChangeVariationStatus,
  props<{ jobId: string, variationId: string; status: string }>(),
);

export const changeVariationStatusSuccess = createAction(
  JobVariationActionTypes.ChangeVariationStatusSuccess,
  props<{ variation: SingleSaveVariation }>(),
);

export const changeVariationStatusFailure = createAction(
  JobVariationActionTypes.ChangeVariationStatusFailure,
  props<{ error: any }>(),
);

export const showAddSectionDialog = createAction(
  JobVariationActionTypes.ShowAddSectionDialog,
);
export const addSection = createAction(
  JobVariationActionTypes.AddSection,
  props<{ name: string }>(),
);

export const deleteSection = createAction(
  JobVariationActionTypes.DeleteSection,
  props<{ id: string }>(),
);

export const showAddVariationDialog = createAction(
  JobVariationActionTypes.ShowAddVariationDialog,
  props<{ jobId: string }>(),
);

export const showRenameSectionDialog = createAction(
  JobVariationActionTypes.ShowRenameVariationDialog,
  props<{ sectionId: string; name: string }>(),
);

export const renameVariationSection = createAction(
  JobVariationActionTypes.RenameVariationSection,
  props<{ id: string; name: string }>(),
);

export const renameVariationSectionSuccess = createAction(
  JobVariationActionTypes.RenameVariationSectionSuccess,
  props<{ id: string; name: string }>(),
);

export const renameVariationSectionFailure = createAction(
  JobVariationActionTypes.RenameVariationSectionFailure,
  props<{ error: any }>(),
);

export const deleteVariationSectionConfirm = createAction(
  JobVariationActionTypes.DeleteVariationSectionConfirm,
  props<{ id: string }>(),
);

export const updateVariationPriceListItemShowDialog = createAction(
  JobVariationActionTypes.UpdateVariationPriceListItemShowDialog,
  props<{ item: JobCostingPriceListItem }>(),
);

export const addVariation = createAction(
  JobVariationActionTypes.AddVariation,
  props<{ jobId: string; name: string }>(),
);

export const moveVariationSectionDown = createAction(
  JobVariationActionTypes.MoveVariationSectionDown,
  props<{ id: string }>(),
);

export const moveVariationSectionUp = createAction(
  JobVariationActionTypes.MoveVariationSectionUp,
  props<{ id: string }>(),
);

//uploadVariationLetterLogo
export const uploadVariationLetterLogo = createAction(
  JobVariationActionTypes.UploadVariationLetterLogo,
  props<{ variationId: string; file: File }>(),
);
export const uploadVariationLetterLogoSuccess = createAction(
  JobVariationActionTypes.UploadVariationLetterLogoSuccess,
  props<{ fileLocation: string }>(),
);
export const uploadVariationLetterLogoFailure = createAction(
  JobVariationActionTypes.UploadVariationLetterLogoFailure,
  props<{ error: any }>(),
);

export const addRemoveVariationPriceListItemsShowDialog = createAction(
  JobVariationActionTypes.AddRemoveVariationPriceListItemsShowDialog,
);

export const clearVariationChangesState = createAction(
  JobVariationActionTypes.ClearVariationChangesState,
);

export const addRemoveVariationPriceListItems = createAction(
  JobVariationActionTypes.AddRemoveVariationPriceListItems,
  props<{
    added: JobCostingPriceListItem[];
    removed: JobCostingPriceListItem[];
  }>(),
);
