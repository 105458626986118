<section class="w-3/4 sm:w-full" *ngIf="facade.isInitialised$ | async">
  <form
    class="flex flex-col mt-4 bg-card shadow rounded overflow-hidden"
    [formGroup]="form"
    autocomplete="off"
  >
    <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
      <p class="text-lg font-medium">Measures used in the app</p>
      <p class="text-secondary">
        Some explanation of where and how measures are used
      </p>
    </div>
    <div class="flex flex-col p-8">
      <div class="flex flex-row justify-end mb-4 items-center">
        <button
          class="pl-2 text-xs rounded-xl min-h-8 h-8 px-4"
          [color]="'primary'"
          [disabled]="isReadOnly"
          (click)="add()"
          mat-flat-button
        >
          <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
          <span class="ml-2 mr-1">New Measure</span>
        </button>
      </div>
      <table
        class="mat-elevation-z1 w-full mt-8"
        #table
        [dataSource]="dataSource"
        mat-table
      >
        <!-- UOM Column -->
        <ng-container matColumnDef="uom">
          <th *matHeaderCellDef mat-header-cell>UOM</th>
          <td
            *matCellDef="let element; let i = index"
            [formGroup]="element"
            mat-cell
          >
            <mat-form-field class="w-full">
              <input
                class="w-full"
                [readonly]="form.get('rows').value[i].isEditable"
                (click)="clickInput($event, element, i)"
                (ondblclick)="clickInput($event, element, i)"
                matInput
                type="text"
                formControlName="uom"
                maxlength="50"
                required
              />
              <mat-error
                *ngIf="
                  form
                    .get('rows')
                    .controls[i].get('uom')
                    .hasError('required') &&
                  !form
                    .get('rows')
                    .controls[i].get('uom')
                    .hasError('whitespace')
                "
                >Must not be empty</mat-error
              >
              <mat-error
                *ngIf="
                  form
                    .get('rows')
                    .controls[i].get('uom')
                    .hasError('required') &&
                  form.get('rows').controls[i].get('uom').hasError('whitespace')
                "
                >Must not be empty and no empty strings</mat-error
              >
              <mat-error
                *ngIf="
                  form
                    .get('rows')
                    .controls[i].get('uom')
                    .hasError('whitespace') &&
                  !form.get('rows').controls[i].get('uom').hasError('required')
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="
                  form.get('rows').controls[i].get('uom').hasError('uomExists')
                "
                >Value exists</mat-error
              >
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th *matHeaderCellDef mat-header-cell>Action</th>
          <td
            *matCellDef="let element; let i = index"
            [formGroup]="element"
            mat-cell
          >
            <button
              class="material-icons app-toolbar-menu save-button"
              *ngIf="!form.get('rows').value[i].isEditable"
              [disabled]="
                form.get('rows').at(i).valid === false ||
                form.get('rows').at(i).dirty === false ||
                isReadOnly
              "
              (click)="save(form, i)"
              mat-icon-button
              matTooltip="Save Changes"
              color="primary"
            >
              <mat-icon>check_circle</mat-icon>
            </button>

            <button
              class="material-icons app-toolbar-menu cancel-button"
              *ngIf="!form.get('rows').value[i].isEditable"
              (click)="cancel(form, i)"
              mat-icon-button
              matTooltip="Cancel Changes"
              color="warn"
            >
              <mat-icon>cancel</mat-icon>
            </button>

            <button
              class="material-icons app-toolbar-menu save-button"
              *ngIf="form.get('rows').value[i].isEditable"
              [disabled]="isReadOnly"
              (click)="edit(form, i)"
              mat-icon-button
              matTooltip="Edit"
              color="primary"
            >
              <mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
            </button>

            <button
              class="material-icons app-toolbar-menu delete-button"
              *ngIf="form.get('rows').value[i].isEditable"
              [disabled]="isReadOnly"
              (click)="delete(form, i)"
              mat-icon-button
              matTooltip="Delete"
              color="warn"
            >
              <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      </table>
    </div>
  </form>
</section>
