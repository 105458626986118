import * as fromActions from '../actions/supplier-price-list.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { CallState, LoadingState } from 'app/store';
import { PageMetaData } from 'app/shared/models';

export interface SupplierPriceListItemState {
  supplierPriceList: SupplierPriceListItemQuery;
  callState: CallState;
  error: any;
}

class PageMetaDataClass implements PageMetaData {
  pageNumber: null;
  pages: null;
  pageSize: null;
  pageRecords: null;
  records: null;
  hasPrevious: null;
  hasNext: null;
}

export interface SupplierPriceListItemQuery {
  items: any[];
  pageMetaData?: PageMetaData;
}

export const initialState: SupplierPriceListItemState = {
  supplierPriceList: { items: [], pageMetaData: new PageMetaDataClass() },
  error: null,
  callState: LoadingState.INIT,
};

export const profileReducer = createReducer(
  initialState,
  on(fromActions.getSupplierPriceListItem, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.getSupplierPriceListItemFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.getSupplierPriceListItemSuccess, (state, { payload }) => ({
    ...state,
    supplierPriceList: payload as SupplierPriceListItemQuery,
    callState: LoadingState.LOADED,
  })),

  on(fromActions.addSupplierPriceItem, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.addSupplierPriceItemFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.addSupplierPriceItemSuccess, (state, { payload }) => ({
    ...state,
    supplierPriceList: {
      items: [...state.supplierPriceList.items, ...[payload as any]],
      pageMetaData: {
        ...state.supplierPriceList.pageMetaData,
        ...{ records: state.supplierPriceList.pageMetaData.records + 1 },
      },
    },
    callState: LoadingState.LOADED,
  })),
);

export function reducer(
  state: SupplierPriceListItemState | undefined,
  action: Action,
) {
  return profileReducer(state, action);
}

export const getSupplierPriceListItemPageNumber = (
  state: SupplierPriceListItemState,
) => {
  return state.supplierPriceList.pageMetaData.pageNumber;
};

export const getSupplierPriceListItem = (state: SupplierPriceListItemState) => {
  return state.supplierPriceList;
};

export const getSupplierPriceListItemItems = (
  state: SupplierPriceListItemState,
) => {
  return state.supplierPriceList.items;
};

export const getSupplierTotalCount = (state: SupplierPriceListItemState) => {
  return state.supplierPriceList.pageMetaData.records;
};

export const getError = (state: SupplierPriceListItemState) => {
  return state.error;
};
