import { createAction, props } from '@ngrx/store';
import {
  AcceptTeamMember,
  Account,
  AccountUser,
  LoggedInUser,
  MemberLeavesAccount,
  PriceListCompleted,
  TeamMember,
  UnitOfMeasureParameters,
} from 'app/shared/models/eazimate.models';
import {
  AccountSettingsModel,
  ProfileSettingsModel,
} from 'app/shared/models/form-ui.models';

export enum AccountUserActionTypes {
  loginAccountUserAction = '[Account User Settings] Login Account User',
  loginAccountUserSuccessAction = '[Account User Settings] Login Account User Success',
  loginAccountUserFailureAction = '[Account User Settings] Login Account User Failure',
  loginAccountUserNotFoundAction = '[Account User Settings] Login Account User Not Found Failure',

  setActiveAccountAction = '[Account User Settings] Set Active Account',
  setActiveAccountSuccessAction = '[Account User Settings API] Set Active Account Success',
  setActiveAccountFailureAction = '[Account User Settings API] Set Active Account Failure',

  _saveAccountSettings = '[Account User Settings] Save Account',
  _saveAccountSuccess = '[Account User Settings API] Save Account Success',
  _saveAccountFailure = '[Account User Settings API] Save Account Failure',

  _cancelAccount = '[Account User Settings] Cancel Account',
  _cancelAccountSuccess = '[Account User Settings] Cancel Account Success',

  _saveProfileSettings = '[Account User Settings] Save Profile Settings',
  _saveAccountUserSuccess = '[Account User Settings API] Save Account User Success',
  _saveAccountUserFailure = '[Account User Settings API] Save Account User Failure',

  _acceptInvite = '[Account User Settings] Accept Invite',
  _rejectInvite = '[Account User Settings] Reject Invite',
  _modifyInvite = '[Account User Settings] Modify Invite',
  _teamMemberUpdateSuccess = '[Account User Settings API] Team Member Update Success',
  _teamMemberUpdateFailure = '[Account User Settings API] Team Member Update Failure',

  _uploadCompanyLogo = '[Account User Settings] Upload Company Logo',
  _uploadCompanyLogoSuccess = '[Account User Settings API] Upload Company Logo Success',
  _uploadCompanyLogoFailure = '[Account User Settings API] Upload Company Logo Failure',

  _uploadQuoteLogo = '[Account User] Upload Quote Logo',
  _uploadQuoteLogoSuccess = '[Account User API] Upload Quote Logo Success',
  _uploadQuoteLogoFailure = '[Account User API] Upload Quote Logo Failure',

  _getSasToken = '[Account User] Get SAS Token',
  _getSasTokenSuccess = '[Account User API] Get SAS Token Success',
  _getSasTokenFailure = '[Account User API] Get SAS Token Failure',

  _leaveAccount = '[Account User] Leave Account',
  _leaveAccountSuccess = '[Account User API] Leave Account Success',
  _leaveAccountFailure = '[Account User API] Leave Account Failure',

  _setThemeMode = '[Account User] Set Theme Mode',
  _saveThemeModeSuccess = '[Account User] Set Theme Mode Success',
  _saveThemeModeFailure = '[Account User] Set Theme Mode Failure',

  _markRemovedAccountsMessageShown = '[Account User] Removed Accounts Message Shown',
  _markRemovedAccountsMessageShownSuccess = '[Account User API] Removed Accounts Message Shown Success',
  _markRemovedAccountsMessageShownFailure = '[Account User API] Removed Accounts Message Shown Failure',

  _getPriceListSetupProgress = '[Account User] Get Price List Setup Progress',
  _getPriceListSetupProgressSuccess = '[Account User API] Get Price List Setup Progress Success',
  _getPriceListSetupProgressFailure = '[Account User API] Get Price List Setup Progress Failure',
}

export const markRemovedAccountsMessageShown = createAction(
  AccountUserActionTypes._markRemovedAccountsMessageShown,
  props<{ payload: string[] }>(),
);

export const markRemovedAccountsMessageShownSuccess = createAction(
  AccountUserActionTypes._markRemovedAccountsMessageShownSuccess,
);

export const markRemovedAccountsMessageShownFailure = createAction(
  AccountUserActionTypes._markRemovedAccountsMessageShownFailure,
  props<{ error: any }>(),
);

export const setThemeMode = createAction(
  AccountUserActionTypes._setThemeMode,
  props<{ payload: 'light' | 'dark' | 'auto' }>(),
);

export const saveThemeModeSuccess = createAction(
  AccountUserActionTypes._saveThemeModeSuccess,
  props<{ payload: any }>(),
);

export const saveThemeModeFailure = createAction(
  AccountUserActionTypes._saveThemeModeFailure,
  props<{ error: any }>(),
);

export const leaveAccount = createAction(
  AccountUserActionTypes._leaveAccount,
  props<{ payload: MemberLeavesAccount }>(),
);

export const leaveAccountSuccess = createAction(
  AccountUserActionTypes._leaveAccountSuccess,
  props<{ accountIdToRemove: any }>(),
);

export const leaveAccountFailure = createAction(
  AccountUserActionTypes._leaveAccountFailure,
  props<{ error: any }>(),
);

export const getSasToken = createAction(AccountUserActionTypes._getSasToken);
export const getSasTokenSuccess = createAction(
  AccountUserActionTypes._getSasTokenSuccess,
  props<{ payload: string }>(),
);
export const getSasTokenFailure = createAction(
  AccountUserActionTypes._getSasTokenFailure,
  props<{ payload: any }>(),
);

export const loginAccountUser = createAction(
  AccountUserActionTypes.loginAccountUserAction,
);
export const loginAccountUserSuccess = createAction(
  AccountUserActionTypes.loginAccountUserSuccessAction,
  props<{ payload: LoggedInUser }>(),
);
export const loginAccountUserFailure = createAction(
  AccountUserActionTypes.loginAccountUserFailureAction,
  props<{ payload: any }>(),
);
export const loginAccountUserNotFoundFailure = createAction(
  AccountUserActionTypes.loginAccountUserNotFoundAction,
  props<{ payload: any }>(),
);

export const setActiveAccount = createAction(
  AccountUserActionTypes.setActiveAccountAction,
  props<{ payload: TeamMember }>(),
);
export const setActiveAccountSuccess = createAction(
  AccountUserActionTypes.setActiveAccountSuccessAction,
  props<{ payload: Account }>(),
);
export const setActiveAccountFailure = createAction(
  AccountUserActionTypes.setActiveAccountFailureAction,
  props<{ payload: any }>(),
);

export const saveAccountSettingsForm = createAction(
  AccountUserActionTypes._saveAccountSettings,
  props<{ accountSettings: AccountSettingsModel }>(),
);

export const cancelAccount = createAction(
  AccountUserActionTypes._cancelAccount,
);

export const cancelAccountSuccess = createAction(
  AccountUserActionTypes._cancelAccountSuccess,
  props<{ payload: Account }>(),
);

export const saveAccountSuccess = createAction(
  AccountUserActionTypes._saveAccountSuccess,
  props<{ account: Account }>(),
);

export const saveAccountFailure = createAction(
  AccountUserActionTypes._saveAccountFailure,
  props<{ error: any }>(),
);

export const saveProfileSettingsForm = createAction(
  AccountUserActionTypes._saveProfileSettings,
  props<{ payload: ProfileSettingsModel }>(),
);

export const saveAccountUserSuccess = createAction(
  AccountUserActionTypes._saveAccountUserSuccess,
  props<{ payload: AccountUser }>(),
);

export const saveAccountUserFailure = createAction(
  AccountUserActionTypes._saveAccountUserFailure,
  props<{ payload: any }>(),
);

export const acceptInvite = createAction(
  AccountUserActionTypes._acceptInvite,
  props<{ teamMember: AcceptTeamMember }>(),
);

export const rejectInvite = createAction(
  AccountUserActionTypes._rejectInvite,
  props<{ teamMember: TeamMember }>(),
);

export const modifyInvite = createAction(
  AccountUserActionTypes._modifyInvite,
  props<{ teamMember: TeamMember }>(),
);

export const teamMemberUpdateSuccess = createAction(
  AccountUserActionTypes._teamMemberUpdateSuccess,
  props<{ teamMember: TeamMember }>(),
);

export const teamMemberUpdateFailure = createAction(
  AccountUserActionTypes._teamMemberUpdateFailure,
  props<{ error: any }>(),
);

export const uploadCompanyLogo = createAction(
  AccountUserActionTypes._uploadCompanyLogo,
  props<{ file: File }>(),
);
export const uploadCompanyLogoSuccess = createAction(
  AccountUserActionTypes._uploadCompanyLogoSuccess,
  props<{ fileLocation: string }>(),
);
export const uploadCompanyLogoFailure = createAction(
  AccountUserActionTypes._uploadCompanyLogoFailure,
  props<{ error: any }>(),
);
export const uploadQuoteLogo = createAction(
  AccountUserActionTypes._uploadQuoteLogo,
  props<{ estimateId: string; file: File }>(),
);
export const uploadQuoteLogoSuccess = createAction(
  AccountUserActionTypes._uploadQuoteLogoSuccess,
  props<{ fileLocation: string }>(),
);
export const uploadQuoteLogoFailure = createAction(
  AccountUserActionTypes._uploadQuoteLogoFailure,
  props<{ error: any }>(),
);

export const getPriceListSetupProgress = createAction(
  AccountUserActionTypes._getPriceListSetupProgress,
);

export const getPriceListSetupProgressSuccess = createAction(
  AccountUserActionTypes._getPriceListSetupProgressSuccess,
  props<{ payload: PriceListCompleted }>(),
);

export const getPriceListSetupProgressFailure = createAction(
  AccountUserActionTypes._getPriceListSetupProgressFailure,
  props<{ error: any }>(),
);
