import { SupplierFacade } from './supplier-details.facade';
import { SupplierListFacade } from './supplier-list.facade';
import { SupplierPriceListItemFacade } from './supplier-price-list.facade';

export const facades = [
  SupplierListFacade,
  SupplierFacade,
  SupplierPriceListItemFacade,
];

export * from './supplier-price-list.facade';
export * from './supplier-list.facade';
export * from './supplier-details.facade';
