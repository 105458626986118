import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { Customer, Supplier, TransactionHistory } from 'app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(private http: HttpClient) { }

  getXeroStatus(): Observable<{ connected: boolean, tenantName: string }> {
    const url = `${environment.urls.api}/integration/xero/status`;
    return this.http.get<{ connected: boolean, tenantName: string }>(url);
  }

  connectToXero(): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/connect`;
    return this.http.post<any>(url, {});
  }

  disconnectFromXero(): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/disconnect`;
    return this.http.post<any>(url, {});
  }

  getXeroAccounts(): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/accounts`;
    return this.http.get<any>(url);
  }

  getXeroCustomers(pageNum: number, pageSize: number, searchTerm: string): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/customers?pagenum=${pageNum}&pageSize=${pageSize}&searchterm=${searchTerm}`;
    return this.http.get<any>(url);
  }

  saveXeroIntegrationCustomer(customer: Customer): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/importcustomer`;
    return this.http.post<any>(url, customer);
  }

  saveXeroIntegrationSupplier(supplier: Supplier): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/importsupplier`;
    return this.http.post<any>(url, supplier);
  }

  getXeroSuppliers(pageNum: number, pageSize: number, searchTerm: string): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/suppliers?pagenum=${pageNum}&pagesize=${pageSize}&searchterm=${searchTerm}`;
    return this.http.get<any>(url);
  }

  getXeroSettings(): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/settings`;
    return this.http.get<any>(url);
  }

  updateXeroSettings(settings): Observable<any> {
    const url = `${environment.urls.api}/integration/xero/settings`;
    return this.http.post<any>(url, settings);
  }

  getQuickBooksStatus(): Observable<{ connected: boolean }> {
    const url = `${environment.urls.api}/integration/quickbooks/status`;
    return this.http.get<{ connected: boolean }>(url);
  }

  connectToQuickBooks(): Observable<any> {
    const url = `${environment.urls.api}/integration/quickbooks/connect`;
    return this.http.post<any>(url, {});
  }

  disconnectFromQuickBooks(): Observable<any> {
    const url = `${environment.urls.api}/integration/quickbooks/disconnect`;
    return this.http.post<any>(url, {});
  }

  getQuickBookSettings(): Observable<any> {
    const url = `${environment.urls.api}/integration/quickbooks/settings`;
    return this.http.get<any>(url);
  }

  updateQuickBookSettings(settings): Observable<any> {
    const url = `${environment.urls.api}/integration/quickbooks/settings`;
    return this.http.post<any>(url, settings);
  }

  getTransactionHistoryIssues(top: number): Observable<any> {
    const url = `${environment.urls.api}/integration/issues?top=${top}`;
    return this.http.get<any>(url);
  }

  getTransactionHistory(top: number, type: number): Observable<any> {
    const url = `${environment.urls.api}/integration/transactionhistory?top=${top}&type=${type}`;
    return this.http.get<any>(url);
  }

  getTransactionHistoryByReferenceId(
    referenceId: string,
  ): Observable<TransactionHistory> {
    const url = `${environment.urls.api}/integration/transactionhistory/${referenceId}`;
    return this.http.get<TransactionHistory>(url);
  }

  removeIssues(ids: string[]) {
    const url = `${environment.urls.api}/integration/issues/remove`;
    return this.http.post<TransactionHistory>(url, {
      ids: ids
    });
  }
}
