import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobVariationSectionFilter',
  pure: false,
  standalone: true,
})
export class JobVariationSectionFilterFilterPipe implements PipeTransform {
  transform(
    items: any[],
    filter: {
      searchTerm: string;
      supplierId: string;
      categoryId: string;
    },
  ): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(
      (item) =>
        (item.description
          ?.toUpperCase()
          .includes(filter.searchTerm.toUpperCase()) ||
          item.priceListCategoryName
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.priceListCategoryCode
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase()) ||
          item.supplier.name
            .toUpperCase()
            .includes(filter.searchTerm.toUpperCase())) &&
        (!filter.supplierId || item.supplierId === filter.supplierId) &&
        (!filter.categoryId || item.priceListCategoryId === filter.categoryId),
    );
  }
}
