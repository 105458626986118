<div
  class="flex flex-row flex-wrap justify-around rounded border border-gray-300 dark:border-gray-500"
  [formGroup]="estimateTotalsForm"
>
  <!--  <div class="">-->
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Unit total</div>
    <div
      class="w-full px-4 py-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
    >
      <input
        class="w-full px-2 py-2 rounded text-right"
        formControlName="unitTotal"
        readonly
      />
    </div>
  </div>
  <!--  </div>-->
  <!-- is hidden according to #4257 -->
  <!--  <div class="">-->
  <!--    <div class="flex flex-col w-40 max-w-full">-->
  <!--      <div>Quotable total</div>-->
  <!--      <div-->
  <!--        class="w-full px-4 py-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"-->
  <!--      >-->
  <!--        <input-->
  <!--          class="w-full px-2 py-2 rounded"-->
  <!--          [inputMask]="currencyInputMask"-->
  <!--          formControlName="quotableUnitTotal"-->
  <!--          readonly-->
  <!--        />-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div class="">-->
  <div id="estimate-summary-on-cost" class="flex flex-col w-40 max-w-full p-2">
    <div>On cost %</div>
    <div
      class="w-full px-4 py-2 rounded mt-0.5"
      [ngClass]="{
          'bg-green-600': (estimate$ | async) && (defaultOnCost < +estimateTotalsForm.get('onCost').value),
          'bg-warn-600': (estimate$ | async) && (defaultOnCost > +estimateTotalsForm.get('onCost').value),
          'bg-primary-100 dark:bg-primary-900': (estimate$ | async) && (defaultOnCost === +estimateTotalsForm.get('onCost').value)
          }"
    >
      <input
        #onCost
        class="w-full px-2 py-2 rounded border text-right"
        (blur)="updateValue('onCost')"
        (keydown.enter)="onCost.blur()"
        type="text"
        formControlName="onCost"
        [readonly]="isReadOnlyMode"
      />
    </div>
  </div>
  <!--  </div>-->
  <!--  <div class="">-->
  <div id="estimate-summary-profit" class="flex flex-col w-40 max-w-full p-2">
    <div>Profit</div>
    <div
      class="w-full px-4 py-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
    >
      <input
        #profitAmount
        class="w-full px-2 py-2 rounded border text-right"
        (blur)="updateValue('profitAmount')"
        (keydown.enter)="profitAmount.blur()"
        formControlName="profitAmount"
        [readonly]="isReadOnlyMode"
      />
    </div>
  </div>
  <!--  </div>-->
  <!--  <div class="">-->
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Net quote</div>
    <div
      class="w-full px-4 py-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
    >
      <input
        class="w-full px-2 py-2 rounded"
        [inputMask]="currencyInputMask"
        formControlName="netQuoteAmount"
        readonly
      />
    </div>
  </div>
  <!--  </div>-->
  <!--  <div class="">-->
  <div
    id="estimate-summary-customer-quote"
    class="flex flex-col w-40 max-w-full p-2"
  >
    <div>Customer quote</div>
    <div
      class="w-full px-4 py-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
    >
      <input
        #quoteAmount
        class="w-full px-2 py-2 rounded border text-right"
        (blur)="updateValue('quoteAmount')"
        (keydown.enter)="quoteAmount.blur()"
        formControlName="quoteAmount"
        [readonly]="isReadOnlyMode"
      />
    </div>
  </div>
  <!--  </div>-->
</div>
