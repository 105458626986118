/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-parens */
import { createSelector } from '@ngrx/store';
import { LoadingState } from 'app/store';
import { first, flatten, last, maxBy, orderBy, sumBy, uniq } from 'lodash';
import { selectJobState } from '../reducers';
import { PhotosListSortField } from '../reducers/photos.reducer';

export const home = {
  name: 'Home',
  path: 'Home\\',
  files: [],
  size: 0,
};

export const selectPhotosState = createSelector(
  selectJobState,
  (s) => s.photos,
);

export const selectAllPhotos = createSelector(selectPhotosState, (s) => {
  return s.photos;
});

export const selectPhotos = createSelector(selectPhotosState, (s) => {
  let photos = s.photos
    .filter((f) => f.id)
    .map((f) => {
      return { ...f, selected: s.selectedFileIds.includes(f.id) };
    });
  if (s.sortField === PhotosListSortField.Date) {
    photos = orderBy(photos, (s) => s.takenDate ?? s.updated, s.sortOrder);
  } else {
    photos = orderBy(photos, [s.sortField], [s.sortOrder]);
  }
  return photos;
});

export const selectFolders = createSelector(selectPhotosState, (s) => {
  const paths = uniq(
    flatten(
      s.photos.map((f) => {
        let path = '';
        return f.path
          ?.split('\\')
          .filter((p) => p)
          .map((p) => {
            path += p + '\\';
            return path;
          });
      }),
    ),
  );

  let folders = paths.map((f) => {
    const parts = f?.split('\\');
    const name = last(parts?.filter((p) => p));
    const descendentFiles = s.photos.filter(
      (a) => a.name && a.path.startsWith(f),
    );
    const parentLevel = parts?.length;

    const childFolders = paths.filter((p) => {
      const level = p?.split('\\').length;
      return p?.startsWith(f) && level === parentLevel + 1;
    });

    const size = sumBy(descendentFiles, (c) => c.size);
    const d = maxBy(
      descendentFiles.filter((x) => x.path.startsWith(f)),
      (f) => f.takenDate ?? f.updated,
    );
    const date = d
      ? d.takenDate ?? d.updated
      : first(s.photos.filter((a) => a.path?.startsWith(f)))?.updated;

    return {
      name: name,
      path: f,
      date,
      fileCount: descendentFiles.filter((x) => x.path === f).length,
      folderCount: childFolders.length,
      size: size,
      selected: s.selectedFolderPaths.includes(f),
    };
  });

  folders = orderBy(folders, [s.sortField], [s.sortOrder]);
  return folders;
});

export const selectJobPhotosCurrentFolder = createSelector(
  selectPhotosState,
  selectFolders,
  (s, folders) => folders.find((f) => f.path === s.currentFolderPath) ?? home,
);

export const selectPhotosCurrentPhotos = createSelector(
  selectPhotosState,
  selectPhotos,
  (s, photos) => photos.filter((f) => f.path === s.currentFolderPath && f.name),
);

export const selectJobPhotosCurrentFolders = createSelector(
  selectPhotosState,
  selectFolders,
  (s, folders) =>
    folders.filter(
      (f) =>
        f.path?.startsWith(s.currentFolderPath) &&
        f.path.split('\\').length ===
          s.currentFolderPath.split('\\').length + 1,
    ),
);

export const selectJobPhotosFolders = createSelector(
  selectPhotosState,
  selectFolders,
  (s, folders) => folders,
);

export const selectJobPhotosIsLoaded = createSelector(
  selectPhotosState,
  (s) => s?.callState === LoadingState.LOADED,
);

export const selectJobPhotosSortField = createSelector(
  selectPhotosState,
  (s) => s?.sortField,
);

export const selectJobPhotosSortOrder = createSelector(
  selectPhotosState,
  (s) => s?.sortOrder,
);

export const selectJobPhotosDetailPhoto = createSelector(
  selectPhotosCurrentPhotos,
  selectPhotosState,
  (photos, s) => photos[s.detailPhotoIndex],
);

export const selectPhotosDetailPhotoIndex = createSelector(
  selectPhotosState,
  (s) => s.detailPhotoIndex,
);

export const selectSelectedJobFileIds = createSelector(
  selectPhotosState,
  (s) => s.selectedFileIds,
);

export const selectSelectedJobFolderPaths = createSelector(
  selectPhotosState,
  (s) => s.selectedFolderPaths,
);
