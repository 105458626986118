/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum FileDownloadActionTypes {
  FileDownloadRequest = '[File Download] Request',
  FileDownloadFailure = '[File Download] Failure',
  FileDownloadNotFound = '[File Download] Not Found',
  FileDownloadSuccess = '[File Download] Success',
}

export const fileDownloadRequest = createAction(
  FileDownloadActionTypes.FileDownloadRequest,
  props<{ url: string; filename: string }>(),
);

export const fileDownloadSuccess = createAction(
  FileDownloadActionTypes.FileDownloadSuccess,
  props<{ blob: Blob; filename: string }>(),
);

export const fileDownloadNotFound = createAction(
  FileDownloadActionTypes.FileDownloadNotFound,
  props<{ url: string; filename: string }>(),
);

export const fileDownloadFailure = createAction(
  FileDownloadActionTypes.FileDownloadFailure,
  props<{ error: string }>(),
);
