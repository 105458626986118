import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  getSuppliersListByJob,
  getUseCategories,
  selectSuppliersListByJobError,
  selectSuppliersListByJobItems,
  selectSuppliersListByJobLoaded,
  selectSuppliersListByJobLoading,
  selectUseCategories,
  updateUseCategories,
} from '..';
import { JobListState } from '../reducers/job-list.reducer';

@Injectable({
  providedIn: 'root',
})
export class SuppliersListByJobFacade {
  isLoading$ = this.store.select(selectSuppliersListByJobLoading);
  isLoaded$ = this.store.select(selectSuppliersListByJobLoaded);
  error$ = this.store.select(selectSuppliersListByJobError);
  items$ = this.store.select(selectSuppliersListByJobItems);
  useCategories$ = this.store.select(selectUseCategories);

  constructor(private store: Store<JobListState>) {}

  getSuppliersListByJob(id: string): void {
    this.store.dispatch(getSuppliersListByJob({ id }));
  }
  getUseCategories(id: string): void {
    this.store.dispatch(getUseCategories({ id }));
  }
  updateUseCategories(id: string, value: boolean): void {
    this.store.dispatch(updateUseCategories({ id, value }));
  }
}
