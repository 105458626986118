/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { tap } from 'rxjs/operators';
import * as fromActions from '../actions/router.action';
@Injectable({
  providedIn: 'root',
})
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.go),
        tap(({ payload }) => {
          console.log(payload);
          this.router.navigate(payload.path, {
            queryParams: payload.query,
            ...payload.extras,
          });
        }),
      ),
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.back),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.forward),
        tap(() => this.location.forward()),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
  ) {}
}
