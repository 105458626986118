<!-- placeholder="Type here at least 3 characters" -->
<quill-editor
  (change)="modelChanged()"
  [formControl]="formControl"
  [minLength]="data?.minlength"
  [required]="!data?.noRequired"
  [maxLength]="data?.maxlength"
  style="height: 100px; width: 100%"
>
  <div quill-editor-toolbar class="flex items-center">
    <span class="ql-formats border-r pr-4">
      <button class="ql-bold" [title]="'Bold'"></button>
      <button class="ql-italic" [title]="'Italic'"></button>
      <button class="ql-underline" [title]="'Underline'"></button>
    </span>
    <span class="ql-formats border-r pr-4">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
    </span>
    <span class="ql-formats border-r pr-4" (click)="linkClick()">
      <button class="ql-link"></button>
    </span>
    <ng-container
      *ngIf="data?.snippetsParams && snippetsFacade.isLoaded$ | async"
    >
      <ng-container *ngIf="snippetsOptions$ | async; let snippetsOptions">
        <span *ngIf="snippetsOptions.length" class="ql-formats mx-0 w-40">
          <mat-form-field class="fuse-mat-no-subscript w-full small-select">
            <mat-select
              [value]="
                useFirstValue
                  ? (snippetsOptions$ | async)[0]?.content
                  : defaultValue
              "
              #select
              placeholder="Insert snippet"
              panelClass="w-40"
            >
              <mat-option
                *ngFor="let s of snippetsOptions"
                [value]="s.content"
                (click)="setSpippet(s.content)"
              >
                {{ s.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </ng-container>
    </ng-container>
    <span class="ql-formats" *ngIf="formControl.enabled">
      <app-speach-to-text
        [formControl]="formControl"
        name="voiceInput"
      ></app-speach-to-text>
    </span>
  </div>
</quill-editor>
