<div
  class="absolute w-full h-full xs:inset-0 flex flex-col flex-auto min-w-0 xs:overflow-hidden"
>
  <div *ngIf="!isSmallPortraitScreen" class="filter-section mx-6" id="status-filter-invoices">
    <mat-form-field appearance="fill" class="min-w-72 mr-4">
      <mat-select [formControl]="statusControl">
        <mat-option
          *ngFor="let jobStatus of invoiceStatuses"
          [value]="jobStatus.value"
        >
          Invoice Status - {{ jobStatus.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div
    *ngIf="!isSmallPortraitScreen && jobInvoiceTotals"
    class="invoice-summary flex flex-row gap-x-3 p-2 mb-4 rounded ml-4 border border-gray-300 dark:border-gray-500"
    style="width: 45rem"
  >
    <div class="flex flex-col w-1/5">
      <div class="">Net quote</div>
      <div
        class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
      >
        {{ jobInvoiceTotals?.netAmount | currency }}
      </div>
    </div>
    <div class="flex flex-col w-1/5">
      <div class="">GST</div>
      <div
        class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
      >
        {{ jobInvoiceTotals?.gstAmount | currency }}
      </div>
    </div>
    <div class="flex flex-col w-1/5">
      <div class="">Customer quote</div>
      <div
        class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
      >
        {{ jobInvoiceTotals?.customerQuote | currency }}
      </div>
    </div>
    <div class="flex flex-col w-1/5">
      <div class="">Invoiced</div>
      <div
        class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
      >
        {{ jobInvoiceTotals?.invoiced | currency }}
      </div>
    </div>
    <div class="flex flex-col w-1/5">
      <div class="">Payment Received</div>
      <div
        class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
      >
        {{ jobInvoiceTotals?.paymentReceived | currency }}
      </div>
    </div>
  </div>

  <!-- desktop list -->
  <div class="hidden lg:flex flex-auto overflow-hidden">
    <div
      class="flex flex-col flex-auto overflow-hidden sm:overflow-y-auto px-4"
    >
      <ng-container>
        <table
          *ngIf="
            (invoicesFacade.isLoaded$ | async) && invoices?.length;
            else noData
          "
          mat-table
          [dataSource]="dataSource"
          matSort
          class="w-full"
        >
          <ng-container matColumnDef="invoiceCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Number</th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoiceCode }}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Description
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoiceDate | date: "dd MMM yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="paymentDueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.paymentDueDate | date: "dd MMM yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="invoicedAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Amount Due
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoicedAmount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="paidAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
            <td mat-cell *matCellDef="let element">
              {{ element.paidAmount | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element">
              <app-invoice-status-badge
                [status]="element.status"
              ></app-invoice-status-badge>
            </td>
          </ng-container>

          <ng-container matColumnDef="integrationStatus" *ngIf="syncEnabled">
            <th mat-header-cell *matHeaderCellDef>Sync</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon
                class="text-green-600"
                *ngIf="element.integrationStatus === 10"
                >{{ element.integrationStatus === 10 ? "check_circle" : "" }}
              </mat-icon>
              <mat-icon
                class="text-red-600"
                *ngIf="element.integrationStatus === 20"
                >{{ element.integrationStatus === 20 ? "error" : "" }}
              </mat-icon>
              <mat-icon
                class="text-blue-600"
                *ngIf="element.integrationStatus === 30"
                >{{ element.integrationStatus === 30 ? "change_circle" : "" }}
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="sent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon class="text-green-600">{{
                element.sent ? "check_circle" : ""
              }}</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
              <button
                class="manage-button text-xs rounded-xl min-h-8 h-8 px-4"
                mat-stroked-button
                [color]="'primary'"
                (click)="details(element)"
              >
                <span class="ml-2 mr-1">Manage</span>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
            (click)="details(row)"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </ng-container>
    </div>
  </div>

  <!-- tablet and mobile list  -->
  <div
    class="lg:hidden flex flex-col flex-auto xs:mb-18 overflow-auto xs:overflow-y-auto px-4"
  >
    <ng-container
      *ngIf="
        (invoicesFacade.isLoaded$ | async) && invoices?.length;
        else noData
      "
    >
      <fuse-card
        *ngFor="let invoice of invoices"
        class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2"
      >
        <div class="flex flex-col xs:w-full sm:w-1/3">
          <div class="text-md font-semibold leading-tight">
            Number: {{ invoice?.invoiceCode }}
          </div>
          <div class="text-secondary font-medium text-md">
            Status:
            <app-invoice-status-badge
              [status]="invoice.status"
            ></app-invoice-status-badge>
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tight">
            Date: {{ invoice?.invoiceDate | date: "dd MMM yyyy" }}
          </div>
          <div class="text-secondary font-medium text-md">
            Due date: {{ invoice?.paymentDueDate | date: "dd MMM yyyy" }}
          </div>
          <div
            *ngIf="invoice?.sent"
            class="flex items-center text-secondary font-medium text-md"
          >
            <span class="mr-1">Sent:</span>
            <mat-icon class="text-green-600">{{
              invoice?.sent ? "check_circle" : ""
            }}</mat-icon>
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tigh">
            Due: {{ invoice?.invoicedAmount | currency }}
          </div>
          <div class="text-secondary font-medium text-md break-all">
            Paid: {{ invoice?.paidAmount | currency }}
          </div>
        </div>

        <div
          class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto"
        >
          <button
            class="type-action manage-button"
            mat-stroked-button
            [color]="'primary'"
            (click)="details(invoice)"
          >
            <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
          </button>
        </div>
      </fuse-card>
    </ng-container>
  </div>
  <mat-paginator
    class="xs:absolute xs:inset-x-0 xs:bottom-0 border-b xs:border-t xs:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    [ngClass]="{
      'pointer-events-none': (invoicesFacade.isLoaded$ | async) === false
    }"
    showFirstLastButtons="true"
    [length]="invoicesFacade.totalCount$ | async"
    [pageIndex]="query.pageNumber - 1"
    [pageSize]="query.pageSize"
    (page)="paginatorChange($event)"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</div>

<ng-template #noData>
  <div
    *ngIf="
      (invoicesFacade.isLoaded$ | async) && (!invoices || !invoices.length)
    "
    class="w-full top-1/2 xs:text-lg md:text-4xl font-semibold text-center"
  >

    <span class="text-lg" *ngIf="statusControl.touched || statusControl.dirty"> No result for your search.</span>
    <span class="text-lg" *ngIf="!(statusControl.touched || statusControl.dirty)">
       No invoices yet. Select '+ Add Invoice' to create an invoice
    </span>
  </div>
</ng-template>
