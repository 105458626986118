import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { BusinessEntity } from '../models';

@Injectable({
  providedIn: 'root',
})
export class BusinessEntityService {
  constructor(private http: HttpClient) {}

  getByABN(abn: string): Observable<BusinessEntity> {
    const url: string = `businessentity/${abn}`;
    return this.http.get<BusinessEntity>(url);
  }
}
