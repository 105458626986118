import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { cloneDeep } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfigQuill,
  QuillEditorModalComponent,
} from '../quill-editor-modal/quill-editor-modal.component';
import { BaseIdName } from 'app/shared/models/eazimate.models';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {
  NgIf,
  NgTemplateOutlet,
  NgFor,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgClass,
  DecimalPipe,
} from '@angular/common';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-inline-editing-grid',
  templateUrl: './inline-editing-grid.component.html',
  styleUrls: ['./inline-editing-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    NgFor,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    MatFormFieldModule,
    NgClass,
    NgxTrimDirectiveModule,
    MatInputModule,
    InputMaskModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    DecimalPipe,
  ],
})
export class InlineEditingGridComponent implements OnInit, OnDestroy {
  @Input() content;
  @Input() newComesFirst = false;
  @Input() canAddNewRow = true;
  @Input() canEdit = true;
  @Input() addNewRowButtonsText = 'Add New';
  @Input() applyChangesImmediately = false;
  @Input() applyAsyncActions = false;
  @Input() fullHeight = true;
  @Input() form: FormGroup;
  @Input() displayedColumns: string[];
  @Input() dataSource: any[];
  @Input() mappedColumns: {
    sourceName: string;
    columnName: string;
    type: string;
    options?: any[];
    values: BaseIdName[];
  }[];
  @Input() itemForm: () => FormGroup;
  @Input() customButtons: {
    icon: string;
    name: string;
    onClick: Function;
    canEdit?: boolean;
  }[];
  @Output() changed: EventEmitter<boolean> = new EventEmitter();
  @Output() editing: EventEmitter<boolean | {amount: number}> = new EventEmitter();
  @Output() update: EventEmitter<boolean> = new EventEmitter();
  @Output() actionFired: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;

  private unsubscribe$ = new Subject<void>();

  editRowIndex = null;
  hasNew = false;
  hasChanges = false;
  dataSourceSnapshot;

  savedPreviousItemValue = null;

  currencyInputMask = createMask({
    parser: (v) => v.split(',').join(''),
    inputmode: 'numeric',
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
  });

  get listForm(): FormArray {
    return this.form?.get('listForm') as FormArray;
  }

  get isEditing(): boolean {
    return this.editRowIndex !== null || this.hasNew;
  }

  getDropdownOptions(sourceName: string, i: number) {
    if (sourceName === 'snippetArea') {
      const allOptions = this.mappedColumns.find(
        (mc) => mc.sourceName === 'snippetArea',
      ).options;
      return allOptions.find(
        (t) => t.value === this.listForm.at(i).get('snippetType').value,
      ).areas;
    }
    return this.mappedColumns.find((mc) => mc.sourceName === sourceName)
      .options;
  }

  getDropdownNameById(id: string, sourceName: string) {
    return this.mappedColumns
      .find((mc) => mc.sourceName === sourceName)
      .values.find((v) => v.id === id).name;
  }

  constructor(
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private confirm: FuseConfirmationService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dataSourceSnapshot = cloneDeep(this.dataSource);
    this.formUpdate();
  }

  formUpdate(): void {
    this.listForm.clear();
    this.dataSourceSnapshot.forEach((item) => {
      const form = this.itemForm();
      form.patchValue(item);
      this.listForm.push(form);
    });
    setTimeout(() => {
      this.table?.renderRows();
      this.cdRef.detectChanges();
    });
  }

  addItem(newItem?: any): void {
    if (this.hasNew) {
      return;
    }
    if (this.applyChangesImmediately && this.editRowIndex !== null) {
      return;
    }
    let newObj = {};
    this.mappedColumns.forEach((c) => (newObj[c.sourceName] = ''));
    if (newItem) {
      newObj = { ...newObj, ...newItem };
    }
    if (this.newComesFirst) {
      this.dataSourceSnapshot.unshift(newObj);
    } else {
      this.dataSourceSnapshot.push(newObj);
    }

    this.formUpdate();
    this.table?.renderRows();
    this.cdRef.detectChanges();

    this.hasNew = true;

    this.editItem(this.newComesFirst ? 0 : this.dataSourceSnapshot.length - 1, true);
    this.changed.emit(true);
  }

  saveChanges(): void {
    if (this.editRowIndex) {
      return;
    }
    this.dataSource = cloneDeep(this.dataSourceSnapshot);
    this.hasChanges = false;
    setTimeout(() => {
      this.update.emit(true);
    }, 100);
  }

  discardChanges(): void {
    if (this.editRowIndex) {
      return;
    }

    this.ngOnInit();

    this.hasChanges = false;
  }

  editItem(index, isAdd?): void {
    if (!this.displayedColumns.some((a) => a === 'action')) {
      return;
    }
    if (
      (this.editRowIndex && this.listForm.at(this.editRowIndex).invalid) ||
      (this.editRowIndex && this.applyChangesImmediately)
    ) {
      return;
    }
    if(this.editRowIndex === index){
      return;
    }
    this.cdRef.detectChanges();
    this.savedPreviousItemValue = this.listForm.at(index).value;
    this.editRowIndex = index;
    if(isAdd){
      this.editing.emit(false);
    }
    else{
      this.editing.emit({ amount: this.listForm.at(index).getRawValue().amount });
    }
    return;
    if (this.mappedColumns.find((mc) => mc.sourceName === 'snippetType')) {
      const typeOptions = this.mappedColumns.find(
        (mc) => mc.sourceName === 'snippetType',
      ).options;
      let areaOptions = this.mappedColumns.find(
        (mc) => mc.sourceName === 'snippetArea',
      );
      areaOptions.options = typeOptions.find(
        (o) =>
          o.value ===
          this.listForm.at(this.editRowIndex).get('snippetType').value,
      ).areas;
      this.listForm
        .at(this.editRowIndex)
        .get('snippetType')
        .valueChanges.subscribe((type) => {
          areaOptions.options = typeOptions.find(
            (o) =>
              o.value ===
              this.listForm.at(this.editRowIndex).get('snippetType').value,
          ).areas;
        });
    }
  }

  checkIsValide(): void {
    let group = this.listForm.at(this.editRowIndex);
    group.updateValueAndValidity();
    if (group.invalid && (group.get('paymentDate')?.invalid || group.get('amount')?.invalid)) {
      return;
    }
    if (group.invalid) {
      let message;
      if(group.errors?.maxValue){
        message = group.errors.maxValue.message;
      }
      this.confirm.open({
        title: 'Warning',
        message: message,
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          confirm: { label: 'Save', color: 'warn' },
          cancel: { label: 'Cancel' },
        },
      }).afterClosed()
      .pipe(
        filter((result) => result === 'confirmed'),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.saveItemChanges();
      })
      this.validateForm(this.listForm.at(this.editRowIndex) as FormGroup);
      return;
    }
    this.saveItemChanges();
  }

  saveItemChanges(): void {
    if (
      this.savedPreviousItemValue ===
        this.listForm.at(this.editRowIndex).value &&
      !this.hasNew
    ) {
      this.editRowIndex = null;
      this.editing.emit(this.editRowIndex !== null);
      return;
    }
    this.dataSourceSnapshot[this.editRowIndex] = this.listForm
      .at(this.editRowIndex)
      .getRawValue();
    this.actionFired.emit({
      action: this.hasNew ? 'add' : 'update',
      value: cloneDeep(this.dataSourceSnapshot[this.editRowIndex]),
    });
    this.hasNew = false;
    this.editRowIndex = null;
    this.editing.emit(this.editRowIndex !== null);
    if (this.applyChangesImmediately) {
      this.saveChanges();
    } else {
      this.hasChanges = true;
      this.changed.emit(true);
    }
  }

  discardItemChanges(index): void {
    // delete row if it was a new row or values are not changed
    if (
      Object.values(this.savedPreviousItemValue).every((v) => !v) ||
      this.hasNew
    ) {
      this.deleteItem(index);
      this.editRowIndex = null;
      this.editing.emit(this.editRowIndex !== null);
      this.hasNew = false;
      if (this.applyChangesImmediately) {
        this.hasChanges = false;
        this.changed.emit(false);
      }
      return;
    }

    this.listForm.at(index).patchValue(this.savedPreviousItemValue);
    if (this.listForm.at(this.editRowIndex).invalid) {
      this.validateForm(this.listForm.at(this.editRowIndex) as FormGroup);
      this.editRowIndex = null;
      this.editing.emit(this.editRowIndex !== null);
      this.hasNew = null;
      return;
    }

    if (
      this.applyAsyncActions &&
      this.hasNew &&
      this.listForm.at(this.editRowIndex).valid
    ) {
      this.actionFired.emit({
        action: 'add',
        value: cloneDeep(this.dataSourceSnapshot[index]),
      });
    }

    this.editRowIndex = null;
    this.editing.emit(this.editRowIndex !== null);
    this.hasNew = null;
  }

  deleteItem(index): void {
    if (this.applyAsyncActions && !this.hasNew) {
      this.actionFired.emit({
        action: 'delete',
        value: cloneDeep(this.dataSourceSnapshot[index]),
      });
      return;
    }
    this.listForm.removeAt(index);
    this.dataSourceSnapshot = this.listForm.getRawValue();
    this.hasChanges = true;
    this.table?.renderRows();
    this.cdRef.detectChanges();

    if (this.applyChangesImmediately) {
      this.saveChanges();
    } else {
      this.changed.emit(true);
    }
  }

  validateForm(form: FormGroup): void {
    Object.keys(form?.controls).forEach((controlName) => {
      form.get(controlName).markAsTouched();
    });
    form.updateValueAndValidity();
  }

  onFocus(e, control) {
    if (this.editRowIndex === null) {
      return;
    }

    const data: ConfigQuill = {
      content: this.listForm.at(this.editRowIndex).value[control],
      noRequired: true,
      minlength: 0,
    };

    this.dialog
      .open(QuillEditorModalComponent, {
        width: '800px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        console.log(result);
        if (
          result !== this.listForm.at(this.editRowIndex).value[control] &&
          result !== undefined
        ) {
          this.listForm.at(this.editRowIndex).get(control).patchValue(result);
          this.hasChanges = true;
          this.changed.emit(true);
        }
      });
  }

  stripTags(v: string): string {
    return v.replaceAll(/<[^>]*>/g, "")
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
