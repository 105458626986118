import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abn',
  standalone: true,
})
export class ABNPipe implements PipeTransform {
  transform(value: string): string {
    if (value != null) {
      return this.format(value.replace(/\D/g, '').substr(0, 11));
    }
  }

  private format(value: string): string {
    return `${value.substr(0, 2)} ${value.substr(2, 3)} ${value.substr(
      5,
      3,
    )} ${value.substr(8, 3)}`.trim();
  }
}
