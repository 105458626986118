/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BulkSoftDeleteResponse, JobFile } from 'app/shared/models';
import { ToastService } from 'app/shared/services/toast.service';
import { of } from 'rxjs';

import moment from 'moment';

import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import { FileSaverService } from 'ngx-filesaver';
import { JobFilesFileEditComponent } from '../../components/job-files-file-edit/job-files-file-edit.component';
import { JobFilesFolderCreateComponent } from '../../components/job-files-folder-create/job-files-folder-create.component';
import { JobFilesFolderEditComponent } from '../../components/job-files-folder-edit/job-files-folder-edit.component';
import { PhotoService } from '../../services/files.service';
import {
    createJobFile,
    createJobFileFailure,
    createJobFilesFolder,
    createJobFilesFolderFailure,
    createJobFilesFolderSuccess,
    createJobFileSuccess,
    deleteFiles,
    deleteFilesFailure,
    deleteFilesSuccess,
    deleteFolders,
    deleteFoldersFailure,
    deleteFoldersSuccess,
    downloadMultipleJobFiles,
    downloadMultipleJobFilesFailure,
    downloadMultipleJobFilesSuccess,
    getJobFiles,
    getJobFilesFailure,
    getJobFilesSuccess,
    renameJobFile,
    renameJobFileFailure,
    renameJobFilesFolder,
    renameJobFilesFolderFailure,
    renameJobFilesFolderSuccess,
    renameJobFileSuccess,
    showCreateJobFilesFolderDialog,
    showRenameJobFileDialog,
    showRenameJobFilesFolderDialog,
} from '../actions/job-files.actions';
import { selectFiles } from '../selectors/job-files.selectors';

@Injectable({
    providedIn: 'root',
})
export class JobFilesEffects {
    getJobFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getJobFiles),
            distinctUntilChanged((a, b) => a.jobId === b.jobId),
            switchMap(({ jobId }) =>
                this.photoService.getJobFiles(jobId).pipe(
                    map((files: JobFile[]) => getJobFilesSuccess({ files })),
                    catchError((error) => {
                        return of(getJobFilesFailure({ error }));
                    })
                )
            )
        )
    );

    renameJobFileSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(renameJobFileSuccess),
                tap(() => this.toast.success('File renamed'))
            ),
        { dispatch: false }
    );

    renameJobFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(renameJobFile),
            switchMap(({ file }) =>
                this.photoService.renameJobFile(file).pipe(
                    map((file: JobFile) => renameJobFileSuccess({ file })),
                    catchError((error) => {
                        return of(renameJobFileFailure({ error }));
                    })
                )
            )
        )
    );

    renameJobFilesFolderSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(renameJobFilesFolderSuccess),
                tap(() => this.toast.success('Folder renamed'))
            ),
        { dispatch: false }
    );

    renameJobFilesFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(renameJobFilesFolder),
            switchMap(({ jobId, oldPath, newPath }) =>
                this.photoService
                    .renameJobFilesFolder(jobId, oldPath, newPath)
                    .pipe(
                        map((files: JobFile[]) =>
                            renameJobFilesFolderSuccess({ files })
                        ),
                        catchError((error) => {
                            return of(renameJobFilesFolderFailure({ error }));
                        })
                    )
            )
        )
    );

    createJobFilesFolderSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createJobFilesFolderSuccess),
                tap(() => this.toast.success('Folder created'))
            ),
        { dispatch: false }
    );

    createJobFilesFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createJobFilesFolder),
            map(({ jobId, path }) => <JobFile>{ jobId, path }),
            switchMap((file) =>
                this.photoService.createJobFile(file).pipe(
                    map((file: JobFile) =>
                        createJobFilesFolderSuccess({ file })
                    ),
                    catchError((error) => {
                        return of(createJobFilesFolderFailure({ error }));
                    })
                )
            )
        )
    );

    showCreateJobFilesFolderDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showCreateJobFilesFolderDialog),
            switchMap((data) =>
                this.dialog
                    .open(JobFilesFolderCreateComponent, {
                        width: '325px',
                        data,
                        panelClass: 'app-job-files-folder-create',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ jobId, path }) => createJobFilesFolder({ jobId, path }))
        )
    );

    showRenameJobFileDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showRenameJobFileDialog),
            switchMap(({ file }) =>
                this.dialog
                    .open(JobFilesFileEditComponent, {
                        width: '325px',
                        data: file,
                        panelClass: 'app-job-files-file-edit',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map((file: JobFile) => renameJobFile({ file }))
        )
    );

    showRenameJobFilesFolderDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showRenameJobFilesFolderDialog),
            switchMap((data) =>
                this.dialog
                    .open(JobFilesFolderEditComponent, {
                        width: '325px',
                        data,
                        panelClass: 'app-job-files-folder-edit',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ jobId, oldPath, newPath }) =>
                renameJobFilesFolder({ jobId, oldPath, newPath })
            )
        )
    );

    createJobFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createJobFile),
            switchMap(({ file }) =>
                this.photoService.createJobFile(file).pipe(
                    map((file: JobFile) => createJobFileSuccess({ file })),
                    catchError((error) => {
                        return of(createJobFileFailure({ error }));
                    })
                )
            )
        )
    );

    deleteFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteFolders),
            concatLatestFrom(() => this.store.select(selectFiles)),
            map(([{ jobId, paths }, files]) => {
                return {
                    ids: files
                        .filter((f) => paths.find((p) => f.path.startsWith(p)))
                        .map((f) => f.id),
                    jobId,
                };
            }),
            switchMap(({ ids, jobId }) =>
                this.photoService.deleteJobFiles(ids, jobId).pipe(
                    map((response: BulkSoftDeleteResponse) => {
                        const deletedIds = response.items
                            .filter(
                                (i) =>
                                    i.response === 'Deleted' ||
                                    i.response === 'Item not found'
                            )
                            .map((i) => i.id);
                        const errors = response.items.filter(
                            (i) => i.response === 'Unknown error'
                        ).length;

                        if (deletedIds.length > 0) {
                            this.toast.success(
                                `${deletedIds.length} Folder${
                                    deletedIds.length > 1 ? 's' : ''
                                } deleted`
                            );
                        }
                        if (errors > 0) {
                            this.toast.error(
                                `There were errors deleting ${errors} Folder${
                                    errors > 0 ? 's' : ''
                                }`
                            );
                        }
                        return deleteFoldersSuccess({ ids: deletedIds });
                    }),
                    catchError((error) => {
                        return of(deleteFoldersFailure({ error }));
                    })
                )
            )
        )
    );

    deleteFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteFiles),
            switchMap(({ jobId, ids }) =>
                this.photoService.deleteJobFiles(ids, jobId).pipe(
                    map((response: BulkSoftDeleteResponse) => {
                        console.log(response);
                        const deletedIds = response.items
                            .filter(
                                (i) =>
                                    i.response === 'Deleted' ||
                                    i.response === 'Item not found'
                            )
                            .map((i) => i.id);
                        const errors = response.items.filter(
                            (i) => i.response === 'Unknown error'
                        ).length;

                        if (deletedIds.length > 0) {
                            this.toast.success(
                                `${deletedIds.length} File${
                                    deletedIds.length > 1 ? 's' : ''
                                } deleted`
                            );
                        }
                        if (errors > 0) {
                            this.toast.error(
                                `There were errors deleting ${errors} File${
                                    errors > 0 ? 's' : ''
                                }`
                            );
                        }

                        return deleteFilesSuccess({ ids: deletedIds });
                    }),
                    catchError((error) => {
                        return of(deleteFilesFailure({ error }));
                    })
                )
            )
        )
    );

    bulkDownloadJobFiles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadMultipleJobFiles),
            switchMap(({ jobId, files }) =>
                this.photoService.downloadMultipleJobFiles(jobId, files).pipe(
                    map((zipArchive: any) => {
                        const fileName = `files-${moment().format(
                            'D-MMM-YYYY-HHmmss'
                        )}.zip`;
                        return downloadMultipleJobFilesSuccess({
                            blob: zipArchive,
                            filename: fileName,
                        });
                    }),
                    catchError((error) => {
                        return of(downloadMultipleJobFilesFailure({ error }));
                    })
                )
            )
        )
    );

    bulkDownloadJobFilesSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(downloadMultipleJobFilesSuccess),
                tap(({ blob, filename }) => {
                    this.fileSaverService.save(blob, filename);
                    this.toast.success('Downloaded ' + filename);
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private toast: ToastService,
        private dialog: MatDialog,
        private photoService: PhotoService,
        private store: Store,
        private fileSaverService: FileSaverService
    ) {}
}
