import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckbox,
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { JobOrderSummaryFacade, SuppliersListByJobFacade } from '../../store';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { JobOrderSummary } from '../../../../shared/models';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, AsyncPipe } from '@angular/common';
import { FuseScrollbarDirective } from '../../../../../@fuse/directives/scrollbar/scrollbar.directive';

interface JobOrderSummaryLocal extends JobOrderSummary {
  checked: boolean;
}

@Component({
  selector: 'app-create-order-modal',
  templateUrl: './create-order-modal.component.html',
  styleUrls: ['./create-order-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FuseScrollbarDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class CreateOrderModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatSort) sort: MatSort;
  private unsubscribeAll: Subject<any> = new Subject<any>();

  form: FormGroup = this.fb.group({
    emailToSuppliers: [false],
  });

  get emailToSuppliers() {
    return this.form.get('emailToSuppliers') as FormControl;
  }

  displayedColumns: string[] = [
    'selected',
    'supplier',
    'priceListCategoryName',
  ];
  dataSource;
  public allSelected = false;

  constructor(
    public dialogRef: MatDialogRef<CreateOrderModalComponent>,
    public fb: FormBuilder,
    public jobSummaryFacade: JobOrderSummaryFacade,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.jobSummaryFacade.jobOrderSummary$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((summary: JobOrderSummaryLocal[]) => {
        console.log(summary);
        summary = summary.map((s) => ({ ...s, checked: false }));
        this.dataSource = new MatTableDataSource(summary);
      });
  }

  selectAll(e: MatCheckboxChange) {
    this.allSelected = e.checked;
    this.dataSource.data.forEach((i) => (i.checked = this.allSelected));
  }
  onSelectItemChange(item: JobOrderSummaryLocal, e: MatCheckboxChange) {
    item.checked = e.checked;
    this.allSelected = this.dataSource.data.every((i) => i.checked);
  }

  close() {
    this.dialogRef.close();
  }

  createNew() {
    const result = {
      all: this.allSelected,
      emailToSuppliers: this.form.value.emailToSuppliers,
      suppliers: this.dataSource.data.filter((s) => s.checked),
    };
    this.dialogRef.close(result);
  }

  hasSelected(): boolean {
    return this.dataSource.data.some((s) => s.checked);
  }
}
