/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { BulkSoftDeleteResponse, Photo } from 'app/shared/models';
import { ToastService } from 'app/shared/services/toast.service';
import { FileSaverService } from 'ngx-filesaver';
import { combineLatest, of } from 'rxjs';

import moment from 'moment';

import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    mapTo,
    switchMap,
    tap,
} from 'rxjs/operators';
import {
    createJobPhotosFolder,
    createJobPhotosFolderFailure,
    createJobPhotosFolderSuccess,
    deleteJobPhotos,
    deleteJobPhotosFailure,
    deleteJobPhotosFolders,
    deleteJobPhotosFoldersFailure,
    deleteJobPhotosFoldersSuccess,
    deleteJobPhotosSuccess,
    downloadMultiplePhotos,
    downloadMultiplePhotosFailure,
    downloadMultiplePhotosSuccess,
    getJobPhotos,
    getJobPhotosFailure,
    getJobPhotosSuccess,
    renameJobPhotosFolder,
    renameJobPhotosFolderFailure,
    renameJobPhotosFolderSuccess,
    setDetailPhotoIndex,
    showCreateJobPhotosFolderDialog,
    showRenameJobPhotosFolderDialog,
} from '..';

import { selectPhotos } from '../selectors/photos.selectors';

import {
    PhotosFolderCreateComponent,
    PhotosFolderEditComponent,
} from '../../components';
import { PhotoService } from '../../services/photo.service';
import {
    selectPhotosCurrentPhotos,
    selectPhotosDetailPhotoIndex,
} from '../selectors/photos.selectors';

@Injectable({
    providedIn: 'root',
})
export class PhotosEffects {
    bulkDownloadPhotos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(downloadMultiplePhotos),
            switchMap(({ jobId, photos }) =>
                this.jobService.downloadMultiplePhotos(jobId, photos).pipe(
                    map((zipArchive: any) => {
                        const fileName = `photos-${moment().format(
                            'D-MMM-YYYY-HHmmss'
                        )}.zip`;
                        return downloadMultiplePhotosSuccess({
                            blob: zipArchive,
                            filename: fileName,
                        });
                    }),
                    catchError((error) => {
                        return of(downloadMultiplePhotosFailure({ error }));
                    })
                )
            )
        )
    );

    bulkDownloadPhotosSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(downloadMultiplePhotosSuccess),
                tap(({ blob, filename }) => {
                    this.fileSaverService.save(blob, filename);
                    this.toast.success('Downloaded ' + filename);
                })
            ),
        { dispatch: false }
    );

    showRenameJobPhotosFolderDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showRenameJobPhotosFolderDialog),
            switchMap((data) =>
                this.dialog
                    .open(PhotosFolderEditComponent, {
                        width: '325px',
                        data,
                        panelClass: 'app-job-photos-folder-edit',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ jobId, oldPath, newPath }) =>
                renameJobPhotosFolder({ jobId, oldPath, newPath })
            )
        )
    );

    renameJobPhotosFolderSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(renameJobPhotosFolderSuccess),
                tap(() => this.toast.success('Folder renamed'))
            ),
        { dispatch: false }
    );

    renameJobPhotosFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(renameJobPhotosFolder),
            switchMap(({ jobId, oldPath, newPath }) =>
                this.jobService
                    .renameJobPhotosFolder(jobId, oldPath, newPath)
                    .pipe(
                        map((photos: Photo[]) =>
                            renameJobPhotosFolderSuccess({ photos })
                        ),
                        catchError((error) => {
                            return of(renameJobPhotosFolderFailure({ error }));
                        })
                    )
            )
        )
    );

    createJobPhotosFolderSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createJobPhotosFolderSuccess),
                tap(() => this.toast.success('Folder created'))
            ),
        { dispatch: false }
    );

    createJobPhotosFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createJobPhotosFolder),
            map(({ jobId, path }) => {
                if (jobId) {
                    return <Photo>{ jobId, path };
                } else {
                    return <Photo>{ path };
                }
            }),
            switchMap((photo) =>
                this.jobService.createJobPhoto(photo).pipe(
                    map((photo: Photo) =>
                        createJobPhotosFolderSuccess({ photo })
                    ),
                    catchError((error) => {
                        return of(createJobPhotosFolderFailure({ error }));
                    })
                )
            )
        )
    );

    showCreateJobPhotosFolderDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(showCreateJobPhotosFolderDialog),
            switchMap((data) =>
                this.dialog
                    .open(PhotosFolderCreateComponent, {
                        width: '325px',
                        data,
                        panelClass: 'app-job-photos-folder-create',
                    })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map(({ jobId, path }) => createJobPhotosFolder({ jobId, path }))
        )
    );

    deleteFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobPhotosFolders),
            concatLatestFrom(() => this.store.select(selectPhotos)),
            map(([{ jobId, paths }, files]) => {
                return {
                    jobId,
                    ids: files
                        .filter((f) => paths.find((p) => f.path?.startsWith(p)))
                        .map((f) => f.id),
                };
            }),
            switchMap(({ jobId, ids }) => {
                return this.jobService.deleteJobPhotos(jobId, ids).pipe(
                    map((response: BulkSoftDeleteResponse) => {
                        const deletedIds = response.items
                            .filter(
                                (i) =>
                                    i.response === 'Deleted' ||
                                    i.response === 'Item not found'
                            )
                            .map((i) => i.id);
                        const errors = response.items.filter(
                            (i) => i.response === 'Unknown error'
                        ).length;

                        if (deletedIds.length > 0) {
                            this.toast.success(
                                `${deletedIds.length} Folder${
                                    deletedIds.length > 1 ? 's' : ''
                                } deleted`
                            );
                        }
                        if (errors > 0) {
                            this.toast.error(
                                `There were errors deleting ${errors} Folder${
                                    errors > 0 ? 's' : ''
                                }`
                            );
                        }
                        return deleteJobPhotosFoldersSuccess({
                            ids: deletedIds,
                        });
                    }),
                    catchError((error) => {
                        return of(deleteJobPhotosFoldersFailure({ error }));
                    })
                );
            })
        )
    );

    deleteJobPhoto$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobPhotos),
            switchMap(({ jobId, ids }) => {
                const message = `Are you sure you want to delete ${
                    ids.length > 1 ? 'these' : 'this'
                } Photo${ids.length > 1 ? 's?' : '?'}`;
                const title = `Delete Photo${ids.length > 1 ? 's' : ''}`;
                return this.confirm
                    .open({
                        title,
                        message,
                        icon: {
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        actions: {
                            cancel: { label: 'No' },
                            confirm: { label: 'Yes', color: 'warn' },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => result === 'confirmed'),
                        mapTo({ jobId, ids })
                    );
            }),
            switchMap(({ jobId, ids }) =>
                this.jobService.deleteJobPhotos(jobId, ids).pipe(
                    map((response: BulkSoftDeleteResponse) => {
                        const deletedIds = response.items
                            .filter(
                                (i) =>
                                    i.response === 'Deleted' ||
                                    i.response === 'Item not found'
                            )
                            .map((i) => i.id);
                        const errors = response.items.filter(
                            (i) => i.response === 'Unknown error'
                        ).length;

                        if (deletedIds.length > 0) {
                            this.toast.success(
                                `${deletedIds.length} Photo${
                                    deletedIds.length > 1 ? 's' : ''
                                } deleted`
                            );
                        }
                        if (errors > 0) {
                            this.toast.error(
                                `There were errors deleting ${errors} Photo${
                                    errors > 0 ? 's' : ''
                                }`
                            );
                        }
                        return deleteJobPhotosSuccess({ ids: deletedIds });
                    }),
                    catchError((error) => {
                        return of(deleteJobPhotosFailure({ error }));
                    })
                )
            )
        )
    );

    deleteJobPhotosSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobPhotosSuccess),
            concatLatestFrom(() =>
                combineLatest([
                    this.store.select(selectPhotosCurrentPhotos),
                    this.store.select(selectPhotosDetailPhotoIndex),
                ])
            ),
            filter(
                ([_, [photos, index]]) =>
                    photos?.length > 0 && index > photos.length - 1
            ),
            map(([_, [photos]]) =>
                setDetailPhotoIndex({ index: photos.length - 1 })
            )
        )
    );

    getJobPhotos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getJobPhotos),
            distinctUntilChanged((a, b) => a.jobId === b.jobId),
            switchMap(({ jobId }) => {
                return this.jobService.getJobPhotos(jobId).pipe(
                    map((photos: Photo[]) => getJobPhotosSuccess({ photos })),
                    catchError((error) => {
                        return of(getJobPhotosFailure({ error }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private toast: ToastService,
        private dialog: MatDialog,
        private confirm: FuseConfirmationService,
        private jobService: PhotoService,
        private store: Store,
        private fileSaverService: FileSaverService
    ) {}
}
