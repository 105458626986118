// export interface Message {
//   id: string;
//   icon?: string;
//   image?: string;
//   title?: string;
//   description?: string;
//   time: string;
//   link?: string;
//   useRouter?: boolean;
//   read: boolean;
// }

export interface Message {
  Id: string;
  MessageId: string;
  AccountId: string;
  AccountUserId: string;
  DateRead: string;
  ReadByAccountUserId: string;
  Message: string;
  Email: string;
  EventType: 0;
  TTL: number;
  Etag: string;
  Timestamp: string;
}

export enum MessageEventType {
  Processed,
  Deferred,
  Delivered,
  Open,
  Click,
  Bounce,
  Dropped,
  SpamReport,
  Unsubscribe,
  GroupUnsubscribe,
  GroupResubscribe,
}
