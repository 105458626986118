/* eslint-disable ngrx/on-function-explicit-return-type */
/* eslint-disable arrow-parens */
import { createReducer, on } from '@ngrx/store';
import { FullPriceList, PriceListItemPriceChangeJob } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  clearState,
  getItemPriceCheck,
  getItemPriceCheckFailure,
  getItemPriceCheckSuccess,
} from '../actions';

export interface ItemPriceCheckState {
  items: PriceListItemPriceChangeJob[];
  selectedPriceListItemIds: string[];
  callState: CallState;
  error: any;
}

export const initialState: ItemPriceCheckState = {
  items: null,
  selectedPriceListItemIds: [],
  callState: LoadingState.INIT,
  error: null,
};

export const currentItemPriceCheckReducer = createReducer(
  initialState,
  on(getItemPriceCheck, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getItemPriceCheckFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getItemPriceCheckSuccess, (state, { items }) => ({
    ...state,
    items,
    callState: LoadingState.LOADED,
  })),
  on(clearState, (state) => ({
    ...initialState,
  })),
);
