import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BillingStatus } from 'app/views/settings/components/billing-settings/billing-settings.types';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { AccountUserService } from '../services/account-user.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class RequiredGuard implements CanActivate, CanActivateChild {
  private industryRoute = '/industry-selector';
  private termsRoute = '/terms-and-conditions';
  private PaymentRequiredRoute = '/payment-required';
  private settingsRoute = '/settings';
  private accountMemberships = '/settings/accounts-memberships';
  private BillingUpdateMessage =
    'Go to Billing -> Manage Billing to update your details';
  private BillingResubscribe = 'See Billing -> Update Subscription';

  checkSubscription$ = (redirect: string) => {
    return this.accountUserService.check().pipe(
      map((data) => {
        const dateNow = moment();
        const billingPeriodEndDate = moment(new Date(data.currentPeriodEnd));

        console.log('dateNow:::', dateNow.toISOString());
        console.log(
          'billingPeriodEndDate:::',
          billingPeriodEndDate.toISOString(),
        );

        //at the moment the team member doesn't have a valid plan so just redirecting them to this
        if (
          data.isTeamMember &&
          !data.validTeamMemberPlan &&
          billingPeriodEndDate < dateNow
        ) {
          this._router.navigate([this.accountMemberships]);
          return true;
        }

        //Do we need to show the Terms and Conditions?
        if (data.showTnC) {
          this._router.navigate([this.termsRoute]);
          return true;
        }

        //Do we need to show the industry selector?
        if (!data.hasPriceList) {
          this._router.navigate([this.industryRoute]);
          return true;
        }

        //if we've made it here that means we need to pay for our subscription
        if (
          (!data.planStatus ||
            data.planStatus === BillingStatus.Init ||
            data.planStatus === BillingStatus.SubscriptionNotActivated) &&
          billingPeriodEndDate < dateNow
        ) {
          this._router.navigate([this.PaymentRequiredRoute]);
          return true;
        }

        if (data.planStatus === BillingStatus.RequiresActionPaymentFailed) {
          const errorMessage = data.paymentErrorMessage
            ? data.paymentErrorMessage + '\n ' + this.BillingUpdateMessage
            : this.BillingUpdateMessage;
          this.confirmationService.open({
            title: 'Payment Failed',
            message: errorMessage,
            actions: {
              confirm: {
                label: 'Ok',
              },
              cancel: {
                show: false,
              },
            },
            dismissible: true,
          });
          this._router.navigate([this.settingsRoute]);
          return true;
        }

        if (
          data.planStatus ===
          BillingStatus.RequiresActionPaymentFailedAllRetriesFailed
        ) {
          this.confirmationService.open({
            title: 'Payment Failed',
            message:
              'Unable to collect payment with the selected payment method.' +
              '\n ' +
              this.BillingUpdateMessage,
            actions: {
              confirm: {
                label: 'Ok',
              },
              cancel: {
                show: false,
              },
            },
            dismissible: true,
          });
          this._router.navigate([this.settingsRoute]);
          return true;
        }

        // Check for the Subscription Expiry
        if (data.currentPeriodEnd) {
          if (billingPeriodEndDate < dateNow) {
            //stop from endless loops
            if (redirect === this.settingsRoute) {
              return true;
            }

            this.confirmationService.open({
              title: 'Subscription Expired',
              message: this.BillingResubscribe,
              actions: {
                confirm: {
                  label: 'Ok',
                },
                cancel: {
                  show: false,
                },
              },
              dismissible: true,
            });
            this._router.navigate([this.settingsRoute]);
            return true;
          }
        }

        return true;
      }),
    );
  };

  checkData = (redirect: string) =>
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap((_) => this.checkSubscription$(redirect)),
    );

  /**
   * Constructor
   */
  //constructor(private _authService: AuthService, private _router: Router) { }
  constructor(
    private _router: Router,
    private accountUserService: AccountUserService,
    private msalBroadcastService: MsalBroadcastService,
    private confirmationService: FuseConfirmationService,
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const redirect = state.url;
    return this.checkData(redirect);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirect = state.url;
    // allow expired subscription accounts to access accounts page
    if (redirect.includes('accounts')) {
      return true;
    }
    return this.checkData(redirect);
  }
}
