import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileUpload, FileUploadType, Photo } from 'app/shared/models';
import { fileUploadRequest } from 'app/shared/store/actions';
import {
  deleteJobPhotos,
  deselectJobPhotosFolderPaths,
  deselectPhotoIds,
  getJobPhotos,
  selectJobPhotosFolderPaths,
  selectPhotoIds,
  setDetailPhotoIndex,
  setJobPhotosCurrentFolderPath,
  setJobPhotosSortField,
  setJobPhotosSortOrder,
  showCreateJobPhotosFolderDialog,
  showRenameJobPhotosFolderDialog,
  toggleJobPhotoFileSelected,
  toggleJobPhotosFolderSelected,
  downloadMultiplePhotos,
  deleteJobPhotosFolders,
} from '..';
import {
  selectAllPhotos,
  selectJobPhotosCurrentFolder,
  selectJobPhotosCurrentFolders,
  selectPhotosCurrentPhotos,
  selectJobPhotosDetailPhoto,
  selectPhotosDetailPhotoIndex,
  selectJobPhotosFolders,
  selectJobPhotosIsLoaded,
  selectJobPhotosSortField,
  selectJobPhotosSortOrder,
  selectSelectedJobFileIds,
  selectSelectedJobFolderPaths,
} from '../selectors/photos.selectors';

import { selectSasToken } from 'app/shared/store/selectors/account-user.selectors';

@Injectable({
  providedIn: 'root',
})
export class PhotosFacade {
  currentFolders$ = this.store.select(selectJobPhotosCurrentFolders);
  currentPhotos$ = this.store.select(selectPhotosCurrentPhotos);
  allPhotos$ = this.store.select(selectAllPhotos);
  currentFolder$ = this.store.select(selectJobPhotosCurrentFolder);
  folders$ = this.store.select(selectJobPhotosFolders);
  isLoaded$ = this.store.select(selectJobPhotosIsLoaded);
  sortField$ = this.store.select(selectJobPhotosSortField);
  sortOrder$ = this.store.select(selectJobPhotosSortOrder);
  detailPhoto$ = this.store.select(selectJobPhotosDetailPhoto);
  detailPhotoIndex$ = this.store.select(selectPhotosDetailPhotoIndex);
  sasToken$ = this.store.select(selectSasToken);
  selectedJobPhotoIds$ = this.store.select(selectSelectedJobFileIds);
  selectedJobFolderPaths$ = this.store.select(selectSelectedJobFolderPaths);

  constructor(private store: Store) {}

  downloadMultiplePhotos(jobId, photos: Photo[]): void {
    this.store.dispatch(downloadMultiplePhotos({ jobId, photos }));
  }

  showRenameFolderDialog(jobId: string, path: string): void {
    this.store.dispatch(showRenameJobPhotosFolderDialog({ jobId, path }));
  }

  createNewFolder(jobId: string, currentPath: string): void {
    this.store.dispatch(
      showCreateJobPhotosFolderDialog({ jobId, path: currentPath }),
    );
  }

  deletePhotos(jobId: string, ids: string[]): void {
    this.store.dispatch(deleteJobPhotos({ jobId, ids }));
  }

  deleteFolders(jobId: string, paths: string[]): void {
    this.store.dispatch(deleteJobPhotosFolders({ jobId, paths }));
  }

  setDetailPhotoIndex(index: number): void {
    this.store.dispatch(setDetailPhotoIndex({ index }));
  }

  uploadPhotos(jobId: string, path: string, files: FileList | File[]): void {
    const filesToUpload = (
      files instanceof FileList ? Array.from(files) : files
    ).map((f) => {
      const file = f as FileUpload;
      file.uploadType = FileUploadType.JobPhoto;
      file.formData = new FormData();
      if (jobId) {
        file.formData.append('jobId', jobId);
        file.url = 'jobphoto/upload';
      } else {
        file.url = 'upload';
      }
      file.formData.append('path', path);

      return file;
    });

    this.store.dispatch(fileUploadRequest({ files: filesToUpload }));
  }

  getPhotos(jobId: string): void {
    this.store.dispatch(getJobPhotos({ jobId }));
  }

  setCurrentFolderPath(path: string): void {
    this.store.dispatch(setJobPhotosCurrentFolderPath({ path }));
  }

  setSortField(sortField: string): void {
    this.store.dispatch(setJobPhotosSortField({ sortField }));
  }

  setSortOrder(sortOrder: 'asc' | 'desc'): void {
    this.store.dispatch(setJobPhotosSortOrder({ sortOrder }));
  }

  toggleFileSelected(id: string): void {
    this.store.dispatch(toggleJobPhotoFileSelected({ id }));
  }

  toggleFolderSelected(path: string): void {
    this.store.dispatch(toggleJobPhotosFolderSelected({ path }));
  }

  selectFolders(paths: string[]): void {
    this.store.dispatch(selectJobPhotosFolderPaths({ paths }));
  }

  deselectFolders(paths: string[]): void {
    this.store.dispatch(deselectJobPhotosFolderPaths({ paths }));
  }

  selectFileIds(ids: string[]): void {
    this.store.dispatch(selectPhotoIds({ ids }));
  }

  deselectFileIds(ids: string[]): void {
    this.store.dispatch(deselectPhotoIds({ ids }));
  }
}
