import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, CurrencyPipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NumbersOnlyDirective } from '@shared/directives/numbers-only.directive';

@Component({
  selector: 'app-add-percentage-amount-invoice-line-dialog',
  templateUrl: './add-percentage-amount-invoice-line-dialog.component.html',
  styleUrls: ['./add-percentage-amount-invoice-line-dialog.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatCheckboxModule,
    CurrencyPipe,
    NumbersOnlyDirective
  ],
})
export class AddPercentageAmountInvoiceLineDialogComponent {
  editForm: FormGroup;
  newAmount: number;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddPercentageAmountInvoiceLineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { amount: number; type: string },
  ) {
    this.editForm = this.fb.group({
      description: [this.data?.type, Validators.required],
      percentage: [0, Validators.required],
      taxInclusive: [true],
    });

    this.editForm.get('percentage').valueChanges.subscribe((v) => {
      if (this.data.type === 'Percentage amount') {
        this.newAmount = (this.data.amount * +v) / 100;
      }
    });
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }
  save(): void {
    let amount: number;
    if (this.data.type === 'Percentage amount') {
      amount = this.newAmount;
    } else if (this.data.type === 'Credit amount') {
      amount = this.editForm.controls.percentage.value * -1;
    } else {
      amount = this.editForm.controls.percentage.value;
    }
    const response = {
      amount: amount,
      description: this.editForm.controls.description.value,
      taxInclusive: this.editForm.controls.taxInclusive.value,
    };
    this.dialogRef.close(response);
  }
}
