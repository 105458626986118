import { createAction, props } from '@ngrx/store';
import {
  PaginatedListOfSnippet,
  Snippet,
  SnippetArea,
  SnippetParameters,
  SnippetType,
} from 'app/shared/models/eazimate.models';

export enum SnippetsActionTypes {
  getSnippetsListAction = '[Snippets List] Get SnippetsList',
  getSnippetsListActionSuccess = '[Snippets List] Get SnippetsList Success',
  getSnippetsListActionFailure = '[Snippets List] Get SnippetsList Failure',

  deleteSnippetAction = '[Snippets] delete Snippet',
  deleteSnippetActionSuccess = '[Snippets] delete Snippet Success',
  deleteSnippetActionFailure = '[Snippets] delete Snippet Failure',

  addSnippetAction = '[Snippets] add Snippet',
  addSnippetActionSuccess = '[Snippets] add Snippet Success',
  addSnippetActionFailure = '[Snippets] add Snippet Failure',

  updateSnippetAction = '[Snippets] update Snippet',
  updateSnippetActionSuccess = '[Snippets] update Snippet Success',
  updateSnippetActionFailure = '[Snippets] update Snippet Failure',

  GetSnippetsListByTypeAreaAction = '[Snippets] get Snippets List by Type Area',
  GetSnippetsListByTypeAreaActionSuccess = '[Snippets] get Snippets List by Type Area Success',
  GetSnippetsListByTypeAreaActionFailure = '[Snippets] get Snippets List by Type Area Failure',

  GetSnippetDefaultByTypeAreaAction = '[Snippets] get Snippets default by Type Area',
  GetSnippetDefaultByTypeAreaActionSuccess = '[Snippets] get Snippets default by Type Area Success',
  GetSnippetDefaultByTypeAreaActionFailure = '[Snippets] get Snippets default by Type Area Failure',
}

export const getSnippetsList = createAction(
  SnippetsActionTypes.getSnippetsListAction,
  props<{ request: SnippetParameters }>(),
);

export const getSnippetsListSuccess = createAction(
  SnippetsActionTypes.getSnippetsListActionSuccess,
  props<{ snippets: PaginatedListOfSnippet }>(),
);

export const getSnippetsListFailure = createAction(
  SnippetsActionTypes.getSnippetsListActionFailure,
  props<{ error: any }>(),
);

export const deleteSnippet = createAction(
  SnippetsActionTypes.deleteSnippetAction,
  props<{ id: string }>(),
);

export const deleteSnippetSuccess = createAction(
  SnippetsActionTypes.deleteSnippetActionSuccess,
  props<{ snippet: Snippet }>(),
);

export const deleteSnippetFailure = createAction(
  SnippetsActionTypes.deleteSnippetActionFailure,
  props<{ error: any }>(),
);

// add snippet
export const addSnippet = createAction(
  SnippetsActionTypes.addSnippetAction,
  props<{ snippet: Snippet }>(),
);

export const addSnippetSuccess = createAction(
  SnippetsActionTypes.addSnippetActionSuccess,
  props<{ snippet: Snippet }>(),
);

export const addSnippetFailure = createAction(
  SnippetsActionTypes.addSnippetActionFailure,
  props<{ error: any }>(),
);

// update snippet
export const updateSnippet = createAction(
  SnippetsActionTypes.updateSnippetAction,
  props<{ snippet: Snippet }>(),
);

export const updateSnippetSuccess = createAction(
  SnippetsActionTypes.updateSnippetActionSuccess,
  props<{ snippet: Snippet }>(),
);

export const updateSnippetFailure = createAction(
  SnippetsActionTypes.updateSnippetActionFailure,
  props<{ error: any }>(),
);

// Get Snippets List By Type and Area
export const getSnippetsListByTypeArea = createAction(
  SnippetsActionTypes.GetSnippetsListByTypeAreaAction,
  props<{ params: SnippetParameters }>(),
);
export const getSnippetsListByTypeAreaSuccess = createAction(
  SnippetsActionTypes.GetSnippetsListByTypeAreaActionSuccess,
  props<{ snippets: Snippet[] }>(),
);
export const getSnippetsListByTypeAreaFailure = createAction(
  SnippetsActionTypes.GetSnippetsListByTypeAreaActionFailure,
  props<{ error: any }>(),
);

// Get default Snippet By Type and Area
export const getSnippetDefaultByTypeArea = createAction(
  SnippetsActionTypes.GetSnippetDefaultByTypeAreaAction,
  props<{ params: SnippetParameters }>(),
);
export const getSnippetDefaultByTypeAreaSuccess = createAction(
  SnippetsActionTypes.GetSnippetDefaultByTypeAreaActionSuccess,
  props<{ snippet: Snippet }>(),
);
export const getSnippetDefaultByTypeAreaFailure = createAction(
  SnippetsActionTypes.GetSnippetDefaultByTypeAreaActionFailure,
  props<{ error: any }>(),
);
