import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SendModalComponent } from 'app/shared/components';
import {
  LoggedInUser,
  SendQuote,
  SnippetArea,
  SnippetType,
} from 'app/shared/models';
import { SendModalData } from 'app/shared/models/form-ui.models';
import { ToastService } from 'app/shared/services/toast.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { EstimateService } from 'app/views/estimates/services/estimate.service';
import { Subject, take } from 'rxjs';
import { JobService } from '../../services/job.service';
import { JobDetailFacade } from '../../store';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-job-quote',
  templateUrl: './job-quote.component.html',
  styleUrls: ['./job-quote.component.scss'],
  standalone: true,
  imports: [NgIf, NgxExtendedPdfViewerModule, MatButtonModule, MatIconModule],
})
export class JobQuoteComponent implements OnInit, OnDestroy {
  pdfData: {
    generatedFile?: any;
    fileName?: string;
  } = {};
  jobId: string;
  jobCostingId: string;
  account: LoggedInUser;

  unsubscriber$ = new Subject<void>();

  constructor(
    private jobService: JobService,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private accountUserFacade: AccountUserFacade,
    private estimateService: EstimateService,
    private asyncPipe: AsyncPipe,
    private jobDetailFacade: JobDetailFacade,
    private toast: ToastService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.jobCostingId = this.route.snapshot.parent.params['id'];

    this.jobDetailFacade.job$
      .pipe(filter(Boolean), takeUntil(this.unsubscriber$))
      .subscribe((job) => {
        this.jobId = job.id;
        this.preview(this.jobId);
      });
    this.accountUserFacade.loggedInUser$
      .pipe(take(1))
      .subscribe((res) => (this.account = res));
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete;
  }

  preview(id: string): void {
    this.jobService.previewJobQuote(id).subscribe(
      (r: any) => {
        this.pdfData.generatedFile = r;
        this.pdfData.fileName = 'quote.pdf';
        this.cdRef.detectChanges();
      },
      () => {},
    );
  }

  sendModal(): void {
    const data: SendModalData = {
      title: 'Send Quote',
      from: this.account.account.email,
      message: `<p>Hi ${
        this.asyncPipe.transform(this.jobDetailFacade.job$).customer.person
          .fullName
      },</p> <br> <p>Please find attached your quote.</p> <br> <p>Any questions please let us know.</p> <br> <p>Thanks</p> <p>${
        this.account.user.person.firstName
      } ${this.account.user.person.lastName}</p> <p>${
        this.account.account.name
      }</p>`,
      subject: `Quote ${this.asyncPipe.transform(this.jobDetailFacade.job$)
        ?.estimateCode} from ${
        this.account.account.name
      } for ${this.asyncPipe.transform(this.jobDetailFacade.job$)?.jobTitle}`,
      to: this.asyncPipe.transform(this.jobDetailFacade.job$)?.customer.person
        .email
        ? [
            this.asyncPipe.transform(this.jobDetailFacade.job$)?.customer.person
              .email,
          ]
        : null,
      quillOptions: {
        snippetsParams: {
          snippetArea: SnippetArea.Email_Body,
          snippetType: SnippetType.Quote,
        },
      },
      jobId: this.jobId,
    };

    const dialogRef = this.dialog.open(SendModalComponent, {
      width: '720px',
      maxHeight: '70vh',
      data: data,
      panelClass: 'app-send-modal',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const request: SendQuote = {
          entityId: this.jobId,
          emailTo: result.to.join(';'),
          message: result.message,
          subject: result.subject,
          sendCopy: !!result.sendMe,
          attachments: result.attachments ? result.attachments : null,
        };
        this.jobService.sendQuoteJob(request).subscribe(
          () => {
            this.toast.success('Sent successfully');
          },
          () => {
            this.toast.error('Sending the quote failed');
          },
        );
      }
    });
  }
}
