<div
  class="flex flex-row flex-wrap justify-around rounded border border-gray-300 dark:border-gray-500"
  [formGroup]="variationTotalsForm"
>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Unit total</div>
    <input
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
      [inputMask]="currencyInputMask"
      formControlName="unitTotal"
      readonly
    />
  </div>
  <!-- is hidden according to #4257 -->
  <!--  <div class="col-span-2">-->
  <!--    <div class="flex flex-col w-40 max-w-full">-->
  <!--      <div>Quotable total</div>-->
  <!--      <input-->
  <!--        class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"-->
  <!--        [inputMask]="currencyInputMask"-->
  <!--        formControlName="quotableUnitTotal"-->
  <!--        readonly-->
  <!--      />-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>On cost %</div>
    <input
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
      #onCost
      [inputMask]="onCostInputMask"
      [readonly]="readonly"
      (blur)="updateValue('onCost')"
      type="text"
      formControlName="onCost"
    />
  </div>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Profit</div>
    <input
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
      #profit
      [inputMask]="currencyInputMask"
      [readonly]="readonly"
      (blur)="updateValue('profitAmount')"
      formControlName="profitAmount"
    />
  </div>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Net quote</div>
    <input
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
      [inputMask]="currencyInputMask"
      formControlName="netQuoteAmount"
      readonly
    />
  </div>
  <div class="flex flex-col w-40 max-w-full p-2">
    <div>Customer quote</div>
    <input
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5"
      #customerQuote
      [inputMask]="currencyInputMask"
      [readonly]="readonly"
      (blur)="updateValue('quoteAmount')"
      formControlName="quoteAmount"
    />
  </div>
</div>
