import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LeavePageService } from '../services/leave-page.service';

@Injectable({
  providedIn: 'root',
})
export class LeavePageGuard implements CanDeactivate<any> {
  constructor(
    private leavePageService: LeavePageService,
    private confirm: FuseConfirmationService,
  ) {}

  canDeactivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.leavePageService.needSave) {
      return true;
    }

    return this.confirm
      .open(this.leavePageService.dialog)
      .afterClosed()
      .pipe(
        map((res) => {
          switch (res) {
            case 'confirmed':
              return true;
              break;
            case 'cancelled':
              return false;
              break;
          }
        }),
        tap((success) => {
          this.leavePageService.dialogResult.emit(!!success);
          if (success) {
            this.leavePageService.needSave = false;
          }
        }),
      );
  }
}
