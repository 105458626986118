import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VariationSection, Variation } from 'app/shared/models';
import {
  getQuoteDocumentSection,
  getVariationLetterQuote,
  selectQuoteDocumentSections,
  selectVariationLetterQuote,
  selectVariationLetterQuoteError,
  selectVariationLetterQuoteIsLoaded,
  selectVariationLetterQuoteIsLoading,
  selectVariationLetterQuoteSections,
  selectVariationLetterQuoteVariation,
  updateQuoteDocumentSection,
  updateVariationLetterQuote,
  uploadVariationLetterLogo,
} from '..';
import { VariationLetterQuoteState } from '../reducers/job-variation-letter.reducer';

@Injectable({
  providedIn: 'root',
})
export class VariationLetterQuoteFacade {
  isLoading$ = this.store.select(selectVariationLetterQuoteIsLoading);
  isLoaded$ = this.store.select(selectVariationLetterQuoteIsLoaded);
  error$ = this.store.select(selectVariationLetterQuoteError);
  variationLetterFull$ = this.store.select(selectVariationLetterQuote);
  sections$ = this.store.select(selectVariationLetterQuoteSections);
  variation$ = this.store.select(selectVariationLetterQuoteVariation);
  quoteDocumentSections$ = this.store.select(selectQuoteDocumentSections);

  constructor(private store: Store<VariationLetterQuoteState>) {}

  getVariationLetterQuote(variationLetterid: string): void {
    this.store.dispatch(getVariationLetterQuote({ variationLetterid }));
  }

  updateVariationLetterQuote(variation: Variation): void {
    this.store.dispatch(updateVariationLetterQuote({ variation }));
  }

  getQuoteDocumentSections(estimateId: string, variationId: string): void {
    this.store.dispatch(getQuoteDocumentSection({ estimateId, variationId }));
  }

  updateQuoteDocumentSections(quoteSections: VariationSection[]): void {
    this.store.dispatch(updateQuoteDocumentSection({ quoteSections }));
  }
}
