import { createAction, props } from '@ngrx/store';
import {
  JobCostingPriceListItem, JobCostingWithMetrics,
  JobHistoryParameters,
  PaginatedListOfJobHistory,
  PaginatedListOfVariation, SingleSaveJobCosting,
  VariationParameters,
} from 'app/shared/models';

export enum JobCostingActionTypes {
  GetJobCosting = '[Job Costing] Get JobCosting',
  GetJobCostingSuccess = '[Job Costing] Get JobCosting Success',
  GetJobCostingFailure = '[Job Costing] Get JobCosting Failure',

  GetJobCostingSingle = '[Job Costing] Get JobCostingSingle',
  GetJobCostingSingleSuccess = '[Job Costing] Get JobCostingSingle Success',
  GetJobCostingSingleFailure = '[Job Costing] Get JobCostingSingle Failure',

  UpdateJobCostingProfit = '[JobCosting] Update Profit',
  UpdateJobCostingOnCost = '[JobCosting] Update OnCost',
  UpdateJobCostingCustomerQuote = '[JobCosting] Update NetQuote',
  UpdateJobCostingTotals = '[JobCosting] Update Totals',

  ResetJobCosting = '[JobCosting] Reset',
  SaveJobCosting = '[JobCosting] Save',
  SaveJobCostingSuccess = '[JobCosting] Save Success',
  SaveJobCostingFailure = '[JobCosting] Save Failure',

  UpdateJobCosting = '[JobCosting] Update',
  UpdateJobCostingSuccess = '[JobCosting] Update Success',
  UpdateJobCostingFailure = '[JobCosting] Update Failure',

  UpdateJobCostingStatus = '[JobCosting] Update Status',
  UpdateJobCostingStatusSuccess = '[JobCosting] Update StatusSuccess',
  UpdateJobCostingStatusFailure = '[JobCosting] Update Status Failure',

  UpdateStatusForSelectedVariationItem = '[JobCosting] updateStatusForSelectedVariationItem',
  UpdateStatusForSelectedVariationItemSuccess = '[JobCosting] updateStatusForSelectedVariationItem Success',
  UpdateStatusForSelectedVariationItemFailure = '[JobCosting] updateStatusForSelectedVariationItem Failure',

  UploadJobCostingQuoteLogo = '[JobCosting] Upload Logo',
  UploadJobCostingQuoteLogoSuccess = '[JobCosting] Upload Logo Success',

  UpdatePriceListItem = '[JobCosting] ',
  UpdatePriceListItemShowDialog = '[JobCosting] UpdatePriceListItemShowDialog',
  AddRemovePriceListItemsShowDialog = '[JobCosting] AddRemovePriceListItemsShowDialog',
  AddRemovePriceListItems = '[JobCosting] AddRemovePriceListItems',
  DeletePriceListItemConfirm = '[JobCosting] DeletePriceListItemConfirm',
  DeletePriceListItem = '[JobCosting] DeletePriceListItem',
  DeletePriceListItemSuccess = '[JobCosting] DeletePriceListItemSuccess',
  DeletePriceListItemFailure = '[JobCosting] DeletePriceListItemFailure',

  getJobVariation = '[Job Costing] Get JobVariation',
  getJobVariationSuccess = '[Job Costing] Get JobVariation Success',
  getJobVariationFailure = '[Job Costing] Get JobVariation Failure',

  getJobHistory = '[Job Costing] Get JobHistory',
  getJobHistorySuccess = '[Job Costing] Get JobHistory Success',
  getJobHistoryFailure = '[Job Costing] Get JobHistory Failure',

}

export const getJobCosting = createAction(
  JobCostingActionTypes.GetJobCosting,
  props<{ id: string }>(),
);

export const getJobCostingSuccess = createAction(
  JobCostingActionTypes.GetJobCostingSuccess,
  props<{ job: JobCostingWithMetrics }>(),
);

export const getJobCostingFailure = createAction(
  JobCostingActionTypes.GetJobCostingFailure,
  props<{ error: any }>(),
);

export const getJobCostingSingle = createAction(
  JobCostingActionTypes.GetJobCostingSingle,
  props<{ id: string }>(),
);

export const getJobCostingSingleSuccess = createAction(
  JobCostingActionTypes.GetJobCostingSingleSuccess,
  props<{ jobCosting: SingleSaveJobCosting }>(),
);

export const getJobCostingSingleFailure = createAction(
  JobCostingActionTypes.GetJobCostingSingleFailure,
  props<{ error: any }>(),
);


export const getVariations = createAction(
  JobCostingActionTypes.getJobVariation,
  props<{ query: VariationParameters }>(),
);

export const getVariationsSuccess = createAction(
  JobCostingActionTypes.getJobVariationSuccess,
  props<{ variation: PaginatedListOfVariation }>(),
);

export const getVariationsFailure = createAction(
  JobCostingActionTypes.getJobVariationFailure,
  props<{ error: any }>(),
);

export const getJobHistory = createAction(
  JobCostingActionTypes.getJobHistory,
  props<{ query: JobHistoryParameters }>(),
);

export const getJobHistorySuccess = createAction(
  JobCostingActionTypes.getJobHistorySuccess,
  props<{ history: PaginatedListOfJobHistory }>(),
);

export const getJobHistoryFailure = createAction(
  JobCostingActionTypes.getJobHistoryFailure,
  props<{ error: any }>(),
);

export const updateJobCostingProfit = createAction(
  JobCostingActionTypes.UpdateJobCostingProfit,
  props<{ profit: number }>(),
);

export const updateJobCostingOnCost = createAction(
  JobCostingActionTypes.UpdateJobCostingOnCost,
  props<{ onCost: number }>(),
);

export const updateJobCostingCustomerQuote = createAction(
  JobCostingActionTypes.UpdateJobCostingCustomerQuote,
  props<{ customerQuote: number }>(),
);

export const updateJobCostingTotals = createAction(
  JobCostingActionTypes.UpdateJobCostingTotals,
);

export const saveJobCosting = createAction(JobCostingActionTypes.SaveJobCosting);

export const saveJobCostingSuccess = createAction(
  JobCostingActionTypes.SaveJobCostingSuccess,
  props<{ jobCosting: SingleSaveJobCosting }>(),
);

export const saveJobCostingFailure = createAction(
  JobCostingActionTypes.SaveJobCostingFailure,
  props<{ error: any }>(),
);

export const updateJobCostingEstimate = createAction(
  JobCostingActionTypes.UpdateJobCosting,
  props<{ jobCosting: SingleSaveJobCosting }>(),
  );

export const updateJobCostingEstimateSuccess = createAction(
  JobCostingActionTypes.UpdateJobCostingSuccess,
  props<{ jobCosting: SingleSaveJobCosting }>(),
);

export const updateJobCostingEstimateFailure = createAction(
  JobCostingActionTypes.UpdateJobCostingFailure,
  props<{ error: any }>(),
);

export const updateJobCostingStatus = createAction(
  JobCostingActionTypes.UpdateJobCostingStatus,
  props<{ params: {id: string, type: string} }>(),
);

export const updateJobCostingStatusSuccess = createAction(
  JobCostingActionTypes.UpdateJobCostingStatusSuccess,
  props<{ jobCosting: SingleSaveJobCosting }>(),
);

export const updateJobCostingStatusFailure = createAction(
  JobCostingActionTypes.UpdateJobCostingStatusFailure,
  props<{ error: any }>(),
);

export const updateStatusForSelectedVariationItem = createAction(
  JobCostingActionTypes.UpdateStatusForSelectedVariationItem,
  props<{ params: {variationItemId: string, status: number} }>(),
);


export const uploadJobCostingQuoteLogo = createAction(
  JobCostingActionTypes.UploadJobCostingQuoteLogo,
  props<{ id: string; file: File }>(),
);

export const uploadJobCostingQuoteLogoSuccess = createAction(
  JobCostingActionTypes.UploadJobCostingQuoteLogoSuccess,
  props<{ fileLocation: string }>(),
);


export const updatePriceListItem = createAction(
  JobCostingActionTypes.UpdatePriceListItem,
  props<{ item: JobCostingPriceListItem }>(),
);

export const updatePriceListItemShowDialog = createAction(
  JobCostingActionTypes.UpdatePriceListItemShowDialog,
  props<{ item: JobCostingPriceListItem }>(),
);

export const addRemovePriceListItemsShowDialog = createAction(
  JobCostingActionTypes.AddRemovePriceListItemsShowDialog,
);

export const addRemovePriceListItems = createAction(
  JobCostingActionTypes.AddRemovePriceListItems,
  props<{
    added: JobCostingPriceListItem[];
    removed: JobCostingPriceListItem[];
  }>(),
);

export const deletePriceListItemConfirm = createAction(
  JobCostingActionTypes.DeletePriceListItemConfirm,
  props<{ id: string }>(),
);

export const deletePriceListItem = createAction(
  JobCostingActionTypes.DeletePriceListItem,
  props<{ id: string }>(),
);

export const deletePriceListItemSuccess = createAction(
  JobCostingActionTypes.DeletePriceListItemSuccess,
  props<{ id: string }>(),
);

export const deletePriceListItemFailure = createAction(
  JobCostingActionTypes.DeletePriceListItemFailure,
  props<{ error: any }>(),
);

export const resetJobCosting = createAction(JobCostingActionTypes.ResetJobCosting);


