import { createAction, props } from '@ngrx/store';
import {
  EstimatePriceListItem,
  SaveAsTemplate,
  SingleSaveEstimate,
  Template,
} from 'app/shared/models';
import { EstimateStatusParams } from '../../models/estimate-statuses';

export enum EstimateActionTypes {
  UpdateEstimateProfit = '[Estimate] Update Profit',
  UpdateEstimateOnCost = '[Estimate] Update OnCost',
  UpdateEstimateCustomerQuote = '[Estimate] Update NetQuote',
  ShowAddEstimateSectionDialog = '[Estimate] Show Add Estimate Section Dialog',
  AddEstimateSection = '[Estimate] Add Estimate Section',
  ShowRenameEstimateSectionDialog = '[Estimate] Show Rename Estimate Section Dialog',
  RenameEstimateSection = '[Estimate] Rename Estimate Section',
  RenameEstimateSectionSuccess = '[Estimate] Rename Estimate Section Success',
  RenameEstimateSectionFailure = '[Estimate] Rename Estimate Section Failure',
  MoveEstimateSectionDown = '[Estimate] Move Estimate Section Down',
  MoveEstimateSectionUp = '[Estimate] Move Estimate Section Up',
  DeleteEstimateSectionConfirm = '[Estimate] Delete Estimate Section Confirm',
  DeleteEstimateSection = '[Estimate] Delete Estimate Section',
  DeleteEstimatePriceListItemConfirm = '[Estimate] Delete Estimate Price List Item Confirm',
  DeleteEstimatePriceListItem = '[Estimate] Delete Estimate Price List Item',
  DeleteEstimatePriceListItemSuccess = '[Estimate] Delete Estimate Price List Item Success',
  DeleteEstimatePriceListItemFailure = '[Estimate] Delete Estimate Price List Item Failure',
  UpdateEstimatePriceListItemShowDialog = '[Estimate] Update Estimate Price List Item Show Dialog',
  AddRemoveEstimatePriceListItemsShowDialog = '[Estimate] Update Estimate Price List Items Show Dialog',
  AddRemoveEstimatePriceListItems = '[Estimate] Update Estimate Price List Items',
  UpdateEstimateTotals = '[Estimate] Update Estimate Totals',
  UpdateEstimatePriceListItem = '[Estimate] Update Estimate Price List Item',
  UpdateEstimateSectionPriceListItemsShowDialog = '[Estimate] Update Estimate Section Price List Items Show Dialog',
  UpdateEstimateSectionPriceListItems = '[Estimate] Update Estimate Section Price List Items',
  GetEstimate = '[Estimate] Get Estimate',
  GetEstimateSuccess = '[Estimate] Get Estimate Success',
  GetEstimateFailure = '[Estimate] Get Estimate Failure',
  UpdateEstimateFull = '[Estimate] Update Estimate Full',
  UpdateEstimateFullSuccess = '[Estimate] Update Estimate Full Success',
  UpdateEstimateFullFailure = '[Estimate] Update Estimate Full Failure',
  SelectEstimatePriceListItems = '[Estimate] Select Estimate Price List Items',
  DeselectEstimatePriceListItems = '[Estimate] Deselect Estimate Price List Items',
  CreateTemplateAction = '[Estimate] Create Template',
  CreateTemplateActionSuccess = '[Estimate] Create Template Success',
  CreateTemplateActionFailure = '[Estimate] Create Template Failure',
  ResetEstimate = '[Estimate] Reset',
  SaveEstimate = '[Estimate] Save',
  UpdateEstimate = '[Estimate] Update',
  SaveEstimateSuccess = '[Estimate] Save Success',
  SaveEstimateFailure = '[Estimate] Save Failure',
  UpdateEstimateStatus = '[Estimate Detail] UpdateEstimateStatus',
  UpdateEstimateStatusSuccess = '[Estimate Detail] UpdateEstimateStatusSuccess',
  UpdateEstimateStatusFailure = '[Estimate Detail] UpdateEstimateStatusFailure',
}

export const saveEstimate = createAction(EstimateActionTypes.SaveEstimate);

export const saveEstimateSuccess = createAction(
  EstimateActionTypes.SaveEstimateSuccess,
  props<{ estimate: SingleSaveEstimate }>(),
);

export const saveEstimateFailure = createAction(
  EstimateActionTypes.SaveEstimateFailure,
  props<{ error: any }>(),
);

export const updateEstimate = createAction(
  EstimateActionTypes.UpdateEstimate,
  props<{ estimate: SingleSaveEstimate }>(),
);

export const resetEstimate = createAction(EstimateActionTypes.ResetEstimate);

export const updateEstimateProfit = createAction(
  EstimateActionTypes.UpdateEstimateProfit,
  props<{ profit: number }>(),
);

export const updateEstimateOnCost = createAction(
  EstimateActionTypes.UpdateEstimateOnCost,
  props<{ onCost: number }>(),
);

export const updateEstimateCustomerQuote = createAction(
  EstimateActionTypes.UpdateEstimateCustomerQuote,
  props<{ customerQuote: number }>(),
);

export const updateEstimatePriceListItemShowDialog = createAction(
  EstimateActionTypes.UpdateEstimatePriceListItemShowDialog,
  props<{ item: EstimatePriceListItem }>(),
);

export const addRemoveEstimatePriceListItemsShowDialog = createAction(
  EstimateActionTypes.AddRemoveEstimatePriceListItemsShowDialog,
);

export const addRemoveEstimatePriceListItems = createAction(
  EstimateActionTypes.AddRemoveEstimatePriceListItems,
  props<{
    added: EstimatePriceListItem[];
    removed: EstimatePriceListItem[];
  }>(),
);

export const showAddEstimateSectionDialog = createAction(
  EstimateActionTypes.ShowAddEstimateSectionDialog,
);

export const addEstimateSection = createAction(
  EstimateActionTypes.AddEstimateSection,
  props<{ name: string }>(),
);

export const showRenameEstimateSectionDialog = createAction(
  EstimateActionTypes.ShowRenameEstimateSectionDialog,
  props<{ id: string; name: string }>(),
);

export const renameEstimateSection = createAction(
  EstimateActionTypes.RenameEstimateSection,
  props<{ id: string; name: string }>(),
);

export const selectEstimatePriceListItems = createAction(
  EstimateActionTypes.SelectEstimatePriceListItems,
  props<{ ids: string[] }>(),
);

export const deselectEstimatePriceListItems = createAction(
  EstimateActionTypes.DeselectEstimatePriceListItems,
  props<{ ids: string[] }>(),
);

export const deleteEstimateSectionConfirm = createAction(
  EstimateActionTypes.DeleteEstimateSectionConfirm,
  props<{ id: string }>(),
);

export const moveEstimateSectionDown = createAction(
  EstimateActionTypes.MoveEstimateSectionDown,
  props<{ id: string }>(),
);

export const moveEstimateSectionUp = createAction(
  EstimateActionTypes.MoveEstimateSectionUp,
  props<{ id: string }>(),
);

export const deleteEstimateSection = createAction(
  EstimateActionTypes.DeleteEstimateSection,
  props<{ id: string }>(),
);

export const updateEstimatePriceListItem = createAction(
  EstimateActionTypes.UpdateEstimatePriceListItem,
  props<{ item: EstimatePriceListItem }>(),
);

export const updateEstimateTotals = createAction(
  EstimateActionTypes.UpdateEstimateTotals,
);

export const updateEstimateSectionPriceListItemsShowDialog = createAction(
  EstimateActionTypes.UpdateEstimateSectionPriceListItemsShowDialog,
  props<{ section: any }>(),
);
export const updateEstimateSectionPriceListItems = createAction(
  EstimateActionTypes.UpdateEstimateSectionPriceListItems,
  props<{
    estimateSectionId: string;
    added: EstimatePriceListItem[];
    removed: EstimatePriceListItem[];
  }>(),
);

export const deleteEstimatePriceListItemConfirm = createAction(
  EstimateActionTypes.DeleteEstimatePriceListItemConfirm,
  props<{ id: string }>(),
);

export const deleteEstimatePriceListItem = createAction(
  EstimateActionTypes.DeleteEstimatePriceListItem,
  props<{ id: string }>(),
);

export const deleteEstimatePriceListItemSuccess = createAction(
  EstimateActionTypes.DeleteEstimatePriceListItemSuccess,
  props<{ id: string }>(),
);

export const deleteEstimatePriceListItemFailure = createAction(
  EstimateActionTypes.DeleteEstimatePriceListItemFailure,
  props<{ error: any }>(),
);

export const getEstimate = createAction(
  EstimateActionTypes.GetEstimate,
  props<{ id: string }>(),
);

export const getEstimateSuccess = createAction(
  EstimateActionTypes.GetEstimateSuccess,
  props<{ estimate: SingleSaveEstimate }>(),
);

export const getEstimateFailure = createAction(
  EstimateActionTypes.GetEstimateFailure,
  props<{ error: any }>(),
);

export const createTemplate = createAction(
  EstimateActionTypes.CreateTemplateAction,
  props<{ template: SaveAsTemplate }>(),
);

export const createTemplateSuccess = createAction(
  EstimateActionTypes.CreateTemplateActionSuccess,
  props<{ template: Template }>(),
);

export const createTemplateFailure = createAction(
  EstimateActionTypes.CreateTemplateActionFailure,
  props<{ error: any }>(),
);

export const updateEstimateStatus = createAction(
  EstimateActionTypes.UpdateEstimateStatus,
  props<{ params: EstimateStatusParams }>(),
);

export const updateEstimateStatusSuccess = createAction(
  EstimateActionTypes.UpdateEstimateStatusSuccess,
);

export const updateEstimateStatusFailure = createAction(
  EstimateActionTypes.UpdateEstimateStatusFailure,
  props<{ error: any }>(),
);
