<div [formGroup]="quoteFormService.quoteForm">
  <div class="flex pr-2">
    <div class="mr-4">
      <div class="w-64 min-w-64">
        <div class="h-0 w-0 overflow-hidden"></div>
        <mat-form-field class="fuse-mat-no-subscript mb-2 w-full">
          <mat-label>Quote date</mat-label>
          <input
            formControlName="sent"
            readonly
            (dateChange)="formatDate()"
            matInput
            [matDatepicker]="quoteDate"
            (focus)="quoteDate.open()"
          />
          <mat-icon
            matSuffix
            class="button-icon"
            svgIcon="arrow_drop_down"
          >
          </mat-icon>
          <mat-datepicker #quoteDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="w-64 min-w-64">
        <mat-form-field
          class="fuse-mat-no-subscript mb-2 w-full"
          appearance="fill"
        >
          <mat-label>Days quote is valid</mat-label>
          <input
            type="number"
            matInput
            formControlName="validDays"
            (blur)="validDaysBlur()"
            [matAutocomplete]="auto"
          />
          <mat-icon
            matSuffix
            class="button-icon"
            svgIcon="arrow_drop_down"
          >
          </mat-icon>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of daysQuoteIsValid"
              [value]="option.value"
            >
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div>
      <div class="w-64 min-w-64">
        <mat-form-field class="fuse-mat-no-subscript mb-2 w-full">
          <mat-label>Layout</mat-label>
          <mat-select formControlName="layoutId">
            <mat-option *ngFor="let v of layouts" [value]="v.value">
              {{ v.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="flex flex-row items-center">
        <div class="w-64 min-w-64">
          <mat-form-field
            (click)="openCropperDialog()"
            class="fuse-mat-no-subscript mb-2 w-full"
            appearance="fill"
          >
            <mat-label>Logo</mat-label>
            <input
              class="hover:cursor-pointer"
              readonly
              [disabled]="quoteFormService.isReadOnlyMode"
              [value]="quoteFormService.quoteForm.get('logo').value | logoName"
              type="text"
              aria-label="Layout"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="ml-2 max-h-16 flex items-center">
          <img
            *ngIf="quoteFormService.quoteForm.get('logo').value"
            class="pl-4 h-20"
            [src]="quoteFormService.quoteForm.get('logo').value"
          />
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>
