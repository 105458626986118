/* eslint-disable arrow-parens */
import {
  AsyncPipe,
  NgIf,
  NgFor,
  CurrencyPipe,
  DatePipe,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Estimate,
  EstimateParameters,
  EstimateStatus,
} from 'app/shared/models';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  acceptEstimateSuccess,
  cloneEstimateSuccess,
  EstimateListActionTypes,
  EstimateListFacade,
} from '../../store';
import { Actions, ofType } from '@ngrx/effects';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatSort, Sort } from '@angular/material/sort';
import { UtilService } from '../../../../shared/services';
import {
  GuidedTourNameEnum,
  IntroJsService,
} from 'app/shared/services/introjs.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { GuidedTourService } from 'app/shared/services/guided-tour.service';
import { BackNavigationService } from '../../../../shared/services/back-navigation.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FuseCardComponent } from '../../../../../@fuse/components/card/card.component';
import { MatButtonModule } from '@angular/material/button';
import { JobStatusPillComponent } from '../../../../shared/components/job-status-pill/job-status-pill.component';
import { SharedModule } from 'app/shared/shared.module';
import { JobDetailFacade } from '@app/views/jobs/store';

@Component({
  selector: 'app-estimate-list',
  templateUrl: './estimate-list.component.html',
  styleUrls: ['./estimate-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatTableModule,
    SharedModule,
    MatButtonModule,
    NgFor,
    FuseCardComponent,
    MatIconModule,
    MatFormFieldModule,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
  ],
})
export class EstimateListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];

  public defaultSort: Sort = { active: 'status', direction: 'asc' };
  statuses = UtilService.convertEnumToObject(EstimateStatus).map((el) => ({
    id: el.value,
    name: el.text,
  }));
  public query: EstimateParameters = {
    pageNumber: 1,
    pageSize: 65536,
    orderBy: [{ orderBy: 'status', descending: false }],
  };

  public historyQuery: EstimateParameters = {
    pageNumber: 1,
    pageSize: 65536,
    orderBy: [{ orderBy: 'status', descending: true }],
  };

  estimates$ = this.estimateListFacade.estimates$;
  estimatesHistory$ = this.estimateListFacade.estimatesHistory$;
  isLoaded$ = this.estimateListFacade.isLoaded$;
  defaultOnCost = this.async.transform(this.accountUserFacade.accountFormModel$)
    ?.defaultOnCost;
  dataSource: MatTableDataSource<Estimate>;
  estimatesHistoryDataSource: MatTableDataSource<Estimate>;
  displayedColumns = [
    'version',
    'date',
    'unitTotal',
    'onCost',
    'profit',
    'netCost',
    'gstAmount',
    'totalCost',
    'status',
    'action',
  ];

  showMobile = false;
  isIgnoreOnExitIntro = false;
  introEstimateCosting;

  private unsubscriber$ = new Subject<void>();

  constructor(
    private async: AsyncPipe,
    private router: Router,
    private cd: ChangeDetectorRef,
    public facade: JobDetailFacade,
    private estimateListFacade: EstimateListFacade,
    private accountUserFacade: AccountUserFacade,
    private route: ActivatedRoute,
    private actions$: Actions,
    private confirm: FuseConfirmationService,
    private introJsService: IntroJsService,
    private guidedTourService: GuidedTourService,
    public breakpointObserver: BreakpointObserver,
    private backNavigationService: BackNavigationService,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 1280px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showMobile = false;
        } else {
          this.showMobile = true;
        }
      });

    const id = this.route.snapshot.parent.params['id'];
    if (id) {
      this.query.jobId = id;
      this.historyQuery.jobId = id;
    }
    this.actions$
      .pipe(
        ofType(cloneEstimateSuccess, acceptEstimateSuccess),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((response) => {
        if (response.type === '[Estimate List] Clone Estimates Success') {
          this.facade.getJob(this.query.jobId);
          this.estimateListFacade.getEstimates(this.query);
          this.estimateListFacade.getEstimatesHistory(this.historyQuery);
        }
        if (response.type === '[Estimate List] Accept Estimates Success') {
          this.router.navigate(['jobs', response.estimate.jobId, 'info']);
        }
      });
    this.actions$
      .pipe(ofType(EstimateListActionTypes.GetEstimatesSuccess), take(1))
      .subscribe(() => {
        // this.guidedTourService.resetGuidedTour(btoa(GuidedTourNameEnum.EstimateCosting)).subscribe();
        this.guidedTourService
          .getGuidedTourShow(btoa(GuidedTourNameEnum.EstimateCosting))
          .subscribe((show) => {
            if (show) {
              this.introJsService.estimateCosting(this.introEstimateCosting);
            }
          });
      });

    this.estimates$.pipe(takeUntil(this.unsubscriber$)).subscribe((res) => {
      if (res?.length) {
        this.dataSource = new MatTableDataSource(res);
      }
    });

    this.estimatesHistory$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        if (res.length === 0) {
          this.estimatesHistoryDataSource = undefined;
        } else {
          this.estimatesHistoryDataSource = new MatTableDataSource(res);
        }
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.introJsService.currentIntroPage$.next(null);
  }

  ngAfterViewInit(): void {
    this.introJsService.currentIntroPage$.next(
      GuidedTourNameEnum.EstimateCosting,
    );
    this.introJsService.resetedTour$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.introInit(true);
      });
    this.guidedTourService
      .getGuidedTourShow(btoa(GuidedTourNameEnum.EstimateCosting))
      .subscribe((show) => {
        if (show) {
          this.introInit();
        }
      });
    this.loadEstimates();
  }

  introInit(afterReset?: boolean): void {
    this.introEstimateCosting = this.introJsService.getIntro();
    this.introEstimateCosting.onexit(() => {
      if (!this.isIgnoreOnExitIntro) {
        this.guidedTourService
          .completeGuidedTour(btoa(GuidedTourNameEnum.EstimateCosting))
          .subscribe();
      } else {
        this.isIgnoreOnExitIntro = false;
      }
    });
    if (afterReset) {
      this.introJsService.estimateCosting(this.introEstimateCosting);
    }
  }

  details(estimate: Estimate): void {
    this.backNavigationService.tooltip = 'Back to Estimate';
    this.backNavigationService.url = this.router.url;
    this.exitIntro();
    this.router.navigate([estimate.id, 'estimate-info'], {
      relativeTo: this.route,
    });
  }

  clone(estimate: Estimate): void {
    this.estimateListFacade.cloneEstimate(estimate.id);
  }

  accept(estimate: Estimate): void {
    this.exitIntro();
    this.confirm
      .open({
        title: 'Accept Estimate',
        message: 'This estimate will be converted to a job.',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'OK', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'confirmed'),
        takeUntil(this.unsubscriber$),
      )
      .subscribe(() => {
        this.estimateListFacade.acceptEstimate(estimate.id);
      });
  }

  exitIntro(): void {
    if (this.introEstimateCosting) {
      this.isIgnoreOnExitIntro = true;
      this.introJsService.exitItroJs(this.introEstimateCosting);
    }
  }

  private loadEstimates(): void {
    this.query = {
      ...this.query,
    };
    this.estimateListFacade.getEstimates(this.query);
    this.estimateListFacade.getEstimatesHistory(this.historyQuery);
  }
}
