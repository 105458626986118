import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobInvoice } from 'app/shared/models';
import { of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  addJobInvoice,
  addJobInvoiceFailure,
  addJobInvoiceSuccess,
  changeInvoiceStatus,
  changeInvoiceStatusFailure,
  changeInvoiceStatusSuccess,
  deleteJobInvoice,
  deleteJobInvoiceFailure,
  deleteJobInvoiceSuccess,
  getCustomerJobInvoices,
  getCustomerJobInvoicesFailure,
  getCustomerJobInvoicesSuccess,
  getJobInvoice,
  getJobInvoiceFailure,
  getJobInvoices,
  getJobInvoicesFailure,
  getJobInvoicesSuccess,
  getJobInvoiceSuccess,
  sendInvoice,
  sendInvoiceFailure,
  sendInvoiceSuccess,
  updateInvoicePayment,
  updateInvoicePaymentFailure,
  updateInvoicePaymentSuccess,
  updateJobInvoice,
  updateJobInvoiceFailure,
  updateJobInvoiceSuccess,
} from '..';
import { JobService } from '../../services/job.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class JobInvoicesEffects {
  addJobInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJobInvoice),
      switchMap(({ invoice }) =>
        this.jobService.addJobInvoice(invoice).pipe(
          map((invoice: JobInvoice) => addJobInvoiceSuccess({ invoice })),
          catchError((error) => {
            return of(addJobInvoiceFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateJobInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobInvoice),
      switchMap(({ invoice }) =>
        this.jobService.saveJobInvoice(invoice).pipe(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          map((invoice: JobInvoice) => updateJobInvoiceSuccess({ invoice })),
          tap(() => this.toast.success('Sent successfully')),
          catchError((error) => of(updateJobInvoiceFailure({ error }))),
        ),
      ),
    ),
  );

  deleteJobInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJobInvoice),
      switchMap(({ id }) =>
        this.jobService.deleteJobInvoice(id).pipe(
          map((invoice: JobInvoice) => {
            this.toast.success('The Invoice was deleted successfully.');
            return deleteJobInvoiceSuccess({ id });
          }),
          catchError((error) => {
            this.toast.error('An error occurred while deleting the Invoice.');
            return of(deleteJobInvoiceFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getJobInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobInvoices),
      switchMap(({ request }) =>
        this.jobService.getJobInvoices(request).pipe(
          map((response) => getJobInvoicesSuccess({ invoices: response })),
          catchError((error) => of(getJobInvoicesFailure({ error }))),
        ),
      ),
    ),
  );

  getCustomerJobInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomerJobInvoices),
      switchMap(({ id }) =>
        this.jobService.getCustomerJobInvoices(id).pipe(
          map((response) =>
            getCustomerJobInvoicesSuccess({ invoices: response }),
          ),
          catchError((error) => of(getCustomerJobInvoicesFailure({ error }))),
        ),
      ),
    ),
  );

  getJobInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobInvoice),
      switchMap(({ id }) =>
        this.jobService.getJobInvoiceById(id).pipe(
          map((response) => getJobInvoiceSuccess({ invoice: response })),
          catchError((error) => of(getJobInvoiceFailure({ error }))),
        ),
      ),
    ),
  );

  changeJobInvoiceStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeInvoiceStatus),
      switchMap(({ action, id }) =>
        this.jobService.changeJobInvoiceStatus(action, id).pipe(
          map((response) => changeInvoiceStatusSuccess({ invoice: response })),
          tap(() => this.toast.success('Status changed')),
          catchError((error) => of(changeInvoiceStatusFailure({ error }))),
        ),
      ),
    ),
  );

  sendInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendInvoice),
      switchMap(({ action, id }) =>
        this.jobService.sendJobInvoice(action, id).pipe(
          map((response) => sendInvoiceSuccess({ invoice: response })),
          catchError((error) => of(sendInvoiceFailure({ error }))),
        ),
      ),
    ),
  );

  updateInvoicePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInvoicePayment),
      switchMap(({ payload }) =>
        this.jobService.updateInvoicePayment(payload).pipe(
          map((response) =>
            updateInvoicePaymentSuccess({ invoicePayment: response }),
          ),
          catchError((error) => of(updateInvoicePaymentFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private toast: ToastService,
  ) {}
}
