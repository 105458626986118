import { AsyncPipe, CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from 'app/shared/shared.module';
import { NgxStripeModule } from 'ngx-stripe';
import { SettingsContainerComponent } from './containers/settings-container/settings-container.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsStoreModule } from './store/settings-store.module';

import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

import { AccountSettingsGuard } from './components/account-settings/account-settings.guard';
import { PaymentDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { ProfileSettingsGuard } from './components/profile-settings/profile-settings.guard';

import { ImageCropperComponent } from 'ngx-image-cropper';

import * as fromComponents from './components';
import * as fromContainers from './containers';
import * as fromDialogs from './dialogs';

import { ChangePlanCostsDialogComponent } from './components/change-plan-costs-dialog/change-plan-costs-dialog.component';
import { ChangePlanDialogComponent } from './components/change-plan-dialog/change-plan-dialog.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ExtraSeatCofirmDialogComponent } from './components/extra-seat-confirm-dialog/extra-seat-confirm-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        SettingsRoutingModule,
        SettingsStoreModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        NgxStripeModule.forRoot(),
        ImageCropperComponent,
        MatTableModule,
        MatButtonModule,
        RouterModule,
        DragDropModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatRippleModule,
        MatDatepickerModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatTooltipModule,
        SettingsContainerComponent,
        PaymentDialogComponent,
        ...fromComponents.components,
        ...fromContainers.containers,
        ...fromDialogs.dialogs,
        ChangePlanDialogComponent,
        ChangePlanCostsDialogComponent,
        ExtraSeatCofirmDialogComponent,
    ],
    providers: [
        AccountSettingsGuard,
        ProfileSettingsGuard,
        AsyncPipe,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModule {}
