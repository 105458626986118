import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { JobNote } from 'app/shared/models';

@Component({
  selector: 'app-job-notes-list',
  templateUrl: './job-notes-list.component.html',
  styleUrls: ['./job-notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class JobNotesListComponent implements OnInit {
  constructor() {}

  @Input() notes: JobNote[];

  @Output() selectNote = new EventEmitter<string>();

  ngOnInit(): void {}
}
