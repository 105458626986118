import { createSelector } from '@ngrx/store';
import { LoadingState } from 'app/store';
import { flatten, last, orderBy, sumBy, uniq } from 'lodash';
import { selectJobState } from '../reducers';

export const home = {
  name: 'Home',
  path: 'Home\\',
  files: [],
  size: 0,
};

export const selectJobFilesState = createSelector(
  selectJobState,
  (s) => s.files,
);

export const selectJobFilesIsLoaded = createSelector(
  selectJobFilesState,
  (s) => s.callState == LoadingState.LOADED,
);

export const selectJobFilesSortField = createSelector(
  selectJobFilesState,
  (s) => s.sortField,
);

export const selectJobFilesSortOrder = createSelector(
  selectJobFilesState,
  (s) => s.sortOrder,
);

export const selectFiles = createSelector(selectJobFilesState, (s) => {
  let files = s.files
    .filter((f) => f.id)
    .map((f) => {
      return {
        id: f.id,
        jobId: f.jobId,
        url: f.url,
        name: f.name,
        path: f.path,
        size: f.size,
        date: f.updated,
        selected: s.selectedFileIds.includes(f.id),
      };
    });

  return orderBy(files, [s.sortField], [s.sortOrder]);
});

export const selectFolders = createSelector(selectJobFilesState, (s) => {
  const paths = uniq(
    flatten(
      s.files.map((f) => {
        let path = '';
        return f.path
          .split('\\')
          .filter((p) => p)
          .map((p) => {
            path += p + '\\';
            return path;
          });
      }),
    ),
  );

  let folders = paths.map((f) => {
    const parts = f.split('\\');
    const name = last(parts.filter((p) => p));
    const descendentFiles = s.files.filter(
      (a) => a.name && a.path.startsWith(f),
    );
    const parentLevel = parts.length;

    const childFolders = paths.filter((p) => {
      const level = p.split('\\').length;
      return p.startsWith(f) && level === parentLevel + 1;
    });

    const size = sumBy(descendentFiles, (c) => c.size);

    return {
      name: name,
      path: f,
      //date: maxBy(descendentFiles.filter(x => x.path === f), f => f.updated).updated,
      fileCount: descendentFiles.filter((x) => x.path === f).length,
      folderCount: childFolders.length,
      size: size,
      selected: s.selectedFolderPaths.includes(f),
    };
  });
  folders = orderBy(folders, [s.sortField], [s.sortOrder]);

  return folders;
});

export const selectJobFilesCurrentFolder = createSelector(
  selectJobFilesState,
  selectFolders,
  (s, folders) => folders.find((f) => f.path === s.currentFolderPath) ?? home,
);

export const selectJobFilesCurrentFiles = createSelector(
  selectJobFilesState,
  selectFiles,
  (s, files) => files.filter((f) => f.path === s.currentFolderPath && f.name),
);

export const selectJobFilesAllFiles = createSelector(
  selectJobFilesState,
  selectFiles,
  (s) => s.files,
);

export const selectJobFilesFolders = createSelector(
  selectJobFilesState,
  selectFolders,
  (s, folders) => folders,
);

export const selectJobFilesCurrentFolders = createSelector(
  selectJobFilesState,
  selectFolders,
  (s, folders) =>
    folders.filter(
      (f) =>
        f.path.startsWith(s.currentFolderPath) &&
        f.path.split('\\').length ===
          s.currentFolderPath.split('\\').length + 1,
    ),
);

export const selectSelectedJobFolderPaths = createSelector(
  selectJobFilesState,
  (s) => s.selectedFolderPaths,
);

export const selectSelectedJobFileIds = createSelector(
  selectJobFilesState,
  (s) => s.selectedFileIds,
);
