import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PageEvent as PageEvent,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';
import {
  JobInvoice,
  JobInvoiceParameters,
  JobInvoiceTotals,
} from 'app/shared/models';
import {
  MatTableDataSource as MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { Subject } from 'rxjs';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { JobInvoicesFacade } from '../../../store';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { UtilService } from 'app/shared/services';
import { JobStateService } from '../../../services/job-state.service';
import { JobService } from '../../../services/job.service';
import { BackNavigationService } from '../../../../../shared/services/back-navigation.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { FuseCardComponent } from '../../../../../../@fuse/components/card/card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InvoiceStatusBadgeComponent } from '../invoice-status-badge/invoice-status-badge.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  NgIf,
  NgFor,
  NgClass,
  AsyncPipe,
  CurrencyPipe,
  DatePipe,
} from '@angular/common';
import { GuidedTourNameEnum, IntroJsService } from '@shared/services/introjs.service';
import { GuidedTourService } from '@shared/services/guided-tour.service';

export enum InvoiceStatusText {
  All = 999,
  Draft = 10,
  'Awaiting Payment' = 20,
  Paid = 30,
  Void = 40,
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatOptionModule,
    MatTableModule,
    MatSortModule,
    InvoiceStatusBadgeComponent,
    MatIconModule,
    MatButtonModule,
    FuseCardComponent,
    MatPaginatorModule,
    NgClass,
    AsyncPipe,
    CurrencyPipe,
    DatePipe,
  ],
})
export class InvoiceListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  invoiceStatuses: { text: string; value: string }[];
  isSmallPortraitScreen: boolean = false;

  public query: JobInvoiceParameters = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: [{ orderBy: '', descending: false }],
  };

  displayedColumns: string[] = [
    'invoiceCode',
    'description',
    'invoiceDate',
    'paymentDueDate',
    'invoicedAmount',
    'paidAmount',
    'status',
    'sent',
    'action',
  ];
  dataSource;
  invoices: JobInvoice[];
  statusControl: FormControl;
  dateControl = new FormControl(moment());
  jobInvoiceTotals: JobInvoiceTotals = undefined;
  private unsubscribe$ = new Subject<void>();
  syncEnabled: boolean = false;
  intro;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public invoicesFacade: JobInvoicesFacade,
    private jobState: JobStateService,
    private chRef: ChangeDetectorRef,
    private fuseMediaWatcherService: FuseMediaWatcherService,
    private jobService: JobService,
    private backNavigationService: BackNavigationService,
    private accountUserFacade: AccountUserFacade,
    private introJsService: IntroJsService,
    private guidedTourService: GuidedTourService,
  ) {}

  ngOnInit(): void {
    this.introJsService.currentIntroPage$.next(GuidedTourNameEnum.InvoiceList);

    if (this.jobState.currentInvoicesListStatus !== InvoiceStatusText.All) {
      this.query.status = this.jobState.currentInvoicesListStatus;
    }
    this.fuseMediaWatcherService
      .onMediaQueryChange$(['(max-height: 440px)', '(max-width: 440px)'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isSmallPortraitScreen = state.matches;
        // Mark for check
        this.chRef.markForCheck();
      });
    this.invoiceStatuses = UtilService.convertEnumToObject(InvoiceStatusText);
    this.statusControl = new FormControl(
      this.jobState.currentInvoicesListStatus,
    );
    this.statusControl.valueChanges.subscribe((statusChanges) => {
      if (!statusChanges) {
        return;
      }
      this.query = {
        ...this.query,
        status: statusChanges,
      };
      if (statusChanges === InvoiceStatusText.All) {
        delete this.query.status;
      }
      this.jobState.currentInvoicesListStatus = statusChanges;
      this.invoicesFacade.getInvoices(this.query);
    });


    this.introJsService.resetedTour$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.introInit();
      });
    this.guidedTourService
      .getGuidedTourShow(btoa(GuidedTourNameEnum.InvoiceList))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((show) => {
        if (show) {
          this.introInit();
        }
      });

    this.dateControl.valueChanges.subscribe((changes: moment.Moment) => {
      this.query = {
        ...this.query,
        filter: changes.toISOString(),
      };
      this.invoicesFacade.getInvoices(this.query);
    });

    //get the status of the accounting integration
    this.accountUserFacade.loggedInUser$.subscribe((loggedInUser) => {
      this.syncEnabled = loggedInUser.accountingIntegrationActive;
      //if we're enabled then add the column to the displayedColumns list
      if (this.syncEnabled) {
        this.displayedColumns.push('integrationStatus');
      }
    });
  }

  introInit(): void {
    this.intro = this.introJsService.getIntro();
    this.intro.onexit(() => {
      this.guidedTourService
        .completeGuidedTour(btoa(GuidedTourNameEnum.InvoiceList))
        .subscribe();
      this.intro.setOptions({});
      this.intro = null;
    });
    this.chRef.detectChanges();

    setTimeout(
      () => this.introJsService.invoicesList(this.intro),
      1000,
    );
  }

  ngAfterViewInit(): void {
    this.query.jobId = this.route.snapshot.parent.params['id'];
    this.jobService.totalJobInvoice(this.query.jobId).subscribe((r) => {
      this.jobInvoiceTotals = r;
    });
    this.invoicesFacade.getInvoices(this.query);
    this.invoicesFacade.invoices$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.invoices = res.map((e) => ({
          ...e,
          statusText: InvoiceStatusText[e.status],
        }));
        this.dataSource = new MatTableDataSource(this.invoices);
        this.chRef.detectChanges();
        this.dataSource.sort = this.sort;
      });
  }

  details(item: JobInvoice): void {
    this.backNavigationService.url = this.router.url;
    this.backNavigationService.tooltip = 'Back to Invoices';
    this.router.navigate(['view', item.id], {
      relativeTo: this.route,
      // queryParams: { customer: item.customerId },
    });
  }

  paginatorChange(pageEvent: PageEvent): void {
    this.query = {
      ...this.query,
    };
    this.query.pageNumber = pageEvent.pageIndex + 1;
    this.query.pageSize = pageEvent.pageSize;
    this.invoicesFacade.getInvoices(this.query);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all observables
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
