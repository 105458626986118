/* eslint-disable arrow-body-style */
import { createSelector } from '@ngrx/store';
import { selectSettingsState, SettingsState } from '../reducers';

import * as fromReducer from '../reducers/team-settings.reducer';

export const selectTeamSettingsState = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.teamSettings,
);

export const selectNumberOfAvailableSeats = createSelector(
  selectTeamSettingsState,
  fromReducer.getAvailableSeats,
);

export const selectTeam = createSelector(
  selectTeamSettingsState,
  fromReducer.getTeam,
);

export const selectIsInitialised = createSelector(
  selectTeamSettingsState,
  fromReducer.getIsInitialised,
);
export const selectIsLoading = createSelector(
  selectTeamSettingsState,
  fromReducer.getIsLoading,
);
export const selectError = createSelector(
  selectTeamSettingsState,
  fromReducer.getIsLoading,
);
export const selectNumberOfOwnerAccounts = createSelector(
  selectSettingsState,
  (state: SettingsState) =>
    state.teamSettings.team == null
      ? 0
      : state.teamSettings.team.reduce((total, item) => {
          if (
            item.teamMemberTypeId === 1 &&
            item.deleted === false &&
            !!item.acceptedDate
          ) {
            return total + 1;
          }
          return total;
        }, 0),
);

export const selectTeamCommon = createSelector(
  selectTeamSettingsState,
  fromReducer.getCommon,
);

export const selectSelectedTeamTeamMember = createSelector(
  selectSettingsState,
  selectTeamCommon,
  (state: SettingsState, common) => {
    return { ...state.teamSettings.selectedTeamMember, common };
  },
);
