import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TandC } from 'app/shared/models/eazimate.models';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TandCService {
  constructor(private http: HttpClient) {}

  getMessage(): Observable<TandC> {
    const url = `${environment.urls.api}/tandc/current`;
    return this.http.get<TandC>(url);
  }

  getShowTandC(): Observable<any> {
    const url = `${environment.urls.api}/tandc/check`;
    return this.http.get<any>(url);
  }

  acceptTandC(): Observable<any> {
    const url = `${environment.urls.api}/tandc/accept`;
    return this.http.get<any>(url);
  }
}
