import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { EstimateState, selectEstimateState } from '../reducers';

export const selectEstimateQuoteState = createSelector(
  selectEstimateState,
  (s: EstimateState) => s.estimateQuote,
);

export const selectEstimateQuoteIsLoading = createSelector(
  selectEstimateQuoteState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectEstimateQuoteIsLoaded = createSelector(
  selectEstimateQuoteState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectEstimateQuoteError = createSelector(
  selectEstimateQuoteState,
  (s) => getError(s.callState),
);

export const selectEstimateQuote = createSelector(
  selectEstimateQuoteState,
  (s) => s.estimateQuote,
);

export const selectQuoteDocumentSections = createSelector(
  selectEstimateQuoteState,
  (s) => s.quoteDocumentSections,
);
