import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobCallForwardsState } from '../reducers/job-callforward.reducer';

export const selectJobCallForwardState =
  createFeatureSelector<JobCallForwardsState>('callforwards');

export const selectJobCallForwardIsLoading = createSelector(
  selectJobCallForwardState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobCallForwardIsLoaded = createSelector(
  selectJobCallForwardState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobCallForwardError = createSelector(
  selectJobCallForwardState,
  (s) => getError(s.callState),
);

// export const selectJobCallForwards = createSelector(
//   selectJobCallForwardState,
//   (s) => s.callforwards.items
// );

export const selectJobCallForwardItems = createSelector(
  selectJobCallForwardState,
  (s) => s.callforwardItems.items,
);

// export const selectJobCallForwardId = createSelector(
//   selectJobCallForwardState,
//   (s) => s.callforwardId
// );

export const selectJobCallForwardItemsTotalCount = createSelector(
  selectJobCallForwardState,
  (s) => s.callforwardItems.pageMetaData?.records,
);

export const selectJobCallForwardItemsPageNumber = createSelector(
  selectJobCallForwardState,
  (s) => s.callforwardItems.pageMetaData?.pageNumber,
);

export const selectJobCallForwardItemTypes = createSelector(
  selectJobCallForwardState,
  (s) => s.itemTypes,
);

export const selectJobCallForwardItemStatuses = createSelector(
  selectJobCallForwardState,
  (s) => s.itemStatuses,
);

export const selectEditIndex = createSelector(
  selectJobCallForwardState,
  (s) => s.editIndex,
);
export const selectIsNew = createSelector(
  selectJobCallForwardState,
  (s) => s.isNew,
);
export const selectCreateNew = createSelector(
  selectJobCallForwardState,
  (s) => s.createNew,
);

export const selectCallForwardItemNotes = createSelector(
  selectJobCallForwardState,
  (s) => s.callforwardItemNotes.items,
);
