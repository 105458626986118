import {
  ChangeDetectorRef,
  Component,
  Host,
  Inject,
  Injector,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import { FormControl, NgControl, NG_VALIDATORS } from '@angular/forms';
import {
  FormControlDirective,
  getValueAccessor,
} from 'app/shared/directives/form-control-component.model';
import {
  AudioConfig,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer,
} from 'microsoft-cognitiveservices-speech-sdk';
import { Subject, takeUntil, timer } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { environment } from '@env/environment';

@Component({
  selector: 'app-speach-to-text',
  templateUrl: './speach-to-text.component.html',
  styleUrls: ['./speach-to-text.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SpeachToTextComponent,
      multi: true,
    },
    getValueAccessor(SpeachToTextComponent),
  ],
  standalone: true,
  imports: [MatButtonModule, MatIconModule, NgIf],
})
export class SpeachToTextComponent extends FormControlDirective {
  @Input() isClearText: boolean = false;
  formControl: FormControl;
  isRequired = false;
  recognizing = false;
  lastValue: string = '';
  _recognizer: SpeechRecognizer;
  showVoiceIndicator = false;

  private lastRecognized: string = '';
  private resetTimer$: Subject<void> = new Subject<void>();

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);
    if (ngControl) {
      this.formControl = ngControl.control as FormControl;
      this.changeDetectorRef.detectChanges();
    }
  }

  modelChanged(): void {
    this.emitChanges();
  }

  validate(control: FormControl): boolean {
    if (control.disabled || control.validator) {
      this.formControl = control as FormControl;
      if (control.validator) {
        const validation = control.validator(new FormControl());
        this.isRequired = validation !== null && validation.required === true;
      }
      return false;
    }
  }

  startButton(): void {
    if (this.recognizing) {
      return;
    }
    this.lastRecognized = '';

    this.lastValue = this.formControl.value;
    this.recognizing = true;
    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
    const speechConfig = SpeechConfig.fromSubscription(
      environment.speachToText.subscriptionKey,
      environment.speachToText.region,
    );
    speechConfig.speechRecognitionLanguage = 'en-US';
    speechConfig.enableDictation();
    this._recognizer = new SpeechRecognizer(speechConfig, audioConfig);
    this._recognizer.recognizing = this._recognizer.recognized =
      this.recognizerCallback.bind(this);
    this._recognizer.startContinuousRecognitionAsync();
  }

  recognizerCallback(s, e) {
    const reason = ResultReason[e.result.reason];
    if (reason === 'RecognizingSpeech') {
      this.formControl.markAsDirty();
      this.formControl.setValue(
        (this.lastValue ? this.lastValue : '') +
          (this.isClearText
            ? ` ${this.lastRecognized + e.result.text}`
            : `<p>${this.lastRecognized + e.result.text}</p>`),
      );
      this.showVoiceIndicator = true;
      this.changeDetectorRef.detectChanges();
      this.resetTimer$.next();
    }
    if (reason === 'RecognizedSpeech') {
      this.showVoiceIndicator = false;
      this.changeDetectorRef.detectChanges();
      this.lastRecognized += e.result.text + ' ';
      timer(4000)
        .pipe(takeUntil(this.resetTimer$))
        .subscribe(() => {
          if (this.recognizing) {
            this.stop();
            this.recognizing = false;
          }
          this.lastRecognized = '';
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  stop(): void {
    this._recognizer.stopContinuousRecognitionAsync(
      stopRecognizer.bind(this),
      function (err) {
        stopRecognizer.bind(this);
        console.error(err);
      }.bind(this),
    );
    function stopRecognizer(): void {
      this._recognizer.close();
      this._recognizer = undefined;
      this.formControl.setValue(
        (this.formControl.value ? this.formControl.value : '') +
          (this.isClearText
            ? ` ${this.lastRecognized}`
            : `<p>${this.lastRecognized}</p>`),
      );
    }
  }
}
