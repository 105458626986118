/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import {
  AddPriceListCategory,
  AddPriceListItem,
  FullPriceList,
  PriceListCategory,
  PriceListItem,
} from 'app/shared/models';

export enum CurrentPriceListActionTypes {
  AddCurrentPriceListItem = '[PriceList] Add Current PriceListItem',
  AddCurrentPriceListItemSuccess = '[PriceList] Add Current PriceListItem Success',
  AddCurrentPriceListItemFailure = '[PriceList] Add Current PriceListItem Failure',
  EditCurrentPriceListItem = '[PriceList] Edit Current PriceListItem',
  EditCurrentPriceListItemSuccess = '[PriceList] Edit Current PriceListItem Success',
  EditCurrentPriceListItemFailure = '[PriceList] Edit Current PriceListItem Failure',
  AddCurrentPriceListCategory = '[PriceList] Add Current PriceListCategory',
  AddCurrentPriceListCategorySuccess = '[PriceList] Add Current PriceListCategory Success',
  AddCurrentPriceListCategoryFailure = '[PriceList] Add Current PriceListCategory Failure',
  EditCurrentPriceListCategory = '[PriceList] Edit Current PriceListCategory',
  EditCurrentPriceListCategorySuccess = '[PriceList] Edit Current PriceListCategory Success',
  EditCurrentPriceListCategoryFailure = '[PriceList] Edit Current PriceListCategory Failure',
  DeleteCurrentPriceListItemsConfirm = '[PriceList] Delete Current PriceListItems Confirm',
  DeleteCurrentPriceListItems = '[PriceList] Delete Current PriceListItems',
  DeleteCurrentPriceListItemsSuccess = '[PriceList] Delete Current PriceListItems Success',
  DeleteCurrentPriceListItemsFailure = '[PriceList] Delete Current PriceListItems Failure',
  DeleteCurrentPriceListCategoryConfirm = '[PriceList] Delete Current PriceListCategory Confirm',
  DeleteCurrentPriceListCategory = '[PriceList] Delete Current PriceListCategory',
  DeleteCurrentPriceListCategorySuccess = '[PriceList] Delete Current PriceListCategory Success',
  DeleteCurrentPriceListCategoryFailure = '[PriceList] Delete Current PriceListCategory Failure',
  GetCurrentPriceList = '[PriceList] Get Current PriceList',
  GetCurrentPriceListSuccess = '[PriceList] Get Current PriceList Success',
  GetCurrentPriceListFailure = '[PriceList] Get Current PriceList Failure',
  SelectCurrentPriceListItems = '[PriceList] Select Current PriceListItems',
  DeselectCurrentPriceListItems = '[PriceList] Deselect Current PriceListItems',
  DeleteSinglePriceListItem = '[PriceList] Delete single item',
  DeleteSinglePriceListItemSuccess = '[PriceList API] Delete single item Success',
  DeleteSinglePriceListItemFailure = '[PriceList API] Delete single item Failure',
  UpdateCurrentPriceListCategory = '[PriceList] Update CurrentPriceListCategory',
  UpdateCurrentPriceListCategorySuccess = '[PriceList API] Update CurrentPriceListCategory Success',
  UpdateCurrentPriceListCategoryFailure = '[PriceList API] Update CurrentPriceListCategory Failure',
}

export const deleteSinglePriceListItem = createAction(
  CurrentPriceListActionTypes.DeleteSinglePriceListItem,
  props<{ id: string }>(),
);

export const deleteSinglePriceListItemSuccess = createAction(
  CurrentPriceListActionTypes.DeleteSinglePriceListItemSuccess,
  props<{ id: string }>(),
);

export const deleteSinglePriceListItemFailure = createAction(
  CurrentPriceListActionTypes.DeleteSinglePriceListItemFailure,
  props<{ error: any }>(),
);

export const addCurrentPriceListItem = createAction(
  CurrentPriceListActionTypes.AddCurrentPriceListItem,
  props<{ item: AddPriceListItem }>(),
);

export const addCurrentPriceListItemSuccess = createAction(
  CurrentPriceListActionTypes.AddCurrentPriceListItemSuccess,
  props<{ item: PriceListItem }>(),
);

export const addCurrentPriceListItemFailure = createAction(
  CurrentPriceListActionTypes.AddCurrentPriceListItemFailure,
  props<{ error: any }>(),
);

export const editCurrentPriceListItem = createAction(
  CurrentPriceListActionTypes.EditCurrentPriceListItem,
  props<{ item: PriceListItem }>(),
);

export const editCurrentPriceListItemSuccess = createAction(
  CurrentPriceListActionTypes.EditCurrentPriceListItemSuccess,
  props<{ item: PriceListItem }>(),
);

export const editCurrentPriceListItemFailure = createAction(
  CurrentPriceListActionTypes.EditCurrentPriceListItemFailure,
  props<{ error: any }>(),
);

export const addCurrentPriceListCategory = createAction(
  CurrentPriceListActionTypes.AddCurrentPriceListCategory,
  props<{ category: AddPriceListCategory }>(),
);

export const addCurrentPriceListCategorySuccess = createAction(
  CurrentPriceListActionTypes.AddCurrentPriceListCategorySuccess,
  props<{ category: PriceListCategory }>(),
);

export const addCurrentPriceListCategoryFailure = createAction(
  CurrentPriceListActionTypes.AddCurrentPriceListCategoryFailure,
  props<{ error: any }>(),
);

export const editCurrentPriceListCategory = createAction(
  CurrentPriceListActionTypes.EditCurrentPriceListCategory,
  props<{ category: PriceListCategory }>(),
);

export const editCurrentPriceListCategorySuccess = createAction(
  CurrentPriceListActionTypes.EditCurrentPriceListCategorySuccess,
  props<{ category: PriceListCategory }>(),
);

export const editCurrentPriceListCategoryFailure = createAction(
  CurrentPriceListActionTypes.EditCurrentPriceListCategoryFailure,
  props<{ error: any }>(),
);

export const selectCurrentPriceListItems = createAction(
  CurrentPriceListActionTypes.SelectCurrentPriceListItems,
  props<{ ids: string[] }>(),
);

export const deselectCurrentPriceListItems = createAction(
  CurrentPriceListActionTypes.DeselectCurrentPriceListItems,
  props<{ ids: string[] }>(),
);

export const deleteCurrentPriceListItemsConfirm = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListItemsConfirm,
  props<{ items: PriceListItem[] }>(),
);

export const deleteCurrentPriceListItems = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListItems,
  props<{ items: PriceListItem[] }>(),
);

export const deleteCurrentPriceListItemsSuccess = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListItemsSuccess,
  props<{ items: PriceListItem[] }>(),
);

export const deleteCurrentPriceListItemsFailure = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListItemsFailure,
  props<{ error: any }>(),
);

export const deleteCurrentPriceListCategoryConfirm = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListCategoryConfirm,
  props<{ category: PriceListCategory }>(),
);

export const deleteCurrentPriceListCategory = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListCategory,
  props<{ category: PriceListCategory }>(),
);

export const deleteCurrentPriceListCategorySuccess = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListCategorySuccess,
  props<{ category: PriceListCategory }>(),
);

export const deleteCurrentPriceListCategoryFailure = createAction(
  CurrentPriceListActionTypes.DeleteCurrentPriceListCategoryFailure,
  props<{ error: any }>(),
);

export const getCurrentPriceList = createAction(
  CurrentPriceListActionTypes.GetCurrentPriceList,
);

export const getCurrentPriceListSuccess = createAction(
  CurrentPriceListActionTypes.GetCurrentPriceListSuccess,
  props<{ priceList: FullPriceList }>(),
);

export const getCurrentPriceListFailure = createAction(
  CurrentPriceListActionTypes.GetCurrentPriceListFailure,
  props<{ error: any }>(),
);

export const updateCurrentPriceListCategory = createAction(
  CurrentPriceListActionTypes.UpdateCurrentPriceListCategory,
  props<{ category: PriceListCategory[] }>(),
);

export const updateCurrentPriceListCategorySuccess = createAction(
  CurrentPriceListActionTypes.UpdateCurrentPriceListCategorySuccess,
  props<{ category: PriceListCategory[] }>(),
);

export const updateCurrentPriceListCategoryFailure = createAction(
  CurrentPriceListActionTypes.UpdateCurrentPriceListCategoryFailure,
  props<{ error: any }>(),
);
