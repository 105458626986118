<div class="w-full" *ngIf="variationFormService.quoteFormUpdated$ | async">
  <div class="text-secondary text-lg mb-4">Section</div>
  <div
    *ngIf="variationFormService.quoteSectionsForm.value?.sections.length"
    class="flex"
  >
    <div *ngIf="allowMultipleSections$ | async" class="min-w-80 w-80 mr-2.5">
      <ng-container
        *ngFor="
          let s of variationFormService.quoteSectionsForm.value?.sections;
          let sectionIndex = index
        "
      >
        <button
          *ngIf="selectedSectionIndex === sectionIndex"
          mat-flat-button
          (click)="selectedSectionIndex = sectionIndex"
          class="w-full rounded mb-2.5"
          [color]="'primary'"
        >
          <ng-container
            *ngTemplateOutlet="buttonContent; context: { $implicit: s }"
          >
          </ng-container>
        </button>
        <button
          *ngIf="selectedSectionIndex !== sectionIndex"
          mat-stroked-button
          (click)="selectedSectionIndex = sectionIndex"
          class="w-full rounded mb-2.5"
        >
          <ng-container
            *ngTemplateOutlet="buttonContent; context: { $implicit: s }"
          >
          </ng-container>
        </button>
      </ng-container>
    </div>
    <div class="w-full">
      <ng-container
        *ngIf="allowMultipleSections$ | async; else noMultipleSections"
      >
        <ng-container
          *ngFor="
            let s of variationFormService.quoteSectionsForm.value?.sections;
            let sectionIndex = index
          "
        >
          <div [hidden]="selectedSectionIndex !== sectionIndex">
            <ng-container
              *ngTemplateOutlet="
                elementTemplate;
                context: { $implicit: s, index: sectionIndex, isMultiple: true }
              "
            >
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #noMultipleSections>
        <ng-container
          *ngFor="
            let s of variationFormService.quoteSectionsForm.value?.sections;
            let sectionIndex = index
          "
        >
          <div *ngIf="sectionIndex === 0">
            <ng-container
              *ngTemplateOutlet="
                elementTemplate;
                context: {
                  $implicit: s,
                  index: sectionIndex,
                  isMultiple: false
                }
              "
            >
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #buttonContent let-element>
  <div
    class="flex items-center justify-between full-width min-w-80 w-80 pl-6 pr-5"
  >
    <span>
      {{ element.name }}
    </span>
    <span class="material-icons"> arrow_forward_ios </span>
  </div>
</ng-template>

<ng-template
  #elementTemplate
  let-element
  let-index="index"
  let-isMultiple="isMultiple"
>
  <ng-container [formGroup]="variationFormService.quoteSectionsForm">
    <ng-container formArrayName="sections">
      <ng-container [formGroupName]="index">
        <div class="grid sm:grid-cols-9 gap-3 w-full mb-6">
          <div *ngIf="isMultiple" class="sm:col-span-3">
            <div class="text-secondary text-lg mb-4">Section totals</div>
            <div class="mb-6">
              <mat-checkbox
                formControlName="showSectionTotal"
                [color]="'primary'"
              >
                Show total for section
              </mat-checkbox>
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="text-secondary text-lg mb-4">Categories</div>
            <div class="mb-6">
              <mat-checkbox
                formControlName="showCategories"
                [color]="'primary'"
              >
                Include categories
              </mat-checkbox>
            </div>
            <div class="pl-2.5">
              <mat-checkbox
                formControlName="showCategoryTotals"
                [color]="'primary'"
                [indeterminate]="
                  !variationFormService.quoteSectionsForm.value.sections[index]
                    .showCategories
                "
              >
                Show totals for categories
              </mat-checkbox>
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="text-secondary text-lg mb-4">Items</div>
            <div class="mb-6">
              <mat-checkbox formControlName="showItems" [color]="'primary'">
                Include items
              </mat-checkbox>
            </div>
            <div class="pl-2.5">
              <mat-checkbox
                formControlName="showItemTotals"
                [color]="'primary'"
                [indeterminate]="
                  !variationFormService.quoteSectionsForm.value.sections[index]
                    .showItems
                "
              >
                Show item totals
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div class="mb-2.5">
          <div class="flex items-center mb-2.5">
            <div class="text-secondary text-lg mr-2.5">Header</div>
            <a
              (click)="
                editDialog(
                  variationFormService.quoteSectionsForm.controls.sections
                    .controls[index].controls.sectionHeader,
                  'Header'
                )
              "
              class="text-primary cursor-pointer"
            >
              Click to edit
            </a>
          </div>
          <div
            [innerHtml]="
              variationFormService.quoteSectionsForm.value.sections[index]
                .sectionHeader
            "
            class="bg-primary-50 dark:bg-hover rounded dorder p-4 pb-8 border text-secondary"
          ></div>
        </div>
        <div class="mb-7">
          <div class="flex items-center mb-2.5">
            <div class="text-secondary text-lg mr-2.5">Footer</div>
            <a
              (click)="
                editDialog(
                  variationFormService.quoteSectionsForm.controls.sections
                    .controls[index].controls.sectionFooter,
                  'Footer'
                )
              "
              class="text-primary cursor-pointer"
            >
              Click to edit
            </a>
          </div>
          <div
            [innerHtml]="
              variationFormService.quoteSectionsForm.value.sections[index]
                .sectionFooter
            "
            class="bg-primary-50 dark:bg-hover rounded dorder p-4 pb-8 border text-secondary"
          ></div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
