/* eslint-disable arrow-parens */
import { AsyncPipe, Location, NgIf, NgFor, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { FuseMockApiUtils } from '@fuse/lib/mock-api';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { QuillEditorModalComponent } from 'app/shared/components';
import {
  EstimateStatus,
  Job,
  JobOrderParameters,
  JobStatus,
  JobType,
  TeamMember,
} from 'app/shared/models';
import { AccountUserFacade } from 'app/shared/store/facades';
import { EstimateStatusParams } from 'app/views/estimates/models/estimate-statuses';
import { JobRoute } from 'app/views/jobs/models';
import { JobStatusParams } from 'app/views/jobs/models/job-statuses';
import {
  JobCostingActionTypes,
  JobDetailFacade,
  JobNotesFacade,
  JobOrdersListFacade,
  JobOrderSummaryFacade,
  JobVariationDetailFacade,
} from 'app/views/jobs/store';
import moment from 'moment';
import { catchError, forkJoin, Observable, of, Subject } from 'rxjs';

import {
  acceptEstimateSuccess,
  EstimateActionTypes,
  EstimateDetailFacade,
  EstimateListFacade,
} from '../../../../estimates/store';
import {
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { CreateTemplateModalComponent, JobEditModalComponent } from '../..';
import { JobService } from '../../../services/job.service';
import { CreateOrderModalComponent } from '../../create-order-modal/create-order-modal.component';
import { BackNavigationService } from 'app/shared/services/back-navigation.service';
import { JobCallForwardsFacade } from '../job-call-forward/store/facades/job-callforward.facade';
import { ToastService } from '../../../../../shared/services/toast.service';
import { IntroJsService } from '../../../../../shared/services/introjs.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'app/shared/shared.module';
import { JobCostingFacade } from '@app/views/jobs/store/facades/job-costing.facade';
import { VariationsService } from '@app/views/jobs/services/variations.service';

@Component({
  selector: 'app-job-detail-container',
  templateUrl: './job-detail-container.component.html',
  styleUrls: ['./job-detail-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    SharedModule,
    RouterLink,
    MatTabsModule,
    NgFor,
    RouterLinkActive,
    RouterOutlet,
    AsyncPipe,
    DatePipe,
  ],
})
export class JobDetailContainerComponent implements OnInit, OnDestroy {
  jobType = JobType;
  jobId: string;
  estimateId: string;
  currentChild: string = '';
  url: string;
  activeAccount;
  routes = JobRoute as any;
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];
  nestedModulesAccess = {
    limitedOrdering: false,
    limitedInvoicing: false,
  };

  isLoaded$ = this.estimateDetailFacade.isLoaded$;
  job$ = this.facade.job$;
  title$ = this.facade.title$;

  // Hide menu if we are in estimate detail
  hideMenu$ = this.facade.currentUrl$.pipe(
    map((url) => {
      return (
        /(estimates\/.*){2}\//i.test(url) || /(variations\/.*)\//i.test(url)
      );
    }),
  );

  isEstimateEstimate = false;

  itemCount$ = this.estimateDetailFacade.itemCount$;

  canCreateTemplate$ = this.itemCount$.pipe(map((count) => count > 0));

  // +++
  menuItems$ = this.facade.job$.pipe(
    filter((job) => !!job),
    map((job) =>
      Object.keys(this.routes)
        .map((r: string) => ({
          text: this.routes[r],
          route: r,
        }))
        .filter(
          (i) =>
            (job.jobType === JobType.Job && i.text !== JobRoute.estimates) ||
            (job.jobType === JobType.Estimate &&
              i.text !== JobRoute.orders &&
              i.text !== JobRoute.invoices &&
              i.text !== JobRoute['call-forward'] &&
              i.text !== JobRoute.variations),
        )
        // filter JobDetail nav menu based on user roles
        .filter((i) => {
          const roles = this.asyncPipe.transform(
            this.userFacade.activeAccount$,
          ).roles;
          if (
            roles.length === 1 &&
            roles[0].name.toLocaleLowerCase() === 'owner'
          ) {
            return true;
          }
          if (job.jobType === JobType.Job) {
            if (i.text === JobRoute['call-forward']) {
              if (
                !roles.find((r) => r.description === JobRoute['call-forward'])
              ) {
                return false;
              }
            }
            if (i.text === JobRoute.orders) {
              if (!roles.find((r) => r.description === 'Ordering')) {
                return false;
              } else {
                this.nestedModulesAccess.limitedOrdering =
                  roles.find((r) => r.description === 'Ordering')
                    .accessLevel === 0;
              }
            }
            if (i.text === JobRoute.invoices) {
              if (!roles.find((r) => r.description === 'Invoicing')) {
                return false;
              } else {
                this.nestedModulesAccess.limitedInvoicing =
                  roles.find((r) => r.description === 'Invoicing')
                    .accessLevel === 0;
              }
            }
          }
          return true;
        }),
    ),
  );

  canResetToEstimate$ = this.facade.job$.pipe(
    filter((job) => !!job),
    switchMap((job) => {
      if (
        job.status === JobStatus.Job_Pre_Start &&
        this.route.snapshot.params['id'] === this.jobId
      ) {
        return this.jobService.canResetJob(this.jobId).pipe(
          map((r) => of(true)),
          catchError(() => of(false)),
        );
      } else {
        return of(false);
      }
    }),
  );

  canResetVariationToQuoted$;
  variationId: string;

  jobStatusesList = [
    {
      name: 'Pre Start',
      value: JobStatus.Job_Pre_Start,
    },
    {
      name: 'In progress',
      value: JobStatus.Job_In_Progress,
    },
    {
      name: 'On hold',
      value: JobStatus.Job_On_Hold,
    },
    {
      name: 'Completed',
      value: JobStatus.Job_Completed,
    },
    {
      name: 'Cancelled',
      value: JobStatus.Job_Cancelled,
    },
  ];
  jobCostingStatusesList = [
    {
      name: 'Pre Start',
      value: JobStatus.Job_Pre_Start,
    },
    {
      name: 'In progress',
      value: JobStatus.Job_In_Progress,
    },
    {
      name: 'On hold',
      value: JobStatus.Job_On_Hold,
    },
    {
      name: 'Completed',
      value: JobStatus.Job_Completed,
    },
    {
      name: 'Cancelled',
      value: JobStatus.Job_Cancelled,
    },
  ];
  estimateStatusesList = [
    {
      name: 'Draft',
      value: JobStatus.Estimate_Draft,
    },
    {
      name: 'Quoted',
      value: JobStatus.Estimate_Quoted,
    },
    {
      name: 'Rejected',
      value: JobStatus.Estimate_Rejected,
    },
    {
      name: 'Withdrawn',
      value: JobStatus.Estimate_Withdrawn,
    },
  ];

  estimatesEstimateStatusesList = [
    {
      name: 'Draft',
      value: EstimateStatus.Draft,
    },
    {
      name: 'Quoted',
      value: EstimateStatus.Quoted,
    },
    {
      name: 'Rejected',
      value: EstimateStatus.Rejected,
    },
    {
      name: 'Withdrawn',
      value: EstimateStatus.Withdrawn,
    },
    // {
    //   name: 'Accepted',
    //   value: EstimateStatus.Accepted
    // }
  ];

  unsubscriber$ = new Subject<void>();
  isVariationHasDraft = true;
  completeEstimateHasItems = false;
  JobStatus = JobStatus;

  constructor(
    private userFacade: AccountUserFacade,
    public facade: JobDetailFacade,
    public callForwardFacade: JobCallForwardsFacade,
    public notesFacade: JobNotesFacade,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private jobService: JobService,
    private variationService: VariationsService,
    private router: Router,
    private actionsSubj: ActionsSubject,
    private confirm: FuseConfirmationService,
    private jobOrdersListFacade: JobOrdersListFacade,
    public jobCostingFacade: JobCostingFacade,
    private location: Location,
    private jobOrderSummaryFacade: JobOrderSummaryFacade,
    public estimateDetailFacade: EstimateDetailFacade,
    private estimateListFacade: EstimateListFacade,
    public variationDetailFacade: JobVariationDetailFacade,
    private asyncPipe: AsyncPipe,
    public backNavigationService: BackNavigationService,
    private toast: ToastService,
    private introJsService: IntroJsService,
  ) {
    if (/(estimate-info$|quote\/settings$)/i.test(this.router.url)) {
      this.isEstimateEstimate = true;
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((event: NavigationEnd) => {
        if (/(estimate-info$|quote\/settings$)/i.test(this.router.url)) {
          this.isEstimateEstimate = true;
        } else {
          this.isEstimateEstimate = false;
        }
      });
  }

  ngOnInit(): void {
    this.introJsService.currentIntroPage$.next(null);

    this.estimateId = this.route.snapshot.firstChild.params['id'];
    const jobId = this.route.snapshot.params['id'];
    this.jobId = jobId;
    if (jobId) {
      this.facade.getJob(jobId);
    }
    this.facade.currentUrl$.subscribe((url: string) => (this.url = url));
    this.facade.activeRoute$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((url: string) => {
        if (url.split('?')) {
          this.currentChild = url.split('?')[0];
        } else {
          this.currentChild = url;
        }
      });

    this.userFacade.activeAccount$.subscribe(
      (r: TeamMember) => (this.activeAccount = r),
    );
    this.jobCostingFacade.jobVariation$
      .pipe(
        filter((jobVariation) => !!jobVariation),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((jobVariation) => {
        this.isVariationHasDraft = jobVariation.items.find(
          (variation) =>
            variation.status == EstimateStatus.Draft ||
            variation.status == EstimateStatus.Quoted,
        )
          ? true
          : false;
      });
    this.jobCostingFacade.jobCostingSingle$
      .pipe(
        filter((jobCosting) => !!jobCosting),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((jobCosting) => {
        this.completeEstimateHasItems = !!jobCosting.items.length;
      });
    this.actionsSubj
      .pipe(
        ofType(
          JobCostingActionTypes.UpdateJobCostingStatusSuccess,
          EstimateActionTypes.UpdateEstimateStatusSuccess,
        ),
        takeUntil(this.unsubscriber$),
      )
      .subscribe(() => {
        this.facade.getJob(this.jobId);
      });

    this.actionsSubj
      .pipe(ofType(acceptEstimateSuccess), takeUntil(this.unsubscriber$))
      .subscribe((response) => {
        if (response.type === '[Estimate List] Accept Estimates Success') {
          this.router.navigate(['jobs', response.estimate.jobId, 'info']);
        }
      });
  }

  createNewNote(id: string): void {
    this.dialog
      .open(QuillEditorModalComponent, {
        width: '800px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.notesFacade.addNote({
            id: FuseMockApiUtils.guid(),
            accountId: this.activeAccount.accountId,
            jobId: id,
            content: result,
            created: moment(),
            createdBy: this.activeAccount.accountId,
            updated: moment(),
            updatedBy: this.activeAccount.accountId,
            deleted: false,
          });
        }
      });
  }

  delete(id: string, isJob: boolean): void {
    this.confirm
      .open({
        title: `Delete ${isJob ? 'job' : 'estimate'}`,
        message: `Are you sure you want to delete this ${
          isJob ? 'job' : 'estimate'
        }? This action cannot be undone.`,
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Delete', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result: string) => result === 'confirmed'))
      .subscribe(() => {
        this.jobService.deleteJob(id).subscribe(() => this.back());
      });
  }

  edit(job: Job): void {
    const dialogRef = this.dialog.open(JobEditModalComponent, {
      width: '626px',
      data: job,
      maxHeight: '70vh',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.facade.getJob(result);
      }
    });
  }

  createNewInvoice(id: string): void {
    this.backNavigationService.url = `/jobs/${id}/invoices`;
    this.router.navigate([this.currentChild, 'view', 'new'], {
      relativeTo: this.route,
      queryParams: { jobId: id },
    });
  }

  back(): void {
    if (this.backNavigationService.url) {
      if (this.backNavigationService.url === this.router.url) {
        this.backNavigationService.url = this.router.url
          .split('/')
          .slice(0, -1)
          .join('/');
      }
      this.router.navigate([this.backNavigationService.url], {
        relativeTo: this.route,
      });
    } else {
      if (this.isEstimateEstimate) {
        this.location.back();
      } else {
        this.router.navigate(['../'], { relativeTo: this.route });
      }
    }
  }

  createOrder(): void {
    const data = {
      jobId: this.jobId,
    };
    this.dialog
      .open(CreateOrderModalComponent, {
        width: '626px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }
        const query: JobOrderParameters = {
          pageNumber: 1,
          pageSize: 9999,
          orderBy: [{ orderBy: 'orderCode', descending: false }],
          jobId: this.jobId,
        };

        if (result.all) {
          if (result.emailToSuppliers) {
            this.jobService.jobOrderBuildJobEmail(this.jobId).subscribe(() => {
              this.refreshData(query);
              this.toast.success('Sent successfully');
            });
            return;
          }
          this.jobService.jobOrderBuildJob(this.jobId).subscribe(() => {
            this.refreshData(query);
          });
        } else {
          if (result.suppliers.length) {
            if (result.emailToSuppliers) {
              const observables: Observable<any>[] = [];
              result.suppliers.forEach((s) => {
                observables.push(
                  this.jobService.jobOrderBuildJobSupplierEmail(
                    this.jobId,
                    s.supplierId,
                  ),
                );
              });
              forkJoin(observables).subscribe(() => {
                this.refreshData(query);
                this.toast.success('Sent successfully');
              });
              return;
            }

            const observables: Observable<any>[] = [];
            result.suppliers.forEach((s) => {
              observables.push(
                this.jobService.jobOrderBuildJobBySupplierAndCategory(
                  this.jobId,
                  s.supplierId,
                  s.priceListCategoryId,
                ),
              );
            });
            forkJoin(observables).subscribe(() => {
              this.refreshData(query);
            });
          }
        }
      });
  }

  refreshData(query) {
    this.jobOrdersListFacade.getJobsOrderList(query);
    this.jobOrderSummaryFacade.getJobsOrderSummary(this.jobId);
  }

  addSection(): void {
    this.estimateDetailFacade.addSection(this.estimateId);
  }

  acceptEstimate() {
    const type =
      this.currentChild === 'complete-estimate' ? 'Pre Start' : 'Estimate';
    this.confirm
      .open({
        title: `Accept ${type}`,
        message: `Are you sure you want to accept the ${type}? The status will be ${this.currentChild === 'complete-estimate' ? 'moved to' : 'marked as'} In Progress.`,
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'OK', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'confirmed'),
        takeUntil(this.unsubscriber$),
      )
      .subscribe(() => {
        if (this.currentChild === 'complete-estimate') {
          this.updateJobCostingStatus(50);
        } else {
          this.estimateListFacade.acceptEstimate(
            this.route.snapshot.firstChild.params['id'],
          );
        }
      });
  }

  addNewVariation(job): void {
    this.variationDetailFacade.addVariation(job.id);
  }

  createNewCallForwardItem() {
    // this.callForwardFacade.fireCreateNewEvent(true);
  }

  createNewTemplate(job: Job): void {
    if (this.asyncPipe.transform(this.estimateDetailFacade.editing$)) {
      this.confirm
        .open({
          title: 'Save changes?',
          message:
            'You have unsaved changes. The unsaved changes will not get to the new template. You will not loose any current changes and can keeep working on the Estimate. Do you want to proceed?',
          icon: {
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
          },
          actions: {
            cancel: { label: 'Cancel' },
            confirm: { label: 'Confirm', color: 'warn' },
          },
        })
        .afterClosed()
        .pipe(filter((result) => result === 'confirmed'))
        .subscribe(() => {
          this.showCreateNewTemplateDialog(job);
        });
    } else {
      this.showCreateNewTemplateDialog(job);
    }
  }

  showCreateNewTemplateDialog(job: Job) {
    this.dialog
      .open(CreateTemplateModalComponent, {
        width: '626px',
      })
      .afterClosed()
      .pipe(withLatestFrom(this.facade.acceptedEstimate$))
      .subscribe(([dialog, est]) => {
        if (dialog.name) {
          this.estimateDetailFacade.saveAsTemplate({
            accountId: job.accountId,
            estimateId:
              this.currentChild === 'estimate-info'
                ? this.url.split('/')[4]
                : est.estimate.id,
            name: dialog.name,
            description: dialog.description,
          });
        }
      });
  }

  updateJobStatus(status: JobStatus): void {
    if (this.isReadOnlyMode) return;
    const params: JobStatusParams = {
      id: this.jobId,
    };
    switch (status) {
      case JobStatus.Job_Cancelled:
        params.type = 'cancelled';
        break;
      case JobStatus.Estimate_Quoted:
        params.type = 'quoted';
        break;
      case JobStatus.Estimate_Draft:
        params.type = 'draft';
        break;
      case JobStatus.Job_Completed:
        params.type = 'completed';
        break;
      case JobStatus.Job_In_Progress:
        params.type = 'inprogress';
        break;
      case JobStatus.Job_On_Hold:
        params.type = 'onhold';
        break;
      case JobStatus.Estimate_Withdrawn:
        params.type = 'withdrawn';
        break;
      case JobStatus.Estimate_Rejected:
        params.type = 'rejected';
        break;
    }
    this.facade.updateJobStatus(params);
  }
  updateJobCostingStatus(status: JobStatus): void {
    this.router.navigate([], { queryParams: { isReadOnly: null } });
    if (this.isReadOnlyMode) return;
    const params: any = {
      id: this.asyncPipe.transform(this.jobCostingFacade.jobCostingSingle$).id,
    };
    switch (status) {
      case JobStatus.Job_Pre_Start:
        params.type = 'prestart';
        break;
      case JobStatus.Job_In_Progress:
        params.type = 'inprogress';
        break;
      case JobStatus.Job_Cancelled:
        params.type = 'cancel';
        break;
      case JobStatus.Estimate_Accepted:
        params.type = 'accepted';
        break;
      case JobStatus.Job_Completed:
        if (this.currentChild === 'complete-estimate') {
          this.router.navigate([], { queryParams: { isReadOnly: true } });
        }
        params.type = 'completed';
        break;
      case JobStatus.Job_On_Hold:
        params.type = 'onhold';
        break;
    }
    this.jobCostingFacade.updateStatus(params);
  }

  checkAndUpdateEstimateStatus(status: EstimateStatus): void {
    const hasChanges = this.asyncPipe.transform(this.estimateDetailFacade.editing$);
    if (hasChanges) {
      this.confirm
        .open({
          title: 'Save changes',
          message: 'You have unsaved changes.',
          icon: {
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
          },
          actions: {
            cancel: {label: 'Cancel'},
            confirm: {label: 'Save', color: 'warn'},
          },
        })
        .afterClosed()
        .pipe(filter((result) => result === 'confirmed'))
        .subscribe(() => {
          this.estimateDetailFacade.save();
          this.updateEstimateStatus(status);
        })
    } else {
      this.updateEstimateStatus(status);
    }
  }

  updateEstimateStatus(status: EstimateStatus): void {
    if (this.isReadOnlyMode) return;
    const params: EstimateStatusParams = {
      id: this.asyncPipe.transform(this.estimateDetailFacade.estimate$).id,
    };
    switch (status) {
      case EstimateStatus.Withdrawn:
        params.type = 'withdrawn';
        break;
      case EstimateStatus.Accepted:
        params.type = 'accepted';
        break;
      case EstimateStatus.Draft:
        params.type = 'draft';
        break;
      case EstimateStatus.Quoted:
        params.type = 'quote';
        break;
      case EstimateStatus.Rejected:
        params.type = 'rejected';
        break;
    }
    this.estimateDetailFacade.updateEstimateStatus(params);
  }

  backToEstimateStatus(): void {
    this.confirm
      .open({
        title: 'Transition from job to estimate',
        message: 'Are you sure you want to transition from job to estimate?',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'Cancel' },
          confirm: { label: 'Confirm', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(filter((result) => result === 'confirmed'))
      .subscribe(() => {
        this.jobService.resetJob(this.jobId).subscribe(
          (res) => {
            this.confirm
              .open({
                title: 'Transition from job to estimate',
                message:
                  'The job has been successfully changed back to an estimate',
                icon: {
                  name: 'heroicons_outline:check-circle',
                  color: 'success',
                },
                actions: {
                  cancel: {
                    show: false,
                  },
                  confirm: { label: 'OK', color: 'primary' },
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.router.navigate(['estimates', this.jobId, 'info']);
              });
          },
          (errorMessage) => {
            this.confirm.open({
              title: 'Error in transition from job to estimate',
              message: errorMessage.error.message,
              icon: {
                name: 'heroicons_outline:exclamation-triangle',
                color: 'warn',
              },
              actions: {
                cancel: {
                  show: false,
                },
                confirm: { label: 'OK', color: 'warn' },
              },
            });
          },
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
