import { SpeachToTextComponent } from 'app/shared/components';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { CustomerService } from '../../../views/customers/services/customer.service';
import {
  catchError,
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  AddressLookup,
  Customer,
  PaginatedListOfCustomer,
  Job,
  JobCloneType,
  PaginatedListOfJob,
  JobType,
  Template,
  PaginatedListOfTemplate,
  TeamMember,
  PaginatedListOfEstimate,
  Estimate,
} from 'app/shared/models';
import { STATES } from 'app/shared/constants/constants';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Observable, Subject, throwError } from 'rxjs';
import { JobService } from '../../../views/jobs/services/job.service';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { UtilService } from 'app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { IntroJsService } from 'app/shared/services/introjs.service';
import { MatSelectModule } from '@angular/material/select';
import { AddressLookupDirective } from '../../directives/address-lookup.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgStyle, NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-job-wizard-modal',
  templateUrl: './job-wizard-modal.component.html',
  styleUrls: ['./job-wizard-modal.component.scss'],
  standalone: true,
  imports: [
    NgStyle,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
    MatRadioModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    CustomerFormComponent,
    MatCheckboxModule,
    AddressLookupDirective,
    MatSelectModule,
    AsyncPipe,
    SpeachToTextComponent,
  ],
})
export class JobWizardModalComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  states = STATES;
  title: string;
  jobType;
  customers: Customer[];
  accountId: string;
  jobs$: Observable<Job[]>;
  estimates: Estimate[];
  templates$: Observable<Template[]>;
  isMobile = false;
  isScreenSmall = false;
  isWizardFinished = false;
  newCustomerFormGroup: FormGroup;

  nameGroup: FormGroup = this.fb.group({
    name: ['', Validators.required],
    description: [''],
  });
  customerGroup: FormGroup = this.fb.group({
    customer: ['', Validators.required],
    customerSearch: ['', Validators.required],
    customerCreationType: ['1'],
  });
  copyFromGroup = this.fb.group({
    jobCloneType: ['0'],
    template: [null],
    job: [null],
    estimate: [null],
  });
  addressGroup = this.fb.group({
    formattedAddress: [''],
    useCustomerAddress: [false],
    streetAddress: ['', Validators.required],
    suburb: ['', Validators.required],
    state: ['', Validators.required],
    postCode: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    latitude: [0],
    longitude: [0],
    locationType: [''],
    placeId: [''],
    country: [''],
  });

  form: FormGroup = this.fb.group({
    nameGroup: this.nameGroup,
    customerGroup: this.customerGroup,
    addressGroup: this.addressGroup,
    copyFromGroup: this.copyFromGroup,
  });

  get customerForm(): FormGroup {
    return this.customerGroup.get('customer') as FormGroup;
  }

  private unsubscribe$ = new Subject<void>();
  formattedAddress = '';
  constructor(
    public dialogRef: MatDialogRef<JobWizardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    public jobService: JobService,
    private accountUserFacade: AccountUserFacade,
    private cdr: ChangeDetectorRef,
    private fuseMediaWatcherService: FuseMediaWatcherService,
    private introJsService: IntroJsService,
  ) {
    this.title = this.data?.id ? `Edit ${data.title}` : `New ${data.title}`;
    this.jobType = JobType[data.title];
  }

  ngOnInit(): void {
    // Subscribe to media query change
    this.fuseMediaWatcherService
      .onMediaQueryChange$('(max-width: 440px)')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isMobile = state.matches;
        // Mark for check
        this.cdr.markForCheck();
      });

    this.fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    // fetch list of existing jobs for clone section
    this.jobs$ = this.accountUserFacade.activeAccount$.pipe(
      tap((acc: TeamMember) => (this.accountId = acc.accountId)),
      switchMap((acc: TeamMember) =>
        this.jobService.getJobs({
          pageNumber: 1,
          pageSize: 10000,
          accountId: this.accountId,
        }),
      ),
      shareReplay(1),
      takeUntil(this.unsubscribe$),
      map((r: PaginatedListOfJob) => r.items),
      catchError((err) => {
        return throwError(err);
      }),
    );

    // fetch list of existing templates for clone section
    this.templates$ = this.accountUserFacade.activeAccount$.pipe(
      switchMap((acc: TeamMember) =>
        this.jobService.getTemplates({
          pageNumber: 1,
          pageSize: 10000,
          accountId: acc.accountId,
        }),
      ),
      shareReplay(1),
      takeUntil(this.unsubscribe$),
      map((r: PaginatedListOfTemplate) => r.items),
      catchError((err) => {
        return throwError(err);
      }),
    );

    this.customerGroup
      .get('customerSearch')
      .valueChanges.pipe(
        filter(Boolean),
        debounceTime(300),
        shareReplay(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((r: string) => {
        this.customerService
          .getCustomers({
            orderBy: [{ orderBy: 'Person.FullName', descending: false }],
            filter: r,
            pageNumber: 1,
            pageSize: 1000,
          })
          .subscribe((rr: PaginatedListOfCustomer) => {
            this.customers = rr.items.filter((c) => c.person);
          });
      });

    this.customerGroup
      .get('customerCreationType')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((type: string) => this.manageCustomerCreationType(type));

    this.form
      .get('addressGroup')
      .get('useCustomerAddress')
      .valueChanges.subscribe((value: boolean) =>
        this.handleAddressForm(value),
      );

    this.addressGroup.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        const tmp = v;
        if (!tmp.country) {
          tmp.country = 'Australia';
        }
        tmp.formattedAddress = `${tmp.streetAddress.trim()}, ${tmp.suburb.trim()} ${
          tmp.state
        } ${tmp.postCode}, ${tmp.country}`;
        tmp.latitude = undefined;
        tmp.longitude = undefined;
        tmp.placeId = undefined;
        this.formattedAddress = tmp.formattedAddress;
        this.addressGroup.patchValue(tmp, { emitEvent: false });
      });

    this.copyFromGroup
      .get('jobCloneType')
      .valueChanges.subscribe((value: string) => this.handleCopyForm(value));

    //  fetch Estimates list for selected Estimate
    this.copyFromGroup
      .get('job')
      .valueChanges.pipe(
        filter((r) => Boolean(r)),
        switchMap((id: string) =>
          this.jobService.getEstimates({
            pageNumber: 1,
            pageSize: 10000,
            jobId: id,
            accountId: this.accountId,
          }),
        ),
        shareReplay(1),
        takeUntil(this.unsubscribe$),
        map((r: PaginatedListOfEstimate) => r.items),
        catchError((err) => {
          return throwError(err);
        }),
      )
      .subscribe((r: Estimate[]) => {
        this.estimates = r;
        this.copyFromGroup.get('estimate').patchValue(r[0].id);
      });
  }

  onNewCustomerInitialized(newCustomerFormGroup: FormGroup): void {
    this.newCustomerFormGroup = newCustomerFormGroup;
  }

  onCustomerChange(customer): void {
    this.form.get('customerGroup').get('customer').patchValue(customer);
  }

  addressSelected(address: AddressLookup): void {
    this.form.get('addressGroup').patchValue(address);
    this.form.get('addressGroup').markAsDirty();
    this.cdr.detectChanges();
  }

  manageCustomerCreationType(type: string): void {
    if (type === '1') {
      this.customerGroup.controls['customer'] = new FormControl(
        '',
        Validators.required,
      );
      this.customerGroup
        .get('customerSearch')
        .addValidators(Validators.required);
    } else if (type === '2') {
      this.customerGroup.get('customerSearch').patchValue('');
      this.customerGroup
        .get('customerSearch')
        .removeValidators(Validators.required);
      this.customerGroup.controls['customer'] = this.newCustomerFormGroup;
    }
    this.customerGroup.get('customerSearch').updateValueAndValidity();
  }

  customerHandler(): void {
    this.customerGroup.updateValueAndValidity();
    if (
      this.customerGroup.get('customerCreationType').value === '2' &&
      this.customerGroup.valid
    ) {
      const customer = this.customerGroup.value.customer;
      // remove id field to create a new instance and not to update existing
      if (customer.id) {
        delete customer.id;
      }
      customer.address = UtilService.transformAddress(customer.address);
      this.customerService.addCustomer(customer).subscribe((newCustomer) => {
        this.stepper.next();
        this.customerForm.addControl('id', new FormControl(''));
        this.customerForm.patchValue(newCustomer);

        // if case step back set lookup option by default and prefill created customer value in the input field
        this.customerGroup
          .get('customerCreationType')
          .patchValue('1', { emitEvent: false });
        this.customerGroup
          .get('customerSearch')
          .patchValue(
            `${customer.person.firstName} ${customer.person.lastName}`,
          );
      });
    }
  }

  save(redirectAfterSaving = false): void {
    const dto: any = {};
    if (!this.form.valid) {
      return;
    }
    this.isWizardFinished = true;
    // if useCustomerAddress === true, get address from customer address
    if (this.addressGroup.value.useCustomerAddress) {
      dto.address = this.customerGroup.value.customer.address;
    } else {
      dto.address = UtilService.transformAddress({
        ...this.addressGroup.value,
      });
    }
    dto.customer = this.customerForm.value;
    dto.jobType = this.jobType;
    dto.jobTitle = this.nameGroup.get('name').value;
    dto.description = this.nameGroup.get('description').value;
    dto.status = 10;
    // collect data from CopyOf section
    dto.cloneItemType = JobCloneType.None;
    dto.cloneItemId = null;
    // clone from template
    if (this.copyFromGroup.value.jobCloneType === '1') {
      dto.cloneItemType = JobCloneType.Template;
      dto.cloneItemId = this.copyFromGroup.value.template;
      // clone from Estimate/job
    } else if (this.copyFromGroup.value.jobCloneType === '2') {
      dto.cloneItemType = this.jobType;
      dto.cloneItemId =
        this.copyFromGroup.value.estimate || this.copyFromGroup.value.job;
    }

    this.jobService.addJob(dto).subscribe((job: Job) => {
      if (redirectAfterSaving) {
        if (this.data.intro) {
          this.introJsService.exitItroJs(this.data.intro);
        }
        this.router.navigateByUrl(`estimates/${job.id}/info`);
        this.dialogRef.close(false);
        return;
      }
      this.dialogRef.close(true);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleCopyForm(value): void {
    switch (value) {
      case '1': {
        this.copyFromGroup.get('template').addValidators(Validators.required);
        this.copyFromGroup.get('job').removeValidators(Validators.required);
        if (this.copyFromGroup.get('job').hasError('required')) {
          this.copyFromGroup.get('job').setErrors(null);
        }
        break;
      }
      case '2': {
        this.copyFromGroup.get('job').addValidators(Validators.required);
        this.copyFromGroup
          .get('template')
          .removeValidators(Validators.required);
        if (this.copyFromGroup.get('template').hasError('required')) {
          this.copyFromGroup.get('template').setErrors(null);
        }
        break;
      }
      default: {
        this.copyFromGroup
          .get('template')
          .removeValidators(Validators.required);
        this.copyFromGroup.get('template').setErrors(null);

        this.copyFromGroup.get('job').removeValidators(Validators.required);
        this.copyFromGroup.get('job').setErrors(null);
      }
    }
    this.copyFromGroup.updateValueAndValidity();
  }

  private handleAddressForm(hide: boolean): void {
    const fields = ['streetAddress', 'suburb', 'state', 'postCode'];
    const controls = this.addressGroup.controls;
    fields.forEach((field) => {
      if (hide) {
        controls[field].removeValidators(Validators.required);
        controls[field].patchValue(
          this.customerGroup.value.customer.address[field],
        );
        controls[field].disable();
      } else {
        controls[field].addValidators(Validators.required);
        controls[field].patchValue('');
        controls[field].enable();
      }
    });
  }
}
