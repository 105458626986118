<div>
  <h2 mat-dialog-title class="text-sm sm:text-base text-center">
    Confirm subscription changes
  </h2>
  <mat-dialog-content>
    <div>
      <p>New subscription cost: {{ changes.newSubscriptionPrice }}</p>
      <br />
      <ol *ngFor="let item of changes.newSubscriptionPriceItems">
        <li>{{ item }}</li>
      </ol>
      <br />
      <p>Amount due next payment: {{changes.total}} (may include prorated charges)</p>
      <p>Next billing date: {{changes.dueDate}}</p>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-stroked-button mat-dialog-close="Confirm" cdkFocusInitial>
      Confirm
    </button>
  </div>
</div>