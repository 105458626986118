import { Component, OnInit } from '@angular/core';
import { IntegrationsService } from '../../../services/integration.service';
import { IntegrationMethodCustomizationComponent } from '../integration-method-customization/integration-method-customization.component';
import { ConnectionStatusComponent } from '../connection-status/connection-status.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AsyncPipe, NgIf } from '@angular/common';
import { FuseConfigService } from '@fuse/services/config';
import { filter } from 'rxjs';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseAlertComponent } from '@fuse/components/alert';
import { AccountUserFacade } from '@app/shared/store/facades';

export interface XeroAccountDto {
  name: string;
  accountCode: string;
}

export interface XeroContactDto {
  contactId: string;
  name: string;
  firstName: string;
  lastName: string;
  isSupplier: boolean;
  isCustomer: boolean;
  email: string;
}
export interface XeroAccountSettingsDto {
  id: string;
  deleted: boolean;
  accountId: string;
  active: boolean;
  connectionActive: boolean;
  salesAccountCode: string;
  salesAccountName: string;
  purchaseOrderAccountCode: string;
  purhcaseOrderAccountName: string;
  bankAccountCode: string;
  bankAccountName: string;
  paymentSychronizationType: number;
  organisationShortCode: string;
}

export enum IntegrationType {
  Xero = 10,
  QuickBooks = 20,
  Myob = 30,
}

export interface TransactionHistoryDisplayDto {
  message: string;
  hasError: boolean;
  transactionTime: Date;
}

@Component({
  selector: 'app-xero',
  templateUrl: './xero.component.html',
  styleUrls: ['./xero.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatProgressBarModule,
    ConnectionStatusComponent,
    IntegrationMethodCustomizationComponent,
    FuseAlertComponent,
  ],
})
export class XeroComponent implements OnInit {
  isConnected: boolean = undefined;
  tenantName = '';
  syncStatus = 'Pending';
  isLoading = true;
  settings: XeroAccountSettingsDto;
  transactionHistory: TransactionHistoryDisplayDto[];
  xeroAccounts: XeroAccountDto[];
  isReadOnly =
    this.async
      .transform(this.accountUserFacade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingsintegrations' &&
          r.accessLevel === 0,
      ).length === 1;

  constructor(
    private integrationsService: IntegrationsService,
    private confirm: FuseConfirmationService,
    public accountUserFacade: AccountUserFacade,
    private async: AsyncPipe,
  ) {
    console.log('XeroComponent isReadOnly:::', this.isReadOnly);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.integrationsService.getXeroStatus().subscribe((r) => {
      console.log(r);
      this.isConnected = r.connected;
      this.tenantName = r.tenantName;
      if (this.isConnected) {
        this.integrationsService.getXeroSettings().subscribe((r) => {
          console.log('settings', r);
          this.settings = r;
          if (this.settings.connectionActive) {
            this.getTransactionHistory();
            this.getXeroAccounts();
          }
          this.isLoading = false;
        });
      }
      this.isLoading = false;
    });
  }

  connect() {
    console.log('connect');
    this.isLoading = true;
    this.integrationsService.connectToXero().subscribe((r) => {
      console.log(r);
      this.isLoading = false;
      //open in the same window
      window.location.href = r.url;
    });
  }

  disconnect() {
    console.log('disconnect');
    this.isLoading = true;
    this.integrationsService.disconnectFromXero().subscribe((r) => {
      console.log(r);
      this.isLoading = false;
      this.isConnected = !this.isConnected;

      this.accountUserFacade.loginAccountUser();
    });
  }

  getXeroAccounts() {
    this.integrationsService.getXeroAccounts().subscribe((r) => {
      console.log('xero accounts', r);
      this.xeroAccounts = r;
    });
  }

  getSettings() {
    this.integrationsService.getXeroSettings().subscribe((r) => {
      console.log('settings', r);
      this.settings = r;
    });
  }

  getTransactionHistory() {
    const topResultCount = 5;
    this.integrationsService
      .getTransactionHistory(topResultCount, IntegrationType.Xero)
      .subscribe((r) => {
        this.transactionHistory = r;

        if (this.transactionHistory.length > 0) {
          this.syncStatus = this.transactionHistory[0].hasError
            ? 'Error during last sync'
            : 'OK';
        } else {
          this.syncStatus = 'N/A';
        }
      });
  }

  onSettingChange(formValues) {
    console.log(formValues);
    this.isLoading = true;
    this.integrationsService
      .updateXeroSettings({
        ...this.settings,
        ...formValues,
      })
      .subscribe((r) => {
        console.log(r);
        this.isLoading = false;
        this.accountUserFacade.loginAccountUser();
      });
  }
}
