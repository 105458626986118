import { Pipe, PipeTransform } from '@angular/core';
import { Job, JobType } from '../models';

@Pipe({
  name: 'jobTitle',
  standalone: true,
})
export class JobTitlePipe implements PipeTransform {
  transform(job: Job): string {
    if (job) {
      return `${JobType[job.jobType]} #${
        job.jobType === JobType.Job ? job.jobCode : job.estimateCode
      } - ${job.jobTitle}`;
    }
  }
}
