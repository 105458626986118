<h2 mat-dialog-title class="text-sm sm:text-base">{{ title }}</h2>
<form [formGroup]="categoryForm" (ngSubmit)="(save)">
  <mat-dialog-content>
    <div class="flex space-x-4">
      <mat-form-field class="flex-1 w-35">
        <mat-label>Name</mat-label>
        <input
          trim="blur"
          formControlName="name"
          matInput
          maxlength="254"
          [autocomplete]="'off'"
        />
        <mat-error *ngIf="categoryForm.get('name').invalid"
          >Name required</mat-error
        >
      </mat-form-field>
      <mat-form-field class="flex-1 w-35">
        <mat-label>Description</mat-label>
        <input
          trim="blur"
          formControlName="description"
          matInput
          maxlength="254"
          [autocomplete]="'off'"
        />
        <mat-error *ngIf="categoryForm.get('description').invalid"
          >Description required</mat-error
        >
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="w-full flex flex-row justify-end">
    <button
      type="submit"
      mat-flat-button
      [color]="'primary'"
      (click)="save()"
      [disabled]="
        !categoryForm.valid ||
        (categoryForm.value.name.trim() === data.category?.name &&
          categoryForm.value.description.trim() === data.category?.description)
      "
    >
      Save
    </button>
    <button
      mat-stroked-button
      type="button"
      class="rounded-4xl px-8 mr-4"
      (click)="close()"
    >
      Cancel
    </button>
    </div>
  </mat-dialog-actions>
</form>
