import { AsyncPipe, NgIf, NgFor, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { Sort, MatSortModule } from '@angular/material/sort';
import { EstimatePriceListItem, PriceListCategory } from 'app/shared/models';
import { JobVariationAddRemoveCostingFacade } from 'app/views/jobs/store';
import { Subject, takeUntil, zip } from 'rxjs';
import { MatTableModule } from '@angular/material/table';
import { LetDirective } from '@ngrx/component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-remove-price-list-items-from-job-variation-costing-dialog',
  templateUrl:
    './remove-price-list-items-from-job-variation-costing-dialog.component.html',
  styleUrls: [
    './remove-price-list-items-from-job-variation-costing-dialog.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatButtonModule,
    MatDialogModule,
    NgFor,
    NgClass,
    MatTooltipModule,
    LetDirective,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    AsyncPipe,
  ],
})
export class RemovePriceListItemsFromJobVariationCostingDialogComponent
  implements OnInit, OnDestroy
{
  constructor(
    public dialogRef: MatDialogRef<any>,
    private facade: JobVariationAddRemoveCostingFacade,
    private async: AsyncPipe,
  ) {}

  displayedColumnsPriceList = [
    'selected',
    'itemcode',
    'description',
    'supplier',
    'qty',
    'uom',
    'unitPrice',
  ];
  displayedColumnsEstimateList = [
    'selected',
    'itemcode',
    'description',
    'supplier',
    'qty',
    'uom',
    'unitPrice',
  ];

  searchControl = new FormControl('');
  priceListItems$ = this.facade.priceListItems$;
  estimateListItems$ = this.facade.estimateListItems$;
  categories$ = this.facade.categories$;
  selectedCategoryId$ = this.facade.selectedCategoryId$;
  canAdd$ = this.facade.canAdd$;
  canRemove$ = this.facade.canRemove$;
  allPriceListItemsSelected$ = this.facade.allPriceListItemsSelected$;
  allEstimateListItemsSelected$ = this.facade.allEstimateListItemsSelected$;
  priceListSorting$ = this.facade.priceListSorting$;
  estimateListSorting$ = this.facade.estimateListSorting$;

  itemSelected$ = this.facade.itemSelected$;

  hasChanges$ = zip(this.facade.addedItems$, this.facade.removedItems$)
    .pipe(
      map(([a,b]) => a.concat(b)),
      map(results => results.length)
      )

  unsubscriber$ = new Subject<void>();

  sortPriceList(e: Sort) {
    this.facade.sortPriceListItems(
      e.active,
      e.direction === 'asc' ? 'asc' : 'desc',
    );
  }

  sortEstimateList(e: Sort) {
    this.facade.sortEstimateListItems(
      e.active,
      e.direction === 'asc' ? 'asc' : 'desc',
    );
  }

  remove() {
    this.facade.remove();
  }

  add() {
    this.facade.add();
  }

  selectCategory(category: PriceListCategory) {
    this.facade.selectCategory(category.id);
  }

  selectAllPriceListItems(ob: MatCheckboxChange) {
    if (ob.checked) {
      this.facade.selectAllPriceListItems();
    } else {
      this.facade.deselectAllPriceListItems();
    }
  }

  selectAllEstimateListItems(ob: MatCheckboxChange) {
    if (ob.checked) {
      this.facade.selectAllEstimateListItems();
    } else {
      this.facade.deselectAllEstimateListItems();
    }
  }

  toggleItemSelected(item: EstimatePriceListItem) {
    this.facade.toggleItemSelected(item.id);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const jobCostingSectionId = this.async.transform(this.facade.section$).id;
    const added = this.async.transform(this.facade.addedItems$);
    const removed = this.async.transform(this.facade.removedItems$);
    const data = { jobCostingSectionId, added, removed };
    this.dialogRef.close(data);
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((filter) => this.facade.setFilter(filter));
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
