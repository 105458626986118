import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TandC } from 'app/shared/models/eazimate.models';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class IndustrySelectorService {
  constructor(private http: HttpClient) {}

  checkPricelist(): Observable<any> {
    const url = `${environment.urls.api}/pricelist`;
    return this.http.get<any>(url, { observe: 'response' });
  }

  getIndustries(): Observable<any> {
    const url = `${environment.urls.api}/industry/list/industries`;
    return this.http.get<any>(url);
  }

  saveIndustry(id: string): Observable<any> {
    const url = `${environment.urls.api}/industry/save/industry/${id}`;
    return this.http.put<any>(url, {});
  }
}
