/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable arrow-parens */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FullEstimate, Job, JobCostingPriceListItem } from 'app/shared/models';
import { of } from 'rxjs';
import {
  catchError,
  distinctUntilKeyChanged,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  getAcceptedEstimate,
  getAcceptedEstimateFailure,
  getAcceptedEstimateSuccess,
  getJob,
  getJobFailure,
  getJobSuccess,
  getOriginalitems,
  getOriginalitemsFailure,
  getOriginalitemsSuccess,
  updateJob,
  updateJobFailure,
  updateJobStatus,
  updateJobStatusFailure,
  updateJobStatusSuccess,
  updateJobSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobDetailEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private router: Router,
  ) {}

  getJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJob),
      switchMap(({ id }) =>
        this.jobService.getJob(id).pipe(
          map((job: Job) => getJobSuccess({ job })),
          catchError((error) => {
            if (error.status === 500) {
              this.router.navigateByUrl('/error/500', {
                state: { error: error.message },
              });
            }
            if (error.status === 404) {
              this.router.navigateByUrl('/error/404', {
                state: { error: error.message },
              });
            }

            return of(getJobFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getAcceptedEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAcceptedEstimate),
      switchMap(({ id }) =>
        this.jobService.getAcceptedEstimate(id).pipe(
          map((estimate: FullEstimate) =>
            getAcceptedEstimateSuccess({ estimate }),
          ),
          catchError((error) => of(getAcceptedEstimateFailure({ error }))),
        ),
      ),
    ),
  );

  getOriginalitems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOriginalitems),
      switchMap(({ id }) =>
        this.jobService.getWorkitems(id).pipe(
          map((items: JobCostingPriceListItem[]) =>
            getOriginalitemsSuccess({ items }),
          ),
          catchError((error) => of(getOriginalitemsFailure({ error }))),
        ),
      ),
    ),
  );

  updateJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJob),
      switchMap(({ job }) =>
        this.jobService.updateJob(job).pipe(
          map((job: Job) => updateJobSuccess({ job })),
          catchError((error) => of(updateJobFailure({ error }))),
        ),
      ),
    ),
  );

  updateJobStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobStatus),
      switchMap(({ params }) =>
        this.jobService.updateJobStatus(params).pipe(
          map(() => updateJobStatusSuccess()),
          catchError((error) => of(updateJobStatusFailure({ error }))),
        ),
      ),
    ),
  );
}
