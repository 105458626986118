<div
  class="uppercase py-0.5 px-3 inline-block whitespace-no-wrap rounded-lg text-sm font-semibold"
  [ngClass]="{
    'text-red-800 bg-red-100 dark:text-red-50 dark:bg-red-500':
      status === jobOrderStatus.Void,
    'text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500':
      status === jobOrderStatus.Draft,
    'text-yellow-800 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-500':
      status === jobOrderStatus.Awaiting_Delivery,
    'text-green-800 bg-green-100 dark:text-green-50 dark:bg-green-500':
      status === jobOrderStatus.Received
  }"
>
  {{ text }}
</div>
