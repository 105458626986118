import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as supplierListReducer from './supplier-list.reducer';
import * as supplierReducer from './supplier-details.reducer';
import * as supplierPriceReducer from './supplier-price-list.reducer';

export interface SupplierState {
  supplierList: supplierListReducer.SupplierListState;
  supplier: supplierReducer.SupplierState;
  supplierPrice: supplierPriceReducer.SupplierPriceListItemState;
}

export const reducers: ActionReducerMap<SupplierState> = {
  supplierList: supplierListReducer.reducer,
  supplierPrice: supplierPriceReducer.reducer,
  supplier: supplierReducer.reducer,
};

export const getSupplierReviewState =
  createFeatureSelector<SupplierState>('supplier');
