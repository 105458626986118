import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';

import { AccountInvitesComponent } from 'app/layout/common/account-invites/account-invites.component';
import { SharedModule } from 'app/shared/shared.module';
import { AsyncPipe } from '@angular/common';
import { NewInviteDialogComponent } from './new-invite-dialog/new-invite-dialog.component';
@NgModule({
  imports: [
    RouterModule,
    OverlayModule,
    PortalModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatDialogModule,
    SharedModule,
    AccountInvitesComponent,
    NewInviteDialogComponent,
  ],
  exports: [AccountInvitesComponent],
  providers: [AsyncPipe],
})
export class AccountInvitesModule {}
