import { AccountUserGuard } from './account-user.guard';
import { LeavePageGuard } from './leave-page.guard';
import { RequiredGuard } from './required.guard';
import { TermsAndConditionsGuard } from './terms-and-conditions.guard';
import { IndustryGuard } from './industry.guard';
import { AdminPageGuard } from './admin-page.guard';

export const guards = [
  AccountUserGuard,
  RequiredGuard,
  LeavePageGuard,
  TermsAndConditionsGuard,
  IndustryGuard,
  AdminPageGuard,
];

export * from './account-user.guard';
export * from './required.guard';
export * from './leave-page.guard';
export * from './terms-and-conditions.guard';
export * from './industry.guard';
export * from './admin-page.guard';
