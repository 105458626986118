import { NgModule } from '@angular/core';
import { AccountsComponent } from 'app/layout/common/accounts/accounts.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';

import { SharedModule } from 'app/shared/shared.module';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    AccountsComponent,
  ],
  exports: [AccountsComponent],
})
export class AccountsModule {}
