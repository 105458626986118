import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { STATES } from 'app/shared/constants/constants';
import {
  AddressLookup,
  Customer,
  PaginatedListOfCustomer,
  Job,
} from 'app/shared/models';
import { UtilService } from 'app/shared/services';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { CustomerService } from '../../../customers/services/customer.service';
import { JobService } from '../../services/job.service';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { SpeachToTextComponent } from '../../../../shared/components/speach-to-text/speach-to-text.component';
import { MatSelectModule } from '@angular/material/select';
import { AddressLookupDirective } from '../../../../shared/directives/address-lookup.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgStyle, NgIf, NgFor } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'app-job-edit-modal',
  templateUrl: './job-edit-modal.component.html',
  styleUrls: ['./job-edit-modal.component.scss'],
  standalone: true,
  imports: [
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    NgIf,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    AddressLookupDirective,
    MatSelectModule,
    SharedModule,
    MatButtonModule,
    SpeachToTextComponent,
  ],
})
export class JobEditModalComponent implements OnInit, OnDestroy {
  states = STATES;
  customers: Customer[];
  job: Job;

  customerGroup: FormGroup = this.fb.group({
    customer: ['', Validators.required],
    customerSearch: ['', Validators.required],
  });
  addressGroup = this.fb.group({
    formattedAddress: [''],
    streetAddress: ['', Validators.required],
    suburb: ['', Validators.required],
    state: ['', Validators.required],
    postCode: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    latitude: [0],
    longitude: [0],
    locationType: [''],
    placeId: [''],
    country: [''],
  });

  form: FormGroup = this.fb.group({
    jobTitle: ['', Validators.required],
    description: [''],
    customerGroup: this.customerGroup,
    addressGroup: this.addressGroup,
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<JobEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private customerService: CustomerService,
    public jobService: JobService,
    private cdr: ChangeDetectorRef,
  ) {
    this.job = data;
  }

  ngOnInit(): void {
    this.fillForm();
    this.customerGroup
      .get('customerSearch')
      .valueChanges.pipe(filter(Boolean), debounceTime(300))
      .subscribe((r: string) => {
        if (this.customers?.find((c) => c.person.fullName === r)) {
          return;
        }
        this.customerGroup.get('customer').patchValue(null);
        this.customerGroup
          .get('customerSearch')
          .setErrors({ invalidInput: true });
        this.customerService
          .getCustomers({ firstName: r })
          .subscribe((rr: PaginatedListOfCustomer) => {
            this.customers = rr.items;
          });
      });
  }

  fillForm(): void {
    this.form.patchValue(this.job);
    this.customerGroup.get('customer').setValue(this.job.customer);
    this.customerGroup
      .get('customerSearch')
      .setValue(this.job.customer.person.fullName);
    this.addressGroup.patchValue(this.job.address);
    this.addressGroup.get('longitude').setValue(this.job.address.longitude);
    this.addressGroup.get('latitude').setValue(this.job.address.latitude);
  }

  onCustomerChange(customer): void {
    this.customerGroup.get('customer').patchValue(customer);
    this.customerGroup.get('customerSearch').setErrors(null);
  }

  addressSelected(address: AddressLookup): void {
    this.addressGroup.patchValue(address);
    this.cdr.detectChanges();
  }

  save(): void {
    if (this.form.invalid) {
      this.form.updateValueAndValidity();
      return;
    }

    const dto: any = {};
    dto.address = UtilService.transformAddress({
      ...this.addressGroup.value,
    });
    dto.customer = this.customerGroup.value.customer;
    dto.jobTitle = this.form.value.jobTitle;
    dto.description = this.form.value.description;
    dto.lastUpdated = {
      updatedDate: moment().toISOString(),
      updatedBy: this.job.accountId,
    };

    this.jobService.updateJob({ ...this.job, ...dto }).subscribe(() => {
      this.dialogRef.close(this.job.id);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
