import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { JobParameters, JobType } from 'app/shared/models';
import { clearJobs, getJobs, getRecentJobs } from '../actions';
import { JobListState } from '../reducers/job-list.reducer';
import {
  selectJobList,
  selectJobListError,
  selectJobListIsLoaded,
  selectJobListIsLoading,
  selectJobListPageNumber,
  selectJobListTotalCount,
  selectRecentJobs,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobListFacade {
  isLoading$ = this.store.select(selectJobListIsLoading);
  isLoaded$ = this.store.select(selectJobListIsLoaded);
  error$ = this.store.select(selectJobListError);
  jobs$ = this.store.select(selectJobList);
  recentJobs$ = this.store.select(selectRecentJobs);
  totalCount$ = this.store.select(selectJobListTotalCount);
  pageIndex$ = this.store.select(selectJobListPageNumber);

  constructor(private store: Store<JobListState>) {}

  getJobs(request: JobParameters): void {
    this.store.dispatch(getJobs({ request }));
  }

  getRecentJobs(jobType: JobType): void {
    this.store.dispatch(getRecentJobs({ jobType }));
  }

  clearJobListState() {
    this.store.dispatch(clearJobs());
  }
}
