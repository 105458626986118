import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeavePageService } from 'app/shared/services/leave-page.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuoteFormService {
  public quoteForm: FormGroup = this.fb.group({
    layoutId: null,
    logo: null,
    sent: null,
    footer: null,
    intro: null,
    tandC: null,
    validDays: [null, Validators.required],
    showCategories: null,
    showCategoryTotals: null,
    showItems: null,
    showItemTotals: null,
  });
  public quoteSectionsForm: FormGroup = this.fb.group({
    sections: this.fb.array([]),
  });
  private formReseting$ = new Subject();
  public changedSectionsIndexes: number[] = [];
  public formUpdated$ = new BehaviorSubject(false);
  public quoteFormUpdated$ = new BehaviorSubject(false);
  formChanged = false;
  quoteFormChanged = false;
  isReadOnlyMode;

  constructor(
    private fb: FormBuilder,
    private leavePageService: LeavePageService,
  ) {}

  buildForm(quoteData) {
    this.reset();
    this.quoteForm.patchValue(quoteData.quote);
    if (this.isReadOnlyMode) {
      Object.keys(this.quoteForm.controls).forEach((key) => {
        this.quoteForm.controls[key].disable({ onlySelf: true });
      });
    }
    if (!quoteData.quote.showCategories) {
      this.quoteForm.get('showCategoryTotals').disable();
    }
    if (!quoteData.quote.showItems) {
      this.quoteForm.get('showItemTotals').disable();
    }

    this.quoteForm.get('showCategories')
      .valueChanges.pipe(takeUntil(this.formReseting$))
      .subscribe((value) => {
        if (!value) {
          this.quoteForm.get('showCategoryTotals').setValue(false);
          this.quoteForm.get('showCategoryTotals').disable();
        } else {
          this.quoteForm.get('showCategoryTotals').enable();
        }
      });
    this.quoteForm.get('showItems')
      .valueChanges.pipe(takeUntil(this.formReseting$))
      .subscribe((value) => {
        if (!value) {
          this.quoteForm.get('showItemTotals').setValue(false);
          this.quoteForm.get('showItemTotals').disable();
        } else {
          this.quoteForm.get('showItemTotals').enable();
        }
      });

    this.formUpdated$.next(true);
  }

  buildQuoteSectionForm(sections) {
    // TODO remove this function later
    if (sections?.length) {
      (this.quoteSectionsForm.get('sections') as FormArray).clear();

      sections.forEach((section, index) => {
        let group;
        group = this.fb.group({
          name: null,
          id: null,
          showSectionTotal: null,
          showCategories: null,
          showCategoryTotals: null,
          showItems: null,
          showItemTotals: null,
          sectionFooter: null,
          sectionHeader: null,
        });

        if (this.isReadOnlyMode) {
          Object.keys(group.controls).forEach((key) => {
            group.controls[key].disable({ onlySelf: true });
          });
        } else {
          group
            .get('showCategories')
            .valueChanges.pipe(takeUntil(this.formReseting$))
            .subscribe((value) => {
              if (!value) {
                group.get('showCategoryTotals').setValue(false);
                group.get('showCategoryTotals').disable();
              } else {
                group.get('showCategoryTotals').enable();
              }
            });
          group
            .get('showItems')
            .valueChanges.pipe(takeUntil(this.formReseting$))
            .subscribe((value) => {
              if (!value) {
                group.get('showItemTotals').setValue(false);
                group.get('showItemTotals').disable();
              } else {
                group.get('showItemTotals').enable();
              }
            });
          if (!section.showCategories) {
            group.get('showCategoryTotals').disable();
          }
          if (!section.showItems) {
            group.get('showItemTotals').disable();
          }
        }

        group.patchValue(section, { emitEvent: false });
        group.valueChanges
          .pipe(take(1), takeUntil(this.formReseting$))
          .subscribe(() => this.changedSectionsIndexes.push(index));
        (this.quoteSectionsForm.get('sections') as FormArray).push(group);
      });
      this.quoteFormUpdated$.next(true);

      this.quoteSectionsForm.valueChanges
        .pipe(takeUntil(this.formReseting$))
        .subscribe(() => {
          this.leavePageService.needSave = true;
          this.quoteFormChanged = true;
        });
    }
  }

  reset(isDestroy?: boolean) {
    this.formReseting$.subscribe(() => {
      this.changedSectionsIndexes = [];
      this.quoteForm.reset({}, { emitEvent: false });
      this.quoteSectionsForm.reset({}, { emitEvent: false });
      this.leavePageService.needSave = false;
      this.formChanged = false;
      this.quoteFormChanged = false;
    });
    this.formUpdated$.next(false);
    this.formReseting$.next(null);
    if (isDestroy) {
      this.formReseting$.complete();
    }
  }
}
