import { JobCostingEffects } from './job-costing.effects';
import { JobDetailEffects } from './job-detail.effects';
import { JobHistoryEffects } from './job-history.effects';
import { JobInvoicesEffects } from './job-invoices.effects';
import { JobListEffects } from './job-list.effects';
import { JobNotesEffects } from './job-notes.effects';
import { JobOrderDetailsEffects } from './job-order-details.effects';
import { JobOrderItemsToOrderEffects } from './job-order-items-to-order.effects';
import { JobOrderListEffects } from './job-order-list.effects';
import { JobOrderPriceListItemEffects } from './job-order-price-list-item.effects';
import { JobOrderSummaryEffects } from './job-order-summary.effects';
import { JobVariationAddRemoveCostingEffects } from './job-variation-add-remove-costing-effects';
import { JobVariationDetailEffects } from './job-variation-detail.effects';
import { VariationLetterQuoteEffects } from './job-variation-letter.effects';
import { JobVariationsListEffects } from './job-variations-list.effects';
import { SuppliersListByJobDetailEffects } from './suppliers-list-by-job.effects';

export * from './job-costing.effects';
export * from './job-history.effects';
export * from './job-detail.effects';
export * from './job-invoices.effects';
export * from './job-list.effects';
export * from './job-notes.effects';
export * from './job-order-details.effects';
export * from './job-order-items-to-order.effects';
export * from './job-order-list.effects';
export * from './job-order-price-list-item.effects';
export * from './job-order-summary.effects';
export * from './job-variation-add-remove-costing-effects';
export * from './job-variation-detail.effects';
export * from './job-variation-letter.effects';
export * from './job-variations-list.effects';
export * from './suppliers-list-by-job.effects';

export const effects = [
  JobListEffects,
  JobDetailEffects,
  JobCostingEffects,
  JobHistoryEffects,
  JobNotesEffects,
  JobOrderListEffects,
  JobOrderDetailsEffects,
  JobOrderPriceListItemEffects,
  JobInvoicesEffects,
  SuppliersListByJobDetailEffects,
  JobOrderSummaryEffects,
  JobOrderItemsToOrderEffects,
  JobVariationsListEffects,
  JobVariationDetailEffects,
  VariationLetterQuoteEffects,
  JobVariationAddRemoveCostingEffects,
];
