import { NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper-dialog',
    templateUrl: './image-cropper-dialog.component.html',
    styleUrls: ['./image-cropper-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        ImageCropperComponent,
        NgIf,
    ],
})
export class ImageCropperDialogComponent {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    isLoaded = false;

    constructor(
        public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }

    imageLoaded(image: HTMLImageElement): void {
        console.log(image);
        this.isLoaded = true;
        // show cropper
    }

    cropperReady(): void {
        // cropper ready
    }

    loadImageFailed(): void {
        // show message
    }

    onOkClick(): void {
        if (!!this.imageChangedEvent.srcElement) {
            const imageName = this.imageChangedEvent.srcElement.value
                .split('\\')
                .pop();
            this.dialogRef.close({ image: this.croppedImage, imageName });
        }
    }
}
