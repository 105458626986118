import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeavePageService } from 'app/shared/services/leave-page.service';
import { JobDetailFacade, JobVariationDetailFacade } from 'app/views/jobs/store/facades';
import { filter, Subject, takeUntil } from 'rxjs';
import { FuseConfirmationService } from '../../../../../../../../@fuse/services/confirmation';
import { AsyncPipe, NgIf, NgClass, NgFor } from '@angular/common';
import { ofType } from '@ngrx/effects';
import { JobVariationActionTypes } from '../../../../../store';
import { ActionsSubject } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { JobVariationSectionComponent } from '../job-variation-section/job-variation-section.component';
import { JobVariationHeaderComponent } from '../job-variation-header/job-variation-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { VariationPriceListItemsComponent } from '../variation-price-list-items/variation-price-list-items.component';

@Component({
  selector: 'app-job-variation-detail-costing',
  templateUrl: './job-variation-detail-costing.component.html',
  styleUrls: ['./job-variation-detail-costing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    JobVariationHeaderComponent,
    NgClass,
    NgFor,
    JobVariationSectionComponent,
    VariationPriceListItemsComponent,
    AsyncPipe,
  ],
})
export class JobVariationDetailCostingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    public variationFacade: JobVariationDetailFacade,
    public leavePageService: LeavePageService,
    private confirm: FuseConfirmationService,
    private async: AsyncPipe,
    private actionsSubj: ActionsSubject,
    private route: ActivatedRoute,
    private jobDetailFacade: JobDetailFacade
  ) {}

  private unsubscriber$ = new Subject<void>();
  public variation$ = this.variationFacade.variation$;
  public isEditing = false;
  public isLoaded$ = this.variationFacade.isLoaded$;
  public readonly = true;
  public name = new FormControl();
  addedSectionName: string;

  save(): void {
    if (
      this.async
        .transform(this.variation$).sections.length) {
      this.variationFacade.save();
      return;
    }
    this.confirm
      .open({
        title: 'Save Variation',
        message:
          'A Variation Item has no items added yet. Are you sure you want to Save?',
        icon: {
          name: 'heroicons_outline:exclamation-triangle',
          color: 'warn',
        },
        actions: {
          cancel: { label: 'No' },
          confirm: { label: 'Yes', color: 'warn' },
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result === 'confirmed'),
        takeUntil(this.unsubscriber$),
      )
      .subscribe(() => {
        this.variationFacade.save();
      });
  }

  cancel(): void {
    this.variationFacade.reset();
  }

  onNameChange($event) {
    this.variationFacade.updateName($event.target.value);
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnInit(): void {
    this.variationFacade.editing$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((e) => {
        this.isEditing = e;
        this.leavePageService.needSave = e;
      });
    this.jobDetailFacade.job$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((job) => (this.readonly = job?.status === 100));

    this.variationFacade.variation$
      .pipe(filter(Boolean))
      .subscribe((variation) => {
        this.name.setValue(variation.name);
      });

    this.leavePageService.dialog = {
      title: 'Leave Page?',
      message: 'You have unsaved changes.',
      icon: {
        name: 'heroicons_outline:exclamation-triangle',
        color: 'warn',
      },
      actions: {
        cancel: { label: 'Cancel' },
        confirm: { label: 'Leave Page', color: 'warn' },
      },
    };

    this.actionsSubj
      .pipe(
        ofType(JobVariationActionTypes.AddSection),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((r: { name: string; type: string }) => {
        this.addedSectionName = r.name;
      });
  }

  ngAfterViewInit(): void {
    this.variationFacade.readonly$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((readonly) => {
        this.readonly = this.async.transform(this.jobDetailFacade.job$)?.status === 100 ||
          this.route.snapshot.data['userReadonlyMode'] || readonly;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.variationFacade.reset();
    this.leavePageService.needSave = false;
  }
}
