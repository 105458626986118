/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AsyncPipe, NgClass } from '@angular/common';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EstimateDetailFacade } from '../../store';

@Component({
  selector: 'app-estimate-header',
  templateUrl: './estimate-header.component.html',
  styleUrls: ['./estimate-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputMaskModule,  NgClass, AsyncPipe],
})
export class EstimateHeaderComponent implements OnInit, OnDestroy {
  @Input() isReadOnlyMode = false;
  @Input() defaultOnCost;
  estimateTotalsForm: FormGroup;
  currentOnCost: number;

  estimate$ = this.facade.estimate$;
  private unsubscriber$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private facade: EstimateDetailFacade,
    private cdr: ChangeDetectorRef
  ) {
    this.estimateTotalsForm = this.fb.group({
      unitTotal: [''],
      quotableUnitTotal: [''],
      netQuoteAmount: [''],
      onCost: ['', { validators: [Validators.required, Validators.min(0)] }],
      profitAmount: ['', [Validators.required]],
      quoteAmount: ['', [Validators.required]],
    });
  }

  updateValue(formControlName: string) {
    const value = +(this.estimateTotalsForm
      .get(formControlName)
      .value.toString().replace(/[^(\d|\\.)]/g, ''));
    switch (formControlName) {
      case 'onCost':
        this.facade.updateOnCost(value);
        break;
      case 'profitAmount':
        this.facade.updateProfit(value);
        break;
      case 'quoteAmount':
        this.facade.updateCustomerQuote(value);
        break;
    }
  }

  onCostInputMask = createMask({
    alias: 'numeric',
    digits: 2,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0',
  });

  currencyInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    digitsOptional: false,
    prefix: '$ ',
    placeholder: '0',
  });

  ngOnInit(): void {
    this.estimate$
      .pipe(
        takeUntil(this.unsubscriber$),
        filter((estimate) => !!estimate),
      )
      .subscribe((estimate) => {

        this.estimateTotalsForm.patchValue({
          ...estimate,
          unitTotal: `$ ${estimate.unitTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          profitAmount: `$ ${estimate.profitAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          quoteAmount: `$ ${estimate.quoteAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        });
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
