/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { IndustrySelectorService } from '../services/industry-selector.service';

@Injectable({
  providedIn: 'root',
})
export class IndustryGuard implements CanActivate {
  private url = '/industry-selector';

  constructor(
    private api: IndustrySelectorService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  checkPriceList$ = (): Observable<boolean> => {
    return this.api.checkPricelist().pipe(
      map((response) => {
        if (response.status === 204) {
          this.router.navigate([this.url]);
          return false;
        }
        return true;
      }),
    );
  };

  checkData = (): Observable<boolean> =>
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap((_) => this.checkPriceList$()),
    );

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkData();
  }
}
