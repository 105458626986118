import { createReducer, on } from '@ngrx/store';
import { getCommonDataSuccess } from '../actions';

export interface CommonDataState {
  jobStatuses: { id: number; name: string }[];
  estimateStatuses: { id: number; name: string }[];
}

export const initialState: CommonDataState = {
  jobStatuses: [],
  estimateStatuses: [],
};

export const commonDataReducer = createReducer(
  initialState,
  on(getCommonDataSuccess, (state, { jobStatuses, estimateStatuses }) => ({
    ...state,
    jobStatuses,
    estimateStatuses,
  })),
);
