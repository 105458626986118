<button
  class="w-10 h-10 relative"
  mat-icon-button
  [color]="recognizing ? 'warn' : 'primary'"
  (click)="startButton()"
>
  <mat-icon *ngIf="recognizing">mic</mat-icon>
  <mat-icon *ngIf="!recognizing">mic_off</mat-icon>
  <div
    *ngIf="showVoiceIndicator"
    class="w-8 h-8 pointer-events-none absolute border-4 border-emerald-700 border-solid rounded-full animate-ping"
  ></div>
</button>
