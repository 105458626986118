import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";

export interface ExtraSeatConfirmDialogData {
  changes: any;
  accountCancelled: boolean;
}
@Component({
  selector: 'app-extra-seat-cofirm-dialog',
  standalone: true,
  imports: [MatDialogModule, NgFor, NgIf, NgClass, MatButtonModule, MatListModule],
  templateUrl: './extra-seat-confirm-dialog.html',
  styleUrl: './extra-seat-confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtraSeatCofirmDialogComponent {

  changes: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ExtraSeatConfirmDialogData,
    private dialogRef: MatDialogRef<ExtraSeatCofirmDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.changes = this.data;
  }
}
