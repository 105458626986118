import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccountUserFacade } from 'app/shared/store/facades';

@Component({
  selector: 'app-myob',
  templateUrl: './myob.component.html',
  styleUrls: ['./myob.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class MyobComponent implements OnInit {
  isConnected: boolean = false;

  isReadOnly =
    this.async
      .transform(this.accountUserFacade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingsbilling' &&
          r.accessLevel === 0,
      ).length === 1;

  constructor(
    public accountUserFacade: AccountUserFacade,
    private async: AsyncPipe,
  ) {}

  ngOnInit(): void {}

  connect() {
    console.log('connect');
  }
}
