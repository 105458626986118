import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models';

@Pipe({
  name: 'address',
  standalone: true,
})
export class AddressPipe implements PipeTransform {
  transform(value: Address): string {
    return null;
    // let parts = [];
    // if (value.addressLine1) {
    //     parts.push(value.addressLine1.trim())
    // }
    // if (value.addressLine2) {
    //     parts.push(value.addressLine2.trim())
    // }
    // let address = parts.join(", ").trim();
    // parts = [];
    // if (value.suburb) {
    //     parts.push(value.suburb.toUpperCase().trim());
    // }
    // if (value.state) {
    //     parts.push(value.state.toUpperCase().trim());
    // }
    // if (value.postcode) {
    //     parts.push(value.postcode.trim());
    // }
    // address += " " + parts.join(" ");
    // return address.trim();
  }
}
