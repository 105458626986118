import { Injectable } from '@angular/core';
import {
  selectCallForwardItemNotes,
  selectCreateNew,
  selectEditIndex,
  selectIsNew,
  selectJobCallForwardError,
  selectJobCallForwardIsLoaded,
  selectJobCallForwardIsLoading,
  selectJobCallForwardItems,
  selectJobCallForwardItemsPageNumber,
  selectJobCallForwardItemStatuses,
  selectJobCallForwardItemsTotalCount,
  selectJobCallForwardItemTypes,
} from '../selectors/job-callforward.selectors';
import { Store } from '@ngrx/store';
import { JobCallForwardsState } from '../reducers/job-callforward.reducer';
import {
  AddCallForwardItem,
  AddCallForwardItemNote,
  CallForwardItem,
  CallForwardItemNote,
  CallForwardItemNoteParameters,
  CallForwardItemParameters,
} from 'app/shared/models/eazimate.models';
import {
  addCallForwardItem,
  addCallForwardItemNote,
  clearCallForwardsStore,
  createNew,
  deleteCallForwardItem,
  deleteCallForwardItemNote,
  editIndex,
  getCallForwardItemNotes,
  getJobCallForwardItems,
  getJobCallForwardItemStatuses,
  getJobCallForwardItemTypes,
  isNew,
  updateCallForwardItem,
  updateCallForwardItemNote,
  updateCallForwardItemStatus,
} from '../actions/job-callforward.actions';

@Injectable({
  providedIn: 'root',
})
export class JobCallForwardsFacade {
  isLoading$ = this.store.select(selectJobCallForwardIsLoading);
  isLoaded$ = this.store.select(selectJobCallForwardIsLoaded);
  error$ = this.store.select(selectJobCallForwardError);
  editIndex$ = this.store.select(selectEditIndex);
  isNew$ = this.store.select(selectIsNew);
  createNew$ = this.store.select(selectCreateNew);
  callforwardsItems$ = this.store.select(selectJobCallForwardItems);
  totalCount$ = this.store.select(selectJobCallForwardItemsTotalCount);
  pageIndex$ = this.store.select(selectJobCallForwardItemsPageNumber);
  itemTypes$ = this.store.select(selectJobCallForwardItemTypes);
  itemStatuses$ = this.store.select(selectJobCallForwardItemStatuses);
  callforwardItemsNotes$ = this.store.select(selectCallForwardItemNotes);

  constructor(private store: Store<JobCallForwardsState>) {}

  // getJobCallForward(query: CallForwardParameters): void {
  //   this.store.dispatch(getJobCallForward({ query }));
  // }

  getJobCallForwardItems(query: CallForwardItemParameters): void {
    this.store.dispatch(getJobCallForwardItems({ query }));
  }

  deleteCallForward(id: string) {
    this.store.dispatch(deleteCallForwardItem({ id }));
  }

  addCallForward(payload: AddCallForwardItem) {
    this.store.dispatch(addCallForwardItem({ payload }));
  }
  updateCallForward(payload: CallForwardItem) {
    this.store.dispatch(updateCallForwardItem({ payload }));
  }

  getItemTypes() {
    this.store.dispatch(getJobCallForwardItemTypes());
  }

  getItemStatuses() {
    this.store.dispatch(getJobCallForwardItemStatuses());
  }
  fireEditIndexEvent(index) {
    this.store.dispatch(editIndex({ index }));
  }
  fireIsNewEvent(value) {
    this.store.dispatch(isNew({ value }));
  }
  fireCreateNewEvent(value) {
    this.store.dispatch(createNew({ value }));
  }

  // notes
  getCallForwardItemNotes(query: CallForwardItemNoteParameters): void {
    this.store.dispatch(getCallForwardItemNotes({ query }));
  }

  addCallForwardItemNotes(payload: AddCallForwardItemNote): void {
    this.store.dispatch(addCallForwardItemNote({ payload }));
  }
  deleteCallForwardItemNote(id: string): void {
    this.store.dispatch(deleteCallForwardItemNote({ id }));
  }
  updateCallForwardItemNotes(payload: CallForwardItemNote): void {
    this.store.dispatch(updateCallForwardItemNote({ payload }));
  }
  updateCallForwardItemStatus(cfId: string, statusId: number): void {
    this.store.dispatch(updateCallForwardItemStatus({ cfId, statusId }));
  }

  clearStore() {
    this.store.dispatch(clearCallForwardsStore());
  }
}
