<div
  class="xs:absolute xs:inset-0 flex flex-col flex-auto min-w-0 xs:overflow-hidden"
  *ngIf="isLoaded$ | async"
>
  <div class="justify-start pb-2.5 mb-2.5 border-b" *ngIf="!readonly">
    <button
      class="order-5 xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
      [disabled]="isEditing === false || name.value.trim().length === 0"
      (click)="save()"
      mat-flat-button
      color="primary"
    >
      <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
      <span class="xs:hidden sm:block ml-2 mr-1">Save</span>
    </button>
    <button
      class="order-5 xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
      [disabled]="isEditing === false"
      (click)="cancel()"
      mat-flat-button
      color="accent"
    >
      <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
      <span class="xs:hidden sm:block ml-2 mr-1">Cancel</span>
    </button>
  </div>
  <div class="px-2.5 w-full flex flex-row">
    <mat-form-field class="w-1/2">
      <mat-label>
        {{
          (variationFacade.isOriginal$ | async) === true
            ? "Estimate"
            : "Variation"
        }}
        Description
      </mat-label>
      <input
        [formControl]="name"
        [readonly]="readonly"
        (input)="onNameChange($event)"
        type="text"
        maxlength="254"
        matInput
      />
    </mat-form-field>
  </div>
  <div class="px-2.5 w-full overflow-auto h-full flex flex-col">
    <!-- Variation Header -->
    <div class="w-full flex flex-row items-center">
      <app-job-variation-header
        [readonly]="readonly"
      ></app-job-variation-header>
    </div>

    <div class="flex flex-col py-3" [ngClass]="{ 'h-full': section$ | async }">
      <ng-container *ngIf="(variation$ | async)?.sections as sections">
        <div
          *ngFor="let section of sections; trackBy: trackByFn; let i = index"
        >
          <app-job-variation-section
            [index]="i"
            [section]="section"
            [showManegeItem]="false"
            [singleMode]="sections.length === 1"
            [expanded]="
              addedSectionName ? addedSectionName === section.name : i === 0
            "
            [variation]="variation$ | async"
            [canMoveUp]="i > 0"
            [canMoveDown]="i < sections.length - 1"
            [readonly]="readonly"
          >
          </app-job-variation-section>
        </div>
      </ng-container>
    </div>
  </div>
</div>
