<!-- Messages toggle -->
<button #messagesOrigin (click)="openPanel()" mat-icon-button>
  <ng-container *ngIf="(af.invitedAccounts$ | async).length > 0">
    <span
      class="absolute top-0 right-0 left-0 flex items-center justify-center h-3"
    >
      <span
        class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium"
      >
        {{ (af.invitedAccounts$ | async).length }}
      </span>
    </span>
  </ng-container>
  <mat-icon [svgIcon]="'heroicons_outline:user'"></mat-icon>
</button>

<!-- Messages panel -->
<ng-template #messagesPanel>
  <div
    class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
  >
    <!-- Header -->
    <div
      class="flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary"
    >
      <div class="sm:hidden -ml-1 mr-3">
        <button (click)="closePanel()" mat-icon-button>
          <mat-icon
            class="icon-size-5 text-current"
            [svgIcon]="'heroicons_solid:x-mark'"
          ></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">Invitation</div>
    </div>

    <!-- Content -->
    <div
      class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card"
    >
      <!-- <pre>{{ af.invitedAccounts$ | async | json }}</pre> -->
      <!-- Messages -->
      <ng-container
        *ngFor="let invite of af.invitedAccounts$ | async; trackBy: trackByFn"
      >
        <div
          class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 cursor-pointer"
          (click)="handleIntiteSelected(invite)"
        >
          <!-- Message without a link -->
          <ng-container>
            <div class="flex flex-auto py-5 pl-6">
              <ng-container *ngTemplateOutlet="inviteContent"></ng-container>
            </div>
          </ng-container>
        </div>

        <!-- Message content template -->
        <ng-template #inviteContent>
          <!-- Title, description & time -->
          <div class="flex flex-col flex-auto">
            <ng-container>
              <div
                class="line-clamp-2"
                [innerHTML]="'You were invited to join an account'"
              ></div>
              <div
                class="indent-6 line-clamp-2 font-semibold mt-2"
                [innerHTML]="
                  'Account name: ' + invite?.accountName || invite?.accountEmail
                "
              ></div>
              <!-- <div
                class="indent-6 line-clamp-2 font-semibold"
                [innerHTML]="getMessage(invite)"
              ></div> -->
            </ng-container>
            <div class="mt-2 text-sm leading-none text-secondary">
              {{ invite?.invitedDate | date: "MMM dd, h:mm a" }}
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-template>
