<div class="container absolute overflow-hidden w-full h-full px-6 pb-6">
  <div
    class="h-full overflow-auto flex md:flex-row xs:flex-col"
    *ngIf="job$ | async as job"
  >
    <div class="left-side flex-col xs:w-full md:w-1/2 mb-2">
      <mat-card class="sm:mb-4 xs:mb-2">
        <p class="sm:text-2xl xs:text-xl leading-tight sm:mb-4 xs:mb-2">
          {{ title$ | async }}
        </p>

        <p class="sm:text-md sm:mb-4 xs:text-sm xs:mb-2">
          Site Address: {{ job?.address?.streetAddress }},
          {{ job?.address?.suburb }}, {{ job?.address?.state }} ,
          {{ job?.address?.postCode }}
        </p>
        <p class="sm:text-md xs:text-sm">
          Customer: {{ job?.customer?.person?.fullName }}
        </p>
        <p class="sm:text-md xs:text-sm">
          Phone: {{ job.customer?.person?.phone }}
        </p>
        <p class="sm:text-md xs:text-sm">
          Email: {{ job?.customer?.person?.email }}
        </p>
      </mat-card>

      <mat-card class="sm:mb-4 xs:mb-2" *ngIf="job?.description">
        <p class="sm:text-xl xs:text-md sm:mb-4 xs:mb-2">Description</p>
        <p class="sm:text-md xs:text-sm">{{ job?.description }}</p>
      </mat-card>

      <app-google-map
        *ngIf="job.address.latitude && job.address.longitude"
        [longitude]="job.address.longitude"
        [latitude]="job.address.latitude"
        [height]="'30vh'"
      ></app-google-map>
    </div>

    <div class="right-side xs:w-full md:w-1/2 xs:ml-0 md:ml-4">
      <mat-card class="sm:mb-4 xs:mb-2 flex flex-row items-center">
        <div class="date-info w-26 text-center">
          {{ job?.updated | date: "d MMM YYYY h:mm a" }}
        </div>
        <div
          class="flex items-center justify-center rounded-full md:w-12 md:h-12 xs:w-10 xs:h-10 bg-primary mx-2"
        >
          <mat-icon
            class="text-white w-8 h-8"
            svgIcon="heroicons_solid:check"
          ></mat-icon>
        </div>
        <div class="text-label">Last updated</div>
      </mat-card>
      <mat-card class="sm:mb-4 xs:mb-2 flex flex-row items-center">
        <div class="date-info w-26 text-center">
          {{ job?.created | date: "d MMM YYYY h:mm a" }}
        </div>
        <div
          class="flex items-center justify-center rounded-full md:w-12 md:h-12 xs:w-10 xs:h-10 bg-primary mx-2"
        >
          <mat-icon
            class="text-white w-8 h-8"
            svgIcon="heroicons_solid:check"
          ></mat-icon>
        </div>
        <div class="text-label">Created</div>
      </mat-card>

    </div>
  </div>
</div>
