import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TransactionHistoryDisplayDto } from '../xero/xero.component';

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class ConnectionStatusComponent implements OnInit, OnChanges {
  @Input() connectionStatus: string;
  @Input() syncStatus: string;
  @Input() data: TransactionHistoryDisplayDto[];
  constructor() {}

  displayedColumns: string[] = [];

  public defaultSort: Sort = { active: '_ts', direction: 'desc' };
  dataSource: MatTableDataSource<TransactionHistoryDisplayDto>;

  ngOnInit(): void {
    this.displayedColumns = ['transactionTime', 'message'];
  }

  ngOnChanges() {
    if (this.data) this.dataSource = new MatTableDataSource(this.data);
  }
}
