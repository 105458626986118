import { createAction, props } from '@ngrx/store';
import {
  EstimatePriceListItem,
  SingleSaveVariation,
  SingleSaveVariationSection,
} from 'app/shared/models';

export enum JobVariationAddRemoveCostingActionTypes {
  ShowDialog = '[Job Variation Add Remove Costing] Load',
  ToggleItemSelected = '[Job Variation Add Remove Costing] Toggle Item Selected',
  SelectAllPriceListItems = '[Job Variation Add Remove Costing] Select All Price List Items',
  DeselectAllPriceListItems = '[Job Variation Add Remove Costing] Deselect All Price List Items',
  SelectAllEstimateListItems = '[Job Variation Add Remove Costing] Select All Estimate List Items',
  DeselectAllEstimateListItems = '[Job Variation Add Remove Costing] Deselect All Estimate List Items',
  SelectCategory = '[Job Variation Add Remove Costing] Select Category',
  AddSelectedItems = '[Job Variation Add Remove Costing] Add Selected Items',
  RemoveSelectedItems = '[Job Variation Add Remove Costing] Remove Selected Items',
  FilterItems = '[Job Variation Add Remove Costing] Filter Items',
  SortPriceListItems = '[Job Variation Add Remove Costing] Sort Price List Items',
  SortEstimateListItems = '[Job Variation Add Remove Costing] Sort Estimate List Items',
}

export const sortPriceListItems = createAction(
  JobVariationAddRemoveCostingActionTypes.SortPriceListItems,
  props<{ sortField: string; sortOrder: 'asc' | 'desc' }>(),
);

export const sortEstimateListItems = createAction(
  JobVariationAddRemoveCostingActionTypes.SortEstimateListItems,
  props<{ sortField: string; sortOrder: 'asc' | 'desc' }>(),
);

export const filterItems = createAction(
  JobVariationAddRemoveCostingActionTypes.FilterItems,
  props<{ filter: string }>(),
);

export const removeSelectedItems = createAction(
  JobVariationAddRemoveCostingActionTypes.RemoveSelectedItems,
);

export const addSelectedItems = createAction(
  JobVariationAddRemoveCostingActionTypes.AddSelectedItems,
);

export const selectCategory = createAction(
  JobVariationAddRemoveCostingActionTypes.SelectCategory,
  props<{ id: string }>(),
);

export const selectAllPriceListItems = createAction(
  JobVariationAddRemoveCostingActionTypes.SelectAllPriceListItems,
);

export const deselectAllPriceListItems = createAction(
  JobVariationAddRemoveCostingActionTypes.DeselectAllPriceListItems,
);

export const selectAllEstimateListItems = createAction(
  JobVariationAddRemoveCostingActionTypes.SelectAllEstimateListItems,
);

export const deselectAllEstimateListItems = createAction(
  JobVariationAddRemoveCostingActionTypes.DeselectAllEstimateListItems,
);

export const toggleItemSelected = createAction(
  JobVariationAddRemoveCostingActionTypes.ToggleItemSelected,
  props<{ id: string }>(),
);

export const showDialog = createAction(
  JobVariationAddRemoveCostingActionTypes.ShowDialog,
  props<{
    variation: SingleSaveVariation;
    section: SingleSaveVariationSection;
    estimateListItems: EstimatePriceListItem[];
    priceListItems: EstimatePriceListItem[];
  }>(),
);
