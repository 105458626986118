import { createSelector } from '@ngrx/store';
import { FileUploadStatus } from 'app/shared/models';
import { selectSharedState, SharedState } from '../reducers';
import { FileUploadState } from '../reducers/file-upload.reducer';

export const selectFileUploadState = createSelector(
  selectSharedState,
  (s: SharedState) => s.fileUploads,
);

export const selectFileUploadFiles = createSelector(
  selectFileUploadState,
  (s: FileUploadState) =>
    s.files.map((f, i) => {
      return {
        name: f.name,
        size: f.size,
        loaded: s.loaded[i],
        progress: Math.round((100 * s.loaded[i]) / f.size),
        status: s.status[i],
        error: s.error[i],
      };
    }),
);

export const selectFileUploadComplete = createSelector(
  selectFileUploadFiles,
  (files) =>
    files.length > 0 &&
    files.every((f) =>
      [FileUploadStatus.Completed, FileUploadStatus.Failed].includes(f.status),
    ),
);
