<div class="px-6 absolute inset-0 flex flex-col min-w-0 overflow-x-hidden overflow-y-auto" style="height: calc(100vh - 65px)">
  <div class="flex flex-col h-full">
    <div [formGroup]="form">
      <div class="flex flex-row mb-8 items-center">
        <button
          class="mr-3 px-2 min-w-3 min-h-3 w-9 h-9 rounded"
          [color]="'primary'"
          (click)="back()"
          mat-flat-button
        >
          <mat-icon [svgIcon]="'heroicons_outline:arrow-left'"></mat-icon>
        </button>

        <ng-container *ngIf="isNew">
          <div>
            <div
              class="xs:text-l md:text-3xl sm:text-xl xs:font-medium sm:font-extrabold tracking-tight leading-none"
            >
              {{ jobDetailFacade.title$ | async }}
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="jobOrderDetailsFacade.jobOrder$ | async; let jobOrder"
        >
          <div>
            <div
              class="xs:text-l md:text-3xl sm:text-xl xs:font-medium sm:font-extrabold tracking-tight leading-none"
            >
              {{ jobOrder.job | jobTitle }}
            </div>
          </div>
        </ng-container>
      </div>

      <div
        class="mb-8 flex flex-row items-center"
        *ngIf="jobOrderDetailsFacade.jobOrder$ | async; let jobOrder"
      >
        <div
          class="mr-2 xs:text-l md:text-3xl sm:text-xl xs:font-medium sm:font-extrabold tracking-tight leading-none"
        >
          Order
        </div>
        <app-order-status-pill
          class="mr-2"
          [status]="jobOrder.status || orderStatus.Draft"
        >
        </app-order-status-pill>
        <div
          id="sent-label"
          class="uppercase py-0.5 px-3 inline-block whitespace-no-wrap rounded-lg text-sm font-semiboldtext-yellow-800 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-500"
          *ngIf="data.sent"
        >
          Sent
        </div>
      </div>

      <div class="mb-8 flex flex-row items-center" *ngIf="isNew">
        <div
          class="xs:text-l md:text-3xl sm:text-xl xs:font-medium sm:font-extrabold tracking-tight leading-none"
        >
          {{ isExtraOverOrder ? "New Extra / Over Order" : "New Order" }}
        </div>
        <app-order-status-pill
          class="mx-4"
          [status]="orderStatus.Draft"
        ></app-order-status-pill>
      </div>

      <div>
        <div class="flex flex-row flex-wrap justify-between mb-2.5">
          <div class="flex flex-row mb-2.5" *ngIf="!isReadOnlyMode">
            <button
              [disabled]="!hasChanges || editRowIndex !== null"
              class="text-xs rounded-xl min-h-8 h-8 px-4 border-primary mr-4"
              [color]="'accent'"
              (click)="cancel()"
              mat-flat-button
            >
              <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
              <span class="ml-2">Cancel</span>
            </button>
            <button
              class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
              [disabled]="
                !hasChanges ||
                form.invalid ||
                !data?.totalAmount ||
                editRowIndex !== null
              "
              [color]="'primary'"
              (click)="saveChanges()"
              mat-flat-button
            >
              <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
              <span class="ml-2">Save</span>
            </button>
            <button
              *ngIf="syncEnabled"
              class="text-xs rounded-xl min-h-8 h-8 px-4"
              [color]="'primary'"
              (click)="sync()"
              mat-flat-button
              [disabled]="
                data?.status !== orderStatus.Received ||
                transactionHistory?.processStatus === 10
              "
            >
              <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
              <span class="ml-2">Sync</span>
            </button>
            <div *ngIf="xeroDeepLink && syncEnabled">
              <a
                class="ml-2 text-xs rounded-xl min-h-8 h-8 px-4"
                mat-flat-button
                [href]="xeroDeepLink"
                target="_blank"
                >View in Xero</a
              >
            </div>
          </div>
          <div class="flex flex-row">
            <ng-container *ngIf="!isNew">
              <button
                id="call-for-order"
                class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                [ngClass]="{
                  'pointer-events-none opacity-20 ': !data?.totalAmount
                }"
                [color]="'primary'"
                (click)="sendOrderModal(true)"
                [disabled]="
                  leavePageService.needSave ||
                  form.invalid ||
                  !data?.totalAmount ||
                  isReadOnlyMode
                "
                mat-flat-button
              >
                <mat-icon
                  class="mr-6 cursor-pointer icon-size-7"
                  svgIcon="mat_outline:campaign"
                >
                </mat-icon>
                <span class="ml-2">Call for order</span>
              </button>

              <ng-container *ngIf="data && !isReadOnlyMode">
                <div>
                  <button
                    id="change-status"
                    [matMenuTriggerFor]="statusMenu"
                    class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                    [color]="'primary'"
                    mat-fab
                    extended
                  >
                    <span class="mr-2">{{ orderStatus[data.status] }}</span>
                    <mat-icon
                      class="icon-size-3"
                      [svgIcon]="'heroicons_outline:chevron-down'"
                    ></mat-icon>
                  </button>
                  <mat-menu #statusMenu="matMenu">
                    <button
                      mat-menu-item
                      *ngFor="let option of statusComboList"
                      (click)="changeStatus(option)"
                    >
                      {{ option.name }}
                    </button>
                  </mat-menu>
                </div>
              </ng-container>
              <button
                id="preview"
                class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                (click)="onPreview()"
                mat-stroked-button
              >
                <div class="flex flex-row items-center">
                  <mat-icon
                    class="color-white dark:color-default"
                    [svgIcon]="'heroicons_outline:eye'"
                  ></mat-icon>
                  <span class="ml-3 mr-2">Preview</span>
                </div>
              </button>
              <button
                id="download"
                class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                (click)="downloadPdf()"
                mat-stroked-button
              >
                <div class="flex flex-row items-center">
                  <mat-icon
                    class="color-white dark:color-default"
                    [svgIcon]="'heroicons_outline:printer'"
                  ></mat-icon>
                  <span class="ml-3 mr-2">Print</span>
                </div>
              </button>
            </ng-container>

            <ng-container *ngIf="!isNew">
              <button
                id="send"
                class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
                [color]="'primary'"
                (click)="sendOrderModal()"
                [disabled]="
                  leavePageService.needSave ||
                  form.invalid ||
                  !data?.totalAmount ||
                  isReadOnlyMode
                "
                mat-flat-button
              >
                <mat-icon
                  [svgIcon]="'heroicons_outline:paper-airplane'"
                ></mat-icon>
                <span class="ml-2">Send</span>
              </button>
              <button
                *ngIf="!isReadOnlyMode"
                class="text-xs rounded-xl min-h-8 h-8 px-4 border-primary mr-4"
                [color]="'accent'"
                (click)="deleteOrder()"
                mat-flat-button
              >
                <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                <span class="ml-2">Delete</span>
              </button>
            </ng-container>
          </div>
        </div>

        <hr class="mb-7 mt-0" />
      </div>
    </div>
    <div>
      <div
        class="grid md:grid-cols-12 sm:grid-cols-2 gap-3 w-full mb-7"
        [formGroup]="form"
      >
        <div class="md:col-span-3 sm:col-span-2">
          <mat-form-field
            class="fuse-mat-no-subscript w-full"
            appearance="fill"
          >
            <mat-label>To supplier</mat-label>
            <input
              #autoCompleteInput
              [readonly]="!isNew || isReadOnlyMode"
              [formControl]="supplierSearchField"
              [matAutocomplete]="auto"
              type="text"
              aria-label="To supplier"
              matInput
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="
                  let item of jobOrderListSuppliers$
                    | async
                    | searchArrayItem: 'name' : supplierSearchField.value
                "
                [value]="item.name"
                (onSelectionChange)="onSupplierChange(item)"
              >
                <span>{{ item.name }} </span>
                <span class="text-secondary"> {{ item.description }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="md:col-span-3 sm:col-span-2">
          <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-label>Category</mat-label>
            @if (isReadOnlyMode) {
              <input
                readonly
                [value]="selectedCategeoryValue"
                type="text"
                aria-label="Category"
                matInput
              />
            } @else {
              <mat-select
                formControlName="categoryId"
                [disabled]="isReadOnlyMode"
              >
                <mat-option *ngFor="let c of categories" [value]="c?.id">
                  {{ c?.name }}
                </mat-option>
              </mat-select>
            }
          </mat-form-field>
        </div>
        <div class="md:col-span-3 sm:col-span-2">
          <mat-form-field
            class="fuse-mat-no-subscript w-full"
            floatLabel="always"
            [class.pointer-events-none]="isReadOnlyMode"
          >
            <mat-label>Delivery address</mat-label>
            <mat-icon
              class="icon-size-5"
              [svgIcon]="'heroicons_solid:home'"
              matPrefix
            ></mat-icon>
            <input
              [readonly]="
                (data?.status !== orderStatus.Draft && !isNew) ||
                !selectedCategoryId
              "
              [value]="formattedAddress"
              (addressSelected)="addressSelected($event)"
              matInput
              addressLookup
              maxlength="256"
              placeholder="Type here to search for an address"
            />
          </mat-form-field>
        </div>
        <div class="md:col-span-3 sm:col-span-2">
          <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-label>Reference</mat-label>
            <input
              [readonly]="
                isReadOnlyMode || (data?.status !== orderStatus.Draft && !isNew)
              "
              formControlName="reference"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="md:col-span-2 sm:col-span-1" *ngIf="!isNew">
          <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-label>Order #</mat-label>
            <input
              readonly
              formControlName="orderCode"
              matInput
              maxlength="32"
            />
          </mat-form-field>
        </div>
        <div
          [ngClass]="{
            'md:col-span-2 sm:col-span-2': isNew,
            'md:col-span-2  sm:col-span-1': !isNew
          }"
        >
          <mat-form-field
            class="fuse-mat-no-subscript w-full"
            [class.pointer-events-none]="isReadOnlyMode"
          >
            <mat-label>Created</mat-label>
            <input
              [readonly]="data?.status !== orderStatus.Draft && !isNew"
              [matDatepicker]="date"
              (dateChange)="formatDate('created')"
              (focus)="
                data?.status === orderStatus.Draft || isNew ? date.open() : null
              "
              (blur)="orderDateSetDefaultValue()"
              formControlName="created"
              matInput
            />
            <mat-datepicker #date></mat-datepicker>
          </mat-form-field>
        </div>
        @if (isReadOnlyMode === false) {
          <div
            [ngClass]="{
              'md:col-span-2 sm:col-span-2': isNew,
              'md:col-span-2  sm:col-span-1': !isNew
            }"
          >
            <mat-form-field
              class="fuse-mat-no-subscript w-full"
              [class.pointer-events-none]="isReadOnlyMode"
            >
              <mat-label>Called</mat-label>
              <input
                [readonly]="data?.status !== orderStatus.Draft && !isNew"
                [matDatepicker]="calledDate"
                [min]="orderDate.value"
                (dateChange)="formatDate('calledDate')"
                (focus)="
                  data?.status === orderStatus.Draft || isNew
                    ? calledDate.open()
                    : null
                "
                formControlName="calledDate"
                matInput
              />
              <mat-datepicker #calledDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div
            #triggerContainer
            class="flex flex-row"
            [ngClass]="{
              'md:col-span-2 sm:col-span-2': isNew,
              'md:col-span-2 sm:col-span-1': !isNew
            }"
          >
            <mat-form-field
              class="fuse-mat-no-subscript w-full relative"
              [class.pointer-events-none]="isReadOnlyMode"
            >
              <div class="h-0 w-0 overflow-hidden">
                <button
                  class="invisible h-full top-0 left-0 w-full absolute"
                  #callForDateMenuTrigger="matMenuTrigger"
                  [disabled]="data?.status !== orderStatus.Draft && !isNew"
                  [matMenuTriggerFor]="menu"
                  mat-button
                >
                  Menu
                </button>
              </div>
              <mat-label>Called for</mat-label>
              <input
                [readonly]="data?.status !== orderStatus.Draft && !isNew"
                [min]="calledDate"
                [matDatepicker]="calledFor"
                (dateChange)="formatDate('callForDate')"
                (focus)="
                  data?.status === orderStatus.Draft || isNew
                    ? callForDateMenuTrigger.openMenu()
                    : null
                "
                formControlName="callForDate"
                matInput
              />
              <mat-icon
                class="button-icon"
                [svgIcon]="'heroicons_outline:chevron-down'"
                matSuffix
              >
              </mat-icon>
              <mat-datepicker #calledFor></mat-datepicker>
              @if (calledForError) {
                <mat-error>Called For Date Too Small</mat-error>
              }
            </mat-form-field>

            <mat-menu class="" #menu="matMenu">
              <button
                [ngStyle]="{ width: triggerContainer?.offsetWidth + 'px' }"
                *ngFor="let item of calledForOptions"
                (click)="callForDateUpdate(item.value)"
                mat-menu-item
              >
                {{ item.name }}
              </button>
              <button (click)="calledFor.open()" mat-menu-item>
                Pick another date
              </button>
            </mat-menu>
          </div>
          <div
            [ngClass]="{
              'md:col-span-2 sm:col-span-2': isNew,
              'md:col-span-2 sm:col-span-1': !isNew
            }"
          >
            <mat-form-field
              class="fuse-mat-no-subscript w-full"
              [class.pointer-events-none]="isReadOnlyMode"
            >
              <mat-label>Acknowledged</mat-label>
              <input
                [readonly]="
                  data?.status !== orderStatus.Draft &&
                  !isNew &&
                  data?.status !== orderStatus.Awaiting_Delivery
                "
                [min]="calledDate.value"
                [matDatepicker]="acknowledged"
                (dateChange)="formatDate('acknowledgedDate')"
                (focus)="
                  data?.status === orderStatus.Draft ||
                  data?.status === orderStatus.Awaiting_Delivery ||
                  isNew
                    ? acknowledged.open()
                    : null
                "
                formControlName="acknowledgedDate"
                matInput
              />
              <mat-datepicker #acknowledged></mat-datepicker>
            </mat-form-field>
          </div>
          <div
            class="sm:col-span-2 flex flex-row items-center pt-5 pl-5"
            *ngIf="!isNew && !isReadOnlyMode"
          >
            <mat-checkbox
              [checked]="data?.sent"
              [disabled]="
                (data?.status !== orderStatus.Draft &&
                  data?.status !== orderStatus.Awaiting_Delivery) ||
                !data?.totalAmount
              "
              [color]="'primary'"
              (change)="changeSend($event)"
            >
              Sent
            </mat-checkbox>
          </div>
        }
      </div>
      <div class="mb-7">
        <div class="flex-auto overflow-y-auto max-h-72 mb-3" #tableScroll>
          <table
            class="w-full border-t border-slate-200"
            #table
            [dataSource]="data?.orderItems"
            mat-table
          >
            <ng-container matColumnDef="priceListCategoryName">
              <th *matHeaderCellDef mat-header-cell>Category</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                {{ element?.priceListCategoryCode }} -
                {{ element?.priceListCategoryName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="itemCode">
              <th *matHeaderCellDef mat-header-cell>Item Code</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                {{ element?.itemCode }}
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th *matHeaderCellDef mat-header-cell>Item Description</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                {{ element?.description }}
              </td>
            </ng-container>
            <ng-container matColumnDef="supplierItemCode">
              <th *matHeaderCellDef mat-header-cell>Supplier Code</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                {{ element?.supplierItemCode }}
              </td>
            </ng-container>

            <ng-container matColumnDef="qty">
              <th *matHeaderCellDef mat-header-cell class="w-24">Qty</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                <div class="w-24" [hidden]="editRowIndex === i">
                  {{ element.qty | number: "1.2-2" }}
                </div>
                <div [hidden]="editRowIndex !== i">
                  <ng-container [formGroup]="tableForm">
                    <ng-container formArrayName="listForm">
                      <ng-container
                        *ngIf="listForm.controls[i]"
                        [formGroupName]="i"
                      >
                        <div class="w-24 flex">
                          <mat-form-field
                            class="w-full cleared-input fuse-mat-no-subscript"
                            [ngClass]="{
                              invalid: listForm.controls[i].invalid,
                              'pointer-events-none': editRowIndex !== i
                            }"
                          >
                            <input
                              class="p-0 text-sm"
                              [readonly]="
                                data?.status !== orderStatus.Draft &&
                                data?.status !==
                                  orderStatus.Awaiting_Delivery &&
                                !isNew
                              "
                              [inputMask]="currencyInputMask"
                              trim="blur"
                              formControlName="qty"
                              matInput
                            />
                          </mat-form-field>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="uom">
              <th *matHeaderCellDef mat-header-cell class="w-24">UOM</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                {{ element?.unitOfMeasure }}
              </td>
            </ng-container>
            <ng-container matColumnDef="unitPrice">
              <th *matHeaderCellDef mat-header-cell class="w-24">Price</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                <div class="w-24" [hidden]="editRowIndex === i">
                  {{ element.unitPrice | number: "1.2-2" }}
                </div>
                <div [hidden]="editRowIndex !== i">
                  <ng-container [formGroup]="tableForm">
                    <ng-container formArrayName="listForm">
                      <ng-container
                        *ngIf="listForm.controls[i]"
                        [formGroupName]="i"
                      >
                        <div class="w-24 flex">
                          <mat-form-field
                            class="w-full cleared-input fuse-mat-no-subscript"
                            [ngClass]="{
                              invalid: listForm.controls[i].invalid,
                              'pointer-events-none': editRowIndex !== i
                            }"
                          >
                            <input
                              class="p-0 text-sm"
                              [readonly]="
                                data?.status !== orderStatus.Draft &&
                                data?.status !==
                                  orderStatus.Awaiting_Delivery &&
                                !isNew
                              "
                              [inputMask]="currencyInputMask"
                              trim="blur"
                              formControlName="unitPrice"
                              matInput
                            />
                          </mat-form-field>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="gstRate">
              <th *matHeaderCellDef mat-header-cell>GST</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                <!-- {{element?.gstRate}} -->
                {{
                  (element?.gstRate / 100) * element?.unitTotal
                    | number: "1.2-2"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="unitTotal">
              <th *matHeaderCellDef mat-header-cell>Amount</th>
              <td *matCellDef="let element; let i = index" mat-cell>
                {{
                  (1 + element?.gstRate / 100) * element?.unitTotal
                    | number: "1.2-2"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element; let i = index" mat-cell>
                <div class="flex flex-row">
                  <ng-container
                    *ngIf="
                      data?.status === orderStatus.Draft ||
                      data?.status === orderStatus.Awaiting_Delivery ||
                      isNew
                    "
                  >
                    <ng-container *ngIf="editRowIndex === i && !isReadOnlyMode">
                      <button
                        (click)="
                          $event.stopPropagation(); saveItemChanges(element, i)
                        "
                        mat-icon-button
                      >
                        <mat-icon
                          class="icon-size-5"
                          [svgIcon]="'heroicons_outline:check-circle'"
                          aria-hidden="true"
                          matPrefix
                        ></mat-icon>
                      </button>
                      <button
                        (click)="
                          $event.stopPropagation(); discardItemChanges(i)
                        "
                        mat-icon-button
                      >
                        <mat-icon
                          class="icon-size-5 text-warn"
                          [svgIcon]="'heroicons_outline:x-mark'"
                          aria-hidden="true"
                          matPrefix
                        ></mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="editRowIndex !== i && !isReadOnlyMode">
                      <button
                        (click)="$event.stopPropagation(); editItem(i)"
                        mat-icon-button
                      >
                        <mat-icon
                          class="icon-size-5 text-secondary"
                          [svgIcon]="'heroicons_outline:pencil'"
                          aria-hidden="true"
                          matPrefix
                        ></mat-icon>
                      </button>
                      <button
                        (click)="$event.stopPropagation(); removeItem(i)"
                        mat-icon-button
                      >
                        <mat-icon
                          class="icon-size-5 text-secondary"
                          [svgIcon]="'heroicons_outline:trash'"
                          aria-hidden="true"
                          matPrefix
                        ></mat-icon>
                      </button>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr
              class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
              *matRowDef="let row; columns: displayedColumns; let i = index"
              [class.edit-section]="editRowIndex === i"
              (click)="
                !isReadOnlyMode &&
                (data?.status === orderStatus.Draft ||
                  data?.status === orderStatus.Awaiting_Delivery ||
                  isNew)
                  ? editItem(i)
                  : null
              "
              mat-row
            ></tr>
          </table>
        </div>

        <div
          *ngIf="
            !isReadOnlyMode && (data?.status === orderStatus.Draft || isNew)
          "
        >
          <button
            *ngIf="!isExtraOverOrder"
            id="add-from-costing"
            class="text-xs rounded-xl min-h-8 h-8 pr-4 pl-2 mr-4"
            [disabled]="!supplierId.value || !itemsToOrderFiltered?.length"
            [color]="'primary'"
            (click)="createSupplierOrder()"
            mat-stroked-button
          >
            <div class="flex flex-row items-center">
              <span class="ml-2 mr-3">Add from costing</span>
              <mat-icon
                class="button-icon"
                [svgIcon]="'heroicons_outline:chevron-down'"
              ></mat-icon>
            </div>
          </button>

          <button
            *ngIf="isExtraOverOrder"
            id="add-from-item-list"
            class="text-xs rounded-xl min-h-8 h-8 pr-4 pl-2 mr-4"
            [disabled]="!selectedCategoryId"
            [color]="'primary'"
            (click)="addFromItemList()"
            mat-stroked-button
          >
            <div class="flex flex-row items-center">
              <span class="ml-2 mr-3">Add from Item List</span>
              <mat-icon
                class="button-icon"
                [svgIcon]="'heroicons_outline:chevron-down'"
              ></mat-icon>
            </div>
          </button>
        </div>
        <div class="pr-11 mb-5">
          <div class="flex flex-row justify-end">
            <div
              class="flex flex-row justify-end items-center text-secondary text-xs max-w-36 w-full py-4 border-b"
            >
              Subtotal
            </div>
            <div
              class="text-right color-white text-lg max-w-24 w-full py-4 border-b"
            >
              {{ data?.subTotal || 0 | currency }}
            </div>
          </div>
          <div class="flex flex-row justify-end">
            <div
              class="flex flex-row justify-end items-center text-secondary text-xs max-w-36 w-full py-4 border-b"
            >
              GST
            </div>
            <div
              class="text-right color-white text-lg max-w-24 w-full py-4 border-b"
            >
              {{ data?.gstAmount || 0 | currency }}
            </div>
          </div>
          <div class="flex flex-row justify-end">
            <div
              class="flex flex-row justify-end items-center text-secondary text-xs max-w-36 w-full py-4 border-b"
            >
              Amount due
            </div>
            <div
              class="text-right color-white text-lg max-w-24 w-full py-4 border-b"
            >
              {{ data?.totalAmount || 0 | currency }}
            </div>
          </div>
        </div>
        <div
          id="delivery-instructions"
          class="border-dashed border rounded-lg p-2.5"
          [ngClass]="{
            'cursor-pointer': data?.status === orderStatus.Draft || isNew,
            'pointer-events-none': isReadOnlyMode
          }"
          (click)="editInstructionDialog()"
        >
          <div class="mb-2.5 text-sm text-secondary">Delivery instructions</div>
          <div class="mb-2.5" [innerHtml]="deliveryInstructions.value"></div>
          <div
            *ngIf="
              !isReadOnlyMode && (data?.status === orderStatus.Draft || isNew)
            "
          >
            <a class="text-primary"> Click to edit </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
