import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  EstimatePriceListItem,
  SaveAsTemplate,
} from 'app/shared/models';
import {
  addRemoveEstimatePriceListItemsShowDialog,
  deleteEstimatePriceListItemConfirm,
  deleteEstimateSectionConfirm,
  getEstimate,
  selectEstimateDetailError,
  selectEstimateDetailEstimate,
  selectEstimateDetailIsLoaded,
  selectEstimateDetailIsLoading,
  updateEstimatePriceListItem,
} from '..';
import { EstimateStatusParams } from '../../models/estimate-statuses';
import {
  createTemplate,
  moveEstimateSectionDown,
  moveEstimateSectionUp,
  resetEstimate,
  saveEstimate,
  showAddEstimateSectionDialog,
  showRenameEstimateSectionDialog,
  updateEstimate,
  updateEstimateCustomerQuote,
  updateEstimateOnCost,
  updateEstimatePriceListItemShowDialog,
  updateEstimateProfit,
  updateEstimateStatus,
} from '../actions';
import { EstimateDetailState } from '../reducers/estimate-detail.reducer';
import {
  selectEstimateDetailItemCount,
  selectEstimateIsEditing,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class EstimateDetailFacade {
  isLoading$ = this.store.select(selectEstimateDetailIsLoading);
  isLoaded$ = this.store.select(selectEstimateDetailIsLoaded);
  error$ = this.store.select(selectEstimateDetailError);
  estimate$ = this.store.select(selectEstimateDetailEstimate);
  itemCount$ = this.store.select(selectEstimateDetailItemCount);
  editing$ = this.store.select(selectEstimateIsEditing);

  constructor(private store: Store<EstimateDetailState>) {}

  save() {
    this.store.dispatch(saveEstimate());
  }

  updateEstimate(estimate) {
    this.store.dispatch(updateEstimate({ estimate }));
  }

  reset() {
    this.store.dispatch(resetEstimate());
  }

  updateCustomerQuote(customerQuote: number): void {
    this.store.dispatch(updateEstimateCustomerQuote({ customerQuote }));
  }

  updateProfit(profit: number): void {
    this.store.dispatch(updateEstimateProfit({ profit }));
  }

  updateOnCost(onCost: number): void {
    this.store.dispatch(updateEstimateOnCost({ onCost }));
  }

  addSection(id: string) {
    this.store.dispatch(showAddEstimateSectionDialog());
  }

  saveAsTemplate(template: SaveAsTemplate): void {
    this.store.dispatch(createTemplate({ template }));
  }

  showRenameEstimateSectionDialog(id: string, name: string) {
    this.store.dispatch(showRenameEstimateSectionDialog({ id, name }));
  }

  deleteSection(id: string) {
    this.store.dispatch(deleteEstimateSectionConfirm({ id }));
  }

  moveEstimateSectionDown(id: string) {
    this.store.dispatch(moveEstimateSectionDown({ id }));
  }

  moveEstimateSectionUp(id: string) {
    this.store.dispatch(moveEstimateSectionUp({ id }));
  }

  updatePriceListItem(item: EstimatePriceListItem) {
    this.store.dispatch(updateEstimatePriceListItem({ item }));
  }


  updateEstimatePriceListItemShowDialog(item: EstimatePriceListItem) {
    this.store.dispatch(updateEstimatePriceListItemShowDialog({ item }));
  }

  addRemoveEstimatePriceListItemsShowDialog() {
    this.store.dispatch(addRemoveEstimatePriceListItemsShowDialog());
  }

  deletePriceListItem(id: string) {
    this.store.dispatch(deleteEstimatePriceListItemConfirm({ id }));
  }

  getEstimate(id: string): void {
    this.store.dispatch(getEstimate({ id }));
  }

  updateEstimateStatus(params: EstimateStatusParams): void {
    return this.store.dispatch(updateEstimateStatus({ params }));
  }
}
