<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div
    class="flex flex-col sm:flex-row items-center sm:items-start p-8 pb-6 sm:pb-8"
  >
    <!-- Icon -->
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600"
    >
      <mat-icon
        class="text-current"
        svgIcon="heroicons_outline:user-plus"
      ></mat-icon>
    </div>

    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left"
    >
      <!-- Title -->
      <div
        class="text-xl leading-6 font-medium"
        [innerHTML]="'Invite team member'"
      ></div>

      <!-- Message -->
      <div class="text-secondary" [innerHTML]="'User will be notified'"></div>
    </div>
  </div>

  <!-- Form -->
  <div
    class="flex flex-row items-center mb-3 gap-x-5 p-8"
    [formGroup]="inviteForm"
  >
    <mat-form-field class="w-3/5">
      <mat-icon matPrefix [svgIcon]="'heroicons_outline:envelope'"></mat-icon>
      <input
        formControlName="email"
        matInput
        type="email"
        [placeholder]="'Team member email'"
        maxlength="254"
        required
      />
      <mat-error
        class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1"
        *ngIf="validEmail"
      >
        Valid email required
      </mat-error>
      <mat-error
        class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1"
        *ngIf="checkInvitePending"
      >
        Team member invitation pending
      </mat-error>
      <mat-error
        class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1"
        *ngIf="checkInviteRejected"
      >
        Team member rejected invitation
      </mat-error>
      <mat-error
        class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1"
        *ngIf="checkInviteAccepted"
      >
        Team member accepted invitation
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-2/5">
      <mat-select
        [panelClass]="'w-72 min-w-72 max-w-72 h-auto max-h-none'"
        formControlName="teamMemberTypeId"
        placeholder="Role"
        disableOptionCentering
        #roleSelect="matSelect"
      >
        <!-- <mat-select-trigger class="text-md">
          <span>Role:</span>
          <span class="ml-1 font-medium"
            >{{ (facade.teamCommon$ | async).teamMemberTypes.filter(x => x.id
            === roleSelect.id)[0].description }}</span
          >
        </mat-select-trigger> -->
        <ng-container
          *ngFor="let role of (facade.teamCommon$ | async).teamMemberTypes"
        >
          <mat-option class="h-auto py-4 leading-none" [value]="role.id">
            <div class="font-medium">{{ role.description }}</div>
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-icon
        class="icon-size-5"
        matPrefix
        [svgIcon]="'heroicons_outline:identification'"
      ></mat-icon>
    </mat-form-field>
  </div>

  <!-- Action buttons -->
  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <!-- Cancel -->
    <button mat-stroked-button [matDialogClose]="undefined">Close</button>

    <!-- Confirm -->
    <button
      mat-flat-button
      class=""
      color="primary"
      [matDialogClose]="inviteForm.value"
      [disabled]="inviteForm.valid === false"
    >
      Invite
    </button>
  </div>
</div>
