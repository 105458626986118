import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobOrder } from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  getJobsOrderSummary,
  getJobsOrderSummaryFailure,
  getJobsOrderSummarySuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobOrderSummaryEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobsOrderSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobsOrderSummary),
      // tap(_ => this.spinner.show()),
      switchMap(({ id }) =>
        this.jobService.getJobOrderSummaryList(id).pipe(
          map((jobOrderSummary: any) =>
            getJobsOrderSummarySuccess({ jobOrderSummary }),
          ),
          catchError((error) => {
            return of(getJobsOrderSummaryFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
