/* eslint-disable arrow-body-style */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FileBlobService } from 'app/shared/services/file-blob.service';
import { ToastService } from 'app/shared/services/toast.service';
import { FileSaverService } from 'ngx-filesaver';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  fileDownloadFailure,
  fileDownloadNotFound,
  fileDownloadRequest,
  fileDownloadSuccess,
} from '../actions/file-download.actions';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadEffects {
  fileDownloadSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fileDownloadSuccess),
        tap(({ blob, filename }) => {
          this.fileSaverService.save(blob, filename);
          this.toast.success(`Downloaded ${filename}`);
        }),
      ),
    { dispatch: false },
  );

  fileDownloadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileDownloadRequest),
      switchMap(({ url, filename }) =>
        this.blobService.download(url).pipe(
          map((res: HttpResponse<Blob>) => {
            if (res.ok) {
              return fileDownloadSuccess({ blob: res.body, filename });
            }

            if (res.status === 404) {
              return fileDownloadNotFound({ url, filename });
            }

            return fileDownloadFailure({ error: res.statusText });
          }),
          catchError((error) => {
            return of(fileDownloadFailure({ error }));
          }),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private blobService: FileBlobService,
    private fileSaverService: FileSaverService,
    private toast: ToastService,
  ) {}
}
