<div class="flex flex-row items-center py-4">
  <h2 class="p-0 mr-4 text-3xl">Manage - Items</h2>

  <div class="table-search-wrap mr-auto ml-auto w-80 p-0 rounded-md">
    <div class="table-search" [formGroup]="searchForm">
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full">
        <mat-icon
          [svgIcon]="'heroicons_outline:magnifying-glass'"
          matPrefix
        ></mat-icon>
        <input
          [autocomplete]="'off'"
          [placeholder]="'Search anything...'"
          formControlName="searchField"
          matInput
        />
        <mat-icon
          *ngIf="searchControl.value"
          (click)="searchControl.patchValue('')"
          svgIcon="heroicons_outline:x-circle"
        ></mat-icon>
      </mat-form-field>
    </div>
  </div>

  <button
    class="text-xs rounded-xl ml-4 mr-4 min-h-8 h-8 px-4"
    [disabled]="!(canSave$ | async)"
    [color]="'primary'"
    (click)="save()"
    mat-flat-button
  >
    <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
    <span class="ml-2 mr-1">Save</span>
  </button>
  <button
    class="text-xs rounded-xl border border-solid border-gray-600 min-h-8 h-8 px-4"
    [color]="'accent'"
    (click)="close()"
    mat-flat-button
  >
    <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
    <span class="ml-2 mr-1">Cancel</span>
  </button>
</div>
<mat-dialog-content>
  <div
    class="content-wrapper flex sm:grid-cols-10 gap-3 w-full h-full max-h-full overflow-hidden"
  >
    <div class="flex w-1/4 overflow-hidden categories-wrap p-1.5 rounded">
      <div class="flex flex-col w-full">
        <div class="flex flex-row justify-between align-center py-2">
          <div class="mb-2.5">Categories</div>
          <button
            *ngIf="!data['selectedCategory']"
            class="text-xs rounded-xl min-h-8 h-8"
            [color]="'primary'"
            (click)="addNewCategory()"
            mat-flat-button
          >
            <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
            <span class="ml-2 mr-1">New Category</span>
          </button>
        </div>
        <div class="flex flex-row w-full">
          <span class="drag-icon"></span>
          <button
              class="w-full hover:bg-black rounded text-left px-1.5 border border-transparent border-solid h-7 min-h-7"
              [ngClass]="{
                  active: (selectedCategory$ | async)?.id === allCategories.id
                }"
              (click)="selectCategory(allCategories)"
              mat-button
              matTooltip="{{ allCategories.code }} - {{ allCategories.name }}"
              matTooltipPosition="right"
            >
              <div class="w-full flex flex-row justify-between align-center">
                <div class="w-full flex flex-row">
                  <span class="self-center truncate text-secondary"
                  >{{ allCategories.code }} - {{ allCategories.name }}</span
                  >
                </div>
                <mat-icon
                  *ngIf="!data['selectedCategory'] && allCategories.code"
                  (click)="addItemToCategory(allCategories)"
                  [svgIcon]="'heroicons_outline:plus-circle'"
                ></mat-icon>
              </div>
            </button>
        </div>
        <div class="flex flex-auto flex-col overflow-auto align-center"
             [cdkDropListData]="categories"
             (cdkDropListDropped)="drop($event)"
             cdkDropList>
          <button
            class="w-full hover:bg-black rounded text-left px-1.5 border border-transparent border-solid h-7 min-h-7"
            *ngFor="let category of categories; trackBy: trackByFn;"
            [ngClass]="{
              active: (selectedCategory$ | async)?.id === category.id
            }"
            (click)="selectCategory(category)"
            mat-button
            matTooltip="{{ category.code ?? '00' }} - {{ category.name }}"
            matTooltipPosition="right"
            [cdkDragData]="category"
            cdkDrag
          >
            <div class="w-full flex flex-row justify-between align-center items-center">
              <div class="w-full flex flex-row">
                <button class="cursor-move" cdkDragHandle mat-icon-button>
                  <span class="material-icons"> drag_indicator </span>
                </button>
                <span class="self-center truncate text-secondary"
                  >{{ category.code ?? "00" }} - {{ category.name }}</span
                >
              </div>
              <mat-icon
                *ngIf="category.code"
                (click)="addItemToCategory(category)"
                [svgIcon]="'heroicons_outline:plus-circle'"
              ></mat-icon>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex w-3/4 h-full overflow-hidden rounded border border-slate-200 pt-2.5"
    >
      <div class="flex flex-col w-full h-full max-h-full">
        <div class="sm:col-span-5 h-full overflow-hidden">
          <div class="flex flex-col h-full">
            <div class="mb-2.5 pl-1">
              Item List ({{ (priceListItems$ | async)?.length }})
            </div>
            <div class="h-full overflow-auto">
              <table
                class="w-full"
                [dataSource]="priceListItems$ | async"
                mat-table
              >
                <ng-container matColumnDef="selected">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    <mat-checkbox
                      [checked]="allPriceListItemsSelected$ | async"
                      [disabled]="(priceListItems$ | async)?.length === 0"
                      (change)="selectAllPriceListItems($event)"
                    >
                    </mat-checkbox>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    <mat-checkbox
                      [checked]="priceListItemIsSelected$(element) | async"
                      [disabled]="'disabled'"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="itemcode">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Item Code
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.itemCode }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Description
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="supplier">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Preferred Supplier
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.supplier.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="qty">
                  <th
                    class="w-10"
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Qty
                  </th>
                  <td *matCellDef="let element" mat-cell>{{ element.qty }}</td>
                </ng-container>
                <ng-container matColumnDef="uom">
                  <th
                    class="w-10"
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    UOM
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitOfMeasure }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="unitPrice">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Unit price
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitPrice }}
                  </td>
                </ng-container>
                <tr
                  *matHeaderRowDef="displayedColumnsPriceList; sticky: true"
                  mat-header-row
                ></tr>
                <tr
                  class="cursor-pointer"
                  *matRowDef="
                    let row;
                    columns: displayedColumnsPriceList;
                    let i = index
                  "
                  [ngClass]="{
                    active: (selectedPriceListItem$ | async)?.id === row.id
                  }"
                  (click)="selectPriceListItem(row)"
                  mat-row
                ></tr>
              </table>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1 pt-4 flex flex-row justify-center">
          <button
            class="text-xs rounded-xl mr-12 min-h-8 h-8 px-4"
            [color]="'primary'"
            [disabled]="(selectedPriceListItems$ | async)?.length === 0"
            (click)="addToEstimate()"
            mat-flat-button
          >
            <span class="ml-2 mr-1">Add</span>
            <mat-icon
              class="text-primary"
              [svgIcon]="'heroicons_outline:arrow-down'"
            ></mat-icon>
          </button>
          <button
            class="text-xs rounded-xl min-h-8 h-8 px-4"
            [color]="'primary'"
            [disabled]="(selectedEstimateListItems$ | async)?.length === 0"
            (click)="removeFromEstimate()"
            mat-flat-button
          >
            <mat-icon
              class="text-primary"
              [svgIcon]="'heroicons_outline:arrow-up'"
            ></mat-icon>
            <span class="ml-2 mr-1">Remove</span>
          </button>
        </div>
        <div class="sm:col-span-5 h-full overflow-hidden">
          <div class="flex flex-col h-full">
            <div class="mb-2.5 pl-1">
              Job List ({{ (estimateListItems$ | async)?.length }})
            </div>
            <div class="h-full overflow-auto">
              <table
                class="w-full"
                [dataSource]="estimateListItems$ | async"
                mat-table
              >
                <ng-container matColumnDef="selected">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    <mat-checkbox
                      [checked]="allEstimateListItemsSelected$ | async"
                      [disabled]="(estimateListItems$ | async)?.length === 0"
                      (change)="selectAllEstimateListItems($event)"
                    >
                    </mat-checkbox>
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    <mat-checkbox
                      [checked]="estimateListItemIsSelected$(element) | async"
                      [disabled]="'disabled'"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="itemcode">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Item Code
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.itemCode }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Description
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.description }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="supplier">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Supplier
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.supplier.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="qty">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Qty
                  </th>
                  <td *matCellDef="let element" mat-cell>{{ element.qty }}</td>
                </ng-container>
                <ng-container matColumnDef="uom">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    UOM
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitOfMeasure }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="unitPrice">
                  <th
                    class="whitespace-nowrap pr-2.5"
                    *matHeaderCellDef
                    mat-header-cell
                  >
                    Unit price
                  </th>
                  <td *matCellDef="let element" mat-cell>
                    {{ element.unitPrice }}
                  </td>
                </ng-container>

                <tr
                  *matHeaderRowDef="displayedColumnsEstimateList; sticky: true"
                  mat-header-row
                ></tr>
                <tr
                  class="cursor-pointer"
                  *matRowDef="
                    let row;
                    columns: displayedColumnsEstimateList;
                    let i = index
                  "
                  [ngClass]="{
                    active: (selectedEstimateListItem$ | async)?.id === row.id
                  }"
                  (click)="selectEstimateListItem(row)"
                  mat-row
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
