<div class="flex flex-col flex-auto absolute inset-0 overflow-hidden p-4" id="price-list-container">
    <div class="flex flex-row justify-between xs:mb-2 mb-4 items-center">
      <div
        class="xs:text-xl sm:text-2xl md:text-4xl font-extrabold tracking-tight leading-none"
      >
        Item List
      </div>
      <button
        *ngIf="!isReadOnly"
        class="pl-2 text-xs rounded-xl min-h-8 h-8 px-4"
        id="items-new-category-button"
        [color]="'primary'"
        (click)="newCategory()"
        mat-flat-button
      >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">New Category</span>
      </button>
    </div>
    <div class="table-search-wrap mb-2">
      <div class="flex flex-row items-center space-x-2 w-full">
        <div class="w-full sm:max-w-80" id="items-search-field">
          <mat-form-field
            class="fuse-mat-dense fuse-mat-no-subscript sm:large-search w-full min-q-50"
          >
            <mat-icon
              [svgIcon]="'heroicons_outline:magnifying-glass'"
              matPrefix
            ></mat-icon>
            <input
              [formControl]="searchControl"
              [autocomplete]="'off'"
              [placeholder]="'Search anything...'"
              matInput
            />
            <button
              *ngIf="searchControl.value"
              (click)="searchControl.setValue('')"
              matSuffix
              mat-icon-button
              aria-label="Clear"
            >
              <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox [formControl]="showEmptyControl">
            <span class="whitespace-no-wrap">Show empty</span>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-auto overflow-auto">

      <div
        class="flex flex-col flex-auto overflow-auto"
        [cdkDropListData]="categories"
        (cdkDropListDropped)="drop($event)"
        cdkDropList
      >
        <div
          *ngFor="let category of categories; trackBy: trackByFn; let i = index"
          [ngClass]="{
            hidden: category.items.length === 0 && !showEmptyControl.value
          }"
          [cdkDragData]="category"
          cdkDrag
        >
          <app-price-list-category
            [category]="category.category"
            [items]="category.items"
            [isReadOnly]="isReadOnly"
            [expanded]="
              (!!searchControl.value && !!category.items?.length) ||
              (!searchControl.value && i === 0)
            "
          >
            <button class="cursor-move" cdkDragHandle mat-icon-button>
              <span class="material-icons"> drag_indicator </span>
            </button>
          </app-price-list-category>
        </div>
      </div>
    </div>
  </div>
