import { createReducer, on } from '@ngrx/store';
import { JobNote } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import { orderBy } from 'lodash';
import {
  addJobNoteSuccess,
  getJobNotes,
  getJobNotesFailure,
  getJobNotesSuccess,
} from '..';

export interface JobNotesState {
  callState: CallState;
  notes: JobNote[];
  sortOrder: 'asc' | 'desc';
  error: string;
  totalCount: number;
}

export const initialState: JobNotesState = {
  callState: LoadingState.INIT,
  notes: [],
  sortOrder: 'asc',
  error: null,
  totalCount: 0,
};

function getSortedNotes(notes: JobNote[], sortOrder) {
  return orderBy(notes, (s: JobNote) => s.updated, sortOrder);
}
export const jobNotesReducer = createReducer(
  initialState,
  on(getJobNotes, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobNotesSuccess, (state, { notes }) => ({
    ...state,
    callState: LoadingState.LOADED,
    notes: notes.items,
    totalCount: notes.pageMetaData.records,
  })),
  on(getJobNotesFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),
  on(addJobNoteSuccess, (state, { note }) => ({
    ...state,
    notes: getSortedNotes([...state.notes, note], state.sortOrder),
  })),
  // on(deleteJobNote, (state, { id }) => ({
  //     ...state,
  //     notes: getSortedNotes(state.notes.filter(n => n.id !== id), state.sortOrder)
  // }))
);
