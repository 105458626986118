import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef as MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NgFor } from '@angular/common';

export interface ChangePlanCostsDialogData {
  changes: any;
  accountCancelled: boolean;
}

@Component({
  selector: 'app-change-plan-costs-dialog',
  templateUrl: './change-plan-costs-dialog.component.html',
  styleUrls: ['./change-plan-costs-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, NgFor, MatButtonModule],
})
export class ChangePlanCostsDialogComponent implements OnInit {
  changes: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangePlanCostsDialogData,
    private dialogRef: MatDialogRef<ChangePlanCostsDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.changes = this.data.changes;
    console.log('this changes', this.changes);
  }
}
