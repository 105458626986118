/* eslint-disable arrow-parens */
import { createReducer, on } from '@ngrx/store';
import { LoggedInUser, TeamMember } from 'app/shared/models/eazimate.models';
import * as fromActions from 'app/shared/store/actions/account-user.actions';

export interface AccountUserState {
  selectedAccount: TeamMember;
  accountPriceListSetupProgress: any;
  loggedInUser: LoggedInUser;
  isInitialised: boolean;
  isLoading: boolean;
  error: any;
  sasToken: string;
  userSettings: {
    themeMode: any;
  };
}

export const initialState: AccountUserState = {
  selectedAccount: undefined,
  accountPriceListSetupProgress: undefined,
  loggedInUser: undefined,
  isInitialised: false,
  isLoading: false,
  error: undefined,
  sasToken: undefined,
  userSettings: {
    themeMode: 'auto',
  },
};

export const accountUserReducer = createReducer<AccountUserState>(
  initialState,
  on(
    fromActions.setThemeMode,
    (state, { payload }): AccountUserState => ({
      ...state,
      userSettings: {
        ...state.userSettings,
        themeMode: payload,
      },
    }),
  ),
  on(
    fromActions.getSasTokenSuccess,
    (state, { payload }): AccountUserState => ({
      ...state,
      sasToken: payload,
    }),
  ),
  on(
    fromActions.loginAccountUser,
    (state): AccountUserState => ({
      ...state,
      isLoading: true,
      isInitialised: false,
    }),
  ),
  on(
    fromActions.loginAccountUserSuccess,
    (state, { payload }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      error: undefined,
      loggedInUser: payload,
      selectedAccount:
        payload.memberships[
          payload.memberships.findIndex(
            (x) => x.accountId === payload.account.id,
          )
        ],
      userSettings: Object.assign(
        {},
        ...payload.userSettings.map((x) => ({
          [x.settingKey]: x.settingValue,
        })),
      ),
    }),
  ),
  on(
    (fromActions.loginAccountUserFailure,
    fromActions.loginAccountUserNotFoundFailure),
    (state, action): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: false,
      error: action.payload.error,
    }),
  ),
  on(
    fromActions.setActiveAccount,
    (state, action): AccountUserState => ({
      ...state,
      selectedAccount: action.payload,
    }),
  ),
  on(
    fromActions.setActiveAccountSuccess,
    (state, { payload }): AccountUserState => ({
      ...state,
      loggedInUser: {
        ...state.loggedInUser,
        account: payload,
      },
    }),
  ),
  on(
    fromActions.saveAccountSettingsForm,
    (state, form): AccountUserState => ({
      ...state,
      loggedInUser: {
        ...state.loggedInUser,
        account: {
          ...state.loggedInUser.account,
          name: form.accountSettings.businessEntityName,
          abn: form.accountSettings.abn,
          email: form.accountSettings.email,
          website: form.accountSettings.website,
          phoneNumber: form.accountSettings.phoneNumber,
          mobileNumber: form.accountSettings.mobileNumber,
          logo: form.accountSettings.logo,
          defaultOnCost: form.accountSettings.defaultOnCost,
          address: {
            ...state.loggedInUser.account.address,
            country: form.accountSettings.address.country,
            suburb: form.accountSettings.address.suburb,
            streetAddress: form.accountSettings.address.streetAddress,
            state: form.accountSettings.address.state,
            placeId: form.accountSettings.address.placeId,
            postCode: form.accountSettings.address.postCode,
            formattedAddress: form.accountSettings.address.formattedAddress,
            latitude: form.accountSettings.address.latitude,
            longitude: form.accountSettings.address.longitude,
            locationType: form.accountSettings.address.locationType,
          },
          invoicePrefix: form.accountSettings.invoicePrefix,
          invoiceStartNumber: form.accountSettings.invoiceStartNumber,
          purchaseOrderPrefix: form.accountSettings.purchaseOrderPrefix,
          purchaseOrderStartNumber:
            form.accountSettings.purchaseOrderStartNumber,
          // purchaseOrderAtCategoryLevel:
          //   form.accountSettings.purchaseOrderAtCategoryLevel,
          quotePrefix: form.accountSettings.quotePrefix,
          quoteStartNumber: form.accountSettings.quoteStartNumber,
          bankAccount: form.accountSettings.bankAccount,
          bankAccountName: form.accountSettings.bankAccountName,
          bankBSB: form.accountSettings.bankBSB,
          license: form.accountSettings.license,
          invoiceTerms: form.accountSettings.invoiceTerms,
          quoteTerms: form.accountSettings.quoteTerms,
          variationTerms: form.accountSettings.variationTerms,
          jobPrefix: form.accountSettings.jobPrefix,
          jobStartNumber: form.accountSettings.jobStartNumber,
        },
      },
      isLoading: true,
    }),
  ),
  on(
    fromActions.saveAccountSuccess,
    (state, { account }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      selectedAccount: {
        ...state.selectedAccount,
        accountName: account.name,
        accountEmail: account.email,
        updatedBy: account.updatedBy,
        updated: account.updated,
      },
      loggedInUser: {
        ...state.loggedInUser,
        account: account,
        memberships: state.loggedInUser.memberships.map((x) => {
          if (x.accountId === account.id) {
            return {
              ...x,
              accountName: account.name,
              accountEmail: account.email,
              updatedBy: account.updatedBy,
              updated: account.updated,
            };
          } else {
            return x;
          }
        }),
      },
    }),
  ),
  on(
    fromActions.saveAccountFailure,
    (state, { error }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      error: error,
    }),
  ),
  on(
    fromActions.saveProfileSettingsForm,
    (state, { payload }): AccountUserState => ({
      ...state,
      loggedInUser: {
        ...state.loggedInUser,
        user: {
          ...state.loggedInUser.user,
          person: {
            ...state.loggedInUser.user.person,
            email: payload.email,
            firstName: payload.firstName,
            lastName: payload.lastName,
            phone: payload.phone,
          },
        },
      },
      isLoading: true,
    }),
  ),
  on(
    fromActions.cancelAccountSuccess,
    (state, { payload }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      loggedInUser: {
        ...state.loggedInUser,
        account: {
          ...payload,
        },
      },
    }),
  ),
  on(
    fromActions.saveAccountUserSuccess,
    (state, { payload }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      loggedInUser: {
        ...state.loggedInUser,
        user: {
          ...state.loggedInUser.user,
          ...payload,
        },
      },
    }),
  ),
  on(
    fromActions.saveAccountUserFailure,
    (state, { payload }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      error: payload,
    }),
  ),
  on(
    fromActions.teamMemberUpdateSuccess,
    (state, { teamMember }): AccountUserState => ({
      ...state,
      isLoading: false,
      loggedInUser: {
        ...state.loggedInUser,
        memberships: state.loggedInUser.memberships.map((tm) => {
          if (tm.accountId === teamMember.accountId) {
            const updatedTm = JSON.parse(JSON.stringify(tm)) as TeamMember;
            updatedTm.updated = teamMember.updated;
            updatedTm.updatedBy = teamMember.updatedBy;
            updatedTm.acceptedDate = teamMember.acceptedDate;
            updatedTm.rejectedDate = teamMember.rejectedDate;
            updatedTm.showNotification = teamMember.showNotification;
            return updatedTm;
          }
          return tm;
        }),
      },
    }),
  ),
  on(
    fromActions.teamMemberUpdateFailure,
    (state, { error }): AccountUserState => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),
  on(
    fromActions.uploadCompanyLogoSuccess,
    (state, { fileLocation }): AccountUserState => ({
      ...state,
      isLoading: false,
      isInitialised: true,
      loggedInUser: {
        ...state.loggedInUser,
        account: {
          ...state.loggedInUser.account,
          logo: fileLocation,
        },
      },
    }),
  ),
  on(
    fromActions.leaveAccount,
    (state, { payload }): AccountUserState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    fromActions.leaveAccountSuccess,
    (state, { accountIdToRemove }): AccountUserState => ({
      ...state,
      isLoading: false,
      error: null,
      loggedInUser: {
        ...state.loggedInUser,
        memberships: state.loggedInUser.memberships.filter(
          (x) => x.accountId !== accountIdToRemove,
        ),
      },
    }),
  ),
  on(
    fromActions.leaveAccountFailure,
    (state, { error }): AccountUserState => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),

  on(
    fromActions.getPriceListSetupProgress,
    (state): AccountUserState => ({
      ...state,
      accountPriceListSetupProgress: undefined,
    }),
  ),
  on(
    fromActions.getPriceListSetupProgressSuccess,
    (state, { payload }): AccountUserState => ({
      ...state,
      accountPriceListSetupProgress: payload,
    }),
  ),
  on(
    fromActions.getPriceListSetupProgressFailure,
    (state, { error }): AccountUserState => ({
      ...state,
      accountPriceListSetupProgress: undefined,
    }),
  ),
);

export const getIsInitialised = (state: AccountUserState): boolean =>
  state.isInitialised;
export const getIsLoading = (state: AccountUserState): boolean =>
  state.isLoading;
export const getError = (state: AccountUserState): any => state.error;
export const getLoggedInUser = (state: AccountUserState): LoggedInUser =>
  state.loggedInUser;
export const getSasToken = (state: AccountUserState): string => state.sasToken;
export const getThemeMode = (state: AccountUserState): string =>
  state.userSettings.themeMode;
