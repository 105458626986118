import { createAction, props } from '@ngrx/store';
import {
  VariationSection,
  FullVariationQuote,
  Variation,
} from 'app/shared/models';

export enum VariationLetterQuoteActionTypes {
  GetVariationLetterQuote = '[VariationLetter List] Get VariationLetter Quote',
  GetVariationLetterQuoteSuccess = '[VariationLetter List] Get VariationLetter Quote Success',
  GetVariationLetterQuoteFailure = '[VariationLetter List] Get VariationLetter Quote Failure',

  UpdateVariationLetterQuote = '[VariationLetter List] Update VariationLetter Quote',
  UpdateVariationLetterQuoteSuccess = '[VariationLetter List] Update VariationLetter Quote Success',
  UpdateVariationLetterQuoteFailure = '[VariationLetter List] Update VariationLetter Quote Failure',

  GetQuoteDocumentSection = '[VariationLetter List] Get QuoteDocumentSection',
  GetQuoteDocumentSectionSuccess = '[VariationLetter List] Get QuoteDocumentSection Success',
  GetQuoteDocumentSectionFailure = '[VariationLetter List] Get QuoteDocumentSection Failure',

  UpdateQuoteDocumentSection = '[VariationLetter List] UpdateQuoteDocumentSection',
  UpdateQuoteDocumentSectionSuccess = '[VariationLetter List] UpdateQuoteDocumentSection Success',
  UpdateQuoteDocumentSectionFailure = '[VariationLetter List] UpdateQuoteDocumentSection Failure',
}

export const getVariationLetterQuote = createAction(
  VariationLetterQuoteActionTypes.GetVariationLetterQuote,
  props<{ variationLetterid: string }>(),
);
export const getVariationLetterQuoteSuccess = createAction(
  VariationLetterQuoteActionTypes.GetVariationLetterQuoteSuccess,
  props<{ variationLetterQuote: FullVariationQuote }>(),
);
export const getVariationLetterQuoteFailure = createAction(
  VariationLetterQuoteActionTypes.GetVariationLetterQuoteFailure,
  props<{ error: any }>(),
);

export const updateVariationLetterQuote = createAction(
  VariationLetterQuoteActionTypes.UpdateVariationLetterQuote,
  props<{ variation: Variation }>(),
);
export const updateVariationLetterQuoteSuccess = createAction(
  VariationLetterQuoteActionTypes.UpdateVariationLetterQuoteSuccess,
  props<{ variationLetterQuote: Variation }>(),
);
export const updateVariationLetterQuoteFailure = createAction(
  VariationLetterQuoteActionTypes.UpdateVariationLetterQuoteFailure,
  props<{ error: any }>(),
);

// quote document sections
export const getQuoteDocumentSection = createAction(
  VariationLetterQuoteActionTypes.GetQuoteDocumentSection,
  props<{ estimateId: string; variationId: string }>(),
);
export const getQuoteDocumentSectionSuccess = createAction(
  VariationLetterQuoteActionTypes.GetQuoteDocumentSectionSuccess,
  props<{ quoteDocumentSections: VariationSection[] }>(),
);
export const getQuoteDocumentSectionFailure = createAction(
  VariationLetterQuoteActionTypes.GetQuoteDocumentSectionFailure,
  props<{ error: any }>(),
);

export const updateQuoteDocumentSection = createAction(
  VariationLetterQuoteActionTypes.UpdateQuoteDocumentSection,
  props<{ quoteSections: VariationSection[] }>(),
);
export const updateQuoteDocumentSectionSuccess = createAction(
  VariationLetterQuoteActionTypes.UpdateQuoteDocumentSectionSuccess,
  props<{ quoteDocumentSections: VariationSection[] }>(),
);
export const updateQuoteDocumentSectionFailure = createAction(
  VariationLetterQuoteActionTypes.UpdateQuoteDocumentSectionFailure,
  props<{ error: any }>(),
);
