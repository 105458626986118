import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ABNPipe } from './abn.pipe';
import { AddressPipe } from './address.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { PriceListCategoryCodePipe } from './price-category-code.pipe';
import { SearchArrayItemPipe } from './filter-array.pipe';
import { GroupingArrayByValuePipe } from './grouping-array-by-value.pipe';
import { JobTitlePipe } from './job-title.pipe';
import { LogoNmaePipe } from './logo-name.pipe';
import { EnumIdToValuePipe } from './enum-id-to-value.pipe';
import { JobVariationSectionFilterFilterPipe } from './job-variation-section.pipe';
import { FilterArrayByElementPipe } from './filter-array-by-element.pipe';
import { FilterStatusesByCfTypePipe } from '@shared/pipes/filter-statuses-by-cf-type.pipe';
import { SortCfitemsPipe } from '@shared/pipes/sort-cf-array.pipe';

const pipes = [
  AddressPipe,
  ABNPipe,
  PhoneNumberPipe,
  PriceListCategoryCodePipe,
  SearchArrayItemPipe,
  GroupingArrayByValuePipe,
  JobTitlePipe,
  LogoNmaePipe,
  EnumIdToValuePipe,
  JobVariationSectionFilterFilterPipe,
  FilterArrayByElementPipe,
  FilterStatusesByCfTypePipe,
  SortCfitemsPipe
];

@NgModule({
  imports: [CommonModule, ...pipes],
  exports: pipes,
  providers: pipes,
})
export class SharedPipesModule {}
