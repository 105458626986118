import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeavePageService } from 'app/shared/services/leave-page.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JobVariationLetterFormService {
  public variationForm: FormGroup = this.fb.group({
    layoutId: null,
    logo: null,
    sent: null,
    footer: null,
    intro: null,
    tandC: null,
    validDays: [null, Validators.required],
  });
  public quoteSectionsForm: FormGroup = this.fb.group({
    sections: this.fb.array([]),
  });
  private formReseting$ = new Subject();
  public changedSectionsIndexes: number[] = [];
  public formUpdated$ = new BehaviorSubject(false);
  public quoteFormUpdated$ = new BehaviorSubject(false);
  formChanged = false;
  quoteFormChanged = false;
  isReadOnlyMode;

  constructor(
    private fb: FormBuilder,
    private leavePageService: LeavePageService,
  ) {}

  buildForm(quoteData) {
    this.reset();
    this.variationForm.patchValue(quoteData.quote);
    if (this.isReadOnlyMode) {
      Object.keys(this.variationForm.controls).forEach((key) => {
        this.variationForm.controls[key].disable({ onlySelf: true });
      });
    }
    this.formUpdated$.next(true);
  }

  buildQuoteSectionForm(sections) {
    if (sections?.length) {
      const isUpdate = this.quoteSectionsForm.get('sections').value.length;

      sections.forEach((section, index) => {
        let group;
        if (!isUpdate) {
          group = this.fb.group({
            id: null,
            name: null,
            sectionFooter: null,
            sectionHeader: null,
            showCategories: null,
            showCategoryTotals: null,
            showItemTotals: null,
            showItems: null,
            showSectionTotal: null,
            jobCostingId: null,
          });
        } else {
          group = (this.quoteSectionsForm.get('sections') as FormArray).at(
            index,
          );
        }

        if (this.isReadOnlyMode) {
          Object.keys(group.controls).forEach((key) => {
            group.controls[key].disable({ onlySelf: true });
          });
        } else {
          group
            .get('showCategories')
            .valueChanges.pipe(takeUntil(this.formReseting$))
            .subscribe((value) => {
              if (!value) {
                group.get('showCategoryTotals').disable();
                group.get('showCategoryTotals').setValue(false);
              } else {
                group.get('showCategoryTotals').enable();
              }
            });

          group
            .get('showItems')
            .valueChanges.pipe(takeUntil(this.formReseting$))
            .subscribe((value) => {
              if (!value) {
                group.get('showItemTotals').disable();
                group.get('showItemTotals').setValue(false);
              } else {
                group.get('showItemTotals').enable();
              }
            });
        }

        group.patchValue(section, { emitEvent: false });
        group.valueChanges
          .pipe(take(1), takeUntil(this.formReseting$))
          .subscribe(() => this.changedSectionsIndexes.push(index));

        if (!isUpdate) {
          (this.quoteSectionsForm.get('sections') as FormArray).push(group);
        }
      });
      this.quoteFormUpdated$.next(true);

      this.quoteSectionsForm.valueChanges.subscribe(() => {
        this.leavePageService.needSave = true;
        this.quoteFormChanged = true;
      });
    }
  }

  reset(isDestroy?: boolean) {
    this.formReseting$.pipe(take(1)).subscribe(() => {
      this.changedSectionsIndexes = [];
      this.variationForm.reset();
      this.quoteSectionsForm.reset();
      this.leavePageService.needSave = false;
      this.formChanged = false;
      this.quoteFormChanged = false;
    });
    this.formUpdated$.next(false);
    this.formReseting$.next(null);
    if (isDestroy) {
      this.formReseting$.complete();
    }
  }
}
