<!--<div class="wrapper h-full -m-6 p-6" [ngStyle]="{ 'max-height': '80vh' }">-->
  <div
    class="xs:text-3xl sm:text-4xl font-extrabold tracking-tight leading-none border-b pb-3 mb-2"
  >
    Details
  </div>
  <mat-dialog-content class="overflow-y-auto">
  <div [formGroup]="form" class="max-h-50vh overflow-y-auto">
    <!--  Name  -->
    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <mat-icon
        class="icon-size-5 opacity-100 text-primary-500 dark:text-primary-100"
        matPrefix
        maxlength="128"
        [svgIcon]="'heroicons_outline:calendar'"
      ></mat-icon>
      <input
        formControlName="jobTitle"
        matInput
        [autocomplete]="'off'"
        placeholder="Type an Estimate name"
      />
      <mat-error *ngIf="form.get('jobTitle').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <!--  Customer  -->
    <ng-container formGroupName="customerGroup">
      <mat-form-field
        class="w-full"
        matAutocompleteOrigin
        #origin="matAutocompleteOrigin"
      >
        <mat-label>Customer</mat-label>
        <mat-icon
          class="icon-size-5 opacity-100 text-primary-500 dark:text-primary-100"
          matPrefix
          [svgIcon]="'heroicons_outline:magnifying-glass'"
        ></mat-icon>
        <input
          matInput
          placeholder="Type to search a customer by name"
          formControlName="customerSearch"
          [matAutocomplete]="auto"
          [matAutocompleteConnectedTo]="origin"
        />
        <mat-error
          *ngIf="customerGroup.get('customerSearch')?.hasError('required')"
        >
          This field is required
        </mat-error>
        <mat-error
          *ngIf="customerGroup.get('customerSearch')?.hasError('invalidInput')"
        >
          The customer isn't recognized. Click one of the autocomplete options.
        </mat-error>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let customer of customers"
          [value]="customer.person.fullName"
          (onSelectionChange)="onCustomerChange(customer)"
        >
          {{ customer.person.fullName }}
          <span class="ml-2 text-gray-600">{{
            customer.address.streetAddress
          }}</span>
        </mat-option>
      </mat-autocomplete>
    </ng-container>

    <!--  Address  -->
    <ng-container formGroupName="addressGroup">
      <mat-form-field class="w-full" floatLabel="always">
        <mat-label>Address lookup</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:magnifying-glass'"
          matPrefix
        ></mat-icon>
        <input
          formControlName="formattedAddress"
          matInput
          addressLookup
          maxlength="256"
          (addressSelected)="addressSelected($event)"
          placeholder="Type to search for an address"
        />
        <mat-error
          *ngIf="
            form
              .get('addressGroup')
              .get('formattedAddress')
              .hasError('required')
          "
        >
          This field is required
        </mat-error>
      </mat-form-field>

      <div class="">
        <mat-form-field class="w-full">
          <mat-label>Address</mat-label>
          <input
            type="text"
            matInput
            [autocomplete]="'off'"
            formControlName="streetAddress"
            placeholder="Type an address"
            maxlength="128"
          />
          <mat-error
            *ngIf="
              form.get('addressGroup').get('streetAddress').hasError('required')
            "
          >
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-wrap flex-shrink-0 flex-row">
        <div class="xs:w-full xs:pr-0 sm:w-1/2 sm:pr-2">
          <mat-form-field class="w-full">
            <mat-label>Suburb</mat-label>
            <input
              type="text"
              matInput
              [autocomplete]="'off'"
              formControlName="suburb"
              placeholder="Type a suburb"
              maxlength="64"
            />
            <mat-error
              *ngIf="
                form.get('addressGroup').get('suburb').hasError('required')
              "
            >
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="xs:w-1/2 xs:pr-2 sm:w-1/4">
          <mat-form-field class="w-full">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
              <mat-option *ngFor="let state of states" [value]="state">
                {{ state }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="form.get('addressGroup').get('state').hasError('required')"
            >
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="xs:w-1/2 sm:w-1/4">
          <mat-form-field class="w-full">
            <mat-label>Postcode</mat-label>
            <input
              type="text"
              matInput
              [autocomplete]="'off'"
              formControlName="postCode"
              maxlength="4"
              placeholder="Type a postcode"
            />
            <mat-error
              *ngIf="
                form.get('addressGroup').get('postCode').hasError('required')
              "
            >
              This field is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <!--  Description  -->
    <div class="flex items-center">
      <div class="mr-2">Description</div>
      <app-speach-to-text
        [isClearText]="true"
        formControlName="description"
      ></app-speach-to-text>
    </div>
    <mat-form-field class="fuse-mat-no-subscript fuse-mat-textarea w-full">
      <textarea
        formControlName="description"
        matInput
        placeholder="Type description"
        maxlength="1024"
      ></textarea>
    </mat-form-field>
  </div>
  </mat-dialog-content>
  <div class="flex flex-row justify-end mt-6">
    <button (click)="close()" class="fuse-mat-button-large" mat-stroked-button>
      <span class="ml-2 mr-1">Cancel</span>
    </button>
    <button
      [disabled]="(form.dirty && form.invalid) ||
        (customerGroup.invalid && customerGroup.dirty) ||
        (addressGroup.dirty && addressGroup.invalid) ||
        (addressGroup.pristine && addressGroup.pristine && form.pristine)
      "
      (click)="save()"
      class="ml-4 fuse-mat-button-large"
      mat-flat-button
      [color]="'primary'"
    >
      <span class="ml-2 mr-1">Save</span>
    </button>
  </div>
<!--</div>-->
