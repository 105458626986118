<div class="w-full max-w-3xl">
  <div class="container mx-auto">
    <div class="w-full max-w-3xl">
      <ng-container>
        <form
          class="flex flex-col mt-4 bg-card shadow rounded overflow-hidden"
          [formGroup]="profileForm"
          (submit)="saveProfile()"
          novalidate
        >
          <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Your details</p>
            <p class="text-secondary">Team member information</p>
          </div>
          <div class="flex flex-col p-8">
            <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
              <!-- First name -->
              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>First name</mat-label>
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:user'"
                    matPrefix
                  ></mat-icon>
                  <input
                    formControlName="firstName"
                    maxlength="30"
                    matInput
                    required
                  />
                  <mat-error>First name required</mat-error>
                </mat-form-field>
              </div>
              <!-- Last name -->
              <div class="sm:col-span-2">
                <mat-form-field class="w-full">
                  <mat-label>Last name</mat-label>
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:user'"
                    matPrefix
                  ></mat-icon>
                  <input
                    formControlName="lastName"
                    maxlength="30"
                    matInput
                    required
                  />
                  <mat-error>Last name required</mat-error>
                </mat-form-field>
              </div>
              <!-- Email -->
              <div class="sm:col-span-4">
                <mat-form-field class="w-full">
                  <mat-label>Email</mat-label>
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:envelope'"
                    matPrefix
                  ></mat-icon>
                  <input
                    formControlName="email"
                    maxlength="254"
                    matInput
                    required
                  />
                  <mat-error>Email required</mat-error>
                </mat-form-field>
              </div>
              <!-- Mobile number -->
              <div class="sm:col-span-4">
                <label>Phone number</label>
                <mat-form-field class="w-full">
                  <mat-icon
                    class="icon-size-5"
                    [svgIcon]="'heroicons_outline:phone'"
                    matPrefix
                  >
                  </mat-icon>
                  <input
                    matInput
                    formControlName="phone"
                    type="tel"
                    placeholder="e.g. 0733331111"
                    [pattern]="phonePattern"
                    maxlength="20"
                    required
                  />
                  <mat-error>Phone number required</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- Actions -->

          <div
            class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700"
          >
            <button
              class="px-6 ml-3"
              [color]="'primary'"
              [disabled]="
                !profileForm.dirty || profileForm.invalid || isReadOnly
              "
              mat-flat-button
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
