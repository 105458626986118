import {
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AddressLookup } from '../models';

declare let google: any;

@Directive({
  selector: 'input[addressLookup]',
  standalone: true,
})
export class AddressLookupDirective implements OnInit {
  @Output() addressSelected = new EventEmitter<AddressLookup>();

  inputElement: HTMLInputElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  ngOnInit(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.inputElement,
      { types: ['address'], componentRestrictions: { country: 'au' } },
    );
    autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
      const address: AddressLookup = {};
      place.address_components?.forEach((el) => {
        if (el.types.includes('administrative_area_level_1')) {
          address.state = el.short_name;
        } else if (el.types.includes('country')) {
          address.country = el.long_name;
        } else if (el.types.includes('postal_code')) {
          address.postCode = el.long_name;
        } else if (el.types.includes('locality')) {
          address.suburb = el.long_name;
        }
      });
      address.formattedAddress = place?.formatted_address;
      address.locationType = place?.types?.length > 0 ? place.types[0] : null;
      address.streetAddress = place?.formatted_address
        ?.split(',')
        ?.slice(-3)[0]
        .trim();
      address.latitude = place.geometry?.location?.lat();
      address.longitude = place.geometry?.location?.lng();
      address.placeId = place.place_id;

      this.addressSelected.emit(address);
    });
  }
}
