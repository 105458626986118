<div class="mb-2.5">
  <mat-expansion-panel
    [ngClass]="{
      'mb-0 flex flex-col full-height-panel': singleMode,
      'h-full': panelOpenState,
    }"
    [expanded]="expanded"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    hideToggle
  >
    <mat-expansion-panel-header class="h-fit">
      <div class="w-full py-4 flex items-center justify-between">
        <div class="flex flex-wrap items-center w-full">
          <div class="mr-5">
            <mat-icon [ngClass]="{ 'rotate-180': panelOpenState }">
              expand_less
            </mat-icon>
          </div>
          <div>{{ section.order }}.</div>
          <div class="ml-4 mr-5 flex-1">
            <div
              class="flex flex-row justify-between p-2 items-center rounded border border-gray-300 dark:border-gray-500"
            >
              <span [innerHTML]="section.name"></span>
              <button
                *ngIf="!readonly"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  onEditName()
                "
                class="px-3 text-xs min-h-8 h-8 mat-button mat-button-base mat-primary"
                mat-button
                color="primary"
              >
                <span class="text-primary xs:hidden sm:block"
                  >Click to edit</span
                >
              </button>
            </div>
          </div>
          <ng-container>
            <button
              class="xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
              *ngIf="!readonly"
              (click)="addRemoveItems($event)"
              matRipple
              mat-flat-button
              color="primary"
            >
              <span class="xs:hidden sm:block ml-2 mr-1">Manage - Items</span>
            </button>
          </ng-container>

          <button
            class="xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
            *ngIf="!readonly"
            (click)="removeItemsFromCosting($event)"
            matRipple
            mat-flat-button
            color="primary"
          >
            <span class="xs:hidden sm:block ml-2 mr-1"
              >Manage - Original Quote Items</span
            >
          </button>
          <div class="flex items-center ml-4" *ngIf="!singleMode">
            <div class="flex items-center" id="estimate-reorder-buttons">
              <button
                *ngIf="!readonly"
                class="mr-1"
                [disabled]="!canMoveDown"
                [matTooltip]="'Move section down'"
                (click)="moveDown(section.id, $event)"
                mat-icon-button
              >
                <mat-icon
                  class="icon-size-5 text-secondary"
                  [svgIcon]="'heroicons_outline:arrow-down-circle'"
                  aria-hidden="true"
                  matPrefix
                ></mat-icon>
              </button>
              <button
                *ngIf="!readonly"
                class="mr-1"
                [disabled]="!canMoveUp"
                [matTooltip]="'Move section up'"
                (click)="moveUp(section.id, $event)"
                mat-icon-button
              >
                <mat-icon
                  class="icon-size-5 text-secondary"
                  [svgIcon]="'heroicons_outline:arrow-up-circle'"
                  aria-hidden="true"
                  matPrefix
                ></mat-icon>
              </button>
            </div>
            <button
              *ngIf="!(readonly || singleMode)"
              class="mr-1"
              [matTooltip]="'Delete section'"
              (click)="deleteSection(section.id, $event)"
              mat-icon-button
            >
              <mat-icon
                class="icon-size-5 dark:text-white"
                [svgIcon]="'heroicons_outline:trash'"
                aria-hidden="true"
                matPrefix
              ></mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div
      *ngIf="section?.items?.length > 0; else emptySection"
      [ngClass]="{ 'h-full': singleMode }"
    >
      <div class="h-full flex flex-col items-stretch">
        <div
          class="rounded-lg overflow-hidden flex flex-col items-stretch h-full"
        >
          <form [formGroup]="filterForm">
            <div class="table-search-wrap mb-3" #tableSearch>
              <div class="flex flex-row items-center flex-wrap justify-between">
                <div class="w-full max-w-80">
                  <mat-form-field
                    class="fuse-mat-dense fuse-mat-no-subscript w-full min-w-50"
                  >
                    <mat-icon
                      class="h-4 min-h-4"
                      [svgIcon]="'heroicons_outline:magnifying-glass'"
                      matPrefix
                    >
                    </mat-icon>
                    <input
                      [formControlName]="'searchTerm'"
                      [autocomplete]="'off'"
                      [placeholder]="'Search anything...'"
                      (keydown.enter)="$event.preventDefault()"
                      trim="blur"
                      matInput
                    />
                    <button
                      *ngIf="searchTerm.value"
                      (click)="searchTerm.setValue('')"
                      matSuffix
                      mat-icon-button
                      aria-label="Clear"
                    >
                      <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div class="flex flex-row items-center flex-wrap">
                  <div class="w-40 sm:w-64 mr-0 sm:mr-4 py-1">
                    <mat-form-field class="fuse-mat-no-subscript w-full">
                      <mat-select [formControlName]="'supplierId'">
                        <mat-option [value]="''">All suppliers</mat-option>
                        <mat-option
                          *ngFor="let supplier of suppliers"
                          [value]="supplier.id"
                        >
                          {{ supplier.name }}
                        </mat-option>
                      </mat-select>
                      <mat-icon
                        class="icon-size-5 dark:text-white"
                        [svgIcon]="'heroicons_outline:tag'"
                        matPrefix
                        aria-hidden="true"
                        matPrefix
                      ></mat-icon>
                    </mat-form-field>
                  </div>
                  <div class="w-40 sm:w-64">
                    <mat-form-field class="fuse-mat-no-subscript w-full">
                      <mat-select [formControlName]="'categoryId'">
                        <mat-option [value]="''">All categories</mat-option>
                        <mat-option
                          *ngFor="let category of categories"
                          [value]="category.id"
                        >
                          {{ category.code ?? "00" }} - {{ category.name }}
                        </mat-option>
                      </mat-select>
                      <mat-icon
                        class="icon-size-5 dark:text-white"
                        [svgIcon]="'heroicons_outline:map-pin'"
                        matPrefix
                        aria-hidden="true"
                        matPrefix
                      ></mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <cdk-virtual-scroll-viewport
            *ngIf="!showMobile"
            class="wrapper mat-elevation-z2"
            [ngStyle]="{
              minHeight: singleMode
                ? 'calc(100% - ' + tableSearch.offsetHeight + 'px)'
                : 'unset',
            }"
            tvsItemSize
          >
            <table [dataSource]="dataSource" mat-table>
              <tr
                *matHeaderRowDef="displayedColumns; sticky: true"
                mat-header-row
              ></tr>
              <tr
                class="hover:bg-gray-400 dark:hover:bg-slate-700"
                [class.cursor-pointer]="!readonly"
                *matRowDef="let row; columns: displayedColumns"
                mat-row
              ></tr>

              <ng-container matColumnDef="category">
                <th *matHeaderCellDef mat-header-cell>Category</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.priceListCategoryCode }} -
                  {{ element.priceListCategoryName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="itemcode">
                <th *matHeaderCellDef mat-header-cell>Item Code</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.itemCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th *matHeaderCellDef mat-header-cell>Description</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.description }}
                </td>
              </ng-container>

              <ng-container matColumnDef="supplier">
                <th *matHeaderCellDef mat-header-cell>Supplier</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.supplier?.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="qty">
                <th *matHeaderCellDef mat-header-cell>Qty</th>
                <td *matCellDef="let element; let i = index" mat-cell>
                  {{ element.qty }}
                </td>
              </ng-container>

              <ng-container matColumnDef="uom">
                <th *matHeaderCellDef mat-header-cell>UOM</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.unitOfMeasure }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unitPrice">
                <th *matHeaderCellDef mat-header-cell>Unit price</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.unitPrice | currency }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unitTotal">
                <th *matHeaderCellDef mat-header-cell>Unit total</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.unitTotal | currency }}
                </td>
              </ng-container>

              <ng-container matColumnDef="onCost">
                <th *matHeaderCellDef mat-header-cell>On cost</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.onCost.toFixed(2) }}%
                </td>
              </ng-container>

              <ng-container matColumnDef="netQuote">
                <th *matHeaderCellDef mat-header-cell>Net quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.netQuote | currency }}
                </td>
              </ng-container>

              <ng-container matColumnDef="quotable">
                <th *matHeaderCellDef mat-header-cell>Quotable</th>
                <td *matCellDef="let element" mat-cell>
                  <mat-checkbox
                    [checked]="element.quotable"
                    (change)="setQuotable(element)"
                    (click)="readonly ? $event.preventDefault() : null"
                    color="primary"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <ng-container *ngIf="!readonly" matColumnDef="action">
                <th class="w-28" *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let element" mat-cell>
                  <div class="flex xs:w-full sm:w-1/6 items-center xs:ml-auto">
                    <button
                      [disabled]="element.qty < 0"
                      (click)="editItem(element)"
                      mat-icon-button
                    >
                      <mat-icon
                        class="icon-size-5 text-secondary"
                        [svgIcon]="'heroicons_outline:pencil'"
                        aria-hidden="true"
                        matPrefix
                      ></mat-icon>
                    </button>
                    <button
                      (click)="deleteItem(element.id, $event)"
                      mat-icon-button
                    >
                      <mat-icon
                        class="icon-size-5 text-secondary"
                        [svgIcon]="'heroicons_outline:trash'"
                        aria-hidden="true"
                        matPrefix
                      ></mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
            </table>
          </cdk-virtual-scroll-viewport>

          <!-- tablet and mobile list  -->
          <div
            class="flex flex-col flex-auto wrapper overflow-y-auto"
            *ngIf="showMobile"
          >
            <fuse-card
              class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2 dark:bg-gray-900"
              *ngFor="
                let element of dataSource?.data
                  | jobVariationSectionFilter: dataSource.filter;
                let i = index
              "
            >
              <div class="flex flex-col xs:w-full sm:w-1/3">
                <div class="text-md font-semibold leading-tight">
                  Description: {{ element.description }} -
                  {{ element.priceListCategoryName }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Category: {{ element.priceListCategoryCode }} -
                  {{ element.priceListCategoryName }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Item Code: {{ element.itemCode }}
                </div>
              </div>

              <div
                class="flex flex-col xs:w-full sm:w-1/4 sm:mt-0 sm:ml-4 xs:ml-0 xs:mt-2"
              >
                <div class="text-md font-semibold leading-tight">
                  Qty: {{ element.qty }}
                </div>
                <div class="text-secondary font-medium text-md">
                  UOM: {{ element.unitOfMeasure }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Supplier: {{ element.supplier?.name }}
                </div>
              </div>

              <div
                class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-4 xs:ml-0 xs:mt-2"
              >
                <div class="text-md font-semibold leading-tigh">
                  Unit total: {{ element.unitTotal | currency }}
                </div>
                <div class="text-secondary font-medium text-md">
                  Unit price: {{ element.unitPrice | currency }}
                </div>
                <div class="text-secondary font-medium text-md">
                  On cost: {{ element.onCost.toFixed(2) }}%
                </div>
                <div class="text-secondary font-medium text-md">
                  Net quote: {{ element.netQuote | currency }}
                </div>
              </div>

              <div
                *ngIf="!readonly"
                class="flex xs:w-full sm:w-1/6 items-center xs:ml-auto"
              >
                <button
                  class="type-action"
                  [id]="'estimate-summary-edit' + i"
                  [color]="'primary'"
                  [disabled]="element.qty < 0"
                  (click)="editItem(element)"
                  mat-stroked-button
                >
                  <mat-icon [svgIcon]="'edit'" matPrefix></mat-icon>
                </button>

                <button
                  (click)="deleteItem(element.id, $event)"
                  mat-icon-button
                >
                  <mat-icon
                    class="icon-size-5 text-secondary"
                    [svgIcon]="'heroicons_outline:trash'"
                    aria-hidden="true"
                    matPrefix
                  ></mat-icon>
                </button>
              </div>
            </fuse-card>
          </div>
        </div>
      </div>
    </div>
    <ng-template #emptySection>
      <div class="p-4 text-secondary italic bg-card dark:bg-transparent">
        <p>No items added yet...</p>
        <p>
          To add items to this section click the 'Manage - Items' or the 'Manage
          - Original Quote Items' button.
        </p>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
