import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectVariationLetterQuoteState = createSelector(
  selectJobState,
  (s: JobState) => s.variationLetter,
);

export const selectVariationLetterQuoteIsLoading = createSelector(
  selectVariationLetterQuoteState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectVariationLetterQuoteIsLoaded = createSelector(
  selectVariationLetterQuoteState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectVariationLetterQuoteError = createSelector(
  selectVariationLetterQuoteState,
  (s) => getError(s.callState),
);

export const selectVariationLetterQuote = createSelector(
  selectVariationLetterQuoteState,
  (s) => s.variationLetterQuote,
);
export const selectVariationLetterQuoteSections = createSelector(
  selectVariationLetterQuoteState,
  (s) => s.variationLetterQuote?.sections,
);
export const selectVariationLetterQuoteVariation = createSelector(
  selectVariationLetterQuoteState,
  (s) => s.variationLetterQuote?.variation,
);
export const selectQuoteDocumentSections = createSelector(
  selectVariationLetterQuoteState,
  (s) => s.quoteDocumentSections,
);
