import { createAction, props } from '@ngrx/store';
import {
  Job,
  JobParameters,
  JobType,
  PaginatedListOfJob,
} from 'app/shared/models';

export enum JobListActionTypes {
  GetJobs = '[Job List] Get Jobs',
  GetJobsSuccess = '[Job List] Get Jobs Success',
  GetJobsFailure = '[Job List] Get Jobs Failure',
  GetRecentJobsAction = '[Job Recent List] Get Recent Jobs',
  GetRecentJobsSuccessAction = '[Job Recent List] Get Recent Jobs Success',
  GetRecentJobsFailureAction = '[Job Recent List] Get Recent Jobs Failure',
  ClearJobs = '[Job List] Clear Jobs',
}

export const getJobs = createAction(
  JobListActionTypes.GetJobs,
  props<{ request: JobParameters }>(),
);

export const getJobsSuccess = createAction(
  JobListActionTypes.GetJobsSuccess,
  props<{ jobs: PaginatedListOfJob }>(),
);

export const getJobsFailure = createAction(
  JobListActionTypes.GetJobsFailure,
  props<{ error: any }>(),
);

// get recent jobs
export const getRecentJobs = createAction(
  JobListActionTypes.GetRecentJobsAction,
  props<{ jobType: JobType }>(),
);

export const getRecentJobsSuccess = createAction(
  JobListActionTypes.GetRecentJobsSuccessAction,
  props<{ recentJobs: Job[] }>(),
);

export const getRecentJobsFailure = createAction(
  JobListActionTypes.GetRecentJobsFailureAction,
  props<{ error: any }>(),
);

export const clearJobs = createAction(JobListActionTypes.ClearJobs);
