import { AsyncPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SingleSaveVariation } from 'app/shared/models';

import {
  addSelectedItems,
  deselectAllEstimateListItems,
  deselectAllPriceListItems,
  filterItems,
  removeSelectedItems,
  selectAllEstimateListItems,
  selectAllPriceListItems,
  selectCategory,
  showDialog,
  sortEstimateListItems,
  sortPriceListItems,
  toggleItemSelected,
} from '../actions';
import {
  selectJobVariationAddRemoveCostingAddedItems,
  selectJobVariationAddRemoveCostingAllEstimateListItemsSelected,
  selectJobVariationAddRemoveCostingAllPriceListItemsSelected,
  selectJobVariationAddRemoveCostingCanAdd,
  selectJobVariationAddRemoveCostingCanRemove,
  selectJobVariationAddRemoveCostingCategories,
  selectJobVariationAddRemoveCostingEstimateListItems,
  selectJobVariationAddRemoveCostingEstimateListSorting,
  selectJobVariationAddRemoveCostingPriceListItems,
  selectJobVariationAddRemoveCostingPriceListSorting,
  selectJobVariationAddRemoveCostingRemovedItems,
  selectJobVariationAddRemoveCostingSection,
  selectJobVariationAddRemoveCostingSelectedCategoryId,
  selectJobVariationAddRemoveCostingVariation,
  selectJobVariationIsItemSelected,
} from '../selectors';
import { JobDetailFacade } from './job-detail.facade';

@Injectable({
  providedIn: 'root',
})
export class JobVariationAddRemoveCostingFacade {
  variation$ = this.store.select(selectJobVariationAddRemoveCostingVariation);
  section$ = this.store.select(selectJobVariationAddRemoveCostingSection);
  priceListItems$ = this.store.select(
    selectJobVariationAddRemoveCostingPriceListItems,
  );

  estimateListItems$ = this.store.select(
    selectJobVariationAddRemoveCostingEstimateListItems,
  );

  addedItems$ = this.store.select(selectJobVariationAddRemoveCostingAddedItems);
  removedItems$ = this.store.select(
    selectJobVariationAddRemoveCostingRemovedItems,
  );
  canAdd$ = this.store.select(selectJobVariationAddRemoveCostingCanAdd);
  canRemove$ = this.store.select(selectJobVariationAddRemoveCostingCanRemove);

  allPriceListItemsSelected$ = this.store.select(
    selectJobVariationAddRemoveCostingAllPriceListItemsSelected,
  );
  allEstimateListItemsSelected$ = this.store.select(
    selectJobVariationAddRemoveCostingAllEstimateListItemsSelected,
  );
  categories$ = this.store.select(selectJobVariationAddRemoveCostingCategories);
  selectedCategoryId$ = this.store.select(
    selectJobVariationAddRemoveCostingSelectedCategoryId,
  );
  priceListSorting$ = this.store.select(
    selectJobVariationAddRemoveCostingPriceListSorting,
  );
  estimateListSorting$ = this.store.select(
    selectJobVariationAddRemoveCostingEstimateListSorting,
  );

  itemSelected$ = (id: string) =>
    this.store.select(selectJobVariationIsItemSelected(id));

  constructor(
    private store: Store,
    private async: AsyncPipe,
    private jobDetailFacade: JobDetailFacade,
  ) {}

  sortPriceListItems(sortField: string, sortOrder: 'asc' | 'desc') {
    this.store.dispatch(sortPriceListItems({ sortField, sortOrder }));
  }

  sortEstimateListItems(sortField: string, sortOrder: 'asc' | 'desc') {
    this.store.dispatch(sortEstimateListItems({ sortField, sortOrder }));
  }

  filterItems(searchTerm: string) {
    this.store.dispatch(filterItems({ filter: searchTerm }));
  }

  remove() {
    this.store.dispatch(removeSelectedItems());
  }

  setFilter(filter: string) {
    this.store.dispatch(filterItems({ filter }));
  }

  add() {
    this.store.dispatch(addSelectedItems());
  }

  selectCategory(id: string) {
    this.store.dispatch(selectCategory({ id }));
  }

  selectAllPriceListItems() {
    this.store.dispatch(selectAllPriceListItems());
  }

  deselectAllPriceListItems() {
    this.store.dispatch(deselectAllPriceListItems());
  }

  selectAllEstimateListItems() {
    this.store.dispatch(selectAllEstimateListItems());
  }

  deselectAllEstimateListItems() {
    this.store.dispatch(deselectAllEstimateListItems());
  }

  toggleItemSelected(id: string) {
    this.store.dispatch(toggleItemSelected({ id }));
  }

  showDialog(variation: SingleSaveVariation, sectionId: string) {
    const section = variation.sections.find((s) => s.id === sectionId);
    const estimateListItems = section.items.filter((i) => i.qty < 0 && i.jobCostingPriceListItemId);

    const allVariationItems = [];
    variation.sections.forEach((s) => allVariationItems.push(...s.items));
    const priceListItems = this.async
      .transform(this.jobDetailFacade.originalItems$)
      .filter(
        (item) => !allVariationItems.find((i) => i.originalItemId === item.id),
      );

    this.store.dispatch(
      showDialog({
        variation,
        section,
        estimateListItems,
        priceListItems,
      }),
    );
  }
}
