/* eslint-disable arrow-body-style */
import { Action, createReducer, on } from '@ngrx/store';
import { Supplier, PaginatedListOfSupplier } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import * as fromActions from '../actions/supplier-list.actions';

export interface SupplierListState {
  supplierList: PaginatedListOfSupplier;
  callState: CallState;
  error: any;
}

export const initialState: SupplierListState = {
  supplierList: { items: [], pageMetaData: {} },
  error: null,
  callState: LoadingState.INIT,
};

export const profileReducer = createReducer(
  initialState,
  on(fromActions.getSupplierList, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.getSupplierListFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.getSupplierListSuccess, (state, { payload }) => ({
    ...state,
    supplierList: payload as PaginatedListOfSupplier,
    callState: LoadingState.LOADED,
  })),

  on(fromActions.addSupplier, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.addSupplierFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.addSupplierSuccess, (state, { payload }) => ({
    ...state,
    supplierList: {
      items: [...state.supplierList.items, ...[payload as Supplier]],
      pageMetaData: {
        ...state.supplierList.pageMetaData,
        ...{ records: state.supplierList.pageMetaData.records + 1 },
      },
    },
    callState: LoadingState.LOADED,
  })),

  on(fromActions.addXeroSupplier, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.addXeroSupplierFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.addXeroSupplierSuccess, (state, { payload }) => ({
    ...state,
    supplierList: {
      items: [...state.supplierList.items, ...[payload as Supplier]],
      pageMetaData: {
        ...state.supplierList.pageMetaData,
        ...{ records: state.supplierList.pageMetaData.records + 1 },
      },
    },
    callState: LoadingState.LOADED,
  })),
);

export function reducer(state: SupplierListState | undefined, action: Action) {
  return profileReducer(state, action);
}

export const getSupplierListPageNumber = (state: SupplierListState) => {
  return state.supplierList.pageMetaData.pageNumber;
};

export const getSupplierList = (state: SupplierListState) => {
  return state.supplierList;
};

export const getSupplierListItems = (state: SupplierListState) => {
  return state.supplierList.items;
};

export const getSupplierTotalCount = (state: SupplierListState) => {
  return state.supplierList.pageMetaData.records;
};

export const getError = (state: SupplierListState) => {
  return state.error;
};

export const getSupplierListIsLoaded = (state: SupplierListState): boolean => {
  return state.callState === LoadingState.LOADED;
};
