<div class="h-8 min-h-8 bg-card rounded-xl flex">
  <button
    *ngFor="let status of statuses; let last = last"
    (click)="selectStatus(status)"
    class="font-medium border-none rounded-xl p-2 text-xs whitespace-nowrap"
    [class.cursor-default]="!status.validForTransactions"
    [ngClass]="{
      'mr-2': !last,
      'bg-blue-600':
        (status.value === 10 || status.value === 60) &&
        selected === status.value,
      'bg-emerald-500': status.value === 50 && selected === status.value,
      'bg-orange-400 dark:bg-orange-500':
        (status.value === 80 || status.value === 30) &&
        selected === status.value,
      'bg-pink-500':
        (status.value === 70 || status.value === 20) &&
        selected === status.value,
      'bg-emerald-400': status.value === 90 && selected === status.value,
      'bg-gray-500':
        (status.value === 100 || status.value === 40) &&
        selected === status.value,
        'bg-red-600':
        (status.value === 110) && selected === status.value,
      'bg-inherit': selected !== status.value,
      'text-white': selected === status.value,
      'text-secondary': !status.validForTransactions,
      'dark:text-white': status.validForTransactions,
      'dark:text-slate-400': disable == status.value,
      'text-slate-400': disable == status.value,
    }"
  >
    <!-- hover:bg-gray-300 hover:text-white -->
    {{ status.name }}
  </button>
</div>
