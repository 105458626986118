import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileUpload } from 'app/shared/models';
import {
  fileUploadCancel,
  fileUploadRequest,
} from '../actions/file-upload.actions';
import {
  selectFileUploadComplete,
  selectFileUploadFiles,
} from '../selectors/file-upload.selectors';

@Injectable({
  providedIn: 'root',
})
export class FileUploadFacade {
  constructor(private store: Store) {}

  uploadFiles(files: FileUpload[]) {
    this.store.dispatch(fileUploadRequest({ files }));
  }

  cancelUpload(index: number) {
    this.store.dispatch(fileUploadCancel({ index }));
  }

  uploadComplete$ = this.store.select(selectFileUploadComplete);
  files$ = this.store.select(selectFileUploadFiles);
}
