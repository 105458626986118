import { createReducer, on } from '@ngrx/store';
import { CallState, LoadingState } from 'app/store';
import {
  getJobsOrderSummary,
  getJobsOrderSummaryFailure,
  getJobsOrderSummarySuccess,
} from '../actions';
import { JobOrderSummary } from '../../../../shared/models';

export interface JobOrderSummaryState {
  jobOrderSummary: any;
  callState: CallState;
  error: any;
}

export const initialState: JobOrderSummaryState = {
  jobOrderSummary: <any>{ summary: <JobOrderSummary>[] },
  callState: LoadingState.INIT,
  error: null,
};

export const jobOrderSummaryReducer = createReducer(
  initialState,
  on(getJobsOrderSummary, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobsOrderSummaryFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobsOrderSummarySuccess, (state, { jobOrderSummary }) => ({
    ...state,
    jobOrderSummary,
    callState: LoadingState.LOADED,
  })),
);
