import { UnitOfMeasure } from 'app/shared/models/eazimate.models';
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AddUnitOfMeasure } from 'app/shared/models';
import { Observable } from 'rxjs';

import * as fromActions from '../actions/general-settings.actions';
import * as fromSelectors from '../selectors/general-settings.selectors';

@Injectable({
  providedIn: 'root',
})
export class GeneralSettingsFacade {
  unitsOfMeasures$ = this.store.select(fromSelectors.selectUnitsOfMeasuresList);
  isLoading$ = this.store.select(fromSelectors.selectIsLoading);
  isInitialised$ = this.store.select(fromSelectors.selectInitialised);
  error$ = this.store.select(fromSelectors.selectError);

  profileSaved$: Observable<Action>;
  credentialsSaved$: Observable<Action>;

  initialise(): void {
    const requestParam = {
      pageNumber: -1,
      pageSize: 999,
      orderBy: [{ descending: false }],
    };

    this.store.dispatch(
      fromActions.getUnitOfMeasuresList({ payload: requestParam }),
    );
  }

  addUnitOfMeasure(payload: AddUnitOfMeasure): void {
    console.log('addUnitMeasure payload:::', payload);
    this.store.dispatch(fromActions.addUnitOfMeasure({ payload }));
  }

  updateUnitOfMeasure(payload: UnitOfMeasure): void {
    console.log('updateUnitOfMeasure payload:::', payload);
    this.store.dispatch(fromActions.updateUnitOfMeasure({ payload }));
  }

  deleteUnitOfMeasure(payload: string): void {
    console.log('deleteUnitOfMeasure payload:::', payload);
    this.store.dispatch(fromActions.deleteUnitOfMeasure({ payload }));
  }

  constructor(
    private store: Store,
    actions$: Actions,
  ) {
    this.initialise();
  }
}
