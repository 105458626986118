import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Message } from 'app/layout/common/messages/messages.types';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  private _messages: ReplaySubject<Message[]> = new ReplaySubject<Message[]>(1);

  /**
   * Constructor
   */

  constructor(
    private _httpClient: HttpClient,
    private toast: ToastService,
    ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for messages
   */
  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all messages
   */
  getAll(): Observable<Message[]> {
    const url = `${environment.urls.noticeApi}/api/ListUnreadMessages`;
    return this._httpClient.get<any>(url).pipe(
      tap((messages) => {
        let msg = JSON.parse(JSON.stringify(messages)) as Message[];
        this._messages.next(msg);
      }),
      catchError(err => {
        console.error('Fetching ListUnreadMessages err:', err);
        this._messages.next([]);
        this.toast.error( 'An error occurred while getting unread messages.');
        return of(null);
      })
    );
  }

  markAsRead(Id: string): Observable<any> {
    const ids = [Id];
    const url = `${environment.urls.noticeApi}/api/MarkMessageAsRead`;
    return this.messages$.pipe(
      take(1),
      switchMap((messages) =>
        this._httpClient.post(url, ids).pipe(
          map(() => {
            // Find the index of the deleted message
            const index = messages.findIndex((item) => item.Id === Id);

            // Delete the message
            messages.splice(index, 1);

            // Update the messages
            this._messages.next(messages);

            // Return the deleted status
            return;
          }),
        ),
      ),
    );
  }

  markAllAsRead(ids: string[]): Observable<any> {
    const url = `${environment.urls.noticeApi}/api/MarkMessageAsRead`;
    return this.messages$.pipe(
      take(1),
      switchMap((messages) =>
        this._httpClient.post(url, ids).pipe(
          map(() => {
            // Update the messages
            this._messages.next([]);

            // Return the deleted status
            return;
          }),
        ),
      ),
    );
  }
}
