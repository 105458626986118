<ng-container *ngIf="status$ | async as status">
  <div
    *ngIf="text$ | async as text"
    class="bg-pink-500 uppercase py-0.5 px-3 inline-block whitespace-nowrap rounded text-sm font-semibold text-white"
    [class]="class ?? ''"
    [ngClass]="{
      'bg-blue-600': status === 10 || status === 60,
      'bg-emerald-500': status === 50,
      'bg-orange-400 dark:bg-orange-500': status === 80 || status === 30,
      'bg-pink-500': status === 70 || status === 20,
      'bg-emerald-400': status === 90,
      'bg-gray-500': status === 100 || status === 40,
      'bg-red-500': status === 110,
    }"
  >
    {{ text }}
  </div>
</ng-container>
