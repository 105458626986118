import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { sortBy } from 'lodash';
import { selectJobState } from '../reducers';

export const selectJobVariationState = createSelector(
  selectJobState,
  (s) => s.variation,
);

export const selectJobVariationIsLoading = createSelector(
  selectJobVariationState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobVariationIsLoaded = createSelector(
  selectJobVariationState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobVariationError = createSelector(
  selectJobVariationState,
  (s) => getError(s.callState),
);

export const selectJobVariation = createSelector(
  selectJobVariationState,
  (s) => {
    return (
      s.variation && {
        ...s.variation,
        sections: sortBy(
          s.variation?.sections.map((section) => {
            return {
              ...section,
              items: sortBy(
                section.items,
                (i) => i.priceListCategoryName + i.itemCode + i.description,
              ),
            };
          }),
          (s) => s.order,
        ),
      }
    );
  },
);

export const selectJobVariationIsEditing = createSelector(
  selectJobVariationState,
  (s) => s.editing,
);
export const selectJobVariationStatus = createSelector(
  selectJobVariationState,
  (s) => s.status,
);
export const selectOriginatedVariation = createSelector(
  selectJobVariationState,
  (s) => s.originalVariation,
);
export const selectVariationChanges = createSelector(
  selectJobVariationState,
  (s) => s.variationChanges,
);
