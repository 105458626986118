import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BillingSettings } from 'app/shared/models';
import { map, switchMap } from 'rxjs/operators';
import { SettingsService } from '../../services/settings.service';
import { getBillingSettings, getBillingSettingsSuccess } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class BillingSettingsEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService,
  ) {}

  getBillingSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(getBillingSettings),
      switchMap(() =>
        this.settingsService
          .getBillingSettings()
          .pipe(
            map((settings: BillingSettings) =>
              getBillingSettingsSuccess({ settings }),
            ),
          ),
      ),
    ),
  );
}
