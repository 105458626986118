import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    ActivatedRoute,
    Router,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { AccountInvitesModule } from '@app/layout/common/account-invites/account-invites.module';
import { Role } from '@app/shared/models';
import {
    GuidedTourNameEnum,
    IntroJsService,
} from '@app/shared/services/introjs.service';
import { SharedModule } from '@app/shared/shared.module';
import { AccountUserFacade } from '@app/shared/store/facades';
import { BillingStatus } from '@app/views/settings/components/billing-settings/billing-settings.types';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseLoadingService } from '@fuse/services/loading';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { AccountsModule } from 'app/layout/common/accounts/accounts.module';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { SettingsIds } from 'app/shared/models/role-url.model';
import { Subject, filter, takeUntil, withLatestFrom } from 'rxjs';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        NotificationsComponent,
        UserComponent,
        NgIf,
        NgClass,
        AsyncPipe,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        RouterOutlet,
        QuickChatComponent,
        SharedModule,
        AccountsModule,
        AccountInvitesModule,
        AsyncPipe,
        RouterModule,
    ],
    providers: [AccountUserFacade, AsyncPipe],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    showIntrojs: GuidedTourNameEnum = null;
    showTour = false;
    planName: string;
    isReadOnly;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public introJsService: IntroJsService,
        public _fuseLoadingService: FuseLoadingService,
        public af: AccountUserFacade
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngAfterContentInit(): void {
        this._fuseLoadingService.show$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((x) => x != null)
            )
            .subscribe((x) => {
                setTimeout(() => {
                    this.showTour = x;
                }, 300);
            });

        this.introJsService.currentIntroPage$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((x) => {
                setTimeout(() => {
                    if (x !== GuidedTourNameEnum.Empty) {
                        this.showIntrojs = x;
                    }
                }, 300);
            });
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.introJsService.resetedTour$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((reset) => {
                if (reset) {
                }
            });
        // Subscribe to navigation data
        this.af.roles$
            .pipe(
                withLatestFrom(this._navigationService.navigation$),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(([roles, navigation]: [Role[], Navigation]) => {
                // make sure admin role is not accessible to anyone without specific roles
                if (
                    roles.filter(
                        (role) =>
                            role.name
                                .toLocaleLowerCase()
                                .includes('systemadmin') ||
                            role.name
                                .toLocaleLowerCase()
                                .includes('industryadmin')
                    ).length === 0
                ) {
                    navigation = {
                        ...navigation,
                        default: navigation.default.filter(
                            (n) => n.id !== 'admin'
                        ),
                    };
                }

                // Owner can access all menus except Amdin
                if (
                    roles.length === 1 &&
                    roles[0].name.toLocaleLowerCase() === 'owner'
                ) {
                    this.navigation = navigation;
                } else {
                    // if not an Owner filter menu base on roles given to user
                    const filtered = navigation.default.filter((menu) => {
                        if (menu.id === 'accounts' || menu.id === 'dashboard') {
                            return true;
                        }
                        return roles.some((role) =>
                            menu.id
                                .toLocaleLowerCase()
                                .includes(role.name.toLocaleLowerCase())
                        );
                    });

                    // check if settings menu can be displayed
                    if (roles.some((role) => SettingsIds.includes(role.name))) {
                        const settings = navigation.default.filter(
                            (x) => x.id === 'settings'
                        )[0];
                        if (
                            filtered.filter((x) => x.id === 'settings')
                                .length === 0
                        ) {
                            filtered.push(settings);
                        }
                    }

                    const filteredNavigation = {
                        compact: [],
                        futuristic: [],
                        horizontal: [],
                        default: filtered,
                    };
                    this.navigation = filteredNavigation;
                }
            });

        this.af.selectActiveAccountPlan$
            .pipe(
                filter((ap) => ap != null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe((ap) => {
                this.planName = ap.plan.name;

                if (
                    ap.paymentProviderAccountStatus === BillingStatus.Cancelled
                ) {
                    this.planName = ap.plan.name + ' - Expired';
                }
            });

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    resetIntro(): void {
        this.introJsService.resetTour();
    }
}
