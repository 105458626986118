import { createReducer, on } from '@ngrx/store';
import {
  JobCosting, JobCostingWithMetrics,
  PaginatedListOfJobHistory,
  PaginatedListOfVariation,
  SingleSaveJobCosting
} from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import { v4 as uuidv4 } from 'uuid';
import {
  getJobCosting,
  getVariations,
  getVariationsSuccess,
  getVariationsFailure,
  getJobCostingSuccess,
  getJobCostingFailure,
  getJobHistory,
  getJobHistoryFailure,
  getJobHistorySuccess,
  getJobCostingSingle,
  getJobCostingSingleFailure,
  getJobCostingSingleSuccess,
  updateJobCostingTotals,
  updateJobCostingOnCost,
  resetJobCosting,
  saveJobCosting,
  saveJobCostingSuccess,
  deletePriceListItem,
  addRemovePriceListItems,
  updatePriceListItem,
  updateJobCostingEstimate,
  updateJobCostingStatus,
  updateJobCostingStatusSuccess,
  updateJobCostingStatusFailure,
  uploadJobCostingQuoteLogoSuccess,
  updateStatusForSelectedVariationItem
} from '../actions';
import { sumBy } from 'lodash';

export interface JobCostingState {
  job: JobCostingWithMetrics;
  jobCosting: SingleSaveJobCosting;
  variation: PaginatedListOfVariation;
  history: PaginatedListOfJobHistory;
  callState: CallState;
  error: any;
  editing: boolean;
  jobCostingOriginal: SingleSaveJobCosting;
  jobCostingStatus: number;
  jobCostingLogo: string;

}

export const initialState: JobCostingState = {
  job: null,
  jobCosting: null,
  variation: null,
  history: null,
  callState: LoadingState.INIT,
  error: null,
  editing: false,
  jobCostingOriginal: null,
  jobCostingStatus: null,
  jobCostingLogo: null,
};

export const JobCostingReducer = createReducer(
  initialState,
  on(getJobCosting, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobCostingFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobCostingSuccess, (state, { job }) => ({
    ...state,
    job,
    callState: LoadingState.LOADED,
  })),
  on(getJobCostingSingle, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobCostingSingleFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobCostingSingleSuccess, (state, { jobCosting }) => ({
    ...state,
    jobCosting,
    jobCostingOriginal: { ...jobCosting },
    callState: LoadingState.LOADED,
    jobCostingStatus: jobCosting.status,
    jobCostingLogo: jobCosting.quote.logo,
  })),

  on(getVariations, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getVariationsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getVariationsSuccess, (state, { variation }) => ({
    ...state,
    variation,
    callState: LoadingState.LOADED,
  })),

  on(updateStatusForSelectedVariationItem, (state, { params }) => ({
    ...state,
    variation: {
      ...state.variation,
      items: state.variation.items.map(i => {
        if (i.id === params.variationItemId) {
          return {
            ...i,
            status: params.status
          }
        } else {
          return i;
        }
      })
    }
  })),

  on(getJobHistory, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobHistoryFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobHistorySuccess, (state, { history }) => ({
    ...state,
    history,
    callState: LoadingState.LOADED,
  })),

  on(updateJobCostingOnCost, (state, { onCost }) => ({
    ...state,
    editing: state.jobCosting.onCost === onCost ? false : true,
    jobCosting: {
      ...state.jobCosting,
      onCost,
      items: state.jobCosting.items.map((i) => {
        return {
          ...i,
          onCost,
          netQuote: i.quotable ? i.unitTotal * (1 + onCost / 100) : 0,
        };
      }),
    },
  })),

  on(updateJobCostingTotals, (state, _) => {
    const quotableItems = state.jobCosting.items.filter((i) => i.quotable);
    const unitTotal = sumBy(state.jobCosting.items, (i) => i.unitTotal);
    const quotableUnitTotal = sumBy(quotableItems, (i) => i.unitTotal);
    const netQuoteAmount = sumBy(quotableItems, (i) => i.netQuote);
    const profitAmount = netQuoteAmount - unitTotal;
    const onCost =
      +((netQuoteAmount / unitTotal - 1) * 100).toFixed(2) ||
      state.jobCosting.onCost;
    const quoteAmount =
      Math.round(
        100 * sumBy(quotableItems, (i) => i.netQuote * (1 + i.gstRate / 100)),
      ) / 100;
    const gstAmount = sumBy(
      quotableItems,
      (i) => (i.unitTotal * (1 + i.onCost / 100) * i.gstRate) / 100,
    );

    return {
      ...state,
      jobCosting: {
        ...state.jobCosting,
        unitTotal,
        netQuoteAmount,
        profitAmount,
        quoteAmount,
        quotableUnitTotal,
        gstAmount,
        onCost,
      }
    };
  }),
  on(saveJobCosting, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(saveJobCostingSuccess, (state, { jobCosting }) => ({
    ...state,
    editing: false,
    jobCosting,
    jobCostingOriginal: { ...jobCosting },
    callState: LoadingState.LOADED,
    jobCostingStatus: jobCosting.status
  })),
  on(updateJobCostingEstimate, (state, { jobCosting }) => ({
    ...state,
    jobCosting: { ...jobCosting },
  })),
  on(updateJobCostingStatus, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobCostingStatusSuccess, (state,{ jobCosting }) => ({
    ...state,
    callState: LoadingState.LOADED,
    jobCostingStatus: jobCosting.status
  })),
  on(updateJobCostingStatusFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),
  on(resetJobCosting, (state, _) => ({
    ...state,
    editing: false,
    jobCosting: { ...state.jobCostingOriginal },
    callState: LoadingState.LOADED,
  })),
  on(deletePriceListItem, (state, { id }) => ({
    ...state,
    editing: true,
    jobCosting: {
      ...state.jobCosting,
      items: state.jobCosting.items.filter((i) => i.id !== id),
    }
  })),
  on(updatePriceListItem, (state, { item }) => ({
    ...state,
    editing: true,
    jobCosting: {
      ...state.jobCosting,
      items: state.jobCosting.items.map((i) => (i.id === item.id ? item : i)),
    },
  })),
  on(
    addRemovePriceListItems,
    (state, {added, removed }) => {
      const removedIds = removed.map((r) => r.id);
      const onCost = state.jobCosting.onCost ?? 0;
      return {
        ...state,
        editing: true,
        jobCosting: {
          ...state.jobCosting,
          items: [...state.jobCosting.items, ...added]
            .filter((i) => !removedIds.includes(i.id))
            .map((i) => {
              return {
                ...i,
                id: uuidv4(),
                onCost,
                netQuote: i.unitTotal * (1 + onCost / 100),
              };
            })
        }
      }
    }),
  on(uploadJobCostingQuoteLogoSuccess, (state, { fileLocation }) => ({
    ...state,
    jobCostingLogo:  fileLocation,
    callState: LoadingState.LOADED,
  })),
);
