/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import {
  FullPriceList,
  PriceListItemPriceChangeCheck,
  PriceListItemPriceChangeJob,
} from 'app/shared/models';

export enum ItemPriceCheckActionTypes {
  GetItemPriceCheck = '[PriceListCheck] Get Current PriceList Check',
  GetItemPriceCheckSuccess = '[PriceListCheck] Get Current PriceList Check Success',
  GetItemPriceCheckFailure = '[PriceListCheck] Get Current PriceList Check Failure',
  ClearState = '[PriceListCheck] ClearState',
}

export const getItemPriceCheck = createAction(
  ItemPriceCheckActionTypes.GetItemPriceCheck,
  props<{ params: PriceListItemPriceChangeCheck }>(),
);

export const getItemPriceCheckSuccess = createAction(
  ItemPriceCheckActionTypes.GetItemPriceCheckSuccess,
  props<{ items: PriceListItemPriceChangeJob[] }>(),
);

export const getItemPriceCheckFailure = createAction(
  ItemPriceCheckActionTypes.GetItemPriceCheckFailure,
  props<{ error: any }>(),
);

export const clearState = createAction(ItemPriceCheckActionTypes.ClearState);
