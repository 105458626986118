import { createAction, props } from '@ngrx/store';
import {
  JobInvoice,
  PaginatedListOfJobInvoice,
  JobInvoiceParameters,
  JobInvoicePayment,
} from 'app/shared/models';

export enum JobInvoicesActionTypes {
  GetJobInvoices = '[Job Invoices] Get Job Invoices',
  GetJobInvoicesSuccess = '[Job Invoices] Get Job Invoices Success',
  GetJobInvoicesFailure = '[Job Invoices] Get Job Invoices Failure',
  GetCustomerJobInvoices = '[Job Invoices] Get Customer Job Invoices',
  GetCustomerJobInvoicesSuccess = '[Job Invoices] Get Customer Job Invoices Success',
  GetCustomerJobInvoicesFailure = '[Job Invoices] Get Customer Job Invoices Failure',
  AddJobInvoice = '[Job Invoices] Add Invoice Job',
  AddJobInvoiceSuccess = '[Job Invoices] Add Job Invoice Success',
  AddJobInvoiceFailure = '[Job Invoices] Add Job Invoice Failure',
  UpdateJobInvoice = '[Job Invoices] Update Invoice Job',
  UpdateJobInvoiceSuccess = '[Job Invoices] Update Job Invoice Success',
  UpdateJobInvoiceFailure = '[Job Invoices] Update Job Invoice Failure',
  DeleteJobInvoice = '[Job Invoices] Delete Invoice Job',
  DeleteJobInvoiceSuccess = '[Job Invoices] Delete Job Invoice Success',
  DeleteJobInvoiceFailure = '[Job Invoices] Delete Job Invoice Failure',
  GetJobInvoice = '[Job Invoice] Get Job Invoice',
  GetJobInvoiceSuccess = '[Job Invoice] Get Job Invoice Success',
  GetJobInvoiceFailure = '[Job Invoice] Get Job Invoice Failure',

  ChangeInvoiceStatus = '[Job Invoice] ChangeInvoiceStatus',
  ChangeInvoiceStatusSuccess = '[Job Invoice] ChangeInvoiceStatus Success',
  ChangeInvoiceStatusFailure = '[Job Invoice] ChangeInvoiceStatus Failure',

  // send, mark as sent, mark as not sent
  SendInvoice = '[Job Invoice] SendInvoice',
  SendInvoiceSuccess = '[Job Invoice] SendInvoice Success',
  SendInvoiceFailure = '[Job Invoice] SendInvoice Failure',

  // update Invoice payment
  UpdateInvoicePayment = '[Job Invoice] UpdateInvoicePayment',
  UpdateInvoicePaymentSuccess = '[Job Invoice] UpdateInvoicePayment Success',
  UpdateInvoicePaymentFailure = '[Job Invoice] UpdateInvoicePayment Failure',

  ClearJobInvoice = '[Job Invoice] ClearJobInvoice',
}
export const clearInvoice = createAction(
  JobInvoicesActionTypes.ClearJobInvoice,
);

export const sendInvoice = createAction(
  JobInvoicesActionTypes.SendInvoice,
  props<{ action: string; id: string }>(),
);

export const sendInvoiceSuccess = createAction(
  JobInvoicesActionTypes.SendInvoiceSuccess,
  props<{ invoice: JobInvoice }>(),
);

export const sendInvoiceFailure = createAction(
  JobInvoicesActionTypes.SendInvoiceFailure,
  props<{ error: string }>(),
);

export const updateInvoicePayment = createAction(
  JobInvoicesActionTypes.UpdateInvoicePayment,
  props<{ payload: JobInvoicePayment }>(),
);

export const updateInvoicePaymentSuccess = createAction(
  JobInvoicesActionTypes.UpdateInvoicePaymentSuccess,
  props<{ invoicePayment: JobInvoicePayment }>(),
);

export const updateInvoicePaymentFailure = createAction(
  JobInvoicesActionTypes.UpdateInvoicePaymentFailure,
  props<{ error: string }>(),
);

export const changeInvoiceStatus = createAction(
  JobInvoicesActionTypes.ChangeInvoiceStatus,
  props<{ action: string; id: string }>(),
);

export const changeInvoiceStatusSuccess = createAction(
  JobInvoicesActionTypes.ChangeInvoiceStatusSuccess,
  props<{ invoice: JobInvoice }>(),
);

export const changeInvoiceStatusFailure = createAction(
  JobInvoicesActionTypes.ChangeInvoiceStatusFailure,
  props<{ error: string }>(),
);

export const updateJobInvoice = createAction(
  JobInvoicesActionTypes.UpdateJobInvoice,
  props<{ invoice: JobInvoice }>(),
);

export const updateJobInvoiceSuccess = createAction(
  JobInvoicesActionTypes.UpdateJobInvoiceSuccess,
  props<{ invoice: JobInvoice }>(),
);

export const updateJobInvoiceFailure = createAction(
  JobInvoicesActionTypes.UpdateJobInvoiceFailure,
  props<{ error: string }>(),
);

export const addJobInvoice = createAction(
  JobInvoicesActionTypes.AddJobInvoice,
  props<{ invoice: JobInvoice }>(),
);

export const addJobInvoiceSuccess = createAction(
  JobInvoicesActionTypes.AddJobInvoiceSuccess,
  props<{ invoice: JobInvoice }>(),
);

export const addJobInvoiceFailure = createAction(
  JobInvoicesActionTypes.AddJobInvoiceFailure,
  props<{ error: string }>(),
);

export const deleteJobInvoice = createAction(
  JobInvoicesActionTypes.DeleteJobInvoice,
  props<{ id: string }>(),
);

export const deleteJobInvoiceSuccess = createAction(
  JobInvoicesActionTypes.DeleteJobInvoiceSuccess,
  props<{ id: string }>(),
);

export const deleteJobInvoiceFailure = createAction(
  JobInvoicesActionTypes.DeleteJobInvoiceFailure,
  props<{ error: string }>(),
);

export const getJobInvoices = createAction(
  JobInvoicesActionTypes.GetJobInvoices,
  props<{ request: JobInvoiceParameters }>(),
);

export const getJobInvoicesSuccess = createAction(
  JobInvoicesActionTypes.GetJobInvoicesSuccess,
  props<{ invoices: PaginatedListOfJobInvoice }>(),
);

export const getJobInvoicesFailure = createAction(
  JobInvoicesActionTypes.GetJobInvoicesFailure,
  props<{ error: string }>(),
);

export const getCustomerJobInvoices = createAction(
  JobInvoicesActionTypes.GetCustomerJobInvoices,
  props<{ id: string }>(),
);

export const getCustomerJobInvoicesSuccess = createAction(
  JobInvoicesActionTypes.GetCustomerJobInvoicesSuccess,
  props<{ invoices: PaginatedListOfJobInvoice }>(),
);

export const getCustomerJobInvoicesFailure = createAction(
  JobInvoicesActionTypes.GetCustomerJobInvoicesFailure,
  props<{ error: string }>(),
);

export const getJobInvoice = createAction(
  JobInvoicesActionTypes.GetJobInvoice,
  props<{ id: string }>(),
);

export const getJobInvoiceSuccess = createAction(
  JobInvoicesActionTypes.GetJobInvoiceSuccess,
  props<{ invoice: JobInvoice }>(),
);

export const getJobInvoiceFailure = createAction(
  JobInvoicesActionTypes.GetJobInvoiceFailure,
  props<{ error: string }>(),
);
