import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EstimatePriceListItemDialogComponent } from '@app/views/estimates/components';
import {
    selectJobCostingSingle,
    selectJobCostingStatus,
} from '@app/views/jobs/store';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AddRemovePriceListItemsDialogComponent } from '@shared/components';
import { ToastService } from '@shared/services/toast.service';
import {
    JobCosting,
    JobCostingPriceListItem,
    PaginatedListOfJobHistory,
    PaginatedListOfVariation,
    SingleSaveJobCosting,
} from 'app/shared/models';
import { flatten, sumBy } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
    addRemovePriceListItems,
    addRemovePriceListItemsShowDialog,
    deletePriceListItem,
    deletePriceListItemConfirm,
    getJobCosting,
    getJobCostingFailure,
    getJobCostingSingle,
    getJobCostingSingleFailure,
    getJobCostingSingleSuccess,
    getJobCostingSuccess,
    getJobHistory,
    getJobHistoryFailure,
    getJobHistorySuccess,
    getVariations,
    getVariationsFailure,
    getVariationsSuccess,
    saveJobCosting,
    saveJobCostingFailure,
    saveJobCostingSuccess,
    updateJobCostingCustomerQuote,
    updateJobCostingOnCost,
    updateJobCostingProfit,
    updateJobCostingStatus,
    updateJobCostingStatusFailure,
    updateJobCostingStatusSuccess,
    updateJobCostingTotals,
    updatePriceListItem,
    updatePriceListItemShowDialog,
    uploadJobCostingQuoteLogo,
    uploadJobCostingQuoteLogoSuccess,
} from '../actions/job-costing.actions';

@Injectable({
    providedIn: 'root',
})
export class JobCostingEffects {
    constructor(
        private actions$: Actions,
        private jobService: JobService,
        private store: Store,
        private confirm: FuseConfirmationService,
        private dialog: MatDialog,
        private toast: ToastService
    ) {}

    getJobCosting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getJobCosting),
            switchMap(({ id }) =>
                this.jobService.getJobCosting(id).pipe(
                    map((job: JobCosting) => getJobCostingSuccess({ job })),
                    catchError((error) => {
                        return of(getJobCostingFailure({ error }));
                    })
                )
            )
        )
    );

    getJobCostingSingle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getJobCostingSingle),
            switchMap(({ id }) =>
                this.jobService.getJobCostingSingle(id).pipe(
                    map((jobCosting: SingleSaveJobCosting) =>
                        getJobCostingSingleSuccess({ jobCosting })
                    ),
                    catchError((error) => {
                        return of(getJobCostingSingleFailure({ error }));
                    })
                )
            )
        )
    );

    getJobVariation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getVariations),
            switchMap(({ query }) =>
                this.jobService.getJobVariation(query).pipe(
                    map((variation: PaginatedListOfVariation) =>
                        getVariationsSuccess({ variation })
                    ),
                    catchError((error) => {
                        return of(getVariationsFailure({ error }));
                    })
                )
            )
        )
    );

    getJobHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getJobHistory),
            switchMap(({ query }) =>
                this.jobService.getJobHistory(query).pipe(
                    map((history: PaginatedListOfJobHistory) =>
                        getJobHistorySuccess({ history })
                    ),
                    catchError((error) => {
                        return of(getJobHistoryFailure({ error }));
                    })
                )
            )
        )
    );

    updateJobCostingCustomerQuote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobCostingCustomerQuote),
            concatLatestFrom(() => this.store.select(selectJobCostingSingle)),
            map(([{ customerQuote }, jobCosting]) => {
                const netQuote = customerQuote / 1.1;
                const profit = netQuote - jobCosting.quotableUnitTotal;
                const onCost = 100 * (profit / jobCosting.quotableUnitTotal);
                return updateJobCostingOnCost({ onCost });
            })
        )
    );

    updateJobCostingProfit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobCostingProfit),
            concatLatestFrom(() => this.store.select(selectJobCostingSingle)),
            map(([{ profit }, jobCosting]) => {
                const nonQuotableUnitTotal = sumBy(
                    flatten(jobCosting.items.filter((i) => !i.quotable)),
                    (i) => i.unitTotal
                );
                const onCost =
                    100 *
                    ((profit + nonQuotableUnitTotal) /
                        jobCosting.quotableUnitTotal);
                return updateJobCostingOnCost({ onCost });
            })
        )
    );

    updateJobCostingOnCost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobCostingOnCost),
            map(updateJobCostingTotals)
        )
    );

    saveJobCosting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveJobCosting),
            concatLatestFrom(() => [
                this.store.select(selectJobCostingSingle),
                this.store.select(selectJobCostingStatus),
            ]),
            switchMap(([_, jobCosting, status]) =>
                this.jobService
                    .saveJobCostingSingle({ ...jobCosting, status })
                    .pipe(
                        map((jobCosting: SingleSaveJobCosting) =>
                            saveJobCostingSuccess({ jobCosting })
                        ),
                        catchError((error) => {
                            return of(saveJobCostingFailure({ error }));
                        })
                    )
            )
        )
    );

    deletePriceListItemConfirm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deletePriceListItemConfirm),
            switchMap(({ id }) =>
                this.confirm
                    .open({
                        title: 'Delete item',
                        message:
                            'Are you sure you want to delete this item? This action cannot be undone.',
                        icon: {
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        actions: {
                            cancel: { label: 'No' },
                            confirm: { label: 'Yes', color: 'warn' },
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => result === 'confirmed'),
                        map(() => deletePriceListItem({ id }))
                    )
            )
        )
    );

    deletePriceListItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deletePriceListItem),
            map(updateJobCostingTotals)
        )
    );

    updatePriceListItemShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePriceListItemShowDialog),
            switchMap(({ item }) =>
                this.dialog
                    .open(EstimatePriceListItemDialogComponent, { data: item })
                    .afterClosed()
            ),
            filter((result: any) => result),
            map((item: JobCostingPriceListItem) =>
                updatePriceListItem({ item })
            )
        )
    );

    updatePriceListItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePriceListItem, addRemovePriceListItems),
            map(updateJobCostingTotals)
        )
    );

    addRemovePriceListItemsShowDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addRemovePriceListItemsShowDialog),
            concatLatestFrom(() => this.store.select(selectJobCostingSingle)),
            switchMap(([{}, jobCosting]) =>
                this.dialog
                    .open(AddRemovePriceListItemsDialogComponent, {
                        data: jobCosting,
                    })
                    .afterClosed()
                    .pipe(
                        filter((result) => !!result),
                        map((data) => addRemovePriceListItems(data))
                    )
            )
        )
    );

    updateJobCostingStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobCostingStatus),
            switchMap(({ params }) =>
                this.jobService.updateJobCostingStatus(params).pipe(
                    map((jobCosting) =>
                        updateJobCostingStatusSuccess({ jobCosting })
                    ),
                    catchError((error) => {
                        this.toast.error(
                            'An error occurred while changing the status.'
                        );
                        return of(updateJobCostingStatusFailure({ error }));
                    })
                )
            )
        )
    );

    uploadJobCostingQuoteLogo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(uploadJobCostingQuoteLogo),
            switchMap(({ id, file }) =>
                this.jobService.uploadJobCostingQuoteLogo(id, file).pipe(
                    map((fileLocation: any) =>
                        uploadJobCostingQuoteLogoSuccess({
                            fileLocation: fileLocation.fileLocation,
                        })
                    )
                )
            )
        )
    );
}
