<div class="relative flex flex-col w-full h-full max-h-screen">
  <!-- Dismiss button -->
  <ng-container>
    <div class="absolute top-0 right-0 pt-4 pr-4">
      <button [matDialogClose]="undefined" mat-icon-button>
        <mat-icon
          class="text-secondary"
          svgIcon="heroicons_outline:x-mark"
        ></mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Content -->
  <div
    class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8"
  >
    <!-- Icon -->
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600"
    >
      <mat-icon class="text-current" svgIcon="feather:settings"></mat-icon>
    </div>

    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left"
    >
      <!-- Title -->
      <ng-container>
        <div
          class="text-xl leading-6 font-medium"
          [innerHTML]="'Customise roles'"
        ></div>
      </ng-container>

      <!-- Message -->
      <ng-container>
        <div
          class="text-secondary"
          [innerHTML]="'Toggle roles to modify user menu'"
        ></div>
      </ng-container>
    </div>
  </div>

  <!-- Form -->
  <div class="p-4 ml-4 flex flex-col overflow-y-auto">
    <div class="font-semibold text-secondary text-2xl">Features</div>
    <div style="max-height: 500px">
      <ng-container *ngFor="let role of data.roles; let i = index">
        <div
          class="col-span-4 items-center flex flex-row"
          *ngIf="
            role.name !== 'Owner' &&
            role.name !== 'Jobs' &&
            role.name !== 'Invoicing' &&
            role.name !== 'Ordering'
          "
        >
          <mat-slide-toggle
            class="example-margin w-2/5"
            [checked]="role.isSelected"
            (change)="togle($event, i)"
            color="primary"
            >{{ role.description }}</mat-slide-toggle
          >
          <input matInput hidden />
          <mat-form-field class="w-3/5" appearance="fill">
            <mat-label>Access level</mat-label>
            <mat-select
              [value]="role.accessLevel"
              [disabled]="role.isSelected === false"
              (selectionChange)="
                handleUpdateTeamMemberRoleChange($event, role, i)
              "
            >
              <mat-option
                *ngFor="let item of data.common.accessLevels"
                [value]="item.id"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Jobs -->
        <div
          class="col-span-4 items-center flex flex-row"
          *ngIf="role.name === 'Jobs'"
        >
          <mat-slide-toggle
            class="example-margin w-2/5"
            [checked]="getUserRole('Jobs').isSelected"
            (change)="changeSelectedJob($event)"
            color="primary"
            >{{ getUserRole("Jobs").description }}</mat-slide-toggle
          >
          <input matInput hidden />
          <mat-form-field class="w-3/5" appearance="fill">
            <mat-label>Access level</mat-label>
            <mat-select
              [value]="getUserRole('Jobs').accessLevel"
              [disabled]="getUserRole('Jobs').isSelected === false"
              (selectionChange)="changeAccessLevel($event, getUserRole('Jobs'))"
            >
              <mat-option
                *ngFor="let item of data.common.accessLevels"
                [value]="item.id"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Invoicing -->
        <div
          class="col-span-4 items-center flex flex-row ml-8"
          *ngIf="role.name === 'Invoicing'"
        >
          <mat-slide-toggle
            class="example-margin w-2/5"
            [disabled]="getUserRole('Jobs').isSelected === false"
            [checked]="getUserRole('Invoicing').isSelected"
            (change)="changeSelected($event, getUserRole('Invoicing'))"
            color="primary"
            >{{ getUserRole("Invoicing").description }}</mat-slide-toggle
          >
          <input matInput hidden />
          <mat-form-field class="w-3/5" appearance="fill">
            <mat-label>Access level</mat-label>
            <mat-select
              [value]="getUserRole('Invoicing').accessLevel"
              [disabled]="getUserRole('Invoicing').isSelected === false"
              (selectionChange)="
                changeAccessLevel($event, getUserRole('Invoicing'))
              "
            >
              <mat-option
                *ngFor="let item of data.common.accessLevels"
                [value]="item.id"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Ordering -->
        <div
          class="col-span-4 items-center flex flex-row ml-8"
          *ngIf="role.name === 'Ordering'"
        >
          <mat-slide-toggle
            class="example-margin w-2/5"
            [disabled]="getUserRole('Jobs').isSelected === false"
            [checked]="getUserRole('Ordering').isSelected"
            (change)="changeSelected($event, getUserRole('Ordering'))"
            color="primary"
            >{{ getUserRole("Ordering").description }}</mat-slide-toggle
          >
          <input matInput hidden />
          <mat-form-field class="w-3/5" appearance="fill">
            <mat-label>Access level</mat-label>
            <mat-select
              [value]="getUserRole('Ordering').accessLevel"
              [disabled]="getUserRole('Ordering').isSelected === false"
              (selectionChange)="
                changeAccessLevel($event, getUserRole('Ordering'))
              "
            >
              <mat-option
                *ngFor="let item of data.common.accessLevels"
                [value]="item.id"
              >
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Actions -->
  <ng-container>
    <div
      class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
    >
      <!-- Cancel -->
      <ng-container>
        <button [matDialogClose]="undefined" mat-stroked-button>Cancel</button>
      </ng-container>

      <!-- Confirm -->
      <ng-container>
        <button
          [matDialogClose]="'confirmed'"
          [disabled]="hasFormChanged"
          (click)="closeOk()"
          class=""
          mat-flat-button
          color="primary"
        >
          Save
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>
