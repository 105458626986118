/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { initial, last } from 'lodash';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PhotoService } from '../../services/files.service';
import { NgIf } from '@angular/common';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-job-files-folder-edit',
  templateUrl: './job-files-folder-edit.component.html',
  styleUrls: ['./job-files-folder-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxTrimDirectiveModule,
    NgIf,
  ],
})
export class JobFilesFolderEditComponent implements OnInit {
  editForm: FormGroup = undefined;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private photoService: PhotoService,
    public dialogRef: MatDialogRef<JobFilesFolderEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { jobId: string; path: string },
  ) {
    this.editForm = this.fb.group({
      name: [
        last(initial(this.data.path.split('\\'))),
        {
          validators: [Validators.required],
          asyncValidators: [this.folderExistsValidator.bind(this)],
          updateOn: 'change',
        },
      ],
    });
  }

  get folderExists(): boolean {
    return (
      this.editForm.get('name').hasError('folderExists') &&
      this.editForm.get('name').touched
    );
  }

  get required(): boolean {
    return (
      this.editForm.get('name').hasError('required') &&
      this.editForm.get('name').touched
    );
  }

  folderExistsValidator(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    const name = control.value;
    const parts = [...this.data.path.split('\\').slice(0, -2), name];
    const newPath = `${parts.join('\\')}\\`;

    if (newPath === this.data.path) {
      return of(null);
    }

    return control.valueChanges.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      switchMap(() =>
        this.photoService.fileFolderExists(this.data.jobId, newPath),
      ),
      tap(() => this.cd.markForCheck()),
      map((exists) => (exists ? { folderExists: true } : null)),
      first(),
    );
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  save(): void {
    const name = this.editForm.controls.name.value;
    const parts = [...this.data.path.split('\\').slice(0, -2), name];
    const newPath = `${parts.join('\\')}\\`;
    const response = {
      jobId: this.data.jobId,
      oldPath: this.data.path,
      newPath: newPath,
    };

    this.dialogRef.close(response);
  }
}
