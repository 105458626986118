import { createAction, props } from '@ngrx/store';
import {
  JobHistoryParameters,
  PaginatedListOfJobHistory,
  SingleGetJobHistory,
} from 'app/shared/models';

export enum JobHistoryActionTypes {
  getJobHistorySingle = '[Job History] Get JobHistorySingle',
  getJobHistorySingleSuccess = '[Job History] Get JobHistorySingle Success',
  getJobHistorySingleFailure = '[Job History] Get JobHistorySingle Failure',

  getJobHistoryList = '[Job History] Get JobHistory list',
  getJobHistoryListSuccess = '[Job History] Get JobHistory list Success',
  getJobHistoryListFailure = '[Job History] Get JobHistory list Failure',
}

export const getJobHistorySingle = createAction(
  JobHistoryActionTypes.getJobHistorySingle,
  props<{ id: string }>(),
);

export const getJobHistorySingleSuccess = createAction(
  JobHistoryActionTypes.getJobHistorySingleSuccess,
  props<{ jobHistorySingle: SingleGetJobHistory }>(),
);

export const getJobHistorySingleFailure = createAction(
  JobHistoryActionTypes.getJobHistorySingleFailure,
  props<{ error: any }>(),
);

export const getJobHistoryList = createAction(
  JobHistoryActionTypes.getJobHistoryList,
  props<{ query: JobHistoryParameters }>(),
);

export const getJobHistoryListSuccess = createAction(
  JobHistoryActionTypes.getJobHistoryListSuccess,
  props<{ jobHistory: PaginatedListOfJobHistory }>(),
);

export const getJobHistoryListFailure = createAction(
  JobHistoryActionTypes.getJobHistoryListFailure,
  props<{ error: any }>(),
);
