import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { accountUserReducer, AccountUserState } from './account-user.reducer';
import { commonDataReducer, CommonDataState } from './common-data.reducer';
import { fileUploadReducer, FileUploadState } from './file-upload.reducer';

export interface SharedState {
  fileUploads: FileUploadState;
  accountUser: AccountUserState;
  commonData: CommonDataState;
}

export const reducers: ActionReducerMap<SharedState> = {
  fileUploads: fileUploadReducer,
  accountUser: accountUserReducer,
  commonData: commonDataReducer,
};

export const selectSharedState = createFeatureSelector<SharedState>('shared');
