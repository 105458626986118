/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-parens */
import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { sortBy, uniqBy } from 'lodash';
import { PriceListState, selectPriceListState } from '../reducers';

export const selectCurrentPriceListState = createSelector(
  selectPriceListState,
  (s: PriceListState) => s.currentPriceList,
);

export const selectCurrentPriceListIsLoading = createSelector(
  selectCurrentPriceListState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectCurrentPriceListIsLoaded = createSelector(
  selectCurrentPriceListState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectCurrentPriceListError = createSelector(
  selectCurrentPriceListState,
  (s) => getError(s.callState),
);

export const selectFullPriceList = createSelector(
  selectCurrentPriceListState,
  (s) => s.priceList,
);
export const selecteCurrentPriceListTotalCount = createSelector(
  selectCurrentPriceListState,
  (s) => s.priceList?.priceListItems?.length,
);

export const selectCurrentPriceList = createSelector(
  selectCurrentPriceListState,
  (s) => s.priceList?.priceList,
);

export const selectCurrentPriceListSuppliers = createSelector(
  selectCurrentPriceListState,
  (s) =>
    sortBy(
      uniqBy(
        s.priceList?.priceListItems.map((item) => item.supplier),
        (supplier) => supplier.id,
      ),
      (supplier) => supplier.name,
    ),
);

export const selectCurrentPriceListCategories = createSelector(
  selectCurrentPriceListState,
  (s) =>
    s.priceList.priceListCategories.filter(c => uniqBy(
      s.priceList?.priceListItems.map((item) => item.priceListCategory),
      (category) => category.id,
    ).map(item => item.id).includes(c.id))
);

export const selectAllPriceListCategories = createSelector(
  selectCurrentPriceListState,
  (s) => sortBy(s.priceList?.priceListCategories, (c) => c.displayOrder),
);

export const selectCurrentPriceListItems = createSelector(
  selectCurrentPriceListState,
  (s) => s.priceList?.priceListItems,
);

export const selectSelectedPriceListItems = createSelector(
  selectCurrentPriceListState,
  (s) =>
    s.priceList?.priceListItems?.filter((i) =>
      s.selectedPriceListItemIds.includes(i.id),
    ),
);

export const selectPriceListItemIsSelected = (id: string) =>
  createSelector(selectCurrentPriceListState, (s) =>
    s.selectedPriceListItemIds.includes(id),
  );

export const selectMaxDisplayOrderPriceListCategory = createSelector(
  selectCurrentPriceListState,
  (s) => sortBy(s.priceList?.priceListCategories, (c) => c.displayOrder)[0],
);
