<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>
  <!-- Content -->
  <div class="flex flex-col sm:flex-row items-center p-8 pb-6 sm:pb-8">
    <div
      class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left"
    >
      <!-- Title -->
      <div class="text-xl leading-6 font-medium">{{ title }}</div>
    </div>
  </div>

  <form
    [formGroup]="editForm"
    novalidate
    (ngSubmit)="(save)"
    (keyup.enter)="save()"
    class="flex flex-row items-center mb-3 gap-x-5 p-8"
  >
    <mat-form-field class="fuse-mat-no-subscript w-full" floatLabel="always">
      <mat-label>Name</mat-label>
      <input trim="blur" matInput formControlName="name" cdkFocusInitial />
    </mat-form-field>
  </form>

  <!-- Actions -->
  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <!-- Cancel -->
    <button mat-stroked-button [matDialogClose]="undefined">Cancel</button>

    <!-- Save -->
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="!editForm.valid"
      (click)="save()"
    >
      Ok
    </button>
  </div>
</div>
