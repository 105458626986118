/* eslint-disable arrow-parens */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobType } from 'app/shared/models';
import { JobService } from 'app/views/jobs/services/job.service';
import { catchError, combineLatest, map, of, switchMap, take } from 'rxjs';
import {
  getCommonData,
  getCommonDataFailure,
  getCommonDataSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class CommonDataEffects {
  getCommonData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCommonData),
      take(1),
      switchMap(() =>
        combineLatest([
          this.jobService.getStatuses(JobType.Job),
          this.jobService.getStatuses(JobType.Estimate),
        ]).pipe(
          map(([jobStatuses, estimateStatuses]) =>
            getCommonDataSuccess({ jobStatuses, estimateStatuses }),
          ),
          catchError((error) => of(getCommonDataFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}
}
