import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  GoogleMap,
  MapInfoWindow,
  MapMarker,
  GoogleMapsModule,
} from '@angular/google-maps';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GoogleMapsModule, NgFor],
})
export class GoogleMapComponent implements OnInit, OnChanges {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  @Input() longitude: number;
  @Input() latitude: number;
  @Input() height: string = '200px';

  zoom = 14;

  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
  };
  markers = [];
  infoContent = '';

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.center = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.markers.push({
      position: {
        lat: this.latitude,
        lng: this.longitude,
      },
      // label: {
      //   color: 'blue',
      //   text: 'Marker Label',
      // },
      // title: 'Marker Title',
      // info: 'Marker info',
      // options: {
      //   animation: google.maps.Animation.DROP,
      // },
    });
  }
}
