import { AsyncPipe, NgIf, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Photo } from 'app/shared/models';
import { PhotosFacade } from '../../store';
import { NgxFilesizeModule } from 'ngx-filesize';
import { GoogleMapComponent } from 'app/layout/common/google-map/google-map.component';

@Component({
  selector: 'app-photos-photo-info',
  templateUrl: './photos-photo-info.component.html',
  styleUrls: ['./photos-photo-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, AsyncPipe, DatePipe, NgxFilesizeModule, GoogleMapComponent],
})
export class PhotosPhotoInfoComponent implements OnInit {
  @Input() photo: Photo;

  constructor(
    public photosFacade: PhotosFacade,
    private async: AsyncPipe,
  ) {}

  ngOnInit(): void {}

  openLink(): void {
    const url =
      this.photo.url + '?' + this.async.transform(this.photosFacade.sasToken$);
    window.open(url, '_blank');
  }
}
