import { createReducer, on } from '@ngrx/store';
import { CallState, LoadingState } from 'app/store';
import {
  addJobOrderPriceListItem,
  addJobOrderPriceListItemFailure,
  addJobOrderPriceListItemSuccess,
  clearJobOrderPriceListItemState,
  deleteJobOrderPriceListItem,
  deleteJobOrderPriceListItemFailure,
  deleteJobOrderPriceListItemSuccess,
  getJobOrderPriceListItem,
  getJobOrderPriceListItemFailure,
  getJobOrderPriceListItemSuccess,
  updateJobOrderPriceListItem,
  updateJobOrderPriceListItemFailure,
  updateJobOrderPriceListItemSuccess,
} from '../actions';

export interface JobOrderPriceListItemState {
  jobOrder: any;
  callState: CallState;
  error: any;
}

export const initialState: JobOrderPriceListItemState = {
  jobOrder: <any>{ items: [] },
  callState: LoadingState.INIT,
  error: null,
};

export const jobOrderPriceListItemReducer = createReducer(
  initialState,
  on(getJobOrderPriceListItem, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobOrderPriceListItemFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobOrderPriceListItemSuccess, (state, { jobOrder }) => ({
    ...state,
    jobOrder,
    callState: LoadingState.LOADED,
  })),

  on(updateJobOrderPriceListItem, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobOrderPriceListItemFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateJobOrderPriceListItemSuccess, (state, { jobOrder }) => ({
    ...state,
    jobOrder: {
      ...state.jobOrder,
      items: state.jobOrder.items.map((item) => {
        if (item.id === jobOrder.id) {
          item = jobOrder;
        }
        return item;
      }),
    },
    callState: LoadingState.LOADED,
  })),

  on(addJobOrderPriceListItem, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(addJobOrderPriceListItemFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(addJobOrderPriceListItemSuccess, (state, { jobOrder }) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),

  on(deleteJobOrderPriceListItem, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(deleteJobOrderPriceListItemFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(deleteJobOrderPriceListItemSuccess, (state, { jobOrder }) => ({
    ...state,
    jobOrder: {
      items: state.jobOrder.items.filter((item) => {
        return item.id !== jobOrder.id;
      }),
      pageMetaData: {
        ...state.jobOrder.pageMetaData,
        ...{
          records:
            +JSON.parse(JSON.stringify(state.jobOrder.pageMetaData.records)) -
            1,
        },
      },
    },
    callState: LoadingState.LOADED,
  })),

  on(clearJobOrderPriceListItemState, (state) => ({
    ...state,
    jobOrder: { items: [] },
  })),
);
