import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileBlobService {
  constructor(private http: HttpClient) {}

  download(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
