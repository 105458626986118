<div>
  <h2 mat-dialog-title class="text-sm sm:text-base">Subscription Payment</h2>
  <mat-dialog-content>
    <div *ngIf="elementsOptions?.clientSecret">
      <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
        <ngx-stripe-payment [options]="paymentElementOptions" />
      </ngx-stripe-elements>

      <!-- divider -->
      <div class="mt-2 mb-2 border-t"></div>

      <!-- errors -->
      <div class="text-xs sm:text-base">
        {{ paymentMessage }}
      </div>

      <div class="flex items-center justify-end">
        <button class="ml-4" [color]="'primary'" mat-flat-button type="button" type="submit" [disabled]="disabled"
          (click)="pay()">
          Pay
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>