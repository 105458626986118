import { createReducer, on } from '@ngrx/store';
import { CallState, LoadingState } from 'app/store';
import {
  getJobOrderItemsToOrder,
  getJobOrderItemsToOrderFailure,
  getJobOrderItemsToOrderSuccess,
  resetJobOrderItemsToOrder,
} from '../actions';

export interface JobOrderItemsToOrderState {
  jobOrderItemsToOrder: any;
  callState: CallState;
  error: any;
}

export const initialState: JobOrderItemsToOrderState = {
  jobOrderItemsToOrder: null,
  callState: LoadingState.INIT,
  error: null,
};

export const jobOrderItemsToOrderReducer = createReducer(
  initialState,
  on(getJobOrderItemsToOrder, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobOrderItemsToOrderFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobOrderItemsToOrderSuccess, (state, { jobOrderItemsToOrder }) => ({
    ...state,
    jobOrderItemsToOrder,
    callState: LoadingState.LOADED,
  })),

  on(resetJobOrderItemsToOrder, (state) => ({
    ...state,
    jobOrderItemsToOrder: null,
  })),
);
