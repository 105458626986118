import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { VariationChangeItem, VariationChanges } from '@shared/models';
import { groupBy } from 'lodash-es';
import { Dictionary } from '@ngrx/entity';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-variation-changes-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './variation-changes-dialog.component.html',
  styleUrl: './variation-changes-dialog.component.scss'
})
export class VariationChangesDialogComponent implements OnInit {
  variations = {};
  variationNames: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<VariationChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VariationChanges,
  ){}

  ngOnInit(){
    this.variations = groupBy(this.data.changes, ({ variationName }) => variationName);
    this.variationNames = Object.keys(this.variations);
  }
}
