import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { jobPhotosReducer, PhotosState } from './photos.reducer';

export interface JobState {
  photos: PhotosState;
}

export const reducers: ActionReducerMap<JobState> = {
  photos: jobPhotosReducer,
};

export const selectJobState = createFeatureSelector<JobState>('photos');
