import { createAction, props } from '@ngrx/store';
import {
  JobOrder,
  JobOrderParameters,
  PaginatedListOfJobOrderListItem,
} from 'app/shared/models';

export enum JobOrderListActionTypes {
  GetJobOrderListFacade = '[JobOrder List] Get JobOrder',
  GetJobOrderListFacadeSuccess = '[JobOrder List] Get JobOrder Success',
  GetJobOrderListFacadeFailure = '[JobOrder List] Get JobOrder Failure',

  JobOrderDeleteFacade = '[JobOrder Delete] Get JobOrder',
  JobOrderDeleteFacadeSuccess = '[JobOrder Delete] Get JobOrder Success',
  JobOrderDeleteFacadeFailure = '[JobOrder Delete] Get JobOrder Failure',
  AddJobOrderDetailsFacadeSuccess = 'AddJobOrderDetailsFacadeSuccess',
}

export const getJobsOrderList = createAction(
  JobOrderListActionTypes.GetJobOrderListFacade,
  props<{ request: JobOrderParameters }>(),
);
export const getJobsOrderListSuccess = createAction(
  JobOrderListActionTypes.GetJobOrderListFacadeSuccess,
  props<{ jobOrders: PaginatedListOfJobOrderListItem }>(),
);
export const getJobsOrderListFailure = createAction(
  JobOrderListActionTypes.GetJobOrderListFacadeFailure,
  props<{ error: any }>(),
);

export const jobOrderDeleteFacade = createAction(
  JobOrderListActionTypes.JobOrderDeleteFacade,
  props<{ request: string }>(),
);
export const jobOrderDeleteFacadeSuccess = createAction(
  JobOrderListActionTypes.JobOrderDeleteFacadeSuccess,
  props<{ jobOrders: JobOrder }>(),
);
export const jobOrderDeleteFacadeFailure = createAction(
  JobOrderListActionTypes.JobOrderDeleteFacadeFailure,
  props<{ error: any }>(),
);
