import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { JobNote, JobNoteParameters } from 'app/shared/models';
import {
  addJobNote,
  deleteJobNote,
  getJobNotes,
  selectJobNotes,
  selectJobNotesIsLoaded,
  selectJobNotesTotalCount,
  updateJobNote,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class JobNotesFacade {
  constructor(private store: Store) {}

  notes$ = this.store.select(selectJobNotes);
  isLoaded$ = this.store.select(selectJobNotesIsLoaded);
  totalCount$ = this.store.select(selectJobNotesTotalCount);

  getNotes(request: JobNoteParameters): void {
    this.store.dispatch(getJobNotes({ request }));
  }

  deleteNote(id: string): void {
    this.store.dispatch(deleteJobNote({ id }));
  }

  addNote(note: JobNote): void {
    this.store.dispatch(addJobNote({ note }));
  }

  updateNote(note: JobNote): void {
    return this.store.dispatch(updateJobNote({ note }));
  }
}
