/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emptyStringValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return { emptyString: true };
    }

    const value = control.value ?? '';

    const isWhitespace = value.trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
