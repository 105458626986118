import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromActions from '../actions/supplier-price-list.actions';
import * as fromSelectors from '../selectors/supplier-price-list.selectors';
import * as fromReducer from '../reducers/supplier-price-list.reducer';
import { SupplierPriceListItemParameters } from 'app/shared/models/eazimate.models';

@Injectable({
  providedIn: 'root',
})
export class SupplierPriceListItemFacade {
  supplierPriceList$ = this.store.pipe(
    select(fromSelectors.getSupplierPriceListItem),
  );
  supplierPriceListItems$ = this.store.pipe(
    select(fromSelectors.getSupplierPriceListItemItems),
  );
  totalCount$ = this.store.pipe(select(fromSelectors.getSupplierTotalCount));
  supplierPriceListPageNumber$ = this.store.pipe(
    select(fromSelectors.getSupplierPriceListItemPageNumber),
  );

  isLoading$ = this.store.select(
    fromSelectors.getSupplierPriceListItemIsLoading,
  );
  isLoaded$ = this.store.select(fromSelectors.getSupplierPriceListItemIsLoaded);
  error$ = this.store.select(fromSelectors.getSupplierPriceListItemError);

  constructor(private store: Store<fromReducer.SupplierPriceListItemState>) {}

  loadSupplierPriceListItem(query: SupplierPriceListItemParameters): void {
    this.store.dispatch(fromActions.getSupplierPriceListItem({ query }));
  }

  addSupplierPriceItem(query: SupplierPriceListItemParameters): void {
    this.store.dispatch(fromActions.addSupplierPriceItem({ query }));
  }
}
