import { Action, createReducer, on } from '@ngrx/store';
import { Supplier } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import * as fromActions from '../actions/supplier-details.actions';

export interface SupplierState {
  supplier: Supplier;
  error: any;
  callState: CallState;
}

export const initialState: SupplierState = {
  supplier: null,
  error: null,
  callState: LoadingState.INIT,
};

export const profileReducer = createReducer(
  initialState,
  on(fromActions.getSupplier, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.getSupplierFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.getSupplierSuccess, (state, { payload }) => ({
    ...state,
    supplier: payload as Supplier,
    callState: LoadingState.LOADED,
  })),

  on(fromActions.editSupplier, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.editSupplierFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.editSupplierSuccess, (state, { payload }) => ({
    ...state,
    supplier: payload as Supplier,
    callState: LoadingState.LOADED,
  })),

  on(fromActions.deleteSupplier, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(fromActions.deleteSupplierFailure, (state, { payload }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error: payload,
  })),
  on(fromActions.deleteSupplierSuccess, (state, { payload }) => ({
    ...initialState,
    callState: LoadingState.LOADED,
  })),
);

export function reducer(state: SupplierState | undefined, action: Action) {
  return profileReducer(state, action);
}

export const getSupplier = (state: SupplierState) => {
  return state.supplier;
};

export const getError = (state: SupplierState) => {
  return state.error;
};
