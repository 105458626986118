<div class="flex flex-col" *ngIf="!isConnected">
    <p>
        If you use MYOB as your accounting platform, we can automatically
        synchronise data so you don’t have to type it into both systems
    </p>

    <button
        class="mt-4 flex h-7 flex-row items-center"
        [disabled]="isReadOnly"
        (click)="connect()"
    >
        <span>Connect to</span>
        <img class="ml-2 h-7 w-16" src="images/logo/myob.png" />
    </button>
</div>
