/* eslint-disable arrow-parens */
import { UnitOfMeasure } from 'app/shared/models/eazimate.models';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { abnValidator, emptyStringValidator } from 'app/shared/form-validators';
import { AddressLookup } from 'app/shared/models';
import { AccountSettingsModel } from 'app/shared/models/form-ui.models';
import { ABNPipe } from 'app/shared/pipes/abn.pipe';
import { UploadImageService } from 'app/shared/services/upload-logo.service';
import { AccountUserFacade } from 'app/shared/store/facades/account-user.facade';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { MatButtonModule } from '@angular/material/button';
import { NumbersOnlyDirective } from '../../../../shared/directives/numbers-only.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AddressLookupDirective } from '../../../../shared/directives/address-lookup.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PHONE_PATTERN } from '@shared/constants/constants';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    AddressLookupDirective,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    InputMaskModule,
    NgIf,
    NumbersOnlyDirective,
    MatButtonModule,
  ],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  public states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  public unitsOfMeasure: UnitOfMeasure[] = undefined;
  unsubscriber$ = new Subject<void>();
  settingsForm: FormGroup;
  addressForm: FormGroup;
  phoneInputMask = createMask({
    alias: '9999999999',
  });
  abnInputMask = createMask({
    parser: (v) => v.split(' ').join(''),
    alias: '99 999 999 999',
  });

  phonePattern = PHONE_PATTERN;
  isReadOnly =
    this.async
      .transform(this.facade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingscompanyinfo' &&
          r.accessLevel === 0,
      ).length === 1;

  constructor(
    public facade: AccountUserFacade,
    private fb: FormBuilder,
    private abnPipe: ABNPipe,
    private changeDetector: ChangeDetectorRef,
    private uploadImageService: UploadImageService,
    public dialog: MatDialog,
    private async: AsyncPipe,
  ) {
    this.addressForm = this.fb.group({
      streetAddress: [
        { value: '', disabled: this.isReadOnly },
        [Validators.maxLength(128),Validators.required],
      ],
      suburb: [{ value: '', disabled: this.isReadOnly }, [Validators.required, Validators.maxLength(64)]],
      state: [{ value: '', disabled: this.isReadOnly }, Validators.required],
      postCode: [
        { value: '', disabled: this.isReadOnly },
        [Validators.required, Validators.pattern(/\d{4}/)],
      ],
      latitude: [0],
      longitude: [0],
      locationType: [''],
      placeId: [''],
      country: [''],
      formattedAddress: [''],
    });

    this.settingsForm = this.fb.group({
      id: [''],
      basic: this.fb.group({
        businessEntityName: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(254),
          ],
        ],
        abn: [
          { value: '', disabled: this.isReadOnly },
          [Validators.required, abnValidator()],
        ],
        email: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            Validators.email,
            emptyStringValidator(),
            Validators.maxLength(254),
          ],
        ],
        website: [{ value: '', disabled: this.isReadOnly }, [Validators.maxLength(100)]],
        phoneNumber: [{ value: '', disabled: this.isReadOnly },[Validators.maxLength(20)]],
        mobileNumber: [{ value: '', disabled: this.isReadOnly }],
        defaultOnCost: [
          { value: 0, disabled: this.isReadOnly },
          [Validators.required],
        ],
        address: this.addressForm,
        license: [{ value: '', disabled: this.isReadOnly },[Validators.maxLength(1024)]],
        logo: [{ value: '', disabled: this.isReadOnly }],
      }),
      quote: this.fb.group({
        quotePrefix: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(10),
          ],
        ],
        quoteStartNumber: [
          { value: 0, disabled: this.isReadOnly },
          [Validators.required],
        ],
        invoicePrefix: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(10),
          ],
        ],
        invoiceStartNumber: [
          { value: 0, disabled: this.isReadOnly },
          [Validators.required],
        ],
        purchaseOrderPrefix: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(10),
          ],
        ],
        purchaseOrderStartNumber: [
          { value: 0, disabled: this.isReadOnly },
          [Validators.min(0), Validators.max(999999)],
        ],
        // purchaseOrderAtCategoryLevel: [false],
        jobPrefix: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(10),
          ],
        ],
        jobStartNumber: [
          { value: 0, disabled: this.isReadOnly },
          [Validators.min(0), Validators.max(999999)],
        ],
      }),
      terms: this.fb.group({
        quoteTerms: [
          { value: 1, disabled: this.isReadOnly },
          [Validators.required, Validators.min(1)],
        ],
        invoiceTerms: [
          { value: 1, disabled: this.isReadOnly },
          [Validators.required, Validators.min(1)],
        ],
        variationTerms: [
          { value: 1, disabled: this.isReadOnly },
          [Validators.required, Validators.min(1)],
        ],
      }),
      bank: this.fb.group({
        bankAccountName: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(150),
          ],
        ],
        bankAccount: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(50),
          ],
        ],
        bankBSB: [
          { value: '', disabled: this.isReadOnly },
          [
            Validators.required,
            emptyStringValidator(),
            Validators.maxLength(10),
          ],
        ],
      }),
    });
  }

  ngOnInit(): void {
    this.facade.accountSaved$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: any) => {
        this.settingsForm.markAsPristine();
      });

    this.facade.accountFormModel$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((settings: AccountSettingsModel) => {
        if (settings) {
          settings = {
            ...settings,
            abn: this.abnPipe.transform(settings.abn),
          };
          this.initFormValues(settings);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  initFormValues(settings: AccountSettingsModel): void {
    const formVals = {
      basic: {
        businessEntityName: settings.businessEntityName,
        abn: settings.abn,
        email: settings.email,
        website: settings.website,
        phoneNumber: settings.phoneNumber,
        mobileNumber: settings.mobileNumber,
        defaultOnCost: settings.defaultOnCost,
        address: settings.address,
        license: settings.license,
        logo: settings.logo,
      },
      quote: {
        quotePrefix: settings.quotePrefix,
        quoteStartNumber: settings.quoteStartNumber,
        invoicePrefix: settings.invoicePrefix,
        invoiceStartNumber: settings.invoiceStartNumber,
        purchaseOrderPrefix: settings.purchaseOrderPrefix,
        purchaseOrderStartNumber: settings.purchaseOrderStartNumber,
        // purchaseOrderAtCategoryLevel: settings.purchaseOrderAtCategoryLevel,
        jobPrefix: settings.jobPrefix,
        jobStartNumber: settings.jobStartNumber,
      },
      terms: {
        quoteTerms: settings.quoteTerms,
        invoiceTerms: settings.invoiceTerms,
        variationTerms: settings.variationTerms,
      },
      bank: {
        bankAccountName: settings.bankAccountName,
        bankAccount: settings.bankAccount,
        bankBSB: settings.bankBSB,
      },
    };
    this.settingsForm.patchValue(formVals);
    if (settings.quoteTerms === 0) {
      this.settingsForm.get('terms.quoteTerms').markAsTouched();
    }
    if (settings.invoiceTerms === 0) {
      this.settingsForm.get('terms.invoiceTerms').markAsTouched();
    }
    if (settings.variationTerms === 0) {
      this.settingsForm.get('terms.variationTerms').markAsTouched();
    }
    this.settingsForm.get('basic').markAllAsTouched();
    this.settingsForm.get('bank').markAllAsTouched();
  }

  handleAddressSelected(address: AddressLookup): void {
    this.addressForm.patchValue(address);
    this.addressForm.markAsDirty();
    this.changeDetector.detectChanges();
  }

  getFormValues(): AccountSettingsModel {
    let model = {
      ...this.settingsForm.get('basic').value,
      ...this.settingsForm.get('quote').value,
      ...this.settingsForm.get('terms').value,
      ...this.settingsForm.get('bank').value,
    } as AccountSettingsModel;

    model = {
      ...model,
      abn: model?.abn?.replace(/\D/g, '') ?? '',
    };

    return model;
  }

  save(): void {
    const formValues = this.getFormValues();
    this.facade.saveAccountSettingsForm(formValues);
  }

  deleteLogo(): void {
    const formValues = this.getFormValues();
    formValues.logo = '';
    this.facade.saveAccountSettingsForm(formValues);
  }

  getInvalidControlNames(group: AbstractControl): any {
    const invalid = [];

    if (group instanceof FormGroup) {
      const controls = (group as FormGroup).controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log('invalid control:::', controls[name]);
          invalid.push(name);
        }
      }
    }
    return invalid;
  }

  openCropperDialog(): void {
    this.uploadImageService
      .openCropperDialog()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((file) => {
        if (file) {
          this.facade.uploadCompanyLogo(file);
        }
      });
  }
}
