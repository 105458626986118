/* eslint-disable ngrx/on-function-explicit-return-type */
/* eslint-disable arrow-parens */
import { createReducer, on } from '@ngrx/store';
import { FileUploadType, JobFile } from 'app/shared/models';
import { fileUploadCompleted } from 'app/shared/store/actions';
import { CallState, LoadingState } from 'app/store';
import {
  clearJobFilesState,
  createJobFilesFolderFailure,
  createJobFilesFolderSuccess,
  createJobFileSuccess,
  deleteFilesSuccess,
  deleteFoldersSuccess,
  deselectFileIds,
  deselectJobFilesFolderPaths,
  getJobFilesFailure,
  getJobFilesSuccess,
  renameJobFilesFolderSuccess,
  renameJobFileSuccess,
  selectFileIds,
  selectJobFilesFolderPaths,
  setJobFilesCurrentFolderPath,
  setJobFilesSortField,
  setJobFilesSortOrder,
  toggleJobFilesFileSelected,
  toggleJobFilesFolderSelected,
} from '../actions/job-files.actions';

export enum JobFilesListSortField {
  Date = 'date',
  Name = 'name',
  Size = 'size',
}
export interface JobFilesState {
  callState: CallState;
  files: JobFile[];
  currentFolderPath: string;
  selectedFileIds: string[];
  selectedFolderPaths: string[];
  sortField: string;
  sortOrder: 'asc' | 'desc';
}

export const initialState: JobFilesState = {
  callState: LoadingState.INIT,
  files: [],
  currentFolderPath: 'Home\\',
  selectedFileIds: [],
  selectedFolderPaths: [],
  sortField: 'name',
  sortOrder: 'asc',
};

export const jobFilesReducer = createReducer(
  initialState,
  on(fileUploadCompleted, (state, { entity, file }) => ({
    ...state,
    files:
      file.uploadType === FileUploadType.JobFile
        ? [...state.files, entity as JobFile]
        : [...state.files],
  })),
  on(createJobFilesFolderSuccess, (state, { file }) => ({
    ...state,
    files: [...state.files, file],
  })),
  on(createJobFilesFolderFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(renameJobFilesFolderSuccess, (state, { files }) => ({
    ...state,
    files: state.files.map((f) => {
      const renamedFile = files.find((x) => x.id === f.id);
      return renamedFile ?? f;
    }),
  })),
  on(renameJobFileSuccess, (state, { file }) => ({
    ...state,
    files: state.files.map((f) => {
      if (f.id === file.id) {
        return file;
      }

      return f;
    }),
  })),
  on(getJobFilesSuccess, (state, { files }) => ({
    ...state,
    callState: LoadingState.LOADED,
    files,
  })),
  on(getJobFilesFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),
  on(setJobFilesCurrentFolderPath, (state, { path }) => ({
    ...state,
    selectedFileIds: [],
    currentFolderPath: path,
  })),
  on(setJobFilesSortField, (state, { sortField }) => ({
    ...state,
    sortField,
  })),
  on(setJobFilesSortOrder, (state, { sortOrder }) => ({
    ...state,
    sortOrder,
  })),
  on(toggleJobFilesFileSelected, (state, { id }) => ({
    ...state,
    selectedFileIds: state.selectedFileIds.includes(id)
      ? state.selectedFileIds.filter((f) => f !== id)
      : [...state.selectedFileIds, id],
  })),
  on(toggleJobFilesFolderSelected, (state, { path }) => ({
    ...state,
    selectedFolderPaths: state.selectedFolderPaths.includes(path)
      ? state.selectedFolderPaths.filter((f) => f !== path)
      : [...state.selectedFolderPaths, path],
  })),
  on(selectFileIds, (state, { ids }) => ({
    ...state,
    selectedFileIds: ids,
  })),
  on(selectJobFilesFolderPaths, (state, { paths }) => ({
    ...state,
    selectedFolderPaths: paths,
  })),
  on(createJobFileSuccess, (state, { file }) => ({
    ...state,
    files: [...state.files, file],
  })),
  on(deselectFileIds, (state, { ids }) => ({
    ...state,
    selectedFileIds: state.selectedFileIds.filter((f) => !ids.includes(f)),
  })),
  on(deselectJobFilesFolderPaths, (state, { paths }) => ({
    ...state,
    selectedFolderPaths: state.selectedFolderPaths.filter(
      (f) => !paths.includes(f),
    ),
  })),
  on(deleteFilesSuccess, (state, { ids }) => ({
    ...state,
    files: state.files.filter((f) => !ids.includes(f.id)),
    selectedFileIds: [],
  })),
  on(deleteFoldersSuccess, (state, { ids }) => ({
    ...state,
    files: state.files.filter((f) => !ids.includes(f.id)),
    selectedFolderPaths: [],
  })),
  on(clearJobFilesState, () => ({
    ...initialState,
  })),
);
