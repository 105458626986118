import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-photos-add-name-dialog',
  templateUrl: './photos-add-name-dialog.html',
  styleUrls: ['./photos-add-name-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class PhotosAddNameDialog implements OnInit {
  editForm: FormGroup;
  public title = `Add file name`;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PhotosAddNameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string; name: string },
  ) {
    this.editForm = this.fb.group({
      name: [this.data?.name, Validators.required],
    });
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close(undefined);
  }
  save(): void {
    const response = {
      id: this.data?.id,
      name: this.editForm.controls.name.value,
    };
    this.dialogRef.close(response);
  }
}
