import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import * as fromReducer from '../reducers/supplier-list.reducer';
import * as fromFeature from '../reducers';

export const getSupplierListState = createSelector(
  fromFeature.getSupplierReviewState,
  (state: fromFeature.SupplierState) => state?.supplierList,
);

export const getSupplierList = createSelector(
  getSupplierListState,
  fromReducer.getSupplierList,
);

export const getSupplierListPageNumber = createSelector(
  getSupplierListState,
  fromReducer.getSupplierListPageNumber,
);

export const getSupplierListItems = createSelector(
  getSupplierListState,
  fromReducer.getSupplierListItems,
);

export const getSupplierTotalCount = createSelector(
  getSupplierListState,
  fromReducer.getSupplierTotalCount,
);

export const getSupplierListIsLoading = createSelector(
  getSupplierListState,
  (s) => s.callState === LoadingState.LOADING,
);

export const getSupplierListIsLoaded = createSelector(
  getSupplierListState,
  (s) => s?.callState === LoadingState.LOADED,
);

export const getSupplierListError = createSelector(getSupplierListState, (s) =>
  getError(s?.callState),
);
