import { createAction, props } from '@ngrx/store';
import { Supplier } from 'app/shared/models';

export enum SupplierActionTypes {
  GetSupplier = '[Supplier] Get Supplier',
  GetSupplierSuccess = '[Supplier API] Get Supplier Success',
  GetSupplierFailure = '[Supplier API] Get Supplier Failure',

  EditSupplier = '[Supplier] Edit Supplier',
  EditSupplierSuccess = '[Supplier API] Edit Supplier Success',
  EditSupplierFailure = '[Supplier API] Edit Supplier Failure',

  DeleteSupplier = '[Supplier] Delete Supplier',
  DeleteSupplierSuccess = '[Supplier API] Delete Supplier Success',
  DeleteSupplierFailure = '[Supplier API] Delete Supplier Failure',
}

export const getSupplier = createAction(
  SupplierActionTypes.GetSupplier,
  props<{ query: string }>(),
);
export const getSupplierSuccess = createAction(
  SupplierActionTypes.GetSupplierSuccess,
  props<{ payload: Supplier }>(),
);
export const getSupplierFailure = createAction(
  SupplierActionTypes.GetSupplierFailure,
  props<{ payload: any }>(),
);

export const editSupplier = createAction(
  SupplierActionTypes.EditSupplier,
  props<{ query: Supplier }>(),
);
export const editSupplierSuccess = createAction(
  SupplierActionTypes.EditSupplierSuccess,
  props<{ payload: Supplier }>(),
);
export const editSupplierFailure = createAction(
  SupplierActionTypes.EditSupplierFailure,
  props<{ payload: any }>(),
);

export const deleteSupplier = createAction(
  SupplierActionTypes.DeleteSupplier,
  props<{ query: string }>(),
);
export const deleteSupplierSuccess = createAction(
  SupplierActionTypes.DeleteSupplierSuccess,
  props<{ payload: Supplier }>(),
);
export const deleteSupplierFailure = createAction(
  SupplierActionTypes.DeleteSupplierFailure,
  props<{ payload: any }>(),
);
