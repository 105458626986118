import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { EstimateState, selectEstimateState } from '../reducers';
import { EstimateStatus } from '../../../../shared/models';

export const selectEstimateListState = createSelector(
  selectEstimateState,
  (s: EstimateState) => s.estimates,
);

export const selectEstimateListIsLoading = createSelector(
  selectEstimateListState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectEstimateListIsLoaded = createSelector(
  selectEstimateListState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectEstimateListError = createSelector(
  selectEstimateListState,
  (s) => getError(s.callState),
);

export const selectEstimateList = createSelector(selectEstimateListState, (s) =>
  s.estimates.items.map((e) => ({
    ...e,
    statusText: EstimateStatus[e.status],
  })),
);

export const selectEstimatesHistoryList = createSelector(
  selectEstimateListState,
  (s) =>
    s.estimatesHistory.items.map((e) => ({
      ...e,
      statusText: EstimateStatus[e.status],
    })),
);

export const selectEstimateListTotalCount = createSelector(
  selectEstimateListState,
  (s) => s.estimates.pageMetaData?.records,
);

export const selectEstimateListPageNumber = createSelector(
  selectEstimateListState,
  (s) => s.estimates.pageMetaData?.pageNumber,
);
