import { HttpClient, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AcceptTeamMember,
  Account,
  AccountUser,
  LoggedInUser,
  PriceListCompleted,
  TeamMember,
} from 'app/shared/models/eazimate.models';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountUserService {
  constructor(private http: HttpClient) {}

  removeShowMessageFlagOnRemovedAccount(payload: string[]): Observable<any> {
    const url = `${environment.urls.api}/teammember/acknowledgemessageshown`;

    return this.http.post<any>(url, payload);
  }

  saveThemeMode(requestPayload: any): Observable<any> {
    const url = `${environment.urls.api}/accountusersetting/upsert`;

    return this.http.put<any>(url, requestPayload);
  }

  getSasToken(): Observable<string> {
    const url = `${environment.urls.api}/settings/account/sas`;
    return this.http.get<string>(url);
  }

  getAccount(): Observable<Account> {
    const url = `${environment.urls.api}/account`;
    return this.http.get<Account>(url);
  }

  logIn(): Observable<LoggedInUser> {
    const url = `${environment.urls.api}/user/login`;
    return this.http.get<LoggedInUser>(url);
  }

  saveAccount(account: Account): Observable<Account> {
    const url = `${environment.urls.api}/settings/account`;
    return this.http.post<Account>(url, account);
  }

  cancelAccount(): Observable<Account> {
    const url = `${environment.urls.api}/settings/account/cancel`;
    return this.http.post<Account>(url, {});
  }

  saveAccountUser(payload: AccountUser): Observable<AccountUser> {
    const url = `${environment.urls.api}/settings/profile`;
    return this.http.put<AccountUser>(url, payload);
  }

  acceptTeamMemberInvite(payload: AcceptTeamMember): Observable<TeamMember> {
    const url = `${environment.urls.api}/teammember/accept`;
    return this.http.post<TeamMember>(url, payload);
  }

  updateTeamMember(payload: TeamMember): Observable<TeamMember> {
    const url = `${environment.urls.api}/teammember/save`;
    return this.http.put<TeamMember>(url, payload);
  }

  uploadCompanyLogo(file: File): Observable<any> {
    const url = `${environment.urls.api}/settings/account/logo`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', url, formData);

    return this.http.post<any>(url, formData);
  }

  uploadQuoteLogo(estimateId: string, file: File): Observable<any> {
    const url = `${environment.urls.api}/estimate/${estimateId}/logo`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest('POST', url, formData);

    return this.http.post<any>(url, formData);
  }

  check(): Observable<any> {
    const url = `${environment.urls.api}/user/check`;
    return this.http.get<any>(url);
  }

  getSubscriptionStatus(): Observable<any> {
    const url = `${environment.urls.api}/user/subscriptionstatus`;
    return this.http.get<any>(url);
  }

  teamMemberLeavesAccount(payload: any): Observable<any> {
    const url = `${environment.urls.api}/settings/team/member/leave`;
    return this.http.post<any>(url, payload);
  }

  getPriceListSetupPogress(): Observable<PriceListCompleted> {
    const url = `${environment.urls.api}/pricelist/noprice`;
    return this.http.get<PriceListCompleted>(url);
  }
}
