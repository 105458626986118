import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  JobOrderSendStatusParams,
  JobOrderStatusParams,
} from '../../models/job-orders';
import {
  addJobsOrderDetails,
  clearJobOrderDetailsState,
  getJobsOrderDetails,
  updateJobOrderSendStatus,
  updateJobOrderStatus,
  updateJobsOrderDetails,
} from '../actions';
import { JobState } from '../reducers';
import {
  selectJobsOrderDetails,
  selectJobsOrderDetailsError,
  selectJobsOrderDetailsIsLoaded,
  selectJobsOrderDetailsIsLoading,
  selectJobsOrderItemsDetails,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobOrderDetailsFacade {
  isLoading$ = this.store.select(selectJobsOrderDetailsIsLoading);
  isLoaded$ = this.store.select(selectJobsOrderDetailsIsLoaded);
  error$ = this.store.select(selectJobsOrderDetailsError);
  jobOrder$ = this.store.select(selectJobsOrderDetails);
  orderItems$ = this.store.select(selectJobsOrderItemsDetails);

  constructor(private store: Store<JobState>) {}

  getJobsOrderDetails(request: any) {
    this.store.dispatch(getJobsOrderDetails({ request }));
  }

  updateJobsOrderDetails(request: any) {
    this.store.dispatch(updateJobsOrderDetails({ request }));
  }

  addJobsOrderDetails(request: any) {
    this.store.dispatch(addJobsOrderDetails({ request }));
  }

  updateJobOrderStatus(request: JobOrderStatusParams) {
    this.store.dispatch(updateJobOrderStatus({ request }));
  }

  updateJobOrderSendStatus(request: JobOrderSendStatusParams) {
    this.store.dispatch(updateJobOrderSendStatus({ request }));
  }

  clearJobOrderDetailsState() {
    this.store.dispatch(clearJobOrderDetailsState());
  }
}
