/* eslint-disable arrow-parens */
import { AsyncPipe, NgIf, NgClass, NgFor } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Estimate, TeamMember } from 'app/shared/models';
import { GuidedTourService } from 'app/shared/services/guided-tour.service';
import {
  GuidedTourNameEnum,
  IntroJsService,
} from 'app/shared/services/introjs.service';
import { AccountUserFacade } from 'app/shared/store/facades';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { EstimateActionTypes, EstimateDetailFacade } from '../../store';
import { CreateTemplateModalComponent } from '../create-template-modal/create-template-modal.component';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { ActivatedRoute } from '@angular/router';
import { EstimateHeaderComponent } from '../estimate-header/estimate-header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EstimatePriceListItemsComponent } from '@app/views/estimates/components/estimate-price-list-items/estimate-price-list-items.component';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    EstimateHeaderComponent,
    EstimatePriceListItemsComponent,
    NgClass,
    NgFor,
    AsyncPipe,
  ],
})
export class EstimateComponent implements OnInit, OnDestroy, AfterViewInit {
  accountId: string;
  estimateId: string;
  canCreateTemplate: boolean;
  isEditing = false;
  addedSectionName: string;
  intro;
  isReadOnlyMode = this.route.snapshot.data['userReadonlyMode'];

  estimate$ = this.estimateDetailFacade.estimate$.pipe(filter((e) => !!e));
  itemCount$ = this.estimateDetailFacade.itemCount$;
  canCreateTemplate$ = this.itemCount$.pipe(map((count) => count > 0));
  defaultOnCost$ = this.accountUserFacade.loggedInUser$.pipe(map(user  => user.account.defaultOnCost));
  now = new Date();

  private unsubscriber$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private estimateDetailFacade: EstimateDetailFacade,
    private accountUserFacade: AccountUserFacade,
    private introJsService: IntroJsService,
    private guidedTourService: GuidedTourService,
    private cdRef: ChangeDetectorRef,
    private asyncPipe: AsyncPipe,
    private actionsSubj: ActionsSubject,
    private route: ActivatedRoute,
  ) {
    this.estimateDetailFacade.editing$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((e) => (this.isEditing = e));
  }

  ngAfterViewInit(): void {
    this.estimate$.pipe(takeUntil(this.unsubscriber$)).subscribe((res) => {
      if (res) {
        this.introJsService.currentIntroPage$.next(
          GuidedTourNameEnum.EstimateCostingDetails,
        );
        this.introJsService.resetedTour$
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe(() => {
            this.introJsService.isTourMode$.next(true);
            this.introInit();
          });
        this.guidedTourService
          .getGuidedTourShow(btoa(GuidedTourNameEnum.EstimateCostingDetails))
          .subscribe((show) => {
            if (show) {
              this.introJsService.isTourMode$.next(true);
              this.introInit();
            }
          });
      }
    });
  }

  introInit(): void {
    this.introJsService.introMenuMode$.next(true);
    this.intro = this.introJsService.getIntro();
    this.intro.onexit(() => {
      this.introJsService.isTourMode$.next(false);
      this.guidedTourService
        .completeGuidedTour(btoa(GuidedTourNameEnum.EstimateCostingDetails))
        .subscribe(() => this.introJsService.introMenuMode$.next(false));
      this.intro.setOptions({});
      this.intro = null;
    });
    this.cdRef.detectChanges();

    this.introJsService.estimateCostingDetails(
      this.intro,
      false,
      false,
      this.isReadOnlyMode,
    );
  }

  save(): void {
    this.estimateDetailFacade.save();
  }

  cancel(): void {
    this.estimateDetailFacade.reset();
  }
  createTemplate(): void {
    this.dialog
      .open(CreateTemplateModalComponent, {
        width: '626px',
      })
      .afterClosed()
      .subscribe(({ name, description }) => {
        if (name) {
          this.estimateDetailFacade.saveAsTemplate({
            accountId: this.accountId,
            estimateId: this.estimateId,
            name,
            description,
          });
        }
      });
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnInit(): void {
    this.accountUserFacade.activeAccount$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((r: TeamMember) => (this.accountId = r.accountId));

    this.estimate$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((e: Estimate) => {
        this.estimateId = e?.id;
      });

    this.itemCount$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((count) => (this.canCreateTemplate = count > 0));

    this.actionsSubj
      .pipe(
        ofType(EstimateActionTypes.AddEstimateSection),
        takeUntil(this.unsubscriber$),
      )
      .subscribe((r: { name: string; type: string }) => {
        this.addedSectionName = r.name;
      });
  }

  ngOnDestroy(): void {
    this.introJsService.currentIntroPage$.next(null);
    this.introJsService.isTourMode$.next(false);
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
