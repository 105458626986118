import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { BillingService } from "@app/core/billing/billing.service";
import { SettingsService } from "../../services/settings.service";

@Component({
  selector: 'app-manage-extra-seats-dialog',
  standalone: true,
  imports: [MatDialogModule, NgFor, NgIf, NgClass, MatButtonModule, MatListModule],
  templateUrl: './manage-extra-seats-dialog.html',
  styleUrl: './manage-extra-seats-dialog.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ManageExtraSeatsDialogComponent implements OnInit {
  numExtraSeats = 0;
  numAvailableSeats = 0;
  changes: any;
  loadingExtraSeats = true;
  loadingAvailableSeats = true;

  constructor(
    private billingService: BillingService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.settingsService.getExtraSeatsCount()
      .subscribe(numSeats => {
        this.numExtraSeats = numSeats;
        this.loadingExtraSeats = false;
      });

    this.settingsService.getAvailableSeats()
      .subscribe(numSeats => {
        this.numAvailableSeats = numSeats;
        this.loadingAvailableSeats = false;
      });

    this.billingService.getRemoveExtraSeatCosts()
      .subscribe(result => {
        this.changes = result;
      });
  }

}
