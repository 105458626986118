import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { QuoteLayoutType } from 'app/shared/models';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { UploadImageService } from 'app/shared/services/upload-logo.service';
import { AccountUserFacade } from 'app/shared/store/facades/account-user.facade';
import { QuoteFormService } from 'app/views/estimates/services/quote-form.service';
import { EstimateDetailFacade } from 'app/views/estimates/store';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-quote-settings',
    templateUrl: './quote-settings.component.html',
    styleUrls: ['./quote-settings.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        MatSelectModule,
        NgIf,
        SharedPipesModule,
    ],
})
export class JobQuoteSettingsComponent implements OnInit, OnDestroy {
    layouts = [
        // {
        //   name: 'Logo centered',
        //   value: QuoteLayoutType.Classic
        // },
        {
            name: 'Logo right',
            value: QuoteLayoutType.Modern,
        },
        // {
        //   name: 'Logo left',
        //   value: QuoteLayoutType.Fun
        // }
    ];
    daysQuoteIsValid = [
        {
            name: 'In 7 days',
            value: 7,
        },
        {
            name: 'In 14 days',
            value: 14,
        },
        {
            name: 'In 30 days',
            value: 30,
        },
        {
            name: 'In 60 days',
            value: 60,
        },
        {
            name: 'In 90 days',
            value: 90,
        },
    ];

    unsubscribeAll = new Subject();

    constructor(
        public quoteFormService: QuoteFormService,
        public UploadImageService: UploadImageService,
        public estimateDetailFacade: EstimateDetailFacade,
        private accountUserFacade: AccountUserFacade,
        private asyncPipe: AsyncPipe
    ) {}

    ngOnInit(): void {
        // this.estimateQuoteFacade.estimateFull$
        //   .pipe(takeUntil(this.unsubscribeAll))
        //   .subscribe((x) => {
        //     if (!!x?.estimate?.quote?.logo) {
        //       this.quoteFormService.quoteForm
        //         .get('logo')
        //         .patchValue(x.estimate.quote.logo);
        //       this.changeDetectorRef.detectChanges();
        //     }
        //   });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    formatDate(): void {
        this.quoteFormService.quoteForm
            .get('sent')
            .setValue(
                moment(
                    this.quoteFormService.quoteForm.get('sent').value
                ).format()
            );
    }

    openCropperDialog(): void {
        if (this.quoteFormService.isReadOnlyMode) {
            return;
        }
        const estimateId = this.asyncPipe.transform(
            this.estimateDetailFacade.estimate$
        ).id;

        this.UploadImageService.openCropperDialog()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((file) => {
                if (file) {
                    this.accountUserFacade.uploadQuoteLogo(estimateId, file);
                }
            });
    }

    validDaysBlur(): void {
        if (this.quoteFormService.quoteForm.get('validDays').invalid) {
            const accountSettings = this.asyncPipe.transform(
                this.accountUserFacade.accountFormModel$
            );
            this.quoteFormService.quoteForm
                .get('validDays')
                .setValue(accountSettings.quoteTerms);
        }
    }
}
