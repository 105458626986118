import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCommonData } from '../actions';
import { selectEstimateStatuses, selectJobStatuses } from '../selectors';
import * as fromAccountSelectors from 'app/shared/store/selectors/account-user.selectors';
import { AsyncPipe } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class CommonDataFacade {
  public jobStatuses$ = this.store.select(selectJobStatuses);
  public estimateStatuses$ = this.store.select(selectEstimateStatuses);

  constructor(
    private store: Store,
    private async: AsyncPipe,
  ) {}

  public getCommonData(): void {
    const accountUser$ = this.store.select(
      fromAccountSelectors.selectAccountUser,
    );
    if (this.async.transform(accountUser$)) {
      this.store.dispatch(getCommonData());
    }
  }
}
