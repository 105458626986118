import { Pipe, PipeTransform } from '@angular/core';

/**
 * Finds an object from given source using the given key - value pairs
 */
@Pipe({
  name: 'enumIdToValue',
  pure: false,
  standalone: true,
})
export class EnumIdToValuePipe implements PipeTransform {
  /**
   * Constructor
   */
  constructor() {}

  /**
   * Transform
   *
   * @param id A string or an array of strings to find from source
   * @param source Array of objects to find from
   */
  transform(id: string, source: { id: string; name: string }[]): any {
    if (!id || !source) {
      return null;
    }
    return source.find((sourceItem) => sourceItem.id === id).name;
  }
}
