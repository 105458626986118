import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AddPriceListItem,
  PriceListCategory,
  PriceListItem,
} from 'app/shared/models';
import * as fromActions from '../actions';
import * as fromSelectors from '../selectors/current-price-list.selectors';
import * as fromSuppliersListSelectors from 'app/views/suppliers/store/selectors/supplier-list.selectors';
@Injectable({
  providedIn: 'root',
})
export class CurrentPriceListFacade {
  fullPriceList$ = this.store.select(fromSelectors.selectFullPriceList);
  priceList$ = this.store.select(fromSelectors.selectCurrentPriceList);
  allCategories$ = this.store.select(
    fromSelectors.selectAllPriceListCategories,
  );
  categories$ = this.store.select(
    fromSelectors.selectCurrentPriceListCategories,
  );
  suppliers$ = this.store.select(fromSelectors.selectCurrentPriceListSuppliers);
  items$ = this.store.select(fromSelectors.selectCurrentPriceListItems);
  isLoading$ = this.store.select(fromSelectors.selectCurrentPriceListIsLoading);
  isLoaded$ = this.store.select(fromSelectors.selectCurrentPriceListIsLoaded);
  error$ = this.store.select(fromSelectors.selectCurrentPriceListError);
  supplierList$ = this.store.select(fromSuppliersListSelectors.getSupplierList);

  constructor(private store: Store) {}

  addItem(item: AddPriceListItem): void {
    this.store.dispatch(fromActions.addCurrentPriceListItem({ item }));
  }

  editItem(item: PriceListItem): void {
    this.store.dispatch(fromActions.editCurrentPriceListItem({ item }));
  }

  editCategory(category: PriceListCategory): void {
    this.store.dispatch(fromActions.editCurrentPriceListCategory({ category }));
  }

  addCategory(category: PriceListCategory): void {
    this.store.dispatch(fromActions.addCurrentPriceListCategory({ category }));
  }

  selectPriceListItems(ids: string[]): void {
    this.store.dispatch(fromActions.selectCurrentPriceListItems({ ids }));
  }

  deselectPriceListItems(ids: string[]): void {
    this.store.dispatch(fromActions.deselectCurrentPriceListItems({ ids }));
  }

  deletePriceListItemsConfirm(items: PriceListItem[]): void {
    this.store.dispatch(
      fromActions.deleteCurrentPriceListItemsConfirm({ items }),
    );
  }

  deletePriceListCategoryConfirm(category: PriceListCategory): void {
    this.store.dispatch(
      fromActions.deleteCurrentPriceListCategoryConfirm({ category }),
    );
  }

  getCurrentPriceList(): void {
    this.store.dispatch(fromActions.getCurrentPriceList());
  }

  updateCurrentPriceListCategory(category): void {
    this.store.dispatch(
      fromActions.updateCurrentPriceListCategory({ category }),
    );
  }
}
