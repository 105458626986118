<div class="mb-2.5">
  {{ data?.title }}
</div>
<div [formGroup]="form">
  <div class="flex flex-row justify-center">
    <mat-checkbox
      [formControl]="checked"
      [labelPosition]="'before'"
      [color]="'primary'"
    >
      <span class="mr-4"> {{ data?.label }} </span>
    </mat-checkbox>
  </div>
  <div class="buttons-group mt-8">
    <button class="ml-2" mat-stroked-button (click)="cancel()">Cancel</button>
    <button
      class="xs:text-sm ml-2"
      mat-flat-button
      [color]="'primary'"
      (click)="save()"
      [disabled]="!form.dirty"
    >
      Save
    </button>
  </div>
</div>
