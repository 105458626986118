import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogClose,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Attachment, Snippet, StorageSource } from 'app/shared/models';
import {
    AttachmentModalData,
    AttachmentModalDataResponse,
    SendModalData,
} from 'app/shared/models/form-ui.models';
import { FilesFacade } from 'app/views/files/store';
import { PhotosFacade } from 'app/views/photo/store';
import moment from 'moment';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { filter } from 'rxjs';
import { AttachmentModalComponent } from '../attachment-modal/attachment-modal.component';
import { QuillEditorElementComponent } from '../quill-editor-element/quill-editor-element.component';

@Component({
    selector: 'app-send-modal',
    templateUrl: './send-modal.component.html',
    styleUrls: ['./send-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [AsyncPipe],
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogClose,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDialogModule,
        ClickOutsideModule,
        NgFor,
        MatChipsModule,
        MatInputModule,
        NgxTrimDirectiveModule,
        MatTooltipModule,
        NgClass,
        NgIf,
        MatMenuModule,
        MatDatepickerModule,
        QuillEditorElementComponent,
        MatCheckboxModule,
        MatTableModule,
        MatSortModule,
    ],
})
export class SendModalComponent implements OnInit {
    displayedColumns: string[] = ['name', 'action'];
    dataSource = new MatTableDataSource([]);

    showChipInput = true;
    separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
    chipCtrl = this.fb.control(null, Validators.email);
    chipCtrlHasError = false;
    emails: string[] = [];

    form: FormGroup = this.fb.group({
        to: [[''], [Validators.required, this.maxChipLengthValidator()]],
        subject: ['', [Validators.required]],
        message: ['', [Validators.required]],
        sendMe: [''],
    });

    attachments;

    get to() {
        return this.form.get('to');
    }

    constructor(
        public dialogRef: MatDialogRef<SendModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SendModalData,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private cdRef: ChangeDetectorRef,
        private photosFacade: PhotosFacade,
        private FilesFacade: FilesFacade,
        private asyncPipe: AsyncPipe,
        private confirm: FuseConfirmationService,
        private renderer: Renderer2
    ) {}

    @ViewChild(MatSort) sort: MatSort;

    ngOnInit(): void {
        if (this.data.callFor) {
            this.form.addControl(
                'deliveryInstructions',
                new FormControl('', Validators.required)
            );
            this.form.addControl(
                'calledFor',
                new FormControl('', Validators.required)
            );

            this.form.get('calledFor').valueChanges.subscribe(() => {
                this.handleSnippetChange(this.form.get('message').value);
            });
            this.form.get('calledFor').patchValue(this.data.callFor.calledFor);
            this.data.callFor.deliveryInstructions$.subscribe((r) => {
                this.form.get('deliveryInstructions').patchValue(r.content);
            });
            this.data.callFor.snippet$.subscribe((r) => {
                this.handleSnippetChange(r.content);
            });
        }

        if (this.data.jobId) {
            this.photosFacade.getPhotos(this.data.jobId);
            this.FilesFacade.getFiles(this.data.jobId);
        }

        if (this.data.to instanceof Array) {
            this.emails = this.data.to;
        }

        this.form.patchValue(this.data);

      if (this.data.quillOptions.snippetContent$) {
        this.data.quillOptions.snippetContent$.subscribe((v: Snippet) =>  {
          this.handleSnippetChange(v.content);
        })
      }
    }

    formatDate(controlName) {
        this.form
            .get(controlName)
            .setValue(moment(this.form.get(controlName).value).format());
    }

    callForDateUpdate(value) {
        this.form
            .get('calledFor')
            .setValue(
                moment(Date.now()).startOf('day').add(value, 'days').format()
            );
    }

    keyCheck(event, chipList): void {
        if (event.data == ';' || event.data == ',') {
            this.chipCtrl.setValue(this.chipCtrl.value.slice(0, -1));

            if (this.chipCtrl.value) {
                this.add(chipList);
            }
        }

        if (event.data === 'Tab') {
            if (!this.chipCtrl.invalid) {
                this.showChipInput = false;
                this.cdRef.detectChanges();
            }
        }
    }

    add(event: MatChipInputEvent): void {
        if (this.chipCtrl.invalid) {
            this.chipCtrlHasError = true;
            return;
        }
        this.chipCtrlHasError = false;
        const value = (this.chipCtrl.value || '').trim();
        if (value) {
            this.emails.push(value);
        }
        if (event.chipInput) {
            event.chipInput!.clear();
        }
        if (event['_chipInput']) {
            event['_chipInput'].clear();
        }
        this.chipCtrl.setValue(null);
        this.to.setValue(this.emails);
    }

    remove(fruit: string): void {
        const index = this.emails.indexOf(fruit);
        if (index >= 0) {
            this.emails.splice(index, 1);
        }
        this.to.setValue(this.emails);
        this.showChipInput = false;
        this.renderer.selectRootElement('#chipsInput').click();
    }

    maxChipLengthValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return;
            }

            return control.value.toString().length < 254
                ? null
                : { maxlength: true };
        };
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        if (this.form.valid) {
            if (
                this.attachments?.files?.length ||
                this.attachments?.photos?.length
            ) {
                let attachments: Attachment[] = [];
                this.attachments.files.forEach((file) => {
                    const attachment = {
                        storageSource: StorageSource.JobFile,
                        file: file,
                    };
                    attachments.push(attachment);
                });
                this.attachments.photos.forEach((photo) => {
                    const attachment = {
                        storageSource: StorageSource.JobPhoto,
                        photo: photo,
                    };
                    attachments.push(attachment);
                });
                let results = {
                    ...this.form.value,
                    attachments: attachments,
                };
                this.dialogRef.close(results);
            } else {
                this.dialogRef.close(this.form.value);
            }
        } else {
            this.form.markAllAsTouched();
        }
    }

    addAttachments(): void {
        const data: AttachmentModalData = {
            photosFolders: this.asyncPipe.transform(this.photosFacade.folders$),
            photosSasToken: this.asyncPipe.transform(
                this.photosFacade.sasToken$
            ),
            photos: this.asyncPipe.transform(this.photosFacade.allPhotos$),

            filesFolders: this.asyncPipe.transform(this.FilesFacade.folders$),
            filesSasToken: this.asyncPipe.transform(this.FilesFacade.sasToken$),
            files: this.asyncPipe.transform(this.FilesFacade.allFiles$),
        };

        data.photos = JSON.parse(JSON.stringify(data.photos)).map((photo) => {
            if (this.attachments?.photos.some((p) => photo.id === p.id)) {
                photo.selected = true;
            }
            return photo;
        });
        data.files = JSON.parse(JSON.stringify(data.files)).map((files) => {
            if (this.attachments?.files.some((f) => files.id === f.id)) {
                files.selected = true;
            }
            return files;
        });

        const dialogRef = this.dialog.open(AttachmentModalComponent, {
            width: '680px',
            maxHeight: '100vh',
            data: data,
            panelClass: 'app-attachment-modal',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: AttachmentModalDataResponse) => {
                if (result) {
                    this.attachments = result;
                    this.dataSource = new MatTableDataSource([
                        ...result.files,
                        ...result.photos,
                    ]);
                    this.dataSource.sort = this.sort;
                    this.cdRef.detectChanges();
                }
            });
    }

    deleteAttachment(item: any): void {
        this.confirm
            .open({
                title: 'Delete attachment',
                message: 'Are you sure you want to delete this attachment?',
                icon: {
                    name: 'heroicons_outline:exclamation-triangle',
                    color: 'warn',
                },
                actions: {
                    cancel: { label: 'Cancel' },
                    confirm: { label: 'Delete', color: 'warn' },
                },
            })
            .afterClosed()
            .pipe(filter((result) => result === 'confirmed'))
            .subscribe(() => {
                if (
                    this.attachments.files.some((file) => item.id === file.id)
                ) {
                    let index;
                    this.attachments.files.forEach((file, i) => {
                        if (item.id === file.id) {
                            index = i;
                        }
                    });
                    this.attachments.files.splice(index, 1);
                }

                if (
                    this.attachments.photos.some(
                        (photo) => item.id === photo.id
                    )
                ) {
                    let index;
                    this.attachments.photos.forEach((file, i) => {
                        if (item.id === file.id) {
                            index = i;
                        }
                    });
                    this.attachments.photos.splice(index, 1);
                }

                this.dataSource = new MatTableDataSource([
                    ...this.attachments.files,
                    ...this.attachments.photos,
                ]);
                this.dataSource.sort = this.sort;
                this.cdRef.detectChanges();
            });
    }

    handleSnippetChange(value: string) {
        let message = value;
        let sign;
        try {
            sign = `<p>${this.data.tags['{AccountFullName}']}</p> <p>${this.data.tags['{AccountName}']}</p>`;
        } catch (e) {
            console.error(
                'The template is not correct. Most possible the tags is not defined.'
            );
            sign = '';
        }
        if (value.includes('{Name}')) {
            try {
                message = message.replace('{Name}', this.data.tags['{Name}']);
            } catch (e) {
                console.error(
                    'The template is not correct. Most possible the tags is not defined.'
                );
            }
        }
        if (this.form.get('calledFor')?.value) {
            const date = moment(this.form.get('calledFor').value).format(
                'DD/MM/YYYY'
            );
            if (value.includes('{Called For Date}')) {
                message = message.replace('{Called For Date}', date);
            } else {
                message = message.replace(/\d+\/\d+\/\d+/gi, date);
            }
        }
        if (value.endsWith('Thanks')) {
            message += sign;
        }
        if (!value.includes('Thanks')) {
            message += '<br>Thanks' + sign;
        }

        this.form.get('message').patchValue(message);
    }
}
