import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logoName',
  standalone: true,
})
export class LogoNmaePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const newValue = value.split('/').pop().split('?')[0];
      return newValue;
    } else {
      return value;
    }
  }
}
