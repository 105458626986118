/* eslint-disable ngrx/no-typed-global-store */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStoreSelectors from '../store/selectors/account-user.selectors';
import * as fromStoreReducers from '../store/reducers/account-user.reducer';
import { TeamMember } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HeaderAppenderInterceptor implements HttpInterceptor, OnDestroy {
  private teamMember: TeamMember = undefined;
  private sub = new Subscription();
  constructor(
    private readonly store: Store<fromStoreReducers.AccountUserState>,
  ) {
    this.sub.add(
      this.store
        .select(fromStoreSelectors.selectActiveAccount)
        .subscribe((x) => {
          this.teamMember = x;
        }),
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // console.log('HeaderAppenderInterceptor req:::', req);
    const accountId = this.teamMember?.accountId || '';
    const modifiedReq = req.clone({
      setHeaders: { 'X-AccountId': accountId },
    });
    return next.handle(modifiedReq);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
