import { createAction, props } from '@ngrx/store';

export enum CommonActionTypes {
  _getCommonData = '[Common] GetCommonData',
  _getCommonDataSuccess = '[Common] GetCommonDataSuccess',
  _getCommonDataFailure = '[Common] GetCommonDataFailure',
}

export const getCommonData = createAction(CommonActionTypes._getCommonData);

export const getCommonDataSuccess = createAction(
  CommonActionTypes._getCommonDataSuccess,
  props<{
    jobStatuses: { id: number; name: string }[];
    estimateStatuses: { id: number; name: string }[];
  }>(),
);

export const getCommonDataFailure = createAction(
  CommonActionTypes._getCommonDataFailure,
  props<{ error: any }>(),
);
