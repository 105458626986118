import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-estimate-section-dialog',
  templateUrl: './estimate-section-dialog.component.html',
  styleUrls: ['./estimate-section-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxTrimDirectiveModule,
    MatInputModule,
  ],
})
export class EstimateSectionDialogComponent implements OnInit {
  editForm: FormGroup = undefined;
  public title = `${this.data?.name ? 'Rename' : 'Add'} ${
    this.data?.area ? this.data?.area : 'Section'
  }`;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EstimateSectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { id: string; name: string; area?: string },
  ) {
    this.editForm = this.fb.group({
      name: [this.data?.name, Validators.required],
    });
  }

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close(undefined);
  }
  save(): void {
    const response = {
      id: this.data?.id,
      name: this.editForm.controls.name.value,
    };
    this.dialogRef.close(response);
  }
}
