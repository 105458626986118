import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobOrderPriceListItemState = createSelector(
  selectJobState,
  (s: JobState) => s.jobOrderPriceListItem,
);

export const selectJobOrderPriceListItemIsLoading = createSelector(
  selectJobOrderPriceListItemState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobOrderPriceListItemIsLoaded = createSelector(
  selectJobOrderPriceListItemState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobOrderPriceListItemError = createSelector(
  selectJobOrderPriceListItemState,
  (s) => getError(s.callState),
);

export const selectJobOrderPriceListItem = createSelector(
  selectJobOrderPriceListItemState,
  (s) => s.jobOrder?.items,
);

export const selectJobOrderPriceListItemTotalCount = createSelector(
  selectJobOrderPriceListItemState,
  (s) => s.jobOrder?.pageMetaData?.records,
);

export const selectJobOrderPriceListItemPageNumber = createSelector(
  selectJobOrderPriceListItemState,
  (s) => s.jobOrder?.pageMetaData?.pageNumber,
);
