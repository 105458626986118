<ng-container *ngIf="(af.accountsToDisplayDialogFor$ | async).length > 0">
  <div mat-dialog-title>
    {{
      (af.accountsToDisplayDialogFor$ | async).length > 1
        ? "You were invited to join accounts"
        : "You were invited to join account"
    }}
  </div>
  <mat-dialog-content class="mb-8">
    <div class="flex flex-col mt-2 divide-y border-t border-b">
      <ng-container
        *ngFor="
          let member of af.accountsToDisplayDialogFor$ | async;
          trackBy: trackByFn;
          let i = index
        "
      >
        <div
          class="flex flex-col sm:flex-row sm:items-center py-6 justify-between"
        >
          <div class="flex items-center">
            <div [style]="getStyle(member.accountEmail, member)">
              {{ getCapitalLetters(member.accountName) }}
            </div>
            <div class="ml-4">
              <div class="font-medium">
                {{ member.accountName }}
              </div>
              <div class="text-secondary">
                {{ member.accountEmail }}
              </div>
            </div>
          </div>
          <div>
            <!-- <span>{{ member.roles[0].name }}</span> -->
          </div>
          <div class="ml-1">
            <mat-icon
              class="icon-size-6 text-hint text-red-600 dark:text-red-500 cursor-pointer"
              [svgIcon]="'heroicons_outline:no-symbol'"
              (click)="rejectInvite(member)"
              matTooltip="Reject invitation"
            ></mat-icon>
            <mat-icon
              class="icon-size-6 text-hint text-green-600 dark:text-green-500 ml-2 cursor-pointer"
              [svgIcon]="'heroicons_outline:check-circle'"
              (click)="acceptInvite(member)"
              matTooltip="Accept invitation"
            ></mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
</ng-container>

<ng-container
  class="mt-4"
  *ngIf="(af.recentTeamUserRemovedFrom$ | async).length > 0"
>
  <div mat-dialog-title>
    {{
      (af.recentTeamUserRemovedFrom$ | async).length > 1
        ? "You were removed from following accounts"
        : "You were removed from account"
    }}
  </div>
  <mat-dialog-content>
    <div class="flex flex-col mt-2 divide-y border-t border-b">
      <ng-container
        *ngFor="
          let member of af.recentTeamUserRemovedFrom$ | async;
          trackBy: trackByFn;
          let i = index
        "
      >
        <div
          class="flex flex-col sm:flex-row sm:items-center py-6 justify-between"
        >
          <div class="flex items-center">
            <div [style]="getStyle(member.accountEmail, member)">
              {{ getCapitalLetters(member.accountName) }}
            </div>
            <div class="ml-4">
              <div class="font-medium">
                {{ member.accountName }}
              </div>
              <div class="text-secondary">
                {{ member.accountEmail }}
              </div>
            </div>
          </div>
          <div>
            <!-- <span>{{ member.roles[0].name }}</span> -->
          </div>
          <div class="ml-1" style="visibility: hidden">
            <mat-icon
              class="icon-size-6 text-hint text-red-600 dark:text-red-500 cursor-pointer"
              [svgIcon]="'heroicons_outline:no-symbol'"
              (click)="rejectInvite(member)"
            ></mat-icon>
            <mat-icon
              class="icon-size-6 text-hint text-green-600 dark:text-green-500 ml-2 cursor-pointer"
              [svgIcon]="'heroicons_outline:check-circle'"
              matTooltip="Accept invitation"
            ></mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
</ng-container>
