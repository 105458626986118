import { createAction, props } from '@ngrx/store';
import {
  JobNote,
  JobNoteParameters,
  PaginatedListOfJob,
} from 'app/shared/models';

export enum JobNotesActionTypes {
  GetJobNotes = '[Job Notes] Get Job Notes',
  GetJobNotesSuccess = '[Job Notes] Get Job Notes Success',
  GetJobNotesFailure = '[Job Notes] Get Job Notes Failure',
  AddJobNote = '[Job Notes] Add Note Job',
  AddJobNoteSuccess = '[Job Notes] Add Job Note Success',
  AddJobNoteFailure = '[Job Notes] Add Job Note Failure',
  UpdateJobNote = '[Job Notes] Update Note Job',
  UpdateJobNoteSuccess = '[Job Notes] Update Job Note Success',
  UpdateJobNoteFailure = '[Job Notes] Update Job Note Failure',
  DeleteJobNote = '[Job Notes] Delete Note Job',
  DeleteJobNoteSuccess = '[Job Notes] Delete Job Note Success',
  DeleteJobNoteFailure = '[Job Notes] Delete Job Note Failure',
}

export const updateJobNote = createAction(
  JobNotesActionTypes.UpdateJobNote,
  props<{ note: JobNote }>(),
);

export const updateJobNoteSuccess = createAction(
  JobNotesActionTypes.UpdateJobNoteSuccess,
  props<{ note: JobNote }>(),
);

export const updateJobNoteFailure = createAction(
  JobNotesActionTypes.UpdateJobNoteFailure,
  props<{ error: string }>(),
);

export const addJobNote = createAction(
  JobNotesActionTypes.AddJobNote,
  props<{ note: JobNote }>(),
);

export const addJobNoteSuccess = createAction(
  JobNotesActionTypes.AddJobNoteSuccess,
  props<{ note: JobNote }>(),
);

export const addJobNoteFailure = createAction(
  JobNotesActionTypes.AddJobNoteFailure,
  props<{ error: string }>(),
);

export const deleteJobNote = createAction(
  JobNotesActionTypes.DeleteJobNote,
  props<{ id: string }>(),
);

export const deleteJobNoteSuccess = createAction(
  JobNotesActionTypes.DeleteJobNoteSuccess,
  props<{ id: string }>(),
);

export const deleteJobNoteFailure = createAction(
  JobNotesActionTypes.DeleteJobNoteFailure,
  props<{ error: string }>(),
);

export const getJobNotes = createAction(
  JobNotesActionTypes.GetJobNotes,
  props<{ request: JobNoteParameters }>(),
);

export const getJobNotesSuccess = createAction(
  JobNotesActionTypes.GetJobNotesSuccess,
  props<{ notes: PaginatedListOfJob }>(),
);

export const getJobNotesFailure = createAction(
  JobNotesActionTypes.GetJobNotesFailure,
  props<{ error: string }>(),
);
