import { createAction, props } from '@ngrx/store';
import { AddJobOrder, JobOrder } from 'app/shared/models';
import {
  JobOrderSendStatusParams,
  JobOrderStatusParams,
} from '../../models/job-orders';

export enum JobOrderDetailsActionTypes {
  GetJobOrderDetailsFacade = '[JobOrder Details] Get JobOrder',
  GetJobOrderDetailsFacadeSuccess = '[JobOrder Details] Get JobOrder Success',
  GetJobOrderDetailsFacadeFailure = '[JobOrder Details] Get JobOrder Failure',

  UpdateJobOrderDetailsFacade = '[JobOrder Details] Update JobOrder',
  UpdateJobOrderDetailsFacadeSuccess = '[JobOrder Details] Update JobOrder Success',
  UpdateJobOrderDetailsFacadeFailure = '[JobOrder Details] Update JobOrder Failure',

  UpdateJobOrderStatusFacade = '[JobOrder Details] Update Status JobOrder',
  UpdateJobOrderStatusFacadeSuccess = '[JobOrder Details] Update Status JobOrder Success',
  UpdateJobOrderStatusFacadeFailure = '[JobOrder Details] Update Status JobOrder Failure',

  UpdateJobOrderSendStatus = '[JobOrder Details] Update JobOrder Send Status',
  UpdateJobOrderSendStatusSuccess = '[JobOrder Details] Update JobOrder Send Status Success',
  UpdateJobOrderSendStatusFailure = '[JobOrder Details] Update JobOrder Send Status Failure',

  AddJobOrderDetailsFacade = '[JobOrder Details] Add JobOrder',
  AddJobOrderDetailsFacadeSuccess = '[JobOrder Details] Add JobOrder Success',
  AddJobOrderDetailsFacadeFailure = '[JobOrder Details] Add JobOrder Failure',

  ClearJobOrderDetailsState = '[JobOrder Details] clearState JobOrder',
}

export const getJobsOrderDetails = createAction(
  JobOrderDetailsActionTypes.GetJobOrderDetailsFacade,
  props<{ request: string }>(),
);
export const getJobsOrderDetailsSuccess = createAction(
  JobOrderDetailsActionTypes.GetJobOrderDetailsFacadeSuccess,
  props<{ jobOrder: JobOrder }>(),
);
export const getJobsOrderDetailsFailure = createAction(
  JobOrderDetailsActionTypes.GetJobOrderDetailsFacadeFailure,
  props<{ error: any }>(),
);

export const updateJobOrderStatus = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderStatusFacade,
  props<{ request: JobOrderStatusParams }>(),
);
export const updateJobOrderStatusSuccess = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderStatusFacadeSuccess,
  props<{ jobOrder: JobOrder }>(),
);
export const updateJobOrderStatusFailure = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderStatusFacadeFailure,
  props<{ error: any }>(),
);

export const updateJobsOrderDetails = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderDetailsFacade,
  props<{ request: JobOrder }>(),
);
export const updateJobsOrderDetailsSuccess = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderDetailsFacadeSuccess,
  props<{ jobOrder: any }>(),
);
export const updateJobsOrderDetailsFailure = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderDetailsFacadeFailure,
  props<{ error: any }>(),
);

export const addJobsOrderDetails = createAction(
  JobOrderDetailsActionTypes.AddJobOrderDetailsFacade,
  props<{ request: AddJobOrder }>(),
);
export const addJobsOrderDetailsSuccess = createAction(
  JobOrderDetailsActionTypes.AddJobOrderDetailsFacadeSuccess,
  props<{ jobOrder: JobOrder }>(),
);
export const addJobsOrderDetailsFailure = createAction(
  JobOrderDetailsActionTypes.AddJobOrderDetailsFacadeFailure,
  props<{ error: any }>(),
);

export const updateJobOrderSendStatus = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderSendStatus,
  props<{ request: JobOrderSendStatusParams }>(),
);
export const updateJobOrderSendStatusSuccess = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderSendStatusSuccess,
  props<{ jobOrder: JobOrder }>(),
);

export const updateJobOrderSendStatusFailure = createAction(
  JobOrderDetailsActionTypes.UpdateJobOrderSendStatusFailure,
  props<{ error: any }>(),
);

export const clearJobOrderDetailsState = createAction(
  JobOrderDetailsActionTypes.ClearJobOrderDetailsState,
);
