import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getJobOrderItemsToOrder, resetJobOrderItemsToOrder } from '../actions';
import { JobListState } from '../reducers/job-list.reducer';
import {
  selectJobOrderItemsToOrder,
  selectJobOrderItemsToOrderError,
  selectJobOrderItemsToOrderIsLoaded,
  selectJobOrderItemsToOrderIsLoading,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobOrderItemsToOrderFacade {
  isLoading$ = this.store.select(selectJobOrderItemsToOrderIsLoading);
  isLoaded$ = this.store.select(selectJobOrderItemsToOrderIsLoaded);
  error$ = this.store.select(selectJobOrderItemsToOrderError);
  jobOrderItemsToOrder$ = this.store.select(selectJobOrderItemsToOrder);

  constructor(private store: Store<JobListState>) {}

  getJobOrderItemsToOrder(query: any) {
    this.store.dispatch(getJobOrderItemsToOrder({ query }));
  }
  clearJobOrderItemsToOrder() {
    this.store.dispatch(resetJobOrderItemsToOrder());
  }
}
