<ng-container [formGroup]="form">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button [matDialogClose]="undefined" mat-icon-button>
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="shadow-lg flex flex-col sm:flex-row items-center p-8 pb-2">
    <!-- Icon -->
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600"
    >
      <mat-icon
        class="text-current"
        svgIcon="heroicons_outline:envelope"
      ></mat-icon>
    </div>

    <div class="text-center sm:text-left">
      <!-- Title -->
      <div class="text-xl leading-6 font-medium" [innerHTML]="data.title"></div>
    </div>
  </div>

  <!-- Form -->

  <mat-dialog-content class="overflow-y-auto">
    <div class="flex flex-col overflow-y-auto h-full gap-x-5 p-8 pt-4 pb-0 mb-0">
      <div class="grid sm:grid-cols-4 gap-3 w-full mb-5">
        <div class="sm:col-span-4">
          <mat-form-field
            class="w-full"
            (click)="showChipInput = true; chipsInput.focus()"
            (clickOutside)="!chipCtrl.invalid ? (showChipInput = false) : null"
          >
            <mat-label>To</mat-label>
            <mat-chip-grid #chipList formControlName="to">
              <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
                <button matChipRemove [attr.aria-label]="'remove'">
                  <mat-icon>cancel</mat-icon>
                </button>
                {{ email }}
              </mat-chip-row>
              <input
                class="p-0 my-3"
                id="chipsInput"
                #chipsInput
                [formControl]="chipCtrl"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                [ngClass]="{
                  'border-0 border-b border-solid border-b-red-600':
                    chipCtrlHasError && chipCtrl.invalid,
                  'h-0 w-0 opacity-0 overflow-hidden my-0': !showChipInput
                }"
                (blur)="showChipInput = false"
                (matChipInputTokenEnd)="add($event)"
                (input)="keyCheck($event, chipList)"
                matInput
                trim
              />
            </mat-chip-grid>
            <mat-hint
              >After entering email address, press enter to complete.</mat-hint>
            <mat-error
              *ngIf="form.get('to').hasError('maxlength')"
            >
              Maximum 254 characters
            </mat-error>
          </mat-form-field>
        </div>
        <div class="sm:col-span-4 flex flex-row">
          <mat-form-field
            class="fuse-mat-no-subscript mr-4"
            [ngClass]="{
              'w-full': !data?.callFor,
              'w-2/3': data?.callFor
            }"
          >
            <mat-label>Subject</mat-label>
            <input trim="blur" formControlName="subject" matInput maxlength="254"/>
          </mat-form-field>

          <div class="relative w-1/3" *ngIf="data?.callFor">
            <div class="absolute -z-1 bottom-0 overflow-hidden">
              <button
                #callForDateMenuTrigger="matMenuTrigger"
                [matMenuTriggerFor]="menu"
                mat-button
              >
              </button>
            </div>
            <mat-form-field class="fuse-mat-no-subscript w-full">
              <mat-label>Called for</mat-label>
              <input
                [matDatepicker]="calledFor"
                (dateChange)="formatDate('callForDate')"
                (focus)="callForDateMenuTrigger.openMenu()"
                formControlName="calledFor"
                [min]="data.callFor.minDate"
                matInput
              />
              <mat-icon
                class="button-icon"
                [svgIcon]="'heroicons_outline:chevron-down'"
                matSuffix
              >
              </mat-icon>
              <mat-datepicker #calledFor></mat-datepicker>
            </mat-form-field>
            <mat-menu #menu="matMenu">
              <button
                *ngFor="let item of data?.callFor?.calledForMenuOptions"
                (click)="callForDateUpdate(item.value)"
                mat-menu-item
              >
                {{ item.name }}
              </button>
              <button (click)="calledFor.open()" mat-menu-item>
                Pick another date
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="sm:col-span-4" *ngIf="data?.callFor">
          <div class="w-full">
            <mat-label class="block mb-1">Delivery Note *</mat-label>
            <app-quill-editor-element
              formControlName="deliveryInstructions"
            ></app-quill-editor-element>
          </div>
        </div>
        <div class="sm:col-span-4">
          <div class="w-full">
            <mat-label class="block mb-1">Message</mat-label>
            <app-quill-editor-element
              [data]="data?.quillOptions"
              [useFirstValue]="true"
              (snippetChanged)="handleSnippetChange(form.get('message').value)"
              formControlName="message"
            ></app-quill-editor-element>
          </div>
        </div>
          </div>
      <div
        class="my-2.5"
        [hidden]="!attachments?.photos?.length && !attachments?.files?.length"
      >
        <div class="max-h-32 overflow-y-auto">
          <table
            class="w-full"
            [dataSource]="dataSource"
            mat-table
            matSortActive="name"
            matSortDirection="asc"
            matSortDisableClear
            matSort
          >
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>
                Attachments
              </th>
              <td *matCellDef="let element" mat-cell>
                {{ element.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th class="w-14 pr-2" *matHeaderCellDef mat-header-cell>Action</th>
              <td class="w-14 pr-2" *matCellDef="let element" mat-cell>
                <button
                  (click)="$event.stopPropagation(); deleteAttachment(element)"
                  mat-icon-button
                >
                  <mat-icon
                    class="icon-size-5 text-secondary"
                    [svgIcon]="'heroicons_outline:trash'"
                    aria-hidden="true"
                    matPrefix
                  ></mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              *matHeaderRowDef="displayedColumns; sticky: true"
              mat-header-row
            ></tr>
            <tr
              class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
              *matRowDef="let row; columns: displayedColumns"
              (click)="deleteAttachment(row)"
              mat-row
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <!-- Action buttons -->
  <div
    class="shadow-lg flex items-center justify-start px-6 py-4 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <div class="mr-auto w-2/5">
      <mat-checkbox color="primary" formControlName="sendMe">Send me a copy
      </mat-checkbox>
      <span class="w-full line-clamp-1 block text-nowrap overflow-hidden text-ellipsis"
      [matTooltip]="data.from.length > 20 ? data.from : ''">({{ data.from }})</span>
    </div>
    <button *ngIf="data?.jobId"
             class=" pr-4 pl-4"
             [color]="'primary'"
             (click)="addAttachments()"
             mat-stroked-button
    >
      <div class="flex flex-row items-center">
        <span class="ml-2 mr-2 text-wrap">Add attachments</span>
      </div>
    </button>
    <button class="ml-4 fuse-mat-button-large" (click)="close()" mat-stroked-button>
      <span class="ml-2 mr-1">Cancel</span>
    </button>
    <div class="ml-0">
      <button
        class="ml-4 fuse-mat-button-large"
        [disabled]="form.invalid"
        [color]="'primary'"
        (click)="save()"
        mat-flat-button>
          <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:paper-airplane'"></mat-icon>
          <span class="ml-2 mr-1">Send</span>
      </button>
    </div>
  </div>
</ng-container>
