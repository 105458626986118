import { AccountUserFacade } from './account-user.facade';
import { CommonDataFacade } from './common-data.facade';
import { FileDownloadFacade } from './file-download.facade';
import { FileUploadFacade } from './file-upload.facade';

export * from './account-user.facade';
export * from './common-data.facade';
export * from './file-download.facade';
export * from './file-upload.facade';

export const facades = [
  FileUploadFacade,
  FileDownloadFacade,
  AccountUserFacade,
  CommonDataFacade,
];
