import { createAction, props } from '@ngrx/store';
import {
  Supplier,
  PaginatedListOfSupplier,
  SupplierParameters,
} from 'app/shared/models';

export enum SupplierListActionTypes {
  GetSupplierList = '[SupplierList] Get SupplierList',
  GetSupplierListSuccess = '[SupplierList API] Get SupplierList Success',
  GetSupplierListFailure = '[SupplierList API] Get SupplierList Failure',

  AddSupplier = '[SupplierList] Add Supplier',
  AddSupplierSuccess = '[SupplierList API] Add Supplier Success',
  AddSupplierFailure = '[SupplierList API] Add Supplier Failure',

  AddXeroSupplier = '[SupplierList] Add Xero Supplier',
  AddXeroSupplierSuccess = '[SupplierList API] Add Xero Supplier Success',
  AddXeroSupplierFailure = '[SupplierList API] Add Xero Supplier Failure',
}

export const getSupplierList = createAction(
  SupplierListActionTypes.GetSupplierList,
  props<{ query: SupplierParameters }>(),
);
export const getSupplierListSuccess = createAction(
  SupplierListActionTypes.GetSupplierListSuccess,
  props<{ payload: PaginatedListOfSupplier }>(),
);
export const getSupplierListFailure = createAction(
  SupplierListActionTypes.GetSupplierListFailure,
  props<{ payload: any }>(),
);

export const addSupplier = createAction(
  SupplierListActionTypes.AddSupplier,
  props<{ query: any }>(),
);
export const addSupplierSuccess = createAction(
  SupplierListActionTypes.AddSupplierSuccess,
  props<{ payload: Supplier }>(),
);
export const addSupplierFailure = createAction(
  SupplierListActionTypes.AddSupplierFailure,
  props<{ payload: any }>(),
);

export const addXeroSupplier = createAction(
  SupplierListActionTypes.AddXeroSupplier,
  props<{ query: any }>(),
);
export const addXeroSupplierSuccess = createAction(
  SupplierListActionTypes.AddXeroSupplierSuccess,
  props<{ payload: Supplier }>(),
);
export const addXeroSupplierFailure = createAction(
  SupplierListActionTypes.AddXeroSupplierFailure,
  props<{ payload: any }>(),
);
