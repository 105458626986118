import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-photo-breadcrumb',
  templateUrl: './photo-breadcrumb.component.html',
  styleUrls: ['./photo-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgFor],
})
export class PhotoBreadcrumbComponent implements OnInit, OnChanges {
  constructor() {}

  crumbs: { path: string; name: string }[] = [];

  @Output() setCurrentFolderPath = new EventEmitter<string>();

  currentFolderName: string;

  @Input() currentFolder: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.crumbs = [];

    if (this.currentFolder?.path) {
      const parts = this.currentFolder.path.split('\\');

      let path: string[] = [];

      parts.forEach((p, i) => {
        if (i === parts.length - 1) {
          this.currentFolderName = p;
        } else {
          path.push(p);
          this.crumbs.push({ path: `${path.join('\\')}\\`, name: p });
        }
      });
    }
  }

  ngOnInit(): void {}
}
