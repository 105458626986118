import { Component, Input, OnChanges } from '@angular/core';
import { InvoiceStatusText } from '../invoice-list/invoice-list.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-invoice-status-badge',
  templateUrl: './invoice-status-badge.component.html',
  styleUrls: ['./invoice-status-badge.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class InvoiceStatusBadgeComponent implements OnChanges {
  @Input() status;
  statuses = InvoiceStatusText;
  text;

  ngOnChanges(): void {
    this.text = this.statuses[this.status];
  }
}
