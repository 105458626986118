import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  addJobsOrderDetails,
  addJobsOrderDetailsFailure,
  addJobsOrderDetailsSuccess,
  getJobsOrderDetails,
  getJobsOrderDetailsFailure,
  getJobsOrderDetailsSuccess,
  updateJobOrderSendStatus,
  updateJobOrderSendStatusFailure,
  updateJobOrderSendStatusSuccess,
  updateJobOrderStatus,
  updateJobOrderStatusFailure,
  updateJobOrderStatusSuccess,
  updateJobsOrderDetails,
  updateJobsOrderDetailsFailure,
  updateJobsOrderDetailsSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobOrderDetailsEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobsOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobsOrderDetails),
      switchMap(({ request }) =>
        this.jobService.getJobOrders(request).pipe(
          map((jobOrder: any) => getJobsOrderDetailsSuccess({ jobOrder })),
          catchError((error) => {
            return of(getJobsOrderDetailsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateJobsOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobsOrderDetails),
      switchMap(({ request }) =>
        this.jobService.updateJobOrders(request).pipe(
          map((jobOrder: any) => updateJobsOrderDetailsSuccess({ jobOrder })),
          catchError((error) => {
            return of(updateJobsOrderDetailsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateJobOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobOrderStatus),
      switchMap(({ request }) =>
        this.jobService.jobOrderStatus(request).pipe(
          map((jobOrder: any) => updateJobOrderStatusSuccess({ jobOrder })),
          catchError((error) => {
            return of(updateJobOrderStatusFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateJobOrderSendStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateJobOrderSendStatus),
      switchMap(({ request }) =>
        this.jobService.jobOrderSendStatus(request).pipe(
          map((jobOrder: any) => updateJobOrderSendStatusSuccess({ jobOrder })),
          catchError((error) => {
            return of(updateJobOrderSendStatusFailure({ error }));
          }),
        ),
      ),
    ),
  );

  addJobsOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addJobsOrderDetails),
      switchMap(({ request }) =>
        this.jobService.addJobOrders(request).pipe(
          map((jobOrder: any) => addJobsOrderDetailsSuccess({ jobOrder })),
          catchError((error) => {
            return of(addJobsOrderDetailsFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
