// customDateAdapter.ts
import { Injectable } from '@angular/core';

import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { DateTimeService } from './date-time.service';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends MomentDateAdapter {
  constructor(private _dateTimeService: DateTimeService) {
    super('en-AU'); // set default locale
  }

  public format(date: Moment, displayFormat: string): string {
    const locale = this._dateTimeService.getLocale();
    const format = this._dateTimeService.getFormat();

    return date.locale(locale).format(format);
  }
}
