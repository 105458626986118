import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { createMask, InputMaskModule } from '@ngneat/input-mask';
import { PHONE_PATTERN, STATES } from 'app/shared/constants/constants';
import { AddressLookup } from 'app/shared/models';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { AddressLookupDirective } from '../../directives/address-lookup.directive';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { abnValidator } from '@shared/form-validators';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    NgxTrimDirectiveModule,
    MatInputModule,
    NgIf,
    InputMaskModule,
    AddressLookupDirective,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
  ],
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  @Input() displayActionButtons = true;
  @Input() data;
  @Output() formInit = new EventEmitter();
  @Output() submitted = new EventEmitter();
  states = STATES;
  phonePattern = PHONE_PATTERN;
  addressForm: FormGroup = this.fb.group({
    streetAddress: ['', [Validators.required]],
    suburb: ['', Validators.required],
    state: ['', Validators.required],
    postCode: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    latitude: [0],
    longitude: [0],
    locationType: [''],
    placeId: [''],
    country: [''],
    formattedAddress: [''],
  });

  personForm: FormGroup = this.fb.group({
    id: [''],
    email: ['', Validators.email],
    title: [''],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phone: [''],
  });

  form: FormGroup;

  formattedAddress: string = '';
  title: string;

  abnInputMask = createMask({
    parser: (v) => v.split(' ').join(''),
    alias: '99 999 999 999',
  });
  phoneInputMask = createMask({
    alias: '9999999999',
  });

  private sub = new Subscription();

  constructor(
    private fb: FormBuilder,
    private chRef: ChangeDetectorRef,
  ) {
    this.title = this.data?.id ? 'Edit Customer' : 'New Customer';
  }

  ngOnInit(): void {
    this.initForm();
    if (this.data) {
      this.addressForm.get('longitude').setValue(this.data.address.longitude);
      this.addressForm.get('latitude').setValue(this.data.address.latitude);
      this.formattedAddress = this.data.address.formattedAddress;
      this.form.patchValue(this.data);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  initForm(): void {
    this.sub.add(
      this.addressForm.valueChanges.subscribe((v) => {
        const tmp = v;
        if (!tmp.country) {
          tmp.country = 'Australia';
        }
        tmp.formattedAddress = `${tmp.streetAddress.trim()}, ${tmp.suburb.trim()} ${
          tmp.state
        } ${tmp.postCode}, ${tmp.country}`;
        this.formattedAddress = tmp.formattedAddress;
        this.addressForm.patchValue(tmp, { emitEvent: false });
      }),
    );

    this.form = this.fb.group({
      businessName: [
        '',
        [
          Validators.minLength(3),
          Validators.maxLength(30),
          Validators.pattern('^[\\W\\d\\w\\s]*[^\\W|^\\d][\\W\\d\\w\\s]*'),
        ],
      ],
      abn: [null, abnValidator()],
      address: this.addressForm,
      person: this.personForm,
    });
    this.formInit.emit(this.form);
  }

  close(): void {
    this.submitted.emit(false);
  }

  save(): void {
    if (this.form.valid) {
      let value = JSON.parse(JSON.stringify(this.form.value));

      value = { ...this.data, ...value };
      this.submitted.emit(value);
    } else {
      this.form.markAllAsTouched();
    }
  }

  addressSelected(address: AddressLookup): void {
    this.addressForm.patchValue(address);
    this.addressForm.markAsDirty();
    this.chRef.detectChanges();
  }
}
