import { createReducer, on } from '@ngrx/store';
import { JobInvoice } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import { orderBy } from 'lodash';
import {
  addJobInvoice,
  changeInvoiceStatus,
  changeInvoiceStatusFailure,
  changeInvoiceStatusSuccess,
  clearInvoice,
  getCustomerJobInvoices,
  getCustomerJobInvoicesFailure,
  getCustomerJobInvoicesSuccess,
  getJobInvoice,
  getJobInvoiceFailure,
  getJobInvoices,
  getJobInvoicesFailure,
  getJobInvoicesSuccess,
  getJobInvoiceSuccess,
  sendInvoice,
  sendInvoiceFailure,
  sendInvoiceSuccess,
  updateInvoicePayment,
  updateInvoicePaymentFailure,
  updateInvoicePaymentSuccess,
  updateJobInvoice,
  updateJobInvoiceFailure,
  updateJobInvoiceSuccess,
} from '..';

export interface JobInvoicesState {
  callState: CallState;
  invoices: JobInvoice[];
  customerInvoices: JobInvoice[];
  invoice: JobInvoice;
  sortOrder: 'asc' | 'desc';
  error: string;
  totalCount: number;
}

export const initialState: JobInvoicesState = {
  callState: LoadingState.INIT,
  invoices: [],
  customerInvoices: [],
  invoice: null,
  sortOrder: 'asc',
  error: null,
  totalCount: 0,
};

const getSortedInvoices = (invoices: JobInvoice[], sortOrder): JobInvoice[] =>
  orderBy(invoices, (s: JobInvoice) => s.updated, sortOrder);

export const jobInvoicesReducer = createReducer(
  initialState,
  on(getJobInvoices, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    callState: LoadingState.LOADED,
    invoices: getSortedInvoices(invoices.items, state.sortOrder),
    totalCount: invoices.pageMetaData.records,
  })),
  on(getJobInvoicesFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),

  // customer Invoices
  on(getCustomerJobInvoices, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getCustomerJobInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    callState: LoadingState.LOADED,
    customerInvoices: invoices.items,
    totalCount: invoices.pageMetaData.records,
  })),
  on(getCustomerJobInvoicesFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),

  on(addJobInvoice, (state, { invoice }) => ({
    ...state,
    invoices: getSortedInvoices([...state.invoices, invoice], state.sortOrder),
  })),
  on(getJobInvoice, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobInvoiceSuccess, (state, { invoice }) => ({
    ...state,
    callState: LoadingState.LOADED,
    invoice: invoice,
  })),
  on(getJobInvoiceFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),
  on(changeInvoiceStatus, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(changeInvoiceStatusSuccess, (state, { invoice }) => ({
    ...state,
    callState: LoadingState.LOADED,
    invoice: invoice,
  })),
  on(changeInvoiceStatusFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),
  on(sendInvoice, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(sendInvoiceSuccess, (state, { invoice }) => ({
    ...state,
    callState: LoadingState.LOADED,
    // invoice: invoice,
  })),
  on(sendInvoiceFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),

  on(updateInvoicePayment, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateInvoicePaymentSuccess, (state, { invoicePayment }) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),
  on(updateInvoicePaymentFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),

  on(updateJobInvoice, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobInvoiceSuccess, (state, { invoice }) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),
  on(updateJobInvoiceFailure, (state, { error }) => ({
    ...state,
    callState: LoadingState.ERRORED,
    error,
  })),

  on(clearInvoice, (state) => ({
    ...state,
    invoice: null,
  })),
);
