/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/dashboard',
  },
  {
    id: 'callforward',
    title: 'Call Forwards',
    type: 'basic',
    icon: 'heroicons_outline:phone-arrow-up-right',
    link: '/call-forwards',
  },
  {
    id: 'estimates',
    title: 'Estimates',
    type: 'basic',
    icon: 'request_quote',
    link: '/estimates',
  },
  {
    id: 'jobs',
    title: 'Jobs',
    type: 'basic',
    icon: 'home_work',
    link: '/jobs',
  },
  {
    id: 'customers',
    title: 'Customers',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: '/customers',
  },
  {
    id: 'itemlist',
    title: 'Item List',
    type: 'basic',
    icon: 'build_circle',
    link: '/item-list',
  },
  {
    icon: 'heroicons_outline:identification',
    id: 'suppliers',
    link: '/suppliers',
    title: 'Suppliers',
    type: 'basic',
  },
  {
    icon: 'holiday_village',
    id: 'templates',
    link: '/templates',
    title: 'Templates',
    type: 'basic',
  },
  // {
  //   icon: 'heroicons_outline:clipboard-copy',
  //   id: 'snippets',
  //   link: '/snippets',
  //   title: 'Snippets',
  //   type: 'basic',
  // },
  {
    icon: 'heroicons_outline:book-open',
    id: 'library',
    link: '/library',
    title: 'Library',
    type: 'basic',
  },
  // {
  //     id: 'admin',
  //     icon: 'contacts',
  //     link: '/admin',
  //     title: 'Admin',
  //     type: 'basic',
  // },
  {
    id: 'accounts',
    title: 'My Accounts',
    type: 'basic',
    icon: 'heroicons_outline:user-group',
    link: '/accounts',
  },
  {
    icon: 'heroicons_outline:adjustments-vertical',
    id: 'settings',
    link: '/settings',
    title: 'Settings',
    type: 'basic',
  },
];

export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
