<div class="items-container absolute inset-0 flex flex-col flex-auto min-w-0 overflow-hidden">

  <form [formGroup]="filterForm" class="flex flex-row items-center flex-wrap px-2 mb-2">
    <button
      class="estimate-section-add-remove-items xs:mr-2 sm:mr-4 text-xs rounded-xl min-h-8 h-8"
      (click)="addRemoveItems()"
      matRipple
      mat-flat-button
      color="primary"
    >
            <span class="xs:hidden sm:block ml-2 mr-1"
            >Manage Item</span
            >
    </button>
    <div class="w-full max-w-80 mr-auto">
      <mat-form-field
        class="fuse-mat-dense fuse-mat-no-subscript w-full min-w-50 mb-1"
      >
        <mat-icon
          class="h-4 min-h-4"
          [svgIcon]="'heroicons_outline:magnifying-glass'"
          matPrefix
        >
        </mat-icon>
        <input
          [formControlName]="'searchTerm'"
          [autocomplete]="'off'"
          [placeholder]="'Search anything...'"
          (keydown.enter)="$event.preventDefault()"
          trim="blur"
          matInput
        />
        <button
          *ngIf="searchTerm.value"
          (click)="searchTerm.setValue('')"
          matSuffix
          mat-icon-button
          aria-label="Clear"
        >
          <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="w-64 mx-4">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-select [formControlName]="'supplierId'">
          <mat-option [value]="''">All suppliers</mat-option>
          <mat-option
            *ngFor="let supplier of suppliers"
            [value]="supplier.id"
          >
            {{ supplier.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          class="icon-size-5 dark:text-white"
          [svgIcon]="'heroicons_outline:tag'"
          matPrefix
          aria-hidden="true"
          matPrefix
        ></mat-icon>
      </mat-form-field>
    </div>
    <div class="w-64">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-select [formControlName]="'categoryId'">
          <mat-option [value]="''">All categories</mat-option>
          <mat-option
            *ngFor="let category of categories"
            [value]="category.id"
          >
            {{ category.code ?? "00" }} - {{ category.name }}
          </mat-option>
        </mat-select>
        <mat-icon
          class="icon-size-5 dark:text-white"
          [svgIcon]="'heroicons_outline:map-pin'"
          matPrefix
          aria-hidden="true"
          matPrefix
        ></mat-icon>
      </mat-form-field>
    </div>
  </form>

  <div *ngIf="items?.length" class="items-list flex flex-col flex-auto overflow-hidden px-2">
    <div class="lg:block hidden flex flex-col flex-auto overflow-y-auto">
      <table [dataSource]="dataSource" mat-table>
        <tr
          *matHeaderRowDef="displayedColumns; sticky: true"
          mat-header-row
        ></tr>
        <tr
          class="hover:bg-gray-400 dark:hover:bg-slate-700 cursor-pointer"
          *matRowDef="let row; columns: displayedColumns"
          mat-row
        ></tr>

        <ng-container matColumnDef="category">
          <th *matHeaderCellDef mat-header-cell>Category</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.priceListCategoryCode }} -
            {{ element.priceListCategoryName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="itemcode">
          <th *matHeaderCellDef mat-header-cell>Item Code</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.itemCode }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th *matHeaderCellDef mat-header-cell>Description</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="supplier">
          <th *matHeaderCellDef mat-header-cell>Preferred Supplier</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.supplier?.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="qty">
          <th *matHeaderCellDef mat-header-cell>Qty</th>
          <td *matCellDef="let element; let i = index" mat-cell>
            {{ element.qty }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uom">
          <th *matHeaderCellDef mat-header-cell>UOM</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.unitOfMeasure }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitPrice">
          <th *matHeaderCellDef mat-header-cell>Unit price</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.unitPrice | currency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unitTotal">
          <th *matHeaderCellDef mat-header-cell>Unit total</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.unitTotal | currency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="onCost">
          <th *matHeaderCellDef mat-header-cell>On cost</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.onCost.toFixed(2) }}%
          </td>
        </ng-container>

        <ng-container matColumnDef="netQuote">
          <th *matHeaderCellDef mat-header-cell>Net quote</th>
          <td *matCellDef="let element" mat-cell>
            {{ element.netQuote | currency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="quotable">
          <th *matHeaderCellDef mat-header-cell>Quotable</th>
          <td *matCellDef="let element" mat-cell>
            <mat-checkbox
              [checked]="element.quotable"
              (change)="setQuotable(element)"
              color="primary"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th class="w-32" *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let element" mat-cell>
            <button
              (click)="editItem(element)"
              mat-icon-button
              *ngIf="!readOnly"
              class="estimate-summary-edit"
            >
              <mat-icon
                class="icon-size-5 text-secondary"
                [svgIcon]="'heroicons_outline:pencil'"
                aria-hidden="true"
                matPrefix
              ></mat-icon>
            </button>
            <button
              *ngIf="!readOnly"
              (click)="$event.stopPropagation(); deleteItem(element.id)"
              mat-icon-button
            >
              <mat-icon
                class="icon-size-5 text-secondary"
                [svgIcon]="'heroicons_outline:trash'"
                aria-hidden="true"
                matPrefix
              ></mat-icon>
            </button>
          </td>
        </ng-container>
      </table>
    </div>
    <!-- tablet and mobile list  -->
    <div
      class="lg:hidden flex flex-col flex-auto wrapper overflow-y-auto mt-2 c px-4"
    >
      <fuse-card
        *ngFor="let element of dataSource?.data; let i = index"
        class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2 dark:bg-gray-900"
      >
        <div class="flex flex-col xs:w-full sm:w-1/3">
          <div class="text-md font-semibold leading-tight">
            Category: {{ element.priceListCategoryCode }} -
            {{ element.priceListCategoryName }}
          </div>

          <div class="text-secondary font-medium text-md">
            Item Code: {{ element.itemCode }}
          </div>
          <div class="text-secondary font-medium text-md">
            Supplier: {{ element.supplier?.name }}
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tight">
            Qty: {{ element.qty }}
          </div>
          <div class="text-secondary font-medium text-md">
            UOM: {{ element.unitOfMeasure }}
          </div>
        </div>

        <div
          class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2"
        >
          <div class="text-md font-semibold leading-tigh">
            Unit total: {{ element.unitTotal | currency }}
          </div>
          <div class="text-secondary font-medium text-md">
            Unit price: {{ element.unitPrice | currency }}
          </div>
          <div class="text-secondary font-medium text-md">
            On cost: {{ element.onCost.toFixed(2) }}%
          </div>
          <div class="text-secondary font-medium text-md">
            Net quote: {{ element.netQuote | currency }}
          </div>
        </div>

        <div
          class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto"
        >
          <button
            id="estimate-summary-edit"
            class="type-action"
            mat-stroked-button
            [color]="'primary'"
            *ngIf="!readOnly"
            (click)="editItem(element)"
          >
            <mat-icon matPrefix [svgIcon]="'edit'"></mat-icon>
          </button>
        </div>
      </fuse-card>
    </div>

  </div>
  <ng-container *ngIf="!this.items || !this.items.length">
    <div class="w-full ">
      <span class="flex mt-12 justify-center">No records yet. Click 'Add items from Item List' button to manage Items.</span>
    </div>

  </ng-container>

</div>
