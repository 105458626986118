/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FullVariationQuote, VariationSection } from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getQuoteDocumentSection,
  getQuoteDocumentSectionFailure,
  getQuoteDocumentSectionSuccess,
  getVariationLetterQuote,
  getVariationLetterQuoteFailure,
  getVariationLetterQuoteSuccess,
  updateVariationLetterQuote,
  updateVariationLetterQuoteFailure,
  updateVariationLetterQuoteSuccess,
  uploadVariationLetterLogo,
  uploadVariationLetterLogoFailure,
  uploadVariationLetterLogoSuccess,
  updateQuoteDocumentSection,
  updateQuoteDocumentSectionFailure,
  updateQuoteDocumentSectionSuccess,
} from '..';
import { VariationLetterService } from '../../services/variation-letter.service';

@Injectable({
  providedIn: 'root',
})
export class VariationLetterQuoteEffects {
  getVariationLetterQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVariationLetterQuote),
      switchMap(({ variationLetterid }) =>
        this.variationLetterService
          .getVariationLetterQuote(variationLetterid)
          .pipe(
            map((variationLetterQuote: FullVariationQuote) =>
              getVariationLetterQuoteSuccess({ variationLetterQuote }),
            ),
            catchError((error) => {
              return of(getVariationLetterQuoteFailure({ error }));
            }),
          ),
      ),
    ),
  );

  updateVariationLetterQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateVariationLetterQuote),
      switchMap(({ variation }) =>
        this.variationLetterService.updateVariationLetter(variation).pipe(
          map((variationLetterQuote) =>
            updateVariationLetterQuoteSuccess({ variationLetterQuote }),
          ),
          catchError((error) => {
            return of(updateVariationLetterQuoteFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getVariationQuoteSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQuoteDocumentSection),
      switchMap(({ estimateId, variationId }) =>
        this.variationLetterService
          .quoteDocumentSection(estimateId, variationId)
          .pipe(
            map((sections: VariationSection[]) =>
              // TODO sort  out and fix
              // getQuoteDocumentSectionSuccess({ quoteDocumentSections: sections.sections })
              getQuoteDocumentSectionSuccess({
                quoteDocumentSections: sections,
              }),
            ),
            catchError((error) => {
              return of(getQuoteDocumentSectionFailure({ error }));
            }),
          ),
      ),
    ),
  );

  updateQuoteDocumentSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateQuoteDocumentSection),
      switchMap(({ quoteSections }) =>
        this.variationLetterService
          .updateQuoteDocumentSections(quoteSections)
          .pipe(
            map(
              (sectionsDetails: VariationSection[]) =>
                // TODO sort  out and fix
                updateQuoteDocumentSectionSuccess({
                  quoteDocumentSections: sectionsDetails,
                }),
              // updateQuoteDocumentSectionSuccess({ quoteDocumentSections: sectionsDetails.sections })
            ),
            catchError((error) => {
              return of(updateQuoteDocumentSectionFailure({ error }));
            }),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private variationLetterService: VariationLetterService,
  ) {}
}
