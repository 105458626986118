<div
  class="h-full flex flex-col max-w-3xl"
  [hidden]="jobOrderSummaryFacade.isLoading$ | async"
>
  <mat-expansion-panel
    class="w-full md:whitespace-nowrap"
    [expanded]="expanded"
    (opened)="expanded = true"
    (closed)="expanded = false"
    hideToggle
  >
    <mat-expansion-panel-header class="h-fit">
      <div class="w-full py-4 flex items-center">
        <div class="mr-5 flex">
          <mat-icon [ngClass]="{ 'rotate-180': expanded }">
            expand_less
          </mat-icon>
        </div>
        <div class="w-full flex justify-between items-center">
          <div>
            Summary ({{ jobOrderSummaryFacade.supplierCount$ | async }} supplier
            categories with
            {{ jobOrderSummaryFacade.itemsToOrderCount$ | async }} items to be
            ordered)
          </div>
          <div (click)="$event.stopPropagation()">
            <mat-form-field
              class="fuse-mat-no-subscript max-w-xs w-full small-select mr-4"
            >
              <mat-select [formControl]="supplier" panelClass="w-40">
                <mat-option [value]="0"> All Suppliers </mat-option>
                <mat-option
                  *ngFor="let s of suppliersOptions"
                  [value]="s.name"
                  matTooltip="{{ s?.name }}"
                >
                  {{ s?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="flex flex-col flex-auto overflow-hidden -mx-6">
      <div
        class="w-full max-h-80 px-6"
        [fuseScrollbarOptions]="{
          wheelPropagation: false,
          suppressScrollX: true
        }"
        fuseScrollbar
      >
        <table
          class="w-full hidden sm:table"
          [dataSource]="dataSource"
          mat-table
          matSort
        >
          <ng-container matColumnDef="supplier">
            <th class="pr-4" *matHeaderCellDef mat-header-cell mat-sort-header>
              Supplier
            </th>
            <td class="pr-4" *matCellDef="let element" mat-cell>
              {{ element.supplier }}
            </td>
          </ng-container>

          <ng-container matColumnDef="priceListCategoryName">
            <th class="pr-4" *matHeaderCellDef mat-header-cell mat-sort-header>
              Category
            </th>
            <td class="pr-4" *matCellDef="let element" mat-cell>
              {{ element.priceListCategoryName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="notOrdered">
            <th class="pr-4" *matHeaderCellDef mat-header-cell>Not Ordered</th>
            <td class="pr-4 text-center" *matCellDef="let element" mat-cell>
              {{ element.notOrdered }}
            </td>
          </ng-container>

          <ng-container matColumnDef="draft">
            <th class="pr-4" *matHeaderCellDef mat-header-cell>Draft</th>
            <td class="pr-4 text-center" *matCellDef="let element" mat-cell>
              {{ element.draft }}
            </td>
          </ng-container>

          <ng-container matColumnDef="awaitingDelivery">
            <th class="pr-4" *matHeaderCellDef mat-header-cell>
              Awaiting Delivery
            </th>
            <td class="pr-4 text-center" *matCellDef="let element" mat-cell>
              {{ element.awaitingDelivery }}
            </td>
          </ng-container>

          <ng-container matColumnDef="received">
            <th class="pr-4" *matHeaderCellDef mat-header-cell>Received</th>
            <td class="pr-4 text-center" *matCellDef="let element" mat-cell>
              {{ element.received }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="pr-4" *matHeaderCellDef mat-header-cell>Action</th>
            <td class="pr-0" *matCellDef="let element" mat-cell>
              <button
                class="text-xs rounded-xl min-h-8 h-8 px-4"
                [disabled]="!element.notOrdered || isReadOnlyMode"
                [color]="'primary'"
                [disabled]="isOrdered"
                (click)="onCreateOrder(element)"
                mat-stroked-button
              >
                <span class="ml-2 mr-1">Order</span>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              No items to order.
            </td>
          </tr>
        </table>
        <div class="sm:hidden">
          <fuse-card
            class="flex xs:flex-col justify-between sm:flex-row dark:bg-gray-900 flex-shrink-0 w-full p-4 mb-2"
            *ngFor="let supplier of dataSource?.data"
          >
            <div class="flex flex-col xs:w-full sm:w-1/3">
              <div class="text-md font-semibold leading-tight">
                Supplier: {{ supplier?.supplier }}
              </div>
              <div class="text-secondary font-medium text-md">
                Not Ordered: {{ supplier?.notOrdered }}
              </div>
              <div class="text-secondary font-medium text-md">
                Draft: {{ supplier?.draft }}
              </div>
              <div class="text-secondary font-medium text-md">
                Awaiting Delivery: {{ supplier.awaitingDelivery }}
              </div>
              <div class="text-secondary font-medium text-md">
                Received: {{ supplier.received }}
              </div>
            </div>
            <div
              class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto"
            >
              <button
                class="text-xs rounded-xl min-h-8 h-8 px-4"
                [color]="'primary'"
                [disabled]="isOrdered"
                (click)="onCreateOrder(supplier)"
                mat-stroked-button
              >
                <span class="ml-2 mr-1">Order</span>
              </button>
            </div>
          </fuse-card>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
