<!-- the new form -->

<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="flex flex-col sm:flex-row items-center p-8 pb-6 sm:pb-8">
    <!-- Icon -->
    <div
      class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600"
    >
      <mat-icon
        class="text-current"
        svgIcon="heroicons_outline:folder-plus"
      ></mat-icon>
    </div>

    <div class="text-center sm:text-left">
      <!-- Title -->
      <div
        class="text-xl leading-6 font-medium"
        [innerHTML]="'Add new folder'"
      ></div>
    </div>
  </div>

  <!-- Form -->
  <div
    class="flex flex-row items-center mb-3 gap-x-5 p-8"
    [formGroup]="editForm"
  >
    <mat-form-field class="w-full" floatLabel="always">
      <mat-label>Name</mat-label>
      <input
        matInput
        [autocomplete]="'off'"
        formControlName="name"
        trim="blur"
      />
      <mat-error *ngIf="required">Name required</mat-error>
      <mat-error *ngIf="folderExists">Folder exists</mat-error>
    </mat-form-field>
  </div>

  <!-- Action buttons -->
  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <!-- Cancel -->
    <button mat-stroked-button [matDialogClose]="undefined">Cancel</button>

    <!-- Save -->
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="!editForm.valid"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>
