import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';
import { RemovePriceListItemsFromJobVariationCostingDialogComponent } from '../../components';
import {
  showDialog,
  updateJobVariationSectionPriceListItems,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobVariationAddRemoveCostingEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dialog: MatDialog,
  ) {}

  jobVariationAddRemoveCostingShowDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showDialog),
      switchMap(() =>
        this.dialog
          .open(RemovePriceListItemsFromJobVariationCostingDialogComponent)
          .afterClosed()
          .pipe(
            filter((result) => !!result),
            map((data) => updateJobVariationSectionPriceListItems(data)),
          ),
      ),
    ),
  );
}
