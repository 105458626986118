/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createReducer, on } from '@ngrx/store';
import { PaginatedListOfEstimate } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  acceptEstimate,
  acceptEstimateFailure,
  acceptEstimateSuccess,
  cloneEstimate,
  cloneEstimateFailure,
  cloneEstimateSuccess,
  getEstimates,
  getEstimatesFailure,
  getEstimatesHistory,
  getEstimatesHistoryFailure,
  getEstimatesHistorySuccess,
  getEstimatesSuccess,
} from '../actions/estimate-list.actions';

export interface EstimateListState {
  estimates: PaginatedListOfEstimate;
  estimatesHistory: PaginatedListOfEstimate;
  callState: CallState;
  error: any;
}

export const initialState: EstimateListState = {
  estimates: <PaginatedListOfEstimate>{ items: [] },
  estimatesHistory: <PaginatedListOfEstimate>{ items: [] },
  callState: LoadingState.INIT,
  error: null,
};

export const estimateListReducer = createReducer(
  initialState,
  on(
    getEstimatesHistory,
    (state): EstimateListState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(
    getEstimatesHistoryFailure,
    (state, { error }): EstimateListState => ({
      ...state,
      callState: { error },
    }),
  ),
  on(
    getEstimatesHistorySuccess,
    (state, { estimates }): EstimateListState => ({
      ...state,
      estimatesHistory: estimates,
      callState: LoadingState.LOADED,
    }),
  ),
  on(
    getEstimates,
    (state): EstimateListState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(
    getEstimatesFailure,
    (state, { error }): EstimateListState => ({
      ...state,
      callState: { error },
    }),
  ),
  on(
    getEstimatesSuccess,
    (state, { estimates }): EstimateListState => ({
      ...state,
      estimates: estimates,
      callState: LoadingState.LOADED,
    }),
  ),
  // clone estimate
  on(
    cloneEstimate,
    (state): EstimateListState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(
    cloneEstimateFailure,
    (state, { error }): EstimateListState => ({
      ...state,
      callState: { error },
    }),
  ),
  on(
    cloneEstimateSuccess,
    (state, { estimate }): EstimateListState => ({
      ...state,
      callState: LoadingState.LOADED,
    }),
  ),
  // clone estimate
  on(
    acceptEstimate,
    (state): EstimateListState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(
    acceptEstimateFailure,
    (state, { error }): EstimateListState => ({
      ...state,
      callState: { error },
    }),
  ),
  on(
    acceptEstimateSuccess,
    (state, { estimate }): EstimateListState => ({
      ...state,
      callState: LoadingState.LOADED,
    }),
  ),
);
