export enum JobRoute {
  'info' = 'Info',
  'photos' = 'Photos',
  'notes' = 'Notes',
  'files' = 'Files',
  'estimates' = 'Estimate costings',
  'variations' = 'Costing & Variations',
  'call-forward' = 'Call Forward',
  'orders' = 'Orders',
  'invoices' = 'Invoices',
}
