<div class="px-6 w-full">
  <div [hidden]="estimateQuoteFacade.isLoading$ | async" class="w-full h-full">
    <div>
      <div class="flex flex-row justify-between mb-2.5">
        <div class="flex flex-row" *ngIf="!isReadOnlyMode">
          <button
            [disabled]="!leavePageService.needSave"
            (click)="cancel()"
            class="text-xs rounded-xl min-h-8 h-8 px-4 border-primary mr-4"
            mat-flat-button
            [color]="'accent'"
          >
            <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
            <span class="ml-2">Cancel</span>
          </button>
          <button
            [disabled]="!leavePageService.needSave"
            (click)="update()"
            class="text-xs rounded-xl min-h-8 h-8 px-4"
            mat-flat-button
            [color]="'primary'"
          >
            <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
            <span class="ml-2">Save</span>
          </button>
        </div>
        <button
          (click)="sendModal()"
          class="ml-auto text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
          mat-flat-button
          [color]="'primary'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
          <span class="ml-2">Send</span>
        </button>
      </div>
    </div>

    <hr class="mb-3 mt-0" />

    <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="mb-3">
      <a
        mat-tab-link
        *ngFor="let link of menuItems"
        [routerLink]="'./' + link.route"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ link.text }}
      </a>
    </nav>

    <div class="mb-5">
      <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>

      <div
        *ngIf="pdfData?.generatedFile"
        class="rounded-xl overflow-hidden"
        style="height: calc(100vh - 120px)"
      >
        <ngx-extended-pdf-viewer
          [filenameForDownload]="pdfData?.fileName"
          [base64Src]="pdfData?.generatedFile"
          useBrowserLocale="true"
          [zoom]="100"
          height="100%"
          [customToolbar]="customToolbar"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</div>

<ng-template #customToolbar>
  <div
    class="border-b pt-9 px-8 pb-3 flex justify-between flex-row items-center"
  >
    <div class="flex flex-row items-center">
      <button mat-icon-button id="zoomIn">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button mat-icon-button id="zoomOut">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
    <div class="flex flex-row items-center">
      <button
        class="text-xs rounded-xl min-h-8 h-8 px-4 mr-6"
        mat-stroked-button
      >
        <div
          (click)="refreshPdf()"
          class="w-full flex flex-row items-center text-secondary"
        >
          <mat-icon
            class="mr-2 text-hint"
            [svgIcon]="'heroicons_solid:arrow-path'"
          ></mat-icon>
          Refresh
        </div>
      </button>
      <button
        class="text-xs rounded-xl min-h-8 h-8 px-4"
        mat-stroked-button
        (click)="print()"
      >
        <div class="w-full flex flex-row items-center text-secondary">
          <mat-icon
            class="mr-2 text-hint"
            [svgIcon]="'heroicons_solid:printer'"
          ></mat-icon>
          Print
        </div>
      </button>
    </div>
  </div>
</ng-template>
