<div class="w-full max-w-3xl">
  <div class="container mx-auto">
    <div class="w-full max-w-3xl p-6">

      <div class="lg:ml-0 text-xl font-light tracking-normal leading-none">
        Subscription Payment
      </div>
      <div class="mt-5">

        <div *ngIf="elementsOptions.clientSecret">
          <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
            <ngx-stripe-payment [options]="paymentElementOptions" />
          </ngx-stripe-elements>

          <!-- divider -->
          <div class="mt-2 mb-2 border-t"></div>

          <!-- errors -->
          <div class="text-xs sm:text-base">
            {{ paymentMessage }}
          </div>

          <div class="flex items-center justify-end">
            <button class="ml-4" [color]="'primary'" mat-flat-button type="button" type="submit" [disabled]="disabled"
              (click)="pay()">
              Pay
            </button>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>