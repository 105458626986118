<div class="flex items-center space-x-2">
  <ng-container *ngFor="let crumb of crumbs">
    <div
      class="text-primary cursor-pointer"
      (click)="this.setCurrentFolderPath.emit(crumb.path)"
    >
      {{ crumb.name }}
    </div>
    <div>/</div>
  </ng-container>
  <div>{{ currentFolderName }}</div>
</div>
