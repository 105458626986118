import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobsOrderSummaryState = createSelector(
  selectJobState,
  (s: JobState) => s.jobOrdersSummary,
);

export const selectJobsOrderSummaryIsLoading = createSelector(
  selectJobsOrderSummaryState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobsOrderSummaryIsLoaded = createSelector(
  selectJobsOrderSummaryState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobsOrderSummaryError = createSelector(
  selectJobsOrderSummaryState,
  (s) => getError(s.callState),
);

export const selectJobsOrderSummary = createSelector(
  selectJobsOrderSummaryState,
  (s) => s.jobOrderSummary.summary,
);

export const selectJobsOrderSummaryItemsToOrderCount = createSelector(
  selectJobsOrderSummaryState,
  (s) => s.jobOrderSummary.itemsToOrderCount,
);

export const selectJobsOrderSummarySupplierCount = createSelector(
  selectJobsOrderSummaryState,
  (s) => s.jobOrderSummary.supplierCount,
);
