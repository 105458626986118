import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatCheckbox as MatCheckbox,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { EstimatePriceListUnitPriceUpdate } from 'app/shared/models';
import { finalize, forkJoin, Observable, skip, takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { PriceListService } from '../../services';
import { ItemPriceCheckFacade } from '../../store/facades/item-price-check.facade';
import { tap } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgFor, AsyncPipe, CurrencyPipe } from '@angular/common';
import { FuseScrollbarDirective } from '../../../../../@fuse/directives/scrollbar/scrollbar.directive';

@Component({
  selector: 'app-price-list-item-price-job',
  templateUrl: './price-list-item-price-job.component.html',
  styleUrls: ['./price-list-item-price-job.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    FuseScrollbarDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgFor,
    MatProgressSpinnerModule,
    MatButtonModule,
    AsyncPipe,
    CurrencyPipe,
  ],
})
export class PriceListItemPriceJobComponent implements OnInit, OnDestroy {
  private unsubscriber$ = new Subject<void>();
  isValid = false;
  form: FormGroup = this.fb.group({
    all: [false],
    items: this.fb.array([]),
  });

  get items() {
    return this.form.get('items') as FormArray;
  }

  get all() {
    return this.form.get('all') as FormControl;
  }

  constructor(
    private dialogRef: MatDialogRef<PriceListItemPriceJobComponent>,
    public fb: FormBuilder,
    public itemPriceCheckFacade: ItemPriceCheckFacade,
    private priceListService: PriceListService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      itemName: string;
      itemPrice: number;
      priceListItemId: string;
    },
  ) {}

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.itemPriceCheckFacade.clear();
  }

  ngOnInit(): void {
    this.itemPriceCheckFacade.priceListItemPriceChangeJob$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((res) => {
        let counterObj = {};
        res.forEach((element, index) => {
          if (!counterObj[index]) {
            counterObj[index] = element;
          }
        });

        Object.keys(counterObj).forEach((k) => {
          const control: FormGroup = this.fb.group({
            checked: [false],
            code: counterObj[k].code,
            jobId: counterObj[k].jobId,
          });
          this.items.push(control);
        });
      });
  }

  checkValidity() {
    this.isValid = this.items.value.some((v) => v.checked);
  }

  close() {
    this.dialogRef.close();
  }

  changeAll(event: MatCheckbox) {
    this.items.controls.forEach((control) => {
      control.get('checked').setValue(event.checked);
    });
    this.checkValidity();
  }

  change() {
    this.all.setValue(!this.items.value.some((v) => !v.checked));
    this.checkValidity();
  }

  update() {
    const observables: Observable<any>[] = [];

    this.items.value.forEach((item) => {
      if (item.checked) {
        const params: EstimatePriceListUnitPriceUpdate = {
          priceListItemUnitPrice: this.data.itemPrice,
          priceListItemId: this.data.priceListItemId,
          jobId: item.jobId,
        };

        observables.push(this.priceListService.updateUnitPrice(params));
      }
    });
    if (observables.length) {
      forkJoin(observables).subscribe(() => {
        this.close();
      });
    }
  }
}
