import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { FileUploadStatus } from 'app/shared/models';
import { FileUploadFacade } from 'app/shared/store/facades';
import { Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-file-upload-progress',
  templateUrl: './file-upload-progress.component.html',
  styleUrls: ['./file-upload-progress.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgFor,
    NgIf,
    MatProgressBarModule,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class FileUploadProgressComponent implements OnInit, OnDestroy {
  constructor(
    private facade: FileUploadFacade,
    private dialogRef: MatDialogRef<FileUploadProgressComponent>,
  ) {}

  private unsubscriber$ = new Subject<void>();

  files$ = this.facade.files$;
  uploadComplete$ = this.facade.uploadComplete$;

  fileUploadStatus = FileUploadStatus;

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
