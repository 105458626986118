import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getJobsOrderSummary } from '../actions';
import { JobListState } from '../reducers/job-list.reducer';
import {
  selectJobsOrderSummary,
  selectJobsOrderSummaryError,
  selectJobsOrderSummaryIsLoaded,
  selectJobsOrderSummaryIsLoading,
  selectJobsOrderSummaryItemsToOrderCount,
  selectJobsOrderSummarySupplierCount,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobOrderSummaryFacade {
  isLoading$ = this.store.select(selectJobsOrderSummaryIsLoading);
  isLoaded$ = this.store.select(selectJobsOrderSummaryIsLoaded);
  error$ = this.store.select(selectJobsOrderSummaryError);
  jobOrderSummary$ = this.store.select(selectJobsOrderSummary);
  itemsToOrderCount$ = this.store.select(
    selectJobsOrderSummaryItemsToOrderCount,
  );
  supplierCount$ = this.store.select(selectJobsOrderSummarySupplierCount);

  constructor(private store: Store<JobListState>) {}

  getJobsOrderSummary(id: any) {
    this.store.dispatch(getJobsOrderSummary({ id }));
  }
}
