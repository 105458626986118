<div>
  <h2 mat-dialog-title class="text-sm sm:text-base text-center">
    Confirm extra seat payment
  </h2>
  <mat-dialog-content>
    <div>
      <p>Extra seat cost: {{ changes.extraSeatPrice }}</p>
      <p>New subscription cost: {{ changes.subscriptionTotal }}</p>
      <br />
      <p>Amount due next payment: {{changes.total}} (may include prorated charges)</p>
      <p>Next billing date: {{changes.dueDate}}</p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button mat-dialog-close="Confirm" class="bg-primary">
      Buy Now
    </button>
  </mat-dialog-actions>
</div>