import { BillingSettingsFacade } from './billing-settings.facade';
import { TeamSettingsFacade } from './team-settings.facade';
import { GeneralSettingsFacade } from './general-settings.facade';

export const facades = [
  BillingSettingsFacade,
  TeamSettingsFacade,
  GeneralSettingsFacade,
];

export * from './billing-settings.facade';
export * from './team-settings.facade';
export * from './general-settings.facade';
