import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobsOrderListState = createSelector(
  selectJobState,
  (s: JobState) => s.jobOrders,
);

export const selectJobsOrderListIsLoading = createSelector(
  selectJobsOrderListState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobsOrderListIsLoaded = createSelector(
  selectJobsOrderListState,
  (s) => {
    console.log('selectJobsOrderListIsLoaded selectJobsOrderListState:::', s);
    return s.callState === LoadingState.LOADED;
  },
);

export const selectJobsOrderListError = createSelector(
  selectJobsOrderListState,
  (s) => getError(s.callState),
);

export const selectJobsOrderList = createSelector(
  selectJobsOrderListState,
  (s) => s.jobOrders.items,
);

export const selectJobsOrderListTotalCount = createSelector(
  selectJobsOrderListState,
  (s) => s.jobOrders.pageMetaData?.records,
);

export const selectJobsOrderListPageNumber = createSelector(
  selectJobsOrderListState,
  (s) => s.jobOrders.pageMetaData?.pageNumber,
);
