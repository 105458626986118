import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupingArrayByValue',
  standalone: true,
})
export class GroupingArrayByValuePipe implements PipeTransform {
  transform(value: any[], key: string): any[] {
    let newArr = [];
    if (value) {
      let counterObj = {};
      value.forEach((element) => {
        if (!counterObj[element[key]]) {
          counterObj[element[key]] = {
            item: element,
            counter: 1,
          };
        } else {
          counterObj[element[key]].counter++;
        }
      });
      Object.keys(counterObj).forEach((k) => {
        newArr.push(counterObj[k]);
      });
    }
    return newArr;
  }
}
