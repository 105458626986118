import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getBillingSettings } from '../actions';
import { BillingSettingsState } from '../reducers/billing-settings.reducer';
import { selectBillingSettings } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class BillingSettingsFacade {
  settings$ = this.store.select(selectBillingSettings);

  constructor(private store: Store<BillingSettingsState>) {}

  getSettings() {
    this.store.dispatch(getBillingSettings());
  }
}
