<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden">
  <div class="flex-auto h-full px-6 py-4 overflow-y-scroll">
    <!--    Page title -->
    <div class="flex flex-row sm:flex-nowrap xs:flex-wrap items-center">
      <button
        (click)="back()"
        class="sm:mr-3 xs:mr-auto px-2 min-w-3 min-h-3 md:w-9 md:h-9 sm:w-6 sm:h-6 rounded"
        mat-flat-button
        [color]="'primary'"
        [matTooltip]="backNavigationService.tooltip || 'Back to invoice list'"
      >
        <mat-icon [svgIcon]="'heroicons_outline:arrow-left'"></mat-icon>
      </button>
      <div
        class="xs:text-l md:text-3xl sm:text-xl xs:font-medium mr-6 sm:font-extrabold tracking-tight leading-none"
      >
        Job #{{ invoice?.job?.jobCode }} - {{ invoice?.job?.jobTitle }}
      </div>
    </div>

    <div
      *ngIf="jobInvoiceTotals; else loadIndicator"
      class="invoice-summary flex flex-row gap-x-3 p-2 mb-4 rounded mt-6 border border-gray-500"
      style="width: 45rem"
    >
      <div class="flex flex-col w-1/5">
        <div class="">Net quote</div>
        <div
          class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
        >
          {{ jobInvoiceTotals?.netAmount | currency }}
        </div>
      </div>
      <div class="flex flex-col w-1/5">
        <div class="">GST</div>
        <div
          class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
        >
          {{ jobInvoiceTotals?.gstAmount | currency }}
        </div>
      </div>
      <div class="flex flex-col w-1/5">
        <div class="">Customer quote</div>
        <div
          class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
        >
          {{ jobInvoiceTotals?.customerQuote | currency }}
        </div>
      </div>
      <div class="flex flex-col w-1/5">
        <div class="">Invoiced</div>
        <div
          class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
        >
          {{ jobInvoiceTotals?.invoiced | currency }}
        </div>
      </div>
      <div class="flex flex-col w-1/5">
        <div class="">Payment Received</div>
        <div
          class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
        >
          {{ jobInvoiceTotals?.paymentReceived | currency }}
        </div>
      </div>
    </div>

    <div class="flex flex-row mt-6">
      <div
        class="xs:text-l md:text-3xl sm:text-xl xs:font-medium mr-6 sm:font-extrabold tracking-tight leading-none"
      >
        Invoice
      </div>
      <div>
        <app-invoice-status-badge
          [status]="invoice?.status"
        ></app-invoice-status-badge>
        <div
          *ngIf="invoice?.sent"
          class="ml-4 inline-block py-0.5 px-3 rounded text-sm font-semibold text-white bg-amber-500"
        >
          SENT
        </div>
      </div>
    </div>

    <!--    Actions  bar -->
    <div class="flex flex-row mt-6 pb-2 border-b">
      <ng-container *ngIf="!isReadOnlyMode">
        <button
          (click)="cancel()"
          [disabled]="!hasChanges"
          *ngIf="ableToEdit.saveInvoice"
          class="text-xs rounded-xl min-h-8 h-8 px-4 border-primary mr-4"
          mat-flat-button
          [color]="'accent'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
          <span class="ml-2">Cancel</span>
        </button>
        <button
          *ngIf="ableToEdit.saveInvoice"
          [disabled]="
            !hasChanges || form.invalid || isEditing || !invoice?.invoicedAmount
          "
          (click)="onSave()"
          class="text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
          mat-flat-button
          [color]="'primary'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
          <span class="ml-2">Save</span>
        </button>
        <button
          class="text-xs rounded-xl min-h-8 h-8 px-4"
          *ngIf="syncEnabled"
          [disabled]="syncButtonDisabled"
          [color]="'primary'"
          (click)="sync()"
          mat-flat-button
        >
          <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
          <span class="ml-2">Sync</span>
        </button>
        <a
          class="text-xs rounded-xl min-h-8 h-8 px-4 ml-2"
          mat-flat-button
          [href]="xeroDeepLink"
          target="_blank"
          *ngIf="xeroDeepLink && syncEnabled"
          >View in Xero</a
        >
      </ng-container>

      <div class="flex flex-row ml-auto">
        <button
          *ngIf="!isReadOnlyMode && mode !== pageMode.NEW"
          [matMenuTriggerFor]="statusMenu"
          class="px-4 text-xs rounded-xl min-h-8 h-8"
          mat-fab
          extended
          color="primary"
        >
          <span class="mr-2">{{ invoiceStatus }}</span>
          <mat-icon
            class="icon-size-3"
            [svgIcon]="'heroicons_outline:chevron-down'"
          ></mat-icon>
        </button>
        <mat-menu #statusMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let option of paidOptions"
            (click)="onPaidOptionSelected(option)"
          >
            {{ option.text }}
          </button>
        </mat-menu>

        <button
          *ngIf="mode !== pageMode.NEW"
          (click)="onPreview()"
          class="xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
          mat-stroked-button
        >
          <mat-icon
            class="color-white dark:color-default"
            [svgIcon]="'heroicons_outline:eye'"
          ></mat-icon>
          <span class="xs:hidden sm:block ml-2 mr-1">Preview</span>
        </button>
        <button
          *ngIf="mode !== pageMode.NEW"
          (click)="downloadPdf()"
          class="xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4"
          mat-stroked-button
        >
          <mat-icon
            class="color-white dark:color-default"
            [svgIcon]="'heroicons_outline:printer'"
          ></mat-icon>
          <span class="xs:hidden sm:block ml-2 mr-1">Print</span>
        </button>
        <!--      <button *ngIf="mode !== pageMode.NEW"-->
        <!--              (click)="onPrint()"-->
        <!--              class="xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4" mat-stroked-button>-->
        <!--        <mat-icon [svgIcon]="'heroicons_outline:printer'"></mat-icon>-->
        <!--        <span class="xs:hidden sm:block ml-2 mr-1">Print</span>-->
        <!--      </button>-->

        <button
          (click)="onSend()"
          *ngIf="mode !== pageMode.NEW"
          class="xs:ml-2 sm:ml-4 text-xs rounded-xl min-h-8 h-8 px-4 mr-4"
          mat-flat-button
          [disabled]="
            form?.invalid ||
            isEditing ||
            !invoice?.invoicedAmount ||
            isReadOnlyMode
          "
          [color]="'primary'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:paper-airplane'"></mat-icon>
          <span class="ml-2">Send</span>
        </button>
        <!--        <button *ngIf="!isReadOnlyMode" class="text-xs rounded-xl min-h-8 h-8 px-4" [color]="'primary'" (click)="sync()" mat-flat-button>-->
        <!--          <mat-icon [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>-->
        <!--          <span class="ml-2">Sync</span>-->
        <!--        </button>-->
        <button
          (click)="onDelete()"
          *ngIf="!isReadOnlyMode && mode !== pageMode.NEW"
          class="text-xs rounded-xl min-h-8 h-8 px-4 border-primary"
          mat-flat-button
          [color]="'accent'"
        >
          <mat-icon [svgIcon]="'heroicons_outline:trash'"></mat-icon>
          <span class="ml-2">Delete</span>
        </button>
      </div>
    </div>

    <div
      class="invoice-header py-4 flex flex-col justify-evenly"
      [formGroup]="form"
    >
      <div class="flex flex-row">
        <!--  Customer  -->
        <!--        <mat-form-field-->
        <!--          class="w-50"-->
        <!--          matAutocompleteOrigin-->
        <!--          #origin="matAutocompleteOrigin"-->
        <!--        >-->
        <!--          <mat-label>Customer</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            placeholder="Type to search a customer by name"-->
        <!--            formControlName="to"-->
        <!--            [matAutocomplete]="auto"-->
        <!--            [matAutocompleteConnectedTo]="origin"-->
        <!--          />-->
        <!--          <mat-error *ngIf="form.get('to')?.hasError('required')">-->
        <!--            This field is required-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
        <!--        <mat-autocomplete #auto="matAutocomplete">-->
        <!--          <mat-option-->
        <!--            *ngFor="let customer of customers"-->
        <!--            [value]="customer.person.fullName"-->
        <!--            (onSelectionChange)="onCustomerChange(customer)"-->
        <!--          >-->
        <!--            {{ customer.person.fullName }}-->
        <!--            <span class="ml-2 text-gray-600">{{-->
        <!--              customer.address.streetAddress-->
        <!--            }}</span>-->
        <!--          </mat-option>-->
        <!--        </mat-autocomplete>-->

        <!--        <mat-form-field class="w-50">-->
        <!--          <mat-label>From</mat-label>-->
        <!--          <input formControlName="from" matInput placeholder="Type here..." />-->
        <!--          <mat-error *ngIf="form.get('from').hasError('required')">-->
        <!--            This field is required-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <mat-form-field class="pr-4 w-1/2">
          <mat-label>Description</mat-label>

          <input
            [readonly]="isReadOnlyMode"
            matInput
            formControlName="description"
            trim="blur"
            maxlength="100"
            placeholder="Type here..."
          />
        </mat-form-field>

        <mat-form-field class="w-1/2">
          <mat-label>Reference</mat-label>
          <input
            [readonly]="isReadOnlyMode"
            matInput
            formControlName="externalInvoiceCode"
            maxlength="100"
          />
          <mat-error
            *ngIf="
              form.touched &&
              form.controls['externalInvoiceCode'].hasError('maxlength')
            "
          >
            Has to be lower 100 chars
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row">
        <mat-form-field appearance="fill" class="w-1/4 pr-4">
          <mat-label>Invoice #</mat-label>
          <input
            [readonly]="isReadOnlyMode"
            matInput
            formControlName="invoiceCode"
            maxlength="32"
          />
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          class="w-1/4 pr-4"
          [class.pointer-events-none]="isReadOnlyMode"
        >
          <mat-label>Date Issued</mat-label>
          <input
            matInput
            [matDatepicker]="issuedDate"
            formControlName="invoiceDate"
            [readonly]="isReadOnlyMode"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="issuedDate"
            [disabled]="isReadOnlyMode"
          ></mat-datepicker-toggle>
          <mat-datepicker #issuedDate></mat-datepicker>
          <mat-error
            *ngIf="
              form.touched &&
              form.controls['invoiceDate'].hasError('incorrectDate')
            "
          >
            Has to be lower than Due date
          </mat-error>
        </mat-form-field>
        <!--        <mat-form-field appearance="fill" class="w-50">-->
        <!--          <mat-label>Due Date</mat-label>-->
        <!--          <input-->
        <!--            matInput-->
        <!--            [matDatepicker]="dueDate"-->
        <!--            formControlName="paymentDueDate"-->
        <!--          />-->
        <!--          <mat-datepicker-toggle-->
        <!--            matSuffix-->
        <!--            [for]="dueDate"-->
        <!--          ></mat-datepicker-toggle>-->
        <!--          <mat-datepicker #dueDate></mat-datepicker>-->
        <!--        </mat-form-field>-->

        <mat-form-field
          class="w-1/4 pr-4"
          [class.pointer-events-none]="isReadOnlyMode"
        >
          <div class="h-0 w-0 overflow-hidden">
            <button
              [disabled]="!ableToEdit.invoiceHeader || isReadOnlyMode"
              mat-button
              [matMenuTriggerFor]="menu"
              #callForDateMenuTrigger="matMenuTrigger"
            >
              Menu
            </button>
          </div>
          <mat-label>Due date</mat-label>
          <input
            [readonly]="!ableToEdit.invoiceHeader || isReadOnlyMode"
            formControlName="paymentDueDate"
            (dateChange)="formatDate('paymentDueDate')"
            (focus)="
              ableToEdit.invoiceHeader
                ? callForDateMenuTrigger.openMenu()
                : null
            "
            matInput
            [matDatepicker]="calledFor"
          />
          <mat-icon
            matSuffix
            class="button-icon"
            [svgIcon]="'heroicons_outline:chevron-down'"
          >
          </mat-icon>
          <mat-datepicker #calledFor></mat-datepicker>
        </mat-form-field>

        <mat-menu class="-m-4" #menu="matMenu">
          <button
            (click)="callForDateUpdate(item.value)"
            *ngFor="let item of calledForOptions"
            mat-menu-item
          >
            {{ item.name }}
          </button>
          <button mat-menu-item (click)="calledFor.open()">
            Pick another date
          </button>
        </mat-menu>

        <div
          class="flex w-1/4 justify-center self-center"
          *ngIf="!isReadOnlyMode"
        >
          <mat-checkbox
            formControlName="sent"
            (change)="onSendOptionSelected($event.checked)"
            [color]="'primary'"
            >Sent</mat-checkbox
          >
        </div>
      </div>

      <!-- intro section     -->
      <div class="intro-section w-full mb-4">
        <div
          class="intro-wrapper p-2 rounded border border-dashed border-gray-300"
        >
          <div
            class="mb-6"
            [innerHTML]="
              invoice?.intro || 'Optionally enter invoice introduction'
            "
          ></div>
          <button
            *ngIf="!isReadOnlyMode"
            [disabled]="!ableToEdit.invoiceHeader"
            (click)="onEditIntro()"
            class="p-0 text-xs min-h-8 h-8"
            mat-button
            color="primary"
          >
            <span class="xs:hidden sm:block">Click to edit</span>
          </button>
        </div>
      </div>

      <ng-template #content>
        <button
          *ngIf="!isReadOnlyMode && ableToEdit.invoiceLines"
          [matMenuTriggerFor]="newInvoiceLineMenu"
          class="mb-1 text-xs rounded-xl min-h-8 h-8 px-4"
          mat-stroked-button
        >
          <span class="ml-2">Add a new</span>
          <mat-icon
            class="icon-size-3"
            [svgIcon]="'heroicons_outline:chevron-down'"
          ></mat-icon>
        </button>
      </ng-template>

      <div class="invoice-section mb-4">
        <app-inline-editing-grid
          *ngIf="invoiceTable"
          #invoiceTableRef
          [content]="content"
          [canEdit]="!isReadOnlyMode"
          [dataSource]="invoiceTable?.dataSource"
          [displayedColumns]="invoiceTable?.displayedColumns"
          [form]="invoiceTable?.form"
          [itemForm]="invoiceTable?.itemForm"
          [mappedColumns]="invoiceTable?.mappedColumns"
          [canAddNewRow]="invoiceTable?.canAddNewRow"
          [applyChangesImmediately]="true"
          (editing)="onEditRow($event)"
          (update)="onUpdateInvoiceGrid()"
        >
        </app-inline-editing-grid>

        <mat-menu #newInvoiceLineMenu="matMenu">
          <button mat-menu-item (click)="onAddInvoiceLine('percentage')">
            Percentage of total
          </button>
          <button mat-menu-item (click)="onAddInvoiceLine('fixed')">
            Fixed amount
          </button>
          <button mat-menu-item (click)="onAddInvoiceLine('remaining')">
            Remaining amount
          </button>
          <button mat-menu-item (click)="onAddInvoiceLine('credit')">
            Credit amount
          </button>
        </mat-menu>

        <div class="invoice-details mt-6 w-60 ml-auto">
          <div
            class="invoice-details_item border-b-2 flex flex-row justify-end items-end leading-8"
          >
            <div class="text-secondary text-md w-24 text-right">Subtotal</div>
            <div class="item-amount w-36 text-xl text-right tracking-wider">
              {{ invoicePrice | currency }}
            </div>
          </div>
          <div
            class="invoice-details_item border-b-2 flex flex-row justify-end items-end leading-8"
          >
            <div class="text-secondary text-md w-24 text-right">GST</div>
            <div class="item-amount w-36 text-xl text-right tracking-wider">
              {{ gst | currency }}
            </div>
          </div>
          <div
            class="invoice-details_item border-b-2 flex flex-row justify-end items-end leading-8"
          >
            <div class="text-secondary text-md w-24 text-right">Amount due</div>
            <div class="item-amount w-36 text-xl text-right tracking-wider">
              {{ invoice?.invoicedAmount | currency }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="ableToEdit.terms" class="footer-section w-full mb-4">
        <div
          class="footer-wrapper p-2 rounded border border-dashed border-gray-300"
        >
          <div class="mb-6" [innerHTML]="invoice?.footer"></div>
          <button
            *ngIf="!isReadOnlyMode"
            (click)="onEditFooter()"
            class="p-0 text-xs min-h-8 h-8"
            mat-button
            color="primary"
          >
            <span class="xs:hidden sm:block">Click to edit</span>
          </button>
        </div>
      </div>

      <div class="payment-section md:w-3/4">
        <div
          *ngIf="!ableToEdit.terms"
          class="payment-terms mb-6"
          [innerHTML]="invoice?.footer"
        ></div>

        <app-inline-editing-grid
          *ngIf="paymentTable"
          [dataSource]="paymentTable?.dataSource"
          [canEdit]="!isReadOnlyMode"
          [displayedColumns]="paymentTable?.displayedColumns"
          [form]="paymentTable?.form"
          [itemForm]="paymentTable?.itemForm"
          [mappedColumns]="paymentTable?.mappedColumns"
          [canAddNewRow]="
            !isReadOnlyMode && paymentTable?.canAddNewRowToPaymentGrid
          "
          [addNewRowButtonsText]="'Add payment'"
          [applyChangesImmediately]="true"
          (editing)="isEditing = $event?.price ? true : false"
          (update)="pageChanges.paymentTable = true"
        ></app-inline-editing-grid>
      </div>
    </div>
  </div>
</div>

<ng-template #loadIndicator>
  <mat-spinner [diameter]="45"></mat-spinner>
</ng-template>
