import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FileUploadProgressComponent } from 'app/shared/components';
import { FileUpload } from 'app/shared/models';
import { FileUploadService } from 'app/shared/services';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { serializeError } from 'serialize-error';
import {
  fileUploadCompleted,
  fileUploadFailure,
  fileUploadFileRequest,
  fileUploadProgress,
  fileUploadRequest,
  fileUploadStarted,
} from '../actions/file-upload.actions';

@Injectable({
  providedIn: 'root',
})
export class FileUploadEffects {
  constructor(
    private actions$: Actions,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog,
  ) {}

  fileUploadRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileUploadRequest),
      tap(() =>
        this.dialog.open(FileUploadProgressComponent, {
          //Dont let them close the dialog
          disableClose: true,
          panelClass: ['w-120'],
        }),
      ),
      concatMap(({ files }) =>
        files.map((file, index) => fileUploadFileRequest({ file, index })),
      ),
    ),
  );

  fileUploadFileRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileUploadFileRequest),
      mergeMap(({ index, file }) =>
        this.fileUploadService.upload(file).pipe(
          map((event) => this.getActionFromHttpEvent(index, file, event)),
          catchError((event: any) => {
            console.log(event);
            return of(fileUploadFailure({ index, error: event.statusText }));
          }),
        ),
      ),
    ),
  );

  private getActionFromHttpEvent(
    index: number,
    file: FileUpload,
    event: HttpEvent<any>,
  ) {
    switch (event.type) {
      case HttpEventType.Sent: {
        return fileUploadStarted({ index });
      }
      case HttpEventType.UploadProgress: {
        return fileUploadProgress({
          index,
          loaded: event.loaded,
        });
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          return fileUploadCompleted({ index, entity: event.body, file });
        } else {
          return fileUploadFailure({
            index,
            error: event.statusText,
          });
        }
      }
      default: {
        return fileUploadFailure({
          index,
          error: JSON.stringify(event),
        });
      }
    }
  }

  private handleError(index: number, error: any) {
    const friendlyErrorMessage = serializeError(error).message;
    return fileUploadFailure({
      index,
      error: friendlyErrorMessage,
    });
  }
}
