import { createReducer, on } from '@ngrx/store';
import { Job, PaginatedListOfJob } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  clearJobs,
  getJobs,
  getJobsFailure,
  getJobsSuccess,
  getRecentJobs,
  getRecentJobsFailure,
  getRecentJobsSuccess,
} from '../actions';

export interface JobListState {
  jobs: PaginatedListOfJob;
  callState: CallState;
  error: any;
  recentJobs: Job[];
}

export const initialState: JobListState = {
  jobs: <PaginatedListOfJob>{ items: [] },
  callState: LoadingState.INIT,
  error: null,
  recentJobs: [],
};

export const jobListReducer = createReducer(
  initialState,
  on(getJobs, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobsSuccess, (state, { jobs }) => ({
    ...state,
    jobs,
    callState: LoadingState.LOADED,
  })),

  // recent jobs
  on(getRecentJobs, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getRecentJobsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getRecentJobsSuccess, (state, { recentJobs }) => ({
    ...state,
    recentJobs,
    callState: LoadingState.LOADED,
  })),

  on(clearJobs, (state) => ({
    ...state,
    jobs: { items: [] },
  })),
);
