export const MappedRoutes = {
  dashboard: 'Dashboard',
  'call-forwards': 'CallForward',
  estimates: 'Estimates',
  jobs: 'Jobs',
  'jobs/.*/orders': 'Ordering',
  'jobs/.*/invoices': 'Invoicing',
  customers: 'Customers',
  'item-list': 'ItemList',
  suppliers: 'Suppliers',
  templates: 'Templates',
  library: 'Library',
  'settings/account': 'SettingsCompanyInfo',
  'settings/billing': 'SettingsBilling',
  'settings/profile': 'SettingsProfileInformation',
  'settings/uom': 'SettingsUnitsOfMeasure',
  'settings/team': 'SettingsTeamMembers',
  'integrations/xero': 'SettingsIntegrations',
  'integrations/quickbooks': 'SettingsIntegrations',
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const SettingsIds = [
  'SettingsCompanyInfo',
  'SettingsBilling',
  'SettingsProfileInformation',
  'SettingsUnitsOfMeasure',
  'SettingsTeamMembers',
  'SettingsIntegrations',
];
