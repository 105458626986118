/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Estimate,
  FullQuote,
  PaginatedListOfEstimate,
} from 'app/shared/models';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  finalize,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  getEstimateQuote,
  getEstimateQuoteFailure,
  getEstimateQuoteSuccess,
  getQuoteDocumentSection,
  getQuoteDocumentSectionSuccess,
  updateEstimateQuote,
  updateEstimateQuoteFailure,
  updateEstimateQuoteSuccess,
  updateQuoteDocumentSection,
  updateQuoteDocumentSectionFailure,
  updateQuoteDocumentSectionSuccess,
} from '..';
import { EstimateService } from '../../services/estimate.service';

@Injectable({
  providedIn: 'root',
})
export class EstimateQuoteEffects {
  getEstimateQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEstimateQuote),
      switchMap(({ estimateid }) =>
        this.estimateService.getEstimateQuote(estimateid).pipe(
          map((estimateQuote: FullQuote) =>
            getEstimateQuoteSuccess({ estimateQuote }),
          ),
          catchError((error) => {
            return of(getEstimateQuoteFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getQuoteDocumentSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQuoteDocumentSection),
      switchMap(({ estimateid }) =>
        this.estimateService.quoteDocumentSection(estimateid).pipe(
          map((sectionsDetails: any) =>
            getQuoteDocumentSectionSuccess({
              quoteDocumentSections: sectionsDetails.sections,
            }),
          ),
          catchError((error) => {
            return of(getQuoteDocumentSectionFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateQuoteDocumentSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateQuoteDocumentSection),
      switchMap(({ quoteSections }) =>
        this.estimateService.updateQuoteDocumentSections(quoteSections).pipe(
          map((sectionsDetails: any) =>
            updateQuoteDocumentSectionSuccess({
              quoteDocumentSections: sectionsDetails.sections,
            }),
          ),
          catchError((error) => {
            return of(updateQuoteDocumentSectionFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateEstimateQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEstimateQuote),
      switchMap(({ fullQuote }) => {
        return this.estimateService.updateEstimate(fullQuote.estimate).pipe(
          map((r) => updateEstimateQuoteSuccess({ estimateQuote: r as any })),
          catchError((error) => {
            return of(updateEstimateQuoteFailure({ error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private estimateService: EstimateService,
  ) {}
}
function getQuoteDocumentSectionFailure(arg0: { error: any }): any {
  throw new Error('Function not implemented.');
}
