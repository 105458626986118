import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentRequiredComponent } from './payment-required/payment-required.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import { routes } from './payment-required.routing';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    FuseCardComponent,
    SharedModule,
    PaymentRequiredComponent,
  ],
  exports: [],
})
export class PaymentRequiredModule {}
