<div class="absolute inset-0 flex flex-col min-w-0">
  <div class="flex-auto h-full bg-card dark:bg-transparent">
    <div class="flex flex-col h-full bg-gray-100 dark:bg-transparent">
      <div class="flex flex-col p-6 py-0">
        <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="mb-3">
          <a
            mat-tab-link
            *ngFor="let link of menuItems$ | async"
            [routerLink]="link.route"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
          >
            {{ link.text }}
          </a>
        </nav>
      </div>
      <mat-tab-nav-panel class="h-full mt-3" #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
</div>
