/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { UserRole } from 'app/shared/models';
import { SettingsPanels } from 'app/shared/models/consts.models';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsRoleRedrectGuard {
  private roleNames: string[] = [];
  private roles: UserRole[] = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private facade: AccountUserFacade,
  ) {
    this.facade.activeAccount$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((ac) => {
        this.roles = ac.roles;
        // get all the role names that related to settings menu and remove all undefined roles
        this.roleNames = ac.roles
          .map((r) => {
            if (r.name.includes('Settings')) {
              return r.name;
            }
          })
          .filter((r) => r !== undefined);
      });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (
      this.roles.length === 1 &&
      this.roles[0].name.toLocaleLowerCase() === 'owner'
    ) {
      return true;
    }

    // get all the panels that matching user assigned settings role
    const filteredPanels = SettingsPanels.filter((panel) =>
      this.roleNames.includes(panel.roleId),
    );

    // if navigated route matchin filtered panel with the same route,  let user in
    if (filteredPanels.some((p) => p.route.includes(route.url[0].path))) {
      return true;
    } else {
      if (this.roleNames.length > 0) {
        const routeToNavigateTo = SettingsPanels.filter(
          (p) => p.roleId === this.roleNames[0],
        )[0];
        const navPath = `/settings/${routeToNavigateTo.route}`;
        this.router.navigate([navPath]);
      }
      return false;
    }
  }
}
