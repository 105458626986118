import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import {
  Router,
  RouterLinkActive,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { SettingsPanels } from 'app/shared/models/consts.models';
import { AccountUserFacade } from 'app/shared/store/facades';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-settings-container',
  templateUrl: './settings-container.component.html',
  styleUrls: ['./settings-container.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    NgFor,
    RouterLinkActive,
    RouterLink,
    NgIf,
    RouterOutlet,
  ],
})
export class SettingsContainerComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer;
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  panels: any[] = [];
  selectedPanel = '';
  private unsubscriber$ = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    public facade: AccountUserFacade,
    private async: AsyncPipe,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private fuseMediaWatcherService: FuseMediaWatcherService,
  ) {
    // Setup available panels
    this.panels = SettingsPanels;

    const roles = this.async.transform(this.facade.activeAccount$).roles;
    // Owner account has only one role an Owner, if more than one role then not an owner
    // and need to filter panels based on settings roles selected for account user
    if (roles.length > 1) {
      const rolesNames = this.async
        .transform(this.facade.activeAccount$)
        .roles.map((r) => {
          if (r.name.includes('Settings')) {
            return r.name;
          }
        })
        .filter((p) => !!p);

      const filteredPanels = this.panels.filter((x) =>
        rolesNames.includes(x.roleId),
      );

      this.panels = filteredPanels;
    }

    this.selectedPanel = this.panels[0].route;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to media changes
    this.fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(({ matchingAliases }) => {
        // Set the drawerMode and drawerOpened
        if (matchingAliases.includes('lg')) {
          this.drawerMode = 'side';
          this.drawerOpened = true;
        } else {
          this.drawerMode = 'over';
          this.drawerOpened = false;
        }

        // Mark for check
        this.changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Navigate to the panel
   *
   * @param panel
   */
  goToPanel(panel: string): void {
    // Close the drawer on 'over' mode
    if (this.drawerMode === 'over') {
      this.drawer.close();
    }

    this.selectedPanel = panel;
  }

  /**
   * Get the details of the panel
   *
   * @param id
   */
  getPanelInfo(id: string): any {
    return this.panels.find((panel: any) => panel.id === id);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
