import { createReducer, on } from '@ngrx/store';
import { BaseIdNameDesc } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  getSuppliersListByJob,
  getSuppliersListByJobFailure,
  getSuppliersListByJobSuccess,
  getUseCategories,
  getUseCategoriesFailure,
  getUseCategoriesSuccess,
} from '..';

export interface SuppliersListByJobDetailState {
  suppliersDesc: BaseIdNameDesc[];
  callState: CallState;
  error: any;
  useCategories: {
    canEdit: boolean;
    purchaseOrderAtCategoryLevel?: boolean;
  };
}

export const initialState: SuppliersListByJobDetailState = {
  suppliersDesc: null,
  callState: LoadingState.INIT,
  error: null,
  useCategories: {
    canEdit: false,
    purchaseOrderAtCategoryLevel: false,
  },
};

export const estimatePriceListSupplierDescReducer = createReducer(
  initialState,
  on(getSuppliersListByJob, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getSuppliersListByJobFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getSuppliersListByJobSuccess, (state, { suppliersDesc }) => ({
    ...state,
    suppliersDesc,
    callState: LoadingState.LOADED,
  })),
  on(getUseCategories, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getUseCategoriesFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getUseCategoriesSuccess, (state, { useCategoriesState }) => ({
    ...state,
    useCategories: useCategoriesState,
    callState: LoadingState.LOADED,
  })),
);
