export const SettingsPanels = [
  {
    id: 'account',
    icon: 'heroicons_outline:document-text',
    title: 'Company Information',
    header: 'The following information is displayed on your quote',
    description: 'Manage your company information',
    route: 'account',
    roleId: 'SettingsCompanyInfo',
  },
  {
    id: 'billing',
    icon: 'heroicons_outline:credit-card',
    title: 'Billing',
    header: '',
    description:
      'Manage your subscription plan, payment method and billing information',
    route: 'billing',
    roleId: 'SettingsBilling',
  },
  {
    id: 'profile',
    icon: 'heroicons_outline:user-circle',
    title: 'Profile Information',
    header: undefined,
    description: 'Manage your user profile and contact information',
    route: 'profile',
    roleId: 'SettingsProfileInformation',
  },
  {
    id: 'uom',
    icon: 'heroicons_outline:list-bullet',
    title: 'Units of measures',
    header: undefined,
    description: 'Manage your units of measures',
    route: 'uom',
    roleId: 'SettingsUnitsOfMeasure',
  },
  {
    id: 'integrations',
    icon: 'mat_outline:cable',
    title: 'Integrations',
    header: undefined,
    description: 'Manage integrations with Xero, MYOB, QuickBooks',
    route: 'integrations',
    roleId: 'SettingsIntegrations',
  },
  {
    id: 'integration-summary',
    icon: 'mat_outline:cable',
    title: 'Integration Summary',
    header: undefined,
    description: 'Summary Integration',
    route: 'integration-summary',
    roleId: 'SettingsIntegrationSummary',
  },
];
