import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { jobOrderDeleteFacade, getJobsOrderList } from '../actions';
import { JobListState } from '../reducers/job-list.reducer';
import {
  selectJobsOrderList,
  selectJobsOrderListError,
  selectJobsOrderListIsLoaded,
  selectJobsOrderListIsLoading,
  selectJobsOrderListPageNumber,
  selectJobsOrderListState,
  selectJobsOrderListTotalCount,
} from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class JobOrdersListFacade {
  state$ = this.store.select(selectJobsOrderListState);
  isLoading$ = this.store.select(selectJobsOrderListIsLoading);
  isLoaded$ = this.store.select(selectJobsOrderListIsLoaded);
  error$ = this.store.select(selectJobsOrderListError);
  jobOrders$ = this.store.select(selectJobsOrderList);
  totalCount$ = this.store.select(selectJobsOrderListTotalCount);
  pageIndex$ = this.store.select(selectJobsOrderListPageNumber);

  constructor(private store: Store<JobListState>) {}

  getJobsOrderList(request: any) {
    this.store.dispatch(getJobsOrderList({ request }));
  }

  deleteJobsOrder(request: any) {
    this.store.dispatch(jobOrderDeleteFacade({ request }));
  }
}
