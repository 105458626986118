import { createReducer, on } from '@ngrx/store';
import { BillingSettings } from 'app/shared/models';
import {
  getBillingSettings,
  getBillingSettingsFailure,
  getBillingSettingsSuccess,
} from '../actions';

export interface BillingSettingsState {
  settings: BillingSettings;
  isLoading: boolean;
}

export const initialState: BillingSettingsState = {
  settings: null,
  isLoading: false,
};

export const billingSettingsReducer = createReducer(
  initialState,
  on(getBillingSettings, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(getBillingSettingsSuccess, (state, { settings }) => ({
    ...state,
    settings,
    isLoading: false,
  })),
  on(getBillingSettingsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
);
