<div class="rounded-md overflow-hidden">
  <google-map
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    [height]="height"
    width="100%"
  >
    <map-marker
      #markerElem
      *ngFor="let marker of markers"
      [position]="marker.position"
      [label]="marker.label"
      [title]="marker.title"
      [options]="marker.options"
    >
    </map-marker
  ></google-map>
</div>
