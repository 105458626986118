<form class="" [formGroup]="form">
  <!--  <div class="my-4 text-lg font-medium leading-6">Orders</div>-->
  <!--  <div class="mb-4">-->
  <!--    <mat-slide-toggle-->
  <!--      color='primary'-->
  <!--      labelPosition='before'-->
  <!--      [formControlName]="'export'">-->
  <!--      <span class="mr-4">Export purchase orders to Xero</span>-->
  <!--    </mat-slide-toggle>-->
  <!--  </div>-->
  <!--  <div class="mb-4">-->
  <!--    <mat-form-field class="w-1/2">-->
  <!--      <mat-label>Purchase order status</mat-label>-->
  <!--      <mat-select class="" formControlName="purchaseOrderStatus">-->
  <!--        <mat-option-->
  <!--          *ngFor="let option of (purchaseOrderStatuses$ | async)"-->
  <!--          [value]="option.id"-->
  <!--          class="">-->
  <!--          {{ option.name }}-->
  <!--        </mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->
  <div class="flex flex-row mb-4">
    <!-- <mat-form-field class="w-1/2 md:w-1/3 mr-4">
      <mat-label>Xero invoice status</mat-label>
      <mat-select class="" formControlName="invoiceStatus">
        <mat-option
          *ngFor="let option of (invoiceStatuses$ | async)"
          [value]="option.id"
          class="">
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field class="w-1/2 md:w-1/3">
      <mat-label>Payments Synchronization</mat-label>
      <mat-select class="" formControlName="paymentSychronizationType">
        <mat-option
          *ngFor="let option of paymentStatuses$ | async"
          [value]="option.id"
          class=""
        >
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="my-4 text-lg font-medium leading-6">Account Mapping</div>
  <div class="flex flex-row mb-4">
    <mat-form-field class="w-3/6 md:w-1/3 mr-4">
      <mat-label>GST Account Name</mat-label>
      <input matInput type="text" formControlName="gstAccountName" />
      <!--      <mat-select class="" formControlName="salesAccount">-->
      <!--        <mat-option-->
      <!--          *ngFor="let option of (salesAccountList | async)"-->
      <!--          [value]="option.id"-->
      <!--          class="">-->
      <!--          {{ option.name }}-->
      <!--        </mat-option>-->
      <!--      </mat-select>-->
    </mat-form-field>
    <mat-form-field class="w-3/6 md:w-1/3">
      <mat-label>Default Sale Item Income Account Name</mat-label>
      <input
        matInput
        type="text"
        formControlName="defaultSaleItemIncomeAccountName"
      />
      <!--      <mat-select class="" formControlName="bankAccount">-->
      <!--        <mat-option-->
      <!--          *ngFor="let option of (bankAccountList | async)"-->
      <!--          [value]="option.id"-->
      <!--          class="">-->
      <!--          {{ option.name }}-->
      <!--        </mat-option>-->
      <!--      </mat-select>-->
    </mat-form-field>
  </div>

  <!--    Actions  bar -->
  <div class="flex flex-row my-6">
    <button
      class="rounded-4xl px-8 mr-4"
      mat-stroked-button
      type="button"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      class="rounded-4xl px-8"
      mat-flat-button
      [color]="'primary'"
      (click)="save()"
      [disabled]="!form.dirty"
    >
      Save
    </button>
  </div>
</form>
