import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EstimateParameters } from 'app/shared/models';
import {
  acceptEstimate,
  cloneEstimate,
  getEstimates,
  selectEstimateList,
  selectEstimateListError,
  selectEstimateListIsLoaded,
  selectEstimateListIsLoading,
  selectEstimateListPageNumber,
  selectEstimateListTotalCount,
} from '..';
import { getEstimatesHistory } from '../actions';
import { EstimateListState } from '../reducers/estimate-list.reducer';
import { selectEstimatesHistoryList } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class EstimateListFacade {
  isLoading$ = this.store.select(selectEstimateListIsLoading);
  isLoaded$ = this.store.select(selectEstimateListIsLoaded);
  error$ = this.store.select(selectEstimateListError);
  estimates$ = this.store.select(selectEstimateList);
  totalCount$ = this.store.select(selectEstimateListTotalCount);
  pageIndex$ = this.store.select(selectEstimateListPageNumber);
  estimatesHistory$ = this.store.select(selectEstimatesHistoryList);

  constructor(private store: Store) {}

  getEstimatesHistory(request: EstimateParameters): void {
    this.store.dispatch(getEstimatesHistory({ request }));
  }
  getEstimates(request: EstimateParameters): void {
    this.store.dispatch(getEstimates({ request }));
  }
  cloneEstimate(id: string): void {
    this.store.dispatch(cloneEstimate({ id }));
  }
  acceptEstimate(id: string): void {
    this.store.dispatch(acceptEstimate({ id }));
  }
}
