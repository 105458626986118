<div class="text-4xl font-bold tracking-tight leading-none border-b pb-3 mb-6">
  Create template
</div>
<div class="mb-4">
  Create a template by saving complete set of items from this estimate.
</div>
<form [formGroup]="templateForm" (ngSubmit)="save()" novalidate>
  <mat-form-field class="w-full">
    <mat-label>Template name</mat-label>
    <input
      trim="blur"
      [formControlName]="'name'"
      matInput
      placeholder="Type a template name"
      maxlength="254"
    />
    <mat-error *ngIf="templateForm.get('name').hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  <!--  Description  -->
  <mat-form-field class="fuse-mat-no-subscript fuse-mat-textarea w-full">
    <mat-label>Description</mat-label>
    <textarea
      [formControlName]="'description'"
      matInput
      placeholder="Enter a description (optional)"
      maxlength="1024"
    ></textarea>
  </mat-form-field>

  <div class="flex flex-row justify-end mt-8">
    <button
      class="rounded-4xl px-8 mr-4"
      mat-stroked-button
      type="button"
      (click)="cancel()"
    >
      Cancel
    </button>
    <button
      class="rounded-4xl px-8"
      type="submit"
      mat-flat-button
      [color]="'primary'"
      [disabled]="!templateForm.valid"
    >
      Create
    </button>
  </div>
</form>
