<div [formGroup]="form">
  <div class="grid sm:grid-cols-10 gap-x-3 w-full">
    <ng-container formGroupName="person">
      <div class="sm:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>Title</mat-label>
          <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_outline:user'"
          ></mat-icon>
          <input
            trim="blur"
            formControlName="title"
            matInput
            [autocomplete]="'off'"
          />
        </mat-form-field>
      </div>
      <div class="sm:col-span-4">
        <mat-form-field class="w-full">
          <mat-label>First name</mat-label>
          <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_outline:user'"
          ></mat-icon>
          <input
            formControlName="firstName"
            maxlength="30"
            matInput
            [autocomplete]="'off'"
          />
          <mat-error *ngIf="personForm.get('firstName').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-4">
        <mat-form-field class="w-full">
          <mat-label>Last name</mat-label>
          <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_outline:user'"
          ></mat-icon>
          <input
            formControlName="lastName"
            maxlength="64"
            matInput
            [autocomplete]="'off'"
          />
          <mat-error *ngIf="personForm.get('lastName').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <div class="sm:col-span-5">
      <mat-form-field class="w-full">
        <mat-label>Business name (optional)</mat-label>
        <mat-icon
          class="icon-size-5"
          matPrefix
          [svgIcon]="'heroicons_outline:building-office'"
        >
        </mat-icon>
        <input formControlName="businessName" matInput [autocomplete]="'off'" />
      </mat-form-field>
    </div>
    <div class="sm:col-span-5">
      <mat-form-field class="w-full">
        <mat-label>ABN (optional)</mat-label>
        <mat-icon
          class="icon-size-5"
          matPrefix
          [svgIcon]="'heroicons_outline:document'"
        ></mat-icon>
        <input
          trim="blur"
          [inputMask]="abnInputMask"
          formControlName="abn"
          matInput
          [autocomplete]="'off'"
        />
        <mat-error *ngIf="form.get('abn').hasError('inputMask')">
          Invalid ABN
        </mat-error>
      </mat-form-field>
    </div>
    <div class="sm:col-span-10">
      <mat-form-field class="w-full" floatLabel="always">
        <mat-label>Address lookup</mat-label>
        <mat-icon
          class="icon-size-5"
          [svgIcon]="'heroicons_solid:home'"
          matPrefix
        ></mat-icon>
        <input
          matInput
          addressLookup
          [value]="formattedAddress"
          (addressSelected)="addressSelected($event)"
          placeholder="Type here to search for an address"
        />
      </mat-form-field>
    </div>
    <ng-container formGroupName="address">
      <div class="sm:col-span-10">
        <mat-form-field class="w-full">
          <mat-label>Address</mat-label>
          <input
            type="text"
            matInput
            [autocomplete]="'off'"
            formControlName="streetAddress"
          />
          <mat-error
            *ngIf="addressForm.get('streetAddress').hasError('required')"
          >
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-6">
        <mat-form-field class="w-full">
          <mat-label>Suburb</mat-label>
          <input
            type="text"
            matInput
            [autocomplete]="'off'"
            formControlName="suburb"
          />
          <mat-error *ngIf="addressForm.get('suburb').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state">
              {{ state }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addressForm.get('postCode').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-2">
        <mat-form-field class="w-full">
          <mat-label>Postcode</mat-label>
          <input
            trim="blur"
            type="text"
            matInput
            [autocomplete]="'off'"
            formControlName="postCode"
            maxlength="4"
          />
          <mat-error *ngIf="addressForm.get('postCode').hasError('pattern')">
            Invalid Postcode
          </mat-error>
          <mat-error *ngIf="addressForm.get('postCode').hasError('required')">
            This field is required
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <ng-container formGroupName="person">
      <div class="sm:col-span-10">
        <mat-form-field class="w-full">
          <mat-label>Email address</mat-label>
          <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_outline:envelope'"
          ></mat-icon>
          <input
            trim="blur"
            formControlName="email"
            maxlength="128"
            matInput
            [autocomplete]="'off'"
          />
          <mat-error *ngIf="personForm.get('email').hasError('email')">
            Invalid Email
          </mat-error>
        </mat-form-field>
      </div>
      <div class="sm:col-span-10">
        <label>Phone number (optional)</label>
        <mat-form-field class="w-full">
          <mat-icon
            class="icon-size-5"
            matPrefix
            [svgIcon]="'heroicons_outline:phone'"
          >
          </mat-icon>
          <input
            trim="blur"
            formControlName="phone"
            type="tel"
            placeholder="e.g. 0733331111"
            [pattern]="phonePattern"
            maxlength="10"
            matInput
            [autocomplete]="'off'"
          />
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <div class="flex flex-row" *ngIf="displayActionButtons">
    <button (click)="close()" class="fuse-mat-button-large" mat-stroked-button>
      <span class="ml-2 mr-1">Cancel</span>
    </button>
    <button
      (click)="save()"
      class="ml-4 fuse-mat-button-large"
      mat-flat-button
      [color]="'primary'"
    >
      <span class="ml-2 mr-1">Save</span>
    </button>
  </div>
</div>
