import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { jobDetailReducer, JobDetailState } from './job-detail.reducer';
import { jobInvoicesReducer, JobInvoicesState } from './job-invoices.reducer';
import { jobListReducer, JobListState } from './job-list.reducer';
import { jobNotesReducer, JobNotesState } from './job-notes.reducer';
import {
  jobOrdersDetailsReducer,
  JobOrdersDetailsState,
} from './job-order-details.reducer';
import {
  jobOrderItemsToOrderReducer,
  JobOrderItemsToOrderState,
} from './job-order-items-to-order.reducer';
import {
  jobOrdersListReducer,
  JobOrdersListState,
} from './job-order-list.reducer';
import {
  jobOrderPriceListItemReducer,
  JobOrderPriceListItemState,
} from './job-order-price-list-item.reducer';
import {
  jobOrderSummaryReducer,
  JobOrderSummaryState,
} from './job-order-summary.reducer';
import {
  jobVariationAddRemoveCostingReducer,
  JobVariationAddRemoveCostingState,
} from './job-variation-add-remove-costing.reducer';
import {
  jobVariationDetailReducer,
  JobVariationDetailState,
} from './job-variation-detail.reducer';
import {
  variationLetterQuoteReducer,
  VariationLetterQuoteState,
} from './job-variation-letter.reducer';
import {
  jobVariationsListReducer,
  JobVariationsListState,
} from './job-variations-list.reducer';
import {
  estimatePriceListSupplierDescReducer,
  SuppliersListByJobDetailState,
} from './suppliers-list-by-job.reducer';
import { JobCostingReducer, JobCostingState } from './job-costing.reducer';
import { JobHistoryReducer, JobHistoryState } from './job-history.reducer';

export interface JobState {
  jobs: JobListState;
  jobCosting: JobCostingState;
  jobHistory: JobHistoryState;
  jobOrdersSummary: JobOrderSummaryState;
  jobOrderItemsToOrderReducer: JobOrderItemsToOrderState;
  jobOrders: JobOrdersListState;
  jobOrder: JobOrdersDetailsState;
  jobOrderPriceListItem: JobOrderPriceListItemState;
  job: JobDetailState;
  notes: JobNotesState;
  invoices: JobInvoicesState;
  suppliersDesc: SuppliersListByJobDetailState;
  variations: JobVariationsListState;
  variation: JobVariationDetailState;
  variationLetter: VariationLetterQuoteState;
  variationAddRemoveCosting: JobVariationAddRemoveCostingState;
}

export const reducers: ActionReducerMap<JobState> = {
  jobs: jobListReducer,
  jobCosting: JobCostingReducer,
  jobHistory: JobHistoryReducer,
  jobOrdersSummary: jobOrderSummaryReducer,
  jobOrderItemsToOrderReducer: jobOrderItemsToOrderReducer,
  jobOrders: jobOrdersListReducer,
  jobOrder: jobOrdersDetailsReducer,
  jobOrderPriceListItem: jobOrderPriceListItemReducer,
  job: jobDetailReducer,
  notes: jobNotesReducer,
  invoices: jobInvoicesReducer,
  suppliersDesc: estimatePriceListSupplierDescReducer,
  variations: jobVariationsListReducer,
  variation: jobVariationDetailReducer,
  variationLetter: variationLetterQuoteReducer,
  variationAddRemoveCosting: jobVariationAddRemoveCostingReducer,
};

export const selectJobState = createFeatureSelector<JobState>('job');
