import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Customer,
  PaginatedListOfCustomer,
  CustomerParameters,
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomers(
    request: CustomerParameters,
  ): Observable<PaginatedListOfCustomer> {
    const url = `${environment.urls.api}/customer/list`;
    return this.http.post<PaginatedListOfCustomer>(url, request);
  }

  getCustomer(id: string): Observable<Customer> {
    const url = `${environment.urls.api}/customer/${id}`;
    return this.http.get<Customer>(url);
  }

  addCustomer(customer: Customer): Observable<Customer> {
    const url = `${environment.urls.api}/customer/add`;
    return this.http.post<Customer>(url, customer);
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    const url = `${environment.urls.api}/customer/save`;
    return this.http.put<Customer>(url, customer);
  }

  deleteCustomer(id: string): Observable<string> {
    const url = `${environment.urls.api}/customer/${id}`;
    return this.http.delete<string>(url);
  }
}
