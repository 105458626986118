/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
import { OnDestroy } from '@angular/core';
import {
  UnitOfMeasure,
  AddUnitOfMeasure,
} from 'app/shared/models/eazimate.models';
import { Component, OnInit } from '@angular/core';
import { GeneralSettingsFacade } from 'app/views/settings/store/facades/general-settings.facade';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountUserFacade } from 'app/shared/store/facades';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-uom-management',
  templateUrl: './uom-management.component.html',
  styleUrls: ['./uom-management.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    AsyncPipe,
  ],
})
export class UomManagementComponent implements OnInit, OnDestroy {
  unsubscriber$ = new Subject<void>();

  dataSource = new MatTableDataSource<any>();

  isEditableNew = true;
  form: FormGroup;
  displayedColumns: string[] = ['uom', 'action'];

  originalUom: UnitOfMeasure[] = [];

  isReadOnly =
    this.async
      .transform(this.accountUserFacade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingsunitsofmeasure' &&
          r.accessLevel === 0,
      ).length === 1;

  constructor(
    private fb: FormBuilder,
    public facade: GeneralSettingsFacade,
    public accountUserFacade: AccountUserFacade,
    private async: AsyncPipe,
  ) {}

  clickInput($event, element, i): void {
    $event.stopPropagation();
    element.get('uom').setErrors(null);
    element.markAsPristine();
  }
  add(): void {
    const control = this.form.get('rows') as FormArray;
    control.insert(0, this.intiAddItem());
    this.dataSource = new MatTableDataSource(control.controls);
  }

  edit(el, i): void {
    el.get('rows').at(i).get('isEditable').patchValue(false);
  }

  save(el, i): void {
    const tmp = el.get('rows').at(i).value;
    el.get('rows').at(i).get('isEditable').patchValue(true);
    if (tmp.isNew) {
      const item: AddUnitOfMeasure = {
        uom: tmp.uom,
      };
      this.facade.addUnitOfMeasure(item);
    } else {
      this.facade.updateUnitOfMeasure(tmp);
    }
  }

  cancel(el, i): void {
    const currnetValue = el.get('rows').at(i).value;
    const originalValue = this.originalUom[i];
    if (currnetValue.isNew) {
      const control = this.form.get('rows') as FormArray;
      control.removeAt(i);
      this.dataSource = new MatTableDataSource(control.controls);
    } else {
      el.get('rows').at(i).get('uom').patchValue(originalValue.uom);
      el.get('rows').at(i).get('isEditable').patchValue(true);
      el.get('rows').at(i).markAsPristine(originalValue.uom);
      el.get('rows').at(i).get('uom').setErrors(null);
    }
  }

  delete(el, i): void {
    const tmp = el.get('rows').at(i).value;
    this.facade.deleteUnitOfMeasure(tmp.id);
  }

  intiAddItem(): FormGroup {
    return this.fb.group({
      uom: new FormControl('', [
        Validators.required,
        this.noWhitespaceValidator,
        this.checkUomExistsValidator.bind(this),
      ]),
      deleted: new FormControl(false),
      isEditable: new FormControl(false),
      isNew: new FormControl(true),
    });
  }

  checkUomExistsValidator(control: FormControl): any {
    let value = control.value ?? '';
    if (typeof value === 'object') {
      value = value.uom;
    }
    const matchedUoms = this.originalUom.filter((x) => {
      return x.uom === value;
    });
    return matchedUoms.length === 0 ? null : { uomExists: true };
  }

  noWhitespaceValidator(control: FormControl): any {
    let value = control.value ?? '';
    if (typeof value === 'object') {
      value = value.uom;
    }
    const isWhitespace = value.trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  ngOnInit(): void {
    this.facade.unitsOfMeasures$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((unitsOfMeasure: UnitOfMeasure[]) => {
        if (unitsOfMeasure) {
          this.originalUom = unitsOfMeasure;
          this.form = this.fb.group({
            rows: this.fb.array(
              unitsOfMeasure.map((x) =>
                this.fb.group({
                  id: new FormControl(x.id),
                  accountId: new FormControl(x.accountId),
                  uom: new FormControl(x.uom, [
                    Validators.required,
                    this.noWhitespaceValidator,
                    this.checkUomExistsValidator.bind(this),
                  ]),
                  created: new FormControl(x.created),
                  createdBy: new FormControl(x.createdBy),
                  deleted: new FormControl(x.deleted),
                  updated: new FormControl(x.updated),
                  updatedBy: new FormControl(x.updatedBy),
                  isEditable: new FormControl(true),
                  isNew: new FormControl(false),
                }),
              ),
            ),
          });
          this.dataSource = new MatTableDataSource(
            (this.form.get('rows') as FormArray).controls,
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
