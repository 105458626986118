import { createReducer, on } from '@ngrx/store';
import { FullEstimate, Job, JobCostingPriceListItem } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  getAcceptedEstimate,
  getAcceptedEstimateFailure,
  getAcceptedEstimateSuccess,
  getJob,
  getJobFailure,
  getJobSuccess,
  getOriginalitems,
  getOriginalitemsFailure,
  getOriginalitemsSuccess,
  updateJob,
  updateJobFailure,
  updateJobStatus,
  updateJobStatusFailure,
  updateJobStatusSuccess,
  updateJobSuccess,
} from '../actions';

export interface JobDetailState {
  job: Job;
  currentNoteId: string;
  acceptedEstimate: FullEstimate;
  callState: CallState;
  error: any;
  originalItems: JobCostingPriceListItem[];
}

export const initialState: JobDetailState = {
  job: null,
  currentNoteId: null,
  acceptedEstimate: null,
  callState: LoadingState.INIT,
  error: null,
  originalItems: null,
};

export const jobDetailReducer = createReducer(
  initialState,
  on(getJob, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getJobSuccess, (state, { job }) => ({
    ...state,
    job,
    callState: LoadingState.LOADED,
  })),
  on(getAcceptedEstimate, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getAcceptedEstimateFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getAcceptedEstimateSuccess, (state, { estimate }) => ({
    ...state,
    acceptedEstimate: estimate, //
    callState: LoadingState.LOADED,
  })),
  on(getOriginalitems, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getOriginalitemsFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getOriginalitemsSuccess, (state, { items }) => ({
    ...state,
    originalItems: items,
    callState: LoadingState.LOADED,
  })),
  on(updateJob, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateJobSuccess, (state, { job }) => ({
    ...state,
    job,
    callState: LoadingState.LOADED,
  })),

  on(updateJobStatus, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateJobStatusFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateJobStatusSuccess, (state) => ({
    ...state,
    callState: LoadingState.LOADED,
  })),
);
