import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JobOrder } from 'app/shared/models';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../../services/job.service';
import {
  getJobOrderItemsToOrder,
  getJobOrderItemsToOrderFailure,
  getJobOrderItemsToOrderSuccess,
} from '../actions';

@Injectable({
  providedIn: 'root',
})
export class JobOrderItemsToOrderEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobOrderItemsToOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobOrderItemsToOrder),
      switchMap(({ query }) =>
        this.jobService.joboOrderItemsToOrder(query).pipe(
          map((jobOrderItemsToOrder: any) =>
            getJobOrderItemsToOrderSuccess({ jobOrderItemsToOrder }),
          ),
          catchError((error) => {
            return of(getJobOrderItemsToOrderFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
