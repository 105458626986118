import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EstimateStatus, JobStatus } from 'app/shared/models';
import { NgFor, NgClass } from '@angular/common';

const VALID_STATUSES_TRANSITIONS = {
  // ESTIMATE
  [JobStatus.Estimate_Draft]: [
    JobStatus.Estimate_Rejected,
    JobStatus.Estimate_Withdrawn,
    JobStatus.Estimate_Accepted,
    JobStatus.Estimate_Quoted,
  ],
  [JobStatus.Estimate_Quoted]: [
    JobStatus.Estimate_Rejected,
    JobStatus.Estimate_Withdrawn,
    JobStatus.Estimate_Accepted,
  ],
  [JobStatus.Estimate_Rejected]: [
    JobStatus.Estimate_Draft,
    JobStatus.Estimate_Quoted,
  ],
  [JobStatus.Estimate_Withdrawn]: [JobStatus.Estimate_Draft],
  [JobStatus.Estimate_Accepted]: [],
  // JOB
  [JobStatus.Job_Pre_Start]: [
    JobStatus.Job_In_Progress,
    JobStatus.Job_On_Hold,
    JobStatus.Job_Cancelled,
  ],
  [JobStatus.Job_In_Progress]: [
    JobStatus.Job_Completed,
    JobStatus.Job_On_Hold,
    JobStatus.Job_Cancelled,
  ],
  [JobStatus.Job_On_Hold]: [JobStatus.Job_In_Progress],
  [JobStatus.Job_Completed]: [JobStatus.Job_In_Progress, JobStatus.Job_On_Hold],
  [JobStatus.Job_Cancelled]: [JobStatus.Job_In_Progress],
};

const VALID_STATUSES_TRANSITIONS_ESTIMATE = {
  [EstimateStatus.Draft]: [EstimateStatus.Rejected, EstimateStatus.Withdrawn],
  [EstimateStatus.Quoted]: [EstimateStatus.Rejected, EstimateStatus.Withdrawn],
  [EstimateStatus.Rejected]: [EstimateStatus.Withdrawn, EstimateStatus.Quoted],
  [EstimateStatus.Withdrawn]: [EstimateStatus.Rejected, EstimateStatus.Quoted],
  // [EstimateStatus.Accepted]: []
};
const VALID_JOBCOSTING_STATUSES_TRANSITIONS = {
  [JobStatus.Job_Pre_Start]: [JobStatus.Job_On_Hold, JobStatus.Job_Cancelled],
  [JobStatus.Job_In_Progress]: [
    JobStatus.Job_Pre_Start,
    JobStatus.Job_Completed,
    JobStatus.Job_On_Hold,
    JobStatus.Job_Cancelled,
  ],
  [JobStatus.Job_On_Hold]: [
    JobStatus.Job_Pre_Start,
    JobStatus.Job_In_Progress,
    JobStatus.Job_Cancelled,
  ],
  [JobStatus.Job_Completed]: [],
  [JobStatus.Job_Cancelled]: [
    JobStatus.Job_Pre_Start,
    JobStatus.Job_In_Progress,
    JobStatus.Job_On_Hold,
  ],
};

interface Statuses {
  name: string;
  value: JobStatus;
  validForTransactions?: boolean;
}

enum Type {
  Job = 'job',
  Estimate = 'estimate',
  JobCosting = 'jobCosting',
}

@Component({
  selector: 'app-status-toggle',
  templateUrl: './status-toggle.component.html',
  styleUrls: ['./status-toggle.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass],
})
export class StatusToggleComponent implements OnInit, OnChanges {
  @Input() statuses: Statuses[];
  // JobStatus || EstimateStatus
  @Input() selected: number;
  @Input() isEstimate = false;
  @Input() disable? = null;
  @Input() type: Type = Type.Job;
  @Output() select = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateValidStatuses();
  }

  ngOnInit(): void {
    this.updateValidStatuses();
  }

  updateValidStatuses(): void {
    this.statuses = this.statuses.map((s) => {
      if (this.type === Type.Job) {
        s.validForTransactions = VALID_STATUSES_TRANSITIONS[this.selected].some(
          (validStatus) => validStatus === s.value,
        );
      }
      if (this.type === Type.Estimate) {
        s.validForTransactions = VALID_STATUSES_TRANSITIONS_ESTIMATE[
          this.selected
        ].some((validStatus) => validStatus === s.value);
      }
      if (this.type === Type.JobCosting) {
        s.validForTransactions = VALID_JOBCOSTING_STATUSES_TRANSITIONS[
          this.selected
        ].some((validStatus) => validStatus === s.value);
      }
      return s;
    });
  }

  selectStatus(status: Statuses) {
    if (this.selected !== status.value && status.validForTransactions) {
      if(this.disable !== status.value){
        this.select.emit(status.value);
      }
    }
  }
}
