<div class="xs:text-3xl sm:text-4xl font-extrabold tracking-tight leading-none border-b pb-3 mb-2">
  {{ this.data?.priceListItemId ? "Edit" : "Add" }} Price List Item
</div>
<form [formGroup]="form" (ngSubmit)="(save)">
  <mat-dialog-content>
    <div class="flex flex-row">
      <div class="w-80">
        <mat-form-field class="w-full">
          <mat-label>Description</mat-label>
          <input trim="blur" formControlName="description" maxlength="1024" matInput />
        </mat-form-field>
      </div>
      <div class="ml-4 flex-auto">
        <mat-form-field *ngIf="!isVariation" class="w-full">
          <mat-label>Preferred Supplier</mat-label>
          <mat-select
            formControlName="supplier"
            [compareWith]="compareSupplier"
            required
          >
            <mat-option
              [value]="supplier"
              *ngFor="let supplier of (facade.supplierList$ | async).items"
            >
              {{ supplier.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkRequired('supplier')"
            >Supplier required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-row" *ngIf="isVariation">
        <mat-form-field>
          <mat-label>Item #</mat-label>
          <input
            trim="blur"
            numbersOnly
            decimal="false"
            formControlName="variationItemCode"
            maxlength="50"
            matInput
          />
        </mat-form-field>
        <mat-form-field class="w-64 ml-4">
          <mat-label>Comment</mat-label>
          <input trim="blur" formControlName="variationComment" maxlength="1024" matInput />
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row">
      <mat-form-field>
        <mat-label>Qty</mat-label>
        <input
          trim="blur"
          numbersOnly
          decimal="true"
          formControlName="qty"
          maxlength=""
          matInput
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>UOM</mat-label>
        <mat-select panelClass="w-36" formControlName="unitOfMeasure" required>
          <mat-option
            [value]="uom.uom"
            *ngFor="let uom of generalFacade.unitsOfMeasures$ | async"
          >
            {{ uom.uom }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Unit price</mat-label>
        <input
          [inputMask]="currencyInputMask"
          trim="blur"
          formControlName="unitPrice"
          matInput
        />
      </mat-form-field>
      <mat-form-field class="cursor-pointer">
        <mat-label>Unit total</mat-label>
        <input
          class="cursor-pointer"
          [inputMask]="currencyInputMask"
          trim="blur"
          formControlName="unitTotal"
          matInput
          readonly
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>On cost %</mat-label>
        <input
          [inputMask]="percentInputMask"
          trim="blur"
          formControlName="onCost"
          matInput
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>GST rate</mat-label>
        <mat-select formControlName="gstRate">
          <mat-option [value]="0">0%</mat-option>
          <mat-option [value]="10">10%</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="w-full flex flex-row justify-end">
    <button [disabled]="form.invalid || form.pristine"  type="submit" mat-flat-button [color]="'primary'" (click)="save()">
      Save
    </button>
    <button mat-flat-button [color]="'accent'" (click)="close()">Cancel</button>
    </div>
  </mat-dialog-actions>
</form>
