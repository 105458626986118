<div class="mb-2.5" *ngIf="form">
  <div class="h-full flex flex-col items-stretch">
    <div
      class=" rounded-lg overflow-hidden flex flex-col items-stretch h-full"
    >
      <div *ngIf="content" class="table-search-wrap rounded-none">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <div
        class="table-search-wrap rounded-none"
        *ngIf="canAddNewRow || !applyChangesImmediately"
      >
        <div class="flex flex-row items-center">
          <button
            *ngIf="canAddNewRow && !hasNew"
            (click)="addItem()"
            class="mb-1 text-xs rounded-xl min-h-8 h-8 px-4 border-primary"
            mat-flat-button
            [color]="'accent'"
          >
            <mat-icon [svgIcon]="'heroicons_outline:plus-small'"></mat-icon>
            <span class="ml-2">{{ addNewRowButtonsText }}</span>
          </button>
          <ng-container *ngIf="hasChanges && !applyChangesImmediately">
            <button
              (click)="saveChanges()"
              class="text-xs text-white rounded-xl min-h-8 h-8 px-4 mr-5"
              color="primary"
              mat-stroked-button
            >
              <span class="ml-2 mr-1">Save Changes</span>
            </button>
            <button
              (click)="discardChanges()"
              class="text-xs text-white rounded-xl min-h-8 h-8 px-4"
              color="primary"
              mat-stroked-button
            >
              <span class="ml-2 mr-1">Discard Changes</span>
            </button>
          </ng-container>
        </div>
      </div>
      <div
        class="flex-auto overflow-x-auto"
        [class.max-h-72]="!fullHeight"
        [fuseScrollbarOptions]="{
          wheelPropagation: false,
          suppressScrollX: true
        }"
      >
        <ng-container [formGroup]="form">
          <ng-container formArrayName="listForm">
            <table
              mat-table
              #table
              [dataSource]="dataSourceSnapshot"
              matSort
              class="w-full inline-edit-table"
            >
              <ng-container
                *ngFor="let col of mappedColumns"
                [matColumnDef]="col.sourceName"
              >
                <th
                  [ngStyle]="{
                    'max-width': col.width ? col.width : 'unset',
                    'min-width': col.minWidth ? col.minWidth : 'unset'
                  }"
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                >
                  {{ col.columnName }}
                </th>
                <td
                  [ngStyle]="{
                    'max-width': col.width ? col.width : 'unset',
                    'min-width': col.minWidth ? col.minWidth : 'unset'
                  }"
                  mat-cell
                  *matCellDef="let element; let i = index"
                >
                  <ng-container
                    *ngIf="listForm.controls[i]"
                    [formGroupName]="i"
                  >
                    <div class="min-w-30 flex pr-4" [ngSwitch]="col.type">
                      <ng-template ngSwitchCase="text">
                        <span *ngIf="editRowIndex !== i">{{
                          listForm.controls[i].get(col.sourceName).value
                        }}</span>
                        <mat-form-field
                          *ngIf="editRowIndex === i"
                          [ngClass]="{
                            invalid: listForm.controls[i].get(col.sourceName)
                              .invalid,
                            'pointer-events-none not-editing':
                              editRowIndex !== i
                          }"
                          class="w-full cleared-input fuse-mat-no-subscript"
                        >
                          <input
                            trim="blur"
                            class="p-0"
                            [formControlName]="col.sourceName"
                            matInput
                          />
                        </mat-form-field>
                      </ng-template>

                      <ng-template ngSwitchCase="textarea">
                        <span *ngIf="editRowIndex !== i"  [innerHTML]="listForm.controls[i].get(col.sourceName).value">
                        </span>
                        <mat-form-field
                          *ngIf="editRowIndex === i"
                          [ngClass]="{
                            invalid: listForm.controls[i].get(col.sourceName)
                              .invalid,
                            'pointer-events-none not-editing':
                              editRowIndex !== i
                          }"
                          class="w-full cleared-input fuse-mat-no-subscript"
                        >
                          <textarea
                            readonly
                            rows="2"
                            trim="blur"
                            class="p-0"
                            [value]='stripTags(listForm.controls[i].get(col.sourceName).value)'
                            (click)="onFocus($event, col.sourceName)"
                            matInput>

                          </textarea>
                        </mat-form-field>
                      </ng-template>

                      <ng-template ngSwitchCase="digits">
                        <div [class.hidden]="editRowIndex === i">
                          {{
                            listForm.controls[i].get(col.sourceName).value
                              | number: "1.2-2"
                          }}
                        </div>
                        <mat-form-field
                          [ngClass]="{
                            hidden: editRowIndex !== i,
                            invalid: listForm.controls[i].get(col.sourceName)
                              .invalid,
                            'pointer-events-none not-editing':
                              editRowIndex !== i
                          }"
                          class="w-full cleared-input fuse-mat-no-subscript"
                        >
                          <input
                            trim="blur"
                            [inputMask]="currencyInputMask"
                            class="p-0 text-left-permanent"
                            class="p-0"
                            [formControlName]="col.sourceName"
                            matInput
                          />
                        </mat-form-field>
                      </ng-template>

                      <ng-template ngSwitchCase="date">
                        <mat-form-field
                          [ngClass]="{
                            invalid: listForm.controls[i].get(col.sourceName)
                              .invalid,
                            'pointer-events-none not-editing':
                              editRowIndex !== i
                          }"
                          class="w-full cleared-input date-input cleared-input fuse-mat-no-subscript"
                        >
                          <input
                            matInput
                            [matDatepicker]="name_i"
                            [formControlName]="col.sourceName"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="name_i"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #name_i></mat-datepicker>
                        </mat-form-field>
                      </ng-template>

                      <ng-template ngSwitchCase="dropdown">
                        <span *ngIf="editRowIndex !== i">{{
                          getDropdownNameById(
                            listForm.controls[i].get(col.sourceName).value,
                            col.sourceName
                          )
                        }}</span>

                        <mat-form-field
                          *ngIf="editRowIndex === i"
                          [ngClass]="{
                            invalid: listForm.controls[i].get(col.sourceName)
                              .invalid,
                            'pointer-events-none not-editing':
                              editRowIndex !== i ||
                              (editRowIndex === i && !hasNew)
                          }"
                          class="w-full cleared-select fuse-mat-no-subscript"
                        >
                          <mat-select
                            class=""
                            [formControlName]="col.sourceName"
                          >
                            <mat-option
                              *ngFor="
                                let option of getDropdownOptions(
                                  col.sourceName,
                                  i
                                )
                              "
                              [value]="option.value"
                              class=""
                            >
                              {{ option.text }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-template>

                      <ng-template ngSwitchCase="checkbox">
                        <mat-icon
                          *ngIf="
                            editRowIndex !== i &&
                            listForm.controls[i].get(col.sourceName).value
                          "
                          aria-hidden="true"
                          class="icon-size-5"
                          matPrefix
                          [svgIcon]="'heroicons_outline:check-circle'"
                        >
                        </mat-icon>

                        <div
                          *ngIf="editRowIndex === i"
                          [ngClass]="{
                            invalid: listForm.controls[i].get(col.sourceName)
                              .invalid
                          }"
                          class="w-full cleared-input"
                        >
                          <mat-checkbox
                            [formControlName]="col.sourceName"
                            color="primary"
                          ></mat-checkbox>
                        </div>
                      </ng-template>
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div class="flex flex-row">
                    <ng-container *ngIf="dataSourceSnapshot.length">
                      <ng-container
                        *ngIf="editRowIndex === i && dataSourceSnapshot.length"
                      >
                        <button
                          [disabled]="!canEdit ||
                            listForm.controls[i]?.get('paymentDate')?.invalid ||
                            listForm.controls[i]?.get('amount')?.invalid"
                          mat-icon-button
                          (click)="$event.stopPropagation(); checkIsValide()"
                        >
                          <mat-icon
                            aria-hidden="true"
                            class="icon-size-5 text-green-500"
                            matPrefix
                            [svgIcon]="'heroicons_outline:check-circle'"
                          ></mat-icon>
                        </button>
                        <button
                          [disabled]="!canEdit"
                          mat-icon-button
                          (click)="
                            $event.stopPropagation(); discardItemChanges(i)
                          "
                        >
                          <mat-icon
                            aria-hidden="true"
                            class="icon-size-5 text-warn"
                            matPrefix
                            [svgIcon]="'heroicons_outline:x-mark'"
                          ></mat-icon>
                        </button>
                      </ng-container>

                      <ng-container
                        *ngIf="editRowIndex !== i && dataSourceSnapshot.length"
                      >
                        <ng-container *ngIf="customButtons">
                          <button
                            *ngFor="let button of customButtons"
                            [disabled]="!button.canEdit"
                            mat-icon-button
                            (click)="
                              $event.stopPropagation();
                              button.onClick(button.name, element)
                            "
                          >
                            <mat-icon
                              aria-hidden="true"
                              class="icon-size-5 text-secondary"
                              matPrefix
                              [svgIcon]="button.icon"
                            ></mat-icon>
                          </button>
                        </ng-container>

                        <button
                          [disabled]="!canEdit"
                          mat-icon-button
                          (click)="$event.stopPropagation(); editItem(i)"
                        >
                          <mat-icon
                            aria-hidden="true"
                            class="icon-size-5 text-secondary"
                            matPrefix
                            [svgIcon]="'heroicons_outline:pencil'"
                          ></mat-icon>
                        </button>
                        <button
                          [disabled]="!canEdit"
                          mat-icon-button
                          (click)="$event.stopPropagation(); deleteItem(i)"
                        >
                          <mat-icon
                            aria-hidden="true"
                            class="icon-size-5 text-secondary"
                            matPrefix
                            [svgIcon]="'heroicons_outline:trash'"
                          ></mat-icon>
                        </button>
                      </ng-container>
                    </ng-container>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                [ngClass]="{
                  'table-new-row': row.isNew,
                  'edit-section': editRowIndex === i && !row.isNew,
                  'bg-zinc-200 dark:bg-zinc-800': editRowIndex === i
                }"
                *matRowDef="let row; columns: displayedColumns; let i = index"
                [ngClass]="{ 'pointer-events-none': !canEdit }"
                class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
                (click)="editItem(i)"
              ></tr>
            </table>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
