<div class="w-full max-w-3xl">
  <div class="container mx-auto">
    <form
      class="flex flex-col mt-4 bg-card shadow rounded overflow-hidden"
      [formGroup]="settingsForm.get('basic')"
    >
      <div class="p-8 border-b">
        <p class="text-lg font-medium">Basic information</p>
        <p class="text-secondary">
          Set your company information, help us personalize your experience and
          make big account changes here
        </p>
      </div>

      <div class="flex flex-col p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <!-- Company name -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-full">
              <mat-label>Company name</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:briefcase'"
                matPrefix
              ></mat-icon>
              <mat-error>Company name required</mat-error>
              <input
                [autocomplete]="'off'"
                formControlName="businessEntityName"
                maxlength="254"
                matInput
                required
              />
              <mat-error
                *ngIf="
                  settingsForm
                    .get('basic.businessEntityName')
                    .hasError('maxlength')
                "
                >Maximum 254 characters</mat-error
              >
            </mat-form-field>
          </div>
          <!-- Company email -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:envelope'"
                matPrefix
              ></mat-icon>
              <mat-error>Email address required</mat-error>
              <input formControlName="email" matInput maxlength="254" />
              <mat-error
                *ngIf="settingsForm.get('basic.email').hasError('maxlength')"
                >Maximum 254 characters</mat-error
              >
            </mat-form-field>
          </div>

          <!-- Address lookup -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-full" floatLabel="always">
              <mat-label>Address lookup</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:home'"
                matPrefix
              ></mat-icon>
              <input
                [disabled]="isReadOnly"
                (addressSelected)="handleAddressSelected($event)"
                matInput
                addressLookup
                placeholder="Type here to search for an address"
              />
            </mat-form-field>
          </div>
          <!-- Address -->
          <ng-container formGroupName="address">
            <div class="sm:col-span-4">
              <mat-form-field class="w-full">
                <mat-label>Address</mat-label>
                <input
                  [autocomplete]="'off'"
                  type="text"
                  matInput
                  maxlength="128"
                  formControlName="streetAddress"
                />
                <mat-error
                  *ngIf="
                    settingsForm
                      .get('basic.address.streetAddress')
                      .hasError('maxlength')
                  "
                  >Maximum 128 characters</mat-error
                >
              </mat-form-field>
            </div>
            <div class="sm:col-span-2">
              <mat-form-field class="w-full">
                <mat-label>Suburb</mat-label>
                <input
                  [autocomplete]="'off'"
                  type="text"
                  matInput
                  maxlength="64"
                  formControlName="suburb"
                />
                <mat-error
                  *ngIf="
                    settingsForm
                      .get('basic.address.suburb')
                      .hasError('maxlength')
                  "
                  >Maximum 64 characters</mat-error
                >
              </mat-form-field>
            </div>
            <div class="sm:col-span-1">
              <mat-form-field class="w-full">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of states" [value]="state">
                    {{ state }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="sm:col-span-1">
              <mat-form-field class="w-full">
                <mat-label>Postcode</mat-label>
                <input
                  [autocomplete]="'off'"
                  type="text"
                  matInput
                  formControlName="postCode"
                  maxlength="4"
                />
              </mat-form-field>
            </div>
          </ng-container>
          <!--  Website -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-full">
              <mat-label>Website</mat-label>
              <mat-icon class="icon-size-5" matPrefix>language</mat-icon>
              <input
                [autocomplete]="'off'"
                formControlName="website"
                maxlength="100"
                matInput
              />
              <mat-error
                *ngIf="settingsForm.get('basic.website').hasError('maxlength')"
                >Maximum 100 characters</mat-error
              >
            </mat-form-field>
          </div>
          <!-- phone number -->
          <div class="sm:col-span-4">
            <label>Phone number</label>
            <mat-form-field class="w-full">
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:phone'"
                matPrefix
              ></mat-icon>
              <input
                [autocomplete]="'off'"
                placeholder="e.g. 0733331111"
                data-cy="au-mobile"
                type="tel"
                [pattern]="phonePattern"
                matInput
                formControlName="phoneNumber"
                maxlength="20"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('basic.phoneNumber').hasError('maxlength')
                "
                >Maximum 20 characters</mat-error
              >
            </mat-form-field>
          </div>

          <!--  ABN -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-full">
              <mat-label>ABN</mat-label>
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:briefcase'"
                matPrefix
              ></mat-icon>
              <input
                [autocomplete]="'off'"
                [inputMask]="abnInputMask"
                formControlName="abn"
                matInput
                required
              />
              <mat-error *ngIf="settingsForm.get('basic.abn').hasError('abn')"
                >ABN is incorrect</mat-error
              >
            </mat-form-field>
          </div>
          <!--  Licence -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-full">
              <mat-label
                >License, certification and associations details</mat-label
              >
              <mat-icon
                class="icon-size-5"
                [svgIcon]="'heroicons_outline:identification'"
                matPrefix
              ></mat-icon>
              <input
                [autocomplete]="'off'"
                formControlName="license"
                matInput
                maxlength="1024"
              />
              <mat-error
                *ngIf="settingsForm.get('basic.license').hasError('maxlength')"
                >Maximum 1024 characters</mat-error
              >
            </mat-form-field>
          </div>
          <!--  Default On Cost % -->
          <div class="sm:col-span-4">
            <mat-form-field class="w-1/2">
              <mat-label>Default On Cost % for estimates</mat-label>
              <mat-icon class="icon-size-5" matPrefix>percent</mat-icon>
              <input
                [autocomplete]="'off'"
                formControlName="defaultOnCost"
                matInput
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="100"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="mb-4 flex items-center justify-end border-t px-8 py-5">
        <button
          class="px-6 ml-3"
          [color]="'primary'"
          [disabled]="
            isReadOnly ||
            settingsForm.get('basic').dirty === false ||
            settingsForm.get('basic').invalid
          "
          (click)="save()"
          mat-flat-button
        >
          Save
        </button>
      </div>

      <!-- Company logo -->
      <div class="p-8 border-b">
        <p class="text-lg font-medium">Company logo</p>
        <p class="text-secondary">
          Upload company logo, it will appear on quotes and invoices
        </p>
      </div>

      <div class="p-8">
        <div class="sm:col-span-4">
          <div>Company logo</div>
          <div class="flex flex-row items-center mt-2">
            <img
              class="w-1/4 p-4"
              *ngIf="settingsForm.get('basic.logo').value"
              [src]="settingsForm.get('basic.logo').value"
            />
          </div>
          <button
            [disabled]="isReadOnly"
            (click)="openCropperDialog()"
            mat-button
            type="button"
            color="primary"
          >
            <mat-icon
              class="icon-size-5 mr-2"
              [svgIcon]="'heroicons_outline:arrow-up-on-square'"
            ></mat-icon>
            <span>Upload</span>
          </button>
          <button
            class="px-3 mr-1"
            [disabled]="isReadOnly"
            (click)="deleteLogo()"
            mat-button
            type="button"
            color="warn"
          >
            <mat-icon
              class="icon-size-5 mr-2"
              [svgIcon]="'heroicons_outline:trash'"
            ></mat-icon>
            <span>Delete</span>
          </button>
        </div>
      </div>
    </form>

    <form
      class="flex flex-col bg-card shadow rounded overflow-hidden"
      [formGroup]="settingsForm.get('quote')"
    >
      <!-- Quote information -->
      <div class="p-8 border-b">
        <p class="text-lg font-medium">Quote information</p>
        <p class="text-secondary">
          Specify the number on a prefix when creating the next quote, invoice
          and purchase order
        </p>
      </div>
      <div class="p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Quote Prefix</mat-label>
              <input
                type="text"
                matInput
                maxlength="10"
                formControlName="quotePrefix"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('quote.quotePrefix').hasError('maxlength')
                "
                >Maximum 10 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm
                    .get('quote.quotePrefix')
                    .hasError('whitespace') &&
                  settingsForm.get('quote.quotePrefix').hasError('required') ===
                    false
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('quote.quotePrefix').hasError('required')
                "
                >Required field</mat-error
              >
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Next Number</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="quoteStartNumber"
              />
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Invoice Prefix</mat-label>
              <input
                type="text"
                matInput
                maxlength="10"
                formControlName="invoicePrefix"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('quote.invoicePrefix').hasError('maxlength')
                "
                >Maximum 10 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm
                    .get('quote.invoicePrefix')
                    .hasError('whitespace') &&
                  settingsForm
                    .get('quote.invoicePrefix')
                    .hasError('required') === false
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('quote.invoicePrefix').hasError('required')
                "
                >Required field</mat-error
              >
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Next Number</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="invoiceStartNumber"
              />
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Job Prefix</mat-label>
              <input
                type="text"
                matInput
                maxlength="10"
                formControlName="jobPrefix"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('quote.jobPrefix').hasError('maxlength')
                "
                >Maximum 10 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('quote.jobPrefix').hasError('whitespace') &&
                  settingsForm.get('quote.jobPrefix').hasError('required') ===
                    false
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="settingsForm.get('quote.jobPrefix').hasError('required')"
                >Required field</mat-error
              >
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Next Number</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="jobStartNumber"
              />
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Purchase Order Prefix</mat-label>
              <input
                type="text"
                matInput
                maxlength="10"
                formControlName="purchaseOrderPrefix"
              />
              <mat-error
                *ngIf="
                  settingsForm
                    .get('quote.purchaseOrderPrefix')
                    .hasError('maxlength')
                "
                >Maximum 10 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm
                    .get('quote.purchaseOrderPrefix')
                    .hasError('whitespace') &&
                  settingsForm
                    .get('quote.purchaseOrderPrefix')
                    .hasError('required') === false
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm
                    .get('quote.purchaseOrderPrefix')
                    .hasError('required')
                "
                >Required field</mat-error
              >
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Next Number</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="purchaseOrderStartNumber"
              />
            </mat-form-field>
          </div>
          <!-- <div class="sm:col-span-4">
            <mat-form-field
              class="w-full"
              matTooltip="Create a separate Purchase Order for each Supplier and Category"
            >
              <mat-label
                >Create a separate Purchase Order for each Supplier and
                Category</mat-label
              >
              <mat-slide-toggle
                [disabled]="isReadOnly"
                color="primary"
                formControlName="purchaseOrderAtCategoryLevel"
                >{{
                  settingsForm.get("quote.purchaseOrderAtCategoryLevel").value
                    ? "Yes"
                    : "No"
                }}</mat-slide-toggle
              >
              <input matInput hidden />
            </mat-form-field>
          </div>
          <div class="sm:col-span-4 -mt-8">
            <p class="pb-2">
              When Yes, a Purchase Order is created for each Supplier and
              Category combination.
            </p>
            <p>
              When not Yes, a Purchase Order is created for each Supplier and
              all Categories for that Supplier will be included on it.
            </p>
          </div>
          -->
        </div>
      </div>

      <!-- Actions -->
      <div class="mb-4 flex items-center justify-end border-t px-8 py-5">
        <button
          class="px-6 ml-3"
          [color]="'primary'"
          [disabled]="
            isReadOnly ||
            settingsForm.get('quote').pristine ||
            settingsForm.get('quote').invalid
          "
          (click)="save()"
          mat-flat-button
        >
          Save
        </button>
      </div>
    </form>

    <form
      class="flex flex-col bg-card shadow rounded overflow-hidden"
      [formGroup]="settingsForm.get('terms')"
    >
      <!-- Terms -->
      <div class="p-8 border-b">
        <p class="text-lg font-medium">Terms</p>
        <p class="text-secondary">
          Default days quotes/invoices are valid/due after the quote/invoice
          date
        </p>
      </div>
      <div class="p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Days quotes are valid</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="quoteTerms"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('terms.quoteTerms').hasError('required')
                "
                >Required field</mat-error
              >
              <mat-error
                *ngIf="settingsForm.get('terms.quoteTerms').hasError('min')"
                >Min value is 1</mat-error
              >
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Days invoices are due</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="invoiceTerms"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('terms.invoiceTerms').hasError('required')
                "
                >Required field</mat-error
              >
              <mat-error
                *ngIf="settingsForm.get('terms.invoiceTerms').hasError('min')"
                >Min value is 1</mat-error
              >
            </mat-form-field>
          </div>
          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Days variations are valid</mat-label>
              <input
                type="number"
                numbersOnly
                decimal="false"
                min="0"
                max="9999999"
                matInput
                formControlName="variationTerms"
              />
              <mat-error
                *ngIf="
                  settingsForm.get('terms.variationTerms').hasError('required')
                "
                >Required field</mat-error
              >
              <mat-error
                *ngIf="settingsForm.get('terms.variationTerms').hasError('min')"
                >Min value is 1</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Actions -->
      <div class="mb-4 flex items-center justify-end border-t px-8 py-5">
        <button
          class="px-6 ml-3"
          [color]="'primary'"
          [disabled]="
            isReadOnly ||
            settingsForm.get('terms').pristine ||
            settingsForm.get('terms').invalid
          "
          (click)="save()"
          mat-flat-button
        >
          Save
        </button>
      </div>
    </form>

    <form
      class="flex flex-col bg-card shadow rounded overflow-hidden"
      [formGroup]="settingsForm.get('bank')"
    >
      <!-- Bank details -->
      <div class="p-8">
        <p class="text-lg font-medium">Bank account details</p>
        <p class="text-secondary">Used for invoicing and customer payment</p>
      </div>
      <div class="p-8">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
          <div class="sm:col-span-4">
            <mat-form-field class="w-full">
              <mat-label>Account name</mat-label>
              <input matInput formControlName="bankAccountName" />
              <mat-error
                *ngIf="
                  settingsForm.get('bank.bankAccountName').hasError('maxlength')
                "
                >Maximum 150 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm
                    .get('bank.bankAccountName')
                    .hasError('whitespace') &&
                  settingsForm
                    .get('bank.bankAccountName')
                    .hasError('required') === false
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('bank.bankAccountName').hasError('required')
                "
                >Required field</mat-error
              >
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>BSB number</mat-label>
              <input matInput maxlength="10" formControlName="bankBSB" />
              <mat-error
                *ngIf="settingsForm.get('bank.bankBSB').hasError('maxlength')"
                >Maximum 10 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('bank.bankBSB').hasError('whitespace') &&
                  settingsForm.get('bank.bankBSB').hasError('required') ===
                    false
                "
                >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="settingsForm.get('bank.bankBSB').hasError('required')"
                >Required field</mat-error
              >
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field class="w-full">
              <mat-label>Account number</mat-label>
              <input matInput maxlength="50" formControlName="bankAccount" />
              <mat-error
                *ngIf="
                  settingsForm.get('bank.bankAccount').hasError('maxlength')
                "
              >Maximum 50 characters</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('bank.bankAccount').hasError('whitespace') &&
                  settingsForm.get('bank.bankAccount').hasError('required') ===
                    false
                "
              >No white spaces allowed</mat-error
              >
              <mat-error
                *ngIf="
                  settingsForm.get('bank.bankAccount').hasError('required')
                "
              >Required field</mat-error
              >
            </mat-form-field>
          </div>

        </div>
      </div>

      <!-- Actions -->
      <div class="flex items-center justify-end border-t px-8 py-5">
        <button
          class="px-6 ml-3"
          [color]="'primary'"
          [disabled]="
            isReadOnly ||
            settingsForm.get('bank').pristine ||
            settingsForm.get('bank').invalid
          "
          (click)="save()"
          mat-flat-button
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
