import { createReducer, on } from '@ngrx/store';
import { PaginatedListOfJobCosting } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';
import {
  getJobCostingSummary,
  getJobCostingSummaryFailure,
  getJobCostingSummarySuccess,
  getJobVariations,
  getJobVariationsFailure,
  getJobVariationsSuccess,
} from '../actions';

export interface JobVariationsListState {
  variations: PaginatedListOfJobCosting;
  callState: CallState;
  error: any;
  costingSummary: any;
}

export const initialState: JobVariationsListState = {
  variations: <PaginatedListOfJobCosting>{ items: [] },
  callState: LoadingState.INIT,
  error: null,
  costingSummary: null,
};

export const jobVariationsListReducer = createReducer(
  initialState,
  on(getJobVariations, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobVariationsSuccess, (state, { variations }) => ({
    ...state,
    variations,
    callState: LoadingState.LOADED,
  })),
  on(getJobVariationsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  // costing  summary
  on(getJobCostingSummary, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getJobCostingSummarySuccess, (state, { summary }) => ({
    ...state,
    costingSummary: summary,
    callState: LoadingState.LOADED,
  })),
  on(getJobCostingSummaryFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
