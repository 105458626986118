<h2 mat-dialog-title class="text-sm sm:text-base">Upload Files</h2>
<mat-dialog-content>
  <div class="flex" *ngFor="let file of files$ | async">
    <div class="mr-4">{{ file.name }}</div>
    <div class="flex items-center">
      <mat-progress-bar
        *ngIf="
          file.progress < 100 &&
          file.status !== fileUploadStatus.Completed &&
          file.status !== fileUploadStatus.Failed
        "
        class="w-full"
        mode="determinate"
        [value]="file.progress"
      ></mat-progress-bar>
      <div
        *ngIf="
          file.progress === 100 && file.status !== fileUploadStatus.Completed
        "
      >
        Saving file...
      </div>
      <div *ngIf="file.status === fileUploadStatus.Completed">
        File uploaded successfully
      </div>
      <div *ngIf="file.status === fileUploadStatus.Failed" class="text-warn">
        {{ file.error }}
      </div>
    </div>
    <div
      *ngIf="
        file.progress < 100 &&
        file.status !== fileUploadStatus.Completed &&
        file.status !== fileUploadStatus.Failed
      "
    >
      {{ file.progress }}%
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="uploadComplete$ | async"
    mat-flat-button
    [color]="'primary'"
    (click)="close()"
  >
    Close
  </button>
</mat-dialog-actions>
