/* eslint-disable @typescript-eslint/ban-types */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { DateAdapter } from '@angular/material/core';
import { InputMaskModule } from '@ngneat/input-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFilesizeModule } from 'ngx-filesize';
import { QuillModule } from 'ngx-quill';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import {
  AddRemovePriceListItemsDialogComponent,
  AttachmentModalComponent,
  CreateSupplierOrderComponent,
  CustomerFormComponent,
  CustomSwitcherComponent,
  FileUploadProgressComponent,
  InlineEditingGridComponent,
  JobNotesEditorComponent,
  JobStatusPillComponent,
  OrderStatusPillComponent,
  PreviewComponent,
  QuillEditorElementComponent,
  QuillEditorModalComponent,
  SendModalComponent,
  SpeachToTextComponent,
  StatusToggleComponent,
} from './components';
import { JobWizardModalComponent } from './components/job-wizard-modal/job-wizard-modal.component';
import { TotalsHeaderComponent } from './components/totals-header/totals-header.component';
import * as fromGuards from './guards';
import { HeaderAppenderInterceptor } from './interceptors/header-appender.interceptor';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { CustomDateAdapter } from './services/custom-date-adapter.service';

import { SharedStoreModule } from './store/shared-store.module';
import { PhotosStoreModule } from 'app/views/photo/store/photo-store.module';
import { FileStoreModule } from 'app/views/files/store/files-store.module';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { UserModeResolver } from './resolvers/user-mode.resolver';
import { FuseScrollbarDirective } from '@fuse/directives/scrollbar';
import { sharedDirectives } from './directives';

@NgModule({
  imports: [
    InputMaskModule.forRoot(),
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    SharedStoreModule,
    NgxFilesizeModule,
    NgxTrimDirectiveModule,
    QuillModule.forRoot(),
    NgxExtendedPdfViewerModule,
    ClickOutsideModule,
    GoogleMapsModule,
    PhotosStoreModule,
    FileStoreModule,
    FuseScrollbarDirective,
    sharedDirectives,
    FileUploadProgressComponent,
    CustomerFormComponent,
    QuillEditorModalComponent,
    CreateSupplierOrderComponent,
    InlineEditingGridComponent,
    SendModalComponent,
    OrderStatusPillComponent,
    PreviewComponent,
    JobStatusPillComponent,
    JobNotesEditorComponent,
    CustomSwitcherComponent,
    JobWizardModalComponent,
    QuillEditorElementComponent,
    AttachmentModalComponent,
    TotalsHeaderComponent,
    SpeachToTextComponent,
    StatusToggleComponent,
    AddRemovePriceListItemsDialogComponent,
    CheckBoxComponent,
  ],
  exports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    NgxFilesizeModule,
    FileUploadProgressComponent,
    NgxTrimDirectiveModule,
    InputMaskModule,
    CustomerFormComponent,
    QuillEditorModalComponent,
    InlineEditingGridComponent,
    OrderStatusPillComponent,
    JobStatusPillComponent,
    JobNotesEditorComponent,
    PreviewComponent,
    CustomSwitcherComponent,
    JobWizardModalComponent,
    QuillEditorElementComponent,
    AttachmentModalComponent,
    TotalsHeaderComponent,
    SpeachToTextComponent,
    StatusToggleComponent,
    GoogleMapsModule,
    AddRemovePriceListItemsDialogComponent,
    CheckBoxComponent,
    sharedDirectives,
  ],
  providers: [
    ...fromGuards.guards,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderAppenderInterceptor,
      multi: true,
    },
    [CustomDateAdapter, { provide: DateAdapter, useClass: CustomDateAdapter }],
  ],
})
export class SharedModule {}
