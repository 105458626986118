/* eslint-disable quotes */
/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { Subject, Subscription } from 'rxjs';
import { first, skip, takeUntil } from 'rxjs/operators';
import { AccountUserFacade } from 'app/shared/store/facades/account-user.facade';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TeamMember } from 'app/shared/models';
import {
  AsyncPipe,
  NgIf,
  NgFor,
  NgTemplateOutlet,
  DatePipe,
} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NewInviteDialogComponent } from './new-invite-dialog/new-invite-dialog.component';
import { DateTime } from 'luxon';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'account-invites',
  templateUrl: './account-invites.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'account-invites',
  standalone: true,
  imports: [
    MatButtonModule,
    NgIf,
    MatIconModule,
    NgFor,
    NgTemplateOutlet,
    AsyncPipe,
    DatePipe,
  ],
})
export class AccountInvitesComponent implements OnInit, OnDestroy {
  @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
  @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

  invites: TeamMember[];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private fuseConfirmationService: FuseConfirmationService,
    public af: AccountUserFacade,
    private async: AsyncPipe,
    public dialog: MatDialog,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to message changes
    this.af.invitedAccounts$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((invites: TeamMember[]) => {
        // Load the messages
        this.invites = invites;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this.showInitialInviteDialog();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the messages panel
   */
  openPanel(): void {
    // Return if the messages panel or its origin is not defined
    if (!this._messagesPanel || !this._messagesOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._messagesPanel, this._viewContainerRef),
    );
  }

  /**
   * Close the messages panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  public handleIntiteSelected(member: TeamMember): void {
    this._overlayRef.detach();

    const confirmation = this.fuseConfirmationService.open({
      title: `Invitation to join <b>${
        member?.accountName || member?.accountEmail
      }</b>`,
      message: 'Would you like to accept or reject it?',
      icon: {
        name: 'heroicons_outline:exclamation-triangle',
        color: 'primary',
      },
      actions: {
        confirm: {
          label: 'Accept',
          color: 'primary',
        },
        cancel: {
          label: 'Reject',
        },
      },
      dismissible: true,
    });

    confirmation
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((x) => {
        if (x === undefined) {
          // dialog was dismissed or closed
          return;
        }
        if (x === 'confirmed') {
          const teamMember = JSON.parse(JSON.stringify(member));
          this.af.acceptTeamMemberInvite({
            id: teamMember.id,
          });
        } else {
          // rejected
          const teamMember = JSON.parse(JSON.stringify(member));
          teamMember.rejectedDate = DateTime.now().setLocale('en-AU');
          this.af.modifyTeamMemberInvite(teamMember);
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private showInitialInviteDialog(): void {
    const accounts = this.async.transform(this.af.accountsToDisplayDialogFor$);

    const accountsRemovedMessageShown = this.async
      .transform(this.af.recentTeamUserRemovedFrom$)
      .map((a) => a.id);

    if (accounts.length > 0 || accountsRemovedMessageShown.length > 0) {
      const dialogRef = this.dialog.open(NewInviteDialogComponent, {
        width: '626px',
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.af.markRemovedAccountsMessageShown(accountsRemovedMessageShown);
        const invites = this.async.transform(
          this.af.accountsToDisplayDialogFor$,
        );

        if (invites.length > 0) {
          const data = JSON.parse(JSON.stringify(invites)).map((m) => {
            m.showNotification = false;
            return m;
          });
          data.forEach((element) => {
            this.af.modifyTeamMemberInvite(element);
          });
        }
      });
    }
  }

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}
