/* eslint-disable arrow-parens */
import { Injectable } from '@angular/core';

import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AccountSettingsComponent } from '../account-settings/account-settings.component';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsGuard {
  constructor(private fuseConfirmationService: FuseConfirmationService) {}
  canDeactivate(component: AccountSettingsComponent): Observable<boolean> {
    if (component.settingsForm.dirty) {
      // show dialog
      const confirmation = this.fuseConfirmationService.open({
        title: 'Leaving page',
        message:
          'Are you sure you want to leave this page? Changes made will be lost',
        actions: {
          confirm: {
            label: 'Ok',
          },
        },
      });

      return confirmation
        .afterClosed()
        .pipe(switchMap((x) => of(x === 'confirmed')));
    } else {
      return of(true);
    }
  }
}
