/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TandCService } from '../services/tandc.service';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsGuard implements CanActivate {
  private termsUrl = '/terms-and-conditions';
  constructor(
    private api: TandCService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  checkTermsAndConditions$ = (): Observable<boolean> => {
    return this.api.getShowTandC().pipe(
      tap((data) => console.log('check tandc', data)),
      map((showTandC) => {
        if (showTandC) {
          this.router.navigate([this.termsUrl]);
          return true;
        }
        return true;
      }),
    );
  };

  checkData = (): Observable<boolean> =>
    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap((_) => this.checkTermsAndConditions$()),
    );

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkData();
  }
}
