import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import * as fromReducer from '../reducers/supplier-details.reducer';
import * as fromFeature from '../reducers';

export const getSupplierState = createSelector(
  fromFeature.getSupplierReviewState,
  (state: fromFeature.SupplierState) => state.supplier,
);

export const getSupplier = createSelector(
  getSupplierState,
  fromReducer.getSupplier,
);

export const getSupplierIsLoading = createSelector(
  getSupplierState,
  (s) => s.callState === LoadingState.LOADING,
);

export const getSupplierIsLoaded = createSelector(
  getSupplierState,
  (s) => s.callState === LoadingState.LOADED,
);

export const getSupplierError = createSelector(getSupplierState, (s) =>
  getError(s.callState),
);
