import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as fromComponents from './components';
import * as fromContainers from './containers';

import { AccountSettingsGuard } from './components/account-settings/account-settings.guard';
import { ProfileSettingsGuard } from './components/profile-settings/profile-settings.guard';
import { SettingsRoleRedrectGuard } from './guards/settings-role-redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: fromContainers.SettingsContainerComponent,
    canActivateChild: [SettingsRoleRedrectGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'account',
      },
      {
        path: 'account',
        title: 'Settings Account',
        component: fromComponents.AccountSettingsComponent,
        canDeactivate: [AccountSettingsGuard],
      },
      {
        path: 'billing',
        title: 'Settings Billing',
        component: fromComponents.BillingSettingsComponent,
      },
      {
        path: 'profile',
        title: 'Settings Profile',
        component: fromComponents.ProfileSettingsComponent,
        canDeactivate: [ProfileSettingsGuard],
      },
      {
        path: 'uom',
        title: 'Settings UOM',
        component: fromComponents.UomManagementComponent,
      },
      {
        path: 'integration-summary',
        title: 'Settings Integration Summary',
        component: fromComponents.IntegrationSummaryComponent,
      },
      {
        path: 'integrations',
        title: 'Settings Integrations',
        component: fromComponents.IntegrationsContainerComponent,
        children: [
          {
            path: '',
            redirectTo: 'int/xero',
            pathMatch: 'full',
          },
          {
            path: 'int/xero',
            title: 'Settings Integrations Xero',
            component: fromComponents.XeroComponent,
          },
          {
            path: 'int/myob',
            title: 'Settings Integrations MYOB',
            component: fromComponents.MyobComponent,
          },
          {
            path: 'int/quickbooks',
            title: 'Settings Integrations Quickbooks',
            component: fromComponents.QuickbooksComponent,
          },
        ],
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
