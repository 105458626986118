<div
  class="xs:absolute xs:inset-0 flex flex-col flex-auto min-w-0 xs:overflow-hidden"
>
  <!-- Header -->
  <div
    class="flex-col sm:flex-row md:flex-col justify-between px-4 xs:pt-2 md:pt-4"
  >
    <div
      class="flex flex-row justify-between xs:mb-2 md:mb-4 items-center"
      [class.hidden]="isSmallPortraitScreen"
    >
      <div
        class="xs:text-xl sm:text-2xl md:text-4xl font-extrabold tracking-tight leading-none"
      >
        {{ title }}s
      </div>
      <button
        id="add-estimate-estimate-list"
        class="pl-2 text-xs rounded-xl min-h-8 h-8 px-4"
        *ngIf="title === 'Estimate' && !isReadOnlyMode"
        [color]="'primary'"
        (click)="new()"
        mat-flat-button
      >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">New Estimate</span>
      </button>
    </div>

    <!--  Recent Section   -->
    <div class="mb-4 xs:mb-2" [class.hidden]="isSmallPortraitScreen">
      <div
        class="sm:text-xl xs:text-l font-bold text-secondary leading-none xs:mb-2 sm:mb-4"
      >
        Recent {{ title }}s
      </div>
      <div
        *ngIf="(jobsFacade.recentJobs$ | async)?.length"
        id="recent-list"
        class="flex w-full flex-row overflow-x-auto py-0.5"
      >
        <div
          class="flex-shrink-0 xs:w-1/2 md:w-1/3 lg:w-1/5 px-0.5 cursor-pointer"
          *ngFor="let rj of jobsFacade.recentJobs$ | async"
          (click)="details(rj)"
        >
          <fuse-card class="p-4 flex flex-col">
            <app-job-status-pill [status]="rj?.status"> </app-job-status-pill>
            <div class="sm:text-lg xs:text-md w-full truncate">
              {{ rj.jobTitle }}
            </div>
            <div class="text-secondary sm:text-md xs:text-sm truncate">
              {{ rj.address?.streetAddress }}
            </div>
            <div class="text-secondary text-sm truncate">
              {{ rj.customer?.person?.fullName }}
            </div>
          </fuse-card>
        </div>
      </div>
    </div>
    <!--  Search Section  -->
    <div
      class="table-search-wrap flex flex-row justify-between items-center"
      [ngClass]="isSmallPortraitScreen ? 'p-0' : ''"
    >
      <div
        class="xs:text-l sm:text-2xl md:text-4xl mr-2 font-extrabold tracking-tight leading-none"
        [class.hidden]="!isSmallPortraitScreen"
      >
        {{ title }}s
      </div>
      <div class="flex flex-row">
        <div class="table-search w-80" [formGroup]="searchForm">
          <mat-form-field
            class="fuse-mat-dense fuse-mat-no-subscript sm:large-search w-full min-w-50"
            id="search-estimate-estimate-list"
          >
            <mat-icon
              [svgIcon]="'heroicons_outline:magnifying-glass'"
              matPrefix
            ></mat-icon>
            <input
              [autocomplete]="'off'"
              [placeholder]="'Search anything...'"
              formControlName="searchField"
              matInput
            />
            <button
              *ngIf="searchField.value"
              (click)="searchField.reset()"
              matSuffix
              mat-icon-button
              aria-label="Clear"
            >
              <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="filter-section ml-4" *ngIf="!isSmallPortraitScreen">
          <mat-form-field
            class="min-w-64 mr-4 small-select"
            appearance="fill"
            id="status-filter-estimate-list"
          >
            <mat-select [formControl]="statusControl">
              <mat-option [value]="999">Status - All</mat-option>
              <ng-container *ngIf="title === jobType[jobType.Job]">
                <mat-option
                  *ngFor="let jobStatus of jobStatuses$ | async"
                  [value]="jobStatus.id"
                >
                  Status - {{ jobStatus.name }}
                </mat-option>
              </ng-container>
              <ng-container *ngIf="title === jobType[jobType.Estimate]">
                <mat-option
                  *ngFor="let jobStatus of estimateStatuses$ | async"
                  [value]="jobStatus.id"
                >
                  Status - {{ jobStatus.name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <button
        class="flex-shrink-0 ml-2 text-xs rounded-xl min-h-8 h-8 px-4 xs:px-1"
        [class.hidden]="!isSmallPortraitScreen"
        [color]="'primary'"
        (click)="new()"
        mat-flat-button
      >
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">{{ title }}</span>
      </button>
    </div>
  </div>
  <!-- desktop list -->
  <div *ngIf="!showMobile" class="hidden lg:flex flex-auto overflow-hidden">
    <div
      *ngIf="(jobsFacade.isLoaded$ | async) && jobs.length; else noData"
      class="flex flex-col flex-auto sm:mb-18 overflow-auto px-4 scroll-bar"
    >
      <ng-container>
        <table
          class="w-full"
          [dataSource]="dataSource"
          [matSortActive]="defaultSort.active"
          [matSortDirection]="defaultSort.direction"
          [hidden]="jobsFacade.isLoading$ | async"
          mat-table
          matSort
        >
          <ng-container
            *ngIf="title === jobType[jobType.Estimate]"
            matColumnDef="estimateCode"
          >
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Estimate No.
            </th>
            <td *matCellDef="let element" mat-cell>
              {{ element.estimateCode }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="title === jobType[jobType.Job]"
            matColumnDef="jobCode"
          >
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Job No.</th>
            <td *matCellDef="let element" mat-cell>
              {{ element?.jobCode }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="title === jobType[jobType.Estimate]"
            matColumnDef="name"
          >
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="title === jobType[jobType.Job]"
            matColumnDef="jobTitle"
          >
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
            <td *matCellDef="let element" mat-cell>
              {{ element.jobTitle }}
            </td>
          </ng-container>
          <ng-container matColumnDef="address">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Address</th>
            <td *matCellDef="let element" mat-cell>
              {{ element?.address?.streetAddress || element?.address }}
            </td>
          </ng-container>
          <ng-container matColumnDef="suburb">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Suburb</th>
            <td *matCellDef="let element" mat-cell>
              {{ element?.address?.suburb || element?.suburb }}
            </td>
          </ng-container>
          <ng-container matColumnDef="customer">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Customer</th>
            <td *matCellDef="let element" mat-cell>
              {{
                element.jobType === jobType.Job
                  ? element?.customer?.businessName ||
                    element?.customer?.person?.fullName
                  : element?.customer
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
            <td *matCellDef="let element" mat-cell>
              {{ element?.customer?.person?.email || element?.email }}
            </td>
          </ng-container>
          <ng-container matColumnDef="phone">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Phone</th>
            <td *matCellDef="let element" mat-cell>
              {{ element?.customer?.person?.phone || element?.phone }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
            <td *matCellDef="let element" mat-cell>
              <app-job-status-pill class="" [status]="element?.status">
              </app-job-status-pill>
            </td>
          </ng-container>
          <ng-container
            *ngIf="title === jobType[jobType.Estimate]"
            matColumnDef="expires"
          >
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Expires</th>
            <td *matCellDef="let element" mat-cell>
              {{ element?.expire | date: "dd MMM yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th *matHeaderCellDef mat-header-cell>Action</th>
            <td *matCellDef="let element" mat-cell>
              <button
                class="manage-job-button text-xs rounded-xl min-h-8 h-8 px-4"
                [color]="'primary'"
                (click)="details(element)"
                mat-stroked-button
              >
                <span class="ml-2 mr-1">Manage</span>
              </button>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr
            class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
            *matRowDef="let row; columns: displayedColumns"
            (click)="details(row)"
            mat-row
          ></tr>
          <ng-container matColumnDef="noRecord">
            <td mat-footer-cell *matFooterCellDef>No records found.</td>
          </ng-container>
          <ng-template
            [ngIf]="jobs.length === 0 && jobsFacade.isLoaded$ | async"
          >
            <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
          </ng-template>
        </table>
      </ng-container>
    </div>
  </div>
  <!-- tablet and mobile list  -->
  <div
    class="lg:hidden flex flex-col flex-auto xs:mb-18 overflow-auto xs:overflow-y-auto px-4"
  >
    <ng-container
      *ngIf="(jobsFacade.isLoaded$ | async) && jobs.length; else noData"
    >
      <fuse-card
        class="flex sm:flex-row xs:flex-col flex-shrink-0 w-full p-4 mb-2"
        *ngFor="let job of jobs"
      >
        <div class="flex sm:w-1/3 xs:w-full">
          <div class="flex flex-col w-full">
            <div
              class="text-md font-semibold leading-tight"
              *ngIf="title === jobType[jobType.Estimate]"
            >
              Estimate No: {{ job?.estimateCode }}
            </div>
            <div
              class="text-md font-semibold leading-tight"
              *ngIf="title === jobType[jobType.Job]"
            >
              Job No: {{ job?.jobCode }}
            </div>
            <div class="text-secondary font-medium text-md">
              title: {{ job?.jobTitle || job.name }}
            </div>
            <app-job-status-pill [status]="job?.status"> </app-job-status-pill>
            <div
              class="text-secondary font-medium text-md"
              *ngIf="title === jobType[jobType.Estimate]"
            >
              Expires: {{ job?.expire | date: "dd MMM yyyy" }}
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-1 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
          <div class="text-md font-semibold leading-tight">
            Customer:
            {{
              job.jobType === jobType.Job
                ? job?.customer?.businessName || job?.customer?.person?.fullName
                : job?.customer
            }}
          </div>
          <div class="text-secondary font-medium text-md">
            address: {{ job?.address?.streetAddress || job?.address }}
          </div>
          <div class="text-secondary font-medium text-md">
            suburb: {{ job?.address?.suburb || job?.suburb }}
          </div>
          <div class="text-secondary font-medium text-md break-all">
            email: {{ job?.customer?.person?.email || job?.email }}
          </div>
          <div class="text-secondary font-medium text-md">
            phone: {{ job?.customer?.person?.phone || job?.phone }}
          </div>
        </div>
        <div class="flex items-center ml-6 xs:ml-auto">
          <button
            class="manage-job-button type-action"
            [color]="'primary'"
            (click)="details(job)"
            mat-stroked-button
          >
            <mat-icon [svgIcon]="'settings'" matPrefix></mat-icon>
          </button>
        </div>
      </fuse-card>
    </ng-container>
  </div>

  <mat-paginator
    class="xs:absolute xs:inset-x-0 xs:bottom-0 border-b xs:border-t xs:border-b-0 z-10 bg-gray-50 dark:bg-transparent"
    [ngClass]="{
      'pointer-events-none': (jobsFacade.isLoaded$ | async) === false
    }"
    [length]="jobsFacade.totalCount$ | async"
    [pageSize]="query.pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    showFirstLastButtons="true"
  >
  </mat-paginator>
</div>

<ng-template #noData>
  <div class="relative w-full">
    <div
      *ngIf="(jobsFacade.isLoaded$ | async) && (!jobs || !jobs.length)"
      class="absolute w-full mt-4 text-base font-semibold text-center"
    >
      <span class="text-lg" *ngIf="searchField.touched || searchField.dirty"> No result for your search.</span>
      <ng-container *ngIf="!(searchField.touched || searchField.dirty)">
           <span class="text-lg" *ngIf="title === 'Estimate'"> No estimates yet. Select '+ New Estimate' to create an estimate</span>
          <span class="text-lg" *ngIf="title === 'Job'"> No jobs yet. </span>
      </ng-container>
    </div>
  </div>
</ng-template>
