/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { FullEstimate, Job, JobCostingPriceListItem } from 'app/shared/models';
import { JobStatusParams } from '../../models/job-statuses';

export enum JobDetailActionTypes {
  GetJob = '[Job Detail] Get Job',
  GetJobSuccess = '[Job Detail] Get Job Success',
  GetJobFailure = '[Job Detail] Get Job Failure',

  GetAcceptedEstimate = '[Job Detail] Get Accepted Estimate',
  GetAcceptedEstimateSuccess = '[Job Detail] Get Accepted Estimate Success',
  GetAcceptedEstimateFailure = '[Job Detail] Get Accepted Estimate Failure',

  GetOriginalitems = '[Job Detail] Get Original Items',
  GetOriginalitemsSuccess = '[Job Detail] Get Original Items Success',
  GetOriginalitemsFailure = '[Job Detail] Get Original Items Failure',

  UpdateJob = '[Job Detail] UpdateJob',
  UpdateJobSuccess = '[Job Detail] UpdateJobSuccess',
  UpdateJobFailure = '[Job Detail] UpdateJobFailure',

  UpdateJobStatus = '[Job Detail] UpdateJobStatus',
  UpdateJobStatusSuccess = '[Job Detail] UpdateJobStatusSuccess',
  UpdateJobStatusFailure = '[Job Detail] UpdateJobStatusFailure',
}

export const getJob = createAction(
  JobDetailActionTypes.GetJob,
  props<{ id: string }>(),
);

export const getJobSuccess = createAction(
  JobDetailActionTypes.GetJobSuccess,
  props<{ job: Job }>(),
);

export const getJobFailure = createAction(
  JobDetailActionTypes.GetJobFailure,
  props<{ error: any }>(),
);

export const getAcceptedEstimate = createAction(
  JobDetailActionTypes.GetAcceptedEstimate,
  props<{ id: string }>(),
);

export const getAcceptedEstimateSuccess = createAction(
  JobDetailActionTypes.GetAcceptedEstimateSuccess,
  props<{ estimate: FullEstimate }>(),
);

export const getAcceptedEstimateFailure = createAction(
  JobDetailActionTypes.GetAcceptedEstimateFailure,
  props<{ error: any }>(),
);

export const getOriginalitems = createAction(
  JobDetailActionTypes.GetOriginalitems,
  props<{ id: string }>(),
);

export const getOriginalitemsSuccess = createAction(
  JobDetailActionTypes.GetOriginalitemsSuccess,
  props<{ items: JobCostingPriceListItem[] }>(),
);

export const getOriginalitemsFailure = createAction(
  JobDetailActionTypes.GetOriginalitemsFailure,
  props<{ error: any }>(),
);

export const updateJob = createAction(
  JobDetailActionTypes.UpdateJob,
  props<{ job: Job }>(),
);

export const updateJobSuccess = createAction(
  JobDetailActionTypes.UpdateJobSuccess,
  props<{ job: Job }>(),
);

export const updateJobFailure = createAction(
  JobDetailActionTypes.UpdateJobFailure,
  props<{ error: any }>(),
);

export const updateJobStatus = createAction(
  JobDetailActionTypes.UpdateJobStatus,
  props<{ params: JobStatusParams }>(),
);

export const updateJobStatusSuccess = createAction(
  JobDetailActionTypes.UpdateJobStatusSuccess,
);

export const updateJobStatusFailure = createAction(
  JobDetailActionTypes.UpdateJobStatusFailure,
  props<{ error: any }>(),
);
