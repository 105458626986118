import { EstimateDetailEffects } from './estimate-detail.effects';
import { EstimateListEffects } from './estimate-list.effects';
import { EstimateQuoteEffects } from './estimate-quote.effects';

export * from './estimate-detail.effects';
export * from './estimate-list.effects';
export * from './estimate-quote.effects';

export const effects = [
  EstimateListEffects,
  EstimateDetailEffects,
  EstimateQuoteEffects,
];
