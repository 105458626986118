import { createAction, props } from '@ngrx/store';
import {
  AddUnitOfMeasure,
  UnitOfMeasure,
  UnitOfMeasureParameters,
} from 'app/shared/models';

export enum GeneralSettingsActionTypes {
  _getUnitOfMeasuresList = '[General Settings] Get Unit Of Measures List',
  _getUnitOfMeasuresListSuccess = '[General Settings API] Get Unit Of Measures List Success',
  _getUnitOfMeasuresListFailure = '[General Settings API] Get Unit Of Measures List Failure',

  _addUnitOfMeasure = '[General Settings] Add Unit Of Measure',
  _addUnitOfMeasuresSuccess = '[General Settings API] Add Unit Of Measure Success',
  _addUnitOfMeasuresFailure = '[General Settings API] Add Unit Of Measure Failure',

  _deleteUnitOfMeasure = '[General Settings] Delte Unit Of Measure',
  _deleteUnitOfMeasuresSuccess = '[General Settings API] Delete Unit Of Measure Success',
  _deleteUnitOfMeasuresFailure = '[General Settings API] Delete Unit Of Measure Failure',

  _updateUnitOfMeasure = '[General Settings] Update Unit Of Measure',
  _updateUnitOfMeasuresSuccess = '[General Settings API] Update Unit Of Measure Success',
  _updateUnitOfMeasuresFailure = '[General Settings API] Update Unit Of Measure Failure',
}

export const getUnitOfMeasuresList = createAction(
  GeneralSettingsActionTypes._getUnitOfMeasuresList,
  props<{ payload: UnitOfMeasureParameters }>(),
);
export const getUnitOfMeasuresListSuccess = createAction(
  GeneralSettingsActionTypes._getUnitOfMeasuresListSuccess,
  props<{ payload: UnitOfMeasure[] }>(),
);
export const getUnitOfMeasuresListFailure = createAction(
  GeneralSettingsActionTypes._getUnitOfMeasuresListFailure,
  props<{ payload: any }>(),
);

export const addUnitOfMeasure = createAction(
  GeneralSettingsActionTypes._addUnitOfMeasure,
  props<{ payload: AddUnitOfMeasure }>(),
);
export const addUnitOfMeasureSuccess = createAction(
  GeneralSettingsActionTypes._addUnitOfMeasuresSuccess,
  props<{ payload: UnitOfMeasure }>(),
);
export const addUnitOfMeasureFailure = createAction(
  GeneralSettingsActionTypes._addUnitOfMeasuresFailure,
  props<{ payload: any }>(),
);

export const updateUnitOfMeasure = createAction(
  GeneralSettingsActionTypes._updateUnitOfMeasure,
  props<{ payload: UnitOfMeasure }>(),
);
export const updateUnitOfMeasureSuccess = createAction(
  GeneralSettingsActionTypes._updateUnitOfMeasuresSuccess,
  props<{ payload: UnitOfMeasure }>(),
);
export const updateUnitOfMeasureFailure = createAction(
  GeneralSettingsActionTypes._updateUnitOfMeasuresFailure,
  props<{ payload: any }>(),
);

export const deleteUnitOfMeasure = createAction(
  GeneralSettingsActionTypes._deleteUnitOfMeasure,
  props<{ payload: string }>(),
);
export const deleteUnitOfMeasureSuccess = createAction(
  GeneralSettingsActionTypes._deleteUnitOfMeasuresSuccess,
  props<{ payload: UnitOfMeasure }>(),
);
export const deleteUnitOfMeasureFailure = createAction(
  GeneralSettingsActionTypes._deleteUnitOfMeasuresFailure,
  props<{ payload: any }>(),
);
