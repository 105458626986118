import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-integrations-container',
  templateUrl: './integrations-container.component.html',
  styleUrls: ['./integrations-container.component.scss'],
  standalone: true,
  imports: [
    MatTabsModule,
    NgFor,
    RouterLinkActive,
    RouterLink,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class IntegrationsContainerComponent implements OnInit {
  integrationsMenu = [
    {
      text: 'Xero',
      route: 'int/xero',
    },
    // {
    //   text: 'MYOB',
    //   route: 'myob',
    // },
    // {
    //   text: 'QuickBooks',
    //   route: 'quickbooks',
    // }
  ];
  menuItems$ = of(this.integrationsMenu);
  constructor() {}

  ngOnInit(): void {
    console.log('IntegrationsContainerComponent');
  }
}
