import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArrayByElement',
  standalone: true,
})
export class FilterArrayByElementPipe implements PipeTransform {
  transform(arr: any[], key: string, value: string): any[] {
    if (value && arr) {
      arr = arr.filter((item) => item[key] !== value);
    }
    return arr;
  }
}
