<div class="absolute w-full xs:inset-0 flex flex-col h-full flex-auto min-w-0 overflow-hidden">
  <div class="flex flex-auto flex-col overflow-hidden h-full ">
    <div class="flex flex-col flex-auto h-full overflow-hidden p-4">
      <div class="flex flex-col h-fit overflow-auto">
        <div class="py-3 w-fit min-w-full flex flex-col mb-6 overflow-visible">
          <p class="text-slate-400 text-lg pb-2">Complete working estimate</p>
          <div class="hidden lg:flex flex-col flex-auto h-full overflow-hidden">
            <table class="w-full h-fit hidden overflow-hidden lg:table" [dataSource]="dataSourceCosting" mat-table>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="created">
                <th *matHeaderCellDef mat-header-cell>Created</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.created | date: "dd MMM yyyy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="unitTotal">
                <th *matHeaderCellDef mat-header-cell>
                  Unit Total
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.unitTotal | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="onCost">
                <th *matHeaderCellDef mat-header-cell>On cost %</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.onCost.toFixed(2) }}%
                </td>
              </ng-container>

              <ng-container matColumnDef="profit">
                <th *matHeaderCellDef mat-header-cell>Profit</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.profitAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="netCost">
                <th *matHeaderCellDef mat-header-cell>Net quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.netQuoteAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="gstAmount">
                <th *matHeaderCellDef mat-header-cell>GST</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.gstAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalCost">
                <th *matHeaderCellDef mat-header-cell>Quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.quoteAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="percentOrdered">
                <th *matHeaderCellDef mat-header-cell>
                  <mat-icon matTooltip="Items ordered. Go to Job Orders to view more details"
                            svgIcon="mat_outline:monetization_on"></mat-icon>
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.percentOrdered }}%
                </td>
              </ng-container>
              <ng-container matColumnDef="percentCalledFor">
                <th *matHeaderCellDef mat-header-cell>
                  <mat-icon matTooltip='Orders called for. Go to Call Forward to view more details'
                  svgIcon="mat_outline:campaign"></mat-icon>
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.percentCalledFor }}%
                </td>
              </ng-container>
              <ng-container matColumnDef="percentAcknowledged">
                <th *matHeaderCellDef mat-header-cell>
                  <mat-icon matTooltip='Called for items acknowledged by supplier'
                              svgIcon="mat_outline:connect_without_contact"></mat-icon>
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.percentAcknowledged }}%
                </td>
              </ng-container>
              <ng-container matColumnDef="percentIssue">
                <th *matHeaderCellDef mat-header-cell>
                  <mat-icon matTooltip='Call forward items with an issue'
                              svgIcon="mat_outline:error_outline"></mat-icon>
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.percentIssue }}%
                </td>
              </ng-container>
              <ng-container matColumnDef="percentCompleted">
                <th *matHeaderCellDef mat-header-cell>
                  <mat-icon matTooltip='Call forward items completed'
                              svgIcon="mat_outline:check_circle"></mat-icon>
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.percentCompleted }}%
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell>Status</th>
                <td *matCellDef="let element" mat-cell>
                  <app-job-status-pill [status]="element.status"></app-job-status-pill>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th *matHeaderCellDef mat-header-cell>Action</th>
                <td *matCellDef="let element" mat-cell>
                  <button class="text-xs rounded-xl min-h-8 h-8 px-4" [color]="'primary'"
                    (click)="completeWorkingEstimate(element)" mat-stroked-button>
                    <span class="ml-2 mr-1">Manage</span>
                  </button>
                </td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumnsCosting" mat-header-row></tr>
              <tr class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
                *matRowDef="let row; columns: displayedColumnsCosting" mat-row></tr>
            </table>
          </div>
          <div class="lg:hidden flex flex-col flex-auto xs:mb-4 overflow-hidden">
            <ng-container *ngIf="dataSourceCosting">
              <fuse-card *ngFor="let costing of dataSourceCosting.data"
                class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2">
                <div class="flex flex-col xs:w-full sm:w-1/3">
                  <div class="text-secondary font-medium text-md">
                    Name: {{ costing?.name }}
                  </div>
                  <div class="flex flex-row items-center text-secondary font-medium text-md">
                    <mat-icon class="mr-4"
                      matTooltip="Items ordered. Go to Job Orders to view more details"
                              svgIcon="mat_outline:monetization_on"></mat-icon>
                    <span>{{ costing.percentOrdered }}%</span>
                  </div>
                  <div class="flex flex-row items-center text-secondary font-medium text-md">
                    <mat-icon class="mr-4"
                              matTooltip='Orders called for. Go to Call Forward to view more details'
                              svgIcon="mat_outline:campaign"></mat-icon>
                    <span>{{ costing.percentCalledFor }}%</span>
                  </div>
                  <div class="flex flex-row items-center text-secondary font-medium text-md">
                    <mat-icon class="mr-4"
                              matTooltip='Called for items acknowledged by supplier'
                              svgIcon="mat_outline:connect_without_contact"></mat-icon>
                    <span>{{ costing.percentAcknowledged }}%</span>
                  </div>
                  <div class="flex flex-row items-center text-secondary font-medium text-md">
                    <mat-icon class="mr-4"
                              matTooltip='Call forward items with an issue'
                              svgIcon="mat_outline:error_outline"></mat-icon>
                    <span>{{ costing.percentIssue }}%</span>
                  </div>
                  <div class="flex flex-row items-center text-secondary font-medium text-md">
                    <mat-icon class="mr-4"
                              matTooltip='Call forward items completed'
                              svgIcon="mat_outline:check_circle"></mat-icon>
                    <span>{{ costing.percentCompleted }}%</span>
                  </div>

                </div>
                <div class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
                  <div class="text-md font-semibold leading-tight">
                    Created: {{ costing?.created | date: "dd MMM yyyy" }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Expires: {{ costing?.quote?.expire | date: "dd MMM yyyy" }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    On Cost: {{ costing?.onCost.toFixed(2) }}%
                  </div>
                  <div class="text-secondary font-medium text-md">
                    GST: {{ costing.gstAmount | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md mt-4">
                    Status:
                    <app-job-status-pill [status]="costing.status"></app-job-status-pill>
                  </div>
                </div>
                <div class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
                  <div class="text-md font-semibold leading-tight">
                    Profit: {{ costing.profitAmount | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Unit total: {{ costing.unitTotal | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Net quote: {{ costing.netQuoteAmount | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Quote: {{ costing.quoteAmount | currency }}
                  </div>
                </div>
                <div class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto">
                  <button class="type-action xs:border-0 xs:p-0" mat-stroked-button [color]="'primary'"
                    (click)="completeWorkingEstimate(costing)">
                    <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
                  </button>
                </div>
              </fuse-card>
            </ng-container>
          </div>
          <ng-container *ngIf="(jobCostingFacade.isLoaded$ | async) && !dataSourceCosting?.data?.length">
            <div class="flex p-3 relative">
              <span>
                There are no customer costing.
              </span>
            </div>
          </ng-container>
        </div>
        <div class="py-3 w-full flex flex-col mb-6 overflow-visible">
          <p class="text-slate-400 text-lg pb-2">Variations</p>
          <div class="hidden lg:flex flex-col flex-auto h-full overflow-hidden">
            <table class="w-full h-fit hidden overflow-hidden lg:table" [dataSource]="dataSourceVariations" mat-table>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="created">
                <th *matHeaderCellDef mat-header-cell>Created</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.created | date: "dd MMM yyyy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="unitTotal">
                <th *matHeaderCellDef mat-header-cell>
                  Unit Total
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.unitTotal | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="onCost">
                <th *matHeaderCellDef mat-header-cell>On cost %</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.onCost.toFixed(2) }}%
                </td>
              </ng-container>

              <ng-container matColumnDef="profit">
                <th *matHeaderCellDef mat-header-cell>Profit</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.profitAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="netCost">
                <th *matHeaderCellDef mat-header-cell>Net quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.netQuoteAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="gstAmount">
                <th *matHeaderCellDef mat-header-cell>GST</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.gstAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalCost">
                <th *matHeaderCellDef mat-header-cell>Quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.quoteAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell>Status</th>
                <td *matCellDef="let element" mat-cell>
                  <app-job-status-pill [status]="element.status"></app-job-status-pill>
                </td>
              </ng-container>
              <ng-container matColumnDef="expires">
                <th *matHeaderCellDef mat-header-cell>Expires</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.quote.expire | date: "dd MMM yyyy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th *matHeaderCellDef mat-header-cell>Action</th>
                <td *matCellDef="let element" mat-cell>
                  <button class="text-xs rounded-xl min-h-8 h-8 px-4" [color]="'primary'"
                    (click)="selectVariation(element)" mat-stroked-button>
                    <span class="ml-2 mr-1">{{ (jobDetailFacade.job$ | async).status ===  100 ? 'View' : 'Manage'}}</span>
                  </button>
                </td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumnsVariations" mat-header-row></tr>
              <tr class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
                *matRowDef="let row; columns: displayedColumnsVariations" mat-row></tr>
            </table>
          </div>
          <div class="lg:hidden flex flex-col flex-auto xs:mb-4 overflow-hidden">
            <ng-container *ngIf="dataSourceVariations">
              <fuse-card *ngFor="let variation of dataSourceVariations.data"
                class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2">
                <div class="flex flex-col xs:w-full sm:w-1/3">
                  <div class="text-secondary font-medium text-md">
                    Name: {{ variation?.name }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Status:
                    <app-job-status-pill [status]="variation.status"></app-job-status-pill>
                  </div>
                </div>

                <div class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
                  <div class="text-md font-semibold leading-tight">
                    Created: {{ variation?.created | date: "dd MMM yyyy" }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Expires: {{ variation?.quote?.expire | date: "dd MMM yyyy" }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    On Cost: {{ variation?.onCost.toFixed(2) }}%
                  </div>
                  <div class="text-secondary font-medium text-md">
                    GST: {{ variation.gstAmount | currency }}
                  </div>
                </div>

                <div class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
                  <div class="text-md font-semibold leading-tight">
                    Profit: {{ variation.profitAmount | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Unit total: {{ variation.unitTotal | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Net quote: {{ variation.netQuoteAmount | currency }}
                  </div>

                  <div class="text-secondary font-medium text-md">
                    Quote: {{ variation.quoteAmount | currency }}
                  </div>
                </div>

                <div class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto">
                  <button class="type-action xs:border-0 xs:p-0" mat-stroked-button [color]="'primary'"
                    (click)="selectVariation(variation)">
                    <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
                  </button>
                </div>
              </fuse-card>
            </ng-container>
          </div>
          <ng-container *ngIf="(jobCostingFacade.isLoaded$ | async) && !dataSourceVariations?.data?.length">
            <div class="flex p-3 relative">
              <span>
                There are no customer variations.
              </span>
            </div>
          </ng-container>
          <ng-template #nodata></ng-template>
        </div>
        <div class="py-3 w-full flex flex-col mb-6 overflow-visible">
          <p class="text-slate-400 text-lg pb-2">History</p>
          <div class="hidden lg:flex flex-col flex-auto h-full overflow-hidden">
            <table class="w-full h-fit hidden overflow-hidden lg:table" [dataSource]="dataSourceHistory" mat-table>
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell>Name</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="created">
                <th *matHeaderCellDef mat-header-cell>Created</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.created | date: "dd MMM yyyy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="unitTotal">
                <th *matHeaderCellDef mat-header-cell>
                  Unit Total
                </th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.unitTotal | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="onCost">
                <th *matHeaderCellDef mat-header-cell>On cost %</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.onCost.toFixed(2) }}%
                </td>
              </ng-container>

              <ng-container matColumnDef="profit">
                <th *matHeaderCellDef mat-header-cell>Profit</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.profitAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="netCost">
                <th *matHeaderCellDef mat-header-cell>Net quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.netQuoteAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="gstAmount">
                <th *matHeaderCellDef mat-header-cell>GST</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.gstAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalCost">
                <th *matHeaderCellDef mat-header-cell>Quote</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.quoteAmount | currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell>Status</th>
                <td *matCellDef="let element" mat-cell>
                  <app-job-status-pill [status]="element.status"></app-job-status-pill>
                </td>
              </ng-container>
              <ng-container matColumnDef="expires">
                <th *matHeaderCellDef mat-header-cell>Expires</th>
                <td *matCellDef="let element" mat-cell>
                  {{ element.quote.expire | date: "dd MMM yyyy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th *matHeaderCellDef mat-header-cell>Action</th>
                <td *matCellDef="let element" mat-cell>
                  <button class="text-xs rounded-xl min-h-8 h-8 px-4" [color]="'primary'" mat-stroked-button
                  (click)="selectJobHistory(element)">
                    <span class="ml-2 mr-1">View</span>
                  </button>
                </td>
              </ng-container>
              <tr *matHeaderRowDef="displayedColumnsHistory" mat-header-row></tr>
              <tr class="hover:bg-gray-200 dark:hover:bg-slate-800 cursor-pointer"
                *matRowDef="let row; columns: displayedColumnsHistory" mat-row></tr>
            </table>
          </div>
          <div class="lg:hidden flex flex-col flex-auto xs:mb-4 overflow-hidden">
            <ng-container *ngIf="dataSourceHistory">
              <fuse-card *ngFor="let history of dataSourceHistory.data"
                class="flex xs:flex-col sm:flex-row flex-shrink-0 w-full p-4 mb-2">
                <div class="flex flex-col xs:w-full sm:w-1/3">
                  <div class="text-secondary font-medium text-md">
                    Name: {{ history?.name }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Status:
                    <app-job-status-pill [status]="history.status"></app-job-status-pill>
                  </div>
                </div>
                <div class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
                  <div class="text-md font-semibold leading-tight">
                    Created: {{ history?.created | date: "dd MMM yyyy" }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Expires: {{ history?.quote?.expire | date: "dd MMM yyyy" }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    On Cost: {{ history?.onCost.toFixed(2) }}%
                  </div>
                  <div class="text-secondary font-medium text-md">
                    GST: {{ history.gstAmount | currency }}
                  </div>
                </div>

                <div class="flex flex-col xs:w-full sm:w-1/5 sm:mt-0 sm:ml-8 xs:ml-0 xs:mt-2">
                  <div class="text-md font-semibold leading-tight">
                    Profit: {{ history.profitAmount | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Unit total: {{ history.unitTotal | currency }}
                  </div>
                  <div class="text-secondary font-medium text-md">
                    Net quote: {{ history.netQuoteAmount | currency }}
                  </div>

                  <div class="text-secondary font-medium text-md">
                    Quote: {{ history.quoteAmount | currency }}
                  </div>
                </div>

                <div class="flex xs:w-full sm:w-1/6 justify-end items-center xs:ml-auto">
                  <button class="type-action xs:border-0 xs:p-0" mat-stroked-button [color]="'primary'"
                    (click)="selectJobHistory(history)">
                    <mat-icon matPrefix [svgIcon]="'settings'"></mat-icon>
                  </button>
                </div>
              </fuse-card>
            </ng-container>
          </div>
          <ng-container *ngIf="(jobCostingFacade.isLoaded$ | async) && !dataSourceHistory?.data?.length">
            <div class="flex p-3 relative">
              <span>
                There are no customer history
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
