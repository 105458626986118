import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  // Util functions must be here
  public static transformAddress(formValue: any): any {
    const value = JSON.parse(JSON.stringify(formValue));
    value['locationType'] = 'Address';
    return value;
  }

  public static requireCheckboxesToBeCheckedValidator(
    minRequired = 1,
  ): ValidatorFn {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    return function validate(formGroup: FormGroup): {
      requireCheckboxesToBeChecked: boolean;
    } {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key: string) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });
      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }

      return null;
    };
  }

  public static convertEnumToObject(
    enumObj: any,
  ): { text: string; value: string }[] {
    return Object.keys(enumObj)
      .filter((key) => !parseInt(key, 10))
      .map((r: string) => ({
        text: r,
        value: enumObj[r],
      }));
  }
}
