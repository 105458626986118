import { createAction, props } from '@ngrx/store';

export enum SupplierPriceListItemActionTypes {
  GetSupplierPriceListItem = '[SupplierPriceListItem] Get SupplierPriceListItem',
  GetSupplierPriceListItemSuccess = '[SupplierPriceListItem API] Get SupplierPriceListItem Success',
  GetSupplierPriceListItemFailure = '[SupplierPriceListItem API] Get SupplierPriceListItem Failure',

  AddSupplierPriceItem = '[SupplierPriceListItem] Add Supplier',
  AddSupplierPriceItemSuccess = '[SupplierPriceListItem API] Add Supplier Success',
  AddSupplierPriceItemFailure = '[SupplierPriceListItem API] Add Supplier Failure',
}

export const getSupplierPriceListItem = createAction(
  SupplierPriceListItemActionTypes.GetSupplierPriceListItem,
  props<{ query: any }>(),
);
export const getSupplierPriceListItemSuccess = createAction(
  SupplierPriceListItemActionTypes.GetSupplierPriceListItemSuccess,
  props<{ payload: any }>(),
);
export const getSupplierPriceListItemFailure = createAction(
  SupplierPriceListItemActionTypes.GetSupplierPriceListItemFailure,
  props<{ payload: any }>(),
);

export const addSupplierPriceItem = createAction(
  SupplierPriceListItemActionTypes.AddSupplierPriceItem,
  props<{ query: any }>(),
);
export const addSupplierPriceItemSuccess = createAction(
  SupplierPriceListItemActionTypes.AddSupplierPriceItemSuccess,
  props<{ payload: any }>(),
);
export const addSupplierPriceItemFailure = createAction(
  SupplierPriceListItemActionTypes.AddSupplierPriceItemFailure,
  props<{ payload: any }>(),
);
