<div
  *ngIf="text"
  class="uppercase py-0.5 px-3 inline-block whitespace-no-wrap rounded text-sm font-semibold"
  [ngClass]="{
    'text-gray-800 bg-gray-100 dark:text-gray-50 dark:bg-blue-500':
      status === 10,
    'text-yellow-800 bg-yellow-100 dark:text-yellow-50 dark:bg-yellow-500':
      status === 20,
    'text-green-800 bg-green-100 dark:text-green-50 dark:bg-green-500':
      status === 30,
    'text-red-800 bg-red-100 dark:text-red-50 dark:bg-red-500': status === 40
  }"
>
  {{ text }}
</div>
