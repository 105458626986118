/* eslint-disable arrow-parens */
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { SearchArrayItemPipe } from 'app/shared/pipes/filter-array.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FuseScrollbarDirective } from '../../../../@fuse/directives/scrollbar/scrollbar.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-create-supplier-order',
  templateUrl: './create-supplier-order.component.html',
  styleUrls: ['./create-supplier-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    NgFor,
    MatIconModule,
    MatInputModule,
    FuseScrollbarDirective,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgIf,
  ],
})
export class CreateSupplierOrderComponent implements AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  dataSource;
  displayedColumns = [
    'select',
    'priceListCategoryName',
    'itemCode',
    'supplierItemCode',
    'description',
    'qty',
    'unitOfMeasure',
    'unitPrice',
    'unitTotal',
  ];
  selection = new SelectionModel(true, []);

  filterForm = this.fb.group({
    supplier: 0,
    category: 0,
    search: '',
  });

  supplierOptions = [];
  categoryOptions = [];

  constructor(
    public dialogRef: MatDialogRef<CreateSupplierOrderComponent>,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private searchArrayItemPipe: SearchArrayItemPipe,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  get supplier(): any {
    return this.filterForm.get('supplier');
  }
  get category(): any {
    return this.filterForm.get('category');
  }
  get search(): any {
    return this.filterForm.get('search');
  }

  ngAfterViewInit(): void {
    const supplierOptionsObj = {};
    const categoryOptionsObj = {};
    this.data?.forEach((element) => {
      if (!supplierOptionsObj[element.supplierId]) {
        supplierOptionsObj[element.supplierId] = element.supplier;
      }
      if (!categoryOptionsObj[element.priceListCategoryName]) {
        categoryOptionsObj[element.priceListCategoryName] =
          element.priceListCategoryName;
      }
    });
    this.supplierOptions = Object.keys(supplierOptionsObj).map(
      (key) => supplierOptionsObj[key],
    );
    this.categoryOptions = Object.keys(categoryOptionsObj).map(
      (key) => categoryOptionsObj[key],
    );

    this.filterForm.valueChanges.subscribe((values) => {
      let data = this.searchArrayItemPipe.transform(
        this.data,
        'supplierId',
        values.supplier.toString(),
      );
      data = this.searchArrayItemPipe.transform(
        data,
        'priceListCategoryName',
        values.category.toString(),
      );
      data = this.searchArrayItemPipe.transform(
        data,
        'description',
        values.search,
      );
      this.selection.clear();
      this.dataUpdate(data);
    });
    this.dataUpdate(this.data);
  }

  dataUpdate(data): void {
    if (data) {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.cdRef.detectChanges();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close(this.selection.selected);
  }
}
