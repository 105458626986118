import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { JobState, selectJobState } from '../reducers';

export const selectJobsOrderDetailsState = createSelector(
  selectJobState,
  (s: JobState) => s.jobOrder,
);

export const selectJobsOrderDetailsIsLoading = createSelector(
  selectJobsOrderDetailsState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobsOrderDetailsIsLoaded = createSelector(
  selectJobsOrderDetailsState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobsOrderDetailsError = createSelector(
  selectJobsOrderDetailsState,
  (s) => getError(s.callState),
);

export const selectJobsOrderDetails = createSelector(
  selectJobsOrderDetailsState,
  (s) => s.jobOrder,
);

export const selectJobsOrderItemsDetails = createSelector(
  selectJobsOrderDetailsState,
  (s) => s.jobOrder.orderItems,
);
