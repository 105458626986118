import { createSelector } from '@ngrx/store';
import { getError, LoadingState } from 'app/store';
import { selectJobState } from '../reducers';

export const selectJobVariationsListState = createSelector(
  selectJobState,
  (s) => s.variations,
);

export const selectJobVariationsListIsLoading = createSelector(
  selectJobVariationsListState,
  (s) => s.callState === LoadingState.LOADING,
);

export const selectJobVariationsListIsLoaded = createSelector(
  selectJobVariationsListState,
  (s) => s.callState === LoadingState.LOADED,
);

export const selectJobVariationsListError = createSelector(
  selectJobVariationsListState,
  (s) => getError(s.callState),
);

export const selectJobVariationsList = createSelector(
  selectJobVariationsListState,
  (s) => s.variations.items,
);

export const selectJobVariationsListTotalCount = createSelector(
  selectJobVariationsListState,
  (s) => s.variations.pageMetaData?.records,
);

export const selectJobVariationsListPageNumber = createSelector(
  selectJobVariationsListState,
  (s) => s.variations.pageMetaData?.pageNumber,
);
export const selectJobCostingSummary = createSelector(
  selectJobVariationsListState,
  (s) => s.costingSummary,
);
