import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AddGuidedTour,
  GuidedTour,
  GuidedTourParameters,
  PaginatedListOfGuidedTour,
} from 'app/shared/models/eazimate.models';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class GuidedTourService {
  constructor(private http: HttpClient) {}

  getGuidedTourList(
    request: GuidedTourParameters,
  ): Observable<PaginatedListOfGuidedTour> {
    const url = `${environment.urls.api}/guidedtour/list`;
    return this.http.post<PaginatedListOfGuidedTour>(url, request);
  }

  addGuidedTour(request: AddGuidedTour): Observable<GuidedTour> {
    const url = `${environment.urls.api}/guidedtour/add`;
    return this.http.post<GuidedTour>(url, request);
  }

  completeGuidedTour(guidedTourName: string): Observable<GuidedTour> {
    console.log(guidedTourName);

    const url = `${environment.urls.api}/guidedtour/complete/${guidedTourName}`;
    return this.http.put<GuidedTour>(url, null);
  }

  resetGuidedTour(guidedTourName: string): Observable<GuidedTour> {
    const url = `${environment.urls.api}/guidedtour/reset/${guidedTourName}`;
    return this.http.put<GuidedTour>(url, null);
  }

  getGuidedTourShow(guidedTourName: string): Observable<boolean> {
    const url = `${environment.urls.api}/guidedtour/show/${guidedTourName}`;
    return this.http.get<boolean>(url);
  }

  deleteGuidedTour(id: string): Observable<GuidedTour> {
    const url = `${environment.urls.api}/guidedtour/${id}`;
    return this.http.delete<GuidedTour>(url);
  }
}
