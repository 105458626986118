<div
  class="flex flex-row gap-x-3 p-2 mt-4 rounded border border-gray-300 dark:border-gray-500"
  style="width: 45rem"
  *ngIf="!!totals"
>
  <div class="flex flex-col w-1/5">
    <div class="">Unit total</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      {{ totals.unitTotal | currency: "" : "symbol" : "1.0-0" }}
    </div>
  </div>
  <div class="flex flex-col w-1/5">
    <div class="">On cost %</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      {{ totals.oncost || 10 }}%
    </div>
  </div>
  <div class="flex flex-col w-1/5">
    <div class="">Profit</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      {{ totals.profitAmount | currency: "" : "symbol" : "1.0-0" }}
    </div>
  </div>
  <div class="flex flex-col w-1/5">
    <div class="">Net quote</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      {{ totals.netQuoteAmount | currency: "" : "symbol" : "1.0-0" }}
    </div>
  </div>
  <div class="flex flex-col w-1/5">
    <div class="">Customer quote</div>
    <div
      class="py-2 pr-2 rounded bg-primary-100 dark:bg-primary-900 mt-0.5 flex justify-end"
    >
      {{ totals.quoteAmount | currency: "" : "symbol" : "1.0-0" }}
    </div>
  </div>
</div>
