import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeModule,
} from '@angular/material/tree';
import { StorageSource } from 'app/shared/models';
import {
  AttachmentFolder,
  AttachmentJobFile,
  AttachmentJobPhoto,
  AttachmentModalData,
  AttachmentModalDataResponse,
} from 'app/shared/models/form-ui.models';
import { NgxFilesizeModule } from 'ngx-filesize';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

interface AttachmentNode {
  name: string;
  path: string;
  type: string;
  children?: AttachmentNode[];
}
interface FolderNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogClose,
    MatIconModule,
    NgIf,
    MatTreeModule,
    NgClass,
    NgFor,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    NgxFilesizeModule,
  ],
})
export class AttachmentModalComponent implements OnInit {
  displayedColumns: string[] = ['name', 'size'];
  dataSourceTable = new MatTableDataSource([]);

  data = [];
  dataIntermediate = {};

  private _transformer = (node: AttachmentNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      path: node.path,
      type: node.type,
    };
  };

  treeControlPhotos = new FlatTreeControl<FolderNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  selectedFolder;
  selectedItems;

  dataSource = new MatTreeFlatDataSource(
    this.treeControlPhotos,
    this.treeFlattener,
  );

  storageSource = StorageSource;
  modalDataLocal: AttachmentModalData;
  attachments: AttachmentModalDataResponse;

  constructor(
    public dialogRef: MatDialogRef<AttachmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: AttachmentModalData,
  ) {
    this.modalDataLocal = JSON.parse(JSON.stringify(modalData));
  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.modalData.photosFolders = this.modalData.photosFolders.map((f) => {
      f.type = StorageSource.JobPhoto;
      return f;
    });
    this.modalData.filesFolders = this.modalData.filesFolders.map((f) => {
      f.type = StorageSource.JobFile;
      return f;
    });

    if (this.modalData.photosFolders.length) {
      let photosFolder = this.modalData.photosFolders.find(
        (f) => f.path === 'Home\\',
      );
      photosFolder.name = 'Photos';
      this.data.push(photosFolder);
      this.data[0].children = this.formatingData(
        this.data,
        'Home\\',
        this.modalData.photosFolders,
      );
    }
    if (this.modalData.filesFolders.length) {
      let filesFolder = this.modalData.filesFolders.find(
        (f) => f.path === 'Home\\',
      );
      filesFolder.name = 'Files';
      this.data.push(filesFolder);
      this.data[!this.modalData.photosFolders.length ? 0 : 1].children =
        this.formatingData(this.data, 'Home\\', this.modalData.filesFolders);
    }

    this.dataSource.data = this.data;

    this.selectNode(this.treeControlPhotos.dataNodes[0]);
    this.attachments = {
      photos: this.modalDataLocal.photos.filter((photo) => photo.selected),
      files: this.modalDataLocal.files.filter((file) => file.selected),
    };
  }

  formatingData(
    data: AttachmentFolder[],
    path: string,
    folders: AttachmentFolder[],
  ): AttachmentFolder[] {
    data = folders.filter(
      (f) =>
        f.path.startsWith(path) &&
        f.path.split('\\').length === path.split('\\').length + 1,
    );
    data.forEach((item) => {
      if (item.folderCount) {
        item.children = this.formatingData(item.children, item.path, folders);
      }
    });
    return data;
  }

  hasChild = (_: number, node: FolderNode) => node.expandable;

  toggleSelected(
    item: AttachmentJobFile | AttachmentJobPhoto,
    storageSource: StorageSource,
  ): void {
    switch (storageSource) {
      case StorageSource.JobFile:
        this.modalDataLocal.files.forEach((file) => {
          if (file.id === item.id) {
            file.selected = !item.selected;
          }
        });
        break;
      case StorageSource.JobPhoto:
        this.modalDataLocal.photos.forEach((photo) => {
          if (photo.id === item.id) {
            photo.selected = !item.selected;
          }
        });
        break;
    }
    this.attachments = {
      photos: this.modalDataLocal.photos.filter((photo) => photo.selected),
      files: this.modalDataLocal.files.filter((file) => file.selected),
    };
  }

  selectNode(folder): void {
    if (this.selectedFolder !== folder) {
      this.selectedFolder = folder;
      this.getElements(folder);
    }
  }

  getElements(folder: AttachmentFolder): void {
    switch (folder.type) {
      case StorageSource.JobPhoto:
        this.selectedItems = this.modalDataLocal.photos.filter(
          (photo) => photo.path === folder.path && photo.name,
        );
        break;
      case StorageSource.JobFile:
        this.selectedItems = this.modalDataLocal.files.filter(
          (file) => file.path === folder.path && file.name,
        );
        this.dataSourceTable = new MatTableDataSource(this.selectedItems);
        this.dataSourceTable.sort = this.sort;
        break;
    }
  }

  select(): void {
    if (!this.attachments?.files?.length && !this.attachments?.photos?.length) {
      return;
    }
    this.dialogRef.close(this.attachments);
  }
}
