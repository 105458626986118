<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>
  <!-- Content -->
  <div
    class="flex flex-col sm:flex-row flex-auto w-full items-center sm:items-start p-8 pb-6 sm:pb-8"
  >
    <div
      class="flex flex-col w-full items-center sm:items-start mt-4 sm:mt-0 space-y-1 text-center sm:text-left"
    >
      <!-- Title -->
      <div class="text-xl leading-6 font-medium mb-4">Add to Invoice</div>

      <form
        class="w-full"
        [formGroup]="editForm"
        novalidate
        (ngSubmit)="(save)"
      >
        <mat-form-field
          class="fuse-mat-no-subscript w-full mb-4"
          floatLabel="always"
        >
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>
        <mat-form-field
          class="fuse-mat-no-subscript w-full"
          floatLabel="always"
        >
          <mat-label
            >Enter
            {{
              data.type === "Percentage amount"
                ? "percentage of total"
                : data.type === "Credit amount"
                  ? "credit amount"
                  : "fixed amount"
            }}</mat-label
          >
          <input
            matInput
            formControlName="percentage"
            numbersOnly
            type="number"
            min="0.1"
          />
        </mat-form-field>
        <mat-checkbox
          *ngIf="data.type === 'Credit amount'"
          formControlName="taxInclusive"
          [color]="'primary'"
        >
          Tax inclusive
        </mat-checkbox>
      </form>
      <div *ngIf="newAmount" class="text font-medium">
        Invoice amount {{ newAmount | currency }}
      </div>
    </div>
  </div>

  <!-- Actions -->
  <div
    class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <!-- Cancel -->
    <button mat-stroked-button [matDialogClose]="undefined">Cancel</button>

    <!-- Save -->
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="!editForm.valid"
      (click)="save()"
    >
      Save
    </button>
  </div>
</div>
