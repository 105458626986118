import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SendQuote,
  FullVariationQuote,
  VariationSection,
  Variation,
} from 'app/shared/models';
import { map, Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class VariationLetterService {
  constructor(private http: HttpClient) {}

  getVariationLetterQuote(
    variationId: string,
  ): Observable<FullVariationQuote> {
    const url = `${environment.urls.api}/variation/${variationId}/quote`;
    return this.http.get<FullVariationQuote>(url);
  }

  updateVariationLetter(variation: Variation): Observable<Variation> {
    const url = `${environment.urls.api}/variation/save`;
    return this.http.put<Variation>(url, variation);
  }

  sendQuote(sendQuote: SendQuote): Observable<any> {
    const url = `${environment.urls.api}/variation/sendemail`;
    return this.http.post<any>(url, sendQuote);
  }

  previewVariationLetter(id: string): Observable<any> {
    const url = `${environment.urls.api}/variation/export/pdf/${id}`;
    return this.http.get(url, { observe: 'response' }).pipe(
      map((response: any) => {
        return response.body;
      }),
    );
  }

  quoteDocumentSection(
    estimateId: string,
    variationId: string,
  ): Observable<VariationSection[]> {
    const url = `${environment.urls.api}/quotedocumentsection/${estimateId}/${variationId}`;
    return this.http.get<VariationSection[]>(url);
  }

  updateQuoteDocumentSections(
    quote: VariationSection[],
  ): Observable<VariationSection[]> {
    const url = `${environment.urls.api}/quotedocumentsection/save`;
    return this.http.put<VariationSection[]>(url, quote);
  }
}
