import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Estimate,
  EstimateParameters,
  EstimatePriceListItem,
  FullEstimate,
  FullQuote,
  PaginatedListOfEstimate,
  SendQuote,
  SingleSaveEstimate,
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EstimateService {
  constructor(private http: HttpClient) {}

  save(estimate: SingleSaveEstimate): Observable<SingleSaveEstimate> {
    const url = `${environment.urls.api}/estimate/single`;
    return this.http.put<SingleSaveEstimate>(url, estimate);
  }

  updateProfit(id: string, profit: number): Observable<FullEstimate> {
    const url = `${environment.urls.api}/estimate/profit`;
    return this.http.post<FullEstimate>(url, { estimateId: id, value: profit });
  }

  updateOnCost(id: string, onCost: number): Observable<FullEstimate> {
    const url = `${environment.urls.api}/estimate/oncost`;
    return this.http.post<FullEstimate>(url, { estimateId: id, value: onCost });
  }

  addSection(id: string, name: string): Observable<any> {
    const url = `${environment.urls.api}/estimatesection/add`;
    var section = <any>{ estimateId: id, name };
    return this.http.post<any>(url, section);
  }

  renameSection(
    id: string,
    name: string,
  ): Observable<{ id: string; name: string }> {
    const url = `${environment.urls.api}/estimatesection/name`;
    return this.http.post<{ id: string; name: string }>(url, { id, name });
  }

  moveEstimateSectionUp(
    id: string,
  ): Observable<[{ id: string; order: number }]> {
    const url = `${environment.urls.api}/estimatesection/move-up/${id}`;
    return this.http.post<[{ id: string; order: number }]>(url, {});
  }

  moveEstimateSectionDown(
    id: string,
  ): Observable<[{ id: string; order: number }]> {
    const url = `${environment.urls.api}/estimatesection/move-down/${id}`;
    return this.http.post<[{ id: string; order: number }]>(url, {});
  }

  updateEstimateSectionPriceListItems(
    request: any,
  ): Observable<any> {
    const url = `${environment.urls.api}/estimatesection/modify`;
    return this.http.post<any>(url, request);
  }

  updateEstimatePriceListItem(
    item: EstimatePriceListItem,
  ): Observable<EstimatePriceListItem> {
    const url = `${environment.urls.api}/estimatepricelistitem/save`;
    return this.http.put<EstimatePriceListItem>(url, item);
  }

  deleteEstimatePriceListItem(id: string): Observable<EstimatePriceListItem> {
    const url = `${environment.urls.api}/estimatepricelistitem/${id}`;
    return this.http.delete<EstimatePriceListItem>(url);
  }

  deleteEstimateSection(id: string): Observable<any> {
    const url = `${environment.urls.api}/estimatesection/${id}`;
    return this.http.delete<any>(url);
  }

  updateEstimateSection(
    estimateSection: any,
  ): Observable<any> {
    const url = `${environment.urls.api}/estimatesection/save`;
    return this.http.put<any>(url, estimateSection);
  }

  getSingleSaveEstimate(id: string): Observable<SingleSaveEstimate> {
    const url = `${environment.urls.api}/estimate/${id}/single`;
    return this.http.get(url);
  }

  updateEstimate(estimate: Estimate): Observable<Estimate> {
    const url = `${environment.urls.api}/estimate/save`;
    return this.http.put<Estimate>(url, estimate);
  }

  getCurrentEstimates(
    request: EstimateParameters,
  ): Observable<PaginatedListOfEstimate> {
    console.log('Estimate service getEstimates');
    const url = `${environment.urls.api}/estimate/list/current`;
    return this.http.post<PaginatedListOfEstimate>(url, request);
  }

  getCurrentEstimatesHistory(
    request: EstimateParameters,
  ): Observable<PaginatedListOfEstimate> {
    console.log('Estimate service getEstimatesHistory');
    const url = `${environment.urls.api}/estimate/list/history`;
    return this.http.post<PaginatedListOfEstimate>(url, request);
  }

  getEstimates(
    request: EstimateParameters,
  ): Observable<PaginatedListOfEstimate> {
    const url = `${environment.urls.api}/estimate/list`;
    return this.http.post<PaginatedListOfEstimate>(url, request);
  }

  getEstimateQuote(estimateid: string): Observable<FullQuote> {
    const url = `${environment.urls.api}/estimate/${estimateid}/quote`;
    return this.http.get<FullQuote>(url);
  }

  cloneEstimate(id: string): Observable<Estimate> {
    const url = `${environment.urls.api}/estimate/clone`;
    return this.http.post<Estimate>(url, { estimateId: id });
  }

  acceptEstimate(id: string): Observable<Estimate> {
    const url = `${environment.urls.api}/estimate/${id}/status/accepted`;
    return this.http.post<Estimate>(url, {});
  }

  sendQuote(sendQuote: SendQuote): Observable<Estimate> {
    const url = `${environment.urls.api}/estimate/sendemail`;
    return this.http.post<Estimate>(url, sendQuote);
  }

  quoteDocumentSection(estimateid: string): Observable<any> {
    const url = `${environment.urls.api}/quotedocumentsection/${estimateid}/${estimateid}`;
    return this.http.get<any>(url);
  }

  updateQuoteDocumentSections(quote: any): Observable<any> {
    const url = `${environment.urls.api}/quotedocumentsection/save`;
    return this.http.put<any>(url, quote);
  }
}
