<div class="mb-2.5" [ngClass]="data?.titleClass">
  {{ data?.title }}
</div>
<div [formGroup]="form">
  <app-quill-editor-element
    formControlName="content"
    [data]="data"
  ></app-quill-editor-element>

  <div class="buttons-group flex flex-row justify-end mt-4">
    <button class="ml-2" mat-stroked-button (click)="cancel()">Cancel</button>
    <button
      class="xs:text-sm ml-2"
      mat-flat-button
      [color]="'primary'"
      (click)="save()"
      [disabled]="
        !(form.valid && form.dirty && content.value !== data?.content)
      "
    >
      Save
    </button>
  </div>
</div>
