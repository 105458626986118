import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ProfileModel } from 'app/shared/models/profile-settings.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountUserFacade } from 'app/shared/store/facades/account-user.facade';
import { emptyStringValidator } from 'app/shared/form-validators';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PHONE_PATTERN } from '@shared/constants/constants';
@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {
  public profileForm: FormGroup;
  public securityForm: FormGroup;
  phonePattern = PHONE_PATTERN;
  unsubscriber$ = new Subject<void>();

  isReadOnly =
    this.async
      .transform(this.facade.activeAccount$)
      .roles.filter(
        (r) =>
          r.name.toLocaleLowerCase() === 'settingscompanyinfo' &&
          r.accessLevel === 0,
      ).length === 1;

  constructor(
    private fb: FormBuilder,
    public facade: AccountUserFacade,
    private async: AsyncPipe,
  ) {
    this.profileForm = this.fb.group({
      firstName: [
        { value: '', disabled: this.isReadOnly },
        [Validators.required, emptyStringValidator()],
      ],
      lastName: [
        { value: '', disabled: this.isReadOnly },
        [Validators.required, emptyStringValidator()],
      ],
      email: [
        { value: '', disabled: this.isReadOnly },
        [Validators.required, Validators.email, emptyStringValidator()],
      ],
      phone: [{ value: '', disabled: this.isReadOnly }, Validators.required],
    });
  }

  saveProfile(): void {
    const profile = this.profileForm.value as ProfileModel;
    this.facade.saveProfileSettingsForm(profile);
  }

  ngOnInit(): void {
    this.facade.profile$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((settings: ProfileModel) => {
        if (settings) {
          this.profileForm.patchValue(settings);
        }
      });

    this.facade.profileSaved$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        this.profileForm.markAsPristine();
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
