<div
    class="flex cursor-pointer flex-row rounded-full border border-gray-300 bg-gray-300 dark:border-gray-700 dark:bg-gray-700"
    (click)="toggle()"
>
    <ng-container *ngIf="mode === 'dark'">
        <span class="h-6 w-6 rounded-full bg-gray-400"></span>
        <mat-icon
            class="ml-2 mr-1 text-yellow-300"
            [svgIcon]="'heroicons_outline:sun'"
        ></mat-icon>
    </ng-container>
    <ng-container *ngIf="mode === 'light'">
        <mat-icon
            class="ml-1 mr-2 text-yellow-500"
            [svgIcon]="'heroicons_outline:moon'"
        ></mat-icon>
        <span class="h-6 w-6 rounded-full bg-gray-400"></span>
    </ng-container>
</div>
