/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function abnValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return;
    }
    const value = (control.value as string).replace(/\s/g, '');

    let isValid = false;

    if (/\d{11}/.test(value)) {
      let sum = 0;

      sum += 10 * (parseInt(value[0], 10) - 1);
      let weight = 1;

      for (let i = 1; i < 11; i++) {
        sum += weight * parseInt(value[i], 10);
        weight += 2;
      }

      isValid = sum % 89 === 0;
    }

    return isValid ? null : { abn: { value: control.value } };
  };
}

export function abnOptionalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return;
    }
    const value = (control.value as string).replace(/\s/g, '');

    let isValid = false;

    if (/\d{11}/.test(value)) {
      let sum = 0;

      sum += 10 * (parseInt(value[0], 10) - 1);
      let weight = 1;

      for (let i = 1; i < 11; i++) {
        sum += weight * parseInt(value[i], 10);
        weight += 2;
      }

      isValid = sum % 89 === 0;
    }

    return isValid || control.value === ''
      ? null
      : { abn: { value: control.value } };
  };
}
