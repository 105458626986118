<div
  class="absolute inset-0 flex flex-col min-w-0 overflow-x-hidden overflow-y-auto"
>
  <div class="flex-auto h-full bg-card dark:bg-transparent">
    <div class="flex flex-col h-full bg-gray-100 dark:bg-transparent">
      <div class="flex flex-row flex-wrap pb-4  border-t mt-2">
        <div class="flex flex-row flex-wrap mr-12">
          <nav mat-tab-nav-bar [tabPanel]="tabPanel">
            <a
              #rla="routerLinkActive"
              *ngFor="let link of menuItems"
              [routerLink]="'./' + link.route"
              [queryParams]="{isReadOnly: isReadOnlyMode ? true : null}"
              [active]="rla.isActive"
              mat-tab-link
              routerLinkActive
              [routerLinkActiveOptions]="{
                paths: 'subset',
                fragment: 'ignored',
                matrixParams: 'ignored',
                queryParams: 'ignored',
              }"
            >
              {{ link.text }}
            </a>
          </nav>
        </div>

        <div class="flex flex-row flex-grow justify-end items-center mr-4" *ngIf="isCostingPage && (jobDetailFacade.job$ | async)?.status !== 100">
          <!-- Reset to Quoted -->
          <button
            class="mr-4 text-xs rounded-xl min-h-8 h-8 px-4"
            *ngIf="canResetVariationToQuoted$ | async"
            [color]="'primary'"
            (click)="resetVariationToQuotedStatus()"
            mat-stroked-button
          >
                <span class="xs:hidden sm:block ml-2 mr-1">
                  Reset Variation to Quoted
                </span>
          </button>

          <ng-container>
            <!-- Variation status bar -->
            <div class="xs:mr-2 sm:mr-4">
              <app-status-toggle
                *ngIf="variationFacade.status$ | async"
                [statuses]="variationStatusesList"
                [selected]="variationFacade.status$ | async"
                (select)="updateVariationStatus($event)"
              ></app-status-toggle>
            </div>
            <!-- Variation Add New Section button  -->
            <button
              class="xs:mr-2 sm:mr-4"
              class="order-5 xs:mr-2 sm:mr-4 text-xs rounded-xl min-h-8 h-8 px-4"
              id="variation-add-section-button"
              *ngIf="
                      !(isReadOnlyMode || (variationFacade.isOriginal$ | async))
                    "
              [disabled]="(variationFacade.isLoaded$ | async) === false || (variationFacade.readonly$ | async)"
              (click)="addVariationSection()"
              mat-flat-button
              color="primary"
            >
              <mat-icon [svgIcon]="'heroicons_outline:plus-small'"></mat-icon>
              <span class="xs:hidden sm:block ml-2 mr-1"
              >Add Variation Item</span
              >
            </button>
          </ng-container>
        </div>

      </div>
      <mat-tab-nav-panel class="h-full" #tabPanel>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
</div>
