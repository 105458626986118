import { EventEmitter, Injectable } from '@angular/core';
import { FuseConfirmationConfig } from '@fuse/services/confirmation';

class State {
  dialog?: FuseConfirmationConfig;
  needSave = false;
}

@Injectable({
  providedIn: 'root',
})
export class LeavePageService {
  dialogResult = new EventEmitter<boolean>();
  private state = new State();

  constructor() {
    this.state.dialog = {
      title: 'Leave Page?',
      message: 'You have unsaved changes.',
      icon: {
        name: 'heroicons_outline:exclamation-triangle',
        color: 'warn',
      },
      actions: {
        cancel: { label: 'Cancel' },
        confirm: { label: 'Leave Page', color: 'warn' },
      },
    };
  }

  get needSave() {
    return this.state.needSave;
  }

  set needSave(value: boolean) {
    this.state.needSave = value;
  }

  get dialog() {
    return this.state.dialog;
  }

  set dialog(value: FuseConfirmationConfig) {
    this.state.dialog = {
      ...value,
    };
  }
}
