import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Estimate, EstimateParameters, FullQuote } from 'app/shared/models';
import {
  getEstimateQuote,
  getQuoteDocumentSection,
  selectEstimateQuote,
  selectEstimateQuoteError,
  selectEstimateQuoteIsLoaded,
  selectEstimateQuoteIsLoading,
  selectQuoteDocumentSections,
  updateEstimateQuote,
  updateQuoteDocumentSection,
} from '..';
import { EstimateQuoteState } from '../reducers/estimate-quote.reducer';

@Injectable({
  providedIn: 'root',
})
export class EstimateQuoteFacade {
  isLoading$ = this.store.select(selectEstimateQuoteIsLoading);
  isLoaded$ = this.store.select(selectEstimateQuoteIsLoaded);
  error$ = this.store.select(selectEstimateQuoteError);
  estimateFull$ = this.store.select(selectEstimateQuote);
  quoteDocumentSections$ = this.store.select(selectQuoteDocumentSections);

  constructor(private store: Store<EstimateQuoteState>) {}

  getEstimateQuote(estimateid: string): void {
    this.store.dispatch(getEstimateQuote({ estimateid }));
  }

  updateEstimateQuote(fullQuote: FullQuote): void {
    this.store.dispatch(updateEstimateQuote({ fullQuote }));
  }

  getQuoteDocumentSection(estimateid: string): void {
    this.store.dispatch(getQuoteDocumentSection({ estimateid }));
  }

  updateQuoteDocumentSections(quoteSections: any): void {
    this.store.dispatch(updateQuoteDocumentSection({ quoteSections }));
  }
}
