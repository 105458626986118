import { BillingSettingsEffects } from './billing-setting.effects';
import { TeamSettingsEffects } from './team-settings.effects';
import { GeneralSettingsEffects } from './general-settings.effects';

export const effects = [
  BillingSettingsEffects,
  TeamSettingsEffects,
  GeneralSettingsEffects,
];

export * from './team-settings.effects';
export * from './general-settings.effects';
