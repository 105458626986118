import { createSelector } from '@ngrx/store';
import { selectSharedState, SharedState } from '../reducers';
import { CommonDataState } from '../reducers/common-data.reducer';

export const selectCommonData = createSelector(
  selectSharedState,
  (state: SharedState) => state.commonData,
);

export const selectJobStatuses = createSelector(
  selectCommonData,
  (s: CommonDataState) => s.jobStatuses,
);

export const selectEstimateStatuses = createSelector(
  selectCommonData,
  (s: CommonDataState) => s.estimateStatuses,
);
