/* eslint-disable arrow-parens */
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { ofType } from '@ngrx/effects';
import { ScannedActionsSubject } from '@ngrx/store';
import { PriceListCategory } from 'app/shared/models';
import { race, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  addCurrentPriceListCategorySuccess,
  editCurrentPriceListCategorySuccess,
} from '../../store/actions';
import { CurrentPriceListFacade } from '../../store/facades';

import { maxBy } from 'lodash';
import { PriceListItemAddEdit } from '../../models';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-price-list-category-dialog',
  templateUrl: './price-list-category-dialog.component.html',
  styleUrls: ['./price-list-category-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxTrimDirectiveModule,
    MatInputModule,
    NgIf,
    MatButtonModule,
  ],
})
export class PriceListCategoryDialogComponent implements OnInit, OnDestroy {
  public isEditMode = false;
  public title: string;
  public categoryForm = this.fb.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    displayOrder: -1,
  });

  private unsubscriber$ = new Subject<void>();
  private allCategories: PriceListCategory[] = [];

  constructor(
    private dialogRef: MatDialogRef<PriceListCategoryDialogComponent>,
    private facade: CurrentPriceListFacade,
    private fb: FormBuilder,
    private actions$: ScannedActionsSubject,
    @Inject(MAT_DIALOG_DATA) public data?: PriceListItemAddEdit,
  ) {
    this.title = `${this.data?.isEdit ? 'Edit' : 'Add'} Category`;
    this.isEditMode = this.data?.isEdit == null ? false : true;
    this.categoryForm.patchValue(this.data.category);
  }

  ngOnInit(): void {
    race(
      this.actions$.pipe(ofType(addCurrentPriceListCategorySuccess)),
      this.actions$.pipe(ofType(editCurrentPriceListCategorySuccess)),
    )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((action) => {
        this.close(action);
      });

    this.facade.allCategories$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((x) => (this.allCategories = x));
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  save(): void {
    const data = { ...this.data.category, ...this.categoryForm.value };
    console.log('save category form:::', data);
    if (this.data.isEdit) {
      this.facade.editCategory(data);
    } else {
      data.displayOrder = this.getMaxViewOrder();
      this.facade.addCategory(data);
    }
  }

  close(action?): void {
    this.dialogRef.close(action);
  }

  private getMaxViewOrder(): number {
    if (this.allCategories.length > 0) {
      const max =
        maxBy(this.allCategories, (c) => c.displayOrder).displayOrder + 1;
      return max;
    }
    return 0;
  }
}
