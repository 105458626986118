import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackNavigationService {
  url = null;
  tooltip = null;

  reset() {
    this.url = null;
    this.tooltip = null;
  }
}
