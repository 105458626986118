<div class="w-full">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <div class="flex w-full flex-col" *ngIf="isConnected === false">
        <p>
            If you use QuickBooks as your accounting platform, we can
            automatically synchronise data so you don’t have to type it into
            both systems
        </p>
        <button class="mt-4 w-40" [disabled]="isReadOnly" (click)="connect()">
            <img src="images/logo/C2QB_green_btn.svg" />
        </button>
    </div>
    <div class="flex w-full flex-col" *ngIf="isConnected">
        <app-connection-status
            [syncStatus]="syncStatus"
            [data]="transactionHistory"
        >
        </app-connection-status>

        <div class="my-6 flex flex-row">
            <button
                class="rounded-xl px-8"
                [color]="'primary'"
                (click)="disconnect()"
                mat-flat-button
            >
                Disconnect from QuickBooks
            </button>
        </div>

        <app-quickbooks-integration-method-customization
            *ngIf="settings"
            [settings]="settings"
            (change)="onSettingChange($event)"
        ></app-quickbooks-integration-method-customization>
    </div>
</div>
