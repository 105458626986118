<div class="w-full">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

    <div class="flex flex-col" *ngIf="isConnected === false">
        <p>
            If you use Xero as your accounting platform, we can automatically
            synchronise data so you don’t have to type it into both systems
        </p>
        <button class="mt-4 h-7" (click)="connect()" [disabled]="isReadOnly">
            <img class="w-40" src="images/logo/xero-connect-blue.svg" />
        </button>
    </div>
    <div class="flex w-full flex-col" *ngIf="isConnected">
        <span *ngIf="settings?.connectionActive"
            >You are connectected to {{ tenantName }}</span
        >

        <fuse-alert *ngIf="!settings?.connectionActive" [type]="'error'">
            <span fuseAlertTitle>Xero Connection Error</span>
            There is an error trying to contact Xero. Try disconnecting and
            reconnecting to Xero to resolve this issue.
        </fuse-alert>
        <button class="my-4 h-7" (click)="disconnect()" [disabled]="isReadOnly">
            <img class="w-40" src="images/logo/xero-disconnect-blue.svg" />
        </button>

        <app-integration-method-customization
            *ngIf="settings?.connectionActive"
            [settings]="settings"
            [accountList]="xeroAccounts"
            (change)="onSettingChange($event)"
        ></app-integration-method-customization>
    </div>
</div>
