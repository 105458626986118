import { createAction, props } from '@ngrx/store';
import { FileUpload } from 'app/shared/models';

export enum FileUploadActionTypes {
  FileUploadRequest = '[File Upload] Request',
  FileUploadFileRequest = '[File Upload] File Request',
  FileUploadCancel = '[File Upload] Cancel',
  FileUploadReset = '[File Upload] Reset',
  FileUploadStarted = '[File Upload] Started',
  FileUploadProgress = '[File Upload] Progress',
  FileUploadFailure = '[File Upload] Failure',
  FileUploadCompleted = '[File Upload] Completed',
}

export const fileUploadRequest = createAction(
  FileUploadActionTypes.FileUploadRequest,
  props<{ files: FileUpload[] }>(),
);

export const fileUploadFileRequest = createAction(
  FileUploadActionTypes.FileUploadFileRequest,
  props<{ index: number; file: FileUpload }>(),
);

export const fileUploadStarted = createAction(
  FileUploadActionTypes.FileUploadStarted,
  props<{ index: number }>(),
);

export const fileUploadCancel = createAction(
  FileUploadActionTypes.FileUploadCancel,
  props<{ index: number }>(),
);

export const fileUploadProgress = createAction(
  FileUploadActionTypes.FileUploadProgress,
  props<{ index: number; loaded: number }>(),
);

export const fileUploadCompleted = createAction(
  FileUploadActionTypes.FileUploadCompleted,
  props<{ index: number; entity: any; file: FileUpload }>(),
);

export const fileUploadFailure = createAction(
  FileUploadActionTypes.FileUploadFailure,
  props<{ index: number; error: any }>(),
);
