import { createReducer, on } from '@ngrx/store';
import { FullQuote } from 'app/shared/models';
import { CallState, LoadingState } from 'app/store';

import {
  getEstimateQuote,
  getEstimateQuoteFailure,
  getEstimateQuoteSuccess,
  getQuoteDocumentSection,
  getQuoteDocumentSectionFailure,
  getQuoteDocumentSectionSuccess,
  updateEstimateQuote,
  updateEstimateQuoteFailure,
  updateEstimateQuoteSuccess,
  updateQuoteDocumentSection,
  updateQuoteDocumentSectionFailure,
  updateQuoteDocumentSectionSuccess,
} from '../actions/estimate-quote.actions';

export interface EstimateQuoteState {
  estimateQuote: FullQuote;
  callState: CallState;
  error: any;
  quoteDocumentSections: any[];
}

export const initialState: EstimateQuoteState = {
  estimateQuote: null,
  callState: LoadingState.INIT,
  error: null,
  quoteDocumentSections: [],
};

export const estimateQuoteReducer = createReducer(
  initialState,
  // on(
  //   fromUserActions.uploadQuoteLogoSuccess,
  //   (state, payload): EstimateQuoteState => ({
  //     ...state,
  //     estimateQuote: {
  //       ...state.estimateQuote,
  //       estimate: {
  //         ...state.estimateQuote.estimate,
  //         quote: {
  //           ...state.estimateQuote.estimate.quote,
  //           logo: payload.fileLocation,
  //         },
  //       },
  //     },
  //   })
  // ),
  on(getEstimateQuote, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getEstimateQuoteFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getEstimateQuoteSuccess, (state, { estimateQuote }) => ({
    ...state,
    estimateQuote: estimateQuote,
    callState: LoadingState.LOADED,
  })),

  // get quote sections
  on(getQuoteDocumentSection, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(getQuoteDocumentSectionFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(getQuoteDocumentSectionSuccess, (state, { quoteDocumentSections }) => ({
    ...state,
    quoteDocumentSections,
    callState: LoadingState.LOADED,
  })),

  // update Estimate
  on(updateEstimateQuote, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateEstimateQuoteFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateEstimateQuoteSuccess, (state, { estimateQuote }) => ({
    ...state,
    estimateQuote: {
      ...estimateQuote,
    },
    callState: LoadingState.LOADED,
  })),

  // update quote sections
  on(updateQuoteDocumentSection, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(updateQuoteDocumentSectionFailure, (state, { error }) => ({
    ...state,
    callState: { error },
  })),
  on(updateQuoteDocumentSectionSuccess, (state, { quoteDocumentSections }) => ({
    ...state,
    quoteDocumentSections,
    callState: LoadingState.LOADED,
  })),
);

function updateQuote(
  estimateQuote: FullQuote,
  newEstimateQuote: FullQuote,
): FullQuote {
  estimateQuote = JSON.parse(JSON.stringify(estimateQuote));
  if (newEstimateQuote.estimate) {
    estimateQuote.estimate = newEstimateQuote.estimate;
  }
  // estimateQuote.sections = estimateQuote.sections.map((section) => {
  //   newEstimateQuote.sections.forEach((s) => {
  //     if (s.id === section.id) {
  //       section = s;
  //     }
  //   });
  //   return section;
  // });
  console.log(estimateQuote);
  return estimateQuote;
}
