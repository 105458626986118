import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import {
  estimateDetailReducer,
  EstimateDetailState,
} from './estimate-detail.reducer';
import {
  estimateListReducer,
  EstimateListState,
} from './estimate-list.reducer';
import {
  estimateQuoteReducer,
  EstimateQuoteState,
} from './estimate-quote.reducer';

export interface EstimateState {
  estimates: EstimateListState;
  estimate: EstimateDetailState;
  estimateQuote: EstimateQuoteState;
}

export const reducers: ActionReducerMap<EstimateState> = {
  estimates: estimateListReducer,
  estimate: estimateDetailReducer,
  estimateQuote: estimateQuoteReducer,
};

export const selectEstimateState =
  createFeatureSelector<EstimateState>('estimate');
