<div class="relative flex flex-col w-full h-full max-h-screen">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon
        class="text-secondary"
        [svgIcon]="'heroicons_outline:x-mark'"
      ></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="shadow-lg flex flex-col sm:flex-row items-center p-8 pb-2">
    <div class="text-center sm:text-left">
      <!-- Title -->
      <div
        class="text-xl leading-6 font-medium"
        [innerHTML]="'Add attachment'"
      ></div>
    </div>
  </div>

  <div class="p-8" *ngIf="!dataSource.data?.length">No attachments</div>

  <div
    *ngIf="dataSource.data?.length"
    class="p-2.5 flex flex-row dark:bg-gray-900 h-full overflow-hidden"
  >
    <div class="w-full max-w-80 overflow-y-auto">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControlPhotos">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node
          [ngClass]="{ 'bg-card': node === selectedFolder }"
          class="cursor-pointer"
          (click)="selectNode(node)"
          *matTreeNodeDef="let node"
          matTreeNodePadding
        >
          <div class="min-w-10 min-h-10"></div>
          {{ node.name }}
        </mat-tree-node>
        <mat-tree-node
          [ngClass]="{ 'bg-card': node === selectedFolder }"
          class="cursor-pointer"
          (click)="selectNode(node)"
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControlPhotos.isExpanded(node)
                  ? "expand_more"
                  : "chevron_right"
              }}
            </mat-icon>
          </button>
          {{ node.name }}
        </mat-tree-node>
      </mat-tree>
    </div>

    <div
      *ngIf="selectedFolder.type === storageSource.JobPhoto"
      class="min-w-fit -mt-2.5 -mr-2.5 overflow-y-auto"
    >
      <div class="grid grid-cols-2">
        <ng-container *ngFor="let photo of selectedItems; let i = index">
          <div
            class="col-span-1"
            *ngIf="photo.thumbnailUrl"
            class="flex flex-col group relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card hover:text-primary-600 dark:hover:text-white"
          >
            <a
              class="absolute top-0 left-0"
              [ngClass]="{
                hidden: photo.selected || !showAllSelectors
              }"
              mat-icon-button
            >
              <mat-icon class="icon-size-5 opacity-70">
                radio_button_unchecked
              </mat-icon>
            </a>
            <a
              class="absolute top-0 left-0"
              (click)="toggleSelected(photo, storageSource.JobPhoto)"
              mat-icon-button
            >
              <mat-icon
                class="hidden group-hover:block hover:text-primary-600 dark:hover:text-white"
              >
                check_circle</mat-icon
              >
            </a>
            <a
              class="absolute top-0 left-0"
              *ngIf="photo.selected"
              mat-icon-button
            >
              <svg style="height: 22px; width: 22px">
                <circle fill="white" cx="12" cy="12" r="8"></circle>
              </svg>
            </a>
            <a
              *ngIf="photo.selected"
              class="absolute top-0 left-0"
              (click)="toggleSelected(photo, storageSource.JobPhoto)"
              mat-icon-button
            >
              <mat-icon class="text-blue-600"> check_circle </mat-icon>
            </a>

            <a>
              <div class="aspect-w-9 aspect-h-6">
                <div class="flex items-center justify-center">
                  <img
                    class="rounded-xl thumbnail"
                    [src]="photo.thumbnailUrl + '?' + modalData.photosSasToken"
                    (click)="toggleSelected(photo, storageSource.JobPhoto)"
                  />
                </div>
              </div>
              <div
                class="flex flex-col flex-auto justify-center text-center text-sm font-medium"
              >
                <div class="truncate" [matTooltip]="photo.name">
                  {{ photo.name }}
                </div>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      [hidden]="selectedFolder.type !== storageSource.JobFile"
      class="w-full overflow-y-auto pl-2"
    >
      <table
        class="w-full"
        mat-table
        [dataSource]="dataSourceTable"
        matSortActive="name"
        matSortDirection="asc"
        matSortDisableClear
        matSort
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th class="w-20" mat-header-cell *matHeaderCellDef>Size</th>
          <td class="w-20 whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.size | filesize }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          [ngClass]="{
            'bg-gray-200 dark:bg-slate-800': row.selected
          }"
          (click)="toggleSelected(row, storageSource.JobFile)"
          class="cursor-pointer"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
  <!-- Action buttons -->
  <div
    class="shadow-lg flex items-center justify-start px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
  >
    <button
      [matDialogClose]="undefined"
      class="fuse-mat-button-large"
      mat-stroked-button
    >
      <span class="ml-2 mr-1">Cancel</span>
    </button>
    <button
      [disabled]="!attachments?.files?.length && !attachments?.photos?.length"
      (click)="select()"
      class="ml-4 fuse-mat-button-large"
      mat-flat-button
      [color]="'primary'"
    >
      <span class="ml-2 mr-1">Select</span>
    </button>
  </div>
</div>
