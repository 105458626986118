import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BaseIdNameDesc } from 'app/shared/models';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  getSuppliersListByJob,
  getSuppliersListByJobFailure,
  getSuppliersListByJobSuccess,
  getUseCategories,
  getUseCategoriesFailure,
  getUseCategoriesSuccess,
  updateUseCategories,
  updateUseCategoriesFailure,
  updateUseCategoriesSuccess,
} from '..';
import { JobService } from '../../services/job.service';
@Injectable({
  providedIn: 'root',
})
export class SuppliersListByJobDetailEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
  ) {}

  getJobOrderPriceListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSuppliersListByJob),
      switchMap(({ id }) =>
        this.jobService.joborderListSuppliersItemsToOrderJob(id).pipe(
          map((suppliersDesc: BaseIdNameDesc[]) =>
            getSuppliersListByJobSuccess({ suppliersDesc }),
          ),
          // tap(() => getUseCategories({id})),
          // concatMap((suppliersDesc: BaseIdNameDesc[]) => [
          //     getSuppliersListByJobSuccess({ suppliersDesc }),
          //     getUseCategories({id})
          // ]),
          shareReplay(),
          catchError((error) => {
            return of(getSuppliersListByJobFailure({ error }));
          }),
        ),
      ),
    ),
  );

  getUseCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUseCategories),
      switchMap(({ id }) =>
        this.jobService.getUseCategories(id).pipe(
          map((useCategoriesState: any) =>
            getUseCategoriesSuccess({ useCategoriesState }),
          ),
          catchError((error) => {
            return of(getUseCategoriesFailure({ error }));
          }),
          shareReplay(),
        ),
      ),
    ),
  );
  updateUseCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUseCategories),
      switchMap(({ id, value }) =>
        this.jobService.updateUseCategories(id, value).pipe(
          map((useCategoriesState: any) =>
            getUseCategoriesSuccess({ useCategoriesState }),
          ),
          catchError((error) => {
            return of(getUseCategoriesFailure({ error }));
          }),
          shareReplay(),
        ),
      ),
    ),
  );
}
